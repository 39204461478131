import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setValue: any,
  editLanguages?:any,
  //value: any,
};

const GetSupplierLanguagesMulti: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setValue, editLanguages //, value
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  //const [value, setValue] = useState<any>(propsField.value);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/DropDown/GetSupplierLanguages`, config);
        if (data.status !== 400) {
          setInfo(data.data)          
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, []);

  function handleInputChange(a: any) {

    //info.filter((a:any) => propsField.value.includes(a.id));

    //setValue(a.map((op:any) => op.id));
    propsField.onChange(a.map((op:any) => op.id));
  }

  useEffect(()=> {
    if(editLanguages) {
      let aux:any = [];
      editLanguages.map((item:any)=> {
        aux.push(item.toString())
      })      
      setValue('languagesSupported', aux)
    }
  },[editLanguages, info])

  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("accordionFinancial.select.select")}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.id}`}
            getOptionLabel={(option: any) => `${option.description}`}
            value={info.filter((a:any) => propsField.value.includes(a.id))}
            onChange={handleInputChange}
            isMulti={true}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetSupplierLanguagesMulti;