import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterExportERPSpreadsheet.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

import { useForm } from "react-hook-form";

export interface propFilter {
    buscar: any,
}

const FilterExportERPSpreadsheet: React.FC<propFilter> = ({
    buscar
}) => {
    const today = new Date();
    const todayStr = [
        `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`
    ];

    const [filterDate, setFilterDate] = useState<any>(todayStr);
    const [confirmation, setConfirmation] = useState<any>(false);

    const {
        handleSubmit,
    } = useForm({});

    const handleActionBuscar = () => {
        buscar(filterDate);
        setConfirmation(false);
    }

    const onSubmit = (data: any) => {
        setConfirmation(true);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Exportar Planilhas Rm</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} defaultValues={[`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`]} />
                                                </InputGroup>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button className="btn-default text-uppercase" type="submit">Exportar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={confirmation}
                setModalShow={setConfirmation}
                titulo=""
                mensagem={filterDate.length === 2 ?  `Deseja exportar a planilha RM dos dias ${filterDate[0]} a ${filterDate[1]}?` : `Deseja exportar a planilha RM do dia ${filterDate[0]}?`}
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterExportERPSpreadsheet;