/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import api from '../../services/api';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import { Link } from 'react-router-dom';
import DesktopDefault from '../../templates/DesktopDefault';
import VehiclesFilter from './components/VehiclesFilter/VehiclesFilter';
import VehiclesTable from './components/VehiclesTable/VehiclesTable';

import './Vehicles.scss';

function Vehicles() {
  const [vehicles, setVehicles] = useState<any>(null);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<any>(false);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [allowSearch, setAllowSearch] = useState<any>(1);
  /* END - Pagination */

  const [actualName, setActualName] = useState<any>();
  const [actualLicensePlate, setActualLicensePlate] = useState<any>();
  const [actualModel, setActualModel] = useState<any>();
  const [actualType, setActualType] = useState<any>();

  useEffect(() => {
    //var today: any = new Date();

    buscar('', '', '', 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  
  const reloadSerch = () => {
    buscar(actualName, actualLicensePlate, actualModel, actualType);
  }

  useEffect(() => {
    setLoading(true);
    setVehicles(null);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listVehicles() {

      try {
        const { data } = await api.post('/Vehicles/GetVehiclesByFilterAsync',
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "vehicleSupplierNameFind": actualName,
            "vehicleLicensePlateFind": actualLicensePlate,
            "vehicleModelFind": actualModel,
            "vehicleTypeFind": actualType,
            "orderByField": ""
          }
          , config
        );

        if (data.status !== 400) {
          setLoading(false);
          setVehicles(data.data.data.rows);
          setTotalRows(data.data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }

      }
    }
    listVehicles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage, allowSearch]);

  async function buscar(name: string = '', licensePlate: string = '', model: any = '', type: any = 0) {
    setLoading(true);
    setVehicles(null);

    setActualName(name);
    setActualLicensePlate(licensePlate);
    setActualModel(model);
    setActualType(type);


    if (pageCount !== 1) {
      setPageCount(1);
    } else {
      setAllowSearch(allowSearch + 1);
    }

    
  };


  return (
    <>
      <DesktopDefault>

        <div className="container-fluid content-dashboard">
          <Breadcrumb title={'Veículos / Lista de Veículos'} />

          <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>Veículos</h2>
            <Link to={'/vehicles/add-vehicle'} className='btn btn-primary'>
              Novo Veículo
            </Link>
          </div>

          <VehiclesFilter buscar={buscar} vehicles={vehicles} error={error} />

          <VehiclesTable vehicles={vehicles} loading={loading} totalRows={totalRows} pageCount={pageCount} setPageCount={setPageCount} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} reloadSerch={reloadSerch}/>
        </div>
      </DesktopDefault>
    </>

  );
}

export default Vehicles;