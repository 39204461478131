/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

//COMPONENTS
import DesktopDefault from '../../templates/DesktopDefault';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import FilterReportDailyReceipt from './components/FilterReportDailyReceipt/FilterReportDailyReceipt';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

//Style
import './ReportDailyReceipt.scss';
import { Button, Col, Form, Modal, Row } from 'react-bootstrap';
import SalesReport from './components/SalesReport/SalesReport';
import ChartProjection from './components/ChartProjection/ChartProjection';
import TableDailyReceipt from './components/TableDailyReceipt/TableDailyReceipt';

function ReportDailyReceipt() {

    const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const ini = new Date(today.getFullYear(), today.getMonth(), 1);
    const fini = new Date(today.getFullYear(), today.getMonth()+1, 0);

    const iniStr = `${ini.getDate()}/${ini.getMonth() + 1}/${ini.getFullYear()}`;
    const finiStr = `${fini.getDate()}/${fini.getMonth() + 1}/${fini.getFullYear()}`;


    const [salesByClient, setSalesByClient] = useState<any>(null);
    const [salesByProduct, setSalesByProduct] = useState<any>(null);
    const [projection, setProjection] = useState<any>(null);
    const [info, setInfo] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [loadingProjection, setLoadingProjection] = useState<any>(false);
    const [loadingSalesByClient, setLoadingSalesByClient] = useState<any>(false);
    const [loadingSalesByProduct, setLoadingSalesByProduct] = useState<any>(false);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalResponse, setModalResponse] = useState<any>('');
    const [modalLog, setModalLog] = useState<any>(null);

    const defaultDate = [iniStr,finiStr];
    const defaultTypeDate = 3; // 1 = Período, 2 = Hoje, 3 = Este Mês, 4 = Este Ano

    const [actualDate, setActualDate] = useState<any>(defaultDate);
    const [actualTypeDate, setActualTypeDate] = useState<any>(defaultTypeDate);

    // useEffect(() => {

    // }, []);

    const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }
    // useEffect(() => {
    //     var today: any = new Date();

    //     /* buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear() - 1}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 0, 0, 1, 0, 0, 0, '', ''); */
    //     buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 1, 1, 0, 0, 0, 0, '', '');
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [])

    async function buscar(typeDate: any, date: any) {
        setActualTypeDate(typeDate);
        setActualDate(date);

        async function changingPage() {
            setLoading(true);
            setLoadingProjection(true);
            setLoadingSalesByClient(true);
            setLoadingSalesByProduct(true);

            let beginDate = []
            let endDate = []

            if (date?.length !== 0) {
                if (date?.length === 1) {
                    beginDate = date[0].split('/')
                } else if (date?.length === 2) {
                    beginDate = date[0].split('/')
                    endDate = date[1].split('/')
                }
            } else {
                if (defaultDate?.length === 1) {
                    beginDate = defaultDate[0].split('/')
                } else if (defaultDate?.length === 2) {
                    beginDate = defaultDate[0].split('/')
                    endDate = defaultDate[1].split('/')
                }
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                // main data
                const res = await api.post('/ReportReceipt/GetReportDailyReceiptAsync',
                    {
                        "calculaProjecao": typeDate === 3 ? 1 : 2,
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (res.data.status !== 400) {
                    const dados = res.data.data;

                    setInfo(dados);
                    setLoading(false);
                }

                // projection
                const res4 = await api.post('/ReportReceipt/GetReportByProjectionAsync',
                    {
                        "mes": parseInt(beginDate[1]),
                        "ano": parseInt(beginDate[2])
                    }, config);

                if (res4.data.status !== 400) {
                    const dados = res4.data.data;

                    setProjection(dados);
                    setLoadingProjection(false);
                }

                // sales by client
                const res2 = await api.post('/ReportReceipt/GetReportByClientTop10Async',
                    {
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (res2.data.status !== 400) {
                    const dados = res2.data.data;

                    setSalesByClient(dados);
                    setLoadingSalesByClient(false);
                }

                // sales by product
                const res3 = await api.post('/ReportReceipt/GetReportByProductTop10Async',
                    {
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (res3.data.status !== 400) {
                    const dados = res3.data.data;

                    setSalesByProduct(dados);
                    setLoadingSalesByProduct(false);
                }

            } catch (error: any) {
                setLoading(false);
            }
        }
        changingPage();
    }

    async function exportReportByProduct() {
        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const res = await api.post('/ReportReceipt/ExportReportByProduct',
            {
                "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
            }, config);

            if (res.status !== 400) {
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
//console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
          let link = document.createElement('a');
          link.href = blob;//window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.remove();
          window.URL.revokeObjectURL(link.href);
        //}
    };

    async function printReportByProduct() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        
        try {
            const res = await api.post('/ReportReceipt/PrintReportByProduct',
            {
                "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
            }, config);

            if (res.status !== 400) {
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    useEffect(() => {
        async function changingPage() {
            setLoading(true);
            setLoadingProjection(true);
            setLoadingSalesByClient(true);
            setLoadingSalesByProduct(true);

            let beginDate = []
            let endDate = []

            if (actualDate?.length !== 0) {
                if (actualDate?.length === 1) {
                    beginDate = actualDate[0].split('/')
                } else if (actualDate?.length === 2) {
                    beginDate = actualDate[0].split('/')
                    endDate = actualDate[1].split('/')
                }
            } else {
                if (defaultDate?.length === 1) {
                    beginDate = defaultDate[0].split('/')
                } else if (defaultDate?.length === 2) {
                    beginDate = defaultDate[0].split('/')
                    endDate = defaultDate[1].split('/')
                }
            }

            let today: any = new Date();

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            
            try {
                // main data
                const res = await api.post('/ReportReceipt/GetReportDailyReceiptAsync',
                    {
                        "calculaProjecao": actualTypeDate === 3 ? 1 : 2,
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (res.data.status !== 400) {
                    const dados = res.data.data;

                    setInfo(dados);
                    setLoading(false);
                }

                // projection
                const res4 = await api.post('/ReportReceipt/GetReportByProjectionAsync',
                    {
                        "mes": parseInt(beginDate[1]),
                        "ano": parseInt(beginDate[2])
                    }, config);

                if (res4.data.status !== 400) {
                    const dados = res4.data.data;

                    setProjection(dados);
                    setLoadingProjection(false);
                }

                // sales by client
                const res2 = await api.post('/ReportReceipt/GetReportByClientTop10Async',
                    {
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (res2.data.status !== 400) {
                    const dados = res2.data.data;

                    setSalesByClient(dados);
                    setLoadingSalesByClient(false);
                }

                // sales by product
                const res3 = await api.post('/ReportReceipt/GetReportByProductTop10Async',
                    {
                        "dataInicio": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
                        "dataFim": (endDate.length === 0) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                    }, config);

                if (res3.data.status !== 400) {
                    const dados = res3.data.data;

                    setSalesByProduct(dados);
                    setLoadingSalesByProduct(false);
                }

            } catch (error: any) {
                setLoading(false);
            }
        }
        changingPage();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard dailyreceipt-report">
                    <Breadcrumb title={'Financeiro / Relatórios / Relatório de Receita Diária'} />
                    <div className="suppliers mt-4 d-flex justify-concolumntent-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Relatório de Receita Diária</h2>
                    </div>

                    <FilterReportDailyReceipt buscar={buscar} defaultDate={defaultDate} defaultTypeDate={defaultTypeDate} />

                    <Row>
                        <Form.Group as={Col} className="table-default" style={{marginBottom: 0}}>
                            <div className="table-container d-flex justify-content-between">
                                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                <div>
                                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportReportByProduct}>
                                    <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Exportar
                                    </div>
                                </div>
                                <div>
                                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printReportByProduct}>
                                    <FontAwesomeIcon
                                        file-invoice
                                        icon={["fal", "print"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Imprimir
                                    </div>
                                </div>
                                </div>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="5">
                            <div>
                                {/* velocimetro projeção */}
                                <ChartProjection info={projection} loading={loadingProjection} />
                            </div>
                            <div>
                                {/* tabela totais */}
                                <TableDailyReceipt info={info} loading={loading} typeDate={actualTypeDate} />
                            </div>
                        </Form.Group>
                        <Form.Group as={Col} md="7">
                            <div>
                                {/* Vendas por Cliente */}
                                <SalesReport info={salesByClient} loading={loadingSalesByClient} title="Vendas por Cliente" />
                            </div>
                            <div>
                                {/* Vendas por Produto */}
                                <SalesReport info={salesByProduct} loading={loadingSalesByProduct} title="Vendas por Produto" />
                            </div>
                        </Form.Group>
                    </Row>
                    
                    <Modal
                        className="modal-confirm"
                        show={modalShow === true}
                        onHide={() => {
                            setModalLog(null);
                            setModalShow(false);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        {modalLog !== null
                            ?
                            <div className='modal-body text-center'>
                                <div>
                                    {modalLog === 0
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em"}}
                                        />
                                    }
                                </div>

                                <div className="px-md-5" style={{ padding: "0 3rem" }}>
                                    {modalResponse}
                                </div>
                                <div>
                                    <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setModalShow(false)}>Fechar</Button>
                                </div>
                            </div>
                            :

                            <div className="modal-body loading-modal">
                                <div className="text-center">
                                    <div className="load"></div>
                                </div>
                                <div className='d-flex justify-content-center'>
                                    <span>Processando</span>
                                </div>
                                <div></div>
                            </div>
                        }
                    </Modal>
                </div>
            </DesktopDefault>
        </>

    );
    
}

export default ReportDailyReceipt;