import React, { useState, useEffect, Key } from 'react';
import api from '../../../../../../services/api';

import { Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { Row, Col, InputGroup } from "react-bootstrap";

// COMPONENTS
import SingleCalendar from '../../../../../../components/Calendar/SingleCalendar';
import GetAccountingAccountsFilter from '../../../../../../components/C2Points/GetAccountingAccountsFilter';
import GetPaymentMethodsFilter from '../../../../../../components/C2Points/GetPaymentMethodsFilter';
import GetAccountOperatorsFilter from '../../../../../../components/C2Points/GetAccountOperatorsFilter';

import "./SingleNote.scss";
import SingleCalendarEditable from '../../../../../../components/Calendar/SingleCalendarEditable';

export interface props {
    singleNoteInfo: any;
    searchSingleNote: any;
    isCreditNote?: any;
};

const SingleNote: React.FC<props> = ({
    singleNoteInfo, searchSingleNote, isCreditNote
}: props) => {

    const hoje = (new Date()).toISOString().substring(0,10);


    const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
            return cell.toFixed(2).replace(".", ",");
        } else {
            return '0,00';
        }
    }

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('');

    const [date, setDate] = useState<any>(formatDate(hoje));
    const [dateVencimento, setDateVencimento] = useState<any>([]);

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    const [categoria, setCategoria] = useState<any>((isCreditNote === true) ? 383 : 378);
    const [formaPagamento, setFormaPagamento] = useState<any>(null);
    const [operadora, setOperadora] = useState<any>(null);
    const [currency, setCurrency] = useState<any>('BRL');

    const [valorAtual, setValorAtual] = useState<any>('');
    const [mdr, setMdr] = useState<any>('');

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // } = useForm({
    //     defaultValues: {
    //         account: (isCreditNote === true) ? {id:383} : {id:378},
    //         payment: '',
    //         operator: '',
    //         value: '',
    //         mdr: '',
    //         currency: 'BRL',
    //         observation: ''
    //     }
    // });

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    function convertDate(elem: any) {
        if (elem !== null) {
            var aux: any = elem.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    async function handleSetOperadora(operadoraP: any) {
        setOperadora(operadoraP);
        //var mdr = 0;
        var valorAtualF = valorAtual && valorAtual !== '' ? valorAtual.replace(',','.') : 0;
    
        const calcAccountOperatorMdr = async () => {
            try {
              const token = localStorage.getItem('GroupId') || '';
              const config = {
                  headers: { 'Authorization': `Bearer ${token}` },
              };
              
              const { data } = await api.post('BillsReceivable/CalcAccountOperatorMdr', {
                id: operadoraP.id,
                installments: 1,
                amount: valorAtualF
              }, config);
              if (data.status !== 400) {
                var response = data.data;
      
                if(response.log !== 1) {
                  setMdr(formatValue(response.data));
                } else {
                  setMdr('');
                }
              }   
            }catch(error: any) {
              //setModalMessage("Erro, tente novamente mais tarde");
              //setLog(1);
              //setModalResponse("error");
            }
        }
        await calcAccountOperatorMdr();
        // if(operadoraP.tipoTaxa === 1) { // 
        //   mdr = operadoraP.taxa;
        // } else {
        //   mdr = valorAtualF * operadoraP.taxa / 100;
        // }

        // if(operadoraP.temTaxaMin === 1) {
        //     mdr = mdr + operadoraP.taxaMin;
        // } 

        // //console.log(mdr);
        // if(mdr) setMdr(formatValue(mdr));
        // else setMdr('');
    }

    function handleValorChange(e: any) {
        const newValue = e.target.value;
        setValorAtual(newValue);
    }

    function handleMdrChange(e: any) {
        const newValue = e.target.value;
        setMdr(newValue);
    }

    const onSubmit = (data: any) => {
        //data.account = data.account.id;
        
        //data.operator = data.operator.id;
        //data.payment = data.payment.id;

        data.account = categoria;//.id;
        data.operator = operadora ? operadora.id : null;
        data.payment = formaPagamento.id;
        data.currency = currency;
        
        data.value = parseFloat( valorAtual ? valorAtual.replace(',','.') : 0 );
        data.mdr = parseFloat( mdr ? mdr.replace(',','.') : 0 );

        //console.log(dateVencimento);return;

        data.data = date !== '' ? fixDate(date) : '';
        data.vencimento = dateVencimento !== '' ? fixDate(dateVencimento) : '';
        data.observation = data.observation ? data.observation : '';

        //; return;

        const insertPayment = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/BillsReceivable/InsertDetachedBill`,
                    {
                        "reserva": singleNoteInfo.localizador, // localizador reserva
                        "agente": singleNoteInfo.items[0].agenteIdentity, // identity do agente
                        "notaCredito": (isCreditNote) ? 1 : 2, // nota credito 1 = sim, 2 = não
                        "data": data.data, // data da nota
                        "contaContabil": data.account, // id da conta contabil
                        "currency": data.currency, // currency da nota
                        "formaPagamento": data.payment, // id da forma pagamento
                        "valor": data.value,
                        "mdr": data.mdr, // valor do mdr
                        "operadora": data.operator !== '' ? data.operator : null, // id da operadora
                        "vencimento": data.vencimento !== '' ? data.vencimento : null, // data de vencimento
                        "observacao": data.observation,
                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`,
                    }, config);
                if (res.status !== 400) {
                    console.log(res)
                    setModalShow(true);
                    setModalMessage(res.data.data.texto);
                    searchSingleNote(singleNoteInfo.localizador);
                }
            } catch (error: any) {
                setModalShow(true);
                setModalMessage("Algo deu errado");
                
            }
        };

        insertPayment();
    }

    useEffect(() => {
        
        if(singleNoteInfo) {
            
            const getPaymentMethodInfo = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.get(`/BillsReceivable/GetPaymentMethodById/${singleNoteInfo.bills[0].formaPagamentoId}`, config);
                    if (res.status !== 400) {
                        var dados = res.data.data;
                        setFormaPagamento(dados);
                    } 
                } catch (error: any) {
                    
                }
            };

            getPaymentMethodInfo();
        }

    }, [singleNoteInfo]);

    if (singleNoteInfo) {

        return (
            <>
                <div>
                    <div className='modal-content-note'>
                        <h5 className='mb-4'>Informações da Reserva</h5>
                        <Row>
                            <Col md={3}>
                                <p className='text-uppercase mb-1'>Data</p>
                                <b>{convertDate(singleNoteInfo.dataReserva)}</b>
                            </Col>
                            <Col md={3}>
                                <p className='text-uppercase mb-1'>Agente</p>
                                <b>{singleNoteInfo.agente}</b>
                            </Col>
                            <Col md={3}>
                                <p className='text-uppercase mb-1'>Cliente</p>
                                <b>{singleNoteInfo.cliente}</b>
                            </Col>
                        </Row>
                    </div>
                    <div className='modal-content-note'>
                        <h5 className='mb-4'>Produtos</h5>
                        {
                            singleNoteInfo.items.length > 0
                                ? singleNoteInfo.items.map((elem: any, index: Key) => {
                                    return (
                                        <>
                                            <Row key={index} className={index > 0 ? "mt-3" : ""}>
                                                <Col md={2}>
                                                    <p className='text-uppercase mb-1'>Data</p>
                                                    <b>{convertDate(elem.dataReserva)}</b>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-uppercase mb-1'>Serviços</p>
                                                    <b>{elem.tour}</b>
                                                </Col>
                                                <Col md={2}>
                                                    <p className='text-uppercase mb-1'>Valor</p>
                                                    <b>R$ {Math.ceil(elem.valor)},00</b>
                                                </Col>
                                                <Col md={1}>
                                                    <p className='text-uppercase mb-1'>Nº</p>
                                                    <b>{elem.qtdePax}</b>
                                                </Col>
                                                <Col md={3}>
                                                    <p className='text-uppercase mb-1'>Centro de Custo</p>
                                                    <b>{elem.centroCusto}</b>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                })
                                :
                                <>
                                </>
                        }
                    </div>
                    <div className='modal-content-note'>
                        <h5 className='mb-4'>Recebidos</h5>
                        <Row className='justify-content-between'>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <p className='text-uppercase mb-1'>Total Recebido</p>
                                        <b>R$ {Math.ceil(singleNoteInfo.totalRecebido)},00</b>
                                    </Col>
                                    <Col md={6}>
                                        <p className='text-uppercase mb-1'>Total Serviços</p>
                                        <b>R$ {Math.ceil(singleNoteInfo.totalServicos)},00</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <p className='text-uppercase mb-1'>Saldo</p>
                                <b>R$ {Math.ceil(singleNoteInfo.totalSaldo)},00</b>
                            </Col>
                        </Row>
                    </div>
                    <div className='modal-content-note single-content-note'>
                        <h5 className='mb-4'>Pagamentos</h5>
                        {
                            singleNoteInfo.bills.length > 0
                                ? singleNoteInfo.bills.map((elem: any, index: Key) => {
                                    return (
                                        <div className="payments-note" key={index}>
                                            <Row>
                                                <Col>
                                                    <span className="date-payments-note">{ (elem.data ? formatDate(elem.data) + " - " : formatDate(singleNoteInfo.dataReserva) + " - ") + elem.contaContabil }</span>
                                                </Col>
                                            </Row>
                                            <div className="content-payments-note">
                                                <div style={{ width: "15%", paddingRight: "20px" }}>
                                                    <p>Valor</p>
                                                    <b>R$ {elem.valor.toFixed(2).replace(".", ",")}</b>
                                                </div>
                                                <div style={{ width: "12.5%", paddingRight: "20px" }}>
                                                    <p>Currency</p>
                                                    <b>{elem.currency}</b>
                                                </div>
                                                <div style={{ width: "20%", paddingRight: "20px" }}>
                                                    <p>Metodo</p>
                                                    <b>{elem.formaPagamento}</b>
                                                </div>
                                                <div style={{ width: "22.5%", paddingRight: "30px" }}>
                                                    <p>Operadora</p>
                                                    <b title={`${elem.operadora}`}>{elem.operadora}</b>
                                                </div>
                                                <div style={{ width: "15%", paddingRight: "20px" }}>
                                                    <p>Vencimento</p>
                                                    <b>{convertDate(elem.vencimento)}</b>
                                                </div>
                                                <div style={{ width: "15%", paddingRight: "20px" }}>
                                                    <p>Observações</p>
                                                    <b title={`${elem.observacao}`}>{elem.observacao}</b>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <>
                                </>
                        }
                    </div>
                    <div className="modal-session-payment">
                        <div className='new-payment'>
                            <h5>Novo Pagamento</h5>
                            <Form
                                noValidate
                                autoComplete="false"
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <Row className="my-3">
                                    <Form.Group as={Col} md={2} controlId="formGridCat">
                                        <Form.Label>Data</Form.Label>
                                        <InputGroup
                                            hasValidation
                                            className=""
                                            size="sm"
                                            placeholder="Quando?"
                                        >
                                            <SingleCalendarEditable setValue={setDate} date={formatDate(hoje)} />
                                        </InputGroup>
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} controlId="formGridDesc">
                                        <Form.Label>Conta Contábil</Form.Label>
                                        <Controller
                                            control={control}
                                            name="account"
                                            rules={{ required: { value: categoria ? false : true, message: "required" }, }}
                                            //defaultValue={categoria?.id}
                                            defaultValue={categoria}
                                            render={({ field }: any) => (
                                                <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel="Conta Contábil" setFilterAccount={setCategoria} />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="account"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={2} controlId="formGridDesc">
                                        <Form.Label>Currency</Form.Label>
                                        <Controller
                                            control={control}
                                            name="currency"
                                            rules={{ required: { value: currency === '', message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='endpoint'>
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        defaultValue={currency}
                                                        value={currency}
                                                        onChange={setCurrency}
                                                    >
                                                        <option value='' selected disabled>Selecione</option>
                                                        <option value='BRL'>BRL</option>
                                                        <option value='USD'>USD</option>
                                                    </Form.Select>
                                                </div>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="currency"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md={4} controlId="formGridDesc">
                                        <Form.Label>Método de Pagamento</Form.Label>
                                        <Controller
                                            control={control}
                                            name="payment"
                                            rules={{ required: { value: true, message: "required" }, }}
                                            //defaultValue={singleNoteInfo.bills[0].formaPagamentoId}
                                            render={({ field }: any) => (
                                                <GetPaymentMethodsFilter propsField={field} propsErrors={errors} propsLabel="Método de pagamento" setPaymentMethod={setFormaPagamento} />)}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="payment"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md={3} controlId="formGridCat">
                                        <Form.Label>Valor</Form.Label>
                                        <Controller
                                            control={control}
                                            name="value"
                                            rules={{ required: { value: valorAtual === '', message: "required" }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.value ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    onChange={handleValorChange}
                                                    value={valorAtual}
                                                    //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        {isCreditNote ? <strong> NC</strong> : <></>}
                                        <ErrorMessage
                                            errors={errors}
                                            name="value"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    {formaPagamento?.possuiOperador === 1 ?
                                    <Form.Group as={Col} md={3} controlId="formGridDesc">
                                        <Form.Label>Operadora</Form.Label>
                                        <Controller
                                            control={control}
                                            name="operator"
                                            rules={{ required: { value: operadora ? false : true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <GetAccountOperatorsFilter propsField={field} propsErrors={errors} propsLabel="Operadora" contaAdquirente={(formaPagamento?.tipo === 1) ? 2 : 1 } setAccountOperator={handleSetOperadora} />
                                            )}

                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="operator"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    : <></> }

                                    {formaPagamento?.possuiMdr === 1 ?
                                    <Form.Group as={Col} md={3} controlId="formGridDesc">
                                        <Form.Label>MDR</Form.Label>
                                        <Controller
                                            control={control}
                                            name="mdr"
                                            rules={{ required: { value: mdr === '', message: "required" }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.mdr ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    onChange={handleMdrChange}
                                                    value={mdr}
                                                    //onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="mdr"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    : <></> }

                                    <Form.Group as={Col} md={3} controlId="formGridDesc">
                                        <Form.Label>Vencimento</Form.Label>
                                        <InputGroup
                                            hasValidation
                                            className=""
                                            size="sm"
                                            placeholder="Quando?"
                                        >
                                            <SingleCalendar setValue={setDateVencimento} />
                                        </InputGroup>
                                    </Form.Group>
                                </Row>
                                <Row className="mb-2">
                                    <Form.Group as={Col} md={12} controlId="formGridCat">
                                        <Form.Label>Observações</Form.Label>
                                        <Controller
                                            control={control}
                                            name="observation"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    as="textarea"
                                                    //aria-invalid={errors?.descricao ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className='justify-content-end mt-4 mx-0'>
                                    <button
                                        className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                                        onClick={closeModal}
                                    >
                                        Cancelar
                                    </button>
                                    <button
                                        className="btn btn-default btn-primary px-4 text-uppercase"
                                        type="submit"
                                    >
                                        Salvar
                                    </button>
                                </Row>
                            </Form>
                        </div>
                    </div>
                </div>

                <Modal
                    className="modal-custom modalAuth"
                    show={modalShow}
                    onHide={() => { setModalShow(false); setModalMessage('') }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Header closeButton>

                        </Modal.Header>
                        <div className='d-flex w-100 h-100 justify-content-center py-5'>
                            <h4>{modalMessage}</h4>
                        </div>
                    </>
                </Modal>
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default SingleNote;