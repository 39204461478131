import React from 'react';

import '../../../App.css';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import EditSuppliersData from '../components/EditSupplier/EditSuppliersData/EditSuppliersData';
import DesktopDefault from '../../../templates/DesktopDefault';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function EditSuppliers() {
  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
            <Breadcrumb title="Editar Fornecedores"/>
            <div className="partners mt-4">
            <div className="d-flex align-items-center">
              <span className='back' onClick={() => { window.location.href = '/suppliers' }}>
                <FontAwesomeIcon
                  icon={["fal", "angle-double-left"]}
                  style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                />
                Voltar</span>
            </div>
              <h2 id="page-title" className="title" style={{color: "#707070"}}>Fornecedores</h2>
              
              {/* Content */}

              <EditSuppliersData  />

              {/* Content */}

            </div>
        </div>
      </DesktopDefault>
    </>
  );
}

export default EditSuppliers;