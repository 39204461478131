import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Dropdown, Form, Modal } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../../../components/Pagination/Pagination";

import { useTranslation } from "react-i18next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "./ResourceScoreListTable.scss";
import { ErrorMessage } from "@hookform/error-message";
import { Controller, useForm } from "react-hook-form";
import api from "../../../../services/api";
import ModalScoreListDetails from "../ModalScoreListDetails/ModalScoreListDetails";

export interface propTable {
  resourceId: any;
  info: any;
  loading: any;
  updateScoreList: any;
}

const ResourceScoreListTable: React.FC<propTable> = ({
  resourceId,
  info,
  loading,
  updateScoreList
}) => {
  const { t } = useTranslation();

  const [modalDetailSelected, setModalDetailSelected] = useState<any>(null);
  const [modalDetailsShow, setModalDetailsShow] = useState<any>(false);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */


  const handleClickShowModalDetails = (row: any) => {
    setModalDetailSelected({
      resourceId: resourceId,
      optionId: row.optionId
    });
    setModalDetailsShow(true);
  }

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell}</span>
      </>
    )
  }

  const columns = [
    { dataField: "description", text: "Erros e Acertos" },
    { dataField: "points", text: "Pontos", formatter: convertValue },
    { dataField: "optionId", text: "Ações", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: "resources",
      text: "Carregando Recursos",
      formatter: loadingFunc,
    },
  ];

  const loadingResources = [
    { resources: "" },
    { resources: "" },
    { resources: "" },
    { resources: "" },
    { resources: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "resources", text: "Recursos" }];

  const notFoundResources = [{ resources: "Nenhuma categoria encontrada" }];
  /* [END] In case of empty table*/

  // formatter de status
  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em análise"
            ? "bg-analise"
            : cell === "Ativo"
              ? "bg-ativo "
              : cell === "Inativo"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  const rowClasses = (row: any, rowIndex: any) => {
    //if (row.status === "Em Análise") {
      return "border-analise";
    //} else if (row.status === "Ativo") {
    //  return "border-ativo";
    //} else {
    //  return "border-inativo";
    //}
  };




  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <button type="button" className="btn btn-default p-0 m-0" style={{width:"20px"}} onClick={(e:any) => handleClickShowModalDetails(row)}><FontAwesomeIcon
            size="sm"
            icon={["fal", "eye"]}
            style={{ color: "#707070", margin: "0px", padding: "0px"}}
          /></button>
          {/* <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg"  icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t('suppliersTable.remove')}</Dropdown.Item> 
              <Dropdown.Item eventKey="2" onClick={() => handleClickShowModalDetails(row)}><FontAwesomeIcon icon={['fal', 'glasses-alt']} />Detalhes</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </>
    );
  }

  if (info !== null) {
    return (
      <>
        <div className="table-default resource-scorelist-table">
          <div className="table-container">
            {/* <div className="table-title">
              <h3 className="">{t("Resources")}</h3>
            </div> */}
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={info}
              hover={true}
              columns={columns}
              striped={true}
              rowClasses={rowClasses}
            />

            {/* modal score details */}
            <Modal
              //className={"modal-confirm loading-modal"}
              show={modalDetailsShow}
              onHide={() => setModalDetailsShow(false)}
              size="xl"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Header className="p-3" closeButton></Modal.Header>
                <ModalScoreListDetails
                  info={modalDetailSelected}
                  setModalShow={setModalDetailsShow}
                  setModalSuccess={setModalSuccess}
                  setModalContent={setModalContent}
                  setResponseText={setResponseText}
                  setLog={setLog}
                />
              </>
            </Modal>    
            {/* END - modal score details */}

            {/* Modal de Success */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => setModalSuccess(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos'>
                  {
                    modalContent !== "loading"
                      ?
                      <>
                        <div>
                          {
                            log === 2 || log === "2"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "exclamation-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              log === 1 || log === "1"
                                ?
                                <FontAwesomeIcon
                                  icon={["fal", "times-circle"]}
                                  size="5x"
                                  className="text-primary"
                                  style={{ fontSize: "7.5em" }}
                                />
                                :
                                log === 0 || log === "0"
                                  ?
                                  <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em" }}
                                  />
                                  :
                                  <FontAwesomeIcon
                                    icon={["fal", "question-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em" }}
                                  />
                          }
                        </div>
                        <div>
                          {responseText}
                        </div>
                        <div className="d-flex justify-content-center">
                          {
                            modalContent === 'success'
                              ?
                              <button
                                onClick={() => { updateScoreList(); setModalSuccess(false);}}
                                className="btn btn-primary mx-2 w-25"
                              >
                                Confirmar
                              </button>
                              :
                              modalContent === 'warning'
                                ?
                                <button
                                  onClick={() => setModalSuccess(false)}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                                :
                                <button
                                  onClick={() => setModalSuccess(false)}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                          }
                        </div>
                      </>
                      :
                      <div className="modal-body">
                        <div className="text-center">
                          <div className="load"></div>
                        </div>
                        <div>
                          <span>Processando</span>
                        </div>
                        <div></div>
                      </div>
                  }
                </Modal.Body>
              </>
            </Modal>
            {/* END - Modal de Success */}
          </div>
        </div>
      </>
    );
  } else if (info === null && loading === true) {
    return (
      <>
        <div className="table-default resource-scorelist-table loading">
          <div className="table-container">

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingResources}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />

          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default resource-scorelist-table loading not-found">
          <div className="table-container">

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundResources}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />

          </div>
        </div>
      </>
    );
  }
};

export default ResourceScoreListTable;
