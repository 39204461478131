import React from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";
import Toolbar from "react-multi-date-picker/plugins/toolbar"

import './Calendar.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal, deleteButton }: any) => {
  if (typeof value === 'object') {
    value = value[0];
  } else {
    value = date;
  }

  setValue(value);
  //value[0] = date;

  return (
    <div style={{ position: 'relative' }}>
      {(value !== '' && deleteButton) && <span style={{ position: 'absolute', color: '#000', top: '13px', right: '15px', cursor: 'pointer' }} onClick={() => { setValue('') }}>
        <FontAwesomeIcon
          icon={'times'}
          size="sm"
          className="multi-search-item-btnicon"
          style={{ fontWeight: 'normal', width: '0.7rem', height: '0.7rem', color: 'gray' }}
        />
      </span>}
      <input
        className="form-control"
        onFocus={openCalendar}
        value={value}
        autoComplete='off'
      //readOnly
      />
    </div>
  )
}

const SingleCalendarWithDefaultValue = ({
  date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, disableFutureDates = false, disablePastDates = false, pastDateToDisable = null, futureDateToDisable = null, deleteButton = false
}: any) => {
  const { t } = useTranslation();
  //const [aux, setAux] = useState<any>(date);

  /* useEffect(()=>{
    setValue('date',aux);
  },[aux]) */

  const minDate: any = pastDateToDisable !== null ? new Date(pastDateToDisable.split('/').reverse().join('-') + ' 12:00:00 GMT') : new Date();
  const maxDate: any = futureDateToDisable !== null ? new Date(futureDateToDisable) : new Date();


  return (
    <DatePicker
      {...field}
      //svalue={new Date(defaultDate)}
      //onChange={(e: any) => {setAux(e.year + "-" + e.month + "-" + e.day) }}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      calendarPosition={`top`}
      maxDate={disableFutureDates === true ? maxDate : ''}
      minDate={disablePastDates !== null ? minDate : ''}
      render={<CustomMultipleInput date={date} setValue={setValue} errors={errors} startTimeLocal={startTimeLocal} deleteButton={deleteButton} />}
    />
  )
}

export default SingleCalendarWithDefaultValue;