import React, { useState } from 'react';

import { Container, Row, Col, Card, Modal } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
import InputMask from "react-input-mask";
import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

import '../../../../assets/sass/accordion.scss';
import GetAccountTypes from '../../../../components/C2Points/GetAccountTypes';
import GetAccountStatus from '../../../../components/C2Points/GetAccountStatus';

import api from '../../../../services/api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

export interface propAccountsForm {
    action: any,
    info: any,
};

function AccountsForm({
    action, info
}: propAccountsForm) {

    const { t } = useTranslation();
    //const [validated, setValidated] = useState(false);
    const [accountType, setAccountType] = useState<any>(1);
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>("");
    const [modalLog, setModalLog] = useState<any>(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            id: info ? info.id : 0,
            nomeFantasia: info ? info.nomeFantasia : '',
            razaoSocial: info ? info.razaoSocial : '',
            cnpj: info && (info.cnpj !== null) ? info.cnpj : '',
            nomeGerente: info && (info.nomeGerente !== null) ? info.nomeGerente : '',
            numeroConta: info && (info.numeroConta !== null) ? info.numeroConta : '',
            agenciaConta: info && (info.agenciaConta !== null) ? info.agenciaConta : '',
            email: info && (info.email !== null) ? info.email : '',
            telefone: info && (info.telefone !== null) ? info.telefone : '',
            tipo: info ? info.tipo : '',
            status: info ? info.status : '1',
            observacao: info ? info.observacao : '',
        },
    });


    const onSubmit = (dataObj: any) => {
        var route = '';
        console.log(dataObj, "objeto da conta");

        if (action === 1) {
            //console.log('insert');
            route = 'InsertAccountAsync';
        } else {
            //console.log('edit');
            route = 'UpdateAccountAsync';
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditAccounts = async () => {
            try {

                const { data } = await api.post(`MerchantAccount/${route}`, dataObj, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;
                    setModalShow(true);
                    setModalMessage(response.texto);
                    setModalLog(0);                                      
                    if (response.log !== 1) {
                        // ok
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-accounts';
                }
            }
        }
        EditAccounts();
    };

    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('accounts.accountsform.name')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="nomeFantasia"
                                            rules={{ required: { value: true, message: 'Por favor, informe o nome' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.nomeFantasia ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="nomeFantasia"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('accounts.accountsform.companyName')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="razaoSocial"
                                            rules={{ required: { value: true, message: 'Por favor, informe a Razão Social' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.razaoSocial ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="razaoSocial"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('accounts.accountsform.companyId')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="cnpj"
                                            rules={{ required: { value: accountType !== '4', message: 'Por favor, informe o cnpj' } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="99.999.999/9999-99" value={field?.value} onChange={field?.onChange}>
                                                    {(field: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            aria-invalid={errors?.cnpj ? "true" : ""}
                                                            label={t('accounts.accountsform.companyId')}
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cnpj"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('accounts.accountsform.managerName')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="nomeGerente"
                                            rules={{ required: { value: accountType !== '4', message: 'Por favor, informe o gerente' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.nomeGerente ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="nomeGerente"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('accounts.accountsform.email')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="email"
                                            rules={{ required: { value: accountType !== '4', message: 'Por favor, informe o email' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.email ? "true" : ""}
                                                    type="email"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="email"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('accounts.accountsform.phone')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="telefone"
                                            rules={{ required: { value: accountType !== '4', message: 'Por favor, informe o telefone' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.telefone ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="telefone"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="2" controlId="">
                                        <Form.Label>{t('accounts.accountsform.accountNumber')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="numeroConta"
                                            rules={{ required: { value: accountType !== '4', message: 'Por favor, informe o numero conta' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.numeroConta ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="numeroConta"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2" controlId="">
                                        <Form.Label>{t('accounts.accountsform.agencyNumber')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="agenciaConta"
                                            rules={{ required: { value: accountType !== '4', message: 'Por favor, informe o numero agencia' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.agenciaConta ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="agenciaConta"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2" controlId="">
                                        <Form.Label>{t('accounts.accountsform.type')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="tipo"
                                            rules={{ required: { value: true, message: 'Por favor, informe o tipo da conta' } }}
                                            render={({ field }: any) => (
                                                <GetAccountTypes propsField={field} propsErrors={errors} propsLabel={t('accounts.accountsform.type')} setAccountType={setAccountType} />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="tipo"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2" controlId="">
                                        <Form.Label>{t('accounts.accountsform.status')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="status"
                                            rules={{ required: { value: true, message: 'Por favor, informe o status' } }}
                                            render={({ field }: any) => (
                                                <GetAccountStatus propsField={field} propsErrors={errors} propsLabel={t('accounts.accountsform.status')} />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="status"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="5" controlId="formGridObs">
                                        <Form.Label>Observação</Form.Label>
                                        <Controller
                                            control={control}
                                            name="observacao"

                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.observacao ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    as="textarea"
                                                    rows={4}
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="observacao"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    <Button type="submit" className="form-button">{t('accounts.accountsform.buttonSave')}</Button>
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>

            <Modal
                            className="modalAuth modal-validation"
                            show={modalShow}
                            onHide={() => {
                                if(modalLog === 0) window.location.href = '/accounts';
                                setModalLog(null);
                                setModalMessage("Carregando");
                                setModalShow(false);
                            }}
                            aria-labelledby="contained-modal-title-vcenter"
                            centered
                        >
                            <>
                                <Modal.Body
                                    className="modal-body text-center sucess-pos modal-default d-flex flex-column justify-content-evenly px-4"
                                >
                                    {modalLog === null ? (
                                        <>
                                            <div className="loading-modal modalPayment">
                                                <div className="load" style={{bottom: '0'}}></div>
                                            </div>
                                        </>
                                    ) : (
                                        <div>
                                            {modalLog === 0 ? (
                                                <FontAwesomeIcon
                                                    icon={["fal", "check"]}
                                                    size="5x"
                                                />
                                            ) : (
                                                <FontAwesomeIcon
                                                    icon={["fal", "times"]}
                                                    size="5x"
                                                />
                                            )}
                                        </div>
                                    )}
                                    <div> 
                                        {/* <h5 className="mb-1">{modalLog === 1 ? `${t("newAccount.valError")}` : modalLog === 0 ? `${t("newAccount.success")}` : `${t("newAccount.waitingVal")}`}</h5> */}
                                        <h5 className="my-5" style={{ color: "#707070" }}>{modalMessage}</h5>
                                    </div>
                                    <div className="d-flex justify-content-center pt-3">
                                        <button
                                            onClick={() => {
                                                if(modalLog === 0) window.location.href = '/accounts';
                                                setModalLog(null);
                                                setModalMessage("Carregando");
                                                setModalShow(false);
                                            }}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            {"Confirmar"}
                                        </button>
                                    </div>
                                </Modal.Body>
                            </>
                        </Modal>
        </>
    );
}

export default AccountsForm;