import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
};

const GetUsersList: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
          headers: { 'Authorization': `Bearer ${token}` },
      };
      async function listUsers() {
          try {
              const { data } = await api.post('/Supplier/GetUsersByFilterAsync',
                  {
                      "page": 0,
                      "rowsPerPage": 0,
                      "name": "",
                      "cpf": "",
                      "email": "",
                      "orderbyfield": ""
                  }
                  , config
              );
              if (data.status !== 400) {
                setInfo([{name: 'Selecione', id: 0}, ...data.data.rows]);
              }
          } catch (error: any) {
              console.log(error)
          }
      }
      listUsers();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.accountingAccounts !== undefined ? "endpoint-error" : "input-select-custom endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors?.accountingAccounts ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("accordionFinancial.select.select")}
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.name}`}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetUsersList;