import React from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';
import Accordion from 'react-bootstrap/Accordion';
import Form from 'react-bootstrap/Form';
import { Controller, useForm } from "react-hook-form";
import Button from 'react-bootstrap/Button';

import { ErrorMessage } from '@hookform/error-message';
import { useTranslation } from 'react-i18next';

import '../../../../assets/sass/accordion.scss';
import GetAccountStatus from '../../../../components/C2Points/GetAccountStatus';

import api from '../../../../services/api';

export interface propMerchantFlagsForm {
    action: any,
    info: any,
};

function MerchantFlagsForm({
    action, info
}: propMerchantFlagsForm) {

    const { t } = useTranslation();
    //const [validated, setValidated] = useState(false);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({
        defaultValues: {
            id: info ? info.id : 0,
            descricao: info ? info.descricao : '',
            status: info ? info.status : '1'
        },
    });


    const onSubmit = (dataObj: any) => {
        var route = '';
        //console.log(dataObj, "objeto da conta");

        if(action === 1 || action === "1") {
            //console.log('insert');
            route = 'InsertMerchantFlagAsync';
        } else {
            //console.log('edit');
            route = 'UpdateMerchantFlagAsync';
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditMerchantFlags = async () => {
            try {

                const { data } = await api.post(`MerchantAccount/${route}`, dataObj, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;

                    alert(response.texto);

                    if(response.log !== 1) {
                        // ok
                    }
                }   
            }catch(error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';              
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-merchantflags';              
                }
            }
        }
        EditMerchantFlags();
    };

    return (
        <>
            <Card className="bg-white mt-4">
                <Accordion >
                    <Card.Body>
                        <Container className="p-0 mt-3" fluid>
                            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>{t('merchantFlags.merchantflagsform.description')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="descricao"
                                            rules={{ required: { value: true, message: 'Por favor, informe a descrição' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.descricao ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="descricao"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col} md="2" controlId="">
                                        <Form.Label>{t('merchantFlags.merchantflagsform.status')}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="status"
                                            rules={{ required: { value: true, message: 'Por favor, informe o status' } }}
                                            render={({ field }: any) => (
                                                <GetAccountStatus propsField={field} propsErrors={errors} propsLabel={t('merchantFlags.merchantflagsform.status')} />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="status"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                    <Button type="submit" className="form-button">{t('merchantFlags.merchantflagsform.buttonSave')}</Button>
                                </Row>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
        </>
    );
}

export default MerchantFlagsForm;