import React from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal }: any) => {
  const { t } = useTranslation();

  function convertDate(cell: any) {
    if (cell !== null && cell !== undefined) {
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return date;
    }
  }
  if (!value) {
    value = startTimeLocal;
  }
  setValue(value);
  
  return (
    <input
    className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
    onFocus={openCalendar}
    value={value}
    id={'singleCalendarId'}
    placeholder={startTimeLocal ? convertDate(startTimeLocal) : `${t('editUser.editUser.registerUser.when')}`}
    autoComplete='off'
    />
    )
  }
  
  const SingleCalendar = ({ date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, disableFutureDates = false, disablePastDates = false }: any) => {
    const { t } = useTranslation(); 
    const maxDate: any = new Date();
    
    function convertDate(cell: any) {
      if (cell !== null && cell !== undefined) {
        var aux: any = cell.split("T")[0].split("-");
        var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;  
        return date;
      }
    }

  return (
    <DatePicker
      {...field}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      calendarPosition={`top`}
      maxDate={disableFutureDates === true ? maxDate : ''}
      minDate={disablePastDates === true ? maxDate : ''}
      render={<CustomMultipleInput date={date} setValue={setValue} errors={errors} startTimeLocal={convertDate(startTimeLocal)} />}
    />
  )
}

export default SingleCalendar;