import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-bootstrap/Modal";

import api from '../../../../services/api';

import FormBillPayEdit from "../Forms/FormBillPayEdit/FormBillPayEdit";

//STYLES
import "./TableSessionBillsPay.scss";
import Pagination from "../../../../components/Pagination/Pagination";

import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import ModalResourceVoucherViewer from "../../../../components/Modal/ModalResourceVoucherViewer/ModalResourceVoucherViewer";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface propTable {
  bills: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportReport: any;
  printReport: any;
  sessionReloc: any;
  sessionId: any;
  sessionDate: any;
  refreshData: any;
  monthClosed: any;
  sessionType: any;
  sessionOperationType: any;  
  loading?: any
}

const TableSessionBillsPay: React.FC<propTable> = ({
  bills, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportReport, printReport
  , sessionReloc, sessionId, sessionDate, refreshData, monthClosed, sessionType, sessionOperationType, loading
}: propTable) => {
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])

  const [billEditModalShow, setBillEditModalShow] = useState<any>(false);
  const [voucherViewModalShow, setVoucherViewModalShow] = useState<any>(false);
  const [billDeleteModalShow, setBillDeleteModalShow] = useState<any>(false);
  const [billEdit, setBillEdit] = useState<any>(null);
  const [billVoucher, setBillVoucher] = useState<any>('');
  const [billDelete, setBillDelete] = useState<any>(null);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)
  //console.log(level);

  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }

  const handleEditBillClick = (row: any) => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    async function editar() {
      try {
        const { data } = await api.post('BillsPayable/GetBill',
          {
            "id": row.manifestId,
            "tipo": row.tipo
          }, config
        );

        if (data.status !== 400) {
          // show modal
          setBillEdit(data.data);
          setBillEditModalShow(true);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    editar();
  }

  const handleActionEditBillModal = (data: any) => {
    setBillEdit(null);
    //refreshData();
  }

  const handleVoucherViewClick = (row: any) => {

    async function getVoucher() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const { data } = await api.post('SessionClosure/GetBillVoucherAsync',
                {
                    "manifestId": row.manifestId,
                    //"tipo": row.tipo
                }, config
            );

            if (data.status !== 400) {
              // show modal
              setBillVoucher(data.data);
              setVoucherViewModalShow(true);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }
    getVoucher();
  }

  /* const handleActionVoucherViewModal = (data: any) => {
    setBillVoucher(null);
  } */



  const handleDeleteBillClick = (row: any) => {
    // mostrar dialogo excluir
    setBillDelete(row);
    setBillDeleteModalShow(true);
  }

  const handleActionCancelDeleteClick = (e: any) => {
    setBillDeleteModalShow(false);
    setBillDelete(null);
  }

  const handleActionBillDeleteModal = () => {
    setBillDeleteModalShow(false);

    setModalContent('loading');
    setModalSuccess(true);
    // se confirmado então chamar exclusão na api
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    async function excluir() {
      try {
        const { data } = await api.post('BillsPayable/DeleteBill',
          {
            "id": billDelete.manifestId,
            "tipo": billDelete.tipo,
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );

        if (data.status !== 400) {
          const dados = data.data;

          //alert(dados.texto);
          setResponseText(dados.texto);
          setLog(dados.log);

          if (dados.log !== 1) {
            setBillDeleteModalShow(false);
            setBillDelete(null);
            setModalContent("success");
            //refreshData();
          } else {
            setModalContent("error");
          }
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }

    excluir();
  }

  /* const handleActionCancelModal = () => {
    setBillDeleteModalShow(false);
    setBillDelete(null);
  } */

  const handleNewBillClick = (row: any) => {

    async function clonar() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}` },
      };

      try {
        const { data } = await api.post('BillsPayable/GetBill',
          {
            "id": row.manifestId,
            "tipo": row.tipo
          }, config
        );

        if (data.status !== 400) {
          const dados = data.data;
          // show modal
          dados.contasaPagarId = 0;
          dados.manifestId = 0;
          dados.tipo = 3;

          setBillEdit(dados);
          setBillEditModalShow(true);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }

    // show modal
    if (row) { // se for passado uma linha então ela é replicada no novo registro
      clonar();
    } else { // senão é criado um registro novo em branco
      setBillEdit({ manifestId: 0, tipo: 3 }); // se row não for definido então cria um objeto vazio
      setBillEditModalShow(true);
    }

  }

  function addActionButton(cell: any, row: any) {
    if(monthClosed === false) {
      return (
        <>
          <div className="d-flex justify-content-center">
            <Dropdown drop="start">
              <Dropdown.Toggle variant="light ">
                <FontAwesomeIcon
                  size="lg"
                  icon={["fal", "ellipsis-h"]}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="1" onClick={(e: any) => handleEditBillClick(row)}>
                  <FontAwesomeIcon
                    icon={["fal", "edit"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Editar
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="2" onClick={(e: any) => handleVoucherViewClick(row)} disabled={row.temVoucher !== 1}>
                  <FontAwesomeIcon
                    icon={["fal", "camera"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Visualizar Voucher
                </Dropdown.Item>
                {level === '19' ?
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="3"
                    onClick={(e: any) => handleDeleteBillClick(row)}
                    //disabled={(row.tipo !== 3 && row.statusPagamento !== 1) || (row.tipo === 3 && row.statusPagamento === 5)}
                    disabled={row.statusPagamento === 5}
                  >
                    <FontAwesomeIcon
                      icon={["fal", "times"]}
                      size="1x"
                    //style={{ marginRight: "1px" }}
                    />
                    Remover
                  </Dropdown.Item>
                </>
                :<></>}
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="4"
                  onClick={(e: any) => handleNewBillClick(row)}>
                  <FontAwesomeIcon
                    icon={["fal", "plus-circle"]}
                    size="1x"
                  // style={{ marginRight: "1px" }}
                  />
                  Adicionar Nova
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      );
    } else { 
      return (
        <>
          <div className="d-flex justify-content-center">
            <Dropdown drop="start">
              <Dropdown.Toggle variant="light ">
                <FontAwesomeIcon
                  size="lg"
                  icon={["fal", "ellipsis-h"]}
                />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="1" disabled={true}>
                  <FontAwesomeIcon
                    icon={["fal", "edit"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Editar
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item eventKey="2" onClick={(e: any) => handleVoucherViewClick(row)} disabled={row.temVoucher !== 1}>
                  <FontAwesomeIcon
                    icon={["fal", "camera"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Visualizar Voucher
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="3"
                  disabled={true}
                >
                  <FontAwesomeIcon
                    icon={["fal", "times"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Remover
                </Dropdown.Item>
                <Dropdown.Divider />
                <Dropdown.Item
                  eventKey="4"
                  disabled={true}
                >
                  <FontAwesomeIcon
                    icon={["fal", "plus-circle"]}
                    size="1x"
                  // style={{ marginRight: "1px" }}
                  />
                  Adicionar Nova
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      );
    }
  }

  // /*converte a data UTC para a data local do usuário*/
  // function convertUTCDateToLocalDate(date: any) {
  //   var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

  //   //var offset = date.getTimezoneOffset() / 60;
  //   //var hours = date.getHours();

  //   //newDate.setHours(hours - offset);

  //   return newDate.toLocaleString().split(' ')[0];
  // }

  // function convertDate(cell: any, row: any) {
  //   var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
  //   /* console.log(date) */
  //   return (
  //     <>
  //       {date}
  //     </>
  //   );
  // }

  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return (
        <>
          {date}
        </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addGuideNote(cell: any, row: any) {
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <span className="text-wrap-custom">
            {cell}
          </span>
        </OverlayTrigger>
        {row.supplierApproval === 2 ?
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">{row.denialMessage}</Tooltip>
            }>
            <span className="text-wrap-custom">
              O pagamento foi rejeitado, justificativa: {row.denialMessage}
            </span>
          </OverlayTrigger>
        : <></>}
      </>

    )
  }

  function addNote(cell: any, row: any) {
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <span className="text-wrap-custom">
            {cell}
          </span>
        </OverlayTrigger>
      </>

    )
  }

  function addStatus(cell: any, row: any) {
    return (
      row.supplierApproval === 2 ?
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">Pagamento rejeitado</Tooltip>
          }>
            <span className="text-wrap-custom">
              <FontAwesomeIcon
                icon={["fal", "times"]}
                size="1x"
                color={"red"}
                style={{ marginRight: "1px" }}
              />
            </span>
        </OverlayTrigger>
      : cell !== 1 ?
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">Conciliado</Tooltip>
          }>
            <span className="text-wrap-custom">
              <FontAwesomeIcon
                icon={["fal", "check"]}
                size="1x"
                color={"green"}
                style={{ marginRight: "1px" }}
              />
            </span>
        </OverlayTrigger>
        : <></>
    );
  }

  function addOperatorPaid(cell: any, row: any) {
    return (
      cell === 1 ?
        <FontAwesomeIcon
          icon={["fal", "check"]}
          size="1x"
          style={{ marginRight: "1px" }}
        />
        : <></>
    );
  }

  function addTotalPax(cell: any, row: any) {
    return (
      <div
        className={"quantities"}
      >
        {cell}
        <div className="quantities-container">
          <h6 className="">Quantidades</h6>
          <div className="table">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={row.quantities ? row.quantities : []}
              columns={columnsQuantities}
              //rowClasses={rowClasses}
              striped={true}
            />
          </div>
        </div>
      </div>
    );
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "descricao", text: "Descrição", sort: true },
    { dataField: "contaContabil", text: "Categoria", sort: true },
    { dataField: "fornecedor", text: "Fornecedor", sort: true },
    { dataField: "totalPax", text: "QTD", sort: true, formatter: addTotalPax },
    //{ dataField: "totalAdult", text: "ADT", sort: true },
    //{ dataField: "totalChild", text: "CHD", sort: true },
    //{ dataField: "totalInfant", text: "INF", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "vencimento",
      text: "Vencimento*",
      sort: true,
      formatter: convertDate,
    },

    { dataField: "operatorPaid", text: "Pago Pelo Guia?", formatter: addOperatorPaid },
    {
      dataField: "valorPago",
      text: "Valor Pago",
      sort: true,
      formatter: convertValue,
    },
    { dataField: "observacaoGuia", text: "Obs. do Guia", sort: true, formatter: addGuideNote },
    { dataField: "observacao", text: "Observação*", sort: true, formatter: addNote },
    { dataField: "statusPagamento", text: " ", formatter: addStatus },
    { dataField: "manifestId", text: "Ações", formatter: addActionButton },
  ];

  const columnsQuantities = [
    {
      dataField: "description",
      text: "Descrição",
    },
    {
      dataField: "quantity",
      text: "Qtde.",
    },
    {
      dataField: "value",
      text: "Valor",
      formatter: convertValue,
    },
  ];
  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 5) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (bills !== null && loading === true) {
    return (
      <>
        <div className="table-default session-bills-pay-table">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                {monthClosed === false ?
                <div>
                  <button className="btn btn-bills" onClick={(e: any) => handleNewBillClick(null)}>
                    <FontAwesomeIcon
                      icon={["fal", "plus-circle"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Novo
                  </button>
                </div>
                : <></>}
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={handlePrintClick}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={bills}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            {billEdit !== null ?
              <Modal
                className="modalAuth"
                show={billEditModalShow}
                onHide={() => setBillEditModalShow(false)}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton></Modal.Header>
                  <FormBillPayEdit 
                    action={handleActionEditBillModal} 
                    info={billEdit} 
                    sessionId={sessionId} 
                    sessionDate={sessionDate} 
                    sessionType={sessionType} 
                    show={setModalSuccess}
                    setModalContent={setModalContent}
                    setResponseText={setResponseText}
                    setLog={setLog}
                    sessionOperationType={sessionOperationType}
                  />
                </>
              </Modal> : <></>}

            
            <ModalResourceVoucherViewer 
              voucherViewModalShow={voucherViewModalShow} 
              setVoucherViewModalShow={setVoucherViewModalShow} 
              voucher={billVoucher} 
            />

            <ModalQuestion
              modalShow={billDeleteModalShow}
              setModalShow={setBillDeleteModalShow}
              titulo="Excluir Conta Pagar"
              mensagem="Deseja excluir o Item?"
              handleActionConfirmModal={handleActionBillDeleteModal}
              handleActionCancelModal={handleActionCancelDeleteClick}
            />
            {/* Modal de Success */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => setModalSuccess(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos'>
                  {
                    modalContent !== "loading"
                      ?
                      <>
                        <div>
                          {
                            log === 2 || log === "2"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "exclamation-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              log === 1 || log === "1"
                                ?
                                <FontAwesomeIcon
                                  icon={["fal", "times-circle"]}
                                  size="5x"
                                  className="text-primary"
                                  style={{ fontSize: "7.5em"}}
                                />
                                :
                                log === 0 || log === "0"
                                  ?
                                  <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                                  :
                                  <FontAwesomeIcon
                                    icon={["fal", "question-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                          }
                        </div>
                        <div>
                          {responseText}
                        </div>
                        <div className="d-flex justify-content-center">
                          {
                            modalContent === 'success'
                              ?
                              <button
                                onClick={() => {refreshData(); setModalSuccess(false);}}
                                className="btn btn-primary mx-2 w-25"
                              >
                                Confirmar
                              </button>
                              :
                              <button
                                onClick={() => setModalSuccess(false)}
                                className="btn btn-primary mx-2 w-25"
                              >
                                Confirmar
                              </button>
                          }
                        </div>
                      </>
                      :
                      <div className="modal-body">
                        <div className="text-center">
                          <div className="load"></div>
                        </div>
                        <div>
                          <span>Processando</span>
                        </div>
                        <div></div>
                      </div>
                  }
                </Modal.Body>
              </>
            </Modal>
            {/* END - Modal de Success */}
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default session-bills-pay-table">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionBillsPay;
