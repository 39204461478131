import React, { useEffect, useState} from "react";

//COMPONENTES
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import InvoicedSalesCards from "./components/InvoicedSalesCards/InvoicedSalesCards";
import InvoicedSalesFilter from "./components/InvoicedSalesFilter/InvoicedSalesFilter";
import InvoicedSalesTable from "./components/InvoicedSalesTable/InvoicedSalesTable";
import { useTranslation } from "react-i18next";

function InvoicedSales(props: any) {
  const { t } = useTranslation();
  const [invoicedSales, setInvoicedSales] = useState<any>(null);
  const [cardsInfo, setCardsInfo] = useState<any>(null);

  //loading
  const [loading, setLoading] = useState<any>(false);

  //filtragem
  const [actualName, setActualName] = useState<any>("");
  const [actualDate, setActualDate] = useState<any>([]);
  const [actualStatus, setActualStatus] = useState<any>(null);
  const [, setActualTourcodeOrNot] = useState<any>(1);
  //fim da filtragem

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [allowSearch, setAllowSearch] = useState<any>(1);
  /* END - Pagination */

  const [loadButton, setLoadButton] = useState<any>([false, false, false])

  /* function clearActualValues() {
    setActualName("");
    setActualStatus(0);
    setActualDate([]);
  }
 */
  useEffect(() => {
    var today: any = new Date();

    /* buscar('', 0, 1, [`${today.getDate()}/1/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`]); */
    buscar('', 0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function buscar(name: any, status: any, tourcodeOrNot: any, date: any) {    
    setInvoicedSales(null);
    setLoading(true);
    setActualName(name);
    setActualDate(date);
    setActualStatus(status);
    setActualTourcodeOrNot(tourcodeOrNot);

    if (pageCount !== 1) {
      setPageCount(1);
    } else {
      setAllowSearch(allowSearch + 1);
    }
  }

  useEffect(() => {
    async function listInvoicedSales() {
      setInvoicedSales(null);
      setLoading(true);
      let beginDate = []
      let endDate = []

      if (actualDate.length === 1) {
        beginDate = actualDate[0].split('/')
      } else if (actualDate?.length === 2) {
        beginDate = actualDate[0].split('/')
        endDate = actualDate[1].split('/')
      }

      let today: any = new Date();

      const token = localStorage.getItem("GroupId") || "{}";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const { data } = await api.post(
          "/AgentStatement/GetSalesAgentByFilterAsync",
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            name: actualName,
            status: actualStatus === '' ? 2 : parseInt(actualStatus),
            beginDate: (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
            endDate: (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
            orderByField: "",
          },
          config
        );

        if (data.status !== 400) {
          if(data.rows.length !== 0){
            setLoading(false);
          setInvoicedSales(data.data.rows);
          setCardsInfo(data.data);
          setTotalRows(data.data.rowsCount);
        }
        }
      } catch (error: any) {
        setLoading(false);
      }
    }
    if (actualStatus !== null) {
      listInvoicedSales();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage, allowSearch]);

  async function exportInvoicedSales() {

    let beginDate = []
    let endDate = []

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    let today: any = new Date();

    setLoadButton([false, true, false]);

    const token = localStorage.getItem("GroupId") || "{}";

    try {
      const exp = await api.post('/AgentStatement/GetSalesAgentByFilterExcelAsync', 
        {
          page: pageCount,
          rowsPerPage: rowsPerPage,
          name: actualName,
          status: actualStatus === '' ? 2 : parseInt(actualStatus),
          beginDate: (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          endDate: (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          orderByField: "",
          supplierId: 0,
        },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "*/*"
          },
          responseType: 'blob'
        },
      );
      
      if (exp.status !== 400) {
        setLoadButton([false, false, false]);

        const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute(
          'download',
          'vendas_faturadas.xlsx',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      }
    } catch (error: any) {
      //setError(true)
      if (error?.response?.status === 401) {
        //window.location.href = window.location.origin + '/';
      }
    }
  };

  async function printInvoicedSales() {

    setLoadButton([true, false, false]);

    let beginDate = []
    let endDate = []

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    let today: any = new Date();

    const token = localStorage.getItem("GroupId") || "{}";

    try {
      const print = await api.post('/AgentStatement/GetSalesAgentByFilterPDFAsync',
        {
          page: pageCount,
          rowsPerPage: rowsPerPage,
          name: actualName,
          status: actualStatus === '' ? 2 : parseInt(actualStatus),
          beginDate: (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          endDate: (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          orderByField: "",
          supplierId: 0,
        },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "*/*"
            //"Access-Control-Allow-Origin": "*"

          },
          responseType: 'blob'
        },

      );
      if (print.status !== 400) {
        setLoadButton([false, false, false]);
        const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute(
          'download',
          'vendas_faturadas.pdf',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);


      }
    } catch (error: any) {
      //(true)
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  };

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={t("invoiced.title")} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
            {t("invoiced.title")}
            </h2>
          </div>

          <InvoicedSalesCards cardsInfo={cardsInfo} t={t}/>

          <InvoicedSalesFilter
            buscar={buscar}
            t={t}
          />

          <InvoicedSalesTable
            loading={loading}
            invoicedSales={invoicedSales}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            exportInvoicedSales={exportInvoicedSales}
            printInvoicedSales={printInvoicedSales}
            loadButton={loadButton}
            t={t}
          />
        </div>
      </DesktopDefault>
    </>
  );
}

export default InvoicedSales;
