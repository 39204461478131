/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import "./DaysHours.scss"
import { Button, Col, Dropdown, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, get, useForm } from "react-hook-form";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import BootstrapTable from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import OnlyTime from "../../../../components/Calendar/OnlyTime";
import GetTime30MinInterval from "../../../../components/C2Points/GetTime30MinInterval";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any,
    isEdit: any
};

const DaysHours: React.FC<productTransfer> = React.memo(({ action, transferObject, isEdit, receiveObject
}: productTransfer) => {

    const [data, setData] = useState<any>([]);
    const [daysHoursTb, setDaysHoursTb] = useState<any>([]);
    const [schedules, setSchedules] = useState<number>(0);
    const [date, setDate] = useState<any>();
    const [startTime, setStartTime] = useState<any>();
    const [endTime, setEndTime] = useState<any>();

    const [textError, setTextError] = useState<any>('');

    const validated = false;

    const weekDays = ['seg', 'ter', 'qua', 'qui', 'sex', 'sab', 'dom'];

    const getChoiceSchedules = (num: number) => {
        // console.log(num);
        setSchedules(num);
    }

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({});
    // console.log(isEdit)

    useEffect(() => {
        let aux: any = [];

        if (transferObject.dayHours !== undefined) {

            transferObject?.dayHours?.forEach((elem: any) => {
                // console.log(elem)
                let leave: any;

                if ((`${elem.saidaRegular}`).includes(",")) {
                    leave = (`${elem.saidaRegular}`).replaceAll(",", " - ");
                } else {
                    leave = `${elem.saidaRegular}`;
                }

                aux.push({
                    days: elem.days,
                    hourType: elem.hourType,
                    hours: elem.hourType === 1 ? `${elem.autonomoHoraInicio} - ${elem.autonomoHoraFim}` : elem.hourType === 2 ? "Atendimento 24h" : leave,
                    id: elem.resourcesId
                })
            })

            setSchedules(transferObject.dayHours[0].hourType)
            setDaysHoursTb(aux)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // console.log(daysHoursTb)

    
    const onSubmit = (data: any) => {
        if (daysHoursTb.length === 0) {
            setTextError("Defina ao menos um dia e horário para o serviço");

            setTimeout(() => {
                setTextError('');
            }, 2000);
        } else {
            var aux: any = [];
            daysHoursTb.forEach((elem: any) => {
                
                aux.push({
                    type: 0,
                    typeString: "",
                    resourcesId: elem.id,
                    seg: (elem.days?.includes("seg") === true ? 1 : 0),
                    ter: (elem.days?.includes("ter") === true ? 1 : 0),
                    quar: (elem.days?.includes("qua") === true ? 1 : 0),
                    quin: (elem.days?.includes("qui") === true ? 1 : 0),
                    sex: (elem.days?.includes("sex") === true ? 1 : 0),
                    sab: (elem.days?.includes("sab") === true ? 1 : 0),
                    dom: (elem.days?.includes("dom") === true ? 1 : 0),
                    hourType: elem.hourType,
                    autonomoHoraInicio: (elem.hourType === 1 ? elem.hours.split(" - ")[0] : "2022-06-27T17:09:36.782Z"),
                    autonomoHoraFim: (elem.hourType === 1 ? elem.hours.split(" - ")[1] : "2022-06-27T17:09:36.782Z"),
                    saidaRegular: (elem.hourType === 3 ? elem.hours.split(" - ") : []),
                    listHours: (elem.hourType === 2 ? ["2022-06-27T17:09:36.782Z"] : []),
                    days: elem.days
                })
            })

            data.dayHours = { dayHours: aux };

            if (isEdit !== true) {
                action('features', data.dayHours);
            } else {
                receiveObject(data.dayHours);
            }
            console.log(data);
        }

    }

    useEffect(() => {
        // console.log(daysHoursTb);
    }, [daysHoursTb])

    const removeDaysHoursTb = (cell: any) => {
        var aux: any = [];
        daysHoursTb.forEach((element: any) => {
            if (element.id !== cell) {
                aux.push(element);
            }
        });
        setDaysHoursTb(aux);
    }

    const AddToTable = () => {
        const obj = getValues();
        let error: any = true;
        for (let i = 0; i < weekDays.length; i++) {
            if (obj[weekDays[i]] === true) {
                error = false
            }
        }
        if (schedules === 0 || error === true) {
            setTextError("Por favor, selecione os dias e horário de funcionamento");

            setTimeout(() => {
                setTextError('');
            }, 2000);

        } else {
            let aux: any = daysHoursTb;
            let daysSelected: any = ''
            let hoursSelected: any = ''

            for (let i = 0; i < weekDays.length; i++) {
                if (obj[weekDays[i]] === true) {
                    daysSelected = daysSelected + ', ' + weekDays[i]
                }
            }

            if (schedules === 1) {
                obj.startTime = startTime;
                obj.endTime = endTime;

                setDaysHoursTb((prevState: any) => [...prevState, { days: daysSelected.replace(', ', ''), hours: `${obj.startTime} - ${obj.endTime}`, id: aux.length + 1, hourType: schedules }]);

            } else if (schedules === 2) {
                setDaysHoursTb((prevState: any) => [...prevState, { days: daysSelected.replace(', ', ''), hours: "Atendimento 24h", id: aux.length + 1, hourType: schedules }]);
            } else if (schedules === 3) {

                for (let i = 0; i < obj.departureTime.length; i++) {
                    hoursSelected = hoursSelected + ' - ' + obj.departureTime[i].value;
                }

                setDaysHoursTb((prevState: any) => [...prevState, { days: daysSelected.replace(', ', ''), hours: `${hoursSelected.replace(' - ', '')}`, id: aux.length + 1, hourType: schedules }]);

            }

            for (let i = 0; i < weekDays.length; i++) {
                setValue(weekDays[i], false);
            }

            setSchedules(0);
            closeModal();
        }
    }

    const unmarkDaysHours = () => {
        setSchedules(0);
    }

    const columns = [
        {
            dataField: "days",
            text: "Dias",
        },
        {
            dataField: "hours",
            text: "Horários",
        },
        {
            dataField: "id",
            text: "Ações",
            formatter: addActionButton
        }
    ]

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "ellipsis-h"]}
                            />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                //disabled={false}
                                onClick={() => removeDaysHoursTb(cell)}
                            >
                                <FontAwesomeIcon
                                    icon={['fal', 'trash-alt']}
                                />
                                Remover
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>

                    <div className='card h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Dias e Horas"}</h3>
                        </div>

                        {/* <Row className="my-3">
                    <Col md={'12'}>
                        <div className="alert alert-primary background-primary p-3">                      
                        <small className="text-primary">Os cartazes que você utiliza para receber seus passageiros não poderão mais ter o logo da C2Rio. Lembre-se que você deve continuar utilizando o logo da sua empresa junto com o nome e o sobrenome do passageiro titular da reserva.</small>
                        </div>
                    </Col>
                </Row> */}

                        <section className="my-3">
                            <Row className="column-chooice add-product">
                                <Col md="12">
                                    <div className="label-small">
                                        Defina os dias e horários que oferecerá o serviço.
                                    </div>

                                    <div className="d-flex align-items-center w-auto mt-2">
                                        <ModalDefault
                                            title="Adicionar novos dias e horas"
                                            name=" + Adicionar Dias e Horários "
                                            icon="no"
                                            typeIcon="plus-circle"
                                            classBtn="btn btn-outline-primary"
                                            classModal="modal-large"
                                        >

                                            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                                <div className='d-flex flex-column justify-content-between h-100 add-product'>
                                                    <div>
                                                        <section className="mt-2">
                                                            <div className="d-flex">
                                                                <div className="label-small mb-3">
                                                                    Dias de Funcionamento
                                                                </div>

                                                                <div className="d-flex mx-3">
                                                                    <small style={{ color: "red" }}>{textError}</small>
                                                                </div>
                                                            </div>

                                                            <Row className="line-chooice">
                                                                <div className="d-flex w-auto">
                                                                    <Controller
                                                                        control={control}
                                                                        name="seg"
                                                                        render={({ field }: any) => (
                                                                            <Form.Check
                                                                                {...field}
                                                                                type="checkbox"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                className="mx-2"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <Form.Label className="label-small">
                                                                        Seg
                                                                    </Form.Label>
                                                                </div>

                                                                <div className="d-flex w-auto">
                                                                    <Controller
                                                                        control={control}
                                                                        name="ter"
                                                                        render={({ field }: any) => (
                                                                            <Form.Check
                                                                                {...field}
                                                                                type="checkbox"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                className="mx-2"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <Form.Label className="label-small">
                                                                        Ter
                                                                    </Form.Label>
                                                                </div>

                                                                <div className="d-flex w-auto">
                                                                    <Controller
                                                                        control={control}
                                                                        name="qua"
                                                                        render={({ field }: any) => (
                                                                            <Form.Check
                                                                                {...field}
                                                                                type="checkbox"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                className="mx-2"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <Form.Label className="label-small">
                                                                        Qua
                                                                    </Form.Label>
                                                                </div>

                                                                <div className="d-flex w-auto">
                                                                    <Controller
                                                                        control={control}
                                                                        name="qui"
                                                                        render={({ field }: any) => (
                                                                            <Form.Check
                                                                                {...field}
                                                                                type="checkbox"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                className="mx-2"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <Form.Label className="label-small">
                                                                        Qui
                                                                    </Form.Label>
                                                                </div>

                                                                <div className="d-flex w-auto">
                                                                    <Controller
                                                                        control={control}
                                                                        name="sex"
                                                                        render={({ field }: any) => (
                                                                            <Form.Check
                                                                                {...field}
                                                                                type="checkbox"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                className="mx-2"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <Form.Label className="label-small">
                                                                        Sex
                                                                    </Form.Label>
                                                                </div>

                                                                <div className="d-flex w-auto">
                                                                    <Controller
                                                                        control={control}
                                                                        name="sab"
                                                                        render={({ field }: any) => (
                                                                            <Form.Check
                                                                                {...field}
                                                                                type="checkbox"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                className="mx-2"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <Form.Label className="label-small">
                                                                        Sab
                                                                    </Form.Label>
                                                                </div>

                                                                <div className="d-flex w-auto">
                                                                    <Controller
                                                                        control={control}
                                                                        name="dom"
                                                                        render={({ field }: any) => (
                                                                            <Form.Check
                                                                                {...field}
                                                                                type="checkbox"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                required
                                                                                className="mx-2"
                                                                            />
                                                                        )}
                                                                    />
                                                                    <Form.Label className="label-small">
                                                                        Dom
                                                                    </Form.Label>
                                                                </div>
                                                            </Row>
                                                            <Row className="mb-3 mt-3">
                                                                <div className="label-small mb-3">
                                                                    Horários
                                                                </div>

                                                                <Row className="line-chooice">
                                                                    <div className="d-flex w-auto">
                                                                        <Controller
                                                                            control={control}
                                                                            name="isDefaultUser"
                                                                            render={({ field }: any) => (
                                                                                <Form.Check
                                                                                    {...field}
                                                                                    type="radio"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    required
                                                                                    className="mx-2"
                                                                                    onClick={() => getChoiceSchedules(1)}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Form.Label className="label-small">
                                                                            Autônomo
                                                                        </Form.Label>
                                                                    </div>

                                                                    <div className="d-flex w-auto">
                                                                        <Controller
                                                                            control={control}
                                                                            name="isDefaultUser"
                                                                            render={({ field }: any) => (
                                                                                <Form.Check
                                                                                    {...field}
                                                                                    type="radio"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    required
                                                                                    className="mx-2"
                                                                                    onClick={() => getChoiceSchedules(2)}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Form.Label className="label-small">
                                                                            Atendimento 24h
                                                                        </Form.Label>
                                                                    </div>

                                                                    <div className="d-flex w-auto">
                                                                        <Controller
                                                                            control={control}
                                                                            name="isDefaultUser"
                                                                            render={({ field }: any) => (
                                                                                <Form.Check
                                                                                    {...field}
                                                                                    type="radio"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    required
                                                                                    className="mx-2"
                                                                                    onClick={() => getChoiceSchedules(3)}
                                                                                />
                                                                            )}
                                                                        />
                                                                        <Form.Label className="label-small">
                                                                            Saída Regular
                                                                        </Form.Label>
                                                                    </div>
                                                                </Row>
                                                            </Row>
                                                            <Row>
                                                                {schedules === 1 ?
                                                                    <>
                                                                        <Col lg={4} md={4}>
                                                                            <Controller
                                                                                control={control}
                                                                                name="startTime"
                                                                                render={({ field }: any) => (
                                                                                    <InputGroup
                                                                                        hasValidation
                                                                                        className=""
                                                                                        size="sm"
                                                                                    >
                                                                                        <OnlyTime
                                                                                            setTime={setStartTime}
                                                                                        />
                                                                                    </InputGroup>
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                        <Col lg={4} md={4}>
                                                                            <Controller
                                                                                control={control}
                                                                                name="endTime"
                                                                                render={({ field }: any) => (
                                                                                    <InputGroup
                                                                                        hasValidation
                                                                                        className=""
                                                                                        size="sm"
                                                                                    >
                                                                                        <OnlyTime
                                                                                            setTime={setEndTime}
                                                                                        />
                                                                                    </InputGroup>
                                                                                )}
                                                                            />
                                                                        </Col>
                                                                    </>
                                                                    :
                                                                    schedules === 3 ?
                                                                        <>
                                                                            <Col lg={6} md={6} >
                                                                                <Controller
                                                                                    control={control}
                                                                                    name="departureTime"
                                                                                    render={({ field }: any) => (
                                                                                        <div className="input-text-custom endpoint">
                                                                                            <GetTime30MinInterval propsField={field} propsLabel="channel" />
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                            </Col>
                                                                        </>
                                                                        :
                                                                        <></>}
                                                            </Row>

                                                            <div className="d-flex justify-content-end align-items-end ">
                                                                 {
                                                                    isEdit !== true
                                                                        ?
                                                                        <Row className=" mx-0 mt-5">
                                                                            <Button
                                                                                variant="outline-primary"
                                                                                className="form-button  mr-3"
                                                                                onClick={() => { unmarkDaysHours(); closeModal(); }}
                                                                            >
                                                                                Cancelar
                                                                            </Button>
                                                                            <Button
                                                                                type="button"
                                                                                className="form-button"
                                                                                onClick={() => AddToTable()}
                                                                            >
                                                                                Salvar
                                                                            </Button>
                                                                        </Row>
                                                                         :
                                                                        <Row className=" mx-0 mt-5">
                                                                            <Button
                                                                                // type="submit"
                                                                                className="form-button"
                                                                                onClick={() => AddToTable()}
                                                                            //onClick={() => action('meetingPoint')}
                                                                            >
                                                                                Salvar
                                                                            </Button>
                                                                        </Row>
                                                                } 
                                                            </div>
                                                        </section>
                                                    </div>
                                                </div>
                                            </Form>
                                        </ModalDefault>


                                        <div className="d-flex mx-3">
                                            <small style={{ color: "red" }}>{textError}</small>
                                        </div>
                                    </div>

                                </Col>



                            </Row>
                        </section>

                        <section className="my-3">
                            <Row>
                                <Col md="12">
                                    <div className="label-small">
                                        Dias e Horários
                                    </div>

                                    <div className="mt-4">
                                        <div className="table-default daysHours-table">
                                            <div className="table-container">
                                                <BootstrapTable
                                                    bootstrap4
                                                    keyField="id"
                                                    data={daysHoursTb}
                                                    columns={columns}
                                                    rowClasses={'border-analise'}
                                                    striped={true}
                                                />
                                            </div>
                                        </div>
                                    </div>


                                </Col>


                            </Row>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true ?
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            variant="outline-primary"
                                            className="form-button mr-3"
                                            onClick={() => action('tariffs')}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Continuar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </section>
                    </div>
                </Form>
            </Col>

        </>
    )
});

export default DaysHours;