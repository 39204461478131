import React, { useState, useEffect, Key } from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import ModalDefault from "../../components/Modal/ModalDefault/ModalDefault";

import api from "../../../../services/api";

import 'react-input-range/lib/css/index.css';


/* Styles from Page */
import "./Transfers.scss"
import FilterTransfer from "../../../../components/Filter/FilterTransfer/FilterTransfer";
/* Styles from Page */

import { useAppDispatch } from '../../../../store/hooks';
import { addItemCart } from "../../../../store/cart";
import Pagination from "../../../../components/Pagination/Pagination";

function Transfers(props: any) {
    const [t] = useTranslation();
    const [open, setOpen] = useState(false);
    const [transfers, setTransfers] = useState<any>(null);
    const [pagination, setPagination] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    const [totalRows, setTotalRows] = useState<any>(0);

    const [loading, setLoading] = useState<any>(false);

    const transferSIG: any = localStorage.getItem("transferSIG");
    const transferItemJSON = JSON.parse(transferSIG);

    const [select, setSelect] = useState<any>();

    function ScrollTop() {
        window.scrollTo(0, 0);
    }

    useEffect(()=>{
        setPagination(1);
    },[rowsPerPage])

    function keepDropdownOpen() {
        var elem: any = document.getElementById("authCartDropdown");

        if (elem && !elem.classList.contains('active')) {
            elem.classList.add('active');
        }
    }

    const dispatch = useAppDispatch();

    useEffect(() => {
        const TransfersRender = async () => {
            try {
                const res = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchTransfersAsync`,
                    {
                        "lang": "BR",
                        "dateTransfer": `${props.search.date.split('/').reverse().join('-')}`,
                        "timeTransfer": `${props.search.time}`,
                        "numPeople": `${props.search.numPeople}`,
                        "origemLng": `${props.search.lngOrigem}`,
                        "origemLat": `${props.search.latOrigem}`,
                        "destinoLng": `${props.search.lngDestino}`,
                        "destinoLat": `${props.search.latDestino}`
                    }
                );
                if (res.status !== 400) {
                    if (res.data.data.transferList.length > 0) {
                        setTransfers(res.data.data.transferList);
                        setPagination(1);
                        setRowsPerPage(10);
                        setTotalRows(res.data.data.transferList.length);
                    }
                }
            } catch (error) { }
        };
        TransfersRender();
    }, [props.search]);

    const auth = (list: any, index: any) => {

        setSelect(transfers[index]);
        setLoading(true);

        setTimeout(() => {
            setLoading(false);
            ScrollTop();
            keepDropdownOpen();
        }, 750);

        //console.log(list, transferItemJSON)

        const item = {
            productName: list.transferVehicleType + ' ' + list.vehicleCategoryDetails.category + ' ' + list.transferServiceType,
            imgCart: list.vehicleCategoryDetails.categoryImagePath + (
                list.transferVehicleType === t("sales.transfers.minivan")
                    ?
                    "minivan-transfer.webp"
                    :
                    list.transferVehicleType === t("sales.transfers.van")
                        ?
                        list.vehicleCategoryDetails.vanImageName
                        :
                        list.transferVehicleType === t("sales.transfers.bus")
                            ?
                            list.vehicleCategoryDetails.busImageName
                            :
                            list.transferVehicleType === t("sales.transfers.car")
                                ?
                                list.vehicleCategoryDetails.carImageName
                                :
                                list.transferVehicleType === t("sales.transfers.microBus")
                                    ?
                                    list.vehicleCategoryDetails.microBusImageName
                                    : ""
            ),
            price: Number(list.preco),

            idTarif: list.idTaxa,

            priceAdults: 0,
            priceChilds: 0,
            priceInfants: 0,
            priceElders: 0,
            priceStudent: 0,
            priceGlobalPeople: Number(list.preco),

            sellingType: list.idSellingType,
            adults: 0,
            childs: 0,
            infants: 0,
            elders: 0,
            student: 0,
            globalPeople: list.idSellingType === 1 ? 1 : transferItemJSON.numPeople, //transferItemJSON.numPeople,
            totalPeople: list.idSellingType === 1 ? transferItemJSON.numPeople : undefined,
            productType: 2,
            productCode: list.productCode,
            time: transferItemJSON.time,
            date: transferItemJSON.date.split('/').reverse().join('-'),
            supplier: list.supplierIdentity,
            supplierFantasyName: list.supplierFantasyName,
            typePickup: 1,
            pickup: 0,

            discount: 0,
            customValueNet: 0,
            customValueSell: 0,
            goingSource: transferItemJSON.destine,
            commingSource: transferItemJSON.origin,
            latOrigem: transferItemJSON.latOrigem,
            lngOrigem: transferItemJSON.lngOrigem,
            latDestino: transferItemJSON.latDestino,
            lngDestino: transferItemJSON.lngDestino,
            cia: " ",
            voo: " ",
            smallSuitcase: list.handLuggage,
            bigSuitcase: list.baggagePerPerson,
            internalNotes: " ",
            idVeiculo: list.minMaxPeople.idTransferVehicles,
            passengers: []

        }

        dispatch(addItemCart(item));
    }

    if (open === true) {
        document.querySelector("#filter-transfer")?.classList.add("filter-modal");
        document.getElementById("shadow")?.classList.add("d-block");
        /* document.querySelector("body")?.classList.add("hidden"); */

    } else {
        document.querySelector("#filter-transfer")?.classList.remove("filter-modal");
        document.getElementById("shadow")?.classList.remove("d-block");
        /*  document.querySelector("body")?.classList.remove("hidden"); */
    }

    const ListTransfers = () => {
        if (transfers !== null && props.search !== null) {
            return (
                <>
                    {transfers.length > 0 ? transfers.slice(((pagination - 1) * rowsPerPage), ((pagination) * rowsPerPage)).map((list: any, index: Key) => {                      
                        return (
                          <div key={index} className="mb-4">
                            <div className="card">
                              <div className="row m-0">
                                <div className="bg-img-transfer col-12 col-md-3 p-0 p-md-4">
                                  <span
                                    className="badge rounded position-absolute badge-primary py-2 px-3 text-uppercase"
                                    style={{
                                      display: "flex",
                                      alignItems: "center",
                                    }}
                                  >
                                    <FontAwesomeIcon
                                      key={index}
                                      icon={["fal", "car"]}
                                      size="2x"
                                      style={{
                                        margin: "0px 5px 0 0px",
                                        fontSize: "1em",
                                      }}
                                    />
                                    {list.sellingType === "PRIVATIVO" ? t("transfer.priceVeihicle") : t("sales.transfers.pricePerPerson") }
                                  </span>
                                  <div
                                    className="bg-vehicles"
                                    style={{
                                      background: `url(${
                                        process.env.REACT_APP_SERVER_URL_IMG
                                      }/${
                                        list.vehicleCategoryDetails
                                          .categoryImagePath
                                      }${
                                        list.transferVehicleType === t("sales.transfers.minivan")
                                          ? "minivan-transfer.webp"
                                          : list.transferVehicleType === t("sales.transfers.van")
                                          ? list.vehicleCategoryDetails
                                              .vanImageName
                                          : list.transferVehicleType ===
                                            t("sales.transfers.bus")
                                          ? list.vehicleCategoryDetails
                                              .busImageName
                                          : list.transferVehicleType === t("sales.transfers.car")
                                          ? list.vehicleCategoryDetails
                                              .carImageName
                                          : list.transferVehicleType ===
                                            t("sales.transfers.microBus")
                                          ? list.vehicleCategoryDetails
                                              .microBusImageName
                                          : ""
                                      })`,
                                    }}
                                  ></div>
                                </div>
                                <div className="col-12 col-md-6 p-3 text-transfer">
                                  <div>
                                    <h3>{`${list.transferVehicleType} ${list.vehicleCategoryDetails.category} ${list.transferServiceType}`}</h3>
                                  </div>
                                  <div>
                                    <small style={{ color: "#848289" }}>
                                      <FontAwesomeIcon
                                        icon={["fal", "clock"]}
                                        size="1x"
                                        style={{
                                          fontSize: "14px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      {t("transfer.duration")}{" "}
                                      <span>{list.duracao}</span>
                                    </small>
                                  </div>
                                  <div>
                                    <small style={{ color: "#848289" }}>
                                      <FontAwesomeIcon
                                        icon={["fal", "handshake-alt"]}
                                        size="1x"
                                        style={{
                                          fontSize: "14px",
                                          marginRight: "5px",
                                        }}
                                      />
                                      {t("transfer.supplier")}{" "}
                                      <span>{list.supplierFantasyName}</span>
                                    </small>
                                  </div>
                                  <div>
                                    <p>
                                      {t("transfer.leave")}{" "}
                                      <span className="time-list">
                                        {props.search.date} {t("transfer.at")}{" "}
                                        {props.search.time}
                                      </span>
                                    </p>
                                  </div>
                                  <div className="row info-travel">
                                    <div className="col-4">
                                      <div className="d-flex justify-content-center">
                                        <FontAwesomeIcon
                                          icon={["fal", "users"]}
                                          size="2x"
                                          style={{ fontSize: "26px" }}
                                        />
                                      </div>
                                      <div className="text-center">
                                        <p>
                                          {list.minMaxPeople.maxPassengers}{" "}
                                          {t("transfer.people")}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="d-flex justify-content-center">
                                        <FontAwesomeIcon
                                          icon={["fal", "suitcase"]}
                                          size="2x"
                                          style={{ fontSize: "26px" }}
                                        />
                                      </div>
                                      <div className="text-center">
                                        <p>
                                          {list.baggagePerPerson}{" "}
                                          {t("transfer.suitcase")}
                                        </p>
                                      </div>
                                    </div>
                                    <div className="col-4">
                                      <div className="d-flex justify-content-center">
                                        <FontAwesomeIcon
                                          icon={["fal", "suitcase-rolling"]}
                                          size="2x"
                                          style={{ fontSize: "26px" }}
                                        />
                                      </div>
                                      <div className="text-center">
                                        <p>
                                          {list.handLuggage}{" "}
                                          {t("transfer.suitcaseHand")}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-md-3 card-price">
                                  <div className="list-price">
                                    <p>
                                      {list.idSellingType === 1
                                        ? t("transfer.priceVeihicle")
                                        : t("sales.transfers.pricePerPerson")}
                                    </p>
                                    <h3>
                                      <small style={{ fontSize: "18px" }}>
                                        R${" "}
                                      </small>
                                      {`${list.preco
                                        .toFixed(2)
                                        .replace(".", ",")}`}
                                    </h3>
                                    <button
                                      onClick={() => auth(list, index)}
                                      className="btn btn-primary btn-list-transfers"
                                    >
                                      {loading === true && select === list ? (
                                        <div className="load"></div>
                                      ) : (
                                        t("transfer.buy")
                                      )}
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        );
                    }) : ""}
                    <Pagination
                        totalRows={totalRows}
                        pageCount={pagination}
                        setPageCount={setPagination}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        selectNumberRows="yes"
                    />
                </>
            )
        } else {
            return (
                <>
                    <h3>{t("transfer.notFound")}</h3>
                </>
            )
        }
    }

    if (props.search !== null) {
        return (
            <>
                <Container className="content-page-transfers py-3" fluid>
                    <Row>
                        <Col lg={3} xl={2} className="px-3 pb-4 pb-md-0">
                            <div id="filter-transfer" className="card card-filter h-100">
                                <button id="menu-close-button" className="x-close-button absolute d-block d-lg-none" onClick={() => { setOpen(false) }}>
                                    <svg xmlns="http://www.w3.org/2000/svg" width="15px" height="15px" viewBox="0 0 20.252 20.252" fill="#333A41">
                                        <path d="M11.769 10.126l8.142 8.142a1.162 1.162 0 11-1.643 1.644l-8.143-8.143-8.142 8.143A1.162 1.162 0 01.34 18.268l8.142-8.142L.34 1.984A1.162 1.162 0 011.983.34l8.142 8.143 1.643 1.643zm8.142-8.142s-4.642 4.666-6.5 6.5a1.162 1.162 0 11-1.644-1.644c2.027-2.035 6.5-6.5 6.5-6.5a1.162 1.162 0 011.643 1.643z"></path>
                                    </svg>
                                </button>
                                <FilterTransfer transfers={transfers} transferItem={props.search} setTransfers={setTransfers} open={open} setOpen={setOpen} setPagination={setPagination} setRowsPerPage={setRowsPerPage} setTotalRows={setTotalRows}/>
                            </div>
                        </Col>
                        <Col lg={9} xl={10} className="px-3">
                            <div className="card p-3">
                                <div>
                                    <h5>{t("transfer.pointsChoice")}</h5>
                                </div>
                                <div style={{ display: "grid" }}>
                                    <small><strong>{t("transfer.origin")}</strong> {props.search.origin}</small>
                                    <small><strong>{t("transfer.destine")}</strong> {props.search.destine}</small>
                                    <small><strong>{t("transfer.date")}</strong> {props.search.date}</small>
                                    <small><strong>{t("transfer.time")}</strong> {props.search.time}</small>
                                    <small><strong>{t("transfer.passegers")}</strong> {props.search.numPeople}</small>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button
                                    className="navbar-toggler d-flex ml-auto d-lg-none py-2 px-1"
                                    type="button"
                                    onClick={() => {
                                        setOpen(!open);
                                    }}
                                    aria-controls="menuSideBar"
                                    aria-expanded={open}
                                    aria-label="Toggle navigation"
                                >

                                    <FontAwesomeIcon
                                        icon={["fal", "sliders-h"]}
                                        size="1x"
                                        style={{ color: "#163264", fontSize: "24px" }}
                                    />
                                </button>
                                <ListTransfers />
                            </div>
                        </Col>
                    </Row>
                    <div id="shadow"></div>
                </Container>
            </>
        )
    } else {
        return (
            <>
                <Container className="content-page-transfers py-3" fluid>
                    <Row>
                        <Col lg={3} xl={2} className="px-3 pb-4 pb-md-0"></Col>
                        <Col lg={9} xl={10} className="px-3">
                            <div className="card p-3">
                                <div>
                                    <h5>{t("transfer.pointsChoice")}</h5>
                                </div>
                                <div style={{ display: "grid" }}>
                                    <small><strong>{t("transfer.origin")}</strong></small>
                                    <small><strong>{t("transfer.destine")}</strong></small>
                                    <small><strong>{t("transfer.date")}</strong></small>
                                    <small><strong>{t("transfer.time")}</strong></small>
                                    <small><strong>{t("transfer.passegers")}</strong></small>
                                </div>
                            </div>
                            <div className="mt-4">
                                <button
                                    className="navbar-toggler d-flex ml-auto d-lg-none py-2 px-1"
                                    type="button"
                                    onClick={() => {
                                        setOpen(!open);
                                    }}
                                    aria-controls="menuSideBar"
                                    aria-expanded={open}
                                    aria-label="Toggle navigation"
                                >

                                    <FontAwesomeIcon
                                        icon={["fal", "sliders-h"]}
                                        size="1x"
                                        style={{ color: "#163264", fontSize: "24px" }}
                                    />
                                </button>
                            </div>
                        </Col>
                    </Row>
                    <div id="shadow"></div>
                </Container>
            </>
        )
    }
}

export default Transfers;