import React, { useEffect, useState, } from "react";
import { Card, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import "./CommissionsBills.scss";

export interface propInfo {
  cardsInfo?: any;
  t?: any;
}


const CommissionsBills: React.FC<propInfo> = ({
  cardsInfo, t
}) => {

  const [totalAmount, setTotalAmount] = useState<any>(0);
  const [totalComission, setTotalCommission] = useState<any>(0);
  const [totalPago, setPago] = useState<any>(0);
  const [totalReceber, setTotalReceber] = useState<any>(0);

  useEffect(() => {
    if (cardsInfo !== null) {
      setTotalAmount(cardsInfo.totalAmount);
      setTotalCommission(cardsInfo.totalComission);
      setPago(cardsInfo.totalPago);
      setTotalReceber(cardsInfo.totalReceber)
    }
    
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardsInfo])

  return (
    <>
      <div className="commissions-report">
        <Row className="cards">
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("commissions.cards.totalSales")}</span>
                </Row>
                <Row>
                  <span className="cart-content">
                    {totalAmount?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0.00'}
                    </span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">               
                <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "coins"]}
                  className="text-primary"
                  />              
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("commissions.cards.totalToReceive")}</span>
                </Row>
                <Row>
                  <span className="cart-content">{totalReceber?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0.00'}</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "hand-holding-usd"]}
                  className="text-primary"
                />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("commissions.cards.totalPaid")}</span>
                </Row>
                <Row>
                  <span className="cart-content">{totalPago?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0.00'}</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "receipt"]}
                  className="text-primary"
                />
              </div>
            </Card>
          </div>
          <div className="col-md-2 card-unit">
            <Card>
              <div className="col-md-10">
                <Row>
                  <span className="card-title">{t("commissions.cards.totalCommission")}</span>
                </Row>
                <Row>
                  <span className="cart-content">{totalComission?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) || 'R$ 0.00'}</span>
                </Row>
              </div>
              <div className="col-md-2 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  size="lg"
                  icon={["fad", "credit-card-front"]}
                  className="text-primary"
                />
              </div>
            </Card>
          </div>
        </Row>
      </div>
    </>
  );
}

export default CommissionsBills;
