/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import GetSuppliersFilter from "../../C2Points/GetSuppliersFilter";

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addStartProduct, resetTour } from "../../../store/product";

export interface productTour {
    action: any;
    receiveObject: any;
    tourObject: any;
    isEdit: any;
    isTicket?: boolean;
};

const StartProduct: React.FC<productTour> = React.memo(({
    action, receiveObject, tourObject, isEdit, isTicket = false
}: productTour) => {

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
    /* Controle Redux  */

    const [, setFilterSupplier] = useState<any>(0);
    const [isTranslate, setIsTranslate] = useState<number>(1);
    const [isIntegration, setIsIntegration] = useState<number>(1);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useForm({});

    const watchOperationType = watch(`operationType`, tourObject ? '' + tourObject.operationType : '');  //recebe o value do input 'operationType toda vez que ele muda;

    const onSubmit = (data: any) => {
        data.isTranslate = isTranslate;
        data.isIntegration = isIntegration;
        data.supplier = data.supplier?.supplierId;
        data.sellingType = isTicket ? 2 : (data.sellingType === "Privativo" ? 1 : 2);
        data.sellingTypeDesc = isTicket ? "Regular" : (data.sellingType === 1 ? "Privativo" : "Regular");
        data.operationType = Number(data.operationType);

        dispatch(addStartProduct(data));

        if (isEdit !== true) {
            //dispatch(addToTour(data));
            action("dataTour", data);
        } else {
            receiveObject(data);
        }
    }

    return (
        <>
            {/* CONTENT */}
            <Col md={12} className="pt-main-container mx-auto">
                <Card className="pt-content">
                    <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                        <h3 className="text-primary pt-title">Vamos começar</h3>
                        <div className="pt-content-info">
                            <Row>
                                <Form.Group as={Col} md="12" controlId="">
                                    <Form.Label className="label-small">
                                        Digite o nome do seu produto
                                    </Form.Label>

                                    <Controller
                                        control={control}
                                        name="nameProduct"
                                        rules={{
                                            required: { value: true, message: 'Por favor, informe o nome do produto' },
                                            minLength: { value: 4, message: `Por favor, insira ao menos 4 caracteres` }
                                        }}
                                        defaultValue={tourObject.nameProduct}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="text"
                                                aria-invalid={errors?.nameProduct ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                required
                                                autoComplete='off'
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="nameProduct"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                    <Form.Label className="label-small">
                                        Deseja traduzir automaticamente os detalhes do produto?
                                    </Form.Label>

                                    <Form.Check
                                        className="mb-1"
                                        type="radio"
                                        name="isTranslate"
                                        id={"radio-2"}
                                        value="yes"
                                        required
                                        defaultChecked={tourSIG.tour.isTranslate !== 2}
                                        label={"Sim"}
                                        onClick={() => setIsTranslate(1)}
                                    />

                                    <Form.Check
                                        type="radio"
                                        name="isTranslate"
                                        id={"radio"}
                                        value="no"
                                        required
                                        defaultChecked={tourSIG.tour.isTranslate === 2}
                                        label={"Não"}
                                        onClick={() => setIsTranslate(2)}
                                    />

                                </Form.Group>

                                {!isTicket &&
                                    <>
                                        <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Selecione o tipo de serviço dos seu produto:
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="sellingType"
                                                rules={{ required: { value: true, message: 'Por favor, escolha um tipo de serviço' } }}
                                                defaultValue={tourObject.sellingTypeDesc}
                                                render={({ field }: any) => (
                                                    <div className="input-select-custom">
                                                        <Form.Select
                                                            {...field}
                                                            aria-invalid={errors?.sellingType ? "true" : ""}
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            className="form-control"
                                                            required
                                                        >
                                                            <option value=''>Selecione</option>
                                                            <option value='Regular'>Regular</option>
                                                            <option value='Privativo'>Privativo</option>
                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="sellingType"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Tipo de Operação:
                                            </Form.Label>
                                            <Controller
                                                control={control}
                                                name="operationType"
                                                rules={{ required: { value: true, message: 'Por favor, escolha um tipo de operação' } }}
                                                defaultValue={'' + tourObject.operationType}
                                                render={({ field }: any) => (
                                                    <div className="input-select-custom">
                                                        <Form.Select
                                                            {...field}
                                                            aria-invalid={errors?.operationType ? "true" : ""}
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            className="form-control"
                                                            required
                                                        >
                                                            <option value=''>Selecione</option>
                                                            <option value='1'>Própria</option>
                                                            <option value='2'>Terceiros</option>
                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="operationType"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </>
                                }
                            </Row>
                        </div>

                        <div className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true
                                    ?
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Continuar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </div>
                    </Form>
                </Card>
            </Col>
        </>
    )
});

export default StartProduct;