import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown, Form, Modal, Col } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import api from '../../../../services/api';
import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import { Link } from "react-router-dom";

//STYLES
import Pagination from "../../../../components/Pagination/Pagination";
import ImageDefault from "../../../../assets/img/image-default.png";

export interface propTable {
    vehicles: any;
    loading: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    reloadSerch: any;
}

const VehiclesTable: React.FC<propTable> = ({
    vehicles, loading, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, reloadSerch
}: propTable) => {
    const { t } = useTranslation();
    const [modalUpdateStatus, setModalUpdateStatus] = useState<any>(false);
    const [modalConfirmStatus, setModalConfirmStatus] = useState<any>(false);
    const [modalConfirmStatusLog, setModalConfirmStatusLog] = useState<any>(0);
    const [modalConfirmStatusMessage, setModalConfirmStatusMessage] = useState<any>('');
    const [vehicleId, setVehicleId] = useState<any>(null);
    //const [supplierId, setSupplierId] = useState<any>(null);

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    /* const handleClickStatus = (row: any) => {
        setVehicleId(row.id);
        setModalUpdateStatus(true);
    } */

    const onSubmit = (obj: any) => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function changeStatus() {
            try {
                const { data } = await api.post('/Supplier/UpdateStatusSupplierAsync',
                    {
                        "supplierId": vehicleId,
                        "status": parseInt(obj.updateStatus),
                    }, config
                );
                if (data.data.log === 0) {
                    setModalConfirmStatusLog(0);
                    setModalUpdateStatus(false);
                    setModalConfirmStatus(true);
                    setModalConfirmStatusMessage(data.data.texto)
                    reloadSerch();
                } else {
                    setModalConfirmStatusLog(1);
                    setModalConfirmStatus(true);
                    setModalConfirmStatusMessage(data.data.texto)
                }

            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }

            }
        }
        changeStatus();

    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            {/* <Dropdown.Item eventKey="1" disabled={true} ><FontAwesomeIcon icon={['fal', 'comment-alt-dollar']} />{t('suppliersTable.account')}</Dropdown.Item> */}
                            <Dropdown.Item eventKey="2" href={"/vehicles/edit-vehicle?id=" + cell}><FontAwesomeIcon icon={['fal', 'edit']} />{t('suppliersTable.edit')}</Dropdown.Item>
                            {/* <Dropdown.Item eventKey="3" onClick={() => { handleClickStatus(row); }}><FontAwesomeIcon icon={['fal', 'circle']} />{t('suppliersTable.status')}</Dropdown.Item> */}
                            {/* <Dropdown.Item eventKey="5" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} />{t('suppliersTable.contract')}</Dropdown.Item> */}
                            {/* <Dropdown.Item eventKey="4" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t('suppliersTable.remove')}</Dropdown.Item> */}
                        </Dropdown.Menu>    
                    </Dropdown>
                </div>
            </>
        );
    }

    /* function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Em Análise"
                        ? "bg-analise"
                        : cell === "Ativo"
                            ? "bg-ativo"
                            : cell === "Inativo"
                                ? "bg-inativo"
                                : ""
                }
            >
                {cell}
            </div>
        );
    } */

    function addImage(cell: any, row: any) {
        if (cell === null) {
            return (
                <>
                    <div className="logo-user user-list"
                        style={{ backgroundImage: `url(${ImageDefault})`, backgroundSize: 'cover' }}>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="logo-user user-list"
                        style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL_IMG}/${cell})`, backgroundSize: 'cover', width: '84px', border: 'none' }}>
                    </div>
                </>
            );
        }
    }


    const columns = [
        { dataField: "vehicleTypePhoto", text: "Foto", formatter: addImage },
        { dataField: "vehicleType", text: "Tipo" },
        { dataField: "vehicleModel", text: "Modelo" },
        { dataField: "vehicleArmored", text: "Blindado" },
        { dataField: "vehicleSupplier", text: "Fornecedor" },
        { dataField: "vehicleLicensePlate", text: "Placa" },
        { dataField: "vehicleYear", text: "Ano" },
        { dataField: "vehicleSeats", text: "Lugares" },
        { dataField: "vehicleId", text: "Ações", formatter: addActionButton },
    ];


    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando Veículos", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

    const notFoundProducts = [{ clientes: "Nenhum cliente cadastrado" }];
    /* [END] In case of empty table*/

    /* const rowClasses = (row: any, rowIndex: any) => {
        if (row.statusDesc === "Em Análise") {
            return 'border-analise';
        } else if (row.statusDesc === "Ativo") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    }; */

    if (vehicles !== null) {
        /* console.log(partners) */
        return (
            <>
                <div className="table-default vehicles-table">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Veículos</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={vehicles}
                            columns={columns}
                            striped={true}
                        />
                        <Pagination
                            totalRows={totalRows}
                            pageCount={pageCount}
                            setPageCount={setPageCount}
                            rowsPerPage={rowsPerPage}
                            setRowsPerPage={setRowsPerPage}
                            selectNumberRows="yes"
                        />
                    </div>
                </div>

                <Modal
                    className="modalAuth"
                    show={modalUpdateStatus}
                    onHide={() => { setVehicleId(null); setModalUpdateStatus(false); }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <div className='status-modal'>
                            <Form className="modal-content" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                <Modal.Body>

                                    <div className="modal-header rounded-0">
                                        <div>
                                            <h4 className="pt-1 text-primary title-reservation">Editar Status</h4>
                                            <small>

                                            </small>
                                        </div>

                                        <div className='buttons-options'>
                                            <div>

                                            </div>
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={['fal', 'times']}
                                                    className="mx-3 text-info"
                                                    size="lg"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => { setVehicleId(null); setModalUpdateStatus(false) }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <hr className='w-100 my-1' style={{ borderBottom: '1px solid' }} />

                                    <div>
                                        <Form.Group as={Col} md="5" controlId="formInfoCompanySubscription">
                                            <Form.Label>Tem certeza que deseja alterar o status?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="updateStatus"
                                                rules={{ required: { value: true, message: 'Por favor, escolha uma opção.' } }}
                                                defaultValue={'2'}
                                                render={({ field }: any) => (
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        style={{ border: "1px solid #ced4da" }}
                                                        required
                                                    >
                                                        <option value=''  >Selecione</option>
                                                        <option value='1'>Em Análise</option>
                                                        <option value='2'>Ativo</option>
                                                        <option value='3'>Inativo</option>
                                                        <option value='4'>Bloqueado</option>
                                                        <option value='5'>Pendente Aprovação</option>
                                                    </Form.Select>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="updateStatus"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="modal-footer rounded-0 border-0">
                                        <button type="button" className="btn-default mr-2 text-uppercase btn btn-outline-primary" data-dismiss="modal" onClick={() => { setVehicleId(null); setModalUpdateStatus(false) }}><i className="fas fa-times fa-fw"></i> <span data-vouchercampo="close">Cancelar</span></button>
                                        <button type="submit" className="btn-default text-uppercase btn btn-primary">
                                            {loading === true ? <div className="load"></div> : "Confirmar"}
                                        </button>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </>
                </Modal>

                <Modal
                    className="modalAuth"
                    show={modalConfirmStatus}
                    onHide={() => { setModalConfirmStatus(false); }}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos d-flex justify-content-evenly' style={{ height: "600px" }}>
                            <div>
                                {modalConfirmStatusLog === 0
                                    ?
                                    <FontAwesomeIcon
                                        icon={["fal", "check-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                    />
                                }
                            </div>
                            <div>
                                {modalConfirmStatusMessage}
                            </div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => { setModalConfirmStatus(false); }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                            </div>

                        </Modal.Body>
                    </>
                </Modal>

            </>
        );
    } else if (vehicles === null && loading === true) {
        return (
            <>
                <div className="table-default partners-table loading not-found">
                    <div className="table-container">
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default partners-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Clientes</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default VehiclesTable;
