import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterSuppliersTariff.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
//import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import { Controller, useForm } from "react-hook-form";
import GetSuppliersIdentity from "../../../../components/C2Points/GetSuppliersIdentity";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import GetProductsAndGroupsList from "../../../../components/C2Points/GetProductsAndGroupsList";
//import GlobalAlert from "../../../Alert/GlobalAlert/GlobalAlert";

export interface propFilter {
    buscar: any,
    billsPay: any,
    error: any,
    clearActualValues: any;
    allowLoadFromFilter?: any;
}

const FilterSuppliersTariff: React.FC<propFilter> = ({
    buscar, billsPay, error, clearActualValues, allowLoadFromFilter
}) => {

    const { t } = useTranslation();

    //const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [filterProduct, setFilterProduct] = useState<any>("");
    const [filterType, setFilterType] = useState<any>(null);
    const [filterSupplier, setFilterSupplier] = useState<any>("");

    //const [alert, setAlert] = useState<any>();
    //const [checked, setChecked] = useState<boolean>(true);

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */

    const {
        control,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = (event: any) => {

        // var dataIntervalLong = false;

        // if (filterDate.length === 2) {
        //     let beginDate = filterDate[0].split('/')
        //     let endDate = filterDate[1].split('/')

        //     const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1])-1, parseInt(beginDate[0]));
        //     const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]));

        //     const days = (dataFim.getTime() - dataIni.getTime()) / (1000*60*60*24);

        //     dataIntervalLong = (days > 90); // 3 meses
            
        // }

        // if(dataIntervalLong) {
        //     setDateIntervalConfirmModalShow(true);
        // } else {
            handleActionBuscar();
        //}
    }

    const handleActionBuscar = () => {
        //setDateIntervalConfirmModalShow(false);
        //console.log(filterLocal, filterStatus, filterType, filterSupplier, filterTypeDate, filterDate);
        buscar(filterProduct, filterSupplier, filterType);
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if(selectValue[i].id === "rowsPerPage"){
                selectValue[i].value = "10";
            }else{
                selectValue[i].value = "";
            }
        }

        setFilterProduct("");
        setFilterSupplier("");
        setFilterType(null);
        buscar("", "", null);
        clearActualValues();
    };


    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
    }

    /*     useEffect(() => {
            if (suppliers.length === 0 || error === true) {
                setAlert(
                    <GlobalAlert alertType={"danger"}>
                        Não foi possível encontrar nenhum fornecedor.
                    </GlobalAlert>
                )
                setTimeout(() => setAlert(null), 2000);
            } else {
                setAlert(null);
            }
        }, [suppliers, error]) */

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Tarifário</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {/* {alert} */}
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Produto</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="produto"
                                                    render={({ field }: any) => (
                                                        <GetProductsAndGroupsList propsField={field} propsErrors={errors} propsLabel="Produtos" setProduct={setFilterProduct} />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Fornecedor</Form.Label>
                                                {/* <Form.Control
                                                    type="text"
                                                    autoComplete='off'
                                                    onChange={handleChangeSupplier}
                                                /> */}
                                                <Controller
                                                    control={control}
                                                    name="fornecedor"
                                                    render={({ field }: any) => (
                                                        <GetSuppliersIdentity propsField={field} propsErrors={errors} propsLabel="Fornecedores" setSupplier={setFilterSupplier} />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeType(e)}
                                                            value={filterType}
                                                        >
                                                            <option value="">Todos</option> 
                                                            <option value="1">Transporte</option> 
                                                            <option value="2">Guia</option> 
                                                            <option value="3">Driver Guide</option> 
                                                            <option value="4">Ticket</option> 
                                                            <option value="5">Restaurante</option> 
                                                            <option value="6">Serv Terceiros</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterSuppliersTariff;