import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as React from "react";
import { Card, Row } from "react-bootstrap";
import './PartnerDataCard.scss';

export interface propCard {
    title?:string;
    children?:any;
    icon?:any
}

const PartnerDataCard: React.FC<propCard> = ({
    children, icon, title
  }: propCard)=> {
  return (
    <Card className="partner-card">
      <div className="col-md-10">
        <Row>
          <span className="partner-card-title">{title}</span>
        </Row>
        <Row>         
          {children}
        </Row>
      </div>
      <div className="col-md-2 d-flex justify-content-center align-items-center">        
        <FontAwesomeIcon
          icon={icon}
          className="text-primary p-1"
          size="3x"
        />
      </div>
    </Card>
  );
}

export default PartnerDataCard;