import React, { useState } from "react";
import { Button, Modal, Row } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { useTranslation } from 'react-i18next';

import api from "../../../../services/api";

function AuthForgotPass() {
    const { t } = useTranslation();
    const [errorMessage, setErrorMessage] = useState<any>(null);

    const [modalResponse, setModalResponse] = useState<any>(null);
    const [showModal, setShowModal] = useState<any>(false);
    const [log, setLog] = useState<any>(0);

    const regex = /^(?=.*[a-z]{1})(?=.*\d)(?=.*[@$!%*#?&])[A-Za-z\d@$!%*#?&]{8,}$/;

    const onSubmit = (e: any) => {
        e.preventDefault();
        const getAuthenticate = async () => {
            let token: any = window.location.href.split('/new_password/')[1];

            try {
                const { data } = await api.post("/UserPermission/AlterPassword", {
                    "token": token,
                    "password": e.target[0].value,

                });
                if (data.status !== 400) {
                    setModalResponse(data.data.texto);
                    setShowModal(true);
                    setLog(data.data.log);
                    /*  localStorage.setItem("c2tourAuth", JSON.stringify(data.data));
                     window.location.href = window.location.origin + "/dashboard"; */
                }
            } catch (error: any) {
                if (error.response.status === 400) {
                    console.log(error.response.data.errorMessage);
                    setErrorMessage(error.response.data.errorMessage);
                }
            }
        };

        if (e.target[0].value === e.target[1].value) {
            if (regex.exec(e.target[0].value)) {
                getAuthenticate();
            } else {
                setShowModal(true);
                setModalResponse("Senhas fraca, use oito ou mais caracteres com uma combinação de letras, números e símbolos");
                setLog(1);
            }
        } else {
            setShowModal(true);
            setModalResponse("Senhas diferentes");
            setLog(1);
        }
    };

    const GetError = () => {
        if (errorMessage === null) {
            return <div className="form-group"></div>;
        } else {
            return (
                <div className="invalid form-group">
                    <div className="invalid-feedback">{errorMessage}</div>
                </div>
            );
        }
    };

    return (
        <>
            <form className="user" name="new_pass" onSubmit={(e) => onSubmit(e)}>
                <GetError />
                <div className="form-group">
                    <label htmlFor="exampleInputPass">{t("login.newPassword.digitNewPass")}</label>
                    <input
                    autoComplete='off'
                        type="password"
                        name="new-pass"
                        className="form-control"
                        id="exampleInputPass"
                        aria-describedby="passHelp"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="exampleInputPass">{t("login.newPassword.confirmPass")}</label>
                    <input
                    autoComplete='off'
                        type="password"
                        name="confirm-pass"
                        className="form-control"
                        id="exampleInputPass"
                        aria-describedby="passHelp"
                    />
                </div>

                <input
                autoComplete='off'
                    type="submit"
                    value={`${t("login.newPassword.savePass")}`}
                    className="btn btn-primary btn-block btn-new-pass"
                />
            </form>

            <Modal
                className="modalAuth"
                show={showModal}
                onHide={() => { setModalResponse(''); setShowModal(false); }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <div className="d-flex flex-column justify-content-evenly align-items-center p-4" style={{ height: '650px' }}>
                        <div className="mb-4">

                            {log === 0
                                ?
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em" }}
                                />
                                :
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em" }}
                                />
                            }
                        </div>
                        <span className="text-center">
                            {modalResponse}
                        </span>
                        <Row className="d-flex justify-content-end mx-0">
                                <Button
                                    type="button"
                                    className="form-button"
                                    onClick={()=>{setShowModal(false);}}
                                >
                                    Confirmar
                                </Button>
                            </Row>
                    </div>
                </>
            </Modal>
        </>
    );
}

export default AuthForgotPass;