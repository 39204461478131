/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterExportERPLogs.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

export interface propFilter {
    buscar: any,
}

const FilterExportERPLogs: React.FC<propFilter> = ({
    buscar
}) => {

    const { t } = useTranslation();
    //const [payment, setPayment] = useState<any>("");

    const today = new Date();
    const todayStr = [
        `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
        `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`
    ];

    const [filterDate, setFilterDate] = useState<any>(todayStr);
    const [filterStatus, setFilterStatus] = useState<any>(3);
    const [filterReference, setFilterReference] = useState<any>('');

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */

    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterDate.length === 2) {
            let beginDate = filterDate[0].split('/')
            let endDate = filterDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1]) - 1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1]) - 1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000 * 60 * 60 * 24);

            dataIntervalLong = (days > 90); // 3 meses

        }

        if (dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        buscar(filterDate, filterReference, filterStatus);
    };

    const handleClearClick = (event: any) => {

        /* let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        } */

        setFilterDate(todayStr);
        setFilterStatus(1);
        setFilterReference('');
        buscar(todayStr, '', 3);
    };

    const handleChangeStatusErp = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Logs RM</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data de envio</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} defaultValues={[`${today.getFullYear()}-${ today.getMonth() + 1}-${today.getDate()}`, `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`]} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Referencia:</Form.Label>

                                                <Form.Control type="text"
                                                    onChange={(value: any) => { setFilterReference(value.target.value) }}
                                                    value={filterReference}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatusErp(e)}
                                                            value={filterStatus}
                                                        >

                                                            <option value="3">Todos</option>
                                                            <option value="0">Pendente</option>
                                                            <option value="1">Enviado</option>
                                                            <option value="2">Erro</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterExportERPLogs;