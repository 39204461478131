/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState, Key } from 'react';
import { Row, Col, OverlayTrigger, Tooltip } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Dropdown } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import Select from 'react-select';

import api from '../../../../services/api';
//import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersInsert';

import ModalConfirm from '../../../../components/Modal/ModalConfirm/ModalConfirm';
import ModalResourceVoucherViewer from '../../../../components/Modal/ModalResourceVoucherViewer/ModalResourceVoucherViewer';
//import ModalQuestion from '../../../../components/Modal/ModalQuestion/ModalQuestion';

/* tipo: 1 = Transporte, 2 = Motorista, 3 = Guia, 4 = Ticket, 5 = Restaurante */

//COMPONENTS
export interface propResource {
    resources: any,
    sessionInfo?: any,
    insert?: any,
    UpdateTotals: any;
};

const SessionResources: React.FC<propResource> = ({
    resources, sessionInfo, insert, UpdateTotals
}: propResource) => {

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId

    const [resource, setResource] = useState<any>();

    /* Resources Type */
    const [addType, setAddType] = useState<any>([]);
    const [addResources, setAddResources] = useState<any>([]);
    /* END - Resources Type */

    /* Modal Confirm */
    const [responseText, setResponseText] = useState<any>();
    const [modalResponse, setModalResponse] = useState<any>();
    const [log, setLog] = useState<any>();
    /* END - Modal Confirm */

    /* ID Suppliers */
    const [idVehicles, setIdVehicles] = useState<any>();
    const [idDrivers, setIdDrivers] = useState<any>();
    const [idGuide, setIdGuide] = useState<any>();
    const [idTicket, setIdTicket] = useState<any>();
    const [idRestaurant, setIdRestaurant] = useState<any>();
    /* END - ID Suppliers */

    /* ID Resources */
    const [supplierIdVehicles, setSupplierIdVehicles] = useState<any>([]);
    const [supplierIdDrivers, setSupplierIdDrivers] = useState<any>([]);
    const [supplierIdGuide, setSupplierIdGuide] = useState<any>([]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [supplierIdTicket, setSupplierIdTicket] = useState<any>([]);
    const [supplierIdRestaurant, setSupplierIdRestaurant] = useState<any>([]);

    const [insertSupplier, setInsertSupplier] = useState<any>([]);
    const [insertSupplierTicket, setInsertSupplierTicket] = useState<any>({});
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [indexTicket, setIndexTicket] = useState<any>(0);

    const [tipo, setTipo] = useState<any>(undefined);
    const [addTipo, setAddTipo] = useState<any>(undefined);

    //const [item, setItems] = useState<any>(null);

    /* END - ID Resources */

    const [voucherViewModalShow, setVoucherViewModalShow] = useState<any>(false);
    const [voucher, setVoucher] = useState<any>('');

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        //formState: { errors },
    } = useForm();

    const onSubmitInsert = (data: any, index: any, elem: any) => {
        const supplier = watch(`supplier${index}`, false);

        var status = watch(`status${index}`, false);

        if (elem.tipo !== 6) {
            if (elem.tipo === 1) {
                elem.resourceId = idVehicles !== undefined ? idVehicles : elem.resourceId;
            } else if (elem.tipo === 2) {
                elem.resourceId = idDrivers !== undefined ? idDrivers : elem.resourceId;
            } else if (elem.tipo === 3) {
                elem.resourceId = idGuide !== undefined ? idGuide : elem.resourceId;
            } else if (elem.tipo === 4) {
                elem.resourceId = idTicket !== undefined ? idTicket : elem.resourceId;
            } else if (elem.tipo === 5) {
                elem.resourceId = idRestaurant !== undefined ? idRestaurant : elem.resourceId;
            }
            //  else if (elem.tipo === 6) {
            //     elem.resourceId = resource !== undefined ? resource : elem.resourceId;
            // }
         } else {
             elem.tipo = addTipo !== undefined ? addTipo : elem.tipo
             elem.resourceId = resource !== undefined ? resource : elem.resourceId
             elem.resourceTypeId = tipo !== undefined ? tipo : elem.resourceTypeId
         }

        status = status !== undefined ? status : elem.status;

        elem.supplierId = supplier;

        //console.log('dados',elem, status);
        
        if (elem.resourceId !== undefined && status !== undefined) {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, },
            };

            setModalResponse("loading");

            const InsertNewResource = async () => {
                try {
                    const res = await api.post(`/Manifest/InsertResource`,
                        {
                            "manifestId": elem.manifestId,
                            "sessionId": sessionInfo.sessionId,
                            "startTime": sessionInfo.startTime,
                            "endTime": sessionInfo.endTime,
                            "descricao": sessionInfo.descricao,
                            "tipo": elem.tipo,
                            "supplierId": supplier,
                            "resourceTypeId": elem.resourceTypeId,
                            "resourceId": elem.resourceId,
                            "resourceDesc": elem.resourceDesc,
                            "status": Number(status),
                            "valor": 0,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config);

                    if (res.status !== 400) {
                        //setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        //setModalResponse("success");
                        window.location.reload();
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalResponse("error");
                    }
                } catch (error: any) {
                    if (error?.response?.status === 401) {
                        setResponseText("Preencha os campos!");
                        setLog(1);
                        setModalResponse("error");
                    }
                    if (error?.response?.status === 400) {
                        setResponseText("Preencha os campos!");
                        setLog(1);
                        setModalResponse("error");
                    }
                }
            }

            InsertNewResource();
        } else {
            setResponseText("Preencha os campos!");
            setLog(1);
            setModalResponse("error");
        }
    }

    const onSubmitUpdate = (data: any, index: any, elem: any) => {
        // if (elem.tipo === 1) {
        //     elem.resourceId = idVehicles;
        // } else if (elem.tipo === 2) {
        //     elem.resourceId = idDrivers
        // } else if (elem.tipo === 3) {
        //     elem.resourceId = idGuide
        // } else if (elem.tipo === 4) {
        //     elem.resourceId = idTicket
        // } else if (elem.tipo === 5) {
        //     elem.resourceId = idRestaurant
        // }

        const status = watch(`status${index}`, false);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, },
        };

        setModalResponse("loading");

        const UpdateResource = async () => {
            try {
                const res = await api.post(`/Manifest/UpdateResource`,
                    {
                        "manifestId": elem.manifestId,
                        "sessionId": sessionInfo.sessionId,
                        "startTime": sessionInfo.startTime,
                        "endTime": sessionInfo.endTime,
                        "descricao": sessionInfo.descricao,
                        "tipo": elem.tipo,
                        "supplierId": elem.supplierId,
                        "resourceTypeId": elem.resourceTypeId === "" ? 0 : elem.resourceTypeId,
                        "resourceId": elem.resourceId,
                        "resourceDesc": elem.resourceDesc === null ? "" : elem.resourceDesc,
                        "status": status === undefined ? elem.status : Number(status),
                        "valor": 0,
                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`,
                        "id": elem.id
                    }, config);

                if (res.status !== 400) {
                    //setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    //setModalResponse("success");
                    window.location.reload();
                } else {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalResponse("error");
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Preencha os campos!");
                    setLog(1);
                    setModalResponse("error");
                }
                if (error?.response?.status === 400) {
                    setResponseText("Preencha os campos!");
                    setLog(1);
                    setModalResponse("error");
                }
            }
        }

        UpdateResource();
    }

    const Suppliers = async (resourceId: any, type: any, index: any, resourceTypeId: any) => {
        setValue(`supplier${index}`, '');
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, },
        };

        try {
            const { data } = await api.post(`/Manifest/GetSuppliersByResourceAsync`, {
                "type": type,
                "resourceId": resourceId
            }, config);
            if (data.status !== 400) {
                /* let aux:any = item;
                aux[index] = data.data;
                setItems(aux); */
                if (type === 1) {
                    setSupplierIdVehicles(data.data);
                    setInsertSupplier(data.data);
                } else if (type === 2) {
                    setSupplierIdDrivers(data.data);
                    setInsertSupplier(data.data);
                } else if (type === 3) {
                    setSupplierIdGuide(data.data);
                    setInsertSupplier(data.data);
                } else if (type === 4) {
                    //console.log(resourceTypeId)
                    let sControl = resourceTypeId;
                    let controlObject = {
                        [sControl]: data.data,
                    }
                    setSupplierIdTicket(data.data);
                    setIndexTicket(index);
                    setInsertSupplier(data.data);
                    setInsertSupplierTicket((oldObject: any) => ({ ...oldObject, ...controlObject }));
                } else if (type === 5) {
                    setSupplierIdRestaurant(data.data);
                    setInsertSupplier(data.data);
                } else {
                    setInsertSupplier(data.data);
                }
                //console.log(data.data)
            }
        } catch (error: any) {
            if (error?.response?.status === 401) { }
            if (error?.response?.status === 400) { }
        }
    }

    const UpdateValue = async (resourceId: any, type: any, index: any, categId: any, supplier: any) => {
        
        if(supplier !== undefined && supplier !== '' && resourceId !== undefined && resourceId !== '') {

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, },
            };

            try {
                const { data } = await api.post(`/Manifest/TryGetSupplierResourceTax`, {
                    "supplierId": supplier,
                    "resourceType": type,
                    "tourDate": sessionInfo.startTime,
                    "productCode": sessionInfo.productCode,
                    "resourceId": resourceId,
                    "categId": categId,
                    "QtdePax": sessionInfo.totalPax,
                    "QtdeAdults": sessionInfo.totalAdult,
                    "QtdeChilds": sessionInfo.totalChild,
                    "QtdeInfants": sessionInfo.totalInfant,
                    "QtdeElders": 0
                }, config);
                if (data.status !== 400) {
                    const obj = data.data;
                    const temTarifario = obj.log === 1 ? 1 : 2;
                    const valor = obj.data;

                    setValue(`valor${index}`, valor)
                    setValue(`temTarifario${index}`, temTarifario)

                    resources[index].valorNet = valor;
                    UpdateTotals(resources);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) { }
                if (error?.response?.status === 400) { }
            }
        }
        else {
            setValue(`valor${index}`, 0)
            setValue(`temTarifario${index}`, 3);

            resources[index].valorNet = 0;
            UpdateTotals(resources);
        }
    }

    function SupplierNotice(elem: any) {
        setModalResponse("loading");

        const Notice = async () => {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, },
                };

                const { data } = await api.post(`/Manifest/NotifySupplier/`,
                    {
                        "id": elem.id,
                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`
                    }, config
                );
                if (data.status !== 400) {
                    setResponseText(data.data.texto);
                    setLog(data.data.log);
                    setModalResponse("success");
                } else {
                    setResponseText(data.data.texto);
                    setLog(data.data.log);
                    setModalResponse("error");
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalResponse("error");
                }
                if (error?.response?.status === 400) {
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalResponse("error");
                }
            }
        }
        Notice();
    }

    function handleVoucherViewClick(manifestId: any) {
        async function getVoucher() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };
            try {
                const { data } = await api.post('SessionClosure/GetBillVoucherAsync',
                    {
                        "manifestId": manifestId,
                        //"tipo": row.tipo
                    }, config
                );
    
                if (data.status !== 400) {
                  // show modal
                  setVoucher(data.data);
                  setVoucherViewModalShow(true);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        getVoucher();
    }

    function handleVirtualVoucherViewClick(manifestId: any) {
        async function getVoucher() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };
            try {
                const { data } = await api.post('SessionClosure/GetBillVirtualVoucherAsync',
                    {
                        "manifestId": manifestId,
                        //"tipo": row.tipo
                    }, config
                );
    
                if (data.status !== 400) {
                  // show modal
                  //setVoucher(data.data);
                  //setVoucherViewModalShow(true);
                  download(data.data, `Virtual Voucher - ${sessionInfo.sessionId} - ${sessionInfo.descricao}.pdf`);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        getVoucher();
    }

    function download(blob: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        //const blob = `data:${filetype};base64,${file}`;
        //console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
        let link = document.createElement('a');
        link.href = blob;//window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
        //}
    };

    function handleRemoveResource(elem: any) {
        //console.log(elem);
        setResponseText("Tem Certeza que deseja excluir este item? Essa ação sera permanente.");
        setLog(2);
        setModalResponse("question");

    }

    function handleActionResourceDeleteModal(elem: any) {
        setModalResponse("loading");

        const Notice = async () => {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}` },
                };

                const { data } = await api.post(`/Manifest/DeleteResource`,
                    {
                        "id": elem.id,
                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`
                    }, config
                );
                if (data.status !== 400) {
                    setResponseText(data.data.texto);
                    setLog(data.data.log);
                    setModalResponse("success");
                } else {
                    setResponseText(data.data.texto);
                    setLog(data.data.log);
                    setModalResponse("error");
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalResponse("error");
                }
                if (error?.response?.status === 400) {
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalResponse("error");
                }
            }
        }
        Notice();
    }


    function handleActionResourceTypeRemoveModal(elem: any) {
        setModalResponse("loading");

        const Notice = async () => {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, },
                };

                const { data } = await api.post(`/Manifest/RemoveResourceTypeFromSession`,
                    {
                        "sessionId": sessionInfo.sessionId,
                        "resourceTypeId": elem.resourceTypeId,
                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`
                    }, config
                );
                if (data.status !== 400) {
                    setResponseText(data.data.texto);
                    setLog(data.data.log);
                    setModalResponse("success");
                } else {
                    setResponseText(data.data.texto);
                    setLog(data.data.log);
                    setModalResponse("error");
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalResponse("error");
                }
                if (error?.response?.status === 400) {
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalResponse("error");
                }
            }
        }
        Notice();
    }

    useEffect(() => {
        if (insert === 7) {
            const ResourceTypes = async () => {

                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}` },
                };
                try {
                    const { data } = await api.get(`/DropDown/GetResourcesTypeIsActive`, config); 
                    if (data.status !== 400) {
                        setAddType(data.data)
                    }
                } catch (error: any) {
                    if (error?.response?.status === 401) { }
                    if (error?.response?.status === 400) { }
                }
            }
            ResourceTypes();
        }
    }, [insert])

    useEffect(() => {
        if (tipo !== undefined) {
            const AddResource = async () => {
                try {
                    const token = localStorage.getItem('GroupId') || '{}';
                    const config = {
                        headers: { 'Authorization': `Bearer ${token}`, },
                    };

                    const { data } = await api.post(`/Manifest/GetSupplierResources`, {
                        supplierId: 0,
                        tipo: addTipo
                    }, config);
                    if (data.status !== 400) {
                        setAddResources(data.data)
                    }
                } catch (error: any) {
                    if (error?.response?.status === 401) { }
                    if (error?.response?.status === 400) { }
                }
            }
            AddResource();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tipo])

    function handleType(e: any, index: any) {
        setTipo(e.target.value)
        setValue(`type${index}`, e.target.value);
        let AddResourceType: any = e.target.options[e.target.selectedIndex].dataset.tipo;
        console.log(AddResourceType, "fomfom");

        if (AddResourceType === "Veículo") {
            setAddTipo(1)
        } else if (AddResourceType === "Motorista") {
            setAddTipo(2)
        } else if (AddResourceType === "Guia") {
            setAddTipo(3)
        } else if (AddResourceType === "Ticket") {
            setAddTipo(4)
        } else if (AddResourceType === "Restaurante") {
            setAddTipo(5)
        } else {
            setAddTipo(6)
        }
    }

    /*     useEffect(() => {
            let auxResource: any = [];
    
            for (let i = 0; i < sessionInfo.resources.length; i++) {
                auxResource.push(
                    []
                )
            }
            setItems(auxResource)
        }, []) */

    return (
        <>
            <div className="d-flex w-100">
                <div className="w-100">
                    <Row className="d-flex align-items-center my-4 px-3" style={{ flexDirection: "row" }}>
                        <span style={{ width: "75px" }}></span>
                        <div className='forms'>
                            <div className='col-1 d-flex justify-content-center'>
                                <span className="manifest-label">TIPO</span>
                            </div>
                            <div className='col-2 d-flex justify-content-center'>
                                <span className="manifest-label">RECURSO</span>
                            </div>
                            <div className='col-2 d-flex justify-content-center'>
                                <span className="manifest-label">FORNECEDOR</span>
                            </div>
                            <div className='col-2 d-flex justify-content-center'>
                                <span className="manifest-label">STATUS</span>
                            </div>
                            <div className='col-1 d-flex justify-content-center'>
                                <span className="manifest-label">DETALHE</span>
                            </div>
                            <div className='col-1 d-flex justify-content-center'>
                                <span className="manifest-label">VALOR</span>
                            </div>
                            <div className='d-flex justify-content-center align-items-end' style={{ width: "100px" }}>
                                <span className="manifest-label">AÇÕES</span>
                            </div>
                        </div>
                    </Row>
                </div>
            </div>
            <div className="d-flex w-100">
                <div className='w-100'>
                    {
                        resources.length > 0
                            ? resources.map((elem: any, index: Key) => {
                                return (
                                    <>
                                        <Form key={index} autoComplete="false">
                                            {(elem.tipo !== insert || elem.id !== 0)
                                                ?
                                                <>
                                                    <Row className="d-flex align-items-center my-2 px-3" style={{ flexDirection: "row" }}>
                                                        <div style={{ maxWidth: "75px" }}>
                                                            {
                                                                (elem.tipo === 1)
                                                                    ?
                                                                    <>
                                                                        <p className="circled">
                                                                            <FontAwesomeIcon
                                                                                file-invoice
                                                                                icon={["fal", "bus"]}
                                                                                size="1x"
                                                                                style={{ fontSize: "2.25rem", padding: "5px" }}
                                                                            />
                                                                        </p>
                                                                    </>
                                                                    :
                                                                    (elem.tipo === 2) ?
                                                                        <p className="circled">
                                                                            <FontAwesomeIcon
                                                                                icon={["fal", "address-card"]}
                                                                                size="3x"
                                                                                style={{ fontSize: "2.25rem", padding: "5px" }}
                                                                            />
                                                                        </p>
                                                                        :
                                                                        (elem.tipo === 3) ?
                                                                            <p className="circled">
                                                                                <FontAwesomeIcon
                                                                                    icon={["fal", "users"]}
                                                                                    size="3x"
                                                                                    style={{ fontSize: "2.25rem", padding: "5px" }}
                                                                                />
                                                                            </p>
                                                                            :
                                                                            (elem.tipo === 4) ?
                                                                                <p className="circled">
                                                                                    <FontAwesomeIcon
                                                                                        icon={["fal", "ticket-alt"]}
                                                                                        size="3x"
                                                                                        style={{ fontSize: "2.25rem", padding: "5px" }}
                                                                                    />
                                                                                </p>
                                                                                :
                                                                                (elem.tipo === 5) ?
                                                                                    <p className="circled">
                                                                                        <FontAwesomeIcon
                                                                                            icon={["fal", "utensils"]}
                                                                                            size="3x"
                                                                                            style={{ fontSize: "2.25rem", padding: "5px" }}
                                                                                        />
                                                                                    </p>
                                                                                    :
                                                                                    (elem.tipo === 6 && elem.id !== 0) ?
                                                                                        <p className="circled">
                                                                                            <FontAwesomeIcon
                                                                                                icon={["fal", "ellipsis-h"]}
                                                                                                size="3x"
                                                                                                style={{ fontSize: "2.25rem", padding: "5px" }}
                                                                                            />
                                                                                        </p>
                                                                                        :
                                                                                        <p className="circled">
                                                                                            <FontAwesomeIcon
                                                                                                icon={["fal", "plus"]}
                                                                                                size="3x"
                                                                                                style={{ fontSize: "2.25rem", padding: "5px" }}
                                                                                            />
                                                                                        </p>
                                                            }
                                                        </div>

                                                        <div className="forms">
                                                            <Form.Group as={Col} md="1">
                                                                {/* <Form.Label>TIPO</Form.Label> */}
                                                                <span className="d-flex justify-content-center" style={{ height: "36px", alignItems: "center" }}>
                                                                    {
                                                                        elem.tipo !== 6 || elem.id !== 0
                                                                            ?
                                                                            <>
                                                                                <p title={elem.resourceTypeDesc} className="resource-type">{elem.resourceTypeDesc}</p>
                                                                            </>
                                                                            :
                                                                            <Controller
                                                                                control={control}
                                                                                name={`tipo`}
                                                                                //rules={{ required: { value: true, message: "Por favor, informe o fornecedor." }, }}
                                                                                render={({ field }: any) => (
                                                                                    <div className="endpoint" >
                                                                                        <Form.Select
                                                                                            aria-labelledby="aria-label"
                                                                                            inputId="aria-example-input"
                                                                                            name="aria-live-color"
                                                                                            {...field}
                                                                                            as="select"
                                                                                            variant="standard"
                                                                                            margin="normal"
                                                                                            onChange={(e: any) => handleType(e, index)}
                                                                                        >
                                                                                            <option value='' selected disabled>Selecione</option>
                                                                                            {
                                                                                                addType.map((type: any) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <option value={`${type.id}`} data-tipo={
                                                                                                                type.guide === 1
                                                                                                                    ?
                                                                                                                    "Guia"
                                                                                                                    :
                                                                                                                    type.people === 1
                                                                                                                        ?
                                                                                                                        "Motorista"
                                                                                                                        :
                                                                                                                        type.restaurant === 1
                                                                                                                            ?
                                                                                                                            "Restaurante"
                                                                                                                            :
                                                                                                                            type.ticket === 1
                                                                                                                                ?
                                                                                                                                "Ticket"
                                                                                                                                :
                                                                                                                                type.vehicle === 1
                                                                                                                                    ?
                                                                                                                                    "Veículo"
                                                                                                                                    :
                                                                                                                                    "Outros"
                                                                                                            }>
                                                                                                                {type.description}
                                                                                                            </option>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                            }
                                                                                        </Form.Select>
                                                                                    </div>
                                                                                )}
                                                                            />

                                                                    }
                                                                </span>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="2">
                                                                {/* <Form.Label>FORNECEDOR</Form.Label> */}
                                                                <Controller
                                                                    control={control}
                                                                    name={`resource${index}`}
                                                                    //option={elem.tipo}
                                                                    defaultValue={elem.resourceId}
                                                                    render={({ field }: any) => (
                                                                        <div>
                                                                            {
                                                                                elem.resource !== null
                                                                                    ?
                                                                                    <>
                                                                                        <span className="d-flex justify-content-center text-center" style={{ height: "36px", alignItems: "center" }}>
                                                                                            {elem.resource === "" ? "??" : elem.resource}
                                                                                        </span>
                                                                                    </>
                                                                                    :
                                                                                
                                                                                        elem.tipo === 1
                                                                                            ?
                                                                                                <div className="endpoint" >
                                                                                                    <Select
                                                                                                        aria-labelledby="aria-label"
                                                                                                        inputId="aria-example-input"
                                                                                                        name="aria-live-color"
                                                                                                        {...field}
                                                                                                        as="select"
                                                                                                        placeholder="Selecione"
                                                                                                        variant="standard"
                                                                                                        margin="normal"
                                                                                                        options={Array.from(elem.resources)}
                                                                                                        getOptionLabel={(option: any) => `${option.descricao}`}
                                                                                                        onChange={(elem: any) => {
                                                                                                            setIdVehicles(elem.id)
                                                                                                            setValue(`resource${index}`, elem.id)
                                                                                                            setValue(`vehicleType${index}`, elem.vehicleTypeId)
                                                                                                            Suppliers(elem.id, 1, index, elem.resourceTypeId)
                                                                                                            const supplier = watch(`supplier${index}`, false)

                                                                                                            UpdateValue(elem.id, 1, index, elem.vehicleTypeId, supplier)
                                                                                                        }
                                                                                                        }
                                                                                                        value={elem.resources.id}
                                                                                                    />
                                                                                                </div>
                                                                                            :
                                                                                        elem.tipo === 2
                                                                                            ?
                                                                                                <div className="endpoint" >
                                                                                                    <Select
                                                                                                        aria-labelledby="aria-label"
                                                                                                        inputId="aria-example-input"
                                                                                                        name="aria-live-color"
                                                                                                        {...field}
                                                                                                        as="select"
                                                                                                        placeholder="Selecione"
                                                                                                        variant="standard"
                                                                                                        margin="normal"
                                                                                                        options={Array.from(elem.resources)}
                                                                                                        getOptionLabel={(option: any) => `${option.descricao}`}
                                                                                                        onChange={(elem: any) => {
                                                                                                            setIdDrivers(elem.id)
                                                                                                            setValue(`resource${index}`, elem.id);
                                                                                                            Suppliers(elem.id, 2, index, elem.resourceTypeId)
                                                                                                            const supplier = watch(`supplier${index}`, false)

                                                                                                            UpdateValue(elem.id, 2, index, 1, supplier)
                                                                                                        }}
                                                                                                        value={elem.resources.id}
                                                                                                    />
                                                                                                </div>
                                                                                            :
                                                                                        elem.tipo === 3
                                                                                            ?
                                                                                                <div className="endpoint" >
                                                                                                    <Select
                                                                                                        aria-labelledby="aria-label"
                                                                                                        inputId="aria-example-input"
                                                                                                        name="aria-live-color"
                                                                                                        {...field}
                                                                                                        as="select"
                                                                                                        placeholder="Selecione"
                                                                                                        variant="standard"
                                                                                                        margin="normal"
                                                                                                        options={Array.from(elem.resources)}
                                                                                                        getOptionLabel={(option: any) => `${option.descricao}`}
                                                                                                        onChange={(elem: any) => {
                                                                                                            setIdGuide(elem.id)
                                                                                                            setValue(`resource${index}`, elem.id);
                                                                                                            Suppliers(elem.id, 3, index, elem.resourceTypeId)
                                                                                                            const supplier = watch(`supplier${index}`, false)

                                                                                                            UpdateValue(elem.id, 3, index, 1, supplier)
                                                                                                        }}
                                                                                                        value={elem.resources.id}
                                                                                                    />
                                                                                                </div>
                                                                                            :
                                                                                        elem.tipo === 4
                                                                                            ?
                                                                                                <div className="endpoint" >
                                                                                                    <Select
                                                                                                        aria-labelledby="aria-label"
                                                                                                        inputId="aria-example-input"
                                                                                                        name="aria-live-color"
                                                                                                        {...field}
                                                                                                        as="select"
                                                                                                        placeholder="Selecione"
                                                                                                        variant="standard"
                                                                                                        margin="normal"
                                                                                                        options={Array.from(elem.resources)}
                                                                                                        getOptionLabel={(option: any) => `${option.descricao}`}
                                                                                                        onChange={(elem2: any) => {
                                                                                                            setIdTicket(elem2.id)
                                                                                                            setValue(`resource${index}`, elem.id);
                                                                                                            Suppliers(elem2.id, 4, index, elem.resourceTypeId)
                                                                                                            const supplier = watch(`supplier${index}`, false)

                                                                                                            UpdateValue(elem.id, 4, index, 1, supplier)
                                                                                                        }}
                                                                                                        value={elem.resources.id}
                                                                                                    />
                                                                                                </div>
                                                                                            :
                                                                                        elem.tipo === 5
                                                                                            ?
                                                                                                <div className="endpoint" >
                                                                                                    <Select
                                                                                                        aria-labelledby="aria-label"
                                                                                                        inputId="aria-example-input"
                                                                                                        name="aria-live-color"
                                                                                                        {...field}
                                                                                                        as="select"
                                                                                                        placeholder="Selecione"
                                                                                                        variant="standard"
                                                                                                        margin="normal"
                                                                                                        options={Array.from(elem.resources)}
                                                                                                        getOptionLabel={(option: any) => `${option.descricao}`}
                                                                                                        onChange={(elem: any) => {
                                                                                                            setIdRestaurant(elem.id)
                                                                                                            setValue(`resource${index}`, elem.id);
                                                                                                            Suppliers(elem.id, 5, index, elem.resourceTypeId)
                                                                                                            const supplier = watch(`supplier${index}`, false)

                                                                                                            UpdateValue(elem.id, 5, index, 1, supplier)
                                                                                                        }}
                                                                                                        value={elem.resources.id}
                                                                                                    />
                                                                                                </div>
                                                                                            :
                                                                                        elem.tipo === 6
                                                                                            ?
                                                                                                <div className="endpoint">
                                                                                                    <Select
                                                                                                        aria-labelledby="aria-label"
                                                                                                        inputId="aria-example-input"
                                                                                                        name="aria-live-color"
                                                                                                        {...field}
                                                                                                        as="select"
                                                                                                        placeholder="Selecione"
                                                                                                        variant="standard"
                                                                                                        margin="normal"
                                                                                                        options={Array.from(addResources)}
                                                                                                        getOptionLabel={(option: any) => `${option.descricao}`}
                                                                                                        onChange={(elem: any) => {
                                                                                                            setResource(elem.id)
                                                                                                            setValue(`resource${index}`, elem.id);
                                                                                                            Suppliers(elem.id, addTipo, index, elem.resourceTypeId)
                                                                                                            const supplier = watch(`supplier${index}`, false)

                                                                                                            UpdateValue(elem.id, addTipo, index, 1, supplier) // todo: vehicletype when type = 1
                                                                                                        }}
                                                                                                        value={elem.resources.id}
                                                                                                    />
                                                                                                </div>
                                                                                            :
                                                                                                <div className="endpoint">
                                                                                                    <Select
                                                                                                        aria-labelledby="aria-label"
                                                                                                        inputId="aria-example-input"
                                                                                                        name="aria-live-color"
                                                                                                        {...field}
                                                                                                        as="select"
                                                                                                        placeholder="Selecione"
                                                                                                        variant="standard"
                                                                                                        margin="normal"
                                                                                                        options={Array.from(addResources)}
                                                                                                        getOptionLabel={(option: any) => `${option.descricao}`}
                                                                                                        onChange={(elem: any) => {
                                                                                                            setResource(elem.id)
                                                                                                            setValue(`resource${index}`, elem.id);
                                                                                                            Suppliers(elem.id, addTipo, index, elem.resourceTypeId)
                                                                                                            const supplier = watch(`supplier${index}`, false)

                                                                                                            UpdateValue(elem.id, addTipo, index, 1, supplier) // todo: vehicletype when type = 1
                                                                                                        }}
                                                                                                        value={elem.resources.id}
                                                                                                    />
                                                                                                </div>
                                                                            }
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="2">
                                                                {/* <Form.Label>RECURSO</Form.Label> */}
                                                                <Controller
                                                                    control={control}
                                                                    name={`supplier${index}`}
                                                                    render={({ field }: any) => (

                                                                        elem.supplier !== null
                                                                            ?
                                                                            <>
                                                                                <span className="d-flex justify-content-center" style={{ height: "36px", alignItems: "center", textAlign: "center" }}>
                                                                                    {elem.supplier === "" ? "??" : elem.supplier}
                                                                                </span>
                                                                            </>
                                                                            :
                                                                            <div className="endpoint" >
                                                                                <Form.Select
                                                                                    aria-labelledby="aria-label"
                                                                                    inputId="aria-example-input"
                                                                                    name="aria-live-color"
                                                                                    {...field}
                                                                                    as="select"
                                                                                    variant="standard"
                                                                                    margin="normal"
                                                                                    onChange={(event: any) => {
                                                                                        const typ = watch(`type${index}`)
                                                                                        const ress = watch(`resource${index}`)
                                                                                        const vehicleType = watch(`vehicleType${index}`)
                                                                                        //console.log('nootnoot',event)

                                                                                        UpdateValue(ress, addTipo, index, vehicleType, event.target.value) 

                                                                                        field.onChange(event);
                                                                                    }}
                                                                                >
                                                                                    <option value="" selected disabled>Selecione</option>
                                                                                    {/* {
                                                                                        item !== null
                                                                                            ?
                                                                                            item[index].map((vehicles: any) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value={`${vehicles?.id}`}>{vehicles?.descricao}</option>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            <></>
                                                                                    } */}
                                                                                    {
                                                                                        elem.tipo === 1
                                                                                            ?
                                                                                            supplierIdVehicles.map((vehicles: any) => {
                                                                                                return (
                                                                                                    <>
                                                                                                        <option value={`${vehicles.supplierId}`}>{vehicles.compFantasia}</option>
                                                                                                    </>
                                                                                                )
                                                                                            })
                                                                                            :
                                                                                            elem.tipo === 2
                                                                                                ?
                                                                                                supplierIdDrivers.map((vehicles: any) => {
                                                                                                    return (
                                                                                                        <>
                                                                                                            <option value={`${vehicles.supplierId}`}>{vehicles.compFantasia}</option>
                                                                                                        </>
                                                                                                    )
                                                                                                })
                                                                                                :
                                                                                                elem.tipo === 3
                                                                                                    ?
                                                                                                    supplierIdGuide.map((vehicles: any) => {
                                                                                                        return (
                                                                                                            <>
                                                                                                                <option value={`${vehicles.supplierId}`}>{vehicles.compFantasia}</option>
                                                                                                            </>
                                                                                                        )
                                                                                                    })
                                                                                                    :
                                                                                                    elem.tipo === 4
                                                                                                        ?
                                                                                                        insertSupplierTicket[elem.resourceTypeId] !== undefined ?


                                                                                                            insertSupplierTicket[elem.resourceTypeId].map((vehicles: any) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <option value={`${vehicles.supplierId}`}>{vehicles.compFantasia}</option>
                                                                                                                    </>
                                                                                                                )
                                                                                                            })
                                                                                                            :
                                                                                                            <></>
                                                                                                        :
                                                                                                        elem.tipo === 5
                                                                                                            ?
                                                                                                            supplierIdRestaurant.map((vehicles: any) => {
                                                                                                                return (
                                                                                                                    <>
                                                                                                                        <option value={`${vehicles.supplierId}`}>{vehicles.compFantasia}</option>
                                                                                                                    </>
                                                                                                                )
                                                                                                            })
                                                                                                            :
                                                                                                            elem.tipo === 6
                                                                                                                ?
                                                                                                                insertSupplier.map((vehicles: any) => {
                                                                                                                    return (
                                                                                                                        <>
                                                                                                                            <option value={`${vehicles.supplierId}`}>{vehicles.compFantasia}</option>
                                                                                                                        </>
                                                                                                                    )
                                                                                                                })
                                                                                                                :
                                                                                                                ""
                                                                                    }
                                                                                </Form.Select>
                                                                            </div>

                                                                    )}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="2">
                                                                {/* <Form.Label>STATUS</Form.Label> */}
                                                                <Controller
                                                                    control={control}
                                                                    name={`status${index}`}
                                                                    render={({ field }: any) => (
                                                                        <div className="endpoint" >
                                                                            <Form.Select
                                                                                aria-labelledby="aria-label"
                                                                                inputId="aria-example-input"
                                                                                name="aria-live-color"
                                                                                {...field}
                                                                                as="select"
                                                                                variant="standard"
                                                                                margin="normal"
                                                                                defaultValue={elem.tipo !== 6 || elem.id !== 0 ? elem.status : ""}
                                                                            >
                                                                                <option value='' selected disabled>Selecione</option>
                                                                                <option value='1'>Pendente</option>
                                                                                <option value='2'>Solicitação</option>
                                                                                <option value='3'>Confirmado</option>
                                                                            </Form.Select>
                                                                        </div>
                                                                    )}
                                                                />
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="1">
                                                                {/* <Form.Label>DETALHE</Form.Label> */}
                                                                <Form.Group as={Col} md="12" className="d-flex text-center" style={{fontSize: "0.8rem"}}>
                                                                    <Form.Group as={Col} md="6" style={{fontWeight: "bolder"}}>
                                                                        Adt
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="6" style={{fontWeight: "bolder"}}>
                                                                        Chd
                                                                    </Form.Group>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="12" className="d-flex text-center" style={{fontSize: "0.8rem"}}>
                                                                    <Form.Group as={Col} md="6">
                                                                        {elem.qtdeAdultReal ? elem.qtdeAdultReal : '--'}
                                                                    </Form.Group>
                                                                    <Form.Group as={Col} md="6">
                                                                        {elem.qtdeChildReal ? elem.qtdeChildReal : '--'}
                                                                    </Form.Group>
                                                                </Form.Group>
                                                                <Form.Group as={Col} md="12" style={{fontSize: "0.8rem"}}>
                                                                    <div><span className="pr-1" style={{fontWeight: "bolder"}}>Notas:</span> {elem.notasGuia ? elem.notasGuia : ''}</div>
                                                                </Form.Group>
                                                            </Form.Group>

                                                            <Form.Group as={Col} md="1">
                                                                {/* <Form.Label>VALOR</Form.Label> */}
                                                                <span className="d-flex justify-content-center" style={{ height: "36px", alignItems: "center" }}>
                                                                <Controller
                                                                    control={control}
                                                                    name={`valor${index}`}
                                                                    defaultValue={elem.valorNet}
                                                                    render={({ field }: any) => (
                                                                        <>
                                                                            {field.value ? field.value.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : (0).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                                                        </>
                                                                    )}
                                                                />

                                                                <Controller
                                                                    control={control}
                                                                    name={`temTarifario${index}`}
                                                                    defaultValue={elem.temTarifario}
                                                                    render={({ field }: any) => (
                                                                        field.value === 2 ?
                                                                            <OverlayTrigger
                                                                                overlay={
                                                                                    <Tooltip id="tooltip">Recurso/Fornecedor sem tariafario associado</Tooltip>
                                                                                }>
                                                                                <span className="text-wrap-custom">
                                                                                    <FontAwesomeIcon icon={"exclamation-triangle"} color={"orange"} className='ml-1' />
                                                                                </span>
                                                                            </OverlayTrigger>
                                                                        :
                                                                            <></>
                                                                    )}
                                                                />
                                                                </span>
                                                            </Form.Group>

                                                            <div className="d-flex justify-content-end align-items-center" style={{ width: "100px" }}>
                                                                {
                                                                    elem.tipo === 6 && elem.id === 0
                                                                        ?
                                                                        <ModalConfirm
                                                                            handleSubmit={handleSubmit}
                                                                            onSubmit={onSubmitInsert}
                                                                            manifestInsert="yes"
                                                                            confirm={responseText}
                                                                            modalContent={modalResponse}
                                                                            log={log}
                                                                            index={index}
                                                                            elem={elem}
                                                                        />
                                                                        :
                                                                        elem.id === 0
                                                                            ?
                                                                            // <ModalConfirm
                                                                            //     handleSubmit={handleSubmit}
                                                                            //     onSubmit={onSubmitInsert}
                                                                            //     manifestInsert="yes"
                                                                            //     confirm={responseText}
                                                                            //     modalContent={modalResponse}
                                                                            //     log={log}
                                                                            //     index={index}
                                                                            //     elem={elem}
                                                                            // />
                                                                            <div className="table-default m-0">
                                                                                <Dropdown drop="start">
                                                                                    <Dropdown.Toggle variant="light ">
                                                                                        <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item eventKey="1" disabled={false} className="d-flex align-items-center"/* onClick={handleSubmit((data: any) => onSubmitUpdate(data, index, elem))} */>
                                                                                            <FontAwesomeIcon
                                                                                                icon={['fal', 'plus']}
                                                                                                //className="mr-2"
                                                                                            />
                                                                                            <ModalConfirm
                                                                                                handleSubmit={handleSubmit}
                                                                                                onSubmit={onSubmitInsert}
                                                                                                manifestInsert="yes"
                                                                                                confirm={responseText}
                                                                                                modalContent={modalResponse}
                                                                                                log={log}
                                                                                                index={index}
                                                                                                elem={elem}
                                                                                            />
                                                                                        </Dropdown.Item>

                                                                                        <Dropdown.Item eventKey="1" disabled={elem.id === -1} className="d-flex align-items-center">
                                                                                            <FontAwesomeIcon
                                                                                                icon={['fal', 'trash-alt']}
                                                                                                //className="mr-2"
                                                                                            />
                                                                                            <ModalConfirm
                                                                                                //handleSubmit={handleSubmit}
                                                                                                confirmSubmit={handleActionResourceTypeRemoveModal}
                                                                                                onSubmit={handleRemoveResource}
                                                                                                manifestRemove="yes"
                                                                                                confirm={responseText}
                                                                                                modalContent={modalResponse}
                                                                                                log={log}
                                                                                                //index={index}
                                                                                                elem={elem}
                                                                                            />
                                                                                            {/* <ModalQuestion
                                                                                                modalShow={resourceDeleteModalShow}
                                                                                                setModalShow={setResourceDeleteModalShow}
                                                                                                titulo="Excluir Recurso"
                                                                                                mensagem="Tem Certeza que deseja excluir este item? Essa ação sera permanente."
                                                                                                handleActionConfirmModal={handleActionResourceDeleteModal}
                                                                                                //handleActionCancelModal={handleActionCancelDeleteClick}
                                                                                            /> */}
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                            :
                                                                            <div className="table-default m-0">
                                                                                <Dropdown drop="start">
                                                                                    <Dropdown.Toggle variant="light ">
                                                                                        <FontAwesomeIcon
                                                                                            size="lg"
                                                                                            icon={["fal", "ellipsis-h"]}
                                                                                        />
                                                                                    </Dropdown.Toggle>

                                                                                    <Dropdown.Menu>
                                                                                        <Dropdown.Item
                                                                                            eventKey="1"
                                                                                            disabled={false}
                                                                                            className="d-flex align-items-center">

                                                                                            <FontAwesomeIcon
                                                                                                icon={['fal', 'edit']}
                                                                                                //className="mr-2"
                                                                                            />
                                                                                            <ModalConfirm
                                                                                                handleSubmit={handleSubmit}
                                                                                                onSubmit={onSubmitUpdate}
                                                                                                manifestEdit="yes"
                                                                                                confirm={responseText}
                                                                                                modalContent={modalResponse}
                                                                                                log={log}
                                                                                                index={index}
                                                                                                elem={elem}
                                                                                            />
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item eventKey="1" disabled={false} className="d-flex align-items-center" /* onClick={() => SupplierNotice(elem)} */>
                                                                                            <FontAwesomeIcon
                                                                                                icon={['fal', 'exclamation-triangle']}
                                                                                                //className="mr-2"
                                                                                            />
                                                                                            <ModalConfirm
                                                                                                //handleSubmit={handleSubmit}
                                                                                                onSubmit={SupplierNotice}
                                                                                                textDropdown="yes"
                                                                                                confirm={responseText}
                                                                                                modalContent={modalResponse}
                                                                                                log={log}
                                                                                                //index={index}
                                                                                                elem={elem}
                                                                                            />
                                                                                        </Dropdown.Item>
                                                                                        
                                                                                        <Dropdown.Item eventKey="1" disabled={elem.voucher === '' && elem.virtualVoucher === 2} className="d-flex align-items-center">
                                                                                            <FontAwesomeIcon
                                                                                                icon={["fal", "camera"]}
                                                                                                //size="1x"
                                                                                                //style={{ marginRight: "1px" }}
                                                                                            />
                                                                                            <div className="d-flex justify-content-start w-100">
                                                                                                <div className="w-100" onClick={(e: any) => elem.virtualVoucher === 1 ? handleVirtualVoucherViewClick(elem.manifestId) : handleVoucherViewClick(elem.manifestId)}>Visualizar Voucher</div>
                                                                                            </div>
                                                                                        </Dropdown.Item>
                                                                                        <Dropdown.Item eventKey="2" disabled={elem.id === -1} className="d-flex align-items-center">
                                                                                            <FontAwesomeIcon
                                                                                                icon={['fal', 'trash-alt']}
                                                                                                //className="mr-2"
                                                                                            />
                                                                                            <ModalConfirm
                                                                                                //handleSubmit={handleSubmit}
                                                                                                confirmSubmit={handleActionResourceDeleteModal}
                                                                                                onSubmit={handleRemoveResource}
                                                                                                manifestRemove="yes"
                                                                                                confirm={responseText}
                                                                                                modalContent={modalResponse}
                                                                                                log={log}
                                                                                                //index={index}
                                                                                                elem={elem}
                                                                                            />
                                                                                            {/* <ModalQuestion
                                                                                                modalShow={resourceDeleteModalShow}
                                                                                                setModalShow={setResourceDeleteModalShow}
                                                                                                titulo="Excluir Recurso"
                                                                                                mensagem="Tem Certeza que deseja excluir este item? Essa ação sera permanente."
                                                                                                handleActionConfirmModal={handleActionResourceDeleteModal}
                                                                                                //handleActionCancelModal={handleActionCancelDeleteClick}
                                                                                            /> */}
                                                                                        </Dropdown.Item>
                                                                                    </Dropdown.Menu>
                                                                                </Dropdown>
                                                                            </div>
                                                                }
                                                                {/* <Dropdown drop="start">
                                                                    <Dropdown.Toggle variant="light "> 
                                                                        <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                                                                    </Dropdown.Toggle>

                                                                    <Dropdown.Menu>
                                                                        <Dropdown.Item eventKey="1" disabled={false}>
                                                                            <FontAwesomeIcon icon={['fal', 'save']} className="mr-2" />
                                                                            <span onClick={handleSubmit((data: any) => onSubmitInsert(data, index, elem))}>Inserir</span>
                                                                        </Dropdown.Item>
                                                                        <Dropdown.Item eventKey="2" disabled={false}>
                                                                            <FontAwesomeIcon icon={['fal', 'edit']} className="mr-2" />
                                                                            <span onClick={handleSubmit((data: any) => onSubmitUpdate(data, index, elem))}>Atualizar</span>
                                                                        </Dropdown.Item>
                                                                    </Dropdown.Menu>
                                                                </Dropdown> */}
                                                            </div>

                                                        </div>
                                                    </Row>
                                                    <hr />
                                                </>
                                                : <></>
                                            }
                                        </Form>
                                    </>
                                );
                            })
                            : <></>
                    }
                </div>
            </div>
            <ModalResourceVoucherViewer 
                voucherViewModalShow={voucherViewModalShow} 
                setVoucherViewModalShow={setVoucherViewModalShow} 
                voucher={voucher} 
            />
        </>
    )
}

export default SessionResources;