/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from "../../../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Form from "react-bootstrap/Form";
import SingleCalendarEditable from '../../../../../../components/Calendar/SingleCalendarEditable';
import GetCostCenterEdit from '../../../../../../components/C2Points/GetCostCenterEdit';
//import GetPartnersFilter from '../../../../../../components/C2Points/GetPartnersFilter';
import GetAccountingAccountsFilter from '../../../../../../components/C2Points/GetAccountingAccountsFilter';
import GetAgentsEdit from '../../../../../../components/C2Points/GetAgentsEdit';
import GetPaymentMethodsFilter from '../../../../../../components/C2Points/GetPaymentMethodsFilter';
import GetAccountOperatorsFilter from '../../../../../../components/C2Points/GetAccountOperatorsFilter';

export interface propInsert {
    show?: any;
    setResponseText?: any,
    setModalContent?: any,
    setLog?: any,
    info: any
}

const EditDetached: React.FC<propInsert> = ({
    show, setResponseText, setModalContent, setLog, info
}: propInsert) => {

    const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }
    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
        
      }

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>('');
    const [pagRecorrente, setPagRecorrente] = useState<any>(false);
    const [file, setFile] = useState<any>(null);
    const [fileName, setFileName] = useState<any>('');
    const [errorRateio, setErrorRateio] = useState<any>(false);
    const [date, setDate] = useState<any>(info ? formatDate(info.data) : '');
    const [vencimento, setVencimento] = useState<any>(info ? formatDate(info.vencimento) : '');
    const [loading, setLoading] = useState<any>(false);
    const [erroData, setErroData] = useState<any>(false);
    const [erroVencimento, setErroVencimento] = useState<any>(false);

    const [client, setClient] = useState<any>(info ? {supplierIdentity:info.agente} : null);

    const [categoria, setCategoria] = useState<any>(info ? info.contaContabilId : null);
    const [descricao, setDescricao] = useState<any>(info ? info.tour : '');
    const [observacao, setObservacao] = useState<any>(info ? info.observacao : '');
    const [formaPagamento, setFormaPagamento] = useState<any>(info ? {id:info.formaPagamentoId} : null);
    const [operadora, setOperadora] = useState<any>(info ? {id:info.operadoraId, descricao:info.operadora} : null);
    const [valorAtual, setValorAtual] = useState<any>(info ? formatValue(info.valor) : '');
    const [mdr, setMdr] = useState<any>(info ? formatValue(info.mdr) : '');

    const [centroCusto, setCentroCusto] = useState<any>(info ? {id:info.centroCustoId} : null);

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    

    const fileHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFile(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();
    // } = useForm({
    //     defaultValues: {
    //         newPaymentsId: info ? info.newPaymentsId : 0,
    //         categoria: info ? info.contaContabilId : '',
    //         descricao: info ? info.tour : '',
    //         formaPagamento: info ? info.formaPagamentoId : '',
    //         operadora: info ? info.operadoraId : '',
    //         valor: info ? formatValue(info.valor) : '',
    //         mdr: info ? formatValue(info.mdr) : '',
    //         fornecedor: info ? info.agente : '',
    //         observacao: info ? info.observacao : '',
    //         centroCusto: info && info.centroCustoId ? info.centroCustoId : ''
    //     }
    // });

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-');
        var dueArray: any = due.split('-');

        if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
            return false;
        }
        if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
            return false;
        }
        if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
            return false;
        }
        return true;
    }

    function handleSetFormaPagamento(formaPagamentoP: any) {
        setOperadora(null);
        setMdr('');
        setFormaPagamento(formaPagamentoP);
    }

    async function handleSetOperadora(operadoraP: any) {
        setOperadora(operadoraP);
        //var mdr = 0;
        var valorAtualF = valorAtual && valorAtual !== '' ? valorAtual.replace(',','.') : 0;
    
        const calcAccountOperatorMdr = async () => {
            try {
              const token = localStorage.getItem('GroupId') || '';
              const config = {
                  headers: { 'Authorization': `Bearer ${token}` },
              };
              
              const { data } = await api.post('BillsReceivable/CalcAccountOperatorMdr', {
                id: operadoraP.id,
                installments: info?.parcelas ? info.parcelas : 1,
                amount: valorAtualF
              }, config);
              if (data.status !== 400) {
                var response = data.data;
      
                if(response.log !== 1) {
                  setMdr(formatValue(response.data));
                } else {
                  setMdr('');
                }
              }   
            }catch(error: any) {
              //setModalMessage("Erro, tente novamente mais tarde");
              //setLog(1);
              //setModalResponse("error");
            }
        }
        await calcAccountOperatorMdr();

        // if(operadoraP.tipoTaxa === 1) { // 
        //   mdr = operadoraP.taxa;
        // } else {
        //   mdr = valorAtualF * operadoraP.taxa / 100;
        // }

        // if(operadoraP.temTaxaMin === 1) {
        //   mdr = mdr + operadoraP.taxaMin;
        // }

        // //console.log(mdr);
        // if(mdr) setMdr(formatValue(mdr));
        // else setMdr('');
    }

    function handleValorChange(e: any) {
        const newValue = e.target.value;
        setValorAtual(newValue);
    }
    
    function handleMdrChange(e: any) {
        const newValue = e.target.value;
        setMdr(newValue);
    }

    function handleDescricaoChange(evt: any) {
        const newValue = evt.target.value;
        setDescricao(newValue);
    }

    function handleObservacaoChange(evt: any) {
        const newValue = evt.target.value;
        setObservacao(newValue);
    }

    const onSubmit = (data: any) => {
        setLoading(true)
        
        //console.log(categoria); return;

        if(date === '') {
            setErroData(true);
        } else {
            setErroData(false);
        }

        if(vencimento === '') {
            setErroVencimento(true);
        } else {
            setErroVencimento(false);
        }

        if(date === '' || vencimento === '') {
            return;
        }
        
        data.newPaymentsId = info.newPaymentsId;

        data.data = fixDate(date);
        data.vencimento = fixDate(vencimento);

        data.formaPagamento = formaPagamento.id;
        data.agente = client.supplierIdentity;
        data.categoria = categoria;//.id;
        data.centroCusto = centroCusto ? centroCusto.id : 0;
        data.operadora = operadora ? operadora.id : null;
        data.valor = parseFloat( valorAtual ? valorAtual.replace(',','.') : 0 );
        data.mdr = parseFloat( mdr ? mdr.replace(',','.') : 0 );
        
        data.observacao = observacao;
        data.descricao = descricao;
        
        // ; return;

        setModalContent("loading");
        if (verifyDate(data.data, data.vencimento)) {
            const insertConta = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post(`/BillsReceivable/UpdateDetachedBill`,
                        {
                            "id": data.newPaymentsId, // id do pagamento receber (se for 0 insere)
                            "descricao": data.descricao,
                            "agente": data.fornecedor, // identity do agente/cliente
                            "data": data.data,
                            "contaContabil": data.categoria, // id da conta contabil
                            "centroCusto":  data.centroCusto, // id do centro custo
                            "currency": 'BRL', // moeda
                            "formaPagamento": data.formaPagamento, // id da forma pagamento
                            "valor": data.valor,
                            "mdr": data.mdr, // valor do mdr
                            "operadora": data.operadora, // id da operadora/bandeira
                            "vencimento": data.vencimento ? data.vencimento : null, // data do vencimento
                            "observacao": data.observacao,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config);
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                        console.log(res)
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                    setLoading(false);
                    closeModal();
                    show(true);
                } catch (error: any) {
                    setLoading(false);
                    setModalContent("error");
                    
                }
            };

            insertConta();
        } else {
            setModalShow(true);
            setModalContent("error");
            setModalMessage("Data de vencimento antes da data atual.");
            setLoading(false)
        }
            
    }

    useEffect(() => {
        
        if(info) {
            // setClient({supplierId: 0, supplierIdentity: info.agente, compName: info.agenteName});
            // setOperadora({id:info.operadoraId, descricao:info.operadora});
            // setValorAtual(formatValue(info.valor));
            // setMdr(formatValue(info.mdr));
            // setDate(formatDate(info.data));
            // setVencimento(formatDate(info.vencimento));

            const getPaymentMethodInfo = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.get(`/BillsReceivable/GetPaymentMethodById/${info.formaPagamentoId}`, config);
                    if (res.status !== 400) {
                        var dados = res.data.data;
                        setFormaPagamento(dados);
                    } 
                } catch (error: any) {
                    
                }
            };

            getPaymentMethodInfo();
        }

    }, [info]);

    if(info !== null) {
        return (
            <>
                <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div>
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCat">
                                    <Form.Label>Conta Contabil</Form.Label>
                                    <Controller
                                        control={control}
                                        name="categoria"
                                        rules={{ required: { value: categoria ? false : true, message: "required" }, }}
                                        //defaultValue={categoria?.id}
                                        defaultValue={categoria}
                                        render={({ field }: any) => (
                                            <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel="Categoria" setFilterAccount={setCategoria} />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="categoria"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
    
                                <Form.Group as={Col} controlId="formGridDesc">
                                    <Form.Label>Descrição</Form.Label>
                                    <Controller
                                        control={control}
                                        name="descricao"
                                        rules={{ required: { value: descricao === '', message: "required" }, }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.descricao ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                value={descricao}
                                                defaultValue={descricao}
                                                onChange={handleDescricaoChange}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="descricao"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
    
                            <Row>
                                <Form.Group as={Col} className="mb-3" controlId="formGridDate">
                                    <Form.Label>Data</Form.Label>
                                    <InputGroup
                                        hasValidation
                                        className={` ${erroData === true ? "endpoint-error" : ""}`} 
                                        size="sm"
                                        placeholder="Quando?"
                                    >
                                        <SingleCalendarEditable setValue={setDate} date={date} />
                                    </InputGroup>
                                    {erroData === true ?
                                        <small style={{color: 'red'}}>required</small>
                                    :<></>}
                                </Form.Group>
    
                                <Form.Group as={Col} className="mb-3" controlId="formGridVenc">
                                    <Form.Label>Vencimento</Form.Label>
                                    <InputGroup
                                        hasValidation
                                        className={` ${erroVencimento === true ? "endpoint-error" : ""}`}
                                        size="sm"
                                        placeholder="Quando?"
                                    >
                                        <SingleCalendarEditable setValue={setVencimento} date={vencimento} />
                                    </InputGroup>
                                    {erroVencimento === true ?
                                        <small style={{color: 'red'}}>required</small>
                                    :<></>}
                                </Form.Group>
                            </Row>
    
    
                            <Row >
                                <Form.Group as={Col} md={3} controlId="formGridDesc">
                                    <Form.Label>Método Pagamento</Form.Label>
                                    {/* {formaPagamento !== null ? */}
                                    <Controller
                                        control={control}
                                        name="formaPagamento"
                                        rules={{ required: { value: !formaPagamento || formaPagamento.id === 0, message: "required" }, }}
                                        defaultValue={formaPagamento?.id}
                                        render={({ field }: any) => (
                                            <GetPaymentMethodsFilter propsField={field} propsErrors={errors} propsLabel="Método Pagamento" setPaymentMethod={handleSetFormaPagamento} />
                                        )}
                                    />
                                    {/* :<></>} */}
                                    <ErrorMessage
                                        errors={errors}
                                        name="formaPagamento"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md={3} controlId="formGridCat">
                                    <Form.Label>Valor</Form.Label>
                                    {/* <Form.Control autoComplete='off' type="text" /> */}
                                    <Controller
                                        control={control}
                                        name="valor"
                                        rules={{ required: { value: valorAtual === '', message: "required" }, }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.valor ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                onChange={handleValorChange}
                                                value={valorAtual}
                                                // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="valor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                {formaPagamento?.possuiOperador === 1 ?
                                <Form.Group as={Col} md={3} className="mb-3" controlId="formGridVenc">
                                    <Form.Label>Operadora / Bandeira</Form.Label>
                                    <Controller
                                        control={control}
                                        name="operadora"
                                        rules={{ required: { value: true, message: "required" }, }}
                                        defaultValue={operadora?.id}
                                        render={({ field }: any) => (
                                            <GetAccountOperatorsFilter propsField={field} propsErrors={errors} propsLabel="Operadora / Bandeira" contaAdquirente={(formaPagamento?.tipo === 1) ? 2 : 1 } setAccountOperator={handleSetOperadora}  />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="operadora"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                : <></>}
                                {formaPagamento?.possuiMdr === 1 ?
                                <Form.Group as={Col} md={3} className="mb-3" controlId="formGridVenc">
                                    <Form.Label>MDR</Form.Label>
                                    <Controller
                                        control={control}
                                        name="mdr"
                                        rules={{ required: { value: mdr === '', message: "required" }, }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="number"
                                                min="0"
                                                aria-invalid={errors?.mdr ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                onChange={handleMdrChange}
                                                value={mdr}
                                                // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="mdr"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                : <></>}
                            </Row>
    
                            <hr style={{ margin: "30px 0" }} />
    
                            <Row className="mb-3">
                                <Form.Group as={Col} md={6} controlId="formGridSupplier">
                                    <Form.Label>Cliente</Form.Label>
                                    <Controller
                                        control={control}
                                        name="fornecedor"
                                        rules={{ required: { value: !client, message: "required" }, }}
                                        defaultValue={client?.supplierIdentity}
                                        render={({ field }: any) => (
                                            <GetAgentsEdit
                                                propsField={field}
                                                propsErrors={errors}
                                                propsLabel="Cliente"
                                                setClient={setClient}
                                            />
    
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="fornecedor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
    
                                <Form.Group as={Col} md={6} className="mb-3" controlId="formGridCenter">
                                    <Form.Label>Centro de Custo</Form.Label>
                                    <Controller
                                        control={control}
                                        name="centroCusto"
                                        defaultValue={centroCusto?.id}
                                        render={({ field }: any) => (
                                            <GetCostCenterEdit propsField={field} propsErrors={errors} propsLabel="Centro de Custo" setCostCenter={setCentroCusto} />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="centroCusto"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
    
                            <Row className="mb-3">
                                <Form.Group as={Col} controlId="formGridCity">
                                    <Form.Label>Observação</Form.Label>
                                    <Controller
                                        control={control}
                                        name="observacao"
    
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.observacao ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                as="textarea"
                                                rows={4}
                                                defaultValue={observacao}
                                                value={observacao}
                                                onChange={handleObservacaoChange}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="observacao"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                        </div>
                        <div className='mt-4 d-flex justify-content-end'>
                            <button
                                className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className="btn btn-default btn-primary px-4 text-uppercase"
                                type="submit"
    /*                             onClick={() => {
                                    setLoading(true)
                                }} */
                            >
                                {
                                    loading === true
                                        ?
                                        <div className="load"></div>
                                        :
                                        "Salvar"
                                }
                            </button>
                        </div>
                    </div>
                </Form>
            </>
        )
    } else {
        return (<></>);
    }
    
}

export default EditDetached;