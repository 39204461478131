import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./ModalContentPayment.scss";
import api from "../../../../services/api";

export interface propContent {
    modalContent: any;
    checkoutResponse: any;
    resetCart: any;
    setModalResponse: any;
    //setModalShow: any;
    pix: boolean;
    //getValues: any;
    watchLanguage?: any
}

const ModalContentPayment: React.FC<propContent> = ({
    modalContent,
    checkoutResponse,
    resetCart,
    setModalResponse,
    //setModalShow,
    pix,
    //getValues,
    watchLanguage
}: propContent) => {
    const { t } = useTranslation();

    const [downloadTicketIntegration, setDownloadTicketIntegration] = useState<boolean>(false);
    const [urlDownload, setUrlDownload] = useState<string>("");
    const [fileName, setFileName] = useState<string>("Voucher");
    const [texts, setTexts] = useState<any>();
    const [scanResponse, setScanResponse] = useState<any>(null); // pixSuccess / pixFailed / pixExpired
    const [pixResponse, setPixResponse] = useState<any>();

    const [minutes, setMinutes] = useState<any>(10);
    const [seconds, setSeconds] = useState<any>(0);

    const downloadVou = () => {
        const link = document.createElement("a");
        link.href = urlDownload;
        link.setAttribute("download", fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    };

    const getVou = async () => {
        //setDownloadTicketIntegration(true);

        const token = localStorage.getItem("GroupId") || "{}";
        const config = {
            headers: {
                Authorization: `Bearer ${token}`,
                "Content-Type": "application/json",
            },
        };
        try {
            const { data } = await api.get(
                `${process.env.REACT_APP_SERVER_URL_API}/checkout/PdfSecondWayHaveNovaXs/${checkoutResponse.data.data.reservationLoc}`,
                config
            );
            if (data.status !== 400) {
                if (data.data.log !== 1) {
                    setUrlDownload(
                        `data:${data.data.data.voucherFile?.contentType};base64, ${data.data.data.voucherFile?.content}`
                    );
                    setFileName(data.data.data.voucherFile?.fileName);
                    setDownloadTicketIntegration(false);
                }
            }
        } catch (error: any) { }
    };

    // TODO voucher
    useEffect(() => {
        // if (checkoutResponse !== "" && checkoutResponse?.data?.log === 0) {
        //     setUrlDownload(
        //         `data:${checkoutResponse.data.data.voucherFile?.contentType};base64, ${checkoutResponse.data.data.voucherFile?.content}`
        //     );
        //     setDownloadTicketIntegration(checkoutResponse.data.data.novaXsMultiClubs);
        //     setFileName(checkoutResponse.data.data.voucherFile?.fileName);

        //     if (checkoutResponse?.data?.data?.novaXsMultiClubs === true) {
        //         let myInterval = setInterval(() => {
        //             getVou();
        //         }, 45000);

        //         return () => {
        //             clearInterval(myInterval);
        //         };

        //         /* setTimeout(() => {
        //                             getVou();
        //                         }, (checkoutResponse.data.data.awaitTime * 1000)); */
        //     }
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checkoutResponse]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        let timer1 = setTimeout(() => setTexts(t("groupsAndCustomized.modalPayment.loading01")), 1000);
        let timer2 = setTimeout(() => setTexts(t("groupsAndCustomized.modalPayment.loading02")), 2000);
        let timer3 = setTimeout(() => setTexts(t("groupsAndCustomized.modalPayment.loading03")), 3000);
        let timer4 = setTimeout(() => setTexts(t("groupsAndCustomized.modalPayment.loading04")), 4000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
        };
    }, [t]);

    // ???
    useEffect(() => {
        // if (modalContent === "success" && pix === true) {
        //     if (
        //         parseInt(checkoutResponse.data.log) === 0 &&
        //         checkoutResponse.data.data[0] !== undefined
        //     ) {
        //         let connection = new signalR.HubConnectionBuilder()
        //             .withUrl(process.env.REACT_APP_SERVER_URL_API_PIX + "/streaming/")
        //             .build();

        //         connection.on(checkoutResponse.data.data[0].data.channelPix, (data) => {
        //             if (data.log === 0) {
        //                 //success
        //                 console.log(data);
        //                 setPixResponse(data);
        //                 setScanResponse("pixSuccess");
        //             } else if (data.log === 1) {
        //                 //error
        //                 setPixResponse(data);
        //                 setScanResponse("pixFailed");
        //             }
        //         });

        //         connection
        //             .start()
        //             .then(function () {
        //                 connection.invoke(
        //                     "JoinToGroup",
        //                     checkoutResponse.data.data[0].data.channelPix
        //                 );
        //             })
        //             .catch(function (err) {
        //                 return console.error(err.toString());
        //             });
        //         let closeChannel = setTimeout(() => {
        //             if (scanResponse !== "pixSuccess" || scanResponse !== "pixFailed") {
        //                 setScanResponse("pixExpired");
        //             }
        //             connection.stop();
        //         }, 600000 /* 660000 */);
        //         return () => {
        //             clearTimeout(closeChannel);
        //         };
        //     }
        // } else {
        // }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent]);

    function copyText(text: string | null = null) {
        if (text === null) {
            var text_to_copy = (document.getElementById("qrcodelink") as HTMLElement)
                .innerHTML;

            if (!navigator.clipboard) {
            } else {
                navigator.clipboard.writeText(text_to_copy);
            }
        } else {
            const urlOrigin: any = text.split("?")[0]
            const token: any = text.split("?")[1];
            let lang: any = "";

            if(watchLanguage === '1') {
                lang = "?";
            } else if (watchLanguage === '2') {
                lang = "/en?";
            } else {
                lang = "/es?";
            }
            
            const link:any = urlOrigin + lang + token;

            if (!navigator.clipboard) {
            } else {
                navigator.clipboard.writeText(link);
            }
        }
    }

    function whatsappFunction(link: string | null = null) {
        // if (link !== null) {
        //     link = link.replaceAll(":", "%3A");
        //     link = link.replaceAll("/", "%2F");
        //     link = link.replaceAll("?", "%3F");
        //     let whatsappLink: string = `https://wa.me/${getValues("phone").replaceAll('+','').replaceAll(' ','')}?text=${link}`;
        //     return (whatsappLink)
        // } else {
            return '';
        // }
    }

    if (modalContent === "loading") {
        return (
            <div className="sucess-pos" id="wrapper">
                <div className="text-center">
                    <div className="load"></div>
                    <h5>{t("groupsAndCustomized.modalPayment.finalizingPurchase")}</h5>
                    <p>{texts}</p>
                </div>
            </div>
        );
    //} else if (modalContent === "success") {
    } else {
            const qrCodeInfo = checkoutResponse?.data?.data?.paymentLink;
           
            if (scanResponse === null) {
                return (
                    <>
                    <div>
                      {
                        checkoutResponse?.data?.log === 2 || checkoutResponse?.data?.log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          checkoutResponse?.data?.log === 1 || checkoutResponse?.data?.log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            checkoutResponse?.data?.log === 0 || checkoutResponse?.data?.log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                        {checkoutResponse?.data?.texto}
                    </div>
                    <div className="d-flex justify-content-center">
                        {qrCodeInfo ?
                        <div className="modal-btn-controller">
                            <Button
                                variant="outline-primary"
                                className="mt-4 mx-auto"
                                type="button"
                                style={{ width: 'unset' }}
                                onClick={() => { copyText(qrCodeInfo) }}
                            >
                                Copiar link
                            </Button>
                        </div>
                        :<></>}
                      
                        <button
                            onClick={() => setModalResponse(false)}
                            className="btn btn-primary mx-2 w-25"
                        >
                            {t('billsToPay.table.confirm')}
                        </button>
                    </div>
                  </>
                );
            // } else if (scanResponse === "pixSuccess") {
            //     return (
            //         <>
            //             <div className="sucess-pos" id="wrapper">
            //                 <div className="text-center">
            //                     {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
            //                     <FontAwesomeIcon
            //                         icon={["fal", "check-circle"]}
            //                         size="5x"
            //                         className="color-primary"
            //                     />
            //                     <h4 className="mt-4">
            //                         {t("modalPayment.creditCard.success.newOrder")}
            //                     </h4>
            //                     <p className="color-success">
            //                         {t("modalPayment.creditCard.success.purchaseSuccess")}
            //                     </p>
            //                     <p>
            //                         {t("modalPayment.creditCard.success.locNumber")}{" "}
            //                         <strong>{pixResponse?.data?.reservationLoc}</strong>
            //                     </p>
            //                     <p className="mb-0">
            //                         <small>{t("modalPayment.creditCard.success.voucher")}</small>
            //                     </p>
            //                     {/* <p><small>{t("modalPayment.creditCard.success.contact")}</small></p>
            //                     <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}

            //                     <p>
            //                         <small>
            //                             <em>{t("modalPayment.creditCard.success.spam")}</em>
            //                         </small>
            //                     </p>
            //                 </div>
            //                 <div className="modal-btn-controller">
            //                     <Button
            //                         variant="outline-primary"
            //                         className="mt-5 mx-auto"
            //                         onClick={() => {
            //                             resetCart();
            //                         }}
            //                     >
            //                         {t("modalPayment.creditCard.success.closeBtn")}
            //                     </Button>
            //                 </div>
            //             </div>
            //         </>
            //     );
            // } else if (scanResponse === "pixFailed") {
            //     return (
            //         <>
            //             <div className="sucess-pos" id="wrapper">
            //                 <div className="text-center">
            //                     <FontAwesomeIcon
            //                         icon={["fal", "times-circle"]}
            //                         size="5x"
            //                         className="color-danger"
            //                     />
            //                     <h4 className="mt-4">
            //                         {t("modalPayment.pix.pixFailed.text01")}
            //                     </h4>
            //                     <p>
            //                         {t("modalPayment.pix.pixFailed.text02")}
            //                         <br />
            //                         {t("modalPayment.pix.pixFailed.text03")}{" "}
            //                         {/* <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}
            //                     </p>
            //                 </div>
            //                 <div className="modal-btn-controller">
            //                     <Button
            //                         className="my-5 mx-auto"
            //                         onClick={() => {
            //                             setModalResponse(null);
            //                         }}
            //                     >
            //                         {t("modalPayment.creditCard.success.closeBtn")}
            //                     </Button>
            //                 </div>
            //             </div>
            //         </>
            //     );
            // } else if (scanResponse === "pixExpired") {
            //     return (
            //         <>
            //             <div className="sucess-pos" id="wrapper">
            //                 <div className="text-center">
            //                     <FontAwesomeIcon
            //                         icon={["fal", "exclamation-circle"]}
            //                         size="5x"
            //                         className="color-info"
            //                     />
            //                     <h4 className="mt-4">{t("modalPayment.pix.expired")}</h4>
            //                     <p className="mb-0">
            //                         <small>{t("modalPayment.pix.expiredWarning")}</small>
            //                     </p>
            //                 </div>
            //                 <div className="modal-btn-controller">
            //                     <Button
            //                         className="my-5 mx-auto"
            //                         onClick={() => {
            //                             resetCart();
            //                         }}
            //                     >
            //                         {t("modalPayment.creditCard.success.closeBtn")}
            //                     </Button>
            //                 </div>
            //             </div>
            //         </>
            //     );
            // } else {
            //     return <></>;
            // }
        } else {
            return <></>;
        }
    // } else if (modalContent === "error") {
    //     return (
    //         <div className="sucess-pos" id="wrapper">
    //             <div className="text-center">
    //                 <FontAwesomeIcon
    //                     icon={["fal", "times-circle"]}
    //                     size="5x"
    //                     className="color-danger"
    //                 />
    //                 <h4 className="mt-4">{t("modalPayment.pix.pixFailed.text01")}</h4>
    //                 <p>
    //                     {t("modalPayment.pix.pixFailed.text02")}
    //                     <br />
    //                     {t("modalPayment.pix.pixFailed.text03")}{" "}
    //                     {/* <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}
    //                 </p>
    //             </div>
    //             <div className="modal-btn-controller">
    //                 <Button
    //                     className="my-5 mx-auto"
    //                     onClick={() => {
    //                         setModalResponse(null);
    //                     }}
    //                 >
    //                     {t("modalPayment.creditCard.success.closeBtn")}
    //                 </Button>
    //             </div>
    //         </div>
    //     );
    // } else if (modalContent === "warning") {
    //     if(checkoutResponse.data) {
    //         return (
    //             <div className="sucess-pos" id="wrapper">
    //                 <div className="text-center">
    //                     {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
    //                     <FontAwesomeIcon
    //                         icon={["fal", "exclamation-triangle"]}
    //                         size="5x"
    //                         //className="color-danger"
    //                     />
    //                     {/* <h4 className="mt-4">{t("groupsAndCustomized.modalPayment.creditCard.warning")}</h4> */}
    //                     <p className="mt-4">{checkoutResponse.data.texto}</p>
    //                     {/* <p>Tente novamente com outros dados de pagamento.<br />
    //                         Se persistir, entre em contato pelo e-mail <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a></p> */}
    //                 </div>
    //                 <div className="modal-btn-controller">
    //                     <Button
    //                         className="my-5 mx-auto"
    //                         onClick={() => {
    //                             setModalResponse(null);
    //                         }}
    //                     >
    //                         {t("groupsAndCustomized.modalPayment.creditCard.success.closeBtn")}
    //                     </Button>
    //                 </div>
    //             </div>
    //         );
    //     } else {
    //         return (
    //             <div className="sucess-pos" id="wrapper">
    //                 <div className="text-center">
    //                     {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
    //                     <FontAwesomeIcon
    //                         icon={["fal", "exclamation-triangle"]}
    //                         size="5x"
    //                         //className="color-danger"
    //                     />
    //                     {/* <h4 className="mt-4">{t("groupsAndCustomized.modalPayment.creditCard.warning")}</h4> */}
    //                     <p className="mt-4">{checkoutResponse}</p>
    //                     {/* <p>Tente novamente com outros dados de pagamento.<br />
    //                         Se persistir, entre em contato pelo e-mail <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a></p> */}
    //                 </div>
    //                 <div className="modal-btn-controller">
    //                     <Button
    //                         className="my-5 mx-auto"
    //                         onClick={() => {
    //                             setModalResponse(null);
    //                         }}
    //                     >
    //                         {t("groupsAndCustomized.modalPayment.creditCard.success.closeBtn")}
    //                     </Button>
    //                 </div>
    //             </div>
    //         );
    //     }
    // } else {
    //     return <></>;
    }
};

export default ModalContentPayment;
