import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import api from "../../../../services/api";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addToComboObject } from "../../../../store/combo";
import { ErrorMessage } from "@hookform/error-message";
import ReactQuillComponent from "../../../../components/ReactQuillComponent/ReactQuillComponent";

export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
}

const Insctructions: React.FC<operation> = React.memo(({
    action, isEdit, editFunction = () => { }
}: operation) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */

    const [check, setCheck] = useState<boolean>(false);
    const [infoAdd, setInfoAdd] = useState<any>([
        {
            info: "Nome completo",
            name: "fullName",
            value: check,
        },
        {
            info: "Data de nascimento",
            name: "birthDate",
            value: check,
        },
        {
            info: "Gênero",
            name: "gender",
            value: check,
        },
        {
            info: "RG / CPF / Passaporte",
            name: "document",
            value: check,
        },
        {
            info: "Nacionalidade",
            name: "nacionality",
            value: check,
        },
        {
            info: "Telefone",
            name: "phone",
            value: check,
        },
        {
            info: "Whatsapp",
            name: "whatsApp",
            value: check,
        },
    ]);

    const [allowEdit, setAllowEdit] = useState<boolean>(false);

    const [instructionsPT, setInstructionsPT] = useState<string>("");
    const [instructionsEN, setInstructionsEN] = useState<string>("");
    const [instructionsES, setInstructionsES] = useState<string>("");

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        setError,
        watch,
        formState: { errors },
    } = useForm({});

    const watchTravellerInfoIsNeed = watch(`isPersonalInfoNeeded`, reduxComboObject?.isPersonalInfoNeeded ? reduxComboObject.isPersonalInfoNeeded : '2');

    let numberPT: any = instructionsPT.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
    let numberEN: any = instructionsEN.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
    let numberES: any = instructionsES.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;

    useEffect(() => {
        window.scrollTo(0, 0);
        if (reduxComboObject?.voucherInstructions !== undefined && reduxComboObject?.voucherInstructions.length > 0) {
            setInstructionsPT(reduxComboObject.voucherInstructions[0].voucherInstruction[0].text);
            setValue("voucherInstructions[0].voucherInstruction[0].text", reduxComboObject.voucherInstructions[0].voucherInstruction[0].text);
            setValue("voucherInstructions[0].voucherInstruction[0].text", reduxComboObject.voucherInstructions[0].voucherInstruction[0].lang);
            setInstructionsEN(reduxComboObject.voucherInstructions[0].voucherInstruction[1].text);
            setValue("voucherInstructions[0].voucherInstruction[1].text", reduxComboObject.voucherInstructions[0].voucherInstruction[1].text);
            setValue("voucherInstructions[0].voucherInstruction[1].text", reduxComboObject.voucherInstructions[0].voucherInstruction[1].lang);
            setInstructionsES(reduxComboObject.voucherInstructions[0].voucherInstruction[2].text);
            setValue("voucherInstructions[0].voucherInstruction[2].text", reduxComboObject.voucherInstructions[0].voucherInstruction[2].text);
            setValue("voucherInstructions[0].voucherInstruction[2].text", reduxComboObject.voucherInstructions[0].voucherInstruction[2].lang);
        }

        setValue("isPersonalInfoNeeded", reduxComboObject?.isPersonalInfoNeeded + '' || '2')

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function changeInfoAdd(value: any, index: any) {
        let aux: any = infoAdd;
        aux[index].value = !aux[index].value;
        setInfoAdd(aux);
        setCheck(!check);
    }


    const handleBlur = (e: any) => {
        if (allowEdit === false) {
            let ptText: any = getValues().voucherInstructions[0].voucherInstruction[0].text/* instructionsPT */;
            TranslateTextEN(ptText);
            TranslateTextES(ptText);
        }
    };

    const TranslateTextEN = async (name: string) => {
        if (name !== undefined) {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "en",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setInstructionsEN(res?.data?.data?.translations[0]?.translatedText);
                    setValue(`voucherInstructions[0].voucherInstruction[1].text`/* "instructionsEN" */, res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        }
    };
    /* Testando api de tradução */
    const TranslateTextES = async (name: string) => {
        if (name !== undefined) {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "es",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setInstructionsES(res?.data?.data?.translations[0]?.translatedText);
                    setValue('voucherInstructions[0].voucherInstruction[2].text'/* "instructionsES" */, res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        }
    };

    const onSubmit = (data: any) => {
        const comboCode = isEdit ? window.location.href.split('comboCode=')[1] : undefined;

        let dataToInsert: any = data;
        console.log(dataToInsert)
        dataToInsert.voucherInstructions = dataToInsert.voucherInstructions.map((elem: any) => {
            return (
                {
                    ...elem, comboCode: comboCode
                }
            )
        })

        if (dataToInsert.voucherInstructions[0].voucherInstruction[0].text === '' && dataToInsert.voucherInstructions[0].voucherInstruction[1].text === '' && dataToInsert.voucherInstructions[0].voucherInstruction[2].text === '') {
            delete dataToInsert.voucherInstructions;
        }
        console.log(dataToInsert)
        dataToInsert.isPersonalInfoNeeded = dataToInsert.isPersonalInfoNeeded || '2';

        dataToInsert.fullName = dataToInsert.fullName === true || parseInt(dataToInsert.fullName) === 1 ? '1' : '2';
        dataToInsert.birthDate = dataToInsert.birthDate === true || parseInt(dataToInsert.birthDate) === 1 ? '1' : '2';
        dataToInsert.gender = dataToInsert.gender === true || parseInt(dataToInsert.gender) === 1 ? '1' : '2';
        dataToInsert.document = dataToInsert.document === true || parseInt(dataToInsert.document) === 1 ? '1' : '2';
        dataToInsert.nacionality = dataToInsert.nacionality === true || parseInt(dataToInsert.nacionality) === 1 ? '1' : '2';
        dataToInsert.phone = dataToInsert.phone === true || parseInt(dataToInsert.phone) === 1 ? '1' : '2';
        dataToInsert.whatsApp = dataToInsert.whatsApp === true || parseInt(dataToInsert.whatsApp) === 1 ? '1' : '2';

        if (parseInt(dataToInsert.isPersonalInfoNeeded) === 1) {
            if (!(dataToInsert.fullName === '1' || dataToInsert.birthDate === '1' || dataToInsert.gender === '1' || dataToInsert.document === '1' || dataToInsert.nacionality === '1' || dataToInsert.phone === '1' || dataToInsert.whatsApp === '1')) {
                setError('isPersonalInfoNeeded', { type: 'custom', message: 'Favor selecionar pelo menos uma das informações pessoais' })
                return;
            } else {
                setError('isPersonalInfoNeeded', { type: 'custom', message: '' });
            }
        } else {
            dataToInsert.isPersonalInfoNeeded = '2'
        }

        dataToInsert.whichTraveler = dataToInsert.whichTraveler || '2';

        if (isEdit) {
            editFunction(dataToInsert);
        } else {
            dispatch(addToComboObject(JSON.stringify(dataToInsert)));
            action("seo");
        }
    };

    return (
        <>
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content">
                            <Form
                                className="h-100"
                                noValidate
                                validated={false}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h3 className="text-primary pt-title mb-0">Instruções</h3>

                                <div className="pt-content-info">
                                    <Row>
                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="">
                                                    <Form.Label className="label-small">
                                                        Os viajantes precisam fornecer informações pessoais?
                                                    </Form.Label>

                                                    <Row className="mx-0 gap-4">
                                                        <Controller
                                                            control={control}
                                                            name="isPersonalInfoNeeded"
                                                            render={({ field }: any) => (
                                                                <Form.Check
                                                                    {...field}
                                                                    className="col-1 mb-1"
                                                                    type="radio"
                                                                    id={"isPersonalInfoNeeded-1"}
                                                                    value={1}
                                                                    defaultChecked={reduxComboObject?.isPersonalInfoNeeded && parseInt(reduxComboObject.isPersonalInfoNeeded) === 1}
                                                                    required
                                                                    //checked={getValues().isPersonalInfoNeeded === "yes"}
                                                                    label={"Sim"}
                                                                //onClick={() => setIsInfoPersonal(1)}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            control={control}
                                                            name="isPersonalInfoNeeded"
                                                            render={({ field }: any) => (
                                                                <Form.Check
                                                                    {...field}
                                                                    className="col-1 mb-1"
                                                                    type="radio"
                                                                    id={"isPersonalInfoNeeded-2"}
                                                                    value={2}
                                                                    required
                                                                    defaultChecked={reduxComboObject?.isPersonalInfoNeeded === undefined || parseInt(reduxComboObject.isPersonalInfoNeeded) === 2}
                                                                    /* checked={
                                                                        getValues().isPersonalInfoNeeded === "no" ||
                                                                        !getValues().isPersonalInfoNeeded
                                                                    } */
                                                                    label={"Não"}
                                                                //onClick={() => setIsInfoPersonal(2)}
                                                                />
                                                            )}
                                                        />
                                                    </Row>
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`isPersonalInfoNeeded`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                                {watchTravellerInfoIsNeed === '1' && (
                                                    <>
                                                        <Row>
                                                            <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                                <Form.Label className="label-small">
                                                                    Informações solicitadas
                                                                </Form.Label>

                                                                <Row className="mx-0">
                                                                    {infoAdd.map((elem: any, index: any) => {
                                                                        return (
                                                                            <Controller
                                                                                control={control}
                                                                                name={elem.name}
                                                                                defaultValue={(reduxComboObject && reduxComboObject[`${elem.name}`]) || false}
                                                                                render={({ field }: any) => (
                                                                                    <Form.Check
                                                                                        {...field}
                                                                                        id={`infoAdd${index}`}
                                                                                        type="checkbox"
                                                                                        variant="standard"
                                                                                        margin="normal"
                                                                                        label={elem.info}
                                                                                        checked={getValues()[elem.name] === true || parseInt(getValues()[elem.name]) === 1}
                                                                                        onChange={(e: any) => {
                                                                                            changeInfoAdd(e.target.checked, index);
                                                                                            setValue(
                                                                                                `${elem.name}`,
                                                                                                e.target.checked
                                                                                            );
                                                                                        }}
                                                                                        required
                                                                                    />
                                                                                )}
                                                                            />
                                                                        );
                                                                    })}
                                                                </Row>
                                                            </Form.Group>
                                                        </Row>
                                                    </>
                                                )}
                                            </Row>
                                        </Col>
                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="">
                                                    <Form.Label className="label-small">
                                                        Quais viajantes precisam fornecê-las?
                                                    </Form.Label>

                                                    <Row className="mx-0 gap-4">
                                                        <Controller
                                                            control={control}
                                                            name="whichTraveler"
                                                            render={({ field }: any) => (
                                                                <Form.Check
                                                                    {...field}
                                                                    className="col-4 mb-1"
                                                                    type="radio"
                                                                    name="whichTraveler"
                                                                    id={"whichTraveler-1"}
                                                                    value="1"
                                                                    required
                                                                    defaultChecked={reduxComboObject?.whichTraveler && parseInt(reduxComboObject?.whichTraveler) === 1}
                                                                    //checked={isTraveled === 1 ? true : false}
                                                                    label={"Apenas um representante"}
                                                                //onClick={() => setIsTraveled(1)}
                                                                />
                                                            )}
                                                        />

                                                        <Controller
                                                            control={control}
                                                            name="whichTraveler"
                                                            render={({ field }: any) => (
                                                                <Form.Check
                                                                    {...field}
                                                                    className="col-1 mb-1"
                                                                    type="radio"
                                                                    name="whichTraveler"
                                                                    id={"whichTraveler-2"}
                                                                    value="2"
                                                                    required
                                                                    defaultChecked={reduxComboObject?.whichTraveler === undefined || parseInt(reduxComboObject.whichTraveler) === 2}
                                                                    //checked={isTraveled === 2 ? true : false}
                                                                    label={"Todos"}
                                                                //onClick={() => setIsTraveled(2)}
                                                                />
                                                            )}
                                                        />
                                                    </Row>
                                                </Form.Group>
                                            </Row>
                                        </Col>
                                    </Row>


                                </div>

                                <div className="pt-content-info" style={{ border: "none" }}>
                                    <Row>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="mb-2 pt-subtitle">
                                                    Instruções do Voucher
                                                </h5>

                                                <div
                                                    className="btn-edit ml-2"
                                                    style={{ position: "unset" }}
                                                    onClick={() => setAllowEdit(!allowEdit)}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={["fal", "edit"]}
                                                        size="1x"
                                                        className="mr-2"
                                                    />
                                                    Editar
                                                </div>
                                            </div>

                                            <p className="small mb-0">
                                                Crie um texto curto para contar de forma clara e atrativa.
                                            </p>
                                            <p className="small mb-0">
                                                Sugerimos que adicione as seguintes palavras mais buscadas
                                                pelos passageiros no Google: ingresso, excursão, passeio e
                                                atração.
                                            </p>
                                        </div>

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            handleBlur={handleBlur}
                                            name={`voucherInstructions[0].voucherInstruction[0].text`}/* "instructionsPT" */
                                            title="Instruções do Tour (Português)"
                                            errors={errors}
                                            setState={setInstructionsPT}
                                            state={instructionsPT}
                                            isEdit={isEdit}
                                            size={12}
                                            id="instructionsPT"
                                            counter={true}
                                            required={false}
                                            minLength={10}
                                            maxLength={3000}
                                            numberOfCaracters={numberPT}
                                        />

                                        <Controller
                                            control={control}
                                            name={`voucherInstructions[0].voucherInstruction[0].lang`}
                                            defaultValue={1}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            name={`voucherInstructions[0].voucherInstruction[1].text`}/* "instructionsEN" */
                                            title="Instruções do Tour (Inglês)"
                                            errors={errors}
                                            setState={setInstructionsEN}
                                            state={instructionsEN}
                                            isEdit={isEdit}
                                            size={6}
                                            counter={true}
                                            id="instructionsEN"
                                            style={allowEdit === false
                                                ? { backgroundColor: "#e9ecef", opacity: "1" }
                                                : {}
                                            }
                                            required={false}
                                            minLength={10}
                                            maxLength={3000}
                                            numberOfCaracters={numberEN}
                                            disabled={!allowEdit}
                                        />
                                        <Controller
                                            control={control}
                                            name={`voucherInstructions[0].voucherInstruction[1].lang`}
                                            defaultValue={2}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />


                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            name={`voucherInstructions[0].voucherInstruction[2].text`}/* "instructionsES" */
                                            title="Instruções do Tour (Espanhol)"
                                            errors={errors}
                                            setState={setInstructionsES}
                                            state={instructionsES}
                                            isEdit={isEdit}
                                            size={6}
                                            counter={true}
                                            id="instructionsES"
                                            style={allowEdit === false
                                                ? { backgroundColor: "#e9ecef", opacity: "1" }
                                                : {}
                                            }
                                            required={false}
                                            minLength={10}
                                            maxLength={3000}
                                            numberOfCaracters={numberES}
                                            disabled={!allowEdit}
                                        />
                                        <Controller
                                            control={control}
                                            name={`voucherInstructions[0].voucherInstruction[2].lang`}
                                            defaultValue={3}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />

                                    </Row>
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {isEdit !== true ? (
                                        <Row className="mx-0 mt-5">
                                            <Button
                                                variant="outline-primary"
                                                className="form-button mr-3"
                                                onClick={() => action("location")}
                                            >
                                                Voltar
                                            </Button>
                                            <Button type="submit" className="form-button">
                                                Continuar
                                            </Button>
                                        </Row>
                                    ) : (
                                        <Row className=" mx-0 mt-5">
                                            <Button
                                                type="submit"
                                                className="form-button"
                                            >
                                                Salvar
                                            </Button>
                                        </Row>
                                    )}
                                </div>
                            </Form>
                        </Card>
                    </Col>
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    );
});

export default Insctructions;
