import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import Select from 'react-select';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any;
  propsErrors: any;
  supplierIdentity?: any;
  defaultValue?: any;
  setValue: any;
};

const GetAllSource: React.FC<propPlus> = ({
  propsField, propsErrors, supplierIdentity = 'SDO0726', defaultValue = null, setValue
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    async function listModalityForSigXSig() {
      try {
        const { data } = await api.get(`/Products/ListModalityForSigXSig?SupplierIdentity=${supplierIdentity/* SDO0726 */}`);
        if (data?.status !== 400) {
          setInfo(data.data.data);

          if (defaultValue !== null) {
            let aux: any = data.data.data.find((elem: any) => { return (elem.modUniqueCode === defaultValue) })
            setValue("productIntegrationCode", { value: aux.modUniqueCode, label: aux.name });
          }
        }
      } catch (error: any) { }
    }
    listModalityForSigXSig();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (info !== null) {
    const transformed = info.map(({ modUniqueCode, name }: any) => ({ value: modUniqueCode, label: name }));
    return (
      <>
        <div className={propsErrors.coastCenterId || propsErrors.costCenter !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            {...propsField}
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            className="basic-multi-select"
            classNamePrefix="select"
            as="select"
            variant="standard"
            margin="normal"
            placeholder={"Selecione"}
            options={transformed}
            getOptionLabel={(option: any) => `${option.label}`}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetAllSource;