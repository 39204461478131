import React from 'react';

import SuppliersTableUsers from '../../../AccordionUsers/SuppliersTableUsers/SuppliersTableUsers'

export interface propEdit {
    setChangeEdit: any,
    info: any,
    usersEdit?: any;
};

const EditUser: React.FC<propEdit> = ({
    setChangeEdit, info, usersEdit
}: propEdit) => {
    
    return (
        <>
            <div className="border bg-white" id="editUsers">
                <div className="edit-title container-fluid">
                    <h3 className="">Usuários</h3>
                    <button onClick={() => {setChangeEdit(false)}}>EDITAR</button>
                </div>
                <div className="data">
                    <SuppliersTableUsers
                        infoEdit={info.usersSupplierDTO}
                        isEdit={true}
                        disableAddContact={true}
                        actionTableUser={()=>{}}
                        usersEdit={usersEdit}
                         />
                </div>
                <br />
            </div>
        </>
    );
}

export default EditUser;