import React, { Key, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';

import Modal from "react-bootstrap/Modal";

//STYLES
import "./MerchantsTableInstallmentsFees.scss";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
import FormAddInstallmentFees from "../../../../../components/Forms/FormAddInstallmentFees/FormAddInstallmentFees";


export interface propModal {
  action: any
  info: any
  setClose: any
  close: any
};

const MerchantsTableInstallmentsFees: React.FC<propModal> = ({
  action, info, setClose, close
}: propModal) => {
  const { t } = useTranslation();
  const [feeTermModalShow, setFeeTermModalShow] = React.useState(false);
  const [feeTermEdit, setFeeTermEdit] = React.useState(null);
  // const suppliers = objectSuppliers.suppliers.default[0];

  const [controlObject, setControlObject] = useState<any>(info ? info : []);
  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  const [merchantFlags,] = useState<any>([]);

  const handleActionModal = (objectModal: any) => {
    setClose(true);
    setControlObject([...controlObject, objectModal],);
  }

  const handleActionEditModal = (objectModal: any) => {
    setFeeTermEdit(null);

    var aux: any = [...controlObject];

    //console.log(objectModal);
    for (var i: any = 0; i < aux.length; i++) {
      if (aux[i].id === objectModal.id) {

        aux.splice(i, 1, objectModal);

        break;
      }
    }
    setControlObject(aux);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const getMerchantFlagById = (id: any): any => {
    for (var i = 0; i < merchantFlags.length; i++) {
      if (parseInt(merchantFlags[i].id) === parseInt(id)) {
        return merchantFlags[i].description;
      }
    }
    return '';
  }

  useEffect(() => {
    

    action(controlObject, deletedObjects);
  }, [action, /*  */controlObject, deletedObjects])


  function removeRow(index: any) {

    //console.log(controlObject)

    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      } else {
        if (controlObject[i].id > 0) setDeletedObjects([...deletedObjects, controlObject[i].id]);
      }
    }

    setControlObject(aux);
  }

  function editRow(index: any) {
    var aux: any = controlObject[index];
    // chama modal
    setFeeTermEdit(aux);
    setFeeTermModalShow(true);
  }

  const getLastInstallment = (): any => {
    var installments: number[] = controlObject.map((a:any) => a.installmentNumber);
    var maxInstallment = installments.length > 0 ? Math.max(...installments) : 0;
    console.log(installments,"nootnoot");
    return maxInstallment;
  }

  return (
    <>
      <div className="table-add-default merchants-tax-table">
        <div className="table-container">
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <Table striped bordered>
            <thead>
              <tr>
                <th>Parcela</th>
                <th>Taxa Administradora</th>
                <th>Taxa Estabelecimento</th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {controlObject.length > 0
                ? controlObject.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>{info.installmentNumber}</td>
                    <td>{info.adminFee}</td>
                    <td>{info.estabFee}</td>
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={['fal', 'ellipsis-h']}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => editRow(index)}
                              eventKey="1"
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'edit']}
                              //className="mr-2"
                              />
                              {t('suppliersTable.edit')}
                            </Dropdown.Item>
                            {info.installmentNumber === getLastInstallment() ?
                            <Dropdown.Item
                              onClick={() => removeRow(index)}
                              eventKey="2"
                              className="text-danger removeRow">
                              <FontAwesomeIcon
                                icon={['fal', 'trash-alt']}
                                //className="mr-2"
                              />
                              {t('suppliersTable.remove')}
                            </Dropdown.Item>
                            : <></>}
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              <div className="add-contact">
                <div className="text-center btn-add-contact">
                  <ModalDefault
                    title="Taxas de Parcela"
                    classModal="modal-pos-tax"
                    name={'+'}
                    class="btn-add"
                    setClose={setClose}
                    close={close}
                  >
                    <div className="form-add-contact">
                      <FormAddInstallmentFees action={handleActionModal} info={null} lastInstallment={getLastInstallment()} />
                    </div>
                  </ModalDefault>
                </div>
              </div>
            </tbody>
          </Table>

          {
            feeTermEdit !== null
              ?
              <Modal
                className="modal-custom modalAuth modal-pos-tax"
                show={feeTermModalShow}
                onHide={() => setFeeTermModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton>
                    <h3>Faixa de parcelamento</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0" }} />
                  <FormAddInstallmentFees action={handleActionEditModal} info={feeTermEdit} />
                </>
              </Modal>
              :
              <></>
          }

        </div>
      </div>
    </>
  );
}

export default MerchantsTableInstallmentsFees;
