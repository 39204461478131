import React from 'react';

export interface propEdit {
    setChangeEdit: any,
    info: any,
};

const EditAccounting: React.FC<propEdit> = ({
    setChangeEdit, info
}: propEdit) => {

    function convertDate(cell: any) {
        if (cell !== null && cell !== undefined) {
            var aux: any = cell.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return date;
        }
    }

    return (
        <>
            <div className="border" id="editAccounting">
                <div style={{ backgroundColor: "#fff", paddingBottom: "60px" }}>
                    <div className="edit-title container-fluid">
                        <h3 className="">Contábil</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>CONTA CONTÁBIL</p>
                            <p>{info.accountingAccountsDesc ? info.accountingAccountsDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>POSSUI CONTRATO?</p>
                            <p>{/* {
                                parseInt(info.haveContract) === 1
                                    ? "Sim"
                                    : parseInt(info.haveContract) === 2
                                        ? "Não"
                                        : "não definido"
                            } */}
                                {info.haveContract === 1 ? 'Sim' : 'Não'}
                            </p>
                        </div>
                        {info.haveContract === 1
                            ?
                            <>
                                <div className="data-unit col-20">
                                    <p>VALIDADE:</p>
                                    <p>{info.validade ? convertDate(info.validade) : "não definido"}</p>
                                </div>
                                <div className="data-unit col-10">
                                    <p>CONTRATO:</p>
                                    <p>{info.contractName ? info.contractName : "não definido"}</p>
                                </div>
                            </>
                            :
                            <>
                                <div className="data-unit col-20 data-unit-white-bg"></div>
                                <div className="data-unit col-10 data-unit-white-bg"></div>
                            </>
                        }
                        <div className="data-unit">
                            <p>CONTA CORRENTE</p>
                            <p>{info.contaCorrenteDesc ? info.contaCorrenteDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit ">
                            <p>CENTRO DE CUSTO</p>
                            <p>{info.costCenterDesc ? info.costCenterDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit col-20 data-unit-white-bg"></div>
                        <div className="data-unit col-10 data-unit-white-bg"></div>
                    </div>
                </div>
            </div>
            {/* </div> */}
        </>
    );
}

export default EditAccounting;