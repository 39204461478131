/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Dropdown } from "react-bootstrap";
import BootstrapTable from "react-bootstrap-table-next";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "./AddCategoryTable.scss";

export interface propTable {
  loading: any;
  categoryList: any;
  setCategoryList: any;
  info: any;
}

const AddCategoryTable: React.FC<propTable> = ({
  loading,
  categoryList,
  setCategoryList,
  info,
}) => {
  const { t } = useTranslation();

  const [, setEditClick] = useState<any>(false);
  const [, setDetailsModal] = useState<any>(false);
  const [, setCurrentCategory] = useState<any>();
  const [categoryObject, setCategoryObject] = useState<any>([]);

  function seeDetailsEdit(cell: any) {
    setEditClick(true);
    setCurrentCategory(cell);
    setDetailsModal(true);
  }

  const productTypeLabel = [
    '',
    'Tour',
    'Transfer',
    'Carnaval',
    'Ticket'
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: "categories",
      text: "Carregando categorias",
      formatter: loadingFunc,
    },
  ];

  const loadingProducts = [
    { categoryList: "" },
    { categoryList: "" },
    { categoryList: "" },
    { categoryList: "" },
    { categoryList: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "product", text: "Produtos" }];

  const notFoundProducts = [{ product: "Nenhum produto encontrado" }];
  /* [END] In case of empty table*/

  // formatter de status

  //REMOVE BUTTOM
  function removeCategory(id: any) {
    var aux: any = [];
    categoryList.forEach((element: any) => {
      if ((element.productCode !== id)) {
        aux.push(element);
      }
    });
    setCategoryList(aux);
  }

  // useEffect(() => {
  //   if (isEdit === false) {
  //     categoryList (categoryObject);
  //   }
  //   console.log(categoryObject)

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [categoryObject])

  // useEffect(() => {
  //   if (isEdit === true) {
  //     setCategoryObject(infoEdit);
  //   }
  // // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [])
  //REMOVE BUTTOM

  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                className="text-danger remove"
                onClick={() => removeCategory(info?.id)}
              >
                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
                {t("suppliersTable.remove")}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(categoryList);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setCategoryList(items);
  }

  if (categoryList?.length > 0) {
    return (
      <>
        <div className="table-default AddCategories-table">
          <div className="table-container">
            <div>
              {/* <BootstrapTable
                bootstrap4
                keyField="id"
                data={categoryList}
                hover={true}                
                columns={columns}
                striped={true}

              /> */}
              <table className="table table-striped table-hover table-bordered">
                <thead>
                  <tr>
                    <th>Ordem</th>
                    <th>Nome do produto</th>
                    <th>Código</th>
                    <th>Preço</th>
                    <th>Tipo de produto</th>
                    <th>URL key</th>
                    <th>Ações</th>
                  </tr>
                </thead>
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="addLocation">
                    {(provided: any) => (
                      <>
                        <tbody
                          {...provided.droppableProps}
                          ref={provided.innerRef}
                        >
                          {categoryList.map((elem: any, index: any) => {
                            console.log(elem)
                            return (
                              <>
                                <Draggable
                                  key={elem.productCode}
                                  draggableId={elem.productCode || elem.product_code}
                                  index={index}
                                >
                                  {(provided: any) => (
                                    <tr
                                      key={index}
                                      ref={provided.innerRef}
                                      {...provided.draggableProps}
                                      {...provided.dragHandleProps}
                                    >
                                      <td>
                                        <div
                                          className="d-flex drag-icon h-100"
                                          style={{
                                            backgroundColor: "transparent",
                                            cursor: "move",
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "bars"]}
                                          />
                                        </div>
                                      </td>
                                      <td>
                                        <OverlayTrigger
                                          overlay={
                                            <Tooltip id="tooltip">
                                              {elem.productName || elem.product_name}
                                            </Tooltip>
                                          }
                                        >
                                          <span className="text-wrap-custom">
                                            {elem.productName || elem.product_name}
                                          </span>
                                        </OverlayTrigger>
                                      </td>
                                      <td>{elem.productCode || elem.product_code}</td>
                                      <td></td>
                                      <td>{productTypeLabel[elem.productType] || productTypeLabel[elem.tipoProduto]}</td>
                                      <td>{elem.productUrlKey}</td>
                                      <td>
                                        <div className="d-flex justify-content-center">
                                          <div className="dropstart">
                                            <div className="d-flex justify-content-center">
                                              <Dropdown drop="start">
                                                <Dropdown.Toggle variant="light ">
                                                  <FontAwesomeIcon

                                                    size="lg"
                                                    icon={["fal", "ellipsis-h"]}
                                                  />
                                                </Dropdown.Toggle>

                                                <Dropdown.Menu>
                                                  <Dropdown.Item
                                                    eventKey="1"
                                                    className="text-danger remove"
                                                    onClick={() =>
                                                      removeCategory(
                                                        elem?.productCode
                                                      )
                                                    }
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={[
                                                        "fal",
                                                        "trash-alt",
                                                      ]}
                                                      className="mr-2"
                                                    />{" "}
                                                    {t("suppliersTable.remove")}
                                                  </Dropdown.Item>
                                                </Dropdown.Menu>
                                              </Dropdown>
                                            </div>
                                          </div>
                                        </div>
                                      </td>
                                    </tr>
                                  )}
                                </Draggable>
                              </>
                            );
                          })}
                          {provided.placeholder}
                        </tbody>
                      </>
                    )}
                  </Droppable>
                </DragDropContext>
              </table>

              <div className="d-flex justify-content-end pt-4">
                <button
                  className="btn btn-default btn-primary px-4 bg-primary"
                  type="submit"
                >
                  {" "}
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else if (categoryList?.length === 0 && loading === true) {
    return (
      <>
        <div className="table-default AddCategories-table loading">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingProducts}
                hover={true}
                columns={loadingColumns}
                striped={true}
              />
              <div className="d-flex justify-content-end pt-4">
                <button
                  className="btn btn-default btn-primary px-4 bg-primary"
                  type="submit"
                >
                  {" "}
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default AddCategories-table loading not-found">
          <div className="table-container">
            <div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notFoundProducts}
                hover={true}
                columns={notFoundColumns}
                striped={true}
              />
              <div className="d-flex justify-content-end pt-4">
                <button
                  className="btn btn-default btn-primary px-4 bg-primary"
                  type="submit"
                >
                  {" "}
                  Salvar
                </button>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
};

export default AddCategoryTable;
