import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import bg02 from "../../assets/img/backgrounds/Bg-NewPass.jpg";
import AuthNewPass from "./components/AuthNewPass/AuthNewPass";
import { Link } from "react-router-dom";

import "./NewPassword.scss"

function Login() {
  const { t } = useTranslation();

  return (
    <Container fluid className="container-login p-0">
      <Row className="h-100 w-100 m-0">
        <Col
          sm={12}
          className="bg-login-image p-0"
          id="new-pass"
          style={{ backgroundImage: `url(${bg02})` }}
        >
          <div className="split-screen">
            <Row className="h-100 w-100 m-0">
              <Col className="logo-login" sm={12} md={6}>
                <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                  <img src={process.env.REACT_APP_SERVER_LOGO_WHITE} alt="Destinow" />
                </div>
              </Col>
              <Col sm={12} md={6} className="bg-white valign valign-new-pass left p-0 my-auto">
                <div className="inner d-flex flex-column justify-content-between h-100">
                  <div>
                    <h1>{t("login.newPassword.resetPassword")}</h1>
                    <p style={{ width: "85%" }}>
                      {t("login.newPassword.subtitleNew")}
                    </p>
                    <p style={{ width: "90%", color: "red" }}>
                      {t("login.newPassword.validatePassword")}
                    </p>
                  </div>
                  {/* <div className="login-brand d-flex align-items-center justify-content-center">
                    <img
                      src={logo}
                      alt="Destinow"
                      className="float-left"
                      style={{ width: "250px" }}
                    />
                  </div> */}
                  <div>
                    <AuthNewPass />
                    <hr style={{ backgroundColor: "#707070", opacity: "1" }} />
                    <div className="text-center link-new-pass">
                      <Link className="small" to="/">
                        {t("login.forgot.haveAccount?")}
                      </Link>
                    </div>
                  </div>
                  <div className="text-center" style={{ fontSize: "10px" }}>
                    <small>{t("login.development")}</small>
                    <div className="login-brand d-flex align-items-center justify-content-center mt-1">
                      <img
                        src={process.env.REACT_APP_SERVER_LOGO}
                        alt="DestiTech"
                        className="float-left"
                        style={{ width: "100px" }}
                      />
                    </div>
                  </div>
                  {/* <div className="expired-session">
                    <ModalDefault>
                      <>
                        <div className="login-brand d-flex align-items-center justify-content-center my-5">
                          <img
                            src={logo}
                            alt="Destinow"
                            className="float-left"
                            style={{ width: "250px" }}
                          />
                        </div>
                        <div className="text-center">
                          <h1 className="h4 mb-4 mt-4 title-login">
                            Sessão expirada
                          </h1>
                        </div>
                        <AuthLogin />
                      </>
                    </ModalDefault>
                  </div> */}
                </div>
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
