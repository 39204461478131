import React, { useState, Key, useEffect, useRef, } from "react";


import { Button, Container, Modal, Row } from "react-bootstrap";
//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


import InfoProducts from "../InfoProducts/InfoProducts";
import InfoProductsTicket from "../InfoProducts/InfoProductsTicket";

import "./SingleProduct.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import api from "../../../../services/api";
import i18next from "i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface tourList {
  search: any;
  setSearch: any;
  setIsLoading: any;
  tourList: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  handleUpdate: any;
}

let code: any;
let type: any;
let stats: any;

const SingleProduct = ({ search, setSearch, imagesBaseUrl, tourList, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, handleUpdate }: any) => {

  const [tourDetails, setTourDetails] = useState<any>(null);
  const [modalShow, setModalShow] = React.useState(false);
  const [preLoad, setPreLoad] = useState<any>(false);
  const [lang, setLang] = useState<string>("BR");
  const [info, setInfo] = useState<any>([]);

  const [active, setActive] = useState<boolean>(true);
  const [arrayDropdown, setArrayDropdown] = useState<any>([]);
  const [statusProduct, setStatusProduct] = useState<any>([]);

  const [modalStatus, setModalStatus] = useState<boolean>(false);

  const [responseText, setResponseText] = useState<any>('Tem certeza que deseja alterar o status?');
  const [modalContent, setModalContent] = useState<any>('start');
  const [log, setLog] = useState<any>(2);

  useEffect(() => {
    if (i18next.language === "pt-BR" || i18next.language === "pt") {
      setLang("BR")
    } else if (i18next.language === "en") {
      setLang("EN")
    } else {
      setLang("ES")
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]);

  const TourDetailRender = async (productCode: any, type: number) => {
    setModalShow(true);
    try {
      const data = await api.post(
        type !== 4 ? `/Products/FetchSingleTourAsync` : `/Products/FetchSingleTicketAsync`,
        {
          "productCode": productCode,
          "lang": lang,
          "idCanal": localStorage.getItem('idCanal') || 1
        }
      );
      if (data.status !== 400) {
        setPreLoad(false);
        setTourDetails(data.data.data);
      }
    } catch (error) { }
  }

  /* Detecta clique fora da div#wrapper para fechar o menu */
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (!event.target?.classList.value?.includes('status-custom')) {
          setArrayDropdown([]);
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }
  /* //Detecta clique fora da div#wrapper para fechar o menu\\ */

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    async function statusProduct() {
      try {
        const { data } = await api.get(`/dropdown/GetStatusProduct`, config);
        if (data.status !== 400) {
          setInfo(data.data);
        }
      } catch (error: any) { }
    }
    statusProduct();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const UpdateStatus = async (status: any, productCode: any, type: number) => {
    setModalContent('loading');

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    try {
      const data = await api.post(`/Ticket/UpdateStatusProduct`,
        {
          "code": productCode, // codigo do produto
          "status": status, // status baseado no dropdown
          "tipo": type // tipo do produto estar na lista de produtos
        }, config
      );
      if (data.status !== 400) {
        setTimeout(() => {
          setLog(data.data.data.log);
          setResponseText(data.data.data.texto);
          if (data.data.data.log === 0) {
            setModalContent('success');
          } else {
            setModalContent('error');
          }
        }, 200);
      }
    } catch (error) {
      setModalContent('error');
    }
  }

  useEffect(() => {
    let aux: any = [];
    let auxStatus: any = [];

    for (let i = 0; i < tourList?.length; i++) {
      aux.push(false);
      auxStatus.push(tourList[i].status);
    }

    setArrayDropdown(() => aux);
    setStatusProduct(() => auxStatus);

  }, [tourList]);

  function handleDropdown(state: boolean, index: any) {
    let aux: any = [];

    for (let i = 0; i < tourList?.length; i++) {
      aux.push(false);
    }
    setArrayDropdown(() => aux);

    aux[index] = !state;
    setArrayDropdown([...aux]);
  };

  function handleStatus(status: any, index: any, productCode: any, typeP: any) {
    code = productCode;
    type = typeP;
    stats = status;

    //statusProduct[index] = status;
    //setStatusProduct([...statusProduct]);
    setArrayDropdown([]);
  }

  useEffect(() => {
    setTimeout(() => {
      setResponseText('Tem certeza que deseja alterar o status?');
      setModalContent('start');
      setLog(2);
    }, 200);
  }, [modalStatus]);

  return (
    <Container className="px-0" fluid>
      <Container className="my-3 py-0 px-0" fluid>
        <Row>
          <div className="col-12 side-list single-product">
            {preLoad === false ? (
              <>
                {tourList?.length > 0
                  ? tourList.map((tour: any, index: Key) => (
                    <div
                      key={index}
                      className="card card-filter-list w-100 mb-4"
                    >
                      <Row className="mx-0 px-0 h-100">
                        <div className="col-5 col-md-3 px-0 ">
                          <div
                            style={{ height: "100%" }}
                            className="card-img card-img-custom pr-0 p-3"
                          >

                            <div
                              className="bg-product-list"
                              style={{
                                backgroundImage: tour.imagesBaseUrl.includes('googleapis')? `url(${process.env.REACT_APP_SERVER_URL_IMG}${tour.tipoProduto === 1 ? 'images-tour' : 'images-ticket'}/${tour.image})` : `url(${tour.imagesBaseUrl}${tour.image})`,
                              }}
                            >
                              <div>
                                <div className="form-control status-select d-flex justify-content-between status-custom" onClick={() => { handleDropdown(arrayDropdown[index], index); setActive(!active) }}>
                                  {
                                    info?.length > 0
                                      ?
                                      // eslint-disable-next-line array-callback-return
                                      info.map((stat: any, i: any) => {
                                        let circleStatus: any;

                                        if (Number(stat.id) === 1) {
                                          circleStatus = 'bg-primary';
                                        } else if (Number(stat.id) === 2) {
                                          circleStatus = 'bg-warning';
                                        } else if (Number(stat.id) === 3) {
                                          circleStatus = 'bg-success';
                                        } else if (Number(stat.id) === 4) {
                                          circleStatus = 'bg-danger';
                                        } else if (Number(stat.id) === 5) {
                                          circleStatus = 'bg-secondary';
                                        } else {
                                          circleStatus = 'bg-custom';
                                        }

                                        if (statusProduct[index] === Number(stat.id)) {
                                          return (
                                            <div key={i}>
                                              <div className="d-flex align-items-center status-custom">
                                                <span className={`${circleStatus} rounded-circle produto-status mr-2 status-custom`}></span>
                                                {stat.description}
                                              </div>
                                            </div>
                                          )
                                        }
                                      }) : ""
                                  }
                                  <div className="status-custom">
                                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" style={{ width: '8px' }} className="status-custom">
                                      <path className="status-custom" fill="none" stroke="#076fa2" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M2 5l6 6 6-6" />
                                    </svg>
                                  </div>
                                </div>
                                {
                                  arrayDropdown[index] === true
                                    ?
                                    <div className={`bg-status-${index} bg-status-options`} id="wrapper" ref={wrapperRef}>
                                      {
                                        info?.length > 0 ? info.map((stat: any, i: any) => {
                                          let circleStatus: any;

                                          if (Number(stat.id) === 1) {
                                            circleStatus = 'bg-primary';
                                          } else if (Number(stat.id) === 2) {
                                            circleStatus = 'bg-warning';
                                          } else if (Number(stat.id) === 3) {
                                            circleStatus = 'bg-success';
                                          } else if (Number(stat.id) === 4) {
                                            circleStatus = 'bg-danger';
                                          } else if (Number(stat.id) === 5) {
                                            circleStatus = 'bg-secondary';
                                          } else {
                                            circleStatus = 'bg-custom';
                                          }
                                          return (
                                            <span key={i} className={statusProduct[index] === Number(stat.id) ? "status-active status-custom" : "status status-custom"} onClick={() => { handleStatus(Number(stat.id), index, tour.productCode, tour.tipoProduto); setModalStatus(true) }}>
                                              <span className={`${circleStatus} rounded-circle produto-status mr-2`}></span>
                                              {stat.description}
                                            </span>
                                          )
                                        }) : ""
                                      }
                                    </div>
                                    :
                                    ""
                                }
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-7 col-md-9 text-custom py-3"
                        >
                          <div className="h-100" style={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "space-between",
                          }}>
                            <div>
                              <h5 className="product-name mb-3">
                                {tour.productName}
                              </h5>
                              <div className="d-flex">
                                <div style={{ paddingRight: "15px" }}>
                                  <p className="small-custom text-muted">
                                    <strong>Código do produto:</strong>
                                    <span> {tour.productCode}</span>
                                  </p>
                                </div>
                              </div>
                              <div
                                className="text-product-info mb-0"
                                dangerouslySetInnerHTML={{
                                  __html: tour.productInfo,
                                }}
                              ></div>
                            </div>
                            <div>
                              <div>
                                <Button
                                  variant="outline-primary"
                                  className="btn-default mr-2 "
                                  onClick={() => TourDetailRender(tour.productCode, tour.tipoProduto)}
                                >
                                  Detalhes do produto
                                </Button>
                                <Button
                                  className="btn-default mr-2 disabled"
                                //onClick={handleAddProductClick}
                                >
                                  Blackout
                                </Button>
                                <Button
                                  variant="outline-primary"
                                  className="btn-default mr-2"
                                  href={`/products/edit-${tour.tipoProduto === 1 ? 'tour' : 'ticket'}?productCode=${tour.productCode}`}
                                //onClick={handleBlackoutClick}
                                >
                                  Editar
                                </Button>
                                '<Button
                                  variant="outline-primary"
                                  className="btn-default"
                                  href={"/products/stock?productCode=" + tour.productCode}
                                  disabled={tour.status === 3 ? false : true}
                                //onClick={handleBlackoutClick}
                                >
                                  Visualizar Estoque
                                </Button>'
                              </div>
                            </div>
                          </div>
                        </div>
                      </Row>
                    </div>
                  ))
                  : ""}

              </>
            ) : (
              <>
                {[1, 2, 3, 4, 5].map((tour: any, index: Key) => (
                  <div key={index} className="col-12 col-md-10 side-list w-100">
                    <div
                      className="card card-filter-list w-100 mb-4"
                      style={{ height: "342px" }}
                    >
                      <Row>
                        <div className="col-5 col-md-3 img-mobile">
                          <div
                            className="card-img card-img-custom my-3 ml-3 animated-background"
                            style={{ maxWidth: "480px", height: "249px" }}
                          ></div>
                        </div>
                        <div className="col-7 col-md-7 text-custom pt-3">
                          <h5
                            className="tour-name animated-background"
                            style={{ height: "24px" }}
                          >
                            {" "}
                          </h5>
                          <div className="dates d-flex">
                            <div
                              className="animated-background"
                              style={{
                                height: "20px",
                                width: "35px",
                                marginRight: "5px",
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: "20px",
                                width: "35px",
                                marginRight: "5px",
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: "20px",
                                width: "35px",
                                marginRight: "5px",
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: "20px",
                                width: "35px",
                                marginRight: "5px",
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: "20px",
                                width: "35px",
                                marginRight: "5px",
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: "20px",
                                width: "35px",
                                marginRight: "5px",
                              }}
                            ></div>
                            <div
                              className="animated-background"
                              style={{
                                height: "20px",
                                width: "35px",
                                marginRight: "5px",
                              }}
                            ></div>
                          </div>
                          <p
                            className="small text-muted animated-background"
                            style={{ height: "20px", marginBottom: "0.5rem" }}
                          ></p>
                          <p
                            className="small text-muted animated-background"
                            style={{ height: "20px", marginBottom: "0.5rem" }}
                          ></p>
                          <p
                            className="small text-muted animated-background"
                            style={{ height: "20px", marginBottom: "0.5rem" }}
                          ></p>
                          <div
                            className="animated-background"
                            style={{ height: "42px" }}
                          ></div>
                        </div>
                        <div
                          className="col-2 col-md-2 m-auto text-center animated-background"
                          style={{ height: "144px", maxWidth: "190px" }}
                        >
                          <span className="small text-muted w-50"></span>
                          <div className="price"></div>
                        </div>
                        <div className="col-12 col-md-"></div>
                      </Row>
                    </div>
                  </div>
                ))}
              </>
            )}
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </Row>
      </Container>
      <Modal
        className="modal-custom modal-products-info"
        show={modalShow}
        onHide={() => setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        {tourDetails?.typeProduct === "TOUR" ? (
          <InfoProducts
            tourDetails={tourDetails}
            setModalShow={setModalShow} />
        ) : (
          <InfoProductsTicket
            tourDetails={tourDetails}
            setModalShow={setModalShow}
          />
        )}
      </Modal>
      <Modal
        size="lg"
        show={modalStatus}
        onHide={() => setModalStatus(false)}
        aria-labelledby="contained-modal-title-vcenter"
        className="modal-confirm loading-modal"
      >
        <Modal.Body className='modal-body text-center sucess-pos'>
          {
            modalContent !== "loading"
              ?
              <>
                <div>
                  {
                    log === 1 || log === "1"
                      ?
                      <FontAwesomeIcon
                        icon={["fal", "times-circle"]}
                        size="5x"
                        style={{ fontSize: "7.5em", color: "#ff6600" }}
                      />
                      :
                      log === 0 || log === "0"
                        ?
                        <FontAwesomeIcon
                          icon={["fal", "check-circle"]}
                          size="5x"
                          style={{ fontSize: "7.5em", color: "#ff6600" }}
                        />
                        :
                        <FontAwesomeIcon
                          icon={["fal", "question-circle"]}
                          size="5x"
                          style={{ fontSize: "7.5em", color: "#ff6600" }}
                        />
                  }
                </div>
                <div>
                  {responseText}
                </div>
                <div className="d-flex justify-content-center">
                  {
                    modalContent === 'success'
                      ?
                      <>
                        <button
                          onClick={() => { setModalStatus(false); handleUpdate() }}
                          className="btn btn-outline-primary mx-2 w-25"
                        >
                          Ok
                        </button>
                      </>
                      :
                      <>
                        <button
                          onClick={() => setModalStatus(false)}
                          className="btn btn-outline-primary mx-2 w-25"
                        >
                          Cancelar
                        </button>
                        <button
                          onClick={() => UpdateStatus(stats, code, type)}
                          className="btn btn-primary mx-2 w-25"
                        >
                          Confirmar
                        </button>
                      </>
                  }
                </div>
              </>
              :
              <div className="modal-body">
                <div className="text-center">
                  <div className="load"></div>
                </div>
                <div>
                  <span>Processando</span>
                </div>
                <div></div>
              </div>
          }
        </Modal.Body>
      </Modal>
    </Container>
  );
};

export default SingleProduct;