import React, { useState } from 'react';

import { Container } from 'react-bootstrap';

import ImageDefault from "../../../../../../assets/img/image-default.png";

import configData from "../../../../../../config/config.json";
import SuppliersTableCompany from '../../../AccordionInfoCompany/SuppliersTableCompany/SuppliersTableCompany';


export interface propEdit {
    setChangeEdit: any,
    info: any,
};

const EditInfoCompany: React.FC<propEdit> = ({
    setChangeEdit, info
}: propEdit) => {
    const [messageError, setMessageError] = useState<boolean>(false);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [contact, setContact] = useState<any>('');

    const getContacts = (objContact: any) => {
        setContact(objContact);
    }

    return (
        <>
            <Container className="border p-0" id="infoCompany" fluid>
                <div style={{ backgroundColor: "#fff", paddingBottom: "60px" }}>
                    <div className="edit-title container-fluid">
                        <h3 className="">Informações da Empresa</h3>
                        <button onClick={() => { setChangeEdit(false) }}>EDITAR</button>
                    </div>
                    <div className="" style={{ width: "100%" }}>
                        {info.compLogoBase64 !== null ?
                            <img style={{ border: "1px solid black", borderRadius: "50%", width: "140px", height: "140px", marginLeft: "1.5rem" }} src={configData.SERVER_TESTE + 'images/' + info.compLogoName} alt="Logo Empresa" /> :
                            <img style={{ border: "1px solid black", borderRadius: "50%", width: "140px", height: "140px", marginLeft: "1.5rem" }} src={ImageDefault} alt="Logo" />
                        }
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>NOME DA EMPRESA:</p>
                            <p>{info.compName ? info.compName : "não definido"}</p>
                        </div>
                        {info.compType === 1
                            ?
                            <>
                                <div className="data-unit blank"></div>
                            </>
                            :
                            <>
                                <div className="data-unit">
                                    <p>NOME FANTASIA:</p>
                                    <p>{info.compFantasia ? info.compFantasia : "não definido"}</p>
                                </div>
                            </>
                        }
                        <div className="data-unit">
                            <p>WEBSITE:</p>
                            <p>{info.website ? info.website : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>PROMOTOR:</p>
                            <p>{info.promotor ? `${info.promotorDescName} ${info.promotorDescSurName}` : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>Tipo do Fornecedor:</p>
                            <p>{info.supplierTypeDesc ? info.supplierTypeDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>Email da empresa:</p>
                            <p>{info.compEmail ? info.compEmail : "não definido"}</p>
                        </div>

                        {/* <div className="data-unit blank"></div> */}

                        <div className="data-unit">
                            <p>TIPO DE PESSOA</p>
                            <p>
                                {info.compType === 1 ? "Pessoa Física" : info.compType === 2 ? "Pessoa Jurídica" : info.compType === 3 ? "Internacional" : "não definido"}
                            </p>
                        </div>

                        <div className="data-unit blank"></div>
                        <div className="data-unit blank"></div>

                        {info.compType !== 3
                            ?
                            <>
                                <div className="data-unit">
                                    <p>{info.compType === 1 ? "CPF" : info.compType === 2 ? "CNPJ" : "não definido"}</p>
                                    <p>{info.compType === 1 ? info.cpf : info.compType === 2 ? info.cnpj : "não definido"}</p>
                                </div>
                                <div className="data-unit">
                                    <p>{info.compType === 1 ? "Inscrição Estadual" : info.compType === 2 ? "Inscrição Municipal" : "não definido"}</p>
                                    <p>{info.compType === 1 ? info.inscricaoEstadual : info.compType === 2 ? info.inscricaoMunicipal : "não definido"}</p>
                                </div>
                            </>
                            :
                            <div className="data-unit">
                                <p>{"Tax Number"}</p>
                                <p>{info.code}</p>
                            </div>
                        }
                        <div className="data-unit blank"></div>

                        <hr className="w-100" />
                    </div>

                    <div className="edit-title container-fluid">
                        <h3 className="">Endereço</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>PAIS:</p>
                            <p>{info.countryDesc ? info.countryDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>CEP:</p>
                            <p>{info.zipCode ? info.zipCode : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>ESTADO:</p>
                            <p>{info.state ? info.state : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>CIDADE:</p>
                            <p>{info.city ? info.city : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>BAIRRO:</p>
                            <p>{info.compBairro ? info.compBairro : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>ENDEREÇO:</p>
                            <p>{info.address ? info.address : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>NÚMERO:</p>
                            <p>{info.numero !== null ? info.numero : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>COMPLEMENTO:</p>
                            <p>{info.compComplem ? info.compComplem : "não definido"}</p>
                        </div>
                        <div className="data-unit data-unit-white-bg"></div>
                        <hr className="w-100" />
                    </div>

                    <div className="edit-title container-fluid">
                        <h3 className="">Contatos</h3>
                    </div>
                    <div className="data">
                        <SuppliersTableCompany isEdit={true} info={info} setMessageError={setMessageError} messageError={messageError} action={getContacts} disableAddContact={true} />
                    </div>
                </div>
            </Container>
            {/* </div> */}
        </>
    );
}

export default EditInfoCompany;