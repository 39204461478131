import React, { useState, Key } from 'react';
import api from '../../../../services/api';

import { Modal } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";

import "./BillsReceivableSettle.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import FormBillReceivableSettle from './components/FormBillReceivableSettle/FormBillReceivableSettle';
import ModalQuestion from '../../../../components/Modal/ModalQuestion/ModalQuestion';

export interface props {
    singleNoteInfo: any;
    searchSingleNote: any;
    accountId: any;
    accountName: any;

    show: any;
    setModalContent: any;
    setResponseText: any;
    setLog: any;
};

const BillsReceivableSettle: React.FC<props> = ({
    singleNoteInfo, searchSingleNote, accountId, accountName
    , show, setModalContent, setResponseText, setLog
}: props) => {

    //const hoje = (new Date()).toISOString().substring(0,10);


    const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }

    /* function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
            return cell.toFixed(2).replace(".", ",");
        } else {
            return '0,00';
        }
    } */

    const [modalShow, setModalShow] = useState<any>(false);

    const [billEdit, setBillEdit] = useState<any>(null);

    const [billUndoSettlementModalShow, setBillUndoSettlementModalShow] = useState<any>(false);
    const [undoSettlementMsg, setUndoSettlementMsg] = useState<any>('');

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;


    // } = useForm({
    //     defaultValues: {
    //         account: (isCreditNote === true) ? {id:383} : {id:378},
    //         payment: '',
    //         operator: '',
    //         value: '',
    //         mdr: '',
    //         currency: 'BRL',
    //         observation: ''
    //     }
    // });

    /* function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    } */

    function convertDate(elem: any) {
        if (elem !== null) {
            var aux: any = elem.split("T")[0].split("-");
            var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
            return (
                <>
                    {date}
                </>
            );
        } else {
            return (
                <>
                </>
            );
        }
    }

    /* const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    } */

    function handleSettleBill(item: any) {
        setBillEdit(item);
        setModalShow(true);
    }

    function handleUndoSettlement(item: any) {
        setBillEdit(item);

        if(item.idLote > 0) {
            setUndoSettlementMsg("Liquidação em lote, deseja mesmo desfazer a liquidação? A liquidação de todo o lote sera desfeita");
        } else {
            setUndoSettlementMsg("Deseja mesmo desfazer a liquidação do pagamento?");
        }
      
        setBillUndoSettlementModalShow(true);
    }

    function handleActionBillEditHideModal () {
        setModalShow(false);
        setBillEdit(null);
    }

    function handleActionBillEditModal() {
        setModalShow(false);
        setBillEdit(null);
        searchSingleNote(singleNoteInfo.localizador);
    }

    function handleActionBillUndoSettlementModal() {
        setBillUndoSettlementModalShow(false);

        const token = localStorage.getItem('GroupId') || '';

        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        setModalContent("loading");
        show(true);

        const salvar = async () => {
            try {
                const { data } = await api.post('BillsReceivable/UndoBillSettlement', {
                    manifestId: billEdit.id,
                    tipo: billEdit.tipo,
                    uid: uId,
                    usuario: `${userName} ${userLastName}`
                }, config);

                if (data.status !== 400) {
                    var response = data.data;

                    //alert(response.texto);
                    setResponseText(response.texto);
                    setLog(response.log);

                    if(response.log !== 1) {
                        setModalContent("successNoRefresh");
                        searchSingleNote(singleNoteInfo.localizador);

                    } else {
                        setModalContent("error");
                    }
                }   
            }catch(error: any) {
                setResponseText("Erro, tente novamente mais tarde");
                setLog(1);
                setModalContent("error");
                if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';              
                }
                if (error?.response?.status === 400) {
                //window.location.href = window.location.origin + '/session-closure';              
                }
            }
        }
        salvar();
    }


    if (singleNoteInfo) {

        return (
            <>
                <div>
                    <div className='modal-content-note'>
                        <h5 className='mb-4'>Informações da Reserva</h5>
                        <Row>
                            <Col md={3}>
                                <p className='text-uppercase mb-1'>Data</p>
                                <b>{convertDate(singleNoteInfo.dataReserva)}</b>
                            </Col>
                            <Col md={3}>
                                <p className='text-uppercase mb-1'>Agente</p>
                                <b>{singleNoteInfo.agente}</b>
                            </Col>
                            <Col md={3}>
                                <p className='text-uppercase mb-1'>Cliente</p>
                                <b>{singleNoteInfo.cliente}</b>
                            </Col>
                        </Row>
                    </div>
                    <div className='modal-content-note'>
                        <h5 className='mb-4'>Produtos</h5>
                        {
                            singleNoteInfo.items.length > 0
                                ? singleNoteInfo.items.map((elem: any, index: Key) => {
                                    return (
                                        <>
                                            <Row key={index} className={index > 0 ? "mt-3" : ""}>
                                                <Col md={2}>
                                                    <p className='text-uppercase mb-1'>Data</p>
                                                    <b>{convertDate(elem.dataReserva)}</b>
                                                </Col>
                                                <Col md={4}>
                                                    <p className='text-uppercase mb-1'>Serviços</p>
                                                    <b>{elem.tour}</b>
                                                </Col>
                                                <Col md={2}>
                                                    <p className='text-uppercase mb-1'>Valor</p>
                                                    <b>R$ {Math.ceil(elem.valor)},00</b>
                                                </Col>
                                                <Col md={1}>
                                                    <p className='text-uppercase mb-1'>Nº</p>
                                                    <b>{elem.qtdePax}</b>
                                                </Col>
                                                <Col md={3}>
                                                    <p className='text-uppercase mb-1'>Centro de Custo</p>
                                                    <b>{elem.centroCusto}</b>
                                                </Col>
                                            </Row>
                                        </>
                                    )
                                })
                                :
                                <>
                                </>
                        }
                    </div>
                    <div className='modal-content-note'>
                        <h5 className='mb-4'>Recebidos</h5>
                        <Row className='justify-content-between'>
                            <Col md={6}>
                                <Row>
                                    <Col md={6}>
                                        <p className='text-uppercase mb-1'>Total Recebido</p>
                                        <b>R$ {Math.ceil(singleNoteInfo.totalRecebido)},00</b>
                                    </Col>
                                    <Col md={6}>
                                        <p className='text-uppercase mb-1'>Total Serviços</p>
                                        <b>R$ {Math.ceil(singleNoteInfo.totalServicos)},00</b>
                                    </Col>
                                </Row>
                            </Col>
                            <Col md={2}>
                                <p className='text-uppercase mb-1'>Saldo</p>
                                <b>R$ {Math.ceil(singleNoteInfo.totalSaldo)},00</b>
                            </Col>
                        </Row>
                    </div>
                    <div className='modal-content-note single-content-note'>
                        <h5 className='mb-4'>Pagamentos</h5>
                        {
                            singleNoteInfo.bills.length > 0
                                ? singleNoteInfo.bills.map((elem: any, index: Key) => {
                                    return (
                                        <div className={"payments-note " + (elem.statusPagamento === 4 || elem.statusPagamento === 3 ? "border-ativo" : elem.statusPagamento === 2 ? "border-analise" : "border-inativo")} key={index}>
                                            <Row>
                                                <Col>
                                                    <span className="date-payments-note">{ (elem.data ? formatDate(elem.data) + " - " : formatDate(singleNoteInfo.dataReserva) + " - ") + elem.contaContabil }</span>
                                                </Col>
                                            </Row>
                                            <div className="content-payments-note">
                                                <div style={{ width: "15%", paddingRight: "20px" }}>
                                                    <p>Valor</p>
                                                    <b>R$ {elem.valor.toFixed(2).replace(".", ",")}</b>
                                                </div>
                                                <div style={{ width: "12.5%", paddingRight: "20px" }}>
                                                    <p>Currency</p>
                                                    <b>{elem.currency}</b>
                                                </div>
                                                <div style={{ width: "20%", paddingRight: "20px" }}>
                                                    <p>Metodo</p>
                                                    <b>{elem.formaPagamento}</b>
                                                </div>
                                                <div style={{ width: "22.5%", paddingRight: "30px" }}>
                                                    <p>Operadora</p>
                                                    {elem.operadora ?
                                                    <b title={`${elem.operadora}`}>{elem.operadora}</b>
                                                    : <b title={`${accountName}`}>{accountName}</b>}
                                                </div>
                                                <div style={{ width: "15%", paddingRight: "20px" }}>
                                                    <p>Vencimento</p>
                                                    <b>{convertDate(elem.vencimento)}</b>
                                                </div>
                                                <div style={{ width: "15%", paddingRight: "20px" }}>
                                                    <p>Observações</p>
                                                    <b title={`${elem.observacao}`}>{elem.observacao}</b>
                                                </div>
                                                <div style={{ width: "4%", paddingRight: "20px" }}>
                                                    <p>Ações</p>
                                                    {elem.notaAvulsa !== 4 && elem.formaPagamentoId === 2 ?
                                                        (elem.statusPagamento === 2 ?
                                                            <button className="btn btn-default p-0 m-0" style={{width:"20px"}} title="Liquidar" onClick={() => handleSettleBill(elem)}><FontAwesomeIcon
                                                                size="sm"
                                                                icon={["fal", "dollar-sign"]}
                                                                style={{ color: "#707070", margin: "0px", padding: "0px"}}
                                                            /></button>
                                                        : elem.statusPagamento === 4 ?
                                                            <button className="btn btn-default p-0 m-0" style={{width:"20px"}} title="Desfazer Liquidação" onClick={() => handleUndoSettlement(elem)}><FontAwesomeIcon
                                                                size="sm"
                                                                icon={["fal", "strikethrough"]}
                                                                style={{ color: "#707070", margin: "0px", padding: "0px"}}
                                                            /></button>
                                                        : <></>)
                                                    : <></>}
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <>
                                </>
                        }
                    </div>
                </div>

                <Modal
                    className="modalAuth"
                    show={modalShow}
                    onHide={handleActionBillEditHideModal}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                    <Modal.Header className="p-3" closeButton></Modal.Header>
                    <FormBillReceivableSettle 
                        action={handleActionBillEditModal} 
                        info={billEdit} 
                        setModalSuccess={show}
                        setModalResponse={setModalContent}
                        setModalMessage={setResponseText}
                        setLog={setLog}
                        accountId={accountId}
                    />
                    </>
                </Modal>

                <ModalQuestion
                    modalShow={billUndoSettlementModalShow}
                    setModalShow={setBillUndoSettlementModalShow}
                    titulo="Desfazer Liquidação"
                    mensagem={undoSettlementMsg}
                    handleActionConfirmModal={handleActionBillUndoSettlementModal}
                />
            </>
        )
    } else {
        return (
            <></>
        )
    }
}

export default BillsReceivableSettle;