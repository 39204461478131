/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import api from '../../../../services/api';
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GetPaymentMethodsFilter from "../../../../components/C2Points/GetPaymentMethodsFilter";
import GetAccountOperatorsFilter from "../../../../components/C2Points/GetAccountOperatorsFilter";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

import "../../../../assets/sass/modal.scss";
//import SingleCalendarEditable from "../../../../Calendar/SingleCalendarEditable";

export interface propModal {
  action: any,
  info: any,
  fileId: any,
  setModalSuccess: any,
  setModalResponse: any,
  setModalMessage: any,
  setLog: any
};

const FinancialModalBillToReceiveModal: React.FC<propModal> = ({
  action, info, fileId
  , setModalSuccess, setModalResponse, setModalMessage, setLog
}: propModal, props: any) => {

  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  function formatValue(cell: any) {
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  }

  const [formaPagamento, setFormaPagamento] = useState<any>({
    id: info ? info.paymentMethodId : 2,
    descricao: info ? info.paymentMethod : 'Cash',
    possuiMdr: 2,
    possuiOperador: 2,
    tipo: 2,
    alias: 'CASH'
  });

  //const [vencimento, setVencimento] = useState<any>(info ? formatDate(info.vencimento) : '');
  const [valorAtual, setValorAtual] = useState<any>(info ? formatValue(info.amount) : 0);
  const [mdr, setMdr] = useState<any>(info ? formatValue(info.mdr) : 0);
  const [loading, setLoading] = useState<any>(false);

  const [billConciliateModalShow, setBillConciliateModalShow] = useState<any>(false);
  const [billToConciliate, setBillToConciliate] = useState<any>(null);
  const [conciliateMsg, setConciliateMsg] = useState<any>('');

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: info ? info.id : 0,
      tipo: info ? info.paymentType : 1, // 1 = pagamento, 2 = parcela
      formaPagamento: info ? info.paymentMethodId : '',
      operadora: info ? info.operatorId : '',
      valor: info ? formatValue(info.amount) : '',
      mdr: info ? formatValue(info.mdr) : '',
      vencimento: info && info.dueDate ? info.dueDate.substring(0,10) : '',
      nsu: info ? info.nsu : '',
      authorizationCode: info ? info.authorizationCode : '',
      observacao: info ? info.observation : '',
    },
  });

  const onSubmitForm = (dataObj: any) => {
    dataObj.valor = valorAtual;
    dataObj.mdr = mdr;
    
    if(formaPagamento.possuiMdr === 1 && mdr === '') {
      //alert('preencha o valor do mdr');
      setModalResponse("error");
      setModalMessage("preencha o valor do mdr");
      setLog(1);
      return;
    }

    if(dataObj.formaPagamento && dataObj.formaPagamento.id) dataObj.formaPagamento = dataObj.formaPagamento.id;
    if(dataObj.operadora && dataObj.operadora.id) dataObj.operadora = dataObj.operadora.id;
    if(!dataObj.operadora) dataObj.operadora = 0;

    if(dataObj.valor !== '') {
      dataObj.valor = parseFloat(dataObj.valor.replace(',','.'));
    } else {
      dataObj.valor = 0;
    }
    if(dataObj.mdr !== '') {
      dataObj.mdr = parseFloat(dataObj.mdr.replace(',','.'));
    } else {
      dataObj.mdr = 0;
    }
    
    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const today = new Date();
    const todayStr = `${today.getFullYear()}-${today.getMonth()+1}-${today.getDate()}`;
    
    const params = (dataObj.id === 0) ? {
      fileId: fileId,
      date: todayStr, 
      paymentMethodId: dataObj.formaPagamento, 
      amount: dataObj.valor,
      mdr: dataObj.mdr, 
      operatorId: dataObj.operadora, 
      nsu: dataObj.nsu,
      authorizationCode: dataObj.authorizationCode,
      dueDate: dataObj.vencimento, 
      observation: dataObj.observacao
    } : dataObj;

    

    const token = localStorage.getItem('GroupId') || '';

    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    setLoading(true);
    setModalResponse("loading");
    setModalSuccess(true);

    const salvar = async () => {
      const route = (dataObj.id === 0) ? 'GroupFile/CreateFileBillToReceive' : 'BillsReceivable/UpdateBill';

      try {
        const { data } = await api.post(route, params, config);
        if (data.status !== 400) {
          var response = data.data;

          //alert(response.texto);
          setModalMessage(response.texto);
          setLog(response.log);

          if(response.log !== 1) {
            setModalResponse("success");
            action(data);
          } else {
            setModalResponse("error");
          }
        }   
      }catch(error: any) {
        setModalMessage("Erro, tente novamente mais tarde");
        setLog(1);
        setModalResponse("error");
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
      }

      setLoading(false);
    }
    salvar();
  };

  const onConciliate = (data: any) => {

    const agora = new Date();
    const agoraStr = convertUTCDateToLocalDate(agora);

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    const usuario = `${user.nome} ${user.lastName}`;

    setConciliateMsg('"Conciliado por '+usuario+' em '+agoraStr+'" confirma?');
    setBillToConciliate(data);
    setBillConciliateModalShow(true);
  };

  function handleActionBillConciliateModal(e: any) {
    setBillConciliateModalShow(false);

    billToConciliate.conciliate = 1;

    onSubmitForm(billToConciliate);
  }

  async function handleSetOperadora(operadora: any) {

    //var mdr = 0;
    var valorAtualF = valorAtual && valorAtual !== '' ? valorAtual.replace(',','.') : 0;

    const calcAccountOperatorMdr = async () => {
      try {
        const token = localStorage.getItem('GroupId') || '';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        
        const { data } = await api.post('BillsReceivable/CalcAccountOperatorMdr', {
          id: operadora.id,
          installments: info?.installments ? info.installments : 1,
          amount: valorAtualF
        }, config);
        if (data.status !== 400) {
          var response = data.data;

          if(response.log !== 1) {
            setMdr(formatValue(response.data));
            setValue('mdr', formatValue(response.data));
          } else {
            setMdr('');
            setValue('mdr', '');
          }
        }   
      }catch(error: any) {
        //setModalMessage("Erro, tente novamente mais tarde");
        //setLog(1);
        //setModalResponse("error");
      }
    }
    await calcAccountOperatorMdr();

    // if(operadora.tipoTaxa === 1) { // 
    //   mdr = operadora.taxa;
    // } else {
    //   mdr = valorAtualF * operadora.taxa / 100;
    // }

    // if(operadora.temTaxaMin === 1) {
    //   mdr = mdr + operadora.taxaMin;
    // }
    
    // if(mdr) setMdr(formatValue(mdr));
    // else setMdr('');
  }

  function handleValorChange(e: any) {
    const newValue = e.target.value;
    setValorAtual(newValue);
  }

  function handleMdrChange(e: any, field: any) {
    const newValue = e.target.value;
    setMdr(newValue);
    field.onChange(e);
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    async function listSuppliers() {
      try {
        const { data } = await api.get(`BillsReceivable/GetPaymentMethodById/${info.paymentMethodId}`, config);

        if (data.status !== 400) {
          setFormaPagamento(data.data);
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
      try {
        if(info.operatorId) {
          const { data } = await api.get(`BillsReceivable/GetAccountOperatorById/${info.operatorId}`, config);

          if (data.status !== 400) {
            handleSetOperadora(data.data);
          }
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    if(info) listSuppliers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  /* function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    return (
      <>
        {date}
      </>
    );
  } */

  //if(info !== null) {
    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row>
              <h5 className="contact-title mb-5">Recebimento</h5>
              <Form
                className="form-add-contact"
                noValidate
              >
                <div className="">
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Forma Pagamento:</Form.Label>
                      {info === null || info.paymentType !== 2 ?
                      <Controller
                        control={control}
                        name="formaPagamento"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <GetPaymentMethodsFilter propsField={field} propsErrors={errors} propsLabel='Forma Pagamento' setPaymentMethod={setFormaPagamento} />
                        )}
                      />
                      :
                      <div className="p-2">{info?.paymentMethod}</div>
                      }
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                      <Form.Label>Valor:</Form.Label>
                      <Controller
                        control={control}
                        name="valor"
                        //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            aria-invalid={errors?.valor ? "true" : ""}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required
                            onChange={handleValorChange}
                            value={valorAtual}
                          />
                        )}
                      />
                    </Form.Group>
                    {formaPagamento.possuiMdr === 1 ?
                    <Form.Group as={Col} md="2">
                      <Form.Label>MDR:</Form.Label>
                        <Controller
                          control={control}
                          name="mdr"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.mdr ? "true" : ""}
                              autoComplete='off'
                              onChange={(e: any) => handleMdrChange(e,field)}
                              value={mdr}
                              variant="standard"
                              
                              margin="normal"
                              required />
                          )}
                        />
                    </Form.Group>
                    :<></>}
                  </Row>
                  <Row className="mb-3">
                    {formaPagamento.possuiOperador === 1 ?
                    <Form.Group as={Col} md="6">
                      <Form.Label>Operadora / Bandeira:</Form.Label>
                      {info === null || info.paymentType !== 2 ?
                      <Controller
                        control={control}
                        name="operadora"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <GetAccountOperatorsFilter propsField={field} propsErrors={errors} propsLabel='Operadora / Bandeira' contaAdquirente={(formaPagamento.tipo === 1) ? 2 : 1 } setAccountOperator={handleSetOperadora} />
                        )}
                      />
                      :
                      <div className="p-2">{info?.operator}</div>}
                    </Form.Group>
                    :<></>}
                    <Form.Group as={Col} md="3">
                      <Form.Label>NSU:</Form.Label>
                      {info === null || info.paymentType !== 2 ?
                      <Controller
                        control={control}
                        name="nsu"
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            autoComplete='off'
                            variant="standard"
                            margin="normal"
                            disabled={formaPagamento.possuiMdr !== 1}
                          />
                        )}
                      />
                      :
                      <div className="p-2">{info?.nsu}</div>
                      }
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                      <Form.Label>Cód. Autorização:</Form.Label>
                      {info === null || info.paymentType !== 2 ?
                      <Controller
                        control={control}
                        name="authorizationCode"
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            autoComplete='off'
                            variant="standard"
                            margin="normal"
                            disabled={formaPagamento.possuiMdr !== 1}
                          />
                        )}
                      />
                      :
                      <div className="p-2">{info?.authorizationCode}</div>
                      }
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="3">
                      <Form.Label>Vencimento:</Form.Label>
                        {/* <SingleCalendarEditable setValue={setVencimento} date={vencimento} /> */}
                      <Controller
                        control={control}
                        name="vencimento"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="date"
                            {...field}
                            aria-invalid={errors?.vencimento ? "true" : ""}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required />
                        )}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Observação:</Form.Label>
                      <Controller
                        control={control}
                        name="observacao"
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required />
                        )}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Form>

              <ModalQuestion
                modalShow={billConciliateModalShow}
                setModalShow={setBillConciliateModalShow}
                titulo="Conciliar Pagamento"
                mensagem={conciliateMsg}
                handleActionConfirmModal={handleActionBillConciliateModal}
                //handleActionCancelModal={handleActionCancelDeleteClick}
              />
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Row className="buttons-modal d-flex justify-content-end mx-0">
            {info?.paymentStatus === 1 ?
            <Button
              type="button"
              className="btn btn-primary px-4 mr-3 text-uppercase"
              onClick={handleSubmit(onConciliate)}
              disabled={loading===true}
            >
              <FontAwesomeIcon
                icon={["fal", "check-circle"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Conciliar
            </Button>
            : <></>}
            <Button
              type="button"
              className="btn btn-primary px-4 mr-3 text-uppercase"
              onClick={handleSubmit(onSubmitForm)}
              disabled={loading===true}
            >
              <FontAwesomeIcon
                icon={["fal", "save"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Salvar 
            </Button>
          </Row>
        </Modal.Footer>


      </>
    );
  //} else {
  //  return (<></>);
  //}
}

export default FinancialModalBillToReceiveModal;
