import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import './Users.scss';
import { useTranslation } from 'react-i18next';
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import { Modal, Col, Form } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import { Link } from "react-router-dom";

//COMPONENTS
import UsersFilter from './components/UsersFilter/UsersFilter';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';

import DesktopDefault from '../../templates/DesktopDefault';
import ImageDefault from "../../../src/assets/img/image-default.png";

function ListUsers() {
    const { t } = useTranslation();
    const [users, setUsers] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);
    const [modalConfirmStatus, setModalConfirmStatus] = useState<any>(false);
    const [modalConfirmStatusLog, setModalConfirmStatusLog] = useState<any>(0);
    const [modalConfirmStatusMessage, setModalConfirmStatusMessage] = useState<any>('');
    const [userId, setUserId] = useState<any>(null);
    const [supplierId, setSupplierId] = useState<any>(null);
    const [modalUpdateStatus, setModalUpdateStatus] = useState<any>(false);

    const [actualFirstname, setActualFirstname] = useState<any>('');
    const [actualLastname, setActualLastname] = useState<any>('');
    const [actualEmail, setActualEmail] = useState<any>('');
    const [actualStatus, setActualStatus] = useState<any>(0);

    const [errorMessage, setErrorMessage] = useState<any>('');

    useEffect(() => {
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listUsers() {
            try {
                const { data } = await api.post('/UserPermission/GetListUserPermission',
                    {
                        "email": "",
                        "firstName": "",
                        "lastName": "",
                        "userStatus": 0
                    }, config
                );
                if (data.status !== 400) {
                    setLoading(false);
                    setUsers(data.data.data);

                    if (data.data.log === 1) {
                        setErrorMessage(data.data.texto);
                    }
                }
            } catch (error: any) {
                setLoading(false);
                console.log(error?.response)
                if (error.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listUsers();
    }, []);

    async function buscar(name: string, lastName: string, email: string, status: any) {
        setActualFirstname(name);
        setActualLastname(lastName);
        setActualEmail(email);
        setActualStatus(status);

        setUsers(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const res = await api.post('/UserPermission/GetListUserPermission',
                {
                    "email": email,
                    "firstName": name,
                    "lastName": lastName,
                    "userStatus": Number(status) //0 - não selecionado / 1 - ativo / 2 - inativo
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);
                setUsers(res.data.data.data);

                if (res.data.log === 1) {
                    setErrorMessage(res.data.texto);
                }
            }
        } catch (error: any) {
            setLoading(false);
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {

        async function updateStatus() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post('/UserPermission/UpdateUserStatusById',
                    {
                        "statusUser": parseInt(data.updateStatus),
                        "idUser": userId,
                        "supplierId": supplierId
                    }, config);

                if (res.status !== 400) {
                    if (res.data.data.log === 0) {
                        setModalConfirmStatusLog(0);
                        setModalUpdateStatus(false);
                        setModalConfirmStatus(true);
                        setModalConfirmStatusMessage(res.data.data.texto)
                        buscar(actualFirstname, actualLastname, actualEmail, actualStatus);
                    } else {
                        setModalConfirmStatusLog(1);
                        setModalConfirmStatus(true);
                        setModalConfirmStatusMessage(`${t('editUser.user.processError')}`);
                    }
                }
            } catch (error: any) {

            }
        }
        updateStatus();
    }

    const handleClickStatus = (row: any) => {
        setUserId(row.id);
        setSupplierId(row.supplierId);
        setModalUpdateStatus(true)
    }

    /* USER TABLE */
    function addStatus(cell: any, row: any) {
        return (
            <div
                className={
                    cell === "Conciliado"
                        ? "bg-analise w-100"
                        : cell === "Ativo"
                            ? "bg-ativo w-100"
                            : cell === "Inativo"
                                ? "bg-inativo w-100"
                                : ""
                }
            >
                {cell}
            </div>
        );
    }

    function addImage(cell: any, row: any) {
        if (cell === null) {
            return (
                <>
                    <div className="logo-user user-list"
                        style={{ backgroundImage: `url(${ImageDefault})`, backgroundSize: 'cover' }}>
                    </div>
                </>
            );
        } else {
            return (
                <>
                    <div className="logo-user user-list"
                        style={{ backgroundImage: `url(${cell})`, backgroundSize: 'cover' }}>
                    </div>
                </>
            );
        }
    }

    function addActionButton(cell: any, row: any) {

        return (
            <>
                <div className="center-table options-table">
                    <Dropdown drop="down" >
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={['fal', 'ellipsis-h']} />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" onClick={() => { window.location.href = `/users/edit-users?id=${cell}` }} ><FontAwesomeIcon icon={['fal', 'edit']} />{t('suppliersTable.edit')}</Dropdown.Item>
                            <Dropdown.Item eventKey="2" onClick={() => { handleClickStatus(row); }}><FontAwesomeIcon icon={['fal', 'circle']} />{t('suppliersTable.status')}</Dropdown.Item>
                            {/* <Dropdown.Item eventKey="3" disabled={true}><FontAwesomeIcon icon={['fal', 'file-contract']} className="mr-2" />  {t('suppliersTable.contract')}</Dropdown.Item>
                            <Dropdown.Item eventKey="4" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />  {t('suppliersTable.remove')}</Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>)
    }

    const columns = [
        { dataField: 'photoUrl', text: `${t('editUser.user.picture')}`, formatter: addImage },
        { dataField: 'firstName', text: `${t('editUser.user.name')}` },
        { dataField: 'lastName', text: `${t('editUser.user.lastname')}` },
        { dataField: 'email', text: `${t('editUser.user.email')}`, sort: true },
        { dataField: 'cpf', text: `${t('editUser.user.cpf')}`, sort: true },
        { dataField: 'phone', text: `${t('editUser.user.telephone')}` },
        { dataField: 'level', text: `${t('editUser.user.level')}` },
        { dataField: 'status', text: `${t('editUser.user.status')}`, formatter: addStatus },
        { dataField: 'id', text: `${t('editUser.user.actions')}`, formatter: addActionButton }
    ];


    const rowClasses = (row: any, rowIndex: any) => {
        /* console.log(row, rowIndex) */
        if (row.status === "Em Análise") {
            return 'border-analise';
        } else if (row.status === "Ativo") {
            return 'border-ativo';
        } else {
            return 'border-inativo';
        }
    };

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background" style={{ height: "58px" }}></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: `${t('editUser.user.loadingUsers')}`, formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */

    /* In case of error*/

    function errorFunc() {
        return (
            <>
                <div className="d-flex align-items-center justify-content-center" style={{ height: "58px" }}>{errorMessage}</div>
            </>
        )
    }
    const errorColumns = [{ dataField: "", text: `${t('editUser.user.users')}`, formatter: errorFunc }];

    const errorProducts = [{ contas: { errorMessage } }];
    /* [END] In case of error*/

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: false,
        alwaysShowAllBtns: true
    });

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">

                    <Breadcrumb title={`${t('editUser.user.users')}`} />

                    <div className="users mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "rgb(112, 112, 112)" }}>
                        {t('editUser.user.usersList')}
                        </h2>
                        <Link to={'/users/new-users'} className='btn btn-primary'>
                        {t('editUser.user.newUser')}
                        </Link>
                    </div>

                    <UsersFilter buscar={buscar} />

                    {
                        (users !== null)
                            ?
                            <>
                                <div className="table-default users-table">
                                    <div className="table-container">
                                        <div className="table-title">
                                            <h3 className="">{t('editUser.user.users')}</h3>
                                        </div>
                                        <BootstrapTable
                                            bootstrap4
                                            keyField='id'
                                            data={users}
                                            hover={true}
                                            columns={columns}
                                            pagination={pagination}
                                            rowClasses={rowClasses}
                                        />

                                    </div>
                                </div>
                            </>
                            : (users === null && loading === true)
                                ?
                                <>
                                    <div className="table-default users-table loading not-found">
                                        <div className="table-container">
                                            <BootstrapTable
                                                bootstrap4
                                                keyField="id"
                                                data={loadingProducts}
                                                hover={true}
                                                columns={loadingColumns}
                                                rowClasses={rowClasses}
                                            />
                                        </div>
                                    </div>

                                </>
                                :
                                <>
                                    <div className="table-default users-table not-found">
                                        <div className="table-container">
                                            <BootstrapTable
                                                bootstrap4
                                                keyField="id"
                                                data={errorProducts}
                                                hover={true}
                                                columns={errorColumns}
                                                rowClasses={rowClasses}
                                            />
                                        </div>
                                    </div>
                                </>

                    }
                </div>
                <Modal
                    className="modalAuth"
                    show={modalUpdateStatus}
                    onHide={() => { setUserId(null); setSupplierId(null); setModalUpdateStatus(false); }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <div className='status-modal'>
                            <Form className="modal-content" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                <Modal.Body>

                                    <div className="modal-header rounded-0">
                                        <div>
                                            <h4 className="pt-1 text-primary title-reservation">{t('editUser.user.editStatus')}</h4>
                                            <small>

                                            </small>
                                        </div>

                                        <div className='buttons-options'>
                                            <div>

                                            </div>
                                            <div>
                                                <FontAwesomeIcon
                                                    icon={['fal', 'times']}
                                                    className="mx-3 text-info"
                                                    size="lg"
                                                    data-dismiss="modal"
                                                    aria-label="Close"
                                                    onClick={() => { setUserId(null); setSupplierId(null); setModalUpdateStatus(false) }}
                                                />
                                            </div>
                                        </div>
                                    </div>

                                    <hr className='w-100 my-1' style={{ borderBottom: '1px solid' }} />

                                    <div>
                                        <Form.Group as={Col} md="5" controlId="formInfoCompanySubscription">
                                            <Form.Label>{t('editUser.user.sureEditStatus')}</Form.Label>
                                            <Controller
                                                control={control}
                                                name="updateStatus"
                                                rules={{ required: { value: true, message: `${t('editUser.user.sureEditStatusError')}` } }}
                                                defaultValue={'2'}
                                                render={({ field }: any) => (
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                        style={{ border: "1px solid #ced4da" }}
                                                        required
                                                    >
                                                        <option value=''  >{t('editUser.user.sureEditStatusSelect.select')}</option>
                                                        <option value='1'>{t('editUser.user.sureEditStatusSelect.active')}</option>
                                                        <option value='2'>{t('editUser.user.sureEditStatusSelect.deactive')}</option>
                                                    </Form.Select>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="updateStatus"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </div>
                                </Modal.Body>
                                <Modal.Footer>
                                    <div className="modal-footer rounded-0 border-0">
                                        <button type="button" className="btn-default mr-2 text-uppercase btn btn-outline-primary" data-dismiss="modal" onClick={() => { setUserId(null); setSupplierId(null); setModalUpdateStatus(false) }}><i className="fas fa-times fa-fw"></i> <span data-vouchercampo="close">{t('editUser.user.cancel')}</span></button>
                                        <button type="submit" className="btn-default text-uppercase btn btn-primary">
                                            {loading === true ? <div className="load"></div> : `${t('editUser.user.confirm')}`}
                                        </button>
                                    </div>
                                </Modal.Footer>
                            </Form>
                        </div>
                    </>
                </Modal>

                <Modal
                    className="modalAuth"
                    show={modalConfirmStatus}
                    onHide={() => { setModalConfirmStatus(false); }}
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos d-flex justify-content-evenly' style={{ height: "600px" }}>
                            <div>
                                {modalConfirmStatusLog === 0
                                    ?
                                    <FontAwesomeIcon
                                        icon={["fal", "check-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                    :
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                }
                            </div>
                            <div>
                                {modalConfirmStatusMessage}
                            </div>
                            <div className="d-flex justify-content-center pt-3">
                                <button
                                    onClick={() => { setModalConfirmStatus(false); }}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    {t('editUser.user.confirm')}
                                </button>
                            </div>

                        </Modal.Body>
                    </>
                </Modal>
            </DesktopDefault>

        </>
    );
}

export default ListUsers;