import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";

import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addToComboObject } from "../../../../store/combo";

import DraggableProductImputs from "../../../../components/DraggableProductImputs/DraggableProductImputs";
import ReactQuillComponent from "../../../../components/ReactQuillComponent/ReactQuillComponent";

export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
}

const DescriptionAndHigh: React.FC<operation> = React.memo(({
    action, isEdit, editFunction = () => { }
}: operation) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */

    const [descriptionPT, setDescriptionPT] = useState<string>("");
    const [descriptionEN, setDescriptionEN] = useState<string>("");
    const [descriptionES, setDescriptionES] = useState<string>("");

    // const [valNamePt, setValNamePt] = useState<boolean>(false);
    // const [valNameEs, setValNameEs] = useState<boolean>(false);
    // const [valNameEn, setValNameEn] = useState<boolean>(false);

    const [allowEditDescription, setAllowEditDescription] = useState<boolean>(false);
    const [allowEditHighlights, setAllowEditHighlights] = useState<boolean>(false);

    const [highlightsArray, setHighlightsArray] = useState<any>([
        {
            PT: "",
            EN: "",
            ES: "",
        },
    ]);

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        setError,
        clearErrors,
        formState: { errors },
    } = useForm({});

    useEffect(() => {
        window.scrollTo(0, 0);
        if (reduxComboObject.description !== undefined) {
            setDescriptionPT(reduxComboObject.description[0].description[0].text);
            setValue("descriptionPT", reduxComboObject.description[0].description[0].text);
            setDescriptionEN(reduxComboObject.description[0].description[1].text);
            setValue("descriptionEN", reduxComboObject.description[0].description[1].text);
            setDescriptionES(reduxComboObject.description[0].description[2].text);
            setValue("descriptionES", reduxComboObject.description[0].description[2].text);
        }

        if (reduxComboObject?.highLight !== undefined) {
            let aux: any = [];

            for (let i: number = 0; i < reduxComboObject[`highLight`]?.length; i++) {
                console.log()
                aux.push({
                    PT: reduxComboObject[`highLight`][i][0].highlight.text,
                    EN: reduxComboObject[`highLight`][i][1].highlight.text,
                    ES: reduxComboObject[`highLight`][i][2].highlight.text,
                });
            }

            setHighlightsArray(() => aux);

            for (let i: number = 0; i < reduxComboObject[`highLight`].length; i++) {
                setValue(`highlightsPT-${i}`, reduxComboObject[`highLight`][i][0].highlight.text);
                setValue(`highlightsEN-${i}`, reduxComboObject[`highLight`][i][1].highlight.text);
                setValue(`highlightsES-${i}`, reduxComboObject[`highLight`][i][2].highlight.text);
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);



    const handleBlurDescription = (e: any) => {
        if (allowEditDescription === false) {
            let ptText: any = getValues().descriptionPT;
            TranslateTextEN(ptText);
            TranslateTextES(ptText);
        }
    };

    const TranslateTextEN = async (name: string) => {
        if (name !== undefined) {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "en",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setDescriptionEN(res?.data?.data?.translations[0]?.translatedText);
                    setValue("descriptionEN", res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        }
    };

    const TranslateTextES = async (name: string) => {
        if (name !== undefined) {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": "es",
                    "format": "text"
                });
                if (res.status !== 400) {
                    setDescriptionES(res?.data?.data?.translations[0]?.translatedText);
                    setValue("descriptionES", res?.data?.data?.translations[0]?.translatedText);
                }
            } catch (error: any) { }
        }
    };

    let numberPT: any = descriptionPT.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
    let numberES: any = descriptionES.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;
    let numberEN: any = descriptionEN.replaceAll("<p>", "").replaceAll("</p>", "").replaceAll("<br>", "").replaceAll("<strong>", "").replaceAll("</strong>", "").length;

    // useEffect(() => {
    //     if (descriptionPT !== undefined) {
    //         if (numberPT < 100) {
    //             setValNamePt(false);
    //         } else {
    //             setValNamePt(true);
    //         }
    //     }

    //     if (descriptionES !== undefined) {
    //         if (numberES < 100) {
    //             setValNameEs(false);
    //         } else {
    //             setValNameEs(true);
    //         }
    //     }

    //     if (descriptionEN !== undefined) {
    //         if (numberEN < 100) {
    //             setValNameEn(false);
    //         } else {
    //             setValNameEn(true);
    //         }
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [descriptionPT, descriptionES, descriptionEN]);

    const onSubmit = (data: any) => {
        const comboCode = isEdit ? window.location.href.split('comboCode=')[1] : undefined;

        let dataToInsert: any = {};

        dataToInsert.description = [
            {
                comboCode: comboCode,
                description: [
                    {
                        text: descriptionPT,
                        lang: 1
                    },
                    {
                        text: descriptionEN,
                        lang: 2
                    },
                    {
                        text: descriptionES,
                        lang: 3
                    }
                ]
            }
        ]

        dataToInsert.highLight = highlightsArray.filter((e: any) => e !== null).map((e: any) => [
            { comboCode: comboCode, highlight: { text: e.PT, lang: 1 } },
            { comboCode: comboCode, highlight: { text: e.EN, lang: 2 } },
            { comboCode: comboCode, highlight: { text: e.ES, lang: 3 } },
        ])

        if (isEdit) {
            editFunction(dataToInsert);
        } else {
            dispatch(addToComboObject(JSON.stringify(dataToInsert)));
            action("product");
        }
    };

    return (
        <>
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content summary">
                            <Form
                                className="h-100"
                                noValidate
                                validated={false}
                                onSubmit={handleSubmit(onSubmit)}
                            >
                                <h3 className="text-primary pt-title mb-0">
                                    Descrição e Destaques
                                </h3>

                                <div className="pt-content-info">
                                    <Row>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="mb-2 pt-subtitle">Descrição</h5>
                                                <div
                                                    className="btn-edit"
                                                    onClick={() =>
                                                        setAllowEditDescription(!allowEditDescription)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={["fal", "edit"]}
                                                        size="1x"
                                                        className="mr-2"
                                                    />
                                                    Editar
                                                </div>
                                            </div>

                                            <p className="small mb-0">
                                                Descreva o produto de maneira clara e atraente.
                                            </p>
                                            <p className="small mb-0">
                                                Acrescentar detalhes específicos, ajudando o viajante a
                                                entender o itinerário e ordem de atrações. Mais
                                                importante: faça-o divertido!
                                            </p>
                                        </div>

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            handleBlur={handleBlurDescription}
                                            name="descriptionPT"
                                            title="Descrição do Combo (Português)"
                                            errors={errors}
                                            setState={setDescriptionPT}
                                            state={descriptionPT}
                                            isEdit={isEdit}
                                            size={12}
                                            numberOfCaracters={numberPT}
                                            maxLength={2100}
                                            minLength={300}
                                            id="portugueseText"
                                        />

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            name="descriptionEN"
                                            title="Descrição do Combo (Inglês)"
                                            errors={errors}
                                            setState={setDescriptionEN}
                                            state={descriptionEN}
                                            isEdit={isEdit}
                                            size={6}
                                            numberOfCaracters={numberEN}
                                            maxLength={2100}
                                            minLength={300}
                                            id="englishText"
                                            style={allowEditDescription === false ? { backgroundColor: "#e9ecef", opacity: "1" } : {}}
                                        />

                                        <ReactQuillComponent
                                            setValue={setValue}
                                            control={control}
                                            name="descriptionES"
                                            title="Descrição do Combo (Espanhol)"
                                            errors={errors}
                                            setState={setDescriptionES}
                                            state={descriptionES}
                                            isEdit={isEdit}
                                            size={6}
                                            numberOfCaracters={numberES}
                                            maxLength={2100}
                                            minLength={300}
                                            id="spanishText"
                                            style={allowEditDescription === false ? { backgroundColor: "#e9ecef", opacity: "1" } : {}}
                                        />
                                    </Row>
                                </div>

                                <div className="pt-content-info" style={{ border: "none" }}>
                                    <Row>
                                        <div>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="mb-2 pt-subtitle">Destaques</h5>
                                                <div
                                                    className="btn-edit ml-2"
                                                    style={{ position: "unset" }}
                                                    onClick={() =>
                                                        setAllowEditHighlights(!allowEditHighlights)
                                                    }
                                                >
                                                    <FontAwesomeIcon
                                                        icon={["fal", "edit"]}
                                                        size="1x"
                                                        className="mr-2"
                                                    />
                                                    Editar
                                                </div>
                                            </div>
                                            <p className="small mb-0">
                                                Insira tudo que um viajante precisa saber antes de comprar
                                                seu produto.
                                            </p>
                                            <p className="small mb-0">
                                                Pense em logística, restições e requisitos.
                                            </p>
                                        </div>

                                        <DraggableProductImputs
                                            items={highlightsArray}
                                            setItems={setHighlightsArray}
                                            tourObject={{ isTranslate: 1 }}
                                            allowEditItems={allowEditHighlights}
                                            portuguese={`highlightsPT`}
                                            english={"highlightsEN"}
                                            spanish={"highlightsES"}
                                            address={"highLight"}
                                            control={control}
                                            setValue={setValue}
                                            errors={errors}
                                            minLength={10}
                                            maxLength={100}
                                            setError={setError}
                                            clearErrors={clearErrors}
                                        />
                                    </Row>
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {isEdit !== true ? (
                                        <Row className="mx-0 mt-5">
                                            <Button
                                                variant="outline-primary"
                                                className="form-button mr-3"
                                                onClick={() => action("summaryAndDetails")}
                                            >
                                                Voltar
                                            </Button>
                                            <Button type="submit" className="form-button">
                                                Continuar
                                            </Button>
                                        </Row>
                                    ) : (
                                        <Row className=" mx-0 mt-5">
                                            <Button
                                                type="submit"
                                                className="form-button"
                                            >
                                                Salvar
                                            </Button>
                                        </Row>
                                    )}
                                </div>
                            </Form>
                        </Card>
                    </Col>
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    );
}
);

export default DescriptionAndHigh;