/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import api from '../../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Modal, Dropdown } from "react-bootstrap";

import { useTranslation } from 'react-i18next';
import { useForm } from "react-hook-form";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableBillsPay.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
import DebitNotes from "../../../../components/Modal/ModalDefault/DebitNotes/DebitNotes";
import Payment from "../../../../components/Modal/ModalDefault/Payment/Payment";
import FormBillsToPay from "../../../../components/Forms/FormBillsToPay/FormBillsToPay";
import Expiration from "../../../../components/Modal/ModalDefault/Expiration/Expiration";
import EditPayment from "../../../../components/Modal/ModalDefault/EditPayment/EditPayment";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import SetDueInBatch from "../../../../components/Modal/ModalDefault/SetDueInBatch/SetDueInBatch";
import FormBillPayableLog from "../../../../components/Forms/FormBillPayableLog/FormBillPayableLog";

export interface propTable {
  billsPay: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportBills: any;
  printBills: any;
  parameters: any;
  totalPay: any;
  actualDate: any;
  loading: any;
  goToParcial: any;
  refreshData?: any
}

const TableBillsPay: React.FC<propTable> = ({
  billsPay, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportBills, printBills, parameters, totalPay, actualDate, loading, goToParcial, refreshData
}: propTable) => {
  /* VARIÁVEIS */
  const { t } = useTranslation();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);
  const [allowReload, setAllowReload] = useState<any>(false);
  const [editModalShow, setEditModalShow] = useState<any>(false);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [expiration, setExpiration] = useState<any>();

  const [undoSettlementModalShow, setUndoSettlementModalShow] = useState<any>(false);
  const [undoSettlementText, setUndoSettlementText] = useState<any>("");

  const [billDeleteModalShow, setBillDeleteModalShow] = useState<any>(false);

  const [settleViaErpShow, setSettleViaErpShow] = useState<any>(false);

  const [setBillsDueModalShow, setSetBillsDueModalShow] = useState<any>(false);

  const [logModalShow, setLogModalShow] = useState<any>(false);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)

  const validated = false;


  /* FUNÇÕES */

  async function settleBills(rows: any) {
    setSelectedSingleRow(rows);  //recebe array vazio ([]) para liquidar consulta
    setModalShow(true);
  }

  function unsettleBill(row: any) {
    setSelectedSingleRow(row);

    if (row.idLote > 0) {
      setUndoSettlementText(t('billsToPay.table.batchSettlement'));
    } else {
      setUndoSettlementText(t('billsToPay.table.sureSettlement'));
    }

    setUndoSettlementModalShow(true);
  }

  async function editBill(row: any) {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };
    try {
      const res = await api.post(`/BillsPayable/GetBill`,
        {
          "id": row.manifestId,
          "tipo": row.tipo,

        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setSelectedSingleRow(dados);
        setEditModalShow(true);
      }
    } catch (error: any) {

    }

  }

  function handleBillDeleteClick(row: any) {
    setSelectedSingleRow(row);
    setBillDeleteModalShow(true);
  }

  async function handleActionBillDeleteModal(e: any) {
    setBillDeleteModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.manifestId;
    var tipo = selectedSingleRow.tipo;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsPayable/DeleteBill`,
        {
          "id": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  function handleSetBillsDueClick(row: any) {
    //setSelectedSingleRow(row);
    setSetBillsDueModalShow(true);
  }

  async function handleActionSetBillsDueModal(e: any) {
    setSetBillsDueModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.manifestId;
    var tipo = selectedSingleRow.tipo;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsPayable/DeleteBill`,
        {
          "id": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function handleActionUndoSettlementModal() {
    setUndoSettlementModalShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var manifestId = selectedSingleRow.manifestId;
    var tipo = selectedSingleRow.tipo;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsPayable/UndoBillSettlement`,
        {
          "manifestId": manifestId,
          "tipo": tipo,
          "uid": uId,
          "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  function settleViaErp() {
    // only accept supplier != all
    if(parameters.fornecedor) {
      setSettleViaErpShow(true);
    } else {
      setResponseText(t('billsToPay.table.onlyOneSupplierError'));
      setLog(2); // warning
      setModalContent("warning");
      setModalSuccess(true);
    }
  }

  async function handleActionSettleViaErpModal() {
    setSettleViaErpShow(false);

    // settle the search result
    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/BillsPayable/SettlePaymentsViaRmAsync`,
        {
          "supplierIdentity": parameters.fornecedor,
          "dataInicial": parameters.dataInicial,
          "dataFinal": parameters.dataFinal
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  function handleShowPaymentLog(row: any) {
    setSelectedSingleRow(row);
    setLogModalShow(true);
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    if (cell !== null) {
      //var date = convertUTCDateToLocalDate(new Date(cell));
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return (
        <>
          {date}
        </>
      );
    } else {
      return (
        <>
        </>
      );
    }
  }

  /* SOBRE A TABELA */

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              {/* <Dropdown.Item
                eventKey="1"
                onClick={() => { settleBills([row]) }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'dollar-sign']}
                //className="mr-2"
                />
                Liquidar
              </Dropdown.Item> */}
              {row.statusPagamento === 'Pago' ?
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => { unsettleBill(row) }}>
                  <FontAwesomeIcon
                    icon={['fal', 'strikethrough']}
                  //className="mr-2"
                  />
                  {t('billsToPay.table.dropdownAction.undoSettlement')}
                </Dropdown.Item>
                :
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => { settleBills([row]) }}>
                  <FontAwesomeIcon
                    icon={['fal', 'dollar-sign']}
                  //className="mr-2"
                  />
                  {t('billsToPay.table.dropdownAction.settle')}
                </Dropdown.Item>
              }
              <Dropdown.Item
                eventKey="3"
                disabled={row.statusPagamento === 'Pago'}
                onClick={() => editBill(row)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                //className="mr-2"
                />
                {t('billsToPay.table.dropdownAction.edit')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                onClick={() => handleShowPaymentLog(row)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'history']}
                //className="mr-2"
                />
                {t('billsToPay.table.dropdownAction.seeLog')}
              </Dropdown.Item>
              {level === '19' ?
                <>
                  <Dropdown.Divider />
                  <Dropdown.Item
                    eventKey="5"
                    disabled={row.statusPagamento === 'Pago'}
                    onClick={() => handleBillDeleteClick(row)}
                    className="text-danger removeRow"
                  >
                    <FontAwesomeIcon
                      icon={['fal', 'trash-alt']}
                    //className="mr-2" 
                    />
                    {t('billsToPay.table.dropdownAction.delete')}
                  </Dropdown.Item>
                </>
                : <></>}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Conciliado"
            ? "bg-analise"
            : cell === "Pago"
              ? "bg-ativo"
              : cell === "Pendente"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  const columns = [
    {
      dataField: "vencimento",
      text: t('billsToPay.table.columns.expiration'),
      formatter: convertDate,
    },
    { dataField: "empresa", text: "Empresa" },
    { dataField: "idMov", text: "Id Mov." },
    { dataField: "fornecedor", text: t('billsToPay.table.columns.supplier') },
    {
      dataField: "dataOperacao",
      text: t('billsToPay.table.columns.operationDate'),
      formatter: convertDate,
    },
    { dataField: "descricao", text: t('billsToPay.table.columns.description') },
    { dataField: "contaContabil", text: t('billsToPay.table.columns.category') },
    {
      dataField: "valor",
      text: t('billsToPay.table.columns.value'),
      formatter: convertValue,
    },
    {
      dataField: "statusPagamento",
      text: t('billsToPay.table.columns.paymentStatus'),
      formatter: addStatus,
    },
    {
      dataField: "dataPagamento",
      text: t('billsToPay.table.columns.paymentData'),
      formatter: convertDate
    },
    { dataField: "contasaPagarId", text: t('billsToPay.table.columns.action'), formatter: addActionButton },
  ];

  const columnsAgent = [
    {
      dataField: "vencimento",
      text: t('billsToPay.table.columns.expiration'),
      formatter: convertDate,
    },
    { dataField: "fornecedor", text: t('billsToPay.table.columns.supplier') },
    {
      dataField: "dataOperacao",
      text: t('billsToPay.table.columns.operationDate'),
      formatter: convertDate,
    },
    { dataField: "descricao", text: t('billsToPay.table.columns.description') },
    { dataField: "contaContabil", text: t('billsToPay.table.columns.category') },
    {
      dataField: "valor",
      text: t('billsToPay.table.columns.value'),
      formatter: convertValue,
    },
    {
      dataField: "statusPagamento",
      text: t('billsToPay.table.columns.paymentStatus'),
      formatter: addStatus,
    },
    {
      dataField: "dataPagamento",
      text: t('billsToPay.table.columns.paymentData'),
      formatter: convertDate
    }
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: t('billsToPay.table.columns.loadingBills'), formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "contas", text: t('billsToPay.table.columns.bills') }];

  const notFoundProducts = [{ contas: t('billsToPay.table.columns.billsNotFound') }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === "Conciliado") {
      return 'border-analise';
    } else if (row.statusPagamento === "Pago") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  /* FORMULÁRIO */

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  useEffect(() => {
    if (modalShow === false) {
      reset({
        data: undefined
      })
    }
  }, [reset, /*  */modalShow])

  const onSubmit = (data: any) => {
    setModalResponse('loading');

    var fixDate = datapgto.split('/');
    data.dataPgto = fixDate.length === 3 ? `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}` : undefined;
    data.fornLiquidacao = data.fornLiquidacao ? data.fornLiquidacao.supplierId : 0;
    data.contaLiquidacao = data.contaLiquidacao ? data.contaLiquidacao.id : 0;

    //console.log(selectedSingleRow.length > 0);
    //return;

    async function settle() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {

        const res = await api.post('/BillsPayable/SettleBills',
          {
            "parametros": selectedSingleRow.length > 0 ? null : parameters, // objeto com os parametros da consulta
            "items": selectedSingleRow.length > 0 ?
              [{
                "contasaPagarId": selectedSingleRow[0].contasaPagarId,
                "id": selectedSingleRow[0].manifestId,
                "tipo": selectedSingleRow[0].tipo
              }]
              : [], // array com os itens a serem liquidados
            "fornLiquidacao": data.fornLiquidacao ? parseInt(data.fornLiquidacao) : 0, //
            "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
            "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
            "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
            "dataPgto": data.dataPgto, // data do pagamento
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );
        if (res.status !== 400) {
          if (res.data.data.log === 0) {
            setModalResponse('success');
            setAllowReload(true);
          }
          else if (res.data.data.log === 1) {
            setModalResponse('error');
          }
        }
      } catch (error: any) {

        if (error?.response?.status === 401) {
        }
      }
    }
    settle();
  }

  useEffect(() => {
    if (modalResponse === null && allowReload === true) {
      window.location.reload();
    }
  }, [allowReload, /*  */modalResponse])

  /* RENDER */
  if (billsPay !== null) {
    /* console.log(props.suppliers) */
    return (
      <>
        <div className="table-default bills-pay-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                {
                  level !== '29'
                    ?
                    <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                      <div>
                        <ModalDefault
                          title={t('billsToPay.table.insertPayment')}
                          name={t('billsToPay.table.insert')}
                          icon="yes"
                          typeIcon="plus-circle"
                          classBtn="btn btn-bills"
                          classModal="modal-large"
                        >
                          <Payment />
                        </ModalDefault>
                      </div>
                      <div>
                        <ModalDefault
                          title={t('billsToPay.table.insertDebitNote')}
                          name={t('billsToPay.table.debitNote')}
                          icon="yes"
                          typeIcon="plus-circle"
                          classBtn="btn btn-bills"
                          classModal="modal-large"
                        >
                          <DebitNotes />
                        </ModalDefault>
                      </div>
                      <div>
                        <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                          <FontAwesomeIcon
                            icon={["fal", "share"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          {t('billsToPay.table.export')}
                        </div>
                      </div>
                      <div>
                        <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                          <FontAwesomeIcon
                            file-invoice
                            icon={["fal", "print"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          {t('billsToPay.table.print')}
                        </div>
                      </div>
                      <div>
                        <Dropdown drop="down" >
                          <Dropdown.Toggle className="menu-profile p-0">
                            <button className="btn btn-bills">
                              <FontAwesomeIcon
                                icon={["fal", "dollar-sign"]}
                                size="1x"
                                style={{ marginRight: "5px" }}
                              />
                              {t('billsToPay.table.batchSettle')}
                            </button>
                          </Dropdown.Toggle>

                          <Dropdown.Menu id="dropdown-menu-liquidar">
                            <Dropdown.Item eventKey="1" disabled={false} onClick={() => { goToParcial() }}> {t('billsToPay.table.partialSettle')}</Dropdown.Item>
                            <Dropdown.Item eventKey="2" disabled={false} onClick={() => { settleBills([]) }}> {t('billsToPay.table.querySettle')}</Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div>
                        <Dropdown drop="down" >
                          <Dropdown.Toggle className="menu-profile p-0">
                            <button className="btn btn-bills">
                              <FontAwesomeIcon
                                icon={["fal", "calendar"]}
                                size="1x"
                                style={{ marginRight: "5px" }}
                              />
                              {t('billsToPay.table.changeExpiration')}
                            </button>
                          </Dropdown.Toggle>
                          <Dropdown.Menu id="dropdown-menu-liquidar">
                            <Dropdown.Item eventKey="1" disabled={false}>
                              <ModalDefault
                                title={t('billsToPay.table.expiration')}
                                name={t('billsToPay.table.partialChange')}
                                icon="no"
                                classBtn="btn btn-bills"
                                classModal="modal-large modal-large-custom"
                                vencimento="yes"
                                menuItem="yes"
                                setValue={setExpiration}
                              >
                                <Expiration
                                  billsPay={billsPay}
                                  totalRows={totalRows}
                                  pageCount={pageCount}
                                  setPageCount={setPageCount}
                                  rowsPerPage={rowsPerPage}
                                  setRowsPerPage={setRowsPerPage}
                                  setExpiration={setExpiration}
                                  expiration={expiration}
                                  actualDate={actualDate}
                                  parameters={parameters}
                                  t={t}
                                />
                              </ModalDefault>
                            </Dropdown.Item>
                            <Dropdown.Item eventKey="2" disabled={false}>
                              <ModalDefault
                                title=""
                                name={t('billsToPay.table.changeQuery')}
                                icon="no"
                                classBtn="btn btn-bills"
                                classModal="modal-confirm loading-modal"
                                //vencimento="yes"
                                menuItem="yes"
                                //setValue={setExpiration}
                                id="modal-set-due-in-batch"
                              >
                                <SetDueInBatch
                                  parameters={parameters}
                                  setModalSuccess={setModalSuccess}
                                  setModalResponse={setModalContent}
                                  setLog={setLog}
                                  setResponseText={setResponseText}
                                  t={t}
                                />
                              </ModalDefault>
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                      <div>
                        <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={settleViaErp}>
                          <FontAwesomeIcon
                            icon={["fal", "dollar-sign"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          {t('billsToPay.table.settleViaErp')}
                        </div>
                      </div>
                    </div>
                    :
                    <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                      <div>
                        <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                          <FontAwesomeIcon
                            icon={["fal", "share"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          {t('billsToPay.table.export')}
                        </div>
                      </div>
                    </div>
                }
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">{t('billsToPay.table.total')} <span className={Math.sign(totalPay) === -1 ? "negative" : ""}>{totalPay.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h4>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsPay}
              columns={level !== '29' ? columns : columnsAgent}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={modalShow}
          onHide={() => setModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <h3>
                {t('billsToPay.table.settle')}
              </h3>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
              <FormBillsToPay control={control} errors={errors} datapgto={datapgto} setDatapgto={setDatapgto} modalResponse={modalResponse} setModalResponse={setModalResponse} />
            </Form>
          </>
        </Modal>
        <Modal
          className={"modal-custom modalAuth modal-large"}
          show={editModalShow}
          onHide={() => setEditModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <h3>
                {t('billsToPay.table.editPayment')}
              </h3>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <EditPayment info={selectedSingleRow} onSuccess={() => { refreshData(); setEditModalShow(false); }} t={t} />
          </>
        </Modal>

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={() => { refreshData(); setModalSuccess(false); }}
                            className="btn btn-primary mx-2 w-25"
                          >
                            {t('billsToPay.table.confirm')}
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              {t('billsToPay.table.confirm')}
                            </button>
                            :
                            <button
                              onClick={() => setModalShow(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              {t('billsToPay.table.confirm')}
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>{t('billsToPay.table.loading')}</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}

        {/* modal undo settlement */}
        <ModalQuestion
          modalShow={undoSettlementModalShow}
          setModalShow={setUndoSettlementModalShow}
          titulo={t('billsToPay.table.dropdownAction.undoSettlement')}
          mensagem={undoSettlementText}
          handleActionConfirmModal={handleActionUndoSettlementModal}
        />
        {/* END - modal undo settlement */}

        {/* modal excluir conta */}
        <ModalQuestion
          modalShow={billDeleteModalShow}
          setModalShow={setBillDeleteModalShow}
          titulo={t('billsToPay.table.deletePayment')}
          mensagem={t('billsToPay.table.deletePaymentQuestion')}
          handleActionConfirmModal={handleActionBillDeleteModal}
        //handleActionCancelModal={handleActionCancelDeleteClick}
        />
        {/* END - modal excluir conta */}

        {/* modal settle via erp */}
        <ModalQuestion
          modalShow={settleViaErpShow}
          setModalShow={setSettleViaErpShow}
          titulo={t('billsToPay.table.settleViaErp')}
          mensagem={t('billsToPay.table.settleViaErpConfirm')}
          handleActionConfirmModal={handleActionSettleViaErpModal}
        />
        {/* END - modal settle via erp */}

        <Modal
          className="modalReceber"
          show={logModalShow}
          onHide={() => setLogModalShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
          <Modal.Header className="p-3" closeButton></Modal.Header>
          <FormBillPayableLog contasaPagarId={selectedSingleRow?.contasaPagarId ? selectedSingleRow?.contasaPagarId : 0} />
          </>
        </Modal>
      </>
    );
  } else if (billsPay === null && loading === true) {
    return (
      <>
        <div className="table-default bills-pay-table loading not-found">
          <div className="table-container">
            {/* <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <ModalDefault
                      title={t('billsToPay.table.insertPayment')}
                      name={t('billsToPay.table.insert')}
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <Payment />
                    </ModalDefault>
                  </div>
                  <div>
                    <ModalDefault
                      title={t('billsToPay.table.insertDebitNote')}
                      name={t('billsToPay.table.debitNote')}
                      icon="yes"
                      typeIcon="plus-circle"
                      classBtn="btn btn-bills"
                      classModal="modal-large"
                    >
                      <DebitNotes />
                    </ModalDefault>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t('billsToPay.table.export')}
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t('billsToPay.table.print')}
                    </div>
                  </div>
                  <div>
                    <Dropdown drop="down" >
                      <Dropdown.Toggle className="menu-profile p-0">
                        <button className="btn btn-bills">
                          <FontAwesomeIcon
                            icon={["fal", "dollar-sign"]}
                            size="1x"
                            style={{ marginRight: "5px" }}
                          />
                          {t('billsToPay.table.batchSettle')}
                        </button>
                      </Dropdown.Toggle>

                      <Dropdown.Menu id="dropdown-menu-liquidar">
                        <Dropdown.Item eventKey="1" disabled={false} onClick={() => { window.location.href = "/settle-bills-to-pay" }}> {t('billsToPay.table.partialSettle')}</Dropdown.Item>
                        <Dropdown.Item eventKey="2" disabled={false} onClick={() => { settleBills([]) }}> {t('billsToPay.table.querySettle')}</Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                  <div>
                    <ModalDefault
                      title={t('billsToPay.table.expiration')}
                      name={t('billsToPay.table.changeExpiration')}
                      icon="yes"
                      typeIcon="calendar"
                      classBtn="btn btn-bills"
                      classModal="modal-large modal-large-custom"
                      vencimento="yes"
                      setValue={setExpiration}
                    >
                      <Expiration
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        setExpiration={setExpiration}
                        expiration={expiration}
                        actualDate={actualDate}
                        parameters={parameters}
                      />
                    </ModalDefault>
                  </div>
                </div>
                <div className="d-flex align-items-center">
                  <h4 className="mb-0">{t('billsToPay.table.total')} <span className={Math.sign(totalPay) === -1 ? "negative" : ""}>{totalPay.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</span></h4>
                </div>
              </div>
            </div> */}
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-pay-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableBillsPay;
