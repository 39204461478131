/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { Row, Col, Card, Modal } from "react-bootstrap";
import Button from "@restart/ui/esm/Button";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";

import Accordion from "react-bootstrap/Accordion";
import api from '../../../services/api';

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { updateDadosCart, updateCupomCart } from "../../../store/cart";

function CupomCheckout(prop: {t:any}) {
    const dispatch = useAppDispatch();
    const cartSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    const [showModal, setShowModal] = useState<any>(false);
    const [modalMessage, setModalMessage] = useState<any>(prop.t("checkout.coupomCheckout.loading"));
    const [modalState, setModalState] = useState<any>('loading');

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const [resTourCode, setResTourCode] = useState<any>(1);

    const validateTourCode = async (code: any) => {
        setModalState('loading');
        setModalMessage(prop.t("checkout.coupomCheckout.loading"));
        setShowModal(true);
        try {
            const { data } = await api.get(`/TourCode/ValidateCoupon/${code}`);
            if (data.status !== 400) {
                if (data.data === null) {
                    setModalState('error');
                    setModalMessage(prop.t("checkout.coupomCheckout.invalidCoupom"));
                } else {
                    if (data.data.typeDiscount !== 3) {
                        setModalState('success');
                        setModalMessage(prop.t("checkout.coupomCheckout.addedCoupom"));
                    }
                    var cupom: any;
                    // 2 - total no carrinho
                    // 3 - percentual do produto
                    // 4 - percentual do total

                    if (data.data.typeDiscount === 2) {
                        cupom = {
                            type: data.data.typeDiscount,
                            codeName: data.data.codeName,
                            code: data.data.code,
                            value: data.data.valueDiscount,
                        };
                        dispatch(updateCupomCart(cupom));

                    } else if (data.data.typeDiscount === 3) {
                        cupom = {
                            type: data.data.typeDiscount,
                            codeName: data.data.codeName,
                            code: data.data.code,
                            value: data.data.valueDiscount,
                        };
                        dispatch(updateCupomCart(cupom));
                        verifyAllCart(cupom);
                    } else if (data.data.typeDiscount === 4) {
                        cupom = {
                            type: data.data.typeDiscount,
                            codeName: data.data.codeName,
                            code: data.data.code,
                            value: data.data.valueDiscount,
                        };
                        dispatch(updateCupomCart(cupom));
                    }
                    setResTourCode(data.data);

                }

            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
            }
        }
    }

    const verifyAllCart = (cupom: any) => {
        var dados: any = [...cartSIG.dados];
        let atLeastOneValid: boolean = false;

        dados.forEach((cItem: any, index: any) => {
            const validateTourCodeProduct = async () => {
                try {
                    const { data } = await api.get(`/TourCode/ValidateTourCodeProduct/${cupom.codeName}/${cItem.productCode}`);
                    if (data.statusCode === 200) {
                        atLeastOneValid = true;
                        cItem.discountBool = true;
                        cItem.discount = data.data.valueDiscount;
                    } else {
                        cItem.discountBool = false;
                        cItem.discount = 0;
                    }

                    if (index === dados.length - 1) {
                        dispatch(updateDadosCart(dados));

                        if (atLeastOneValid === true) {
                            setModalState('success');
                            setModalMessage(prop.t("checkout.coupomCheckout.addedCoupom"));
                        }else{
                            setModalState('success');
                            setModalMessage(prop.t("checkout.coupomCheckout.noProduct"));
                        }
                    }

                } catch (error: any) {
                }
            }

            validateTourCodeProduct();
        });

    }

    const onSubmit = (data: any) => {
        validateTourCode(data.name);
    }


    return (
        <>
            <Card className="cupom bg-white mt-4" id="editResourcesForms">
                <Accordion >
                    <Accordion.Item eventKey="0">
                        <Accordion.Header>{prop.t("checkout.coupomCheckout.coupom")}</Accordion.Header>
                        <Accordion.Body>
                            <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" controlId="formCheckoutName">
                                        <Controller
                                            control={control}
                                            name="name"
                                            rules={{ required: { value: true, message: 'Cupom inválido' } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.compName ? "true" : ""}
                                                    label="Nome"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    required
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Row>
                                <Row>
                                    <div className='d-flex justify-content-center'>
                                        <Button type="submit" className="btn">{prop.t("checkout.coupomCheckout.apply")}</Button>
                                    </div>
                                </Row>
                            </Form>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </Card>

            <Modal
                className="modal-confirm"
                show={showModal}
                onHide={() => {
                    setModalState('loading');
                    setModalMessage(prop.t("checkout.coupomCheckout.loading"));
                    setShowModal(false)
                }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <div className='modal-body text-center d-flex flex-column justify-content-evenly' style={{ height: '600px' }}>
                    {modalState === 'loading'
                        ?
                        <>
                            <div className="load-big"></div>
                        </>
                        :
                        modalState === 'error' ?
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    className='text-primary'
                                    style={{ fontSize: "7.5em"/* , color: "#EC421C" */ }}
                                />
                            </div>
                            :
                            <div>
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className='text-primary'
                                    style={{ fontSize: "7.5em"/* , color: "#EC421C" */ }}
                                />
                            </div>
                    }
                    <div className="px-md-5" style={{ padding: "0 3rem" }}>
                        {modalMessage}
                    </div>
                    <div>
                        <Button type="submit" className="btn btn-primary form-button px-4 py-2" onClick={() => setShowModal(false)}>{prop.t("checkout.coupomCheckout.close")}</Button>
                    </div>
                </div>
            </Modal>

        </>
    );
}

export default CupomCheckout;