import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterMarketingSalesReport.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

export interface propFilter {
    buscar: any,
}

const FilterMarketingSalesReport: React.FC<propFilter> = ({
    buscar
}) => {
    const today = new Date();
    const todayStr = [
        `${today.getDate() - 3 > 0 ? today.getDate() - 3 : 28}/${today.getDate() - 3 > 0 ? today.getMonth() + 1 : today.getMonth()}/${today.getFullYear()}`,
        `${today.getDate() - 3 > 0 ? today.getDate() - 1 : 28}/${today.getDate() - 3 > 0 ? today.getMonth() + 1 : today.getMonth()}/${today.getFullYear()}`
    ];

    const [filterDate, setFilterDate] = useState<any>(todayStr);
    const [filterTypeDate, setFilterTypeDate] = useState<any>(1);

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */

    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterDate.length === 2) {
            let beginDate = filterDate[0].split('/')
            let endDate = filterDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1]) - 1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1]) - 1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000 * 60 * 60 * 24);

            dataIntervalLong = (days > 90); // 3 meses

        }

        if (dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        buscar(filterDate, filterTypeDate);
    };

    /* const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterDate(todayStr);
        setFilterTypeDate(1);
        buscar(todayStr, 0);
    }; */

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Vendas</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate} defaultValues={[`${today.getFullYear()}-${today.getDate() - 3 > 0 ? today.getMonth() + 1 : today.getMonth()}-${today.getDate() - 3 > 0 ? today.getDate() - 3 : 28}`, `${today.getFullYear()}-${today.getDate() - 3 > 0 ? today.getMonth() + 1 : today.getMonth()}-${today.getDate() - 3 > 0 ? today.getDate() - 1 : 28}`]} />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            <option value="1">Data de compra</option>
                                                            <option value="2">Data de visita</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                {/* <Button style={{ width: "220px" }} variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button> */}
                                                <Button style={{ width: "220px" }} className="btn-default text-uppercase" onClick={handleFilterClick}>Agendar Relatório</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterMarketingSalesReport;