import React, { useState, useEffect, Key } from "react";
import { Card, Row, Col, Accordion, Container, ProgressBar, Button } from "react-bootstrap";
import api from "../../../../services/api";

import "./DashboardTableStockGroupSession.scss"

// import RequestResponseModal from "../../../components/Modal/RequestResponseModal/RequestResponseModal";
import Modal from "react-bootstrap/Modal";
import RequestResponseModal from "../../../../components/Modal/RequestResponseModal/RequestResponseModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import DateSlider from "../SliderDate/SliderDate";

const DashboardTableStockGroupSession = () => {
    const [loading, setLoading] = useState<boolean>(true);
    const [loadingDates, setLoadingDates] = useState<boolean>(false);
    const [loadingHours, setLoadingHours] = useState<boolean>(true);
    const [groupInfo, setGroupInfo] = useState<any>(null)
    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [modalText, /* setModalText */] = useState<any>(null);
    const [log, /* setLog */] = useState<any>();
    const [selectedRange, setSelectedRange] = useState<any>(null);

    const [stockDetails, setStockDetails] = useState<null | number>(null);
    const [infoStock, setInfoStock] = useState<any>([])

    //const [selectedProductCode, setSelectedProductCode] = useState<string>('')
    const [prodCode, setProdCode] = useState<string | null>(null)
    const [controll, setControll] = useState<boolean>(false)

    const groupSessionUniqueCode = window.location.href.split('groupSessionUniqueCode=')[1];

    // Obtenha a data atual
    const today = new Date();

    const ano = today.getFullYear();
    const mes = (today.getMonth() + 1).toString().padStart(2, '0'); // +1 porque janeiro é 0
    const dia = today.getDate().toString().padStart(2, '0');

    const [dataSearch, setDataSearch] = useState<any>(`${ano}-${mes}-${dia}T00:00:00`)

    const getStockByDate = async (dataSearch: any = null, index: number = 0, load: boolean = true) => {
        setLoadingHours(true);
        setProdCode(prodCode);
        //setSelectedProductCode(productCode);


        let startDate: any = '';
        let endDate: any = '';

        groupInfo?.stockGroupSessionItens?.forEach((elem: any) => {
            if (startDate === '' || new Date(elem.tariffStartDate) < new Date(startDate)) {
                startDate = elem.tariffStartDate
            }
            if (endDate === '' || new Date(elem.tariffEndDate) > new Date(endDate)) {
                endDate = elem.tariffEndDate;
            }
        });

        //let selectedProductInfo: any = groupInfo?.stockGroupSessionItens.find((elem: any) => { return elem.productCode === productCode })

        setSelectedRange(() => { return ({ startDate, endDate }) });

        let firstStockDate: any;
        if (dataSearch === null) {
            firstStockDate = new Date(`${ano}-${mes}-${dia}T00:00:00`/* selectedProductInfo.tariffStartDate */).toJSON().slice(0, 10)
        } else {
            firstStockDate = new Date(dataSearch)
        }


        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const dados = {
            "groupSessionUniqueCode": groupSessionUniqueCode,
            "DateStartSearch": firstStockDate,
            "DateEndSearch": firstStockDate
        }

        setLoadingDates(load);


        try {
            const { data } = await api.post(`/Stock/DashBoardStockModalityOrTicketByRangeDatesUp`, dados, config);

            if (data.statusCode === 200) {
                setLoadingDates(false);
                setControll(true);
                setStockDetails(0)
                setInfoStock(data.data[0].dashBoardByDayByRangeProductList/* [data.data[0].dashBoardByDayByRangeProductList.find((elem: any) => { return (elem.productCode === productCode) })] */)
                setLoadingHours(false);
            } else {
                setLoadingDates(false);
                setLoadingHours(false);
            }
        } catch (error: any) {
            setLoadingHours(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const getGroupSession = async () => {
            const dados = {
                "prodModCodeUnique": '',
                "tarCodeUnique": '',
                "groupSessionUniqueCode": groupSessionUniqueCode,
            }

            try {
                const { data } = await api.post(`/Stock/GetGroupSession`, dados, config);

                if (data.statusCode === 200) {
                    setGroupInfo(data?.data[0]);
                    setLoading(false);
                }
            } catch (error: any) { }
        }

        getGroupSession();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (groupInfo !== null) {
            getStockByDate();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groupInfo])

    useEffect(() => {
        if (controll === true) {
            getStockByDate(dataSearch, 0, false)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataSearch])

    function convertDate(dataISO: Date) {
        // // Data no formato ISO
        // var dataISO = "2023-09-15T00:00:00";

        // Criar um objeto Date com a data ISO
        var data = new Date(dataISO);

        // Extrair o dia, mês e ano da data
        var dia = data.getDate();
        var mes = data.getMonth() + 1; // Os meses são baseados em zero, então somamos 1
        var ano = data.getFullYear();

        // Formatar a data no formato "dd/mm/yyyy"
        var dataFormatada = dia + '/' + (mes < 10 ? '0' : '') + mes + '/' + ano;

        return (dataFormatada);

    }

    function calcularFracaoConsumida(total: number, consumido: number) {
        const fracao = consumido / total;

        if (fracao >= 1 / 3) {
            return "success";
        } else if (fracao === 2 / 3) {
            return "warning";
        } else if (fracao === 1) {
            return "danger";
        } else {
            return "";
        }
    }

    return (
        <Row className='dashboard-table-stock-group-session'>
            <Col md={/* loadingDates === false && stockDetails === null ? 12 : */ 12}>
                <Card className="bg-white my-4">
                    {
                        loading ?
                            <>
                                <div className="py-5">
                                    <div className="load primary"></div>
                                </div>
                            </>
                            :
                            <>
                                <div className='py-4 px-4'>
                                    <h2>{groupInfo?.groupSessionName}</h2>
                                    <p><b>Código de grupo:</b>  {groupInfo?.groupSessionUniqueCode}</p>

                                    {/* {groupInfo?.stockGroupSessionItens?.length > 0 && groupInfo?.stockGroupSessionItens?.map((elem: any, index: number) => {
                                        return (
                                            <>
                                                {index !== 0 && <hr />}
                                                <i>{elem?.productName} - {elem?.modalityName}</i> 

                                                <Row className="m-0 my-5">
                                                    <Col md={4} className="product-box px-4 py-4">
                                                        <div className="d-flex">
                                                            <FontAwesomeIcon className="text-primary mr-3" icon={['fal', 'box']} size="3x" />
                                                            <div>
                                                                <p className="mb-0">Período</p>

                                                                <p className="mb-0">{convertDate(elem?.tariffStartDate)} - {convertDate(elem?.tariffEndDate)}</p>
                                                            </div>
                                                        </div>
                                                    </Col>
                                                    <Col md={6}>
                                                        <div className="d-flex flex-column justify-content-evenly h-100">
                                                            <i><strong>Produto: </strong>{elem?.productName}</i>
                                                            <i><strong>Modalidade: </strong>{elem?.modalityName}</i>
                                                            <i><strong>Tarifário: </strong>{elem?.tariffName}</i>
                                                        </div>
                                                    </Col>
                                                    <Col md={2}>
                                                        <div className="d-flex justify-content-end align-items-end h-100">
                                                            <Button
                                                                type="button"
                                                                className=""
                                                                onClick={() => { getStockByDate(elem?.productCode) }}
                                                            >
                                                                Ver
                                                            </Button>
                                                        </div>
                                                    </Col>

                                                </Row>

                                            </>
                                        )
                                    })} */}

                                    {
                                        loadingDates ?
                                            <>
                                                <div className="bg-white my-4 py-4 px-4">
                                                    <div className="py-5">
                                                        <div className="load primary"></div>
                                                    </div>
                                                </div>
                                            </>
                                            :
                                            <>


                                                <div className="bg-white">
                                                    {/* <Row className="m-0">
                                                                    <Col md={12} className="border rounded-3 px-4 py-4" onClick={() => getStockByDate(selectedProductCode)}>
                                                                        <div className="d-flex">
                                                                            <FontAwesomeIcon className="text-primary mr-3" icon={['fal', 'box']} size="3x" />
                                                                            <div>
                                                                                <p className="mb-0">Período</p>
                                                                                <p className="mb-0">{convertDate(selectedRange?.startDate)} - {convertDate(selectedRange?.endDate)}</p>
                                                                            </div>
                                                                        </div>
                                                                    </Col>
                                                                </Row> */}

                                                    <Row className="d-flex justify-content-center w-100 mt-5 mx-0">
                                                        <DateSlider
                                                            changeDate={setDataSearch}
                                                            selectedRange={selectedRange}
                                                            loadingHours={loadingHours}
                                                        />
                                                    </Row>

                                                    {
                                                        infoStock &&
                                                        <Row className="m-0 mt-5">
                                                            {
                                                                loadingHours ?
                                                                    <>
                                                                        <div className="py-5">
                                                                            <div className="load primary"></div>
                                                                        </div>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <Accordion defaultActiveKey={new Array(infoStock[0]?.dashBoardByDayByRangeDatesList?.length).fill(null).map((elem: any, index: number) => { return (`${index}`) })/* ['0'] */} className="p-0" alwaysOpen>
                                                                            <Row>
                                                                                {
                                                                                    infoStock?.length > 0 && infoStock[0]?.dashBoardByDayByRangeDatesList?.length > 0
                                                                                        ?
                                                                                        infoStock[0]?.dashBoardByDayByRangeDatesList?.map((info: any, index: Key) => {
                                                                                            let allProductsWithInfoHour: any = infoStock?.filter((elem: any) => { return (elem?.dashBoardByDayByRangeDatesList?.map((listElem: any) => { return (listElem.hour) }).includes(info?.hour)) })
                                                                                            let totalSold: number = infoStock
                                                                                                .map((elem: any) => {
                                                                                                    return (elem.dashBoardByDayByRangeDatesList
                                                                                                        .find((findElem: any) => { return (findElem?.hour === info?.hour) }))
                                                                                                })
                                                                                                .filter((filterElem: any) => { return (filterElem !== undefined) })
                                                                                                .map((elem: any) => {
                                                                                                    return (elem.dashBoardBySalesChannelByRangeDatesList
                                                                                                        .map((mapElem: any) => { return (mapElem.soldByChannel) }))
                                                                                                        .reduce(function (acumulador: number, valorAtual: number) {
                                                                                                            return acumulador + valorAtual;
                                                                                                        }, 0);
                                                                                                })
                                                                                                .reduce(function (acumulador: number, valorAtual: number) {
                                                                                                    return acumulador + valorAtual;
                                                                                                }, 0)

                                                                                                let maxStock: number = infoStock[0]?.dashBoardByDayByRangeDatesList.find((elem: any) => {return(elem?.hour === info?.hour)})?.dashBoardBySalesChannelByRangeDatesList[0]?.amountOnSaleByChannel;

                                                                                            return (
                                                                                                <Col md={4}>
                                                                                                    <Accordion.Item className="mb-4 " eventKey={String(index)}>
                                                                                                        <Accordion.Header className="font-accordion-header-stock"><span className="h5 mb-0">{info?.hour?.split(':')[0] + ':' + info?.hour?.split(':')[1]}</span></Accordion.Header>
                                                                                                        <Accordion.Body className="pt-0 mt-3 mb-2">
                                                                                                            <Container className="p-0 pb-3" fluid>
                                                                                                                <Row className="m-0 p-0">
                                                                                                                    
                                                                                                                    <div className="mb-3">
                                                                                                                        <div className="mb-2">
                                                                                                                            <div className="d-flex justify-content-between"> <span>Estoque Geral</span> <span>{totalSold} / {maxStock}</span></div>
                                                                                                                            <ProgressBar variant={calcularFracaoConsumida(maxStock, totalSold)} className="p-0" now={totalSold} max={maxStock} />
                                                                                                                        </div>
                                                                                                                    </div>

                                                                                                                    {allProductsWithInfoHour.map((product: any) => {
                                                                                                                        return (
                                                                                                                            <div className="mb-3">
                                                                                                                                <p className="" style={{ marginBottom: '0.5rem' }}>Produto: <strong>{groupInfo?.stockGroupSessionItens?.find((elem: any) => { return (elem?.productCode === product?.productCode) })?.productName}</strong></p>
                                                                                                                                {infoStock?.find((elem: any) => { return (elem?.productCode === product?.productCode) })?.dashBoardByDayByRangeDatesList?.find((elem: any) => { return (elem?.hour === info?.hour) })?.dashBoardBySalesChannelByRangeDatesList?.map((chanels: any) => (
                                                                                                                                    <div className="mb-2">
                                                                                                                                        <div className="d-flex justify-content-between"> <span>{chanels.salesChannelName}</span> <span>{chanels.soldByChannel} / {chanels?.amountLimit || chanels.amountOnSaleByChannel}</span></div>
                                                                                                                                        <ProgressBar variant={calcularFracaoConsumida(chanels?.amountLimit || chanels.balanceAvailableByChannel, chanels.soldByChannel)} className="p-0" now={chanels.soldByChannel} max={chanels?.amountLimit || chanels.amountOnSaleByChannel} />
                                                                                                                                    </div>

                                                                                                                                ))}

                                                                                                                            </div>
                                                                                                                        )
                                                                                                                    })}

                                                                                                                </Row>
                                                                                                            </Container>
                                                                                                        </Accordion.Body>
                                                                                                    </Accordion.Item>
                                                                                                </Col>
                                                                                            )
                                                                                        })
                                                                                        :
                                                                                        <>
                                                                                            Data inválida
                                                                                        </>
                                                                                }
                                                                            </Row>
                                                                        </Accordion>
                                                                    </>
                                                            }
                                                        </Row>
                                                    }
                                                </div>

                                            </>
                                    }
                                </div>
                            </>
                    }
                </Card>
                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                    <div className="btn-sucess">
                        <Modal
                            className="modal-custom modalAuth"
                            show={modalResponse !== null}
                            onHide={() => setModalResponse(null)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                {/* <Modal.Header closeButton></Modal.Header> */}
                                <RequestResponseModal
                                    name={"Fornecedor"}
                                    modalResponse={modalResponse}
                                    setModalResponse={setModalResponse}
                                    modalText={modalText}
                                    log={log}
                                />
                            </>
                        </Modal>
                    </div>
                </Row>
            </Col>


            {/* <Col md={4}>
                {
                    loadingDates ?
                        <>
                            <Card className="bg-white my-4 py-4 px-4">
                                <div className="py-5">
                                    <div className="load primary"></div>
                                </div>
                            </Card>
                        </>
                        :
                        <>
                            {
                                selectedProductCode === '' ?
                                    <>
                                        <Card className="bg-white my-4 py-4 px-4">
                                        </Card>
                                    </>
                                    :
                                    <>

                                        <Card className="bg-white my-4 py-4 px-4">
                                            <Row className="m-0">
                                                <Col md={12} className="border rounded-3 px-4 py-4" onClick={() => getStockByDate(selectedProductCode)}>
                                                    <div className="d-flex">
                                                        <FontAwesomeIcon className="text-primary mr-3" icon={['fal', 'box']} size="3x" />
                                                        <div>
                                                            <p className="mb-0">Período</p>
                                                            <p className="mb-0">{convertDate(selectedRange?.startDate)} - {convertDate(selectedRange?.endDate)}</p>
                                                        </div>
                                                    </div>
                                                </Col>
                                            </Row>

                                            <Row className="d-flex justify-content-center w-100 mt-5 mx-0">
                                                <DateSlider
                                                    changeDate={setDataSearch}
                                                    selectedRange={selectedRange}
                                                    loadingHours={loadingHours}
                                                />
                                            </Row>

                                            {
                                                infoStock &&
                                                <Row className="m-0 mt-5">
                                                    {
                                                        loadingHours ?
                                                            <>
                                                                <div className="py-5">
                                                                    <div className="load primary"></div>
                                                                </div>
                                                            </>
                                                            :
                                                            <>
                                                                <Accordion defaultActiveKey="0" className="p-0" >
                                                                    {
                                                                        infoStock?.length > 0 && infoStock[0]?.dashBoardByDayByRangeDatesList?.length > 0
                                                                            ?
                                                                            infoStock[0]?.dashBoardByDayByRangeDatesList?.map((info: any, index: Key) => {
                                                                                return (
                                                                                    <Accordion.Item className="mb-4 " eventKey={String(index)}>
                                                                                        <Accordion.Header className="font-accordion-header-stock"><span className="h5 mb-0">{info?.hour?.split(':')[0] + ':' + info?.hour?.split(':')[1]}</span></Accordion.Header>
                                                                                        <Accordion.Body className="pt-0 mt-3 mb-2">
                                                                                            <Container className="p-0 pb-3" fluid>
                                                                                                <Row className="m-0 p-0">
                                                                                                    {info.dashBoardBySalesChannelByRangeDatesList.map((chanels: any) => (
                                                                                                        <>
                                                                                                            <div className="d-flex justify-content-between"> <span>{chanels.salesChannelName}</span> <span>{chanels.soldByChannel} / {chanels.amountOnSaleByChannel}</span></div>
                                                                                                            <ProgressBar variant={calcularFracaoConsumida(chanels.balanceAvailableByChannel, chanels.soldByChannel)} className="p-0" now={chanels.soldByChannel} max={chanels.amountOnSaleByChannel} />
                                                                                                        </>

                                                                                                    ))}

                                                                                                </Row>
                                                                                            </Container>
                                                                                        </Accordion.Body>
                                                                                    </Accordion.Item>
                                                                                )
                                                                            })
                                                                            :
                                                                            <>
                                                                                Data inválida
                                                                            </>
                                                                    }
                                                                </Accordion>
                                                            </>
                                                    }
                                                </Row>
                                            }
                                        </Card>
                                    </>
                            }
                        </>
                }

            </Col> */}


        </Row>
    );
}



export default DashboardTableStockGroupSession;
