import React, { useEffect, useState } from "react";
import api from "../../../services/api";

import { Link } from "react-router-dom";
import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../../templates/DesktopDefault";
import ResourcesVehicleRankingFilter from "../components/ResourcesVehicleRankingFilter/ResourcesVehicleRankingFilter";
import ResourcesVehicleRankingTable from "../components/ResourcesVehicleRankingTable/ResourcesVehicleRankingTable";

function ResourcesVehicleRanking() {   

  const [resources, setResources] = useState<any>([]);  

  /* Filtragem */
  const [actualSupplierId, setActualSupplierId] = useState<any>(0);
  const [actualVehicleType, setActualVehicleType] = useState<any>(0);  
  /* END - Filtragem */

  //loading
  const [loading, setLoading] = useState<any>(false);
  //loading

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

 

  useEffect(() => {
    // setLoading(true);
    // setResources(null);

    // const token = localStorage.getItem("GroupId") || "{}";
    // const config = {
    //   headers: { Authorization: `Bearer ${token}` },
    // };
    // async function listResources() {
    //   try {
    //     const { data } = await api.post(
    //       "Resources/GetResourcesByFilterAsync",
    //       {
    //         page: pageCount,
    //         rowsPerPage: rowsPerPage,
    //         code: "",
    //         resourceNameFind: "",
    //         resourceTypeFind: 0,
    //         vehicleTypeFind: 0,
    //         //supplierGroupIdFind: 0,
    //         supplierIdFind: 0,
    //         orderByField: ""
    //       },
    //       config
    //     );

    //     if (data.status !== 400) {  
    //       console.log(data.data.data.rows)          
    //       setLoading(false);
    //       setResources(data.data.data.rows);
    //       setTotalRows(data.data.data.rowsCount);
    //     }
    //   } catch (error: any) {
    //     setLoading(false);
    //     // if (error.response.status === 401) {
    //     //   window.location.href = window.location.origin + "/";
    //     // }
    //   }
    // }
    // listResources();
    apiSearch(actualSupplierId, actualVehicleType);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  function clearActualValues() {
    setActualSupplierId(0);
    setActualVehicleType(0);
  }

  async function apiSearch(supplierId: any, vehicleType: any) {
    setResources(null);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {
      const res = await api.post('Resources/GetVehiclesBySupplierAndType',
        {
            //page: pageCount,
            //rowsPerPage: rowsPerPage,
            supplierId: supplierId,
            vehicleType: vehicleType,
        }
        , config
      );

      if (res.status !== 400) {
        setLoading(false);
        setResources(res.data.data);
        //setTotalRows(res.data.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
    //   if (error.response.status === 401) {
    //     window.location.href = window.location.origin + '/';
    //   }
    }
  }

  async function buscar(supplierId: any, vehicleType: any) { 
    setActualSupplierId(supplierId);
    setActualVehicleType(vehicleType);
    setPageCount(1);

    apiSearch(supplierId, vehicleType);
  };

  const reloadTable = () => {
    buscar(actualSupplierId, actualVehicleType);
  }



  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={"Ranking de Veículos"} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
              Ranking de Veículos{""}
            </h2>
          </div>

          <ResourcesVehicleRankingFilter
            buscar={buscar}
            resources={resources}
            clearActualValues={clearActualValues}
          />

          <ResourcesVehicleRankingTable
            resources={resources}
            loading={loading}
            //totalRows={totalRows}
            //pageCount={pageCount}
            //setPageCount={setPageCount}
            //rowsPerPage={rowsPerPage}
            //setRowsPerPage={setRowsPerPage}
            reloadTable={reloadTable}
          />

        </div>
      </DesktopDefault>
    </>
  );
}

export default ResourcesVehicleRanking;
