import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './ListSuppliers.scss'
import { useTranslation } from 'react-i18next';

//COMPONENTS
import SuppliersFilter from '../components/ListSuppliers/SuppliersFilter/SuppliersFilter';
import SuppliersTable from '../components/ListSuppliers/SuppliersTable/SuppliersTable';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import { Link } from 'react-router-dom';
import DesktopDefault from '../../../templates/DesktopDefault';

function ListSuppliers() {
  const { t } = useTranslation();
  const [suppliers, setSuppliers] = useState<any>(null);
  const [error, setError] = useState<any>();
  const [alert, setAlert] = useState<any>(false);
  const [loading, setLoading] = useState<any>(false);

  const [actualName, setActualName] = useState<any>('');
  const [actualState, setActualState] = useState<any>('');
  const [actualDate, setActualDate] = useState<any>([]);
  const [actualClientType, setActualClientType] = useState<any>(0);
  const [actualStatus, setActualStatus] = useState<any>(0);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  useEffect(() => {
    setLoading(true);
    setSuppliers(null);

    let beginDate: any = []
    let endDate: any = []


    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.post('Supplier/GetSuppliersByFilterAsync',
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "name": actualName,
            "state": actualState,
            "status": actualStatus,
            "type": actualClientType,
            "country": 0,
            "beginDate": actualDate.length > 0 ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : '',
            "endDate": actualDate.length === 1 ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : actualDate.length === 2 ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : '',
            "orderByField": ""
          }, config
        );

        if (data.status !== 400) {
          setLoading(false);
          setSuppliers(data.data.rows);
          setTotalRows(data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  async function buscar(
    name: string = actualName,
    state: string = actualState,
    type: any = actualClientType,
    status: any = actualStatus,
    date: any = actualDate
  ) {
    setActualName(name);
    setActualState(state);
    setActualClientType(type);
    setActualStatus(status);
    setActualDate(date);

    setLoading(true);
    setSuppliers(null);

    setPageCount(1);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    let beginDate = []
    let endDate = []


    if (date.length === 1) {
      beginDate = date[0].split('/')
    } else if (date.length === 2) {
      beginDate = date[0].split('/')
      endDate = date[1].split('/')
    }

    try {
      const res = await api.post('Supplier/GetSuppliersByFilterAsync',
        {
          "page": 1,
          "rowsPerPage": rowsPerPage,
          "name": name,
          "state": state,
          "status": status,
          "type": parseInt(type),
          "country": 0,
          "beginDate": date.length > 0 ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : '',
          "endDate": date.length === 1 ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0]) + 1}` : date.length === 2 ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : '',
          "orderByField": ""
        }, config
      );
      if (res.status !== 400) {
        setLoading(false);
        setSuppliers(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      
      setError(true)
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  };

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={`${t("listSuppliers.breadSubtitle")} / ${t("listSuppliers.breadTitle")}`} />
          <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
              {t("listSuppliers.title")}
            </h2>
            {alert}
            <Link to={'/suppliers/add-suppliers'} className='btn btn-primary'>
              {t("listSuppliers.btnNew")}
            </Link>
          </div>
          <SuppliersFilter
            buscar={buscar}
            setAlert={setAlert}
            suppliers={suppliers}
            error={error}
          />
          <SuppliersTable
            buscar={buscar}
            suppliers={suppliers}
            loading={loading}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
          />
        </div>
      </DesktopDefault>
    </>
  );
}

export default ListSuppliers;