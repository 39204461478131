import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { addToComboObject } from "../../../../store/combo";

export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
};

const SEO: React.FC<operation> = React.memo(({
    action, isEdit, editFunction = () => { }
}: operation) => {

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */

    const minLengthPT = 5;
    const maxLengthPT = 350;
    const minLengthEN = 5;
    const maxLengthEN = 350;
    const minLengthES = 5;
    const maxLengthES = 350;
    const [numberOfCaractersPT, setNumberOfCaractersPT] = useState<any>("0");
    const [numberOfCaractersEN, setNumberOfCaractersEN] = useState<any>("0");
    const [numberOfCaractersES, setNumberOfCaractersES] = useState<any>("0");

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({});

    const onSubmit = (data: any) => {
        const comboCode = isEdit ? window.location.href.split('comboCode=')[1] : undefined;

        let dataToInsert: any = data;

        dataToInsert.metaTitle = dataToInsert.metaTitle.map((elem: any) => {
            return (
                {
                    ...elem, comboCode: comboCode
                }
            )
        })
        dataToInsert.metaDescription = dataToInsert.metaDescription.map((elem: any) => {
            return (
                {
                    ...elem, comboCode: comboCode
                }
            )
        })
        dataToInsert.keyWord = dataToInsert.keyWord.map((elem: any) => {
            return (
                {
                    ...elem, comboCode: comboCode
                }
            )
        })

        if(isEdit){
            editFunction(dataToInsert);
        } else {
            dispatch(addToComboObject(JSON.stringify(dataToInsert)));
            action("finish");
        }
    }

    useEffect(()=>{
        let data: any = getValues();

        if(data.keyWord[0].keyword[0].text === undefined){
            setValue("keyWord[0].keyword[0].text", reduxComboObject.name[0].text.replaceAll(' ', '-').toLowerCase());
        }
        if(data.keyWord[0].keyword[1].text === undefined){
            setValue("keyWord[0].keyword[1].text", reduxComboObject.name[1].text.replaceAll(' ', '-').toLowerCase());
        }
        if(data.keyWord[0].keyword[2].text === undefined){
            setValue("keyWord[0].keyword[2].text", reduxComboObject.name[2].text.replaceAll(' ', '-').toLowerCase());
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[])

    return (
        <>
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content">
                            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-primary pt-title mb-0">Search Engine Optimization</h3>

                                <div>
                                    <h4 className="my-4 pt-subtitle">Meta Data</h4>
                                </div>
                                <div className="" style={{ border: "none", padding: '24px 0' }}>

                                    <Row>
                                        <div>
                                            <h5>Português</h5>
                                        </div>

                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Chave URL (Português)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="keyWord[0].keyword[0].text"
                                                        defaultValue={reduxComboObject?.keyWord && reduxComboObject?.keyWord[0].keyword[0].text}
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o Slug' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                //aria-invalid={errors?.keyWord[0].keyword[0].text ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('keyWord[0].keyword[0].text', e.target.value)
                                                                }}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="keyWord[0].keyword[0].text"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Controller
                                                    control={control}
                                                    name={`keyWord[0].keyword[0].lang`}
                                                    defaultValue={1}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="hidden"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />

                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Meta Title (Português)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="metaTitle[0].metaTitle[0].text"
                                                        defaultValue={reduxComboObject?.metaTitle && reduxComboObject?.metaTitle[0].metaTitle[0].text}
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o meta title' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                //aria-invalid={errors?.metaTitle[0].metaTitle[0].text ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('metaTitle[0].metaTitle[0].text', e.target.value)
                                                                }}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="metaTitle[0].metaTitle[0].text"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Controller
                                                    control={control}
                                                    name={`metaTitle[0].metaTitle[0].lang`}
                                                    defaultValue={1}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="hidden"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />
                                            </Row>
                                        </Col>

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Meta Description (Português)</span>
                                                <span style={{ fontWeight: 400 }}>{numberOfCaractersPT}/{maxLengthPT}</span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="metaDescription[0].metaDescription[0].text"
                                                defaultValue={reduxComboObject?.metaDescription?.length > 0 ? reduxComboObject?.metaDescription[0].metaDescription[0].text : ''}
                                                rules={{
                                                    required: { value: true, message: 'Por favor, informe o meta description' },
                                                    minLength: {
                                                        value: minLengthPT,
                                                        message: `Por favor, insira ao menos ${minLengthPT} caracteres`
                                                    },
                                                    maxLength: {
                                                        value: maxLengthPT,
                                                        message: `Por favor, insira no máximo ${maxLengthPT} caracteres`
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        as="textarea"
                                                        rows={5}
                                                        type="text"
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setValue('metaDescription[0].metaDescription[0].text', e.target.value);
                                                            setNumberOfCaractersPT(e.target.value.length)
                                                        }}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="metaDescription[0].metaDescription[0].text"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        <Controller
                                            control={control}
                                            name={`metaDescription[0].metaDescription[0].lang`}
                                            defaultValue={1}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </Row>
                                </div>

                                <hr />

                                <div className="" style={{ border: "none", padding: '24px 0' }}>
                                    <Row>
                                        <div>
                                            <h5>Inglês</h5>
                                        </div>
                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Chave URL (Inglês)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="keyWord[0].keyword[1].text"
                                                        defaultValue={reduxComboObject?.keyWord && reduxComboObject?.keyWord[0].keyword[1].text}
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o Slug' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                //aria-invalid={errors?.keyWord[0].keyword[1].text ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('keyWord[0].keyword[1].text', e.target.value)
                                                                }}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="keyWord[0].keyword[1].text"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                                <Controller
                                                    control={control}
                                                    name={`keyWord[0].keyword[1].lang`}
                                                    defaultValue={2}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="hidden"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />


                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Meta Title (Inglês)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="metaTitle[0].metaTitle[1].text"
                                                        defaultValue={reduxComboObject?.metaTitle && reduxComboObject?.metaTitle[0].metaTitle[1].text}
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o meta title' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                //aria-invalid={errors?.metaTitle[0].metaTitle[1].text ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('metaTitle[0].metaTitle[1].text', e.target.value)
                                                                }}
                                                                //value={metaTitle[0].metaTitle[1].text}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="metaTitle[0].metaTitle[1].text"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                                <Controller
                                                    control={control}
                                                    name={`metaTitle[0].metaTitle[1].lang`}
                                                    defaultValue={2}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="hidden"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />
                                            </Row>
                                        </Col>

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Meta Description (Inglês)</span>
                                                <span style={{ fontWeight: 400 }}>{numberOfCaractersEN}/{maxLengthEN}</span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="metaDescription[0].metaDescription[1].text"
                                                defaultValue={reduxComboObject?.metaDescription?.length > 0 ? reduxComboObject?.metaDescription[0].metaDescription[1].text : ''}
                                                rules={{
                                                    required: { value: true, message: 'Por favor, informe o meta description' },
                                                    minLength: {
                                                        value: minLengthEN,
                                                        message: `Por favor, insira ao menos ${minLengthEN} caracteres`
                                                    },
                                                    maxLength: {
                                                        value: maxLengthEN,
                                                        message: `Por favor, insira no máximo ${maxLengthEN} caracteres`
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        as="textarea"
                                                        rows={5}
                                                        type="text"
                                                        //aria-invalid={errors?.metaDescription[0].metaDescription[1].text ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setValue('metaDescription[0].metaDescription[1].text', e.target.value);
                                                            setNumberOfCaractersEN(e.target.value.length);
                                                        }}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="metaDescription[0].metaDescription[1].text"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        <Controller
                                            control={control}
                                            name={`metaDescription[0].metaDescription[1].lang`}
                                            defaultValue={2}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </Row>
                                </div>

                                <hr />

                                <div className="" style={{ border: "none", padding: '24px 0' }}>
                                    <Row>
                                        <div>
                                            <h5>Espanhol</h5>
                                        </div>
                                        <Col md='6'>
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Chave URL (Espanhol)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="keyWord[0].keyword[2].text"
                                                        defaultValue={reduxComboObject?.keyWord && reduxComboObject?.keyWord[0].keyword[2].text}
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o Slug' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                //aria-invalid={errors?.keyWord[0].keyword[2].text ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('keyWord[0].keyword[2].text', e.target.value)
                                                                }}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="keyWord[0].keyword[2].text"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                                <Controller
                                                    control={control}
                                                    name={`keyWord[0].keyword[2].lang`}
                                                    defaultValue={3}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="hidden"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />

                                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                                    <Form.Label className="label-small">
                                                        Meta Title (Espanhol)
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name="metaTitle[0].metaTitle[2].text"
                                                        defaultValue={reduxComboObject?.metaTitle && reduxComboObject?.metaTitle[0].metaTitle[2].text}
                                                        rules={{
                                                            required: { value: true, message: 'Por favor, informe o meta title' },
                                                            minLength: {
                                                                value: 5,
                                                                message: 'Por favor, insira ao menos 5 caracteres'
                                                            },
                                                            maxLength: {
                                                                value: 200,
                                                                message: 'Por favor, insira no máximo 200 caracteres'
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                //aria-invalid={errors?.metaTitle[0].metaTitle[2].text ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => {
                                                                    setValue('metaTitle[0].metaTitle[2].text', e.target.value)
                                                                }}
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name="metaTitle[0].metaTitle[2].text"
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                                <Controller
                                                    control={control}
                                                    name={`metaTitle[0].metaTitle[2].lang`}
                                                    defaultValue={3}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="hidden"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />
                                            </Row>
                                        </Col>

                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                            <Form.Label className="label-small d-flex justify-content-between">
                                                <span>Meta Description (Espanhol)</span>
                                                <span style={{ fontWeight: 400 }}>{numberOfCaractersES}/{maxLengthES}</span>
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="metaDescription[0].metaDescription[2].text"
                                                defaultValue={reduxComboObject?.metaDescription?.length > 0 ? reduxComboObject?.metaDescription[0].metaDescription[2].text : ''}
                                                rules={{
                                                    required: { value: true, message: 'Por favor, informe o meta description' },
                                                    minLength: {
                                                        value: minLengthES,
                                                        message: `Por favor, insira ao menos ${minLengthES} caracteres`
                                                    },
                                                    maxLength: {
                                                        value: maxLengthES,
                                                        message: `Por favor, insira no máximo ${maxLengthES} caracteres`
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        as="textarea"
                                                        rows={5}
                                                        type="text"
                                                        //aria-invalid={errors?.metaDescription[0].metaDescription[2].text ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        onChange={(e: any) => {
                                                            setValue('metaDescription[0].metaDescription[2].text', e.target.value);
                                                            setNumberOfCaractersES(e.target.value.length);
                                                        }}
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="metaDescription[0].metaDescription[2].text"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>

                                        <Controller
                                            control={control}
                                            name={`metaDescription[0].metaDescription[2].lang`}
                                            defaultValue={3}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="hidden"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                />
                                            )}
                                        />
                                    </Row>
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {
                                        isEdit !== true
                                            ?
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action('instructions')}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Continuar
                                                </Button>
                                            </Row>
                                            :
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                //onClick={() => action('meetingPoint')}
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                    }
                                </div>
                            </Form>
                        </Card>
                    </Col >
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
})

export default SEO;

