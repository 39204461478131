import React, { useState, useEffect } from 'react';

import { Row, Col } from 'react-bootstrap';

import api from '../../../../../../services/api';

import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";

import TableInvoice from './TableInvoice/TableInvoice';
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import SingleCalendar from "../../../../../../components/Calendar/SingleCalendar";
import ModalConfirm from '../../../../../../components/Modal/ModalConfirm/ModalConfirm';

export interface propExp {
    billsReceive: any;
    totalRows: any;
    pageCount: any;
    rowsPerPage: any;
    parameters?: any;
    setModalSuccess: any;
    setResponseText: any;
    setModalContent: any;
    setLog: any;
}

const Invoice: React.FC<propExp> = ({
    billsReceive, totalRows, pageCount, rowsPerPage, parameters, setModalSuccess, setResponseText, setModalContent, setLog
}: propExp) => {

    const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');

        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
    }

    const [loading, setLoading] = useState<any>(false);

    const [selectedCheck, setSelectedCheck] = useState<any>([]);
    const [itemsToInvoice, setItemsToInvoice] = useState<any>([]);
    const [expiration, setExpiration] = useState<any>('');
    //const [responseText, setResponseText] = useState<any>();
    //const [modalResponse, setModalResponse] = useState<any>();
    const [agentName, setAgentName] = useState<any>('');
    const [expirationReadOnly, setExpirationReadOnly] = useState<any>(false);
    
    const [invoiceBills, setInvoiceBills] = useState<any>(billsReceive);
    const [invoicePageCount, setInvoicePageCount] = useState<any>(pageCount);
    const [invoiceRowsPerPage, setInvoiceRowsPerPage] = useState<any>(rowsPerPage);

    const [selectAllFat, setSelectAllFat] = useState<boolean>(false);

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    const {
        control,
        handleSubmit,
    } = useForm();

    const onSubmit = (data: any) => {
        
        if(!expiration) {
            setResponseText('Deve ser preenchido a data de vencimento.');
            setModalContent('warning');
            setLog(2); 
            setModalSuccess(true);
            return;
        }

        if(!data.empresa) {
            setResponseText('Deve ser preenchido o logo.');
            setModalContent('warning');
            setLog(2); 
            setModalSuccess(true);
            return;
        }

        if(selectAllFat === false && itemsToInvoice.length <= 0) {
            setResponseText('Selecione ao menos uma reserva para faturar.');
            setModalContent('warning');
            setLog(2); 
            setModalSuccess(true);
            return;
        }

        setModalSuccess(true);
        setModalContent('loading');

        //let today: any = new Date();

        data.dataVcto = expiration.split("/").reverse().join('-');

        let auxExp: any = [];
        let auxParams: any = null;

        //parameters.statusPagamento = parameters.statusPagamento !== null ? parameters.statusPagamento : 0;

        if(selectAllFat === true) {
            auxParams = parameters;
        } else {
            for (let i: any = 0; i < itemsToInvoice.length; i++) {
                auxExp.push(itemsToInvoice[i].localizador);
            }
        }

        data.items = auxExp;

        //console.log(data, "oh lacrimosa");
        //return;

        async function changeExpiration() {
            if ((selectedCheck.length !== 0 || selectAllFat) && expiration !== "") {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post('/BillsReceivable/CreateInvoice',
                        {
                            "parametros": auxParams,
                            "empresa": data.empresa,
                            "items": data.items,
                            "vencimento": data.dataVcto,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config
                    );
                    if (res.data.status !== 400) {
                        const dados = res.data.data;

                        setLog(dados.log);
                        if (dados.log === 0) {
                            setResponseText(dados.texto)
                            setModalContent('success');
                            /* setAllowReload(true); */
                            const fileName = 'Fatura Nº ' + dados.numeroFatura + ' ' + agentName.substring(0,30) + '.pdf';

                            downloadBase64(dados.impressaoFatura, 'application/pdf', fileName);
                        }
                        else if (dados.log === 1) {
                            setResponseText(dados.texto)
                            setModalContent('error');
                        }
                    }
                } catch (error: any) {
                    setResponseText("Algo deu errado");
                    setModalContent('error');
                    
                    // if (error?.response?.status === 401) {
                    // }
                }
            } else {
                setResponseText("Preencha os campos necessários!");
                setModalContent('error');
            }
        }
        changeExpiration();

    }

    function downloadBase64(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        let link = document.createElement('a');
        link.href = 'data:'+filetype+';base64,'+file;
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
        link.remove();
        window.URL.revokeObjectURL(link.href);
    }

    function handleClick(cell: any) {
        const getBills = invoiceBills.find((element: { localizador: any; }) => element.localizador === cell);
        setSelectedCheck((prevState: any) => [...prevState, getBills]);
    }

    function handleCheckAllClick() {
        setSelectAllFat(!selectAllFat);
    }

    useEffect(() => {
        if (selectedCheck.length > 1) {
            var lastOne: any = selectedCheck[selectedCheck.length - 1];
            var aux: any = [];
            var add: boolean = true;
            for (var i: any = 0; i < itemsToInvoice.length; i++) {
                if (itemsToInvoice[i].localizador !== lastOne.localizador) {
                    aux.push(itemsToInvoice[i]);
                } else {
                    add = false;
                }
            }

            if (add === true) {
                setItemsToInvoice((prevState: any) => [...prevState, lastOne]);
            } else {
                setItemsToInvoice(aux);
            }

        } else if (selectedCheck.length === 1) {
            setItemsToInvoice(selectedCheck);
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedCheck])

    function confereChecked(cell: any) {
        for (var i: any = 0; i < itemsToInvoice.length; i++) {
            if (itemsToInvoice[i].localizador === cell) {
                return true;
            }
        }
        return false;
    }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    } 

    function loadingFunc() {
        return (
          <>
            <div className="animated-background row-loading"></div>
          </>
        )
      }
    
      const loadingColumns = [{ dataField: "contasd", text: "Carregando contas", formatter: loadingFunc }];
    
      const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
      ];

    useEffect(() => {

        async function billTopPay() {
            setLoading(true);
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };

                const { data } = await api.post('/BillsReceivable/GetReportSingle',
                    {
                        "page": invoicePageCount,
                        "rowsPerPage": invoiceRowsPerPage,
                        "item": parameters.item,
                        "idDecolarFenix": parameters.idDecolarFenix,
                        "tokenNsu": parameters.tokenNsu,
                        "agenteRef": parameters.agenteRef,
                        "erp": 1, // não tinha
                        "filtroErp": parameters.filtroErp ? parameters.filtroErp : 2,
                        "statusErp": parameters.statusErp ? parseInt(parameters.statusErp) : null,
                        "statusReserva": parameters.statusReserva,
                        "statusPagamento": parameters.statusPagamento,
                        "formaPagamento": parameters.formaPagamento,
                        "adquirente": parameters.adquirente,
                        "fornecedor": parameters.fornecedor,
                        "agenteIndividual": parameters.agenteIndividual,
                        "agente": parameters.agente,
                        "cliente": parameters.cliente,
                        "tipoData": parameters.tipoData,
                        "dataInicial": parameters.dataInicial,
                        "dataFinal": parameters.dataFinal
                    }, config
                );

                if (data.status !== 400) {
                    const dados = data.data.rows;
                    setInvoiceBills(dados);

                    if(agentName === '' && dados[0]) {
                        setAgentName(dados[0].agente);
                    } 

                    if(expiration === '' && ((parameters.tipoData === 3 && parameters.dataInicial === parameters.dataFinal) || dados.length === 1)) {
                        const venc = formatDate(parameters.dataInicial);
                        setExpiration(venc);
                        setExpirationReadOnly(true);
                    }
                }
            } catch (error: any) {
                ;
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
            setLoading(false);
        }
        billTopPay();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [invoicePageCount, invoiceRowsPerPage])

    if(loading === false) {
        return (
            <>
                <div className='modal-content-note pt-1 pb-4'>
                    <Row>
                        <Col md={3}>
                            <p className='mb-1'><strong>Agente</strong></p>
                            <span>{agentName}</span>
                        </Col>
                        <Col md={3}>
                            <p className='mb-1'><strong>Vencimento</strong></p>
                            {expirationReadOnly === false ?
                            <SingleCalendar setValue={setExpiration} value={expiration} />
                            : 
                            <span>{expiration}</span>}
                        </Col>
                        <Col md={3} className="d-flex align-items-end">
                            <Form.Check
                                name={'checkAll'}
                                //fullWidth
                                //variant="standard"
                                //margin="normal"
                                type="checkbox"
                                defaultChecked={selectAllFat}
                                //value={selectAllFat}
                                onClick={() => { handleCheckAllClick() }}
                                label={"Selecionar Tudo"}
                            />
                        </Col>
                    </Row>
                </div>
                <div>
                    <TableInvoice 
                        invoiceBills={invoiceBills}
                        totalRows={totalRows}
                        invoicePageCount={invoicePageCount}
                        setInvoicePageCount={setInvoicePageCount}
                        invoiceRowsPerPage={invoiceRowsPerPage}
                        setInvoiceRowsPerPage={setInvoiceRowsPerPage}
                        confereChecked={confereChecked}
                        handleClick={handleClick}
                        selectAllFat={selectAllFat}
                    />
                    
                </div>
                <div className='mt-4 d-flex justify-content-end'>
                    <div className="col-sm-12 col-md-3 mr-auto d-flex justify-content-end align-middle">
                        <strong className="mr-3">Logo:</strong> 
                        <div className="form-check mr-3">
                            <Form.Label className="form-check-label">
                                <Controller
                                    control={control}
                                    name="empresa"
                                    render={({ field }: any) => (
                                        <Form.Check
                                        autoComplete='off'
                                            {...field}
                                            fullWidth
                                            variant="standard"
                                            margin="normal"
                                            type="radio"
                                            // defaultChecked={confereChecked(cell)}
                                            value="1"
                                            // className="form-check-input"
                                            // onClick={() => { handleClick(cell) }}
                                        />
                                    )}
                                />
                                {/* <input autoComplete='off' type="radio" id="logoRfat1" name="logoRfat" value="1"  />  */}
                                A
                            </Form.Label>
                        </div> 
                        <div className="form-check mr-3">
                            <Form.Label className="form-check-label">
                                <Controller
                                    control={control}
                                    name="empresa"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            fullWidth
                                            autoComplete='off'
                                            variant="standard"
                                            margin="normal"
                                            type="radio"
                                            // defaultChecked={confereChecked(cell)}
                                            value="2"
                                            // className="form-check-input"
                                            // onClick={() => { handleClick(cell) }}
                                        />
                                    )}
                                />
                                {/* <input autoComplete='off' type="radio" id="logoRfat2" name="logoRfat" value="2" className="form-check-input" />  */}
                                B
                            </Form.Label>
                        </div>
                    </div>
                    <div className='btn-expiration'>
                        <button
                            className="btn btn-default btn-outline-primary mx-2"
                            style={{width: 'auto'}}
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                    </div>
                    
                    <div className='btn-expiration'>
                        <button className="btn btn-primary" onClick={handleSubmit(onSubmit)}>
                            Confirmar
                        </button>
                    </div>

                    {/* <ModalConfirm
                        handleSubmit={handleSubmit}
                        onSubmit={onSubmit}
                        expiration="yes"
                        confirm={responseText}
                        modalContent={modalResponse}
                    /> */}

                    {/* <button
                        className="btn btn-default btn-primary px-4 text-uppercase"
                        type="submit"

                    >
                        {
                            loading === true
                                ?
                                <div className="load"></div>
                                :
                                "Faturar"
                        }
                    </button> */}
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className='modal-content-note pt-1 pb-4'>
                    <Row>
                        <Col md={3}>
                            <p className='mb-1'><strong>Agente</strong></p>
                            <span>{agentName}</span>
                        </Col>
                        <Col md={3}>
                            <p className='mb-1'><strong>Vencimento</strong></p>
                            <span>{expiration}</span>
                        </Col>
                    </Row>
                </div>
                <div>
                    <div className="table-invoice filter justify-content-center mt-4 loading not-found">
                        <div className="table-container">
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={loadingProducts}
                                hover={true}
                                columns={loadingColumns}
                                //striped={true}
                            />
                        </div>
                    </div>
                </div>
                <div className='mt-4 d-flex justify-content-end'>
                    <div className="col-sm-12 col-md-3 mr-auto d-flex justify-content-end align-middle">
                        <strong className="mr-3">Logo:</strong> 
                        <div className="form-check mr-3">
                            <Form.Label className="form-check-label">
                                <Controller
                                    control={control}
                                    name="empresa"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            fullWidth
                                            variant="standard"
                                            margin="normal"
                                            type="radio"
                                            // defaultChecked={confereChecked(cell)}
                                            value="1"
                                            disabled={true}
                                            autoComplete='off'
                                        />
                                    )}
                                />
                                {/* <input autoComplete='off' type="radio" id="logoRfat1" name="logoRfat" value="1"  />  */}
                                A
                            </Form.Label>
                        </div> 
                        <div className="form-check mr-3">
                            <Form.Label className="form-check-label">
                                <Controller
                                    control={control}
                                    name="empresa"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            fullWidth
                                            variant="standard"
                                            margin="normal"
                                            type="radio"
                                            // defaultChecked={confereChecked(cell)}
                                            value="2"
                                            disabled={true}
                                            autoComplete='off'
                                        />
                                    )}
                                />
                                {/* <input autoComplete='off' type="radio" id="logoRfat2" name="logoRfat" value="2" className="form-check-input" />  */}
                                B
                            </Form.Label>
                        </div>
                    </div>
                    <div className='btn-expiration'>
                        <button
                            className="btn btn-default btn-outline-primary mx-2"
                            style={{width: 'auto'}}
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                    </div>
                    <button
                        className="btn btn-default btn-primary"
                        type="button"
                        style={{width: 'auto'}}
                        disabled={true}
                    >
                        Confirmar
                    </button>
                </div>
            </>
        )
    }
}

export default Invoice;