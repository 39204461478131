/* eslint-disable react/style-prop-object */
/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useRef, useEffect, useCallback } from 'react';
import api from '../../../../services/api';

//COMPONENTS
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Card, Col, Form, Row, OverlayTrigger, Tooltip, Button, Modal } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { GoogleMap, useJsApiLoader, Polygon, Autocomplete } from '@react-google-maps/api';
import { geocodeByAddress, getLatLng } from 'react-google-places-autocomplete';
import AutoComplete from "react-google-autocomplete";
import GetTranfersZones from '../../../../components/C2Points/GetTranfersZones';
import GetSupplierGroupId from '../../../../components/C2Points/GetSupplierGroupId';

export interface propEdit {
    isEdit?: any;
    infoEdit?: any;
    actionFunc: any;
}

const AddZoneComponent: React.FC<propEdit> = ({
    isEdit = false, infoEdit, actionFunc
}: propEdit) => {
    const [placeName, setPlaceName] = useState<any>(isEdit === true ? infoEdit.destino : '');
    const [lat, setLat] = useState<any>(-22.95195551029806);
    const [lng, setLng] = useState<any>(-43.18175312958387);
    const [polyLinePath, setPolyLinePath] = useState<any>(isEdit === true ? infoEdit.zone.map((elem: any) => ({ lat: Number(elem.lat), lng: Number(elem.lng) })) : []);
    const [mapref, setMapRef] = useState<any>(null);
    const [zoneName, setZoneName] = useState<any>(isEdit === true ? infoEdit.zona : '');

    const polygonRef: any = useRef(null);
    const listenersRef: any = useRef([]);

    function LocationLat(lat: any) {
        setLat(lat);
    }

    function LocationLng(lng: any) {
        setLng(lng);
    }

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {
        data.placeName = placeName;
        data.zones = polyLinePath;
        //data.zoneName = zoneName;
        data.supplier = parseInt(data.supplier.id);

        actionFunc(data);
    }

    useEffect(() => {
        if (isEdit === true) {
            let latMin: any = Math.min(...infoEdit.zone.map((elem: any) => (Number(elem.lat))));
            let latMax: any = Math.max(...infoEdit.zone.map((elem: any) => (Number(elem.lat))));
            let lngMin: any = Math.min(...infoEdit.zone.map((elem: any) => (Number(elem.lng))));
            let lngMax: any = Math.max(...infoEdit.zone.map((elem: any) => (Number(elem.lng))));

            setLat((latMax + latMin) / 2);
            setLng((lngMax + lngMin) / 2);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_API || '',
        libraries: ['places']
    })

    const center = { lat: lat, lng: lng }

    const paths = [
        { lat: 25.774, lng: -80.19 },
        { lat: 18.466, lng: -66.118 },
        { lat: 32.321, lng: -64.757 },
        { lat: 25.774, lng: -80.19 }
    ]

    const options = {
        fillColor: '#f00',
        fillOpacity: .2,
        strokeColor: "red",
        strokeOpacity: 1,
        strokeWeight: 2,
        clickable: true,
        draggable: true,
        editable: true/* editable */,
        geodesic: true,
        zIndex: 1
    }

    const handleClick: any = (data: any) => {
        let aux: any = [...polyLinePath];
        aux.push({
            lat: data.latLng.lat(),
            lng: data.latLng.lng()
        })
        setPolyLinePath(aux);

    }

    const onCenterChanged: any = () => {
        if (mapref) {
            const newCenter: any = mapref?.getCenter();
            setLat(newCenter.lat());
            setLng(newCenter.lng());
        }
    }

    const onEdit: any = useCallback(() => {
        if (polygonRef.current) {
            const nextPath = polygonRef.current
                .getPath()
                .getArray()
                .map((latLng: any) => {
                    return { lat: latLng.lat(), lng: latLng.lng() };
                });
            setPolyLinePath(nextPath);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setPolyLinePath]);

    const onLoad: any = useCallback(
        (polygon: any) => {
            polygonRef.current = polygon;
            const path = polygon.getPath();
            listenersRef.current.push(
                path?.addListener("set_at", onEdit),
                path?.addListener("insert_at", onEdit),
                path?.addListener("remove_at", onEdit)
            );
        },
        [onEdit]
    );

    const handleOnLoad: any = (map: any) => {
        setMapRef(map);
    };

    const handleChangeZone = (e: any) => {
        const newValue = e.target.value;
        setZoneName(newValue);
    }

    return (
        <>
            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">

                <Card className="px-3 mt-4">

                    <Row className="mb-3">
                        <Form.Group as={Col} md="4">
                            <Form.Label>Fornecedor:</Form.Label>
                            <Controller
                                control={control}
                                name="supplier"
                                //defaultValue={isEdit === true ? { id: infoEdit.groupId.toString(), description: infoEdit.groupId } : ''}
                                render={({ field }: any) => (
                                    <div className="input-select-custom">
                                        <GetSupplierGroupId
                                            propsField={field}
                                            propsLabel={"supplier"}
                                            propsErrors={errors}
                                            setValue={setValue}
                                            defaultId={isEdit === true ? infoEdit.groupid : ''}
                                        //setFilterSupplier={setFilterSupplier}
                                        />
                                    </div>
                                )}
                            />
                        </Form.Group>

                        {isLoaded
                            ?

                            <Form.Group as={Col} lg="4" md="4">
                                <Form.Label>Destino:
                                    <OverlayTrigger
                                        overlay={
                                            <Tooltip id="tooltip">
                                                Pesquise e selecione usando os resultados do endereço do Google.
                                            </Tooltip>
                                        }>
                                        <span>
                                            <FontAwesomeIcon
                                                className="ml-2"
                                                icon={["fal", "question-circle"]}
                                            />
                                        </span>
                                    </OverlayTrigger>
                                </Form.Label>
                                <Controller
                                    control={control}
                                    name={`destination`}
                                    render={({ field }: any) => (
                                        <AutoComplete
                                            options={{
                                                types: ["(regions)"],
                                                fields: ["name"]
                                            }}
                                            defaultValue={isEdit === true ? infoEdit.destino : ''}
                                            placeholder=""
                                            apiKey={process.env.REACT_APP_GOOGLE_API}
                                            onPlaceSelected={(place) => {
                                                setPlaceName(place.name);
                                                // eslint-disable-next-line no-lone-blocks
                                                {
                                                    geocodeByAddress(`${place.name}`)
                                                        .then(results => getLatLng(results[0]))
                                                        .then(({ lat, lng }) => {
                                                            LocationLat(lat);
                                                            LocationLng(lng);
                                                        }
                                                        );
                                                }
                                            }}
                                        />
                                    )}
                                />
                            </Form.Group>
                            : <></>
                        }

                        <Form.Group as={Col} md="3">
                            <Form.Label>Zona:</Form.Label>
                            <Controller
                                control={control}
                                name="zoneName"
                                defaultValue={isEdit === true ? infoEdit.zona : ''}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        type="text"
                                        aria-invalid={errors?.zoneName ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        required
                                        autoComplete='off'
                                    />
                                )}
                                    />
                                    {/* <GetTranfersZones propsField={field} propsLabel={'Zona'} propsErrors={errors} handleChangeZone={handleChangeZone} isEdit={isEdit} infoEdit={infoEdit} /> */}
                        </Form.Group>

                    </Row>

                </Card>

                {isLoaded
                    ?
                    <>
                        <Card className="px-3 mt-4 google-map">
                            <GoogleMap
                                mapContainerStyle={{ width: '100%', height: '450px' }}
                                center={center}
                                zoom={12}
                                onClick={handleClick}
                                onCenterChanged={onCenterChanged}
                                onLoad={handleOnLoad}
                            >
                                <Polygon
                                    onLoad={onLoad}
                                    paths={polyLinePath}
                                    options={options}
                                    onMouseUp={onEdit}
                                />
                            </GoogleMap>
                        </Card>
                    </>
                    :
                    <>
                    </>
                }

                <Row>
                    <Form.Group
                        as={Col}
                        md="12"
                        lg="12"
                        className="d-flex justify-content-md-end mt-5 mb-3"
                    >
                        <Button
                            variant="outline-primary"
                            className="btn-default mr-2 text-uppercase"
                            type='button'
                            onClick={() => { setPolyLinePath([]) }}
                        >
                            Resetar Mapa
                        </Button>
                        <Button
                            type="submit"
                            className="btn-default text-uppercase">
                            Cadastrar
                        </Button>
                    </Form.Group>
                </Row>
            </Form>
        </>

    );
}

export default AddZoneComponent;