//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Accordion, Card, Col, Container, Form, Modal, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";
import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersFilter';
import GetResourceVehicleTypeFilter from "../../../../components/C2Points/GetResourceVehicleTypeFilter";
import GetResourcesTypeIsActiveFilter from "../../../../components/C2Points/GetResourcesTypeIsActiveFilter";
import GetSupplierUsers from "../../../../components/C2Points/GetSupplierUsers";
import GetSupplierLanguagesMulti from "../../../../components/C2Points/GetSupplierLanguagesMulti";
import ResourceAddScore from "../ResourceAddScore/ResourceAddScore";
import ResourceScoreListTable from "../ResourceScoreListTable/ResourceScoreListTable";
import api from "../../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import ModalConfirm from "../../../../components/Modal/ModalConfirm/ModalConfirm";

export interface propEdit {
  watch: any;
  errors: any;
  control: any;
  isEdit?: any;
  infoEdit?: any;
  setValue?:any
}

const ResourceCard: React.FC<propEdit> = ({
  watch,
  errors,
  control,
  isEdit = false,
  infoEdit,
  setValue
}) => {
  const [, setFilterVehicleType] = useState<any>(0);
  const [, setFilterType] = useState<any>(0);
  /* const [responseText, setResponseText] = useState<any>("Preencha os campos!");
  const [modalResponse, setModalResponse] = useState<any>();
  const [log, setLog] = useState<any>(); */

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */
    
  const resourceTypeAtual = isEdit === true ? {
    value: infoEdit.resourceType.toString(),
    label: infoEdit.resourceTypeDesc,
    vehicle: infoEdit.vehicle,
    people: infoEdit.people,
    guide: infoEdit.guide,
    ticket: infoEdit.ticket,
    restaurant: infoEdit.restaurant
  } : null;

  const supplierAtual = isEdit === true ? {
    supplierId: infoEdit.supplierId,
    compName: infoEdit.supplierDesc
  } : null;

  var watchIsVehicle = isEdit === true ? resourceTypeAtual : watch(`resourceType`, false);
  var watchSupplier = isEdit === true ? supplierAtual : watch(`supplier`, false);

  const paymentProcess = isEdit === true && (infoEdit.ticket === 1 || infoEdit.restaurant === 1) ? 
                              (infoEdit.ticket === 1 && infoEdit.costInformation === 1) ? '2' 
                            : infoEdit.virtualVoucher === 1 ? '3' 
                            : infoEdit.automaticIntegration === 1 ? '4' 
                            : '1'
                          : '1';
       
  //const [languagesSupported, setLanguagesSupported] = useState<any>(isEdit === true ? infoEdit.languagesSupported : []);

  const [totalScore, setTotalScore] = useState<any>(isEdit === true ? infoEdit.score : 0);
  const [scoreList, setScoreList] = useState<any>(isEdit === true ? infoEdit.scoreList : []);
  const [loading, setLoading] = useState<boolean>(false);

  const watchLang = watch('languagesSupported')

  async function updateScoreList() {
    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function getResource() {
      setLoading(true);

      try {
        const { data } = await api.get(
          `Resources/GetSupplierResourceScoreList?resourceId=${infoEdit.resourceId}`, config
        );

        if (data.status !== 400) {
          const dados = data.data;

          setScoreList(dados);

          var newScore = 0;
          for(let i = 0; i < dados.length; i++) {
            newScore += dados[i].points;
          }
          setTotalScore(newScore);
          
        }
      } catch (error: any) {}

      setLoading(false);
    }

    getResource();
  }
  
  return (
    <>
      <Card className="bg-white mt-4">
        <Accordion>
          <Card.Body>
            <Container className="p-0 mt-3" fluid>
              <>
                <Row>
                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Tipo de Resource:</Form.Label>
                    <Controller
                      control={control}
                      name="resourceType"
                      defaultValue={
                        isEdit === true
                          ? resourceTypeAtual
                          : ""
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o nome",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          {isEdit === true ?
                            <span>{field.value ? field.value.label : ''}</span>
                          :
                          <GetResourcesTypeIsActiveFilter
                            propsField={field}
                            propsLabel={"tipo"}
                            propsErrors={errors}
                            setFilterType={setFilterType}
                          />
                          }
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="resourceType"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Fornecedor:</Form.Label>
                    <Controller
                      control={control}
                      name="supplier"
                      defaultValue={
                        isEdit === true
                          ? {
                              supplierId: infoEdit.supplierId,
                              compName: infoEdit.supplierDesc,
                            }
                          : ""
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o fornecedor",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          {/* <GetSupplierGroupId
                            propsField={field}
                            propsLabel={"supplier"}
                            propsErrors={errors}
                            //setFilterSupplier={setFilterSupplier}
                          /> */}
                          <GetSuppliersFilter
                              propsField={field}
                              propsErrors={errors}
                              propsLabel="Fornecedor"
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="supplier"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={"mb-3 " +
                      (watchIsVehicle?.people === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Usuario:</Form.Label>
                    <Controller
                      control={control}
                      name="employeeUser"
                      defaultValue={
                        isEdit === true
                          ? {
                              uid: infoEdit.employeeUserUid,
                              nome: infoEdit.employeeUserName,
                            }
                          : ""
                      }
                      // rules={{
                      //   required: {
                      //     value: true,
                      //     message: "Por favor, informe o usuario",
                      //   },
                      // }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          <GetSupplierUsers
                              propsField={field}
                              // propsErrors={errors}
                              propsLabel="Usuario"
                              supplierId={watchSupplier?.supplierId}
                          />
                        </div>
                      )}
                    />
                    {/* <ErrorMessage
                      errors={errors}
                      name="employeeUser"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    /> */}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={"mb-3 " +
                      (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Tipo de Veículo:</Form.Label>
                    <Controller
                      control={control}
                      name="vehicle"
                      defaultValue={
                        isEdit === true
                          ? {
                              value: infoEdit.vehicleType,
                              label: infoEdit.vehicleTypeDesc,
                            }
                          : ""
                      }
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message: "Por favor, informe o tipo de veículo",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="input-select-custom">
                          <GetResourceVehicleTypeFilter
                            propsField={field}
                            propsLabel={"tipo"}
                            propsErrors={errors}
                            setFilterVehicleType={setFilterVehicleType}
                          />
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="vehicle"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Assentos:</Form.Label>
                    <Controller
                      control={control}
                      name="seats"
                      defaultValue={isEdit === true ? infoEdit.seats : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message:
                            "Por favor, informe a quantidade de assentos",
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Por favor, insira somente números",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[0-9]*$/.test(value)) {
                              field.onChange(e);
                            }
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="seats"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Min. Operação:</Form.Label>
                    <Controller
                      control={control}
                      name="minOperation"                      
                      defaultValue={isEdit === true ? infoEdit?.minOperation : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message:
                            "Por favor, informe a quantidade de mínima para operação",
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Por favor, insira somente números",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          type='number'
                          min={1}
                          defaultValue={1}
                          required
                          autoComplete='off'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[0-9]*$/.test(value)) {
                              field.onChange(e);
                            }
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="minOperation"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                   as={Col}
                   md="4"
                   controlId=""
                   className={
                     "mb-3 " +
                     (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                   }>
                    <Form.Label>Max. Ocupação:</Form.Label>
                    <Controller
                      control={control}
                      name="maxOcupation"                      
                      defaultValue={isEdit === true ? infoEdit?.maxOcupation : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message:
                            "Por favor, informe a quantidade de máxima para ocupação",
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: "Por favor, insira somente números",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          type='number'
                          min={1}
                          defaultValue={1}
                          required
                          autoComplete='off'
                          onChange={(e) => {
                            const value = e.target.value;
                            if (/^[0-9]*$/.test(value)) {
                              field.onChange(e);
                            }
                          }}
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="maxOcupation"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  {/* <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.ticket === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Guia Informa Custo?:</Form.Label>
                    <Controller
                      control={control}
                      name="costInformation"
                      defaultValue={isEdit === true ? infoEdit.costInformation : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.ticket === 1 ? true : false,
                          message:
                            "Por favor, informe se o recurso é pra ter um preco final informado pelo guia",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="endpoint">
                          <Form.Select
                            {...field}
                            variant="standard"
                            margin="normal"
                            required
                          >
                            <option value="">Selecione</option>
                            <option
                              value="1"
                              selected={
                                isEdit === true && infoEdit.costInformation === 1
                              }
                            >
                              Sim
                            </option>
                            <option
                              value="2"
                              selected={
                                isEdit === true && infoEdit.costInformation === 2
                              }
                            >
                              Não
                            </option>
                          </Form.Select>
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="costInformation"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group> */}
                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.ticket === 1 || watchIsVehicle?.restaurant === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Processo de Pagamento:</Form.Label>
                    <Controller
                      control={control}
                      name="paymentProcess"
                      defaultValue={isEdit === true ? paymentProcess : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.ticket === 1 || watchIsVehicle?.restaurant === 1 ? true : false,
                          message:
                            "Por favor, informe se o processo de pagamento do recurso",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="endpoint">
                          <Form.Select
                            {...field}
                            variant="standard"
                            margin="normal"
                            required
                          >
                            <option value="1">Padrão</option>
                            {watchIsVehicle?.ticket === 1 ?
                            <option value="2">Informado pelo Guia</option>
                            :<></>}
                            <option value="3">Voucher Virtual</option>
                            {/* <option value="4">Integração Automatica</option> */}
                          </Form.Select>
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="paymentProcess"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>


                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={
                      "mb-3 " +
                      (watchIsVehicle?.vehicle === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Informação do Veiculo:</Form.Label>
                    <Controller
                      control={control}
                      name="vehicleIdentification"
                      defaultValue={isEdit === true ? infoEdit.vehicleIdentification : ""}
                      rules={{
                        required: {
                          value: watchIsVehicle?.vehicle === 1 ? true : false,
                          message:
                            "Por favor, informe a identificação do veículo",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="vehicleIdentification"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Nome do Resource:</Form.Label>
                    <Controller
                      control={control}
                      name="resourceName"
                      defaultValue={
                        isEdit === true ? infoEdit.resourceName : ""
                      }
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o nome do resource",
                        },
                      }}
                      render={({ field }: any) => (
                        <Form.Control
                          {...field}
                          variant="standard"
                          margin="normal"
                          required
                          autoComplete='off'
                        />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="resourceName"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group as={Col} md="4" controlId="" className="mb-3">
                    <Form.Label>Tipo de Pagamento:</Form.Label>
                    <Controller
                      control={control}
                      name="payment"
                      rules={{
                        required: {
                          value: true,
                          message: "Por favor, informe o tipo de pagamento",
                        },
                      }}
                      render={({ field }: any) => (
                        <div className="endpoint">
                          <Form.Select
                            {...field}
                            variant="standard"
                            margin="normal"
                            required
                          >
                            <option value="">Selecione</option>
                            <option
                              value="1"
                              selected={
                                isEdit === true && infoEdit.paymentType === 1
                              }
                            >
                              Por Pessoa
                            </option>
                            <option
                              value="2"
                              selected={
                                isEdit === true && infoEdit.paymentType === 2
                              }
                            >
                              Por Grupo
                            </option>
                          </Form.Select>
                        </div>
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="payment"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    md="4"
                    controlId=""
                    className={"mb-3 " +
                      (watchIsVehicle?.guide === 1 ? "d-block" : "d-none")
                    }
                  >                    
                    <Form.Label>Idioma:</Form.Label>
                    <Controller
                      control={control}
                      name="languagesSupported"
                      defaultValue={isEdit === true ? infoEdit.languagesSupported : []}
                      rules={{
                        required: {
                          value: watchIsVehicle?.guide === 1 ? true : false,
                          message:
                            "Por favor, informe os idiomas", 
                        },
                      }}
                      render={({ field }: any) => (
                        <GetSupplierLanguagesMulti propsField={field} propsLabel={"Idioma"} propsErrors={errors} setValue={setValue} editLanguages={infoEdit?.languagesSupported} />
                      )}
                    />
                    <ErrorMessage
                      errors={errors}
                      name="languagesSupported"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    />

                  </Form.Group>

                  
                </Row>
                <Row>
                  <Form.Group
                    as={Col}
                    md="6"
                    controlId=""
                    className={"mb-3 " +
                      (watchIsVehicle?.guide === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Categoria de atendimento:</Form.Label>
                    <Row>
                      <Controller
                        control={control}
                        name="attCategDriverGuide"
                        defaultValue={isEdit === true ? infoEdit.attCategDriverGuide : false}
                        //rules={{required: {value: watchIsVehicle?.guide === 1 ? true : false, message: "Por favor, informe os idiomas",},}}
                        render={({ field }: any) => (
                          <div className="col-md-3 d-flex">
                            <Form.Label className="form-check-label d-flex m-0 align-items-center">
                              
                              <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                defaultChecked={isEdit === true ? infoEdit.attCategDriverGuide === 1 : false}
                                //value={item.id}
                                //onClick={attCategDriverGuide}
                              />
                              <span className="ml-2">Driver Guide</span>
                            </Form.Label>
                          </div>
                        )}
                      />
                      <Controller
                        control={control}
                        name="attCategSightseeing"
                        defaultValue={isEdit === true ? infoEdit.attCategSightseeing : false}
                        //rules={{required: {value: watchIsVehicle?.guide === 1 ? true : false, message: "Por favor, informe os idiomas",},}}
                        render={({ field }: any) => (
                          <div className="col-md-3 d-flex">
                            <Form.Label className="form-check-label d-flex m-0 align-items-center">                              
                              <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                defaultChecked={isEdit === true ? infoEdit.attCategSightseeing === 1 : false}
                                //value={item.id}
                                //onClick={attCategSightseeing}
                              />
                              <span className="ml-2">Sightseeing</span>
                            </Form.Label>
                          </div>
                        )}
                      />
                      <Controller
                        control={control}
                        name="attCategPickupBarra"
                        defaultValue={isEdit === true ? infoEdit.attCategPickupBarra : false}
                        //rules={{required: {value: watchIsVehicle?.guide === 1 ? true : false, message: "Por favor, informe os idiomas",},}}
                        render={({ field }: any) => (
                          <div className="col-md-3 d-flex">
                            <Form.Label className="form-check-label d-flex m-0 align-items-center">
                              
                              <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                defaultChecked={isEdit === true ? infoEdit.attCategPickupBarra === 1 : false}
                                //value={item.id}
                                //onClick={attCategPickupBarra}
                              />
                              <span className="ml-2">Pickup Barra</span>
                            </Form.Label>
                          </div>
                        )}
                      />
                      <Controller
                        control={control}
                        name="attCategPraias"
                        defaultValue={isEdit === true ? infoEdit.attCategPraias : false}
                        //rules={{required: {value: watchIsVehicle?.guide === 1 ? true : false, message: "Por favor, informe os idiomas",},}}
                        render={({ field }: any) => (
                          <div className="col-md-3 d-flex">
                            <Form.Label className="form-check-label d-flex m-0 align-items-center">
                              
                              <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                defaultChecked={isEdit === true ? infoEdit.attCategPraias === 1 : false}
                                //value={item.id}
                                //onClick={attCategPraias}
                              />
                              <span className="ml-2">Praias</span>
                            </Form.Label>
                          </div>
                        )}
                      />
                    </Row>

                    {/* <ErrorMessage
                      errors={errors}
                      name="attCategDriverGuide"
                      render={({ message }) => (
                        <small style={{ color: "red" }}>{message}</small>
                      )}
                    /> */}
                  </Form.Group>
                  <Form.Group 
                    as={Col}
                    md="6"
                    controlId=""
                    className={"mb-3 " +
                      (isEdit === true && watchIsVehicle?.guide === 1 ? "d-block" : "d-none")
                    }
                  >
                    <Form.Label>Score:</Form.Label>
                    <p>{totalScore}</p>
                  </Form.Group>
                </Row>
                
                {isEdit === true && watchIsVehicle?.guide === 1 ? 
                <>
                  <Row>
                    <hr className="my-5" />
                    <ResourceAddScore resourceId={infoEdit?.resourceId} updateScoreList={updateScoreList} setModalSuccess={setModalSuccess} setModalContent={setModalContent} setResponseText={setResponseText} setLog={setLog} />
                  </Row>

                  <Row>
                    {/* Lista com os Erros e Acertos */}
                    <ResourceScoreListTable resourceId={infoEdit?.resourceId} info={scoreList} loading={loading} updateScoreList={updateScoreList} />
                    <hr className="my-5" />
                  </Row>
                </>
                :
                <></>}
              </>
              {/*  */}
              <div
                className="d-flex justify-content-end"
                style={{ width: "unset" }}
              >
                <button
                  className="btn btn-default btn-primary px-4 text-uppercase"
                  type="submit"
                >
                  Cadastrar
                </button>
              </div>
              <Row className="mt-5">
                {/* <Form.Group as={Col} md="12" controlId="">
                  <ModalConfirm
                    confirm={responseText}
                    modalContent={modalResponse}
                    log={log}
                  />
                </Form.Group> */}
              </Row>
            </Container>
          </Card.Body>
        </Accordion>
      </Card>

      {/* Modal de Success */}
      <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em" }}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}
    </>
  );
};

export default ResourceCard;
