/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

//STYLES
import "./GroupFilePassengers.scss";
import { Form } from "react-bootstrap";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
//import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
//import FormAddFeeTerm from "../../../../../components/Forms/FormAddFeeTerm/FormAddFeeTerm";


export interface propModal {
  action: any
  info: any
  //blockIndex: any 
};

const GroupFilePassengers: React.FC<propModal> = ({
  action, info //, blockIndex
}: propModal) => {
  const { t } = useTranslation();

  /* function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
} */

  // const suppliers = objectSuppliers.suppliers.default[0];

  // const [controlObject, setControlObject] = useState<any>(info ? info : [{
  //   id:0,name:'',passportId:'',email:'',phone:''
  // }]);
  const [controlObject, setControlObject] = useState<any>(info ? info.map((obj: any) => obj.id) : [-1]);

  const [arrayName, setArrayName] = useState<any>(info ? info.map((obj: any) => obj.name) : ['']);
  const [arrayPassportId, setArrayPassportId] = useState<any>(info ? info.map((obj: any) => obj.passportId) : ['']);
  const [arrayEmail, setArrayEmail] = useState<any>(info ? info.map((obj: any) => obj.email) : ['']);
  const [arrayPhone, setArrayPhone] = useState<any>(info ? info.map((obj: any) => obj.phone) : ['']);

  const [deletedObjects, setDeletedObjects] = useState<any>([]);

  const handleActionModal = (objectModal: any) => {
    setControlObject([...controlObject, objectModal],);
  }

  // function removeRow(index: any) {

  //   const aux: any = [];
  //   const newDeleted : any = deletedObjects;

  //   for (var i: any = 0; i < controlObject.length; i++) {
  //     if (i !== index) {
  //       aux.push(controlObject[i]);
  //     } else {
  //       if (controlObject[i].id > 0) {
  //         newDeleted.push(controlObject[i].id);
  //         //[...deletedObjects, controlObject[i].id]
  //         setDeletedObjects(newDeleted);
  //       }
  //     }
  //   }

  //   setControlObject(aux);
  //   action(aux, deletedObjects);
  // }

  // function addRow() {
  //   var aux: any = {id:0,name:'',passportId:'',email:'',phone:''};
  //   controlObject.push(aux);
  //   action(controlObject, deletedObjects);
  // }

  function removeRow(index: any) {

    //console.log(controlObject)

    var aux: any = [];
    var auxName: any = [];
    var auxPassportId: any = [];
    var auxEmail: any = [];
    var auxPhone: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
        auxName.push(arrayName[i]);
        auxPassportId.push(arrayPassportId[i]);
        auxEmail.push(arrayEmail[i]);
        auxPhone.push(arrayPhone[i]);
      } else {
        if (controlObject[i] > 0) setDeletedObjects([...deletedObjects, controlObject[i]]);
      }
    }

    setArrayName(auxName);
    setArrayPassportId(auxPassportId);
    setArrayEmail(auxEmail);
    setArrayPhone(auxPhone);
    setControlObject(aux);
  }

  const addRow = () => {
      setArrayName([...arrayName, '']);
      setArrayPassportId([...arrayPassportId, '']);
      setArrayEmail([...arrayEmail, '']);
      setArrayPhone([...arrayPhone, '']);
      setControlObject([...controlObject, -1]);
  };

  function refreshBlocks() {
    const blocks: any = [];

    for (let i: any = 0; i < controlObject.length; i++) {
      const block = {
        id: controlObject[i],
        name: arrayName[i],
        passportId: arrayPassportId[i],
        email: arrayEmail[i],
        phone: arrayPhone[i],
      };
      blocks.push(block);
    }

    action(blocks, deletedObjects);
  }

  return (
    <>
      <div className="table-add-default groupfile-passenger-table">
        <div className="table-container">
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <Table striped bordered>
            <thead>
              <tr>
                <th>{t("groupsAndCustomized.passengers.name")}</th>
                <th>{t("groupsAndCustomized.passengers.passportId")}</th>
                <th>{t("groupsAndCustomized.passengers.email")}</th>
                <th>{t("groupsAndCustomized.passengers.phone")}</th>
                <th>{t("groupsAndCustomized.passengers.actions")}</th>
              </tr>
            </thead>
            <tbody>
              {controlObject.length > 0
                ? controlObject.map((item: any, index: Key) => (
                  <tr key={index}>
                    
                    <td>
                      {/* Nome */}
                      {item > 0 ? arrayName[index] :
                      <Form.Control type="text"
                        autoComplete='off'
                        required
                        defaultValue={arrayName[index]}
                        onChange={(e: any) => { arrayName[index] = e.target.value; refreshBlocks(); }}
                        value={arrayName[index]}
                      />
                      }
                    </td>
                    <td>
                      {/* Passaporte/ID */}
                      {item > 0 ? arrayPassportId[index] :
                      <Form.Control type="text"
                        autoComplete='off'
                        required
                        defaultValue={arrayPassportId[index]}
                        onChange={(e: any) => { arrayPassportId[index] = e.target.value; refreshBlocks(); }}
                        value={arrayPassportId[index]}
                      />
                      }
                    </td>
                    <td>
                      {/* E-Mail */}
                      {item > 0 ? arrayEmail[index] :
                      <Form.Control type="text"
                        autoComplete='off'
                        required
                        defaultValue={arrayEmail[index]}
                        onChange={(e: any) => { arrayEmail[index] = e.target.value; refreshBlocks(); }}
                        value={arrayEmail[index]}
                      />
                      }
                    </td>
                    <td>
                      {/* Telefone */}
                      {item > 0 ? arrayPhone[index] :
                      <Form.Control type="text"
                        autoComplete='off'
                        required
                        defaultValue={arrayPhone[index]}
                        onChange={(e: any) => { arrayPhone[index] = e.target.value; refreshBlocks(); }}
                        value={arrayPhone[index]}
                      />
                      }
                    </td>
                    <td>{
                      <div className="center-table options-table">
                        <div className="px-2">
                          <div className="d-flex justify-content-end">
                            <span className='btn' style={{ height: "40px" }} onClick={(e: any) => removeRow(index)}>
                              <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "trash-alt"]}
                                style={{ color: "#707070" }}
                              />
                            </span>
                          </div>
                        </div>
                        {/* <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={['fal', 'ellipsis-h']}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => editRow(index)}
                              eventKey="1"
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'edit']}
                              //className="mr-2"
                              />
                              {t('suppliersTable.edit')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => removeRow(index)}
                              eventKey="2"
                              className="text-danger removeRow">
                              <FontAwesomeIcon
                                icon={['fal', 'trash-alt']}
                                //className="mr-2"
                              />
                              {t('suppliersTable.remove')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown> */}
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              <div className="add-contact">
                <div className="text-center btn-add-contact">
                  <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={["fal", "plus"]}
                      style={{ color: "#707070", margin: "0 12px" }}
                    />
                  </span>
                  {/* <ModalDefault
                    title="Taxa e Prazo"
                    classModal="modal-pos-tax"
                    name={'+'}
                    class="btn-add"
                  >
                    <div className="form-add-contact">
                      <FormAddFeeTerm action={handleActionModal} info={null} />
                    </div>
                  </ModalDefault> */}
                </div>
              </div>
            </tbody>
          </Table>

          {/* {
            feeTermEdit !== null
              ?
              <Modal
                className="modal-custom modalAuth modal-pos-tax"
                show={feeTermModalShow}
                onHide={() => setFeeTermModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton>
                    <h3>Taxa e Prazo</h3>
                  </Modal.Header>
                  <hr style={{ margin: "30px 0" }} />
                  <FormAddFeeTerm action={handleActionEditModal} info={feeTermEdit} />
                </>
              </Modal>
              :
              <></>
          } */}

        </div>
      </div>
    </>
  );
}

export default GroupFilePassengers;
