import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Modal } from "react-bootstrap";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

import api from "../../../../../../../../services/api";

//COMPONENTS

import './SessionPassengersTransfers.scss';


export interface propPassengers {
    items: any,
};

const SessionPassengersTransfers: React.FC<propPassengers> = ({
    items
}: propPassengers) => {
    /* Success Modal (Enviar Email e Whatsapp) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    //const [modalShow, setModalShow] = useState<any>(false);
    /* END - Success Modal (Enviar Email e Whatsapp) */

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    function SendEmail(item: any) {
        setModalContent("loading");
        const email = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendEmailToClient`,
                    {
                        id: item,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    alert("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    alert("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        email();
    }

    function SendWhatsapp(item: any) {
        setModalContent("loading");
        const whatsapp = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendWhatsAppToClient`,
                    {
                        id: item,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    alert("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    alert("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        whatsapp();
    }

    function handleReload() {
        window.location.reload();
    }

    return (
        <>
            <div className="passengers-transfers">
                <div>
                    <p>ID/OS</p>
                    <b>{items.newItemsId}</b>
                </div>
                <div>
                    <p>Código</p>
                    <b>{items.localizador}</b>
                </div>
                <div>
                    <p>Cliente</p>
                    <b>{items.agente}</b>
                </div>
                <div>
                    <p>Tipo</p>
                    <b>{items.tipo}</b>
                </div>
                <div>
                    <p>Pax</p>
                    <b>{items.cliente}</b>
                </div>
                <div className='px-4'>
                    <p>Qtde.Pax</p>
                    <b>{items.qtdePax}</b>
                </div>
                <div>
                    <p>Malas</p>
                    <b>{items.qtdeMalas}</b>
                </div>
                <div className='icons-transfer'>
                    <div className="mr-2">
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip">Enviar Email</Tooltip>
                            }>
                            <div style={{ height: "18px" }} onClick={() => {
                                SendEmail(items.newItemsId)
                                setModalSuccess(true)
                            }}>
                                <FontAwesomeIcon
                                    icon={['fas', 'envelope']}
                                    style={{ fontSize: "20px" }}
                                />
                            </div>
                        </OverlayTrigger>
                    </div>
                    <div>
                        <OverlayTrigger
                            overlay={
                                <Tooltip id="tooltip">Enviar Whatsapp</Tooltip>
                            }>
                            <div style={{ height: "18px" }} onClick={() => {
                                SendWhatsapp(items.newItemsId)
                                setModalSuccess(true)
                            }}>
                                <FontAwesomeIcon
                                    icon={['fab', 'whatsapp']}
                                    style={{ fontSize: "20px" }}
                                />
                            </div>
                        </OverlayTrigger>
                    </div>
                </div>
            </div>
            {/* Modal de Success */}
            <Modal
                className={"modal-confirm loading-modal"}
                show={modalSuccess}
                onHide={() => setModalSuccess(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalContent !== "loading"
                                ?
                                <>
                                    <div>
                                        {
                                            log === 1 || log === "1"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em"}}
                                                />
                                                :
                                                log === 0 || log === "0"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em"}}
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "question-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em"}}
                                                    />
                                        }
                                    </div>
                                    <div>
                                        {responseText}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        {
                                            modalContent === 'success'
                                                ?
                                                <button
                                                    onClick={handleReload}
                                                    className="btn btn-primary mx-2 w-25"
                                                >
                                                    Confirmar
                                                </button>
                                                :
                                                <button
                                                    onClick={() => {} /* setModalShow(false) */}
                                                    className="btn btn-primary mx-2 w-25"
                                                >
                                                    Confirmar
                                                </button>
                                        }
                                    </div>
                                </>
                                :
                                <div className="modal-body">
                                    <div className="text-center">
                                        <div className="load"></div>
                                    </div>
                                    <div>
                                        <span>Processando</span>
                                    </div>
                                    <div></div>
                                </div>
                        }
                    </Modal.Body>
                </>
            </Modal>
            {/* END - Modal de Success */}
        </>
    );

}

export default SessionPassengersTransfers;