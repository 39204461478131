/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import api from "../../../../services/api";

import { Row, Col, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import Form from "react-bootstrap/Form";
import SingleCalendar from '../../../../components/Calendar/SingleCalendar';

import GetAccounts from '../../../../components/C2Points/GetAccounts';
import GetAccountsByTypeFilter from '../../../../components/C2Points/GetAccountsByTypeFilter';

export interface propInsert {
    show?: any;
    setResponseText?: any,
    setModalContent?: any,
    setLog?: any,
    fixAccountId?: any,
    fixAccountName?: any
}

const InsertAccountTransfer: React.FC<propInsert> = ({
    show, setResponseText, setModalContent, setLog, fixAccountId, fixAccountName
}: propInsert) => {

    const [date, setDate] = useState<any>('');
    const [vencimento, setVencimento] = useState<any>('');
    const [loading, setLoading] = useState<any>(false);
    const [erroData, setErroData] = useState<any>(false);

    const [accountOrigin, setAccountOrigin] = useState<any>(fixAccountId ? fixAccountId : '');
    const [accountDestiny, setAccountDestiny] = useState<any>();

    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
        
      }

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-');
        var dueArray: any = due.split('-');

        if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
            return false;
        }
        if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
            return false;
        }
        if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
            return false;
        }
        return true;
    }

    const onSubmit = (data: any) => {
        setLoading(true)
        
        //

        if(date === '') {
            setErroData(true);
        } else {
            setErroData(false);
        }

        if(date === '') {
            return;
        }

        data.contaOrigem = accountOrigin;
        data.contaDestino = accountDestiny;
        data.descricao = data.descricao ? data.descricao : '';
        data.data = (typeof(date) === 'string') ? fixDate(date) : fixDate(date[0]);
        
        data.valor = parseFloat( data.valor ?  data.valor.replace(',','.') : 0 );

        setModalContent("loading");
        //if (verifyDate(data.data, data.vencimento)) {
            const insertConta = async () => {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };
                try {
                    const res = await api.post(`/Extract/InsertAccountTransfer`,
                        {
                            "descricao": data.descricao,
                            "data": data.data,
                            "valor": data.valor,
                            
                            "contaOrigem": data.contaOrigem,
                            "contaDestino": data.contaDestino,
                            
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`
                        }, config);
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                        console.log(res)
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                    setLoading(false);
                    closeModal();
                    show(true);
                } catch (error: any) {
                    setLoading(false);
                    setModalContent("error");
                    
                }
            };

            insertConta();
        // } else {
        //     setModalContent("error");
        //     setResponseText("Data de vencimento antes da data atual.");
        //     setLoading(false);
        // }
            
    }

    return (
        <>
            <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                <div className='d-flex flex-column justify-content-between h-100'>
                    <div>
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6">
                                <Form.Label>Conta de Origem</Form.Label>
                                {!fixAccountId ?
                                <Controller
                                    control={control}
                                    name="accountOrigin"
                                    rules={{ required: { value: accountOrigin ? false : true, message: "required" }, }}
                                    //defaultValue={categoria?.id}
                                    defaultValue={accountOrigin}
                                    render={({ field }: any) => (
                                        <GetAccounts propsField={field} propsErrors={errors} propsLabel="Conta de Origem" setAccount={setAccountOrigin} />
                                    )}
                                />
                                :<input
                                    type="text"
                                    className="form-control"
                                    disabled={true}
                                    value={fixAccountName}
                                    autoComplete='off'
                                />}
                                <ErrorMessage 
                                    errors={errors}
                                    name="accountOrigin"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6">
                                <Form.Label>Conta de Destino</Form.Label>
                                {!fixAccountId ?
                                <Controller
                                    control={control}
                                    name="accountOrigin"
                                    rules={{ required: { value: accountOrigin ? false : true, message: "required" }, }}
                                    //defaultValue={categoria?.id}
                                    defaultValue={accountOrigin}
                                    render={({ field }: any) => (
                                        <GetAccounts propsField={field} propsErrors={errors} propsLabel="Conta de Destino" setAccount={setAccountDestiny} />
                                    )}
                                />
                                :
                                <Controller
                                    control={control}
                                    name="accountDestiny"
                                    rules={{ required: { value: accountDestiny ? false : true, message: "required" }, }}
                                    //defaultValue={categoria?.id}
                                    defaultValue={accountDestiny}
                                    render={({ field }: any) => (
                                        <GetAccountsByTypeFilter propsField={field} propsErrors={errors} propsLabel="Conta de Destino" setAccount={setAccountDestiny} contaAdquirente={1} tipoConta={1} />
                                    )}
                                />}
                                <ErrorMessage 
                                    errors={errors}
                                    name="accountDestiny"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                        <Row className="mb-3">
                            <Form.Group as={Col} controlId="formGridDesc">
                                <Form.Label>Descrição</Form.Label>
                                <Controller
                                    control={control}
                                    name="descricao"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.descricao ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="descricao"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>
                            
                        <Row>
                            
                            <Form.Group as={Col} className="mb-3" md="3" controlId="formGridDate">
                                <Form.Label>Data da Transferência</Form.Label>
                                <InputGroup
                                    hasValidation
                                    className={` ${erroData === true ? "endpoint-error" : ""}`} 
                                    size="sm"
                                    placeholder="Quando?"
                                >
                                    <SingleCalendar setValue={setDate} />
                                </InputGroup>
                                {erroData === true ?
                                    <small style={{color: 'red'}}>required</small>
                                :<></>}
                            </Form.Group>
                            
                            <Form.Group as={Col} md={2} controlId="formGridCat">
                                <Form.Label>Valor</Form.Label>
                                {/* <Form.Control type="text" /> */}
                                <Controller
                                    control={control}
                                    name="valor"
                                    rules={{ required: { value: true, message: "required" }, }}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            aria-invalid={errors?.valor ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                            // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="valor"
                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                />
                            </Form.Group>
                        </Row>

                    </div>
                    <div className='mt-4 d-flex justify-content-end'>
                        <button
                            className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                            onClick={closeModal}
                        >
                            Cancelar
                        </button>
                        <button
                            className="btn btn-default btn-primary px-4 text-uppercase"
                            type="submit"
/*                             onClick={() => {
                                setLoading(true)
                            }} */
                        >
                            {
                                loading === true
                                    ?
                                    <div className="load"></div>
                                    :
                                    "Cadastrar"
                            }
                        </button>
                    </div>
                </div>
            </Form>
        </>
    )
}

export default InsertAccountTransfer;