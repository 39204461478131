/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import api from '../../../../../services/api';
import { Container, Row, Col, InputGroup } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GetPaymentMethodsFilter from "../../../../C2Points/GetPaymentMethodsFilter";
import GetAccountOperatorsFilter from "../../../../C2Points/GetAccountOperatorsFilter";
import ModalQuestion from "../../../../Modal/ModalQuestion/ModalQuestion";

import "../../../../../assets/sass/modal.scss";
import GetAccountingAccountsFilter from "../../../../C2Points/GetAccountingAccountsFilter";
import SingleCalendarEditable from "../../../../Calendar/SingleCalendarEditable";
//import SingleCalendarEditable from "../../../../Calendar/SingleCalendarEditable";

export interface propModal {
  action: any,
  info?: any,
  reserva: any,
  cliente: any,
  setModalSuccess: any,
  setModalResponse: any,
  setModalMessage: any,
  setLog:any
};

const FormBillReceivableAdd: React.FC<propModal> = ({
  action, info, reserva, cliente
  , setModalSuccess, setModalResponse, setModalMessage, setLog
}: propModal, props: any) => {

  const hoje = (new Date()).toISOString().substring(0,10);

  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  }

  const [formaPagamento, setFormaPagamento] = useState<any>({
    id: info ? info.formaPagamentoId : 2,
    descricao: info ? info.formaPagamento : 'Cash',
    possuiMdr: 2,
    possuiOperador: 2,
    tipo: 2,
    alias: 'CASH'
  });

  //const [vencimento, setVencimento] = useState<any>(info ? formatDate(info.vencimento) : '');
  const [valorAtual, setValorAtual] = useState<any>(info ? formatValue(info.valor) : '');
  const [mdr, setMdr] = useState<any>(info ? formatValue(info.mdr) : '');
  const [loading, setLoading] = useState<any>(false);

  const [date, setDate] = useState<any>(formatDate(hoje));
  const [categoria, setCategoria] = useState<any>(378);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      //id: info ? info.id : 0,
      //tipo: info ? info.tipo : 1, // 1 = pagamento, 2 = parcela
      formaPagamento: info ? info.formaPagamentoId : '',
      operadora: info ? info.operadoraId : '',
      valor: info ? formatValue(info.valor) : '',
      mdr: info ? formatValue(info.mdr) : '',
      vencimento: info && info.vencimento ? info.vencimento.substring(0,10) : hoje,
      nsu: info ? info.nsu : '',
      observacao: info ? info.observacao : '',
      account: 378,
    },
  });

  const fixDate = (data: any) => {
    var aux = data.split('/');

    return (`${aux[2]}-${aux[1]}-${aux[0]}`);
  }

  const onSubmitForm = (dataObj: any) => {
    dataObj.valor = valorAtual;
    dataObj.mdr = mdr;
    
    if(formaPagamento.possuiMdr === 1 && mdr === '') {
      alert('preencha o valor do mdr');
      return;
    }

    if(dataObj.formaPagamento && dataObj.formaPagamento.id) dataObj.formaPagamento = dataObj.formaPagamento.id;
    if(dataObj.operadora && dataObj.operadora.id) dataObj.operadora = dataObj.operadora.id;
    if(!dataObj.operadora) dataObj.operadora = 0;

    if(dataObj.valor !== '') {
      dataObj.valor = parseFloat(dataObj.valor.replace(',','.'));
    } else {
      dataObj.valor = 0;
    }
    if(dataObj.mdr !== '') {
      dataObj.mdr = parseFloat(dataObj.mdr.replace(',','.'));
    } else {
      dataObj.mdr = 0;
    }
    

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    dataObj.currency = "BRL";
    dataObj.reserva = reserva;
    dataObj.agente = cliente; 
    dataObj.contaContabil = categoria;//.id;
    dataObj.data =  date !== '' ? fixDate(date) : '';

    const token = localStorage.getItem('GroupId') || '';

    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    setLoading(true);
    setModalResponse("loading");
    setModalSuccess(true);

    const salvar = async () => {
      try {
        const { data } = await api.post('BillsReceivable/InsertBill', dataObj, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
          var response = data.data;

          //alert(response.texto);
          setModalMessage(response.texto);
          setLog(response.log);

          if(response.log !== 1) {
            setModalResponse("success");
            action(data);
          } else {
            setModalResponse("error");
          }
        }   
      }catch(error: any) {
        setModalMessage("Erro, tente novamente mais tarde");
        setLog(1);
        setModalResponse("error");
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
        //console.log(error);
      }

      setLoading(false);
    }
    salvar();
  };

  async function handleSetOperadora(operadora: any) {

    //var mdr = 0;
    var valorAtualF = valorAtual && valorAtual !== '' ? valorAtual.replace(',','.') : 0;


    const calcAccountOperatorMdr = async () => {
      
      try {
        const token = localStorage.getItem('GroupId') || '';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        
        const { data } = await api.post('BillsReceivable/CalcAccountOperatorMdr', {
          id: operadora.id,
          installments: info?.parcelas ? info.parcelas : 1,
          amount: valorAtualF
        }, config);
        if (data.status !== 400) {
          var response = data.data;

          if(response.log !== 1) {
            setMdr(formatValue(response.data));
          } else {
            setMdr('');
          }
        }   
      }catch(error: any) {
        //setModalMessage("Erro, tente novamente mais tarde");
        //setLog(1);
        //setModalResponse("error");
        console.log(error);
      }
    }
    await calcAccountOperatorMdr();

    // if(operadora.tipoTaxa === 1) { // 
    //   mdr = operadora.taxa;
    // } else {
    //   mdr = valorAtualF * operadora.taxa / 100;
    // }
    // if(operadora.temTaxaMin === 1) {
    //   mdr = mdr + operadora.taxaMin;
    // }

    // console.log(mdr);
    // if(mdr) setMdr(formatValue(mdr));
    // else setMdr('');
  }

  function handleValorChange(e: any) {
    const newValue = e.target.value;
    setValorAtual(newValue);
  }

  function handleMdrChange(e: any, field: any) {
    const newValue = e.target.value;
    setMdr(newValue);
    field.onChange(e);
  }

  useEffect(() => {
    // const token = localStorage.getItem('GroupId') || '{}';
    // const config = {
    //     headers: { 'Authorization': `Bearer ${token}` },
    // };

    // async function listSuppliers() {
    //   try {
    //     const { data } = await api.get(`BillsReceivable/GetPaymentMethodById/${info.formaPagamentoId}`, config);

    //     if (data.status !== 400) {
    //       setFormaPagamento(data.data);
    //     }
    //   } catch (error: any) {
    //     if (error.response.status === 401) {
    //       window.location.href = window.location.origin + '/';
    //     }
    //   }
    //   try {
    //     if(info.operadorId) {
    //       const { data } = await api.get(`BillsReceivable/GetAccountOperatorById/${info.operadorId}`, config);

    //       if (data.status !== 400) {
    //         handleSetOperadora(data.data);
    //       }
    //     }
    //   } catch (error: any) {
    //     if (error.response.status === 401) {
    //       window.location.href = window.location.origin + '/';
    //     }
    //   }
    // }
    // if(info) listSuppliers();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*info*/]);

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  /* function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    return (
      <>
        {date}
      </>
    );
  } */


    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row>
              <h5 className="contact-title mb-5">Adicionar Pagamento</h5>
              <Form
                className="form-add-contact"
                noValidate
              >
                <div className="">
                  <Row className="mb-3">
                    <Form.Group as={Col} md={3} controlId="formGridCat">
                        <Form.Label>Data</Form.Label>
                        <InputGroup
                            hasValidation
                            className=""
                            size="sm"
                            placeholder="Quando?"
                        >
                            <SingleCalendarEditable setValue={setDate} date={date} />
                        </InputGroup>
                    </Form.Group>

                    <Form.Group as={Col} md={8} controlId="formGridDesc">
                        <Form.Label>Conta Contábil</Form.Label>
                        <Controller
                            control={control}
                            name="account"
                            rules={{ required: { value: categoria ? false : true, message: "required" }, }}
                            //defaultValue={categoria?.id}
                            defaultValue={categoria}
                            render={({ field }: any) => (
                                <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel="Conta Contábil" setFilterAccount={setCategoria} />
                            )}
                        />
                        {/* <ErrorMessage
                            errors={errors}
                            name="account"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        /> */}
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="6">
                      <Form.Label>Forma Pagamento:</Form.Label>
                      <Controller
                        control={control}
                        name="formaPagamento"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <GetPaymentMethodsFilter propsField={field} propsErrors={errors} propsLabel='Forma Pagamento' setPaymentMethod={setFormaPagamento} />
                        )}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="2">
                      <Form.Label>Valor:</Form.Label>
                      <Controller
                        control={control}
                        name="valor"
                        //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            aria-invalid={errors?.valor ? "true" : ""}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required
                            onChange={handleValorChange}
                            value={valorAtual}
                          />
                        )}
                      />
                    </Form.Group>
                    {formaPagamento.possuiMdr === 1 ?
                    <Form.Group as={Col} md="2">
                      <Form.Label>MDR:</Form.Label>
                        <Controller
                          control={control}
                          name="mdr"
                          rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                          render={({ field }: any) => (
                            <Form.Control type="text"
                              {...field}
                              aria-invalid={errors?.mdr ? "true" : ""}
                              autoComplete='off'
                              onChange={(e: any) => handleMdrChange(e,field)}
                              value={mdr}
                              variant="standard"
                              
                              margin="normal"
                              required />
                          )}
                        />
                    </Form.Group>
                    :<></>}
                  </Row>
                  <Row className="mb-3">
                    {formaPagamento.possuiOperador === 1 ?
                    <Form.Group as={Col} md="6">
                      <Form.Label>Operadora / Bandeira:</Form.Label>
                      <Controller
                        control={control}
                        name="operadora"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <GetAccountOperatorsFilter propsField={field} propsErrors={errors} propsLabel='Operadora / Bandeira' contaAdquirente={(formaPagamento.tipo === 1) ? 2 : 1 } setAccountOperator={handleSetOperadora} />
                        )}
                      />
                    </Form.Group>
                    :<></>}
                    <Form.Group as={Col} md="3">
                      <Form.Label>NSU:</Form.Label>
                      <Controller
                        control={control}
                        name="nsu"
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            autoComplete='off'
                            variant="standard"
                            margin="normal"
                            disabled={formaPagamento.possuiMdr !== 1}
                          />
                        )}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="3">
                      <Form.Label>Vencimento:</Form.Label>
                        {/* <SingleCalendarEditable setValue={setVencimento} date={vencimento} /> */}
                      <Controller
                        control={control}
                        name="vencimento"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="date"
                            {...field}
                            aria-invalid={errors?.vencimento ? "true" : ""}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required />
                        )}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Observação:</Form.Label>
                      <Controller
                        control={control}
                        name="observacao"
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required />
                        )}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Form>
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Row className="buttons-modal d-flex justify-content-end mx-0">
            <Button
              type="button"
              className="btn btn-primary px-4 mr-3 text-uppercase"
              onClick={handleSubmit(onSubmitForm)}
              disabled={loading===true}
            >
              <FontAwesomeIcon
                icon={["fal", "save"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Salvar 
            </Button>
          </Row>
        </Modal.Footer>


      </>
    );

}

export default FormBillReceivableAdd;
