import { Key, useState } from "react";

import Table from "react-bootstrap/Table";
import Dropdown from 'react-bootstrap/Dropdown';
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import api from "../../../../services/api";

import FormBillReceivableEdit from "./FormBillReceivableEdit/FormBillReceivableEdit";
import ModalQuestion from "../../../Modal/ModalQuestion/ModalQuestion";
import FormRegisterBillCreditCard from "./FormRegisterBillCreditCard/FormRegisterBillCreditCard";

//STYLES
import "./TableBillReceiveEditBills.scss";
import FormBillReceivableAdd from "./FormBillReceivableAdd/FormBillReceivableAdd";
import { Button } from "react-bootstrap";

export interface propModal {
  //action: any
  info: any,
  refreshData: any,
  dataOperacao: any,
  estrangeiro: any,
  documentoCliente: any,
  setModalSuccess: any,
  setModalResponse: any,
  setModalMessage: any,
  setLog:any,
  reserva: any,
  cliente: any,
  loading: any,
  statusErp: any,
  registerCard: any
};

const TableBillReceiveEditBills: React.FC<propModal> = ({
  //action, 
  info,
  refreshData,
  dataOperacao,
  estrangeiro,
  documentoCliente,
  setModalSuccess,
  setModalResponse,
  setModalMessage,
  setLog,
  reserva,
  cliente,
  loading,
  statusErp,
  registerCard
}: propModal) => {
  const [billEditModalShow, setBillEditModalShow] = useState<boolean>(false);
  const [billCreditCardModalShow, setBillCreditCardModalShow] = useState<boolean>(false);
  const [billEdit, setBillEdit] = useState<any>(null);


  const [billDeleteModalShow, setBillDeleteModalShow] = useState<any>(false);
  const [billExportErpModalShow, setBillExportErpModalShow] = useState<any>(false);

  const [billAddModalShow, setBillAddModalShow] = useState<any>(false);
  //const [billDelete, setBillDelete] = React.useState(null);

  //const [controlObject, setControlObject] = useState<any>(info ? info : []);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;


  function handleBillDeleteClick(row: any) {
    // confirma antes de excluir
    setBillEdit(row);
    setBillDeleteModalShow(true);
  } 

  function handleActionBillDeleteModal(e: any) {
    // do delete
    setBillDeleteModalShow(false);
    setModalResponse('loading');
    setModalSuccess(true);
    
    async function settleQuery() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };

        try {
            const res = await api.post('/BillsReceivable/DeleteBillAsync',
                {
                    "paymentId": billEdit.id, // array com os itens a serem liquidados
                    "uid": uId,
                    "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
                }, config
            );
            if (res.status !== 400) {
              setModalMessage(res.data.data.texto);
              setLog(res.data.data.log);

              if (res.data.data.log === 0) {
                setModalResponse('success');
              }
              else if (res.data.data.log === 1) {
                setModalResponse('error');
              }
            }
        } catch (error: any) {
            //console.log(error)
            console.log(error.response)
            if (error?.response?.status === 401) {
                //window.location.href = window.location.origin + '/';
            }
        }
    }

    settleQuery();
  }

  function handleBillAddClick() {
    setBillAddModalShow(true);
  }

  function handleBillEditClick(row: any) {
    
    // chama modal
    setBillEdit(row);
    setBillEditModalShow(true);
  }

  function handleActionBillEditModal(data: any) {
    setBillEdit(null);
    setBillEditModalShow(false);
    //refreshData();
  }

  function handleActionBillEditHideModal() {
    //() => setBillEditModalShow(false)
    setBillEditModalShow(false);
    setBillEdit(null);
  }

  function handleActionBillAddModal(data: any) {
    setBillAddModalShow(false);
    //refreshData();
  }

  function handleActionBillAddHideModal() {
    setBillAddModalShow(false);
  }

  function handleRegisterCardClick(row: any) {
    setBillEdit(row);
    setBillCreditCardModalShow(true);
  }

  function handleActionBillCreditCardHideModal() {
    setBillCreditCardModalShow(false);
    setBillEdit(null);
  }

  function handleActionBillCreditCardModal(data: any) {
    setBillEdit(null);
    setBillCreditCardModalShow(false);
    //refreshData();
  }

  /*converte a data UTC para a data local do usuário*/
 /*  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any) {
    if(!cell) return '';
    //var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 3 || row.statusPagamento === 4) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  /* Forma de Pagamento: Cash | PIX | Transferência | Cartão |   poderão ser exportados a qualquer tempo
    Outras  formas de Pagamento:  Faturado | Comissão a Receber |  só poderão ser exportados  após D+1 da data de operação
 */
  // function canExportErp(info: any) {
  //   if(statusErp === 2) {
  //     return false;
  //   } else {
  //     //#VerificarBruno
  //     // if(info.exportaErp === 1) {
  //     //   return true;
  //     // } else if(info.exportaErp === 3) {

  //     //   const ano  = parseInt( dataOperacao.substring(0,4) );
  //     //   const mes  = parseInt( dataOperacao.substring(5,7) );
  //     //   const dia  = parseInt( dataOperacao.substring(8,10) );
          
  //     //   const dataOperacaoD = new Date(ano,mes-1,dia,0,0,0);
  //     //   const today = new Date();

  //     //   const days = (today.getTime() - dataOperacaoD.getTime()) / (1000*60*60*24);

  //     //   if(days >= 1) {
  //     //     return true;
  //     //   } else {
  //     //     return false;
  //     //   }

  //     // } else {
  //     //   return false;
  //     // }
  //     return true;
      
  //   }
  // }

  // function exportarErpClick(info: any) {
  //   // console.log(info);
  //   setBillEdit(info);
  //   setBillExportErpModalShow(true);
  // }

  function handleActionBillExportErpModal() {
    setBillExportErpModalShow(false);

    //const info = billEdit;
    setBillEdit(null);

    setModalResponse('loading');
    setModalSuccess(true);
    
    async function settleQuery() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };

      try {
        const res = await api.post(`/Checkout/EnvioContasReceberRM?booking=${reserva}`, {}, config);

        if (res.status !== 400) {
          //const dados = res.data.data;
          const dados = res.data;

          setLog(dados.log);

          if (dados.log === 0) {
            setModalResponse('success');
            setModalMessage('Enviado com sucesso ao ERP!');
          }
          else if (dados.log === 1) {
            setModalResponse('error');
            setModalMessage(dados.texto);
          }
        }
      } catch (error: any) {
        //console.log(error)
        console.log(error.response)
        if (error?.response?.status === 401) {
            //window.location.href = window.location.origin + '/';
        }
      }
    }

    settleQuery();
  }

  function canRegisterCard(info: any) {
    //info.statusPagamento !== 2 || info.formaPagamentoId !== 85 || statusErp === 2
    if(registerCard !== 1 || info.statusPagamento !== 2 || info.formaPagamentoId !== 85) {
      return false;
    }

    return true;
  }

  const loadingInfo = [{id:1},{id:2}];

  return (
    <>
      <div className="table-default bill-receive-bills-table">
        <div className="table-container">
          <div className="table-title mb-3">
            <h3>Pagamentos</h3>
          </div>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Data</th>
                <th>Método Pagamento</th>
                <th>Valor</th>
                <th>MDR</th>
                <th>Operadora / Bandeira</th>
                <th>NSU</th>
                <th>Vencimento</th>
                <th>Observação</th>
                <th></th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
            {loading !== true ?
              (info.length > 0
                ? info.map((info: any, index: Key) => (
                  <tr key={index} className={rowClasses(info, index)}>
                    <td>{convertDate(info.data)}</td>
                    <td>{info.formaPagamento}</td>
                    <td>{convertValue(info.valor)}</td>
                    <td>{convertValue(info.mdr)}</td>
                    <td>{info.operadora}</td>
                    <td>{info.nsu}</td>
                    <td>{convertDate(info.vencimento)}</td>
                    <td>{info.observacao}</td>
                    <td>
                    {info.notaAvulsa !== 0 ? 
                      <>
                        {
                          info.apiCheck === 1 ?
                          <FontAwesomeIcon
                            icon={["fal", "cloud"]}
                            size="1x"
                            style={{ marginRight: "1px" }}
                            className="fg-ativo"
                            title="Validado na API"
                          />
                          :
                          <FontAwesomeIcon
                            icon={["fal", "cloud"]}
                            size="1x"
                            style={{ marginRight: "1px" }}
                            className="fg-gray"
                          />
                        }
                        {
                          info.statusPagamento === 2 ?
                            <FontAwesomeIcon
                              icon={["fal", "check"]}
                              size="1x"
                              style={{ marginRight: "1px" }}
                              className="fg-blue"
                            />
                          : info.statusPagamento === 3 || info.statusPagamento === 4 ?
                            <FontAwesomeIcon
                              icon={["fal", "check"]}
                              size="1x"
                              style={{ marginRight: "1px" }}
                              className="fg-ativo"
                            />
                          : <></>
                        }
                      </>
                    :<></>}
                    </td>
                    <td>{info.notaAvulsa !== 4 && info.notaAvulsa !== 0 ?
                      <div className="center-table options-table">
                        <Dropdown drop="start" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon 
                            size="lg" 
                            icon={['fal', 'ellipsis-h']} 
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            {info.statusPagamento === 3 || info.statusPagamento === 4 || statusErp === 2 ?
                              <Dropdown.Item eventKey="1" disabled={true}><FontAwesomeIcon icon={['fal', 'edit']} />Editar</Dropdown.Item>
                            :
                              <Dropdown.Item onClick={()=>handleBillEditClick(info)} eventKey="1"><FontAwesomeIcon icon={['fal', 'edit']} />Editar</Dropdown.Item>
                            }
                            {canRegisterCard(info) ?
                              <Dropdown.Item onClick={()=>handleRegisterCardClick(info)} eventKey="2"><FontAwesomeIcon icon={['fal', 'credit-card']} />Registrar Cartão</Dropdown.Item>
                            :
                              <Dropdown.Item eventKey="2" disabled={true}><FontAwesomeIcon icon={['fal', 'credit-card']} />Registrar Cartão</Dropdown.Item>
                            }
                            <Dropdown.Divider />
                            {info.tipo === 2 || (info.statusPagamento !== 1 && info.statusPagamento !== 2) || statusErp === 2 ?
                              <Dropdown.Item eventKey="3" className="text-danger removeRow" disabled={true}><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />Excluir</Dropdown.Item>
                            :
                              <Dropdown.Item onClick={()=>handleBillDeleteClick(info)} eventKey="3" className="text-danger removeRow"><FontAwesomeIcon icon={['fal', 'trash-alt']} className="mr-2" />Excluir</Dropdown.Item>
                            }
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    : <></>}</td>
                  </tr>
                ))
                : <tr style={{ border: "none" }}><td colSpan={10} className="text-center">Nenhum item encontrado</td></tr>)
                
              : <>
              {loadingInfo.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td colSpan={10} className="text-primary animated-background"></td>
                  </tr>
                ))}
            </>}
            </tbody>
          </Table>
          <div className="row p-2">
            <div className="col col-12 text-center">
              {loading || statusErp === 2 ?
                <Button className='bg-transparent' style={{ height: "40px" }} disabled={true}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fal", "plus"]}
                    style={{ color: "#707070", margin: "0 12px" }}
                  />
                </Button>
              :
                <Button className='bg-transparent' style={{ height: "40px" }} onClick={(e: any) => handleBillAddClick()}>
                  <FontAwesomeIcon
                    size="lg"
                    icon={["fal", "plus"]}
                    style={{ color: "#707070", margin: "0 12px" }}
                  />
                </Button>
              }
            </div>
          </div>

          
          <Modal
            className="modalReceber"
            show={billEditModalShow}
            onHide={handleActionBillEditHideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header className="p-3" closeButton></Modal.Header>
              <FormBillReceivableEdit 
                action={handleActionBillEditModal} 
                info={billEdit} 
                setModalSuccess={setModalSuccess}
                setModalResponse={setModalResponse}
                setModalMessage={setModalMessage}
                setLog={setLog}
              />
            </>
          </Modal>
          <Modal
            className="modalReceber"
            show={billCreditCardModalShow}
            onHide={handleActionBillCreditCardHideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header className="p-3" closeButton></Modal.Header>
              <FormRegisterBillCreditCard 
                action={handleActionBillCreditCardModal} 
                paymentId={billEdit ? billEdit.id : 0} 
                dataOperacao={dataOperacao} 
                countryRequired={estrangeiro===1} 
                docRequired={documentoCliente ? false : true} 
                setModalSuccess={setModalSuccess}
                setModalResponse={setModalResponse}
                setModalMessage={setModalMessage}
                setLog={setLog}
              />
            </>
          </Modal>

          <ModalQuestion
            modalShow={billDeleteModalShow}
            setModalShow={setBillDeleteModalShow}
            titulo="Excluir Pagamento"
            mensagem="Deseja excluir o Pagamento?"
            handleActionConfirmModal={handleActionBillDeleteModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />

          <ModalQuestion
            modalShow={billExportErpModalShow}
            setModalShow={setBillExportErpModalShow}
            titulo="Exportar para ERP"
            mensagem="Confirma os valores a serem exportados ?"
            handleActionConfirmModal={handleActionBillExportErpModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />

          <Modal
            className="modalReceber"
            show={billAddModalShow}
            onHide={handleActionBillAddHideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header className="p-3" closeButton></Modal.Header>
              <FormBillReceivableAdd 
                action={handleActionBillAddModal} 
                reserva={reserva}
                cliente={cliente}
                setModalSuccess={setModalSuccess}
                setModalResponse={setModalResponse}
                setModalMessage={setModalMessage}
                setLog={setLog}
              /> 
            </>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default TableBillReceiveEditBills;
