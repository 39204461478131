import React from 'react';

import { Row } from "react-bootstrap";
/* import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message"; */

export interface propForm {
    control: any,
    errors: any,
    t?:any
};

const PaymentCheckoutPix: React.FC<propForm> = ({
    control, errors, t
}: propForm) => {

    return (
        <>
            <h3>{t("checkout.paymentCheckout.pix.pix")}</h3>

            <Row className="mb-3">
                <div className="" id="payment-div">
                    <div className="row p-0 m-0">
                        <div className='col-8'>
                            <small>{t("checkout.paymentCheckout.pix.msg1")}<br /> {t("checkout.paymentCheckout.pix.msg2")}</small>
                            <br />
                            <br />
                            <div>
                                <small>
                                    {t("checkout.paymentCheckout.pix.msg3")}<br />
                                    {t("checkout.paymentCheckout.pix.msg4")}<br />
                                    {t("checkout.paymentCheckout.pix.msg5")}<br />
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

            </Row>
        </>
    );
}

export default PaymentCheckoutPix;