import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import './SubordinateExtract.scss';

//COMPONENTS

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterSubordinateExtract from './components/FilterSubordinateExtract/FilterSubordinateExtract';
import TableSubordinateExtract from './components/TableSubordinateExtract/TableSubordinateExtract';
import DesktopDefault from '../../templates/DesktopDefault';
import { useTranslation } from 'react-i18next';

function SubordinateExtract() {
    const { t } = useTranslation();
    const [sessions, setSessions] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const hoje = new Date();
    const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate() - 1); // hoje - 1 dia = ontem
    const umMesAtras = new Date(hoje.getFullYear(), hoje.getMonth()-1, hoje.getDate());
    
    const dataPadrao = [`${umMesAtras.getDate()}/${umMesAtras.getMonth()+1}/${umMesAtras.getFullYear()}`,`${ontem.getDate()}/${ontem.getMonth()+1}/${ontem.getFullYear()}`];
    const adquirentePadrao = '16';

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    //console.log(tourAuth);
    const isAgent = tourAuth.user.perfil.isAgent;
    const supplierId = tourAuth.user.perfil.supplierId;

    /* Filter */
    const [filterLocalizador, setFilterLocalizador] = useState<any>("");
    const [filterRefAgent, setFilterRefAgent] = useState<any>("");
    const [filterStatusReserve, setFilterStatusReserve] = useState<any>(1);
    const [filterStatusPagamento, setFilterStatusPagamento] = useState<any>(2);
    const [filterFormaPagamento, setFilterFormaPagamento] = useState<any>(5);
    const [filterAdquirente, setFilterAdquirente] = useState<any>(adquirentePadrao);
    const [filterSupplier, setFilterSupplier] = useState<any>("");
    const [filterClient, setFilterClient] = useState<any>("");
    const [filterTypeDate, setFilterTypeDate] = useState<any>(2);
    const [filterDate, setFilterDate] = useState<any>(dataPadrao);
    /* END - Filter */

    const [canRefresh, setCanRefresh] = useState<boolean>(false);

    useEffect(() => {
        if(canRefresh) 
            refreshData();
        else
            setCanRefresh(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function refreshData() {
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: any = []
        let endDate: any = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        try {
            const res = await api.post('/CardConciliation/GetReportSubordinate',
                {
                    "page": pageCount,
                    "rowsPerPage": rowsPerPage,
                    "tipoData": filterTypeDate,
                    "statusReserva": filterStatusReserve !== '' ? filterStatusReserve : null,
                    "statusPagamento": filterStatusPagamento !== '' ? filterStatusPagamento : null,
                    "formaPagamento": filterFormaPagamento !== '' ? filterFormaPagamento : null,
                    "localizador": filterLocalizador,
                    "agentRef": filterRefAgent,
                    "cliente": filterClient,
                    "subordinateId": isAgent === 1 ? supplierId : ( filterSupplier !== '' ? filterSupplier : null ),
                    "adquirente": filterAdquirente,
                    "dataInicial": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`,
                    "dataFinal": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                const data = res.data.data;

                setLoading(false);
                setSessions(data.rows);
                setTotalRows(data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function buscar(date: any, typeDate: any, localizador: any, refAgent: any, statusReserve: any, statusPagamento: any, formaPagamento: any, adquirente: any, supplier: any, client: any) {
        setPageCount(1);
        setFilterDate(date);
        
        setFilterTypeDate(typeDate);
        setFilterLocalizador(localizador);
        setFilterRefAgent(refAgent);
        setFilterStatusReserve(statusReserve);
        setFilterStatusPagamento(statusPagamento);
        setFilterFormaPagamento(formaPagamento);
        setFilterAdquirente(adquirente);
        setFilterSupplier(supplier);
        setFilterClient(client);
        
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: any = []
        let endDate: any = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        try {
            const res = await api.post('/CardConciliation/GetReportSubordinate',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "tipoData": typeDate,
                    "statusReserva": statusReserve !== '' ? statusReserve : null,
                    "statusPagamento": statusPagamento !== '' ? statusPagamento : null,
                    "formaPagamento": formaPagamento !== '' ? formaPagamento : null,
                    "localizador": localizador,
                    "agentRef": refAgent,
                    "cliente": client,
                    "subordinateId": isAgent === 1 ? supplierId : ( supplier !== '' ? supplier : null ),
                    "adquirente": adquirente,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                const data = res.data.data;

                setLoading(false);
                setSessions(data.rows);
                setTotalRows(data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
        //console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
        let link = document.createElement('a');
        link.href = blob;//window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
        //}
    };

    async function exportReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: any = []
        let endDate: any = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        try {
            const res = await api.post('CardConciliation/ExportReportSubordinate',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "tipoData": filterTypeDate,
                    "statusReserva": filterStatusReserve !== '' ? filterStatusReserve : null,
                    "statusPagamento": filterStatusPagamento !== '' ? filterStatusPagamento : null,
                    "formaPagamento": filterFormaPagamento !== '' ? filterFormaPagamento : null,
                    "localizador": filterLocalizador,
                    "agentRef": filterRefAgent,
                    "cliente": filterClient,
                    "subordinateId": isAgent === 1 ? supplierId : ( filterSupplier !== '' ? filterSupplier : null ),
                    "adquirente": filterAdquirente,
                    "dataInicial": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`,
                    "dataFinal": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: any = []
        let endDate: any = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        try {
            const res = await api.post('CardConciliation/PrintReportSubordinate',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "tipoData": filterTypeDate,
                    "statusReserva": filterStatusReserve !== '' ? filterStatusReserve : null,
                    "statusPagamento": filterStatusPagamento !== '' ? filterStatusPagamento : null,
                    "formaPagamento": filterFormaPagamento !== '' ? filterFormaPagamento : null,
                    "localizador": filterLocalizador,
                    "agentRef": filterRefAgent,
                    "cliente": filterClient,
                    "subordinateId": isAgent === 1 ? supplierId : ( filterSupplier !== '' ? filterSupplier : null ),
                    "adquirente": filterAdquirente,
                    "dataInicial": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`,
                    "dataFinal": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard subordinate-extract">
                    <Breadcrumb title={t("subordinateExtract.subordinateExtract.path")} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>{t("subordinateExtract.subordinateExtract.title")}</h2>
                    </div>
                    <FilterSubordinateExtract
                        buscar={buscar}
                        sessions={sessions}
                        error={error}
                        adquirentePadrao={adquirentePadrao}
                        dataPadrao={dataPadrao}
                        isAgent={isAgent}
                        //supplierId={supplierId}
                    />
                    <TableSubordinateExtract
                        sessions={sessions}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportReport={exportReport}
                        printReport={printReport} 
                        loading={loading}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );
}

export default SubordinateExtract;