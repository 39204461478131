/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useContext, useEffect, useState } from 'react';
import { AccordionContext, Accordion, useAccordionButton, Row, Modal } from 'react-bootstrap';
import api from '../../../../../services/api';


import EditInfoCompany from './EditInfoCompany/EditInfoCompany'
import AccordionInfoCompany from '../../AccordionInfoCompany/AccordionInfoCompany'
import EditFinancial from './EditFinancial/EditFinancial'
import AccordionFinancial from '../../AccordionFinancial/AccordionFinancial';
import EditAccounting from './EditAccounting/EditAccounting'
import AccordionAccount from '../../AccordionAccount/AccordionAccount';
import EditResources from './EditResources/EditResources'
import AccordionResources from '../../AccordionResources/AccordionResources';
import EditAdditionalInfo from './EditAdditionalInfo/EditAdditionalInfo'
import AccordionInfoAdd from '../../AccordionInfoAdd/AccordionInfoAdd';
import EditUser from './EditUser/EditUser';
import AccordionUsers from '../../AccordionUsers/AccordionUsers';
import RequestResponseModal from '../../../../../components/Modal/RequestResponseModal/RequestResponseModal';

export interface propInfo {
    info: any,
};

const EditSuppliersDataFields: React.FC<propInfo> = ({
    info
}: propInfo) => {

    const [stepCtrl, setStepCtrl] = useState<number>(0);
    const [infoCompanyEdit, setInfoCompanyEdit] = useState<boolean>(true);
    const [financialEdit, setFinancialEdit] = useState<boolean>(true);
    const [accountingEdit, setAccountingEdit] = useState<boolean>(true);
    const [resourcesEdit, setResourcesEdit] = useState<boolean>(true);
    const [usersEdit, setUsersEdit] = useState<boolean>(true);
    const [additionalInfoEdit, setAdditionalInfoEdit] = useState<boolean>(true);

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [modalText, setModalText] = useState<any>(''); /// ["loading","error","sucess"] para abrir o modal pós envio

    const [allowReload, setAllowReload] = React.useState(false);

    const handleNext = () => {
        setStepCtrl(stepCtrl + 1);
    }

    const ContextAwareToggle = ({ children, eventKey, callback, status }: any) => {

        const { activeEventKey } = useContext(AccordionContext);

        const decoratedOnClick = useAccordionButton(
            eventKey,
            () => callback && callback(eventKey),
        );

        const isCurrentEventKey = activeEventKey === eventKey;

        return (
            <h2 className="accordion-custom accordion-header">
                <button
                    type="button"
                    aria-expanded="false"
                    className={isCurrentEventKey ? "accordion-button collapsed" : "accordion-button collapsed"}
                    onClick={decoratedOnClick}
                    disabled={status}>
                    {children}
                </button>
            </h2>
        );
    }

    const editSuppliers = (obj: any) => {
        obj.cityCode = obj.municipioId;
        setModalResponse("loading");
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function updateSuppliers() {
            try {
                const res = await api.post('Supplier/UpdateSupplierAsync',
                    obj
                    , config
                );

                if (res.status === 200) {
                    if (res.data.statusCode === 200) {
                        if (res.data.data.log === 0) {
                            setModalResponse("success");
                        } else {
                            setModalResponse("error");
                        }
                        setModalText(res.data.data.texto);
                    } else {
                        setModalResponse("error");
                        setModalText(res?.data?.errorMessage || "Algo deu errado, favor entrar em contato com o suporte!");
                    }
                } else {
                    setModalResponse("error");
                    setModalText("Algo deu errado, favor entrar em contato com o suporte!");
                }
            } catch (error: any) {
                //setErrors(error.response)
                setModalResponse("error");
                setModalText(error?.response?.data?.errorMessage || "Erro ao processar operação")
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        updateSuppliers();
    }

    useEffect(() => {
        if (modalResponse === 'success') {
            setAllowReload(true);
        }

        if (allowReload === true && modalResponse === null) {
            window.location.reload();
        }
    }, [modalResponse])


    return (
        <>
            {infoCompanyEdit && financialEdit && accountingEdit && resourcesEdit && usersEdit && additionalInfoEdit
                ?
                <>
                    <EditInfoCompany setChangeEdit={setInfoCompanyEdit} info={info} />
                    <EditFinancial setChangeEdit={setFinancialEdit} info={info} />
                    <EditAccounting setChangeEdit={setAccountingEdit} info={info} />
                    <EditResources setChangeEdit={setResourcesEdit} info={info} />
                    <EditUser setChangeEdit={setUsersEdit} info={info} usersEdit={true} />
                    <EditAdditionalInfo setChangeEdit={setAdditionalInfoEdit} info={info} />
                </>
                : ''
            }
            {!infoCompanyEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionInfoCompany editSuppliers={editSuppliers} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} infoEdit={info} isEdit={true} />
                </Accordion>
                :
                ''
            }
            {!financialEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionFinancial editSuppliers={editSuppliers} setChangeEdit={setFinancialEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} infoEdit={info} isEdit={true} />
                </Accordion>
                :
                ''
            }
            {!accountingEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionAccount editSuppliers={editSuppliers} setChangeEdit={setAccountingEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} infoEdit={info} isEdit={true} />
                </Accordion>
                :
                ''
            }
            {!resourcesEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionResources editSuppliers={editSuppliers} setChangeEdit={setResourcesEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} infoEdit={info} isEdit={true} />
                </Accordion>
                :
                ''
            }
            {!usersEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionUsers editSuppliers={editSuppliers} setChangeEdit={setUsersEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} supplierControlObject={false} setStepCtrl={false} infoEdit={info} isEdit={true} />
                </Accordion>
                :
                ''
            }
            {!additionalInfoEdit
                ?
                <Accordion defaultActiveKey="0" flush>
                    <AccordionInfoAdd editSuppliers={editSuppliers} setChangeEdit={setAdditionalInfoEdit} action={handleNext} ContextAwareToggle={ContextAwareToggle} setStepCtrl={false} infoEdit={info} isEdit={true} />
                </Accordion>
                :
                ''
            }
            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                <div className="btn-sucess">
                    <Modal
                        className="modal-custom modalAuth"
                        show={modalResponse !== null}
                        onHide={() => setModalResponse(null)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Header closeButton></Modal.Header>
                            <RequestResponseModal modalResponse={modalResponse} modalText={modalText} setModalResponse={setModalResponse} />
                        </>
                    </Modal>
                </div>
            </Row>
        </>
    );
}

export default EditSuppliersDataFields;