/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Key } from 'react';
import api from "../../../../../../services/api";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Badge, Dropdown, Button, OverlayTrigger, Tooltip, Overlay } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import DoubleCalendar from "../../../../../../components/Calendar/DoubleCalendar";
import DoubleCalendarTicket from "../../../../../../components/Calendar/DoubleCalendarTicket";
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import InputMask from "react-input-mask";
import './DetailsExportERM.scss';
import GetPickupList from '../../../../../../components/C2Points/GetPickupList';
import SingleCalendar from '../../../../../../components/Calendar/SingleCalendar';
import GetAgents from '../../../../../../components/C2Points/GetAgents';
import GetAgentsDetails from '../../../../../../components/C2Points/GetAgentsDetails';
import GetPickupLocation from '../../../../../../components/C2Points/GetPickupLocation';
import GetPickups from '../../../../../../components/C2Points/GetPickups';

export interface propInfo {
  id: any,
  setDetailsModal: any,
};

const DetailsExportERM: React.FC<propInfo> = ({
  id, setDetailsModal
}: propInfo) => {
  const [item, setItem] = useState<any>(null);
  //const [texts, setTexts] = useState<any>('Pesquisando');
  const [resetTimer, setResetTimer] = useState<any>(1);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  //const [allowCalendar, setAllowCalendar] = useState<boolean>(false);
  const [name, setName] = useState<any>();
  const [cellphone, setCellphone] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [cia, setCia] = useState<any>();
  const [voo, setVoo] = useState<any>();

  const [resellerReference, setresellerReference] = useState<any>();
  //const [passageiro, setPassageiro] = useState<any>();
  const [privMaxNumPeople, setPrivMaxNumPeople] = useState<any>(null);
  const [hour, setHour] = useState<any>();
  const [date, setDate] = useState<any>();
  const [dateTour, setDateTour] = useState<any>([]);
  const [internalNotesItem, setInternalNotesItem] = useState<any>();
  const [pickup, setPickup] = useState<any>(undefined);
  const [pickupListId, setPickupListId] = useState<any>(null);
  const [tourOptions, setTourOptions] = useState<any>(null);

  const [editSuccess, setEditSuccess] = useState<any>(false);
  const [currentEditResponse, setCurrentEditResponse] = useState<any>(null);
  const [editErrorText, setEditErrorText] = useState<any>('');

  const [passengers, setPassengers] = useState<any>(undefined);
  const [showPassengersDetails, setShowPassengersDetails] = useState(false);
  const target = useRef(null);
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>('');
  const [status, setStatus] = useState<any>(null);

  const [agents, setAgents] = useState<any>(null);

  const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)

  const [loading, setLoading] = useState<any>(false);

  const validated = false;

  useEffect(() => {
    let timer5 = setTimeout(() => setResetTimer(resetTimer + 1), 2000);

    return () => {
      clearTimeout(timer5);
    };
  }, [resetTimer])

  useEffect(() => {
    const getDetails = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const res = await api.get(`Manifest/sendRmReturn?Loc=${id}`, config);
        if (res.status !== 400) {
          console.log(res)
          setItem(res.data.data)
          if (res.data.data === null) {
            setDetailsModal(false);
            //setModalErrorShow(true);

          }
        }
      } catch (error: any) {
        setDetailsModal(false);
        //setModalErrorShow(true);
        setStatus(400);

      }
    };
    if (id !== null) {
      getDetails();
    }

  }, [id]);

  /* useEffect(() => {
    if (item !== null) {
      setName(item.nome);
      setCellphone(item.telefone);
      setEmail(item.email);
      setVoo(item.voo);
      setCia(item.cia);
      //setPassageiro(item.quantidadePax);
      setDate(item.startTimeLocal);
      setHour(convertDate(item.startTimeLocal, true));
      setPassengers({
        "adult": item.quantityReservation.adultSale,
        "child": item.quantityReservation.childSale,
        "infant": item.quantityReservation.infantSale,
        "elder": item.quantityReservation.elderSale,
        "student": item.quantityReservation.studentSale,
        "globalPeople": item.quantityReservation.privateSale
      });
      setInternalNotesItem(item.internalNotesItem);
      setresellerReference(item.resellerReference);

      for (var i: any = 0; i < item.pickupListByOrderDTO.length; i++) {
        if (item.nomePickup === item.pickupListByOrderDTO[i].nomePickup) {
          setPickup(item.pickupListByOrderDTO[i].idPickupLocation);
        }
      }

      const endP = item.productType === 1 ? `/Products/FetchSingleTourAsync` : `/Products/FetchSingleTicketAsync`;

      const getProdModCode = async () => {
        try {
          const { data } = await api.post(
            endP,
            {
              "productCode": item.productCode,
              "lang": "BR",
              "slug": ""
            }
          );
          if (data.status !== 400) {
            setTourOptions(data.data.modalities);
            setPickupListId(data.data.modalities.filter((e: any) => parseInt(e.id) === parseInt(item.idModalidade))[0].pickupListId);
            setPrivMaxNumPeople(data.data.maxNumPeople)
            //setAllowCalendar(true)
          }
        } catch (error) { }
      }
      getProdModCode();
    }
  }, [item]) */

  useEffect(() => {
    var aux: any = [];
    if (tourOptions !== null) {

      for (var i = 0; i < tourOptions.length; i++) {
        /*  */
        aux.push({
          adult: 0,
          child: 0,
          infant: 0,
        })
      }
    }
    setDateTour(aux);
  }, [tourOptions])

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any, isHour: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours/*  - offset */);
    if (isHour === true) {
      return newDate.toLocaleString().split(' ')[1];
    } else {
      return newDate.toLocaleString().split(' ')[0];
    }
  }

  function convertDate(cell: any, isHour: any = false) {
    var date = convertUTCDateToLocalDate(new Date(cell), false);
    var hours = convertUTCDateToLocalDate(new Date(cell), true);
    if (isHour === true) {
      return (
        <>
          {hours}
        </>
      );
    } else {
      return (
        <>
          {date}
        </>
      );
    }
  }
  /*converte a data UTC para a data local do usuário*/

  function changePriceDateTour(obj: any) {
    //.log(obj[0].data);
    setDateTour(obj);
    setDate(obj[0].data);
  }

  function getVoucher(idItem: any) {
    // setCancelamentoModal(true);

    const getVou = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/checkout/PdfSecondWay/${idItem}`, {

        }, config);
        if (data.status !== 400) {
          if (data.data.log === 0 || data.data.log === '0') {

            const link = document.createElement('a');
            link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
            link.setAttribute(
              'download',
              data.data.data.voucherFile.fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // Clean up and remove the link
            link?.parentNode?.removeChild(link);
          } else if (data.data.log === 1 || data.data.log === '1') {
            alert(data.data.data.texto)
          }
        }
      } catch (error: any) {
      }
    };

    getVou();

  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmit = (res: any) => {
    res.name = name;
    res.cellphone = cellphone;
    res.email = email;
    res.cia = cia;
    res.voo = voo;
    res.internalNotesItem = internalNotesItem;
    res.resellerReference = resellerReference;
    res.resellerName = agents === null ? item?.resellerName : agents?.compFantasia;


    if (date === '') {
      res.date = item.startTimeLocal.split('T')[0];
    } else {
      res.date = item.productType !== 2 ? date : date.split('/').reverse().join('-');
    }

    if (item.productType === 2) {
      if (typeof res.hour === typeof 0) {
        res.hour = item.startTimeLocal.split('T')[1];
      }

      res.date = res.date + "T" + res.hour;
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    var language = (item.idioma === 'Inglês') ? 2 : (item.idioma === 'Espanhol') ? 3 : 1;

    const updateReservation = async () => {
      try {
        const { data } = await api.post(`/Checkout/EditEnviaCheckout`,
          {
            "firstName": res.name,
            "lastName": item.sobrenome,
            "phone": res.cellphone,
            "email": res.email,
            "idTarif": item.idTarifario,
            "internalNotes": res.internalNotesItem,
            "IdNewItem": item.idItem,
            "date": res.date,
            "idBooking": item.idBookings,
            "BookingLoc": item.bookingLoc,
            "Foreign": 1,
            "Language": language,
            "adults": passengers.adult,
            "childs": passengers.child,
            "infants": passengers.infant,
            "elders": passengers.elder,
            "students": passengers.student,
            "globalPeople": passengers.globalPeople,
            "pickup": res.listPickup ? parseInt(res.listPickup.idPickupLocation) : pickup?.id ? pickup.id : pickup,
            "cia": res.cia,
            "voo": res.voo,
            "resellerReference": res.resellerReference,
            "resellerName": res.resellerName
          }, config
        );
        if (data.status !== 400) {
          if (data.data.log === 1 || data.data.log === '1') {
            setCurrentEditResponse('error');
            setEditErrorText(data.data.texto);
            setLoading(false)
          } else {
            setCurrentEditResponse('success');
            setEditErrorText(data.data.texto);
          }
          // window.location.reload();
        }
      } catch (error: any) {
        setCurrentEditResponse('error')
        setEditErrorText(error?.response?.data?.errorMessage)
        setLoading(false)
      }
    }
    if ((res.hour === '') || (typeof res.hour !== typeof 0 && (parseInt(res.hour?.toString().split(':')[0]) >= 24 || parseInt(res.hour?.toString().split(':')[1]) > 60 || res.hour?.toString().includes('_')))) {
      alert('Hora inválida');
    } else {
      setLoading(true);
      setEditSuccess(true);
      setCurrentEditResponse('loading');
      updateReservation();
    }
  }

  if (item !== null) {
    return (
      <>
        <div>
          <Modal.Body>
            <div className="modal-header rounded-0">
              <div>
                <h4 className="pt-1 text-primary title-reservation">
                  Localizador: {item.bookingLoc}
                </h4>
              </div>
            </div>
            <hr
              className="w-100 my-4"
              style={{ borderBottom: "1px solid" }}
            />
            <div className="w-100 p-0">
              <Row className="p-0 m-0">
                <Col md={3}>
                  <small>
                    Data de emissão: <b>{new Date(item.dataEmissao).toLocaleDateString()}</b>
                  </small>
                </Col>
                <div className="divider"></div>
                <Col md={6} className="p-0 m-0 pl-4">
                  <small>
                    Data do passeio: <b>{new Date(item.dataPasseio).toLocaleDateString()}</b>
                  </small>
                </Col>
              </Row>

              <Row className="p-0 m-0 mt-5">
                <h6 className="mb-3">Informações adicionais</h6>
                <Col md={3}>
                  <span className="subtitle-modal">Portador</span>
                  <span className="response-modal">
                    <b>{item?.portador || "Não informado"}</b>
                  </span>
                </Col>
              </Row>
              <Row className="p-0 m-0 mt-5">
                <Col md={3}>
                  <span className="subtitle-modal">Valor Bruto</span>
                  <span className="response-modal">
                    <b>{(Number(item.valorBruto)).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                  </span>
                </Col>
                <Col md={3}>
                  <span className="subtitle-modal">Taxa</span>
                  <span className="response-modal">
                    <b>{Number(item.taxa).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                  </span>
                </Col>
              </Row>
              <Row className="p-0 m-0 mt-5">
                <Col md={3}>
                  <span className="subtitle-modal">Valor líquido</span>
                  <span className="response-modal">
                    <b>{Number(item.valorLiquido).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                  </span>
                </Col>
                <Col md={3}>
                  <span className="subtitle-modal">Valor com taxa</span>
                  <span className="response-modal">
                    <b>{Number(item.valorComTaxa).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                  </span>
                </Col>
                <Col md={3}>
                  <span className="subtitle-modal">Valor de desconto</span>
                  <span className="response-modal">
                    <b>{Number(item.valorDesconto).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                  </span>
                </Col>
                <Col md={3}>
                  <span className="subtitle-modal">Valor de taxa adm</span>
                  <span className="response-modal">
                    <b>{Number(item.valorTaxaAdm).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</b>
                  </span>
                </Col>
              </Row>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <div className="modal-footer rounded-0 border-0">
              <button
                type="button"
                className="btn btn-outline-primary rounded-0"
                data-dismiss="modal"
                onClick={() => {
                  setDetailsModal(false);
                }}
              >
                <i className="fas fa-times fa-fw"></i>{" "}
                <span data-vouchercampo="close">Fechar</span>
              </button>
            </div>
          </Modal.Footer>
        </div>
      </>
    );
  } else {
    return (
      <>
        <Modal.Body>
          <div className="modal-header rounded-0">
            <div>
              <div className="pt-1 text-primary title-reservation animated-background" style={{ height: "30px", width: "500px" }}></div>
              <small></small>
            </div>

            <div className='buttons-options'>
              <div>

              </div>
              <div>
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  className="mx-3 text-info"
                  size="lg"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { setDetailsModal(false) }}
                />
              </div>
            </div>
          </div>

          <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />

          <div className='w-100 p-0'>

            <Row className='p-0 m-0'>
              <Col md={2}>
                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
              </Col>
              <Col md={3} >
                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
              </Col>
              <div className='divider'></div>
              <Col md={6} className="p-0 m-0 pl-4">
                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
              </Col>
            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
                <div className='d-flex'>
                  <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                </div>
              </div>
            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
                <div className='d-flex'>
                  <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                </div>
              </div>
            </Row>
          </div>

        </Modal.Body>

      </>
    );
  }
}

export default DetailsExportERM;