/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
//import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersFilter";
//import GetProductsListSubCategory from "../../../../components/C2Points/GetProductsListSubCategory";

export interface propFilter {
    buscar: any;
    products: any;
    clearActualValues: any;
}

const TransferFilter: React.FC<propFilter> = ({
    buscar,
    products,
    clearActualValues
}) => {
    const [t] = useTranslation();
    const [filterOrigem, setFilterOrigem] = useState<any>("");
    const [filterDestino, setFilterDestino] = useState<any>("");
    const [filterStatus, setFilterStatus] = useState<any>(0);
    const [filterTypeService, setFilterTypeService] = useState<any>(0);
    const [filterTypeVeihicle, setFilterTypeVehicle] = useState<any>(0);

    const {
        control,
        setValue,
        formState: { errors },
    } = useForm({});

    const handleFilterClick = () => {
        buscar(
            filterOrigem,
            filterDestino,
            filterStatus,
            filterTypeService,
            filterTypeVeihicle
        );
    };

    const handleClearClick = () => {
        setValue("products", "");

        let inputValue = document.querySelectorAll("input");
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll("select");
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "";
            }
        }

        setFilterOrigem("");
        setFilterDestino("");
        setFilterStatus(0);
        setFilterTypeService(0);
        setFilterTypeVehicle(0);
        buscar("", "", 0, 0, 0);
        clearActualValues();
    };

    const handleChangeOrigem = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterOrigem(newValue);
    };

    const handleChangeDestino = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterDestino(newValue);
    };

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
    };

    const handleChangeTypeService = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeService(newValue);
    };

    const handleChangeTypeVeihicle = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeVehicle(newValue);
    };

    return (
        <div
            className="suppliers-filter d-flex justify-content-center bg-white mt-4"
            style={{ textAlign: "left" }}
        >
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header>
                        <FontAwesomeIcon
                            icon={["fal", "sliders-h"]}
                            size="lg"
                            className="mr-2"
                        />
                        <span className="h5 mb-0">Buscar transfers</span>
                    </Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form>
                                        <Row className="mb-4">
                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>
                                                    Origem
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="origin"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeOrigem}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Destino</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="destiny"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                            onChange={handleChangeDestino}
                                                        />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Status</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="status"
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => handleChangeStatus(e)}
                                                            >
                                                                <option value="0" selected>
                                                                    Todos
                                                                </option>
                                                                <option value="1">Ativo</option>
                                                                <option value="2">Inativo</option>
                                                                <option value="3">Rascunho</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-4">
                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Tipo de serviço</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="typeService"
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => handleChangeTypeService(e)}
                                                            >
                                                                <option value="0" selected>
                                                                    Todos
                                                                </option>
                                                                <option value="1">Privativo</option>
                                                                <option value="2">Compartilhada</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                <Form.Label>Tipo de veículo</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="typeVeihicle"
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                onChange={(e: any) => handleChangeTypeVeihicle(e)}
                                                            >
                                                                <option value="0" selected>
                                                                    Todos
                                                                </option>
                                                                <option value="1">Ônibus</option>
                                                                <option value="2">Micro-ônibus</option>
                                                                <option value="3">Van</option>
                                                                <option value="4">Carro</option>
                                                                <option value="5">Mini Van</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row
                                            as={Col}
                                            md="12"
                                            className="mt-5 justify-content-md-end"
                                        >
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button
                                                    variant="outline-primary"
                                                    className="btn-default mr-2 text-uppercase"
                                                    onClick={handleClearClick}
                                                >
                                                    {t("reservations.filter.btnClear")}
                                                </Button>
                                                <Button
                                                    className="btn-default text-uppercase"
                                                    onClick={handleFilterClick}
                                                >
                                                    {t("reservations.filter.btnSearch")}
                                                </Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    );
};

export default TransferFilter;
