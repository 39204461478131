import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';
import api from '../../services/api';
import SourceTable from './components/SourceTable';

import './ListSources.scss';
import SourceForm from './components/SourceForm';
import { Modal } from 'react-bootstrap';
import { useForm } from 'react-hook-form';
import SourceFilter from './components/SourceFilter';
import ModalDefault from '../../components/Modal/ModalDefault/ModalDefault';

const ListSources = () => {
    const [sources, setSources] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);

    const [selectedSource, setSelectedSource] = useState<any>(null);
    const [channels, setChannels] = useState<any>(null);

    const {
        control,
        getValues,
        setValue
    } = useForm({});

    useEffect(() => {
        const channelInfos = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { Authorization: `Bearer ${token}` },
            };
            try {
                const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/SalesChannel/GetAllSalesChannelFilterByPaginate`, {
                    page: 0,
                    rowsPerPage: 0
                }, config);

                if (data.statusCode === 200) {
                    setChannels(data.data.data.rows.map((elem: any) => { return ({ idSalesChannel: elem.idSalesChannel, salesChannelName: elem.salesChannelName, statusSalesChannel: elem.statusSalesChannel }) }));
                }
            } catch (error: any) { }
        };
        channelInfos();
    }, [])

    function search(resetPagination: boolean = false, obj: any = {}) {
        setSources(null);
        setLoading(true);

        const sourceInfos = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.post(`SourceChannel/FetchSourceChannel`, {
                    page: pageCount,
                    rowsPerPage,
                    sourceCode: obj.sourceCode || '',
                    idSalesChannel: obj.idSalesChannel || 0
                }, config);

                if (data.statusCode === 200) {
                    setLoading(false);
                    setSources(data.data.data.rows);
                    setTotalRows(data.data.data.rowsCount);

                    if (resetPagination) {
                        setPageCount(1);
                    }
                }
            } catch (error: any) { }
        };
        sourceInfos();
    }

    useEffect(() => {
        let data: any = getValues();
        setSources(null);
        setLoading(true);
        search(false, data);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    const reloadTable: any = () => {
        /* if(pageCount !== 1){
            setPageCount(1);
        } else { */
        setSources(null);
        setLoading(true);
        search();
        /* } */
    }

    useEffect(() => {
        if (selectedSource === null) {
            reloadTable();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [selectedSource]);

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Listar Sources'} />

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Listar Sources</h2>

                        <ModalDefault
                            title="Inserir Novo Source"
                            name="Associar Source"
                            icon="no"
                            typeIcon="plus-circle"
                            classBtn="btn btn-primary"
                            classModal="modal-large"
                            closeAction={reloadTable}
                        >
                            <SourceForm channels={channels} />
                        </ModalDefault>
                    </div>

                    <SourceFilter
                        buscar={search}
                        control={control}
                        getValues={getValues}
                        setValue={setValue}
                        channels={channels}
                    />
                    <SourceTable
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        sources={sources}
                        channels={channels}
                        loading={loading}
                        setSelectedSource={setSelectedSource}
                    />

                    <Modal
                        className="modal-custom modalAuth modal-large"
                        show={selectedSource !== null}
                        onHide={() => {
                            setSelectedSource(null);
                        }}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Header closeButton>
                                <div className="d-flex justify-content-between w-100">
                                    <h3>Editar Source</h3>
                                </div>
                            </Modal.Header>
                            <hr style={{ margin: "30px 0" }} />
                            <SourceForm channels={channels} isEdit={true} selectedSource={selectedSource} setSelectedSource={setSelectedSource} />
                        </>
                    </Modal>
                </div>
            </DesktopDefault>
        </>
    );
};

export default ListSources;