import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  isEdit?: any;
  infoEdit?: any;
  hasSavedInputs?: any;
  setValue: any;
  defaultIdArray?: any;
};

const GetResourcesType: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, isEdit = false, infoEdit, hasSavedInputs = false, setValue, defaultIdArray = null
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  //const [defaultItems, setDefaultItems] = useState<any>([]);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listResourcesType() {
      try {
        const { data } = await api.get(`/DropDown/GetResourcesType`, config);
        if (data.status !== 400) {
          setInfo(data.data)

          if(defaultIdArray !== null){
            let aux: any = [];
            let auxWithLabel: any = [];
            
            for(let i: number = 0; i < defaultIdArray.length; i++){
              aux.push(data.data.find((e: any) => parseInt(e.id) === defaultIdArray[i]));
            }

            aux.forEach((e: any) => {
              auxWithLabel.push({
                "label": e.description,
                "value": e.id
              })
            });
            setValue('supplierByResourceModel', auxWithLabel);
          }
        }
      } catch (error: any) {
        setTimeout(() => {
          listResourcesType();
        }, 5000);
      }
    }

    listResourcesType();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit === true || hasSavedInputs === true) {
      let aux: any = [];

      for (let i: any = 0; i < infoEdit?.length; i++) {
        aux.push(
          {
            "label": infoEdit[i].label || infoEdit[i].resourceTypeNameInput,
            "value": infoEdit[i].value || infoEdit[i].resourceTypeIdInput
          }
        );
      }
      setValue('supplierByResourceModel', aux);
      //setDefaultItems(aux)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoEdit])

  if (info !== null) {
    const transformed = info.map(({ id: value, description: label }: any) => ({ value, label }));
    return (
      <>
        <div className={propsErrors.supplierByResourceModel !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors?.supplierByResourceModel ? "true" : ""}
            label={propsLabel}
            as="select"
            className="basic-multi-select"
            classNamePrefix="select"
            margin="normal"
            placeholder={t('accordionFinancial.select.select')}
            isMulti
            options={transformed}
            getOptionLabel={(option: any) => `${option.label}`}
            //defaultValue={defaultItems}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetResourcesType;