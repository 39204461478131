import React from "react";

import "./ProductTour.scss"

import StartProduct from "../../components/RegisterProducts/StartProduct/StartProduct";
import DataTour from "../../components/RegisterProducts/DataTour/DataTour";
import Operation from "../../components/RegisterProducts/Operation/Operation";
import SummaryAndDetails from "../../components/RegisterProducts/SummaryAndDetails/SummaryAndDetails";

import DescriptionAndHigh from "../../components/RegisterProducts/DescriptionAndHigh/DescriptionAndHigh";
import Modalities from "../../components/RegisterProducts/Modalities/Modalities";
import Tariffs from "../../components/RegisterProducts/Tariffs/Tariffs";
import Location from "../../components/RegisterProducts/Location/Location";
import Insctructions from "../../components/RegisterProducts/Instructions/Instructions";
//import Contact from "./components/Contact/Contact";
//import Rules from "./components/Rules/Rules";
import SEO from "../../components/RegisterProducts/SEO/SEO";
import Photo from "./components/Photo/Photo";

export interface propTransfer {
  receiveObject?: any;
  tourObject?: any;
  handleNext?: any;
  management?: any;
  isEdit?: any;
  isMigration?: any;
  editFunction?: any;
}

const ProductTour: React.FC<propTransfer> = ({
  receiveObject,
  tourObject,
  handleNext,
  management,
  isEdit = false,
  isMigration = false,
  editFunction = () => {}
}: propTransfer) => {

  return (
    <>
      {/* CONTENT */}

      {(() => {
        switch (management) {
          case 0:
            return <StartProduct
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
            />;
          case 1:
            return <DataTour
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              isMigration={isMigration}
              editFunction={editFunction}
            />;
          case 2:
            return <Operation
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 3:
            return <SummaryAndDetails
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 4:
            return <DescriptionAndHigh
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 5:
            return <Photo
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 6:
            return <Modalities
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 7:
            return <Tariffs
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 8:
            return <Location
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 9:
            return <Insctructions
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 10:
            return <SEO
              action={handleNext}
              receiveObject={receiveObject}
              tourObject={tourObject}
              isEdit={isEdit}
              isMigration={isMigration}
              editFunction={editFunction}
            />;
          default:
            return null;
        }
      })()}
    </>
  )
}

export default ProductTour;