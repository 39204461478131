import React, { useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  info: any,
  lastInstallment?: any
};

const FormAddInstallmentFees: React.FC<propModal> = ({
  action, info, lastInstallment
}: propModal, props: any) => {

  //const [receiptDays, setReceiptDays] = useState<any>(Array.from({ length: 32 }, (_, i) => i));

  const [installmentNumber, setInstallmentNumber] = useState<any>(info ? info.installmentNumber : parseInt(lastInstallment)+1);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      adminFee: info ? info.adminFee : '',
      estabFee: info ? info.estabFee : '',
      installmentNumber: info ? info.installmentNumber : parseInt(lastInstallment)+1,
      id: info ? info.id : -1
    },
  });

  const onSubmitContact = (data: any) => {
    //data.id = id;
    //setId(id - 1);
    data.installmentNumber = installmentNumber;

    if (!info) {
      data.id = (Math.floor(Math.random() * 100000) + 1) * (-1);
      setInstallmentNumber(installmentNumber+1);
    }

    action(data);
  };

  return (
    <>
      <Container className="bg-modal-contact p-0" fluid>
        <Row>
          {/* <h5 className="contact-title mb-5">Taxa e Prazo</h5> */}
          <Form
            className="form-add-contact"
            noValidate
          >
            <div className="">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Num Parcela:</Form.Label>
                  <Controller
                    control={control}
                    name="installmentNumber"
                    //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="number"
                        {...field}
                        autoComplete='off'
                        variant="standard"
                        margin="normal"
                        value={installmentNumber}
                        readOnly />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Taxa Administradora (%):</Form.Label>
                  <Controller
                    control={control}
                    name="adminFee"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="text"
                        {...field}
                        aria-invalid={errors?.adminFee ? "true" : ""}
                        autoComplete='off'
                        variant="standard"

                        margin="normal"
                        required />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Taxa Estabelecimento (%):</Form.Label>
                  <Controller
                    control={control}
                    name="estabFee"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="text"
                        {...field}
                        aria-invalid={errors?.estabFee ? "true" : ""}
                        autoComplete='off'
                        variant="standard"

                        margin="normal"
                        required />
                    )}
                  />
                </Form.Group>
              </Row>
              
            </div>
          </Form>
        </Row>
      </Container>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitContact)}
          >
            Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormAddInstallmentFees;
