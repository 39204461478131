import React from 'react';
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../templates/DesktopDefault';

import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import './Products.scss';
import { Col, Row } from 'react-bootstrap';
import { IconCombo } from '../../assets/icons';

function Products() {
  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={'Produtos'} />

          <Row className="mt-4 list-type-products">
            {/* Tour */}
            <Col md={3}>
              <Link
                to={'/products/list-products'}
                className="card border-bottom-primary shadow h-100 btn btn-product"
              >
                <FontAwesomeIcon
                  className="text-primary text-center mb-3"
                  icon={['fal', 'route']}
                  size="3x"
                />
                <h2 className="text-info mb-3">Tour</h2>
                <p className="text-info">
                  Uma visita guiada a um ou mais lugares
                </p>
              </Link>
            </Col>

            {/* Transfer */}
            <Col md={3}>
              <Link to={'/products/list-transfer'} className='card border-bottom-primary shadow h-100 btn btn-product'>
                <FontAwesomeIcon
                  className="text-primary text-center mb-3"
                  icon={["fal", "bus"]}
                  size="3x"
                />
                <h2 className="text-info mb-3">Transfer</h2>
                <p className="text-info">Adicione serviços de levar viajantes entre locais, com foco em transporte, em vez de passeios turísticos</p>
              </Link>
            </Col>

            {/* Ticket */}
            <Col md={3}>
              <Link
                to={'/products/list-products'}
                className="card border-bottom-primary shadow h-100 btn btn-product"
              >
                <FontAwesomeIcon
                  className="text-primary text-center mb-3"
                  icon={['fal', 'ticket-alt']}
                  size="3x"
                />
                <h2 className="text-info mb-3">Ticket</h2>
                <p className="text-info">
                  Entrada e/ou descontos independentes para uma ou mais atrações
                  ou eventos
                </p>
              </Link>
            </Col>

            {/* Combo */}
            <Col md={3}>
              <Link
                to={'/products/list-combo'}
                className="card border-bottom-primary shadow h-100 btn btn-product"
              >
                {/* <img
                  src={iconTickets}
                  alt="combos"
                  className="combo-ticket mb-3"
                />    */}
                
                {IconCombo}        

                <h2 className="text-info mb-3">Combo</h2>
                <p className="text-info">
                  Maximize sua diversão, reúna diversos produtos, criando
                  memórias inesquecíveis.
                </p>
              </Link>
            </Col>
          </Row>
        </div>
      </DesktopDefault>
    </>
  );
}

export default Products;
