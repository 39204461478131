/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";

//COMPONENTS
import DesktopDefault from "../../templates/DesktopDefault";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import { Button, Card, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import "./OpeningHours.scss";
import { Controller, useForm } from "react-hook-form";
import OnlyTime from "../../components/Calendar/OnlyTime";
import api from "../../services/api";
//import ModalConfirm from "../../components/Modal/ModalConfirm/ModalConfirm";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function OpeningHours() {
  const [openingHoursCheck, setOpeningHoursCheck] = useState<any>(false);
  const [info, setInfo] = useState<any>(null);

  const [modalShow, setModalShow] = useState<any>(false);
  const [modalMessage, setModalMessage] = useState<any>('Carregando');
  const [modalLog, setModalLog] = useState<any>(null);

  const validated = false;

  const [weekDay, setWeekDay] = useState<any>([
    {
      id: 1,
      portuguesDay: "Segunda-feira",
      spanishDay: "Lunes",
      englishDay: "Monday",
      status: 1,
      hourStart: '00:00',
      hourEnd: '00:00',
      lastService: "2022-08-19T18:55:46"
    },
    {
      id: 2,
      portuguesDay: "Terça-feira",
      spanishDay: "Martes",
      englishDay: "Tuesday",
      status: 1,
      hourStart: '00:00',
      hourEnd: '00:00',
      lastService: "2022-08-19T15:55:46"
    },
    {
      id: 3,
      portuguesDay: "Quarta-feira",
      spanishDay: "Miércoles",
      englishDay: "Wednesday",
      status: 1,
      hourStart: '00:00',
      hourEnd: '00:00',
      lastService: "2022-08-19T15:55:46"
    },
    {
      id: 4,
      portuguesDay: "Quinta-feira",
      spanishDay: "Jueves",
      englishDay: "Thursday",
      status: 1,
      hourStart: '00:00',
      hourEnd: '00:00',
      lastService: "2022-08-19T15:55:46"
    },
    {
      id: 5,
      portuguesDay: "Sexta-feira",
      spanishDay: "Viernes",
      englishDay: "Friday",
      status: 1,
      hourStart: '00:00',
      hourEnd: '00:00',
      lastService: "2022-08-19T15:55:46"
    },
    {
      id: 6,
      portuguesDay: "Sábado",
      spanishDay: "Sábado",
      englishDay: "Saturday",
      status: 1,
      hourStart: '00:00',
      hourEnd: '00:00',
      lastService: "2022-08-19T15:55:46"
    },
    {
      id: 7,
      portuguesDay: "Domingo",
      spanishDay: "Domingo",
      englishDay: "Sunday",
      status: 1,
      hourStart: '00:00',
      hourEnd: '00:00',
      lastService: "2022-08-19T15:55:46"
    }, {
      id: 8,
      portuguesDay: "Horário Comple",
      spanishDay: "Horário Comple",
      englishDay: "Horário Comple",
      status: 1,
      hourStart: "2022-08-19T18:55:46",
      hourEnd: "2022-08-19T18:55:46",
      lastService: "2022-08-19T15:55:46"
    }
  ])

  /* function closeModal() {
    var closeBtn: any = document.getElementsByClassName("btn-close")[0];
    if (closeBtn !== undefined) {
      closeBtn.click();
    }
  } */

  function handleCheck(e: any, index: any) {
    setValue(weekDay[index].day, e.target.checked === true ? 1 : 2);
    setOpeningHoursCheck(e.target.checked);

    let aux: any = weekDay;
    aux[index].status = aux[index].status === 1 ? 0 : 1;
    setWeekDay(aux);
    setOpeningHoursCheck(!openingHoursCheck);
  }

  const handlerOpeningHours = (hour: any, index: number) => {
    if (hour !== undefined) {
      let aux: any = weekDay;
      weekDay[index].hourStart = hour;
      setWeekDay(aux);
    }
  }

  const handlerCloseningHours = (hour: any, index: number) => {
    if (hour !== undefined) {
      let aux: any = weekDay;
      weekDay[index].hourEnd = hour;
      weekDay[index].lastService = hour;
      setWeekDay(aux);
    }
  }

  const handlerLastService = (hour: any, index: number) => {
    if (hour !== undefined) {
      let aux: any = weekDay;
      weekDay[index].lastService = hour;
      setWeekDay(aux);
    }
  }


  const onSubmit = (data: any) => {
    setModalShow(true);
    setModalMessage('Carregando');

    data[0] = weekDay[0];
    data[0].status = weekDay[0].status;// === true || weekDay[0].status === 1 ? 1 : 0;

    data[1] = weekDay[1];
    data[1].status = weekDay[1].status;// === true || weekDay[1].status === 1 ? 1 : 0;

    data[2] = weekDay[2];
    data[2].status = weekDay[2].status;// === true || weekDay[2].status === 1 ? 1 : 0;

    data[3] = weekDay[3];
    data[3].status = weekDay[3].status;// === true || weekDay[3].status === 1 ? 1 : 0;

    data[4] = weekDay[4];
    data[4].status = weekDay[4].status;// === true || weekDay[4].status === 1 ? 1 : 0;

    data[5] = weekDay[5];
    data[5].status = weekDay[5].status;// === true || weekDay[5].status === 1 ? 1 : 0;

    data[6] = weekDay[6];
    data[6].status = weekDay[6].status;// === true || weekDay[6].status === 1 ? 1 : 0;     

    data[7] = weekDay[7];
    data[7].status = weekDay[7].status;// === true || weekDay[7].status === 1 ? 1 : 0;

    delete data.mondayOpening; delete data.mondayClosening;
    delete data.tuesdayOpening; delete data.tuesdayClosening;
    delete data.wednesdayOpening; delete data.wednesdayClosening;
    delete data.thursdayOpening; delete data.thursdayClosening;
    delete data.fridayOpening; delete data.fridayClosening;
    delete data.saturdayOpening; delete data.saturdayClosening;
    delete data.sundayOpening; delete data.sundayClosening;
    delete data.monday; delete data.tuesday; delete data.wednesday;
    delete data.thursday; delete data.friday; delete data.saturday; delete data.sunday;

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function updateOpeningHour() {
      try {
        const res = await api.post(
          `OpeningHours/UpdateOpeningHoursAsync`,
          {
            openingHoursList: weekDay
          },
          config
        );

        if (res.status !== 400) {
          setModalMessage(res.data.data.texto);
          setModalLog(res.data.data.log)
        } else {
          setModalMessage(res.data.errorMessage);
          setModalLog(res.data.data.log);
        }
      } catch (error: any) {
        console.log(error.response.data.errorMessage)
        setModalMessage(error.response.data.errorMessage);
        setModalLog(1);
      }
    }
    updateOpeningHour();
  };

  /* useEffect(() => {
    let aux: any = weekDay;
    aux[0].hourStart = opening;
    aux[1].hourStart = opening;
    setWeekDay(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [opening])

  useEffect(() => {
    let aux: any = weekDay;
    aux[0].hourEnd = closening
    setWeekDay(aux);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [closening]) */


  useEffect(() => {

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function addOpeningHour() {
      try {
        const { data } = await api.get(
          `OpeningHours/GetOpeningHoursAsync`, config
        );

        if (data.status !== 400) {
          setInfo(data.data.data);
          setWeekDay(data.data.data);
        }
      } catch (error: any) {

      }
    }
    addOpeningHour();
  }, []);

  useEffect(() => {
    if (info !== null) {
      let aux: any = info;
      /* aux.forEach((element:any) => { 
        if(element.status === 1){
          element.status = true;
        } else{
          element.status = false;
        }
      }); */
      setWeekDay(aux);
      setOpeningHoursCheck(!openingHoursCheck);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info])

  const { control, setValue, handleSubmit } = useForm({});
  return (
    <>
      <DesktopDefault>
        <div className="container-fluid container-opening-hours">
          <Breadcrumb title="Horário de Funcionamento" />

          <div className="opening-hours-title d-flex justify-content-start w-100">
            <h1>Horário de Funcionamento</h1>
          </div>

          <Card className="card-opening-hours">
            <Form
              noValidate
              validated={validated}
              onSubmit={handleSubmit(onSubmit)}
              autoComplete="false"
            >
              <Row className="d-flex align-items-center mb-4">
                <Col lg={4} md={4} className="column">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Segunda-feira
                    </Form.Label>
                    <Controller
                      control={control}
                      name="monday"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[0].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[0].status === 0}
                          onChange={(e: any) => handleCheck(e, 0)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="mondayOpening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[0].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={0}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[0].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="mondayClosening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[0].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={0}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[0].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex align-items-center mb-4">
                <Col lg={4} md={4} className="column">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Terça-feira
                    </Form.Label>
                    <Controller
                      control={control}
                      name="tuesday"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[1].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[1].status === 0}
                          onChange={(e: any) => handleCheck(e, 1)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="tuesdayOpening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[1].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={1}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[1].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="tuesdayClosening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[1].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={1}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[1].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex align-items-center mb-4">
                <Col lg={4} md={4} className="column">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Quarta-feira
                    </Form.Label>
                    <Controller
                      control={control}
                      name="wednesday"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[2].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[2].status === 0}
                          onChange={(e: any) => handleCheck(e, 2)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="wednesdayOpening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[2].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={2}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[2].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="wednesdayClosening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[2].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={2}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[2].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex align-items-center mb-4">
                <Col lg={4} md={4} className="column">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Quinta-feira
                    </Form.Label>
                    <Controller
                      control={control}
                      name="thursday"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[3].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[3].status === 0}
                          onChange={(e: any) => handleCheck(e, 3)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="thursdayOpening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[3].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={3}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[3].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="thursdayClosening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[3].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={3}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[3].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex align-items-center mb-4">
                <Col lg={4} md={4} className="column">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Sexta-feira
                    </Form.Label>
                    <Controller
                      control={control}
                      name="friday"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[4].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[4].status === 0}
                          onChange={(e: any) => handleCheck(e, 4)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="fridayOpening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[4].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={4}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[4].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="fridayClosening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[4].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={4}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[4].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex align-items-center mb-4">
                <Col lg={4} md={4} className="column">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Sábado
                    </Form.Label>
                    <Controller
                      control={control}
                      name="saturday"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[5].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[5].status === 0}
                          onChange={(e: any) => handleCheck(e, 5)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="saturdayOpening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[5].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={5}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[5].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="saturdayClosening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[5].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={5}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[5].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex align-items-center">
                <Col lg={4} md={4} className="column">
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Domingo
                    </Form.Label>
                    <Controller
                      control={control}
                      name="sunday"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[6].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[6].status === 0}
                          onChange={(e: any) => handleCheck(e, 6)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="sundayOpening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[6].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={6}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[6].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={3} md={3} className="column">
                  <Form.Group>
                    <Controller
                      control={control}
                      name="sundayClosening"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[6].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={6}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[6].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Row className="d-flex align-items-center mt-5">
                <Col lg={4} md={4} className="column" style={{ paddingTop: "24px" }}>
                  <Form.Group className="d-flex align-items-center">
                    <Form.Label className="col-md-6 m-0 mr-2">
                      Horário Completo
                    </Form.Label>
                    <Controller
                      control={control}
                      name="completeSchedule"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <Form.Check
                          className="m-0"
                          type="switch"
                          id="custom-switch"
                          label={
                            weekDay[7].status === 0 ? "Aberto" : "Fechado"
                          }
                          checked={weekDay[7].status === 0}
                          onChange={(e: any) => handleCheck(e, 7)}
                        />
                      )}
                    />
                  </Form.Group>
                </Col>
                <Col lg={2} md={2} className="column">
                  <Form.Group>
                    <Form.Label className="">
                      Horário de abertura
                    </Form.Label>
                    <Controller
                      control={control}
                      name="openingHours"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[7].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={7}
                                setTime={handlerOpeningHours}
                                defaultTime={weekDay[7].hourStart}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={2} md={2} className="column ">
                  <Form.Group>
                    <Form.Label className="">
                      Horário de fechamento
                    </Form.Label>
                    <Controller
                      control={control}
                      name="closeningHours"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[7].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={7}
                                setTime={handlerCloseningHours}
                                defaultTime={weekDay[7].hourEnd}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>

                <Col lg={2} md={2}>
                  <Form.Group>
                    <Form.Label className="">
                      Último serviço
                    </Form.Label>
                    <Controller
                      control={control}
                      name="lastService"
                      // rules={{ required: { value: true, message: "required" }, }}
                      render={({ field }: any) => (
                        <div className="input-text-custom">
                          {weekDay[7].status === 1 ? (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                            />
                          ) : (
                            <InputGroup hasValidation className="" size="sm">
                              <OnlyTime
                                index={7}
                                setTime={handlerLastService}
                                defaultTime={weekDay[7].lastService}
                              />
                            </InputGroup>
                          )}
                        </div>
                      )}
                    />
                  </Form.Group>
                </Col>
              </Row>

              <Modal
                className="modalAuth"
                show={modalShow}
                onHide={() => {
                  setModalLog(null);
                  setModalMessage("Carregando");
                  setModalShow(false);
                }}
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Body
                    className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                    style={{ height: "600px" }}
                  >
                    {modalLog === null ? (
                      <>
                        <div className="loading-modal">
                          <div className="load"></div>
                        </div>
                      </>
                    ) : (
                      <div>
                        {modalLog === 0 ? (
                          <FontAwesomeIcon
                            icon={["fal", "check-circle"]}
                            size="5x"
                            style={{ fontSize: "7.5em", color: "#6731FF" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            style={{ fontSize: "7.5em", color: "#6731FF" }}
                          />
                        )}
                      </div>
                    )}
                    <div>{modalMessage}</div>
                    <div className="d-flex justify-content-center pt-3">
                      <button
                        onClick={() => {
                          setModalLog(null);
                          setModalMessage("Carregando");
                          setModalShow(false);
                        }}
                        className="btn btn-primary mx-2 w-25"
                      >
                        Confirmar
                      </button>
                    </div>
                  </Modal.Body>
                </>
              </Modal>

              <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">

                    {/* <Button
                        className="form-button cancel"
                        onClick={() => { window.location.href = '/vehicles/list-vehicles' }}
                    >
                        Cancelar
                    </Button> */}

                    <Button
                        type="submit"
                        className="form-button"
                    >
                        Cadastrar
                    </Button>
                </Row>

              {/* <div className="mt-5 mb-3 d-flex justify-content-end">

                <ModalConfirm
                  show={modalShow}
                  handleSubmit={handleSubmit}
                  onSubmit={onSubmit}
                  confirm={responseText}
                  modalContent={modalResponse}
                  log={log}
                  closeModal={closeModal}
                />
              </div> */}


            </Form>
          </Card>
        </div>
      </DesktopDefault>
    </>
  );
}

export default OpeningHours;