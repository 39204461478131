import React from "react";

import DataCombo from "./components/DataCombo/DataCombo";
import Operation from "./components/Operation/Operation";
import SummaryAndDetails from "./components/SummaryAndDetails/SummaryAndDetails";
import DescriptionAndHigh from "./components/DescriptionAndHigh/DescriptionAndHigh";
import Products from "./components/Products/Products";
import Markup from "./components/Markup/Markup";
import Photo from "./components/Photo/Photo";
import Location from "./components//Location/Location";
import Instructions from "./components/Instructions/Instructions";
import SEO from "./components/SEO/SEO"; 

export interface propTransfer {
  handleNext?: any;
  management?: any;
  isEdit?: any;
  editFunction?: any;
}

const ProductCombo: React.FC<propTransfer> = ({
  handleNext,
  management,
  isEdit = false,
  editFunction = () => { }
}: propTransfer) => {

  return (
    <>
      {/* CONTENT */}

      {(() => {
        switch (management) {
          case 1:
            return <DataCombo
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 2:
            return <Operation
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 3:
            return <SummaryAndDetails
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 4:
            return <DescriptionAndHigh
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 5:
            return <Products
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 6:
            return <Markup
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 7:
            return <Photo
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 8:
            return <Location
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 9:
            return <Instructions
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          case 10:
            return <SEO
              action={handleNext}
              isEdit={isEdit}
              editFunction={editFunction}
            />;
          default:
            return null;
        }
      })()}
    </>
  )
}

export default ProductCombo;