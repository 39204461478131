/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../../../../components/Breadcrumb/Breadcrumb";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import DesktopDefault from "../../../../../templates/DesktopDefault";
import EnableCategory from "../../../../AddCategory/components/EnableCategory/EnableCategory";
import AddCategoryTable from "../../../../AddCategory/components/AddCategoryTable/AddCategoryTable";
import LinkProduct from "../../../../AddCategory/components/LinkProduct/LinkProduct";

//import "../AddCategory.scss"
import api from "../../../../../services/api";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";

export interface propEdit {
  categoryInfo?: any;
}

const EditCategory: React.FC<propEdit> = ({ categoryInfo }: propEdit) => {
  //loading
  const [loading, setLoading] = useState<any>(false);
  //loading

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  const [product, setProduct] = useState<any>(null);
  const [categoryList, setCategoryList] = useState<any>([]);
  const [salesChannel, setSalesChannel] = useState<any>([]);
  const [image, setImage] = useState<any>(null);

  const [modalShow, setModalShow] = useState<any>(false);
  const [modalSubmitShow, setModalSubmitShow] = useState<any>(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<any>("Carregando");

  const [editCategory, setEditCategory] = useState<any>(null);

  const onSubmit = (obj: any) => {
    setModalSubmitShow(true);

    obj.categoryImage = image !== null ? image : '';
    
    let aux: any = [];
    for (let i = 0; i < categoryList.length; i++) {
      aux.push({
        /* "productName": categoryList[i].product_name, */
        productCode: categoryList[i].productCode,
      });
    }

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function enableCategory() {
      try {
        const { data } = await api.post(
          "Categories/UpdateCategoriesAsync",
          {
            categorieId: editCategory.categorieId,
            categorieName: obj.name,
            categorieSlug: obj.url,
            categorieDescription: obj.description,
            categorieStatus: obj.category === undefined ? 1 : obj.category,
            categorieChannel:
              obj.channel === undefined
                ? editCategory.categorieChannel
                : obj.channel,
            categoriePublic: 0,
            categorieGroupId: 1,
            channelApp: 0,
            channelSite: 0,
            channelMarketplace: 0,
            channelB2B: 0,
            productsList: aux,
            salesChannel: salesChannel.map((elem: any) => elem.value),
          },
          config
        );

        if (data.status !== 400) {
          setModalLog(data.data.log);
          setModalMessage(data.data.texto);
        }
      } catch (error: any) {}
    }
    enableCategory();
  };

  useEffect(() => {
    const idCat: any = window.location.href.split("?id=")[1];

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function editCategory() {
      try {
        const { data } = await api.get(
          `/Categories/GetCategoriesByIdAsync/${idCat}`,
          config
        );
        if (data.statusCode !== 400) {
          if (data.data.log === 0) {
            setEditCategory(data.data.data);
            setCategoryList(data.data.data.productsList);
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
        }
      }
    }
    editCategory();
  }, []);

  const { control, handleSubmit, setValue } = useForm({});

  const reload = () => {
    if (modalLog === 0) {
      window.location.reload();
    }
  };

  return (
    <DesktopDefault>
      <div className="container-fluid content-edit-category add-categoria">
        <Breadcrumb title={"Editar Categoria"} />

        <div className="mt-4 ">
          <div className="d-flex align-items-center">
            <span
              className="back"
              onClick={() => {
                window.location.href = "/listar-categorias";
              }}
            >
              <FontAwesomeIcon
                icon={["fal", "angle-double-left"]}
                style={{ margin: "0 5px 0 0", fontSize: "16px" }}
              />
              Voltar
            </span>
          </div>
          <h2 className="title" style={{ color: "#707070" }}>
            Editar Categoria{" "}
          </h2>
        </div>
        {editCategory !== null ? (
          <Form
            className="my-4"
            noValidate
            validated={false}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="false"
          >
            {/* enableCategory */}
            <EnableCategory
              control={control}
              product={product}
              isEdit={true}
              setSalesChannel={setSalesChannel}
              infoEdit={editCategory}
              setValue={setValue}
              setImage={setImage}
            />
            {/* enableCategory */}

            <div className="my-5 d-flex justify-content-center">
              <Modal
                className={"modal-large modal-custom modalAuth"}
                show={modalShow}
                onHide={() => {
                  setModalShow(false);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <div className="d-flex justify-content-between w-100 mb-4">
                    <h3>Vincular Produto</h3>
                  </div>
                </Modal.Header>
                <LinkProduct
                  //buscar={buscar}
                  setCategoryList={setCategoryList}
                  setModalShow={setModalShow}
                  categoryList={categoryList}
                />
              </Modal>
              <Button
                onClick={() => {
                  setModalShow(true);
                }}
              >
                Vincular Produto
              </Button>
            </div>

            <AddCategoryTable
              loading={loading}
              categoryList={categoryList}
              setCategoryList={setCategoryList}
              info={categoryInfo}
            />
          </Form>
        ) : (
          <></>
        )}
      </div>

      <Modal
        className="modalAuth"
        show={modalSubmitShow}
        onHide={() => {
          reload();
          setModalLog(null);
          setModalMessage("Carregando");
          setModalSubmitShow(false);
        }}
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          <Modal.Body
            className="modal-body text-center sucess-pos d-flex justify-content-evenly"
            style={{ height: "600px" }}
          >
            {modalLog === null ? (
              <>
                <div className="loading-modal">
                  <div className="load"></div>
                </div>
              </>
            ) : (
              <div>
                {modalLog === 0 ? (
                  <FontAwesomeIcon
                    icon={["fal", "check-circle"]}
                    size="5x"
                    className="text-primary"
                    style={{ fontSize: "7.5em"}}
                  />
                ) : (
                  <FontAwesomeIcon
                    icon={["fal", "times-circle"]}
                    size="5x"
                    className="text-primary"
                    style={{ fontSize: "7.5em"}}
                  />
                )}
              </div>
            )}
            <div>{modalMessage}</div>
            <div className="d-flex justify-content-center pt-3">
              <button
                onClick={() => {
                  reload();
                  setModalLog(null);
                  setModalMessage("Carregando");
                  setModalSubmitShow(false);
                }}
                className="btn btn-primary mx-2 w-25"
              >
                Confirmar
              </button>
            </div>
          </Modal.Body>
        </>
      </Modal>
    </DesktopDefault>
  );
};

export default EditCategory;
