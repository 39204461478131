import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './SessionClosed.scss';

//COMPONENTS

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import FilterSessionClosed from '../components/FilterSessionClosed/FilterSessionClosed';
import TableSessionClosure from '../components/TableSessionClosure/TableSessionClosure';
import DesktopDefault from '../../../templates/DesktopDefault';

function SessionClosed() {
    //const { t } = useTranslation();
    const [sessions, setSessions] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */


    /* Summary */
    // const [totalRecebimentos, setTotalRecebimentos] = useState<any>(0);
    // const [totalPagamentos, setTotalPagamentos] = useState<any>(0);
    // const [totalMargem, setTotalMargem] = useState<any>(0);
    // const [totalNotas, setTotalNotas] = useState<any>(0);
    // const [totalPrejuizo, setTotalPrejuizo] = useState<any>(0);
    // const [verificacao, setVerificacao] = useState<any>(0);
    /* END - Summary */

    const [canRefresh, setCanRefresh] = useState<boolean>(false);

    const hoje = new Date();
    //const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate() - 1); // hoje - 1 dia = ontem
    //const dataPadrao = `${ontem.getDate()}/${ontem.getMonth()+1}/${ontem.getFullYear()}`;

    const lastDate = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate()-2);

    const firstDate = new Date(hoje.getFullYear(), hoje.getMonth(), 1);
    const lastDate1 = (lastDate >= firstDate) ? lastDate : firstDate;

    const dataPadrao = [`${firstDate.getDate()}/${firstDate.getMonth()+1}/${firstDate.getFullYear()}`,`${lastDate1.getDate()}/${lastDate1.getMonth()+1}/${lastDate1.getFullYear()}`];

    /* Filter */
    const [filterSession, setFilterSession] = useState<any>('');
    const [filterDate, setFilterDate] = useState<any>(dataPadrao);
    const [filterType, setFilterType] = useState<any>('');
    const [filterStatus, setFilterStatus] = useState<any>('1');
    const [filterStatusErp, setFilterStatusErp] = useState<any>('1');
    /* END - Filter */

    useEffect(() => {
        if(canRefresh) 
            refreshData();
        else
            setCanRefresh(true);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function refreshData() {
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: any = []
        let endDate: any = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        let today: any = new Date();

        async function listSuppliers() {
            try {
                
                const { data } = await api.post('SessionClosure/GetReportClosedSessions',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "session": filterSession,
                        "status": filterStatus !== '' ? filterStatus : null,
                        "tipo": filterType !== '' ? filterType : null,
                        "statusErp": filterStatusErp !== '' ? filterStatusErp : null,
                        // "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                        // "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                        "dataInicial": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );

                if (data.status !== 400) {
                    setLoading(false);
                    setSessions(data.data.rows);
                    setTotalRows(data.data.rowsCount);

                    // setTotalRecebimentos(data.data.totalRecebimentos);
                    // setTotalPagamentos(data.data.totalPagamentos);
                    // setTotalMargem(data.data.totalMargem);
                    // setTotalNotas(data.data.totalNotas);
                    // setTotalPrejuizo(data.data.totalPrejuizo);
                    // setVerificacao(data.data.verificacao);
                }
            } catch (error: any) {
                setLoading(false);
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listSuppliers();
    }

    async function buscar(session: string, type: any, status: any, statusErp: any, date: any) {
        setPageCount(1);
        setFilterSession(session);
        setFilterDate(date);
        setFilterType(type)
        setFilterStatus(status);
        setFilterStatusErp(statusErp);
        
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('SessionClosure/GetReportClosedSessions',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "session": session,
                    "status": status !== '' ? status : null,
                    "tipo": type !== '' ? type : null,
                    "statusErp": statusErp !== '' ? statusErp : null,
                    // "dataInicial": date.length > 0 ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : '',
                    // "dataFinal": date.length === 1 ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : date.length === 2 ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : '',
                    // "dataInicial": date ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    // "dataFinal": date ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,

                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);
                setSessions(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);

                // setTotalRecebimentos(res.data.data.totalRecebimentos);
                // setTotalPagamentos(res.data.data.totalPagamentos);
                // setTotalMargem(res.data.data.totalMargem);
                // setTotalNotas(res.data.data.totalNotas);
                // setTotalPrejuizo(res.data.data.totalPrejuizo);
                // setVerificacao(res.data.data.verificacao);
            }
        } catch (error: any) {
            setLoading(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
        //console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
        let link = document.createElement('a');
        link.href = blob;//window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
        //}
    };

    async function exportReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: any = []
        let endDate: any = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('SessionClosure/ExportReportClosedSessions',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "session": filterSession,
                    "status": filterStatus !== '' ? filterStatus : null,
                    "tipo": filterType !== '' ? filterType : null,
                    "statusErp": filterStatusErp !== '' ? filterStatusErp : null,
                    // "dataInicial": date.length > 0 ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : '',
                    // "dataFinal": date.length === 1 ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : date.length === 2 ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : '',
                    // "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    // "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataInicial": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate: any = []
        let endDate: any = []

        if (filterDate.length === 1) {
            beginDate = filterDate[0].split('/')
        } else if (filterDate.length === 2) {
            beginDate = filterDate[0].split('/')
            endDate = filterDate[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('SessionClosure/PrintReportClosedSessions',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "session": filterSession,
                    "status": filterStatus !== '' ? filterStatus : null,
                    "tipo": filterType !== '' ? filterType : null,
                    "statusErp": filterStatusErp !== '' ? filterStatusErp : null,
                    // "dataInicial": date.length > 0 ? `${beginDate[0]}-${beginDate[1]}-${beginDate[2]}` : '',
                    // "dataFinal": date.length === 1 ? `${beginDate[0]}-${beginDate[1]}-${parseInt(beginDate[2]) + 1}` : date.length === 2 ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : '',
                    // "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    // "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataInicial": (filterDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (filterDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (filterDate !== null && filterDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    //"orderByField": ""
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard session-closed">
                    <Breadcrumb title={'Financeiro / Fechamento / Pendências de Session'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Pendências de Session</h2>
                    </div>
                    <FilterSessionClosed
                        buscar={buscar}
                        sessions={sessions}
                        error={error}
                        setFilterDate={setFilterDate}
                        setFilterSession={setFilterSession}
                        setFilterStatus={setFilterStatus}
                        setFilterType={setFilterType}
                        dataPadrao={dataPadrao}
                    />
                    {/* <Row className="cards">
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Total Receita
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalRecebimentos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "receipt"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Total Pagamentos
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalPagamentos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "hand-holding-usd"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Total Margem
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalMargem.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "dollar-sign"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Notas Emitidas
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalNotas.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "file-invoice"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Prejuizo Apurado
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalPrejuizo.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "file-invoice"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Verificação
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {verificacao.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "file-invoice"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                    </Row> */}
                    <TableSessionClosure
                        sessions={sessions}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportReport={exportReport}
                        printReport={printReport}
                        loading={loading}
                        refreshData={refreshData}
                        canExportErp={true}
                        sessionClosed={true}
                    />
                </div>
            </DesktopDefault>
        </>
    );
}

export default SessionClosed;