import React, { useState } from "react";
import { Row, Col } from "react-bootstrap";
//import Button from "@restart/ui/esm/Button";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//import SingleCalendar from "../../Calendar/SingleCalendar";

import { ModalBody } from "react-bootstrap";

function ModalImageDriver(props: any) {
    //console.log(props)
    const [modalShow, setModalShow] = useState(false);

    return (
        <div>
            <Modal
                {...props}
                className={
                    props.classModal
                        ? "modal-custom modalAuth " + props.classModal
                        : "modal-custom modalAuth"
                }
                show={modalShow}
                onHide={() => {
                    setModalShow(false);
                    props.closeAction ? props.closeAction() : <></>;
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Header closeButton>
                        <div>
                            <h3>
                                {
                                    props.title ? props.title : ""
                                }
                            </h3>
                        </div>
                    </Modal.Header>
                    <hr />
                </>
                <ModalBody>
                    <div>
                        <h6>Informações do Motorista</h6>
                        <Row>
                            <Col md={8}>
                                <span>NOME</span>
                                <b>{props.obj?.nome}</b>
                            </Col>
                            <Col md={4}>
                                <span>CNH</span>
                                <b>{props.obj?.categoriaCNH}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>TELEFONE</span>
                                <b>{props.obj?.telefone}</b>
                            </Col>
                            <Col>
                                <span>ESTADO</span>
                                <b>{props.obj?.estado}</b>
                            </Col>
                            <Col>
                                <span>CIDADE</span>
                                <b>{props.obj?.cidade}</b>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <span>EMAIL</span>
                                <b>{props.obj?.email}</b>
                            </Col>
                        </Row>
                        <Row className="mx-0">
                            <h6 className="px-0 py-2">Foto do Motorista:</h6>
                            <div className="bg-modal-image" style={{ backgroundImage: `url(${props.obj?.foto})` }}></div>
                        </Row>
                    </div>
                </ModalBody>
            </Modal>

            <div className="d-flex justify-content-center">
                <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setModalShow(true)}
                >
                    <FontAwesomeIcon
                        icon={["fas", "camera-alt"]}
                        size="1x"
                        style={{ marginRight: "10px" }}
                    />
                </span>
            </div>
        </div>
    );
}

export default ModalImageDriver;
