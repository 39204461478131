import React from 'react';
import { Row } from 'react-bootstrap';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
//import GaugeChart from '../../../../components/GaugeChart/GaugeChart';
import ReactSpeedometer, { Transition } from 'react-d3-speedometer';

export interface propInfo {
    info: any;
    loading: any;
    //minValue: any;
    //maxValue: any;
}

const ChartProjection: React.FC<propInfo> = ({
    info, loading//, minValue, maxValue
}) => {
    //const color_primary: any = process.env.REACT_APP_COLOR_PRIMARY;
    //const color_secondary: any = process.env.REACT_APP_COLOR_SECONDARY;

    //const [salesInfo, setSalesInfo] = useState<any>(null);

    // useEffect(() => {
    //     if(info !== null) {

    //         const dataVendas: any = [
    //             ["Descrição", "Share (%)"],
    //             ["Projeção", info.taxaProjecao]
    //         ]

    //         setSalesInfo(dataVendas);
    //     }
    // }, [info])

    function formatValue(cell: any) {
        if(cell && cell !== '' && typeof cell === "number") {
            return cell.toFixed(2).replace(".", ",");
          } else {
            return '0,00';
          }
    }

    return (
        <>
            <Row >

                <div className="table-default dailyreceipt-report-sales-table">
                    <div className="table-container">
                        <div className="d-flex justify-content-between">
                            <h4 className="p-0 m-0 pb-2" style={{ color: "#707070" }}>Projeção</h4>
                        </div>
                        <div className="p-3 d-flex justify-content-center">
                            {info !== null && loading === false
                                ?
                                <> 
                                    {/* vermelho de 0 a 69% , amarelo de 70 a 85% , verde mais claro de 86 a 100% 100% ou mais verde escuro */}
                                    {/* <GaugeChart 
                                        radius={120} 
                                        percent={info.taxaProjecao ? info.taxaProjecao : 0} 
                                        colors={[[0, '#B54848'], [70, '#F6C23E'], [86, '#4ABA58'], [100, '#2F7338']]}
                                        maxPercent={300}
                                    /> */}

                                    <ReactSpeedometer
                                        maxValue={120}
                                        minValue={0}
                                        height={190}
                                        width={290}
                                        value={info.taxaProjecao && typeof info.taxaProjecao === "number" ?  info.taxaProjecao : 0}
                                        needleTransition={Transition.easeQuadIn}
                                        needleTransitionDuration={1000}
                                        needleColor="red"
                                        //startColor="green"
                                        segments={12}
                                        segmentColors={[
                                            "#800000",
                                            "#900000",
                                            "#A00000",
                                            "#B00000",
                                            "#C00000",
                                            "#D00000",
                                            "#E00000",
                                            "#C99300",
                                            "#E8AA00",
                                            "#FFCD00",
                                            "#10A500",
                                            "#16DF00"
                                        ]}
                                        maxSegmentLabels={12}
                                        currentValueText={`${formatValue(info.taxaProjecao)} %`}
                                        //endColor="blue"
                                    />
                                </>
                                :
                                <>
                                    <div className="animated-background row-loading" style={{ height: '300px' }}></div>
                                </>
                            } 
                        </div>
                    </div>
                </div>
            </Row>
        </>

    );
}

export default ChartProjection;