import React, { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Button, Col, Container, Form, InputGroup, Row } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from 'react-i18next';

import RangeCalendar from '../../../../components/Calendar/RangeCalendar';
import GetPromoters from '../../../../components/C2Points/GetPromoters';
import GetAffiliateClient from '../../../../components/C2Points/GetAffiliateClient';
import GetProductsListCod from '../../../../components/C2Points/GetProductsListCod';
import GetAfiliatesType from '../../../../components/C2Points/GetAfiliatesType';
import GetUsersList from '../../../../components/C2Points/GetUsersList';

import './FilterSalesReport.scss';
import i18next from 'i18next';


export interface propFilter {
    buscar: any;
}

const FilterSalesReport: React.FC<propFilter> = ({
    buscar 
}) => {
    const { t } = useTranslation();

    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);

    const [filterType, setFilterType] = useState<any>(0);
    const [filterTypeDate, setFilterTypeDate] = useState<any>(1);
    const [filterDate, setFilterDate] = useState<any>([]);
    const [filterReservationStatus, setFilterReservationStatus] = useState<any>(0);
    const [filterPaymentStatus, setFilterPaymentStatus] = useState<any>(0);
    const [filterBy, setFilterBy] = useState<any>(3);

    const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel);
    console.log(level)

    const {
        control,
        handleSubmit,
        setValue, 
        watch,       
        formState: { errors },
    } = useForm();
    const watchAff:any = watch("affiliate");
    console.log(watchAff)

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if (selectValue[i].id === "rowsPerPage") {
                selectValue[i].value = "10";
            } else {
                selectValue[i].value = "0";
            }
        }

        var today: any = new Date();

        setFilterType(0);
        setFilterTypeDate(1);
        setFilterDate([]);
        setFilterReservationStatus("")
        setFilterPaymentStatus(1);
        setFilterBy(3);
        setValue('channel', 0);
        setValue('promoter', 0);
        setValue('affiliate', 0);
        setValue('user', 0);
        setValue('product', 0);
        buscar(0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`], 1, 1, 0, 0, 0, 0, '', 3);
        //clearActualValues();
    };

    const onSubmit = (data: any) => {
        
        /*
        data.channel = (data.channel?.id) || 1;
        data.user = data.user || 0; */
        data.promoter = data.promoter?.id || 0;
        data.affiliate = data.affiliate || 0;
        data.user = data?.user?.id || 0;
        data.product = data.product?.code || '';

        buscar(filterType, filterTypeDate, filterDate, filterReservationStatus, filterPaymentStatus, data.channel, data.promoter, data.affiliate, data.user, data.product, filterBy);
    }

    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
    }
    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);
    }
    const handleChangeReservationStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterReservationStatus(newValue);
    }
    const handleChangePaymentStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterPaymentStatus(newValue);
    }
    const handleChangeFilterBy = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterBy(newValue);
    }

    useEffect(()=>{
        buscar(filterType, filterTypeDate, filterDate, filterReservationStatus, filterPaymentStatus, filterBy);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [i18next.language])

    // Filtro funcionando

    return (
        <>
            <div className="suppliers-filter d-flex justify-content-center bg-white mt-4 filterSalesReport" style={{ textAlign: "left" }}>
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">{t("salesReport.searchReports")}</span></Accordion.Header>
                        <Accordion.Body className="pt-0">
                            <Container className="p-0" fluid>
                                <Row>
                                    <div>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t("salesReport.type")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeType(e)}
                                                            >
                                                                <option value="0">{t("salesReport.all")}</option>
                                                                <option value="1">Tour</option>
                                                                <option value="2">Transfer</option>
                                                                <option value="4">Ticket</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t("salesReport.dateType")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeTypeDate(e)}
                                                            >
                                                                <option value="0" hidden>{t("salesReport.reservationDate")}</option>
                                                                <option value="1">{t("salesReport.reservationDate")}</option>
                                                                <option value="2">{t("salesReport.operationDate")}</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t("salesReport.date")}</Form.Label>
                                                    <InputGroup hasValidation className="ranger-calendar">
                                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                    </InputGroup>
                                                </Form.Group>

                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t("salesReport.reservationStatus")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeReservationStatus(e)}
                                                            >
                                                                <option value="0">{t("salesReport.all")}</option>
                                                                <option value="1">{t("salesReport.confirmed")}</option>
                                                                <option value="2">{t("salesReport.canceled")}</option>
                                                                <option value="7">{t("salesReport.preReservation")}</option>
                                                                <option value="9">{t("salesReport.nonApprovedPayment")}</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t("salesReport.paymentStatus")}</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangePaymentStatus(e)}
                                                            >
                                                                <option value="0">{t("salesReport.all")}</option>
                                                                <option value="1">{t("salesReport.paid")}</option>
                                                                <option value="2">{t("salesReport.pending")}</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                                {level !== '29' && <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>{t("salesReport.affiliateType")}:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="channel"
                                                        rules={{ required: { value: false, message: t("salesReport.nameError") } }}
                                                        render={({ field }: any) => (
                                                            <>
                                                                <div className="input-select-custom">
                                                                    <GetAfiliatesType
                                                                        propsField={field}
                                                                        propsErrors={errors}
                                                                        propsLabel={t('accordionInfoCompany.promo')}
                                                                    />
                                                                </div>
                                                            </>
                                                        )}
                                                    />
                                                </Form.Group>}
                                            </Row>
                                            <Row>
                                                {level !== '29' && <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>{t("salesReport.promoter")}:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="promoter"
                                                        rules={{ required: { value: false, message: t("salesReport.nameError") } }}
                                                        render={({ field }: any) => (
                                                            <div className="input-select-custom">
                                                                <GetPromoters
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                    propsLabel={t('accordionInfoCompany.promo')}
                                                                    allowAll={true}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>}
                                                {level !== '29' && <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>{t("salesReport.affiliateClient")}:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="affiliate"
                                                        rules={{ required: { value: false, message: t("salesReport.nameError") } }}
                                                        render={({ field }: any) => (
                                                            <div className="input-select-custom">
                                                                <GetAffiliateClient
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                    propsLabel="supplierId" />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>}
                                                {level !== '29' && <Form.Group as={Col} md="2" controlId="validationCustom01">
                                                    <Form.Label>{t("salesReport.user")}:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="user"
                                                        rules={{ required: { value: false, message: t("salesReport.nameError") } }}
                                                        render={({ field }: any) => (
                                                            <GetUsersList
                                                                propsField={field}
                                                                propsErrors={errors}
                                                                propsLabel="" />
                                                        )}
                                                    />
                                                </Form.Group>}
                                                {level !== '29' && <Form.Group as={Col} md="4" controlId="validationCustom01">
                                                    <Form.Label>{t("salesReport.product")}:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="product"
                                                        rules={{ required: { value: false, message: t("salesReport.nameError") } }}
                                                        render={({ field }: any) => (
                                                            <div className="input-select-custom">
                                                                <GetProductsListCod
                                                                    propsField={field}
                                                                    propsErrors={errors}
                                                                />
                                                            </div>
                                                        )}
                                                    />
                                                </Form.Group>}
                                                
                                                <Form.Group as={Col} md="2">
                                                    <Form.Label>{t("salesReport.filterBy")}:</Form.Label>
                                                    <div className="endpoint">
                                                        <InputGroup hasValidation>
                                                            <Form.Select
                                                            autoComplete='off'
                                                                aria-describedby="inputGroupPrepend"
                                                                onChange={(e: any) => handleChangeFilterBy(e)}
                                                            >
                                                                <option value="0" hidden>{t("salesReport.day")}</option>
                                                                <option value="3" selected>{t("salesReport.day")}</option>
                                                                <option value="2" >{t("salesReport.month")}</option>
                                                                <option value="1">{t("salesReport.year")}</option>
                                                            </Form.Select>
                                                        </InputGroup>
                                                    </div>
                                                </Form.Group>
                                            </Row>
                                            <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                                <Col xs lg="12" className="d-flex justify-content-md-end">
                                                    <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={() => { handleClearClick(); setAllowLoadFromFilter(false) }}>{t('partners.partnersfilter.btnClear')}</Button>
                                                    <Button type="submit" className="btn-default text-uppercase" onClick={() => { }}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
}

export default FilterSalesReport;