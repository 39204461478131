import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
//import api from '../../../../services/api';

import React, { useEffect, useState, useRef } from "react";

import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { removeItemCart, removeTotalCart, removeCupomCart } from "../../../../store/cart";

import './CartHeader.scss'
import { useTranslation } from "react-i18next";

function CartHeader() {
    const { t } = useTranslation();
    const cartSIG = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
    const dispatch = useAppDispatch();
    const [numberValidCupons, setNumberValidCupons] = useState<any>(0);

    let totalCart = 0;

    var cartIcon: any = document.getElementById("navbarDropdownCarrinho");
    cartIcon?.addEventListener("mouseover", function (event: any) {
        var elem: any = document.getElementById("authCartDropdown");

        elem.classList.remove("d-none");

    })

    /* Detecta clique fora da div#wrapper para fechar o dropdown de login */
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    var elem: any = document.getElementById("authCartDropdown");
                    if (elem) {
                        elem.classList.remove('active');
                    }
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* //Detecta clique fora da div#wrapper para fechar o dropdown de login\\ */

    function keepDropdownOpen(e: any) {
        var elem: any = document.getElementById("authCartDropdown");

        if (elem && !elem.classList.contains('active') && !e?.target?.classList.contains('close-btn')) {
            elem.classList.remove("d-none");
            elem.classList.add('active');
        }
    }

    function keepDropdownClosed() {
        var elem: any = document.getElementById("authCartDropdown");

        if (elem && elem.classList.contains('active')) {
            elem.classList.remove('active');
        }

        elem.classList.add('d-none');

        /* let timer2 = setTimeout(() => {
            elem.classList.remove('d-none');
        }, 100);

        return () => {
            clearTimeout(timer2);
        }; */
    }

    useEffect(() => {
        var dados: any = [...cartSIG.dados];
        let qtd: any = 0;

        if (cartSIG?.cupom?.type === 3) {

            dados.forEach((cItem: any, index: any) => {
                if (cItem.discountBool === true) {
                    qtd += 1;
                }
            });
            setNumberValidCupons(qtd);

        }
    }, [cartSIG])

    const formatDateString = (date: string) => {
        const dateRegex1 = /^\d{4}-\d{2}-\d{2}$/;
        const dateRegex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2})?$/;

        if (dateRegex1.test(date)) {
            // Se o formato é YYYY-MM-DD
            return date.split('-').reverse().join('/');
        } else if (dateRegex2.test(date)) {
            // Se o formato é YYYY-MM-DDTHH:MM:SS.SSSZ ou YYYY-MM-DDTHH:MM:SS.SSSSSSS-Z
            return date.split('T')[0].split('-').reverse().join('/');
        } else {
            // Se a data não está em nenhum dos formatos esperados
            return date;
        }
    };

    if (cartSIG.dados.length === 0) { //Carrinho vazio
        return (
            <>
                <ul className="navbar-nav ml-auto" id="wrapper" ref={wrapperRef}>
                    <li className="dropdown carrinho nav-item-c2"
                        data-cart="item"
                        onClick={keepDropdownOpen}
                    >
                        <Link
                            className="nav-link text-primary text-center"
                            data-cart="btn"
                            to="#"
                            id="navbarDropdownCarrinho"
                            role="button"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <FontAwesomeIcon
                                icon={["fad", "shopping-cart"]}
                                size="lg"
                                className="mr-2"
                            />
                            <div className="title-cart mr-2">{t("cartHeader.title")}</div>
                            <div className="indicate-number">{cartSIG.dados.length}</div>
                            <div className="d-md-inline-block" id="number-circle">
                                <span
                                    id="numeroItens"
                                    className="badge badge-pill badge-primary text-white"
                                >
                                    { }
                                </span>
                            </div>
                        </Link>
                        <div
                            className="dropdown-menu cart"
                            id="authCartDropdown"
                            aria-labelledby="navbarDropdownCarrinho"
                            data-cart="divMenu"
                            style={{ zIndex: 1040 }}
                        >
                            <div className="titleCart bg-light d-flex justify-content-center align-items-center">
                                {t("cartHeader.emptyCart")}
                            </div>
                        </div>
                    </li>
                </ul>
            </>
        )
    } else {
        return (
            <ul className="navbar-nav ml-auto" id="wrapper" ref={wrapperRef}>
                <li className="dropdown carrinho nav-item-c2"
                    data-cart="item"
                    onClick={keepDropdownOpen}
                >
                    <Link
                        className="nav-link text-primary text-center"
                        data-cart="btn"
                        to="#"
                        id="navbarDropdownCarrinho"
                        role="button"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                    >
                        <FontAwesomeIcon
                            icon={["fad", "shopping-cart"]}
                            size="lg"
                            className="mr-2"
                        />
                        <div className="title-cart mr-2">{t("cartHeader.title")}</div>
                        <div className="indicate-number">{cartSIG.dados.length}</div>
                        <div className="d-md-inline-block" id="number-circle">
                            <span
                                id="numeroItens"
                                className="badge badge-pill badge-primary text-white"
                            >
                                { }
                            </span>
                        </div>
                    </Link>
                    <div
                        className="dropdown-menu cart"
                        id="authCartDropdown"
                        aria-labelledby="navbarDropdownCarrinho"
                        data-cart="divMenu"
                        style={{ zIndex: 1040 }}
                    >
                        <div className="titleCart bg-light d-flex space-between align-items-center">
                            <p><strong>{t("cartHeader.myCart")},</strong> {cartSIG.dados.length} {t("cartHeader.itens")}</p> <b className="close-btn" style={{ fontSize: "1.375rem", cursor: "pointer" }} onClick={keepDropdownClosed}>×</b>
                        </div>
                        <div className="messages d-none">
                            {t("cartHeader.addedToCart")}
                        </div>
                        <div className="listItens">
                            {cartSIG.dados.length >= 1
                                ? cartSIG.dados.map((cItem: any, index: any) => {

                                    let priceProduct = cItem.sellingType === 1
                                        ?
                                        (Number(cItem.priceGlobalPeople))
                                        : (
                                            (Number(cItem.adults) * Number(cItem.priceAdults))
                                            + (Number(cItem.childs) * Number(cItem.priceChilds))
                                            + (Number(cItem.infants) * Number(cItem.priceInfants))
                                            + (Number(cItem.elders) * Number(cItem.priceElders))
                                            + (Number(cItem.student) * Number(cItem.priceStudent))
                                            + (Number(cItem.globalPeople)) * (Number(cItem.priceGlobalPeople))
                                        );

                                    if (cItem.isCombo) {
                                        priceProduct = cItem.price;
                                    }

                                    totalCart = Number(totalCart) + (priceProduct - (priceProduct * (cItem.discount / 100)));

                                    return (
                                        <div className="lineItemCart border-bottom row mb-2 m-0" key={index}>
                                            <div className="col-4 p-0" style={{ position: "relative" }}>
                                                <div className="controlImage">
                                                    <img
                                                        src={cItem.productType === 1 ? cItem.imagesBaseUrl + cItem.imgCart : cItem.productType === 4 ? cItem.imagesBaseUrl + cItem.imgCart : `${process.env.REACT_APP_SERVER_URL_IMG}/` + cItem.imgCart}
                                                        className="card-img"
                                                        alt="..."
                                                    />
                                                </div>

                                            </div>
                                            <div className="col-8 controlInfo">

                                                <div className="infoCart">
                                                    <h6 title={`${cItem.productName}`} className="titleItem">{cItem.productName}</h6>
                                                    <div className="d-flex space-between">
                                                        {
                                                        !cItem.isCombo &&
                                                        <>
                                                        
                                                        <div>
                                                            {t("cartHeader.date")} {formatDateString(cItem.date)}
                                                        </div>
                                                        <div>
                                                            {
                                                                cItem.sellingType === 1
                                                                    ?
                                                                    <>
                                                                        {cItem.totalPeople}{t("cartHeader.person")}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        {(Number(cItem.adults) + Number(cItem.childs) + Number(cItem.infants) + Number(cItem.elders) + Number(cItem.student)) + t("cartHeader.person")}
                                                                    </>

                                                            }
                                                            {
                                                                (Number(cItem.adults) + Number(cItem.childs) + Number(cItem.infants) + Number(cItem.elders) + Number(cItem.student)) > 1 || cItem.globalPeople > 1 || cItem.totalPeople > 1
                                                                    ?
                                                                    <>
                                                                        {'s'}
                                                                    </>
                                                                    :
                                                                    <></>

                                                            }


                                                        </div>
                                                        </>
                                }
                                                    </div>
                                                </div>
                                                <div className="priceControl d-flex space-between">
                                                    <div className="d-flex">
                                                        <small>R$</small>{" "}<div className="priceItem ml-1">{(priceProduct - (priceProduct * (cItem.discount / 100))).toFixed(2).split(".").join(",")}</div>
                                                    </div>
                                                    <div>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "trash-alt"]}
                                                            style={{ cursor: 'pointer' }}
                                                            className="text-primary p-1"
                                                            size="lg"
                                                            onClick={() => { dispatch(removeItemCart(cItem)) }}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    )
                                })
                                :
                                <></>
                            }
                        </div>
                        <div className="subTotalControl bg-light ">
                            {
                                cartSIG?.cupom?.type === 2
                                    ?
                                    <>
                                        <div className="bg-cupom-value d-flex justify-content-between">
                                            <small className="d-flex space-between align-items-center">
                                                <p className="mb-0">{cartSIG.cupom.codeName}</p>
                                            </small>
                                            <small className="text-right">
                                                <span className="mb-0">
                                                    {cartSIG.cupom.value.toFixed(2).split(".").join(",")}
                                                </span>
                                                <span
                                                    className="exclude-cupom"
                                                    onClick={() => dispatch(removeCupomCart())}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={["fal", "times"]}
                                                        size="1x"
                                                        style={{ marginLeft: "20px" }}
                                                    />
                                                </span>
                                            </small>
                                        </div>
                                        <div className="bg-cart-price-total pt-0">
                                            <div className="d-flex space-between align-items-center"><p>{t("cartHeader.subtotal")}</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG?.totalCart)?.toFixed(2).split(".").join(",")}</p></div></div>
                                        </div>
                                    </>
                                    :
                                    cartSIG?.cupom?.type === 3
                                        ?
                                        <>
                                            <div className="bg-cupom-value d-flex justify-content-between">
                                                <small className="d-flex space-between align-items-center">
                                                    <p className="mb-0">{cartSIG.cupom.codeName}</p>
                                                </small>
                                                <small className="text-right">
                                                    {numberValidCupons === 0
                                                        ?
                                                        <span className="mb-0">
                                                            {`${numberValidCupons} ${t("cartHeader.validCoupons")}`}
                                                        </span>
                                                        :
                                                        <span className="mb-0">
                                                            {`${cartSIG.cupom.value.toFixed(2).split(".").join(",")} X ${numberValidCupons} ${t("cartHeader.validCoupons")}`}
                                                        </span>
                                                    }
                                                    <span
                                                        className="exclude-cupom"
                                                        onClick={() => dispatch(removeCupomCart())}
                                                    >
                                                        <FontAwesomeIcon
                                                            icon={["fal", "times"]}
                                                            size="1x"
                                                            style={{ marginLeft: "20px" }}
                                                        />
                                                    </span>
                                                </small>
                                            </div>
                                            <div className="bg-cart-price-total pt-0">
                                                <div className="d-flex space-between align-items-center"><p>{t("cartHeader.subtotal")}</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG?.totalCart - (cartSIG.cupom.value * numberValidCupons))?.toFixed(2).split(".").join(",")}</p></div></div>
                                            </div>
                                        </>
                                        :
                                        cartSIG?.cupom?.type === 4
                                            ?
                                            <>
                                                <small className="d-flex space-between align-items-center">
                                                    <p className="mb-0">{cartSIG.cupom.codeName}</p>
                                                    {/* <p className="mb-0">{cartSIG.cupom.value}%</p> */}
                                                    <small className="text-right m-0 d-flex align-items-center">
                                                        <span className="mb-0">{cartSIG.cupom.value}%</span>
                                                        <span
                                                            className="exclude-cupom"
                                                            onClick={() => dispatch(removeCupomCart())}
                                                        >
                                                            <FontAwesomeIcon
                                                                icon={["fal", "times"]}
                                                                size="1x"
                                                                style={{ marginLeft: "10px" }}
                                                            />
                                                        </span>
                                                    </small>
                                                </small>
                                                <div className="bg-cart-price-total pt-0">
                                                    <div className="d-flex space-between align-items-center"><p>{t("cartHeader.subtotal")}</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG?.totalCart)?.toFixed(2).split(".").join(",")}</p></div></div>
                                                </div>
                                            </>
                                            :
                                            <div className="d-flex space-between align-items-center"><p>{t("cartHeader.subtotal")}</p> <div className="d-flex"><small>R$</small> <p className="priceSubtotal ml-1">{(cartSIG?.totalCart)?.toFixed(2).split(".").join(",")}</p></div></div>

                            }

                        </div>
                        <div className="p-3 bg-primary-dark" >
                            <div className="d-flex space-between">
                                <Button
                                    type="button"
                                    className="btn-cart"
                                    variant="outline-light"
                                    onClick={() => { dispatch(removeTotalCart(cartSIG.dados)) }}
                                >
                                    {t("cartHeader.clear")}
                                </Button>

                                <Link
                                    to="/checkout"
                                    className="btn btn-primary btn-cart"
                                >
                                    {t("cartHeader.buyNow")}
                                </Link>
                            </div>
                            {/* <div className="contationControl">
                                <Link
                                    to="/quotation"
                                    className="anchor-quotation"
                                >
                                    <FontAwesomeIcon
                                        icon={["fal", "envelope"]}
                                        className="text-white"
                                        size="sm"
                                    />
                                    Fazer cotação
                                </Link>
                            </div> */}
                        </div>

                    </div>
                </li>
            </ul>
        )
    }
}

export default CartHeader;