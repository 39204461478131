import React, {  } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { Dropdown } from "react-bootstrap";

import BootstrapTable from "react-bootstrap-table-next";
import Pagination from "../../../../components/Pagination/Pagination";

import { useTranslation } from "react-i18next";

import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import "./ResourceToAssociateTable.scss";

export interface propTable {
  resourceToAssociate: any;
  loading: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
}

const ResourceToAssociateTable: React.FC<propTable> = ({
  resourceToAssociate,
  loading,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
}) => {
  const { t } = useTranslation();

  const columns = [
    { dataField: "generic1", text: "Logo" },
    { dataField: "generic2", text: "Empresa" },
    { dataField: "generic3", text: "Cidade" },
    { dataField: "generic4", text: "Estado" },
    { dataField: "generic5", text: "Telefone" },
    { dataField: "generic6", text: "Email"},
    { dataField: "generic7", text: "Tipo"},
    { dataField: "generic8", text: "Associar"},
   
  ];

  // const [, setEditClick] = useState<any>(false);
  // const [, setDetailsModal] = useState<any>(false);
  // const [, setCurrentResourceToAssociate] = useState<any>();

  // function seeDetailsEdit(cell: any) {
  //   setEditClick(true);
  //   setCurrentResourceToAssociate(cell);
  //   setDetailsModal(true);
  // }

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    );
  }

  const loadingColumns = [
    {
      dataField: "resourceToAssociate",
      text: "Carregando fornecedores",
      formatter: loadingFunc,
    },
  ];

  const loadingResourceToAssociate = [
    { resourceToAssociate: "" },
    { resourceToAssociate: "" },
    { resourceToAssociate: "" },
    { resourceToAssociate: "" },
    { resourceToAssociate: "" },
  ];
  /* [END] loading table */

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "resourceToAssociate", text: "Fornecedor" }];

  const notFoundResourceToAssociate = [{ resources: "Nenhum fornecedor encontrado" }];
  /* [END] In case of empty table*/

  // formatter de status
  // function addStatus(cell: any, row: any) {
  //   return (
  //     <div
  //       className={
  //         cell === "Em análise"
  //           ? "bg-analise"
  //           : cell === "Ativo"
  //           ? "bg-ativo "
  //           : cell === "Inativo"
  //           ? "bg-inativo"
  //           : ""
  //       }
  //     >
  //       {cell}
  //     </div>
  //   );
  // }

  // const rowClasses = (row: any, rowIndex: any) => {
  //   if (row.status === "Em Análise") {
  //     return "border-analise";
  //   } else if (row.status === "Ativo") {
  //     return "border-ativo";
  //   } else {
  //     return "border-inativo";
  //   }
  // };

  //botao de ação
  // function addActionButton(cell: any, row: any) {
  //   return (
  //     <>
  //       <div className="d-flex justify-content-center">
  //         <Dropdown drop="start">
  //           <Dropdown.Toggle variant="light ">
  //             <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
  //           </Dropdown.Toggle>

  //           <Dropdown.Menu>
  //             {/* <Dropdown.Item eventKey="1" disabled={true}>
  //               <FontAwesomeIcon
  //                 icon={["fal", "comment-alt-dollar"]}
  //                 className="mr-2"
  //               />{" "}
  //               {t("suppliersTable.account")}
  //             </Dropdown.Item> */}

  //             <Dropdown.Item
  //               eventKey="2"
  //               onClick={() => {
  //                 seeDetailsEdit(cell);
  //               }}
  //             >
                
  //               <FontAwesomeIcon icon={["fal", "edit"]} className="mr-2" />{" "}
  //               {t("reservations.table.dropdown.edit")}{" "}
                
  //             </Dropdown.Item>

  //             <Dropdown.Item eventKey="3" disabled={true}>
  //               <FontAwesomeIcon icon={["fal", "circle"]} className="mr-2" />{" "}
  //               {t("suppliersTable.status")}
  //             </Dropdown.Item>

  //             {/* <Dropdown.Item eventKey="5" disabled={true}>
  //               <FontAwesomeIcon
  //                 icon={["fal", "file-contract"]}
  //                 className="mr-2"
  //               />{" "}
  //               {t("suppliersTable.contract")}
  //             </Dropdown.Item> */}

  //             <Dropdown.Item
  //               eventKey="4"
  //               disabled={true}
  //               className="text-danger remove"
  //             >
  //               <FontAwesomeIcon icon={["fal", "trash-alt"]} className="mr-2" />{" "}
  //               {t("suppliersTable.remove")}
  //             </Dropdown.Item>
  //           </Dropdown.Menu>
  //         </Dropdown>
  //       </div>
  //     </>
  //   );
  // }

  if (resourceToAssociate !== null) {
    return (
      <>
        <div className="table-default resourceToAssociate-table ">
          <div className="table-container">
          <div className="table-title">
              <h3 className="">{t("Fornecedores")}</h3>
            </div>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={resourceToAssociate}
                hover={true}
                columns={columns}
                striped={true}                
              />

              <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              />
            
          </div>
        </div>
      </>
    );
  } else if (resourceToAssociate === null && loading === true) {
    return (
      <>
        <div className="table-default resourceToAssociate-table loading">
          <div className="table-container">
            
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingResourceToAssociate}
                hover={true}
                columns={loadingColumns}
                striped={true}
              />

              <Pagination
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                selectNumberRows="yes"
              />
            
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default resourceToAssociate-table loading not-found">
          <div className="table-container">
           
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={notFoundResourceToAssociate}
                hover={true}
                columns={notFoundColumns}
                striped={true}
              />
           
          </div>
        </div>
      </>
    );
  }
};

export default ResourceToAssociateTable;
