/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

import api from "../../../services/api";

import InputRange from "react-input-range";

import 'react-input-range/lib/css/index.css';
//import SetTransfersItem from "../../../infra/Transfers/SetTransfersItem";

import "./FilterTransfer.scss";

function FilterTransfers(props: any) {
    const [t] = useTranslation();
    const [filterVehicle, setFilterVehicle] = useState<any>([]);
    const [filterService, setFilterService] = useState<any>([]);
    const [filterCategory, setFilterCategory] = useState<any>([]);

    const [selectedFilterVehicle, setSelectedFilterVehicle] = useState<any>([]);
    const [selectedFilterService, setSelectedFilterService] = useState<any>([]);
    const [selectedFilterCategory, setSelectedFilterCategory] = useState<any>([]);

    const [minValue, setMinValue] = useState<any>(0);
    const [maxValue, setMaxValue] = useState<any>(1000);
    const [value, setValue] = useState<any>({ min: minValue, max: maxValue });

    function handleSelectedFilterVehicle(elem: any) { ///cria o array dis checkbox selecionados para veículos
        var aux: any = []

        if (selectedFilterVehicle.includes(elem) === true) {
            for (let i = 0; i < selectedFilterVehicle.length; i++) {
                if (selectedFilterVehicle[i] !== elem) {
                    aux.push(selectedFilterVehicle[i]);
                }
            }
        } else {
            aux = [...selectedFilterVehicle, elem];
        }

        setSelectedFilterVehicle(aux);
    }

    function handleSelectedFilterService(elem: any) {   ///cria o array dis checkbox selecionados para serviços
        var aux: any = []

        if (selectedFilterService.includes(elem) === true) {
            for (let i = 0; i < selectedFilterService.length; i++) {
                if (selectedFilterService[i] !== elem) {
                    aux.push(selectedFilterService[i]);
                }
            }
        } else {
            aux = [...selectedFilterService, elem];
        }

        setSelectedFilterService(aux);
    }

    function handleSelectedFilterCategory(elem: any) {  ///cria o array dis checkbox selecionados para categoria
        var aux: any = []

        if (selectedFilterCategory.includes(elem) === true) {
            for (let i = 0; i < selectedFilterCategory.length; i++) {
                if (selectedFilterCategory[i] !== elem) {
                    aux.push(selectedFilterCategory[i]);
                }
            }
        } else {
            aux = [...selectedFilterCategory, elem];
        }

        setSelectedFilterCategory(aux);
    }

    function handleRangeChange(e: any) {
        setValue(e);
    }


    useEffect(() => {
        const TransfersRender = async () => {
            try {
                const res = await api.post(
                    `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchTransfersAsync`,
                    {
                        "lang": "BR",
                        "dateTransfer": `${props.transferItem.date.split('/').reverse().join('-')}`,
                        "timeTransfer": `${props.transferItem.time}`,
                        "numPeople": `${props.transferItem.numPeople}`,
                        "origemLng": `${props.transferItem.lngOrigem}`,
                        "origemLat": `${props.transferItem.latOrigem}`,
                        "destinoLng": `${props.transferItem.lngDestino}`,
                        "destinoLat": `${props.transferItem.latDestino}`,
                        "transferVehicleType": selectedFilterVehicle.length > 0 ? selectedFilterVehicle : '',
                        "transferServiceType": selectedFilterService.length > 0 ? selectedFilterService : '',
                        "vehicleCategory": selectedFilterCategory.length > 0 ? selectedFilterCategory : '',
                        "priceStart": value.min,
                        "priceEnd": value.max
                    }
                );
                if (res.status !== 400) {
                    if (res.data.statusCode === 200) {
                        props.setPagination(1);
                        props.setRowsPerPage(10);
                        props.setTotalRows(res.data.data.transferList.length);
                        props.setTransfers(res.data.data.transferList);
                    } else {
                        props.setTransfers(null);
                    }
                }
            } catch (error) { }
        };
        TransfersRender();
    }, [selectedFilterVehicle, selectedFilterService, selectedFilterCategory]);

    let auxValue: any = [];

    /* Correção infinity */
    if (value.min === Infinity && value.max === -Infinity) {
        setMinValue(value.min = 0);
        setMaxValue(value.max = 1000);
    }
    /* Correção infinity */

    useEffect(() => {
        let auxVehicle: any = [];
        let auxService: any = [];
        let auxCategory: any = [];
        //let auxValue: any = [];

        for (let j = 0; j < props.transfers?.length; j++) {
            //Vehicles
            if (props.transfers[j].transferVehicleType) {
                if (!auxVehicle.includes(props.transfers[j].transferVehicleType)) {
                    auxVehicle.push(props.transfers[j].transferVehicleType)
                }
            }

            //Services
            if (props.transfers[j].transferServiceType) {
                if (!auxService.includes(props.transfers[j].transferServiceType)) {
                    auxService.push(props.transfers[j].transferServiceType)
                }
            }

            //Category
            if (props.transfers[j].vehicleCategoryDetails.category) {
                if (!auxCategory.includes(props.transfers[j].vehicleCategoryDetails.category)) {
                    auxCategory.push(props.transfers[j].vehicleCategoryDetails.category)
                }
            }

            //Value Min and Máx
            if (props.transfers[j].preco) {
                auxValue.push(props.transfers[j].preco)
            }
        }

        /* /////
        props.transfers.forEach((element: any) => {
        });
        ///// */

        if (filterVehicle.length === 0) { ///impede os checkbox renderizarem denovo
            setFilterVehicle(auxVehicle);
            setFilterService(auxService);
            setFilterCategory(auxCategory);
            setMinValue(value.min = Math.floor(Math.min(...auxValue)));
            setMaxValue(value.max = Math.ceil(Math.max(...auxValue)));
        }
    }, [auxValue, /*  */props.transfers, value, filterVehicle.length]);

    /* Botão Filtrar Preço */
    async function handlePrice() {
        try {
            const price = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchTransfersAsync`,
                    {
                        "lang": "BR",
                        "dateTransfer": `${props.transferItem.date.split('/').reverse().join('-')}`,
                        "timeTransfer": `${props.transferItem.time}`,
                        "numPeople": `${props.transferItem.numPeople}`,
                        "origemLng": `${props.transferItem.lngOrigem}`,
                        "origemLat": `${props.transferItem.latOrigem}`,
                        "destinoLng": `${props.transferItem.lngDestino}`,
                        "destinoLat": `${props.transferItem.latDestino}`,
                        "transferVehicleType": selectedFilterVehicle.length > 0 ? selectedFilterVehicle : '',
                        "transferServiceType": selectedFilterService.length > 0 ? selectedFilterService : '',
                        "vehicleCategory": selectedFilterCategory.length > 0 ? selectedFilterCategory : '',
                        "priceStart": value.min,
                        "priceEnd": value.max
                    }
            );
            if (price.status !== 400) {
                if (price.data.statusCode === 200) {
                    props.setPagination(1);
                        props.setRowsPerPage(10);
                        props.setTotalRows(price.data.data.transferList.length);
                    props.setTransfers(price.data.data.transferList);
                } else {
                    props.setTransfers(null);
                }
            }
        } catch (error) {
        }
    }
    /* END - Botão Filtrar Preço */

   /*  async function handleFilter() {
        try {
            const res = await api.post(
                `${process.env.REACT_APP_SERVER_URL_API}/Products/FetchTransfersAsync`,
                {
                    "lang": "BR",
                    "dateTransfer": `${props.transferItem.date.split('/').reverse().join('-')}`,
                    "timeTransfer": `${props.transferItem.time}`,
                    "numPeople": `${props.transferItem.numPeople}`,
                    "origemLng": `${props.transferItem.lngOrigem}`,
                    "origemLat": `${props.transferItem.latOrigem}`,
                    "destinoLng": `${props.transferItem.lngDestino}`,
                    "destinoLat": `${props.transferItem.latDestino}`,
                    "transferVehicleType": selectedFilterVehicle.length > 0 ? selectedFilterVehicle : '',
                    "transferServiceType": selectedFilterService.length > 0 ? selectedFilterService : '',
                    "vehicleCategory": selectedFilterCategory.length > 0 ? selectedFilterCategory : '',
                    "priceStart": value.min,
                    "priceEnd": value.max
                }
            );
            if (res.status !== 400) {
                if (res.data.statusCode === 200) {
                    props.setTransfers(res.data.data.transferList);
                } else {
                    props.setTransfers(null);
                }
            }
        } catch (error) { }
    } */

    return (
        <>
            <div style={{ margin: "5px" }}>
                <div className="mt-4 mb-5">
                    <h5 className="pb-3">{t("transfer.price")}</h5>
                    <div className="mt-4 mb-5">
                        <InputRange
                            minValue={minValue}
                            maxValue={maxValue}
                            value={value}
                            step={1}
                            onChange={handleRangeChange}
                            formatLabel={value => `R$${value}`}
                        />
                        <div style={{ marginTop: "30px" }}>
                            <button onClick={handlePrice} className="btn btn-primary" style={{ borderRadius: "0", width: "100%" }}>Filtrar</button>
                        </div>
                    </div>
                </div>
                <div className="mt-4 mb-5">
                    <h5>{t("transfer.vehicleType")}</h5>
                    <div id="type-vehicles" className="d-flex flex-column">
                        {filterVehicle.map((filter: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="check-cat">
                                        <input autoComplete='off' type="checkbox" name="Arte" id={`vehicle-${index}`} onClick={() => { handleSelectedFilterVehicle(filter) }} />
                                        <label htmlFor={`vehicle-${index}`}>
                                            {filter}
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="mt-4 mb-5">
                    <h5>{t("transfer.kindOfService")}</h5>
                    <div className="d-flex flex-column">
                        {filterService.map((filter: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="check-cat">
                                        <input autoComplete='off' type="checkbox" name="Arte" id={`service-${index}`} onClick={() => { handleSelectedFilterService(filter) }} />
                                        <label htmlFor={`service-${index}`}>
                                            {filter}
                                        </label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <div className="mt-4 mb-5">
                    <h5>{t("transfer.categoryType")}</h5>
                    <div className="d-flex flex-column">
                        {filterCategory.map((filter: any, index: any) => {
                            return (
                                <div key={index}>
                                    <div className="check-cat">
                                        <input autoComplete='off' type="checkbox" name="Arte" id={`category-${index}`} onClick={() => { handleSelectedFilterCategory(filter) }} />
                                        <label htmlFor={`category-${index}`}>{filter}</label>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </div>
        </>
    );
}

export default FilterTransfers;
