/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef, Key } from 'react';
import api from "../../../../services/api";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import { Row, Col, Badge, Dropdown, Button, OverlayTrigger, Tooltip, Overlay } from "react-bootstrap";
import InputGroup from 'react-bootstrap/InputGroup';
import DoubleCalendar from "../../../../components/Calendar/DoubleCalendar";
import DoubleCalendarTicket from "../../../../components/Calendar/DoubleCalendarTicket";
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import NumberOfPeople from '../NumberOfPeople/NumberOfPeople';
import InputMask from "react-input-mask";
import './DetailsReservation.scss';
import GetPickupList from '../../../../components/C2Points/GetPickupList';
import SingleCalendar from '../../../../components/Calendar/SingleCalendar';
import GetAgents from '../../../../components/C2Points/GetAgents';
import GetAgentsDetails from '../../../../components/C2Points/GetAgentsDetails';
import GetPickupLocation from '../../../../components/C2Points/GetPickupLocation';
import GetPickups from '../../../../components/C2Points/GetPickups';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';
import DropdownHours from '../../../../components/DropdownHours/DropdownHours';
import DropdownHoursFree from '../../../../components/DropdownHoursFree/DropdownHoursFree';

export interface propInfo {
  id: any,
  setDetailsModal: any,
  action: any,
  editClick: any;
  setCancelReservationObj: any;
  getReciboEmail: any;
  setModalErrorShow: any;
};

const DetailsReservation: React.FC<propInfo> = ({
  id, setDetailsModal, action, editClick = false, setCancelReservationObj, getReciboEmail, setModalErrorShow,
}: propInfo) => {
  const { t } = useTranslation();

  const [item, setItem] = useState<any>(null);
  //const [texts, setTexts] = useState<any>('Pesquisando');
  const [resetTimer, setResetTimer] = useState<any>(1);
  const [editProfile, setEditProfile] = useState<boolean>(false);
  const [editProfileDefault, setEditProfileDefault] = useState<boolean>(false);
  //const [allowCalendar, setAllowCalendar] = useState<boolean>(false);
  const [name, setName] = useState<any>();
  const [cellphone, setCellphone] = useState<any>();
  const [email, setEmail] = useState<any>();
  const [cia, setCia] = useState<any>();
  const [voo, setVoo] = useState<any>();

  const [resellerReference, setresellerReference] = useState<any>();
  //const [passageiro, setPassageiro] = useState<any>();
  const [privMaxNumPeople, setPrivMaxNumPeople] = useState<any>(null);
  const [hour, setHour] = useState<any>();
  const [date, setDate] = useState<any>('');
  const [dateTour, setDateTour] = useState<any>([]);
  const [internalNotesItem, setInternalNotesItem] = useState<any>();
  const [pickup, setPickup] = useState<any>(undefined);
  const [pickupListId, setPickupListId] = useState<any>(null);
  const [tourOptions, setTourOptions] = useState<any>([]);

  const [editSuccess, setEditSuccess] = useState<any>(false);
  const [currentEditResponse, setCurrentEditResponse] = useState<any>(null);
  const [editErrorText, setEditErrorText] = useState<any>('');

  const [passengers, setPassengers] = useState<any>(undefined);
  const [showPassengersDetails, setShowPassengersDetails] = useState(false);
  const target = useRef(null);
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>('');
  const [status, setStatus] = useState<any>(null);

  const [agents, setAgents] = useState<any>(null);
  const [lang, setLang] = useState<string>("BR");

  useEffect(() => {
    if (i18next.language === "pt-BR" || i18next.language === "pt") {
      setLang("BR")
    } else if (i18next.language === "en") {
      setLang("EN")
    } else {
      setLang("ES")
    }
  }, [i18next.language]);

  const level:any = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)

  useEffect(() => {
    if ((editClick === true && item !== null)) {
      setEditProfile(true)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tourOptions])

  const [loading, setLoading] = useState<any>(false);

  const validated = false;

  useEffect(() => {
    let timer5 = setTimeout(() => setResetTimer(resetTimer + 1), 2000);

    return () => {
      clearTimeout(timer5);
    };
  }, [resetTimer])


  useEffect(() => {
    const getDetails = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const res = await api.get(`/NewReservations/GetNewReservationsByIdAsync/${id}`, config);
        if (res.status !== 400) {
          setItem(res.data.data)
          setEditProfile(editClick === true && res.data.data.productType === 2)
          if (res.data.data === null) {
            setDetailsModal(false);
            setModalErrorShow(true);

          }
        }
      } catch (error: any) {
        setDetailsModal(false);
        setModalErrorShow(true);
        setStatus(400);

      }
    };
    if (id !== null) {
      getDetails();
    }

  }, [id]);

  useEffect(() => {
    let idChannel: number = 1;

    if (item !== null) {
      var language = (item.idioma === 'Inglês') ? 2 : (item.idioma === 'Espanhol') ? 3 : 1;
      setValue('language', language)
      setName(item.nome);
      setCellphone(item.telefone);
      setEmail(item.email);
      setVoo(item.voo);
      setCia(item.cia);
      //setPassageiro(item.quantidadePax);
      setDate(item.startTimeLocal.split("T")[0]);
      setHour(convertDate(item.startTimeLocal, true));
      setPassengers({
        "adult": item.quantityReservation.adultSale,
        "child": item.quantityReservation.childSale,
        "infant": item.quantityReservation.infantSale,
        "elder": item.quantityReservation.elderSale,
        "student": item.quantityReservation.studentSale,
        "globalPeople": item.quantityReservation.privateSale
      });
      setInternalNotesItem(item.internalNotesItem);
      setresellerReference(item.resellerReference);

      for (var i: any = 0; i < item.pickupListByOrderDTO?.length; i++) {
        if (item.nomePickup === item.pickupListByOrderDTO[i].nomePickup) {
          setPickup(item.pickupListByOrderDTO[i].idPickupLocation);
        }
      }

      const endP = item.productType === 1 ? `/Products/FetchSingleTourAsync` : `/Products/FetchSingleTicketAsync`;

      const getProdModCode = async () => {
        try {
          const { data } = await api.post(
            endP,
            {
              "productCode": item.productCode,
              "lang": lang,
              "idCanal": idChannel//localStorage.getItem('idCanal') || 1
            }
          );
          if (data.status !== 400) {
            setTourOptions(data.data.modalities);
            setPickupListId(data.data.modalities.filter((e: any) => parseInt(e.id) === parseInt(item.idModalidade))[0].pickupListId);
            setPrivMaxNumPeople(data.data.maxNumPeople)
            //setAllowCalendar(true)
          }
        } catch (error) { }
      }
      const getIdChannel = async () => {
        try {
          const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/${item.source.toLowerCase()}`);
          if (data.statusCode === 200) {
            idChannel = data.data.data;
            if (item.producType !== 2) {
              getProdModCode();
            } 
          }
        } catch (error) { }
      }
      getIdChannel();
    }
  }, [item])

  useEffect(() => {
    var aux: any = [];
    
    if (tourOptions !== null) {

      for (var i = 0; i < tourOptions.length; i++) {
        /*  */
        aux.push({
          time: undefined,
          adult: 0,
          child: 0,
          infant: 0,
        })
      }
    }
    setDateTour(() => aux);
  }, [tourOptions])

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any, isHour: any) {
    // Cria uma nova data ajustada para o fuso horário local
    // anterior: var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);
    var newDate = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000);
    
    //var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours/*  - offset */);
    if (isHour === true) {
      return newDate.toLocaleString().split(' ')[1];
    } else {
      return newDate.toLocaleString('pt-BR').split(' ')[0];
    }
  }

  
  function convertDate(cell: any, isHour: any = false) {
    var date = convertUTCDateToLocalDate(new Date(cell), false);
    var hours = convertUTCDateToLocalDate(new Date(cell), true);
    if (isHour === true) {
      return (
        <>
          {hours}
        </>
      );
    } else {
      return (
        <>
          {date.replaceAll(",", "")}
        </>
      );
    }
  }
  /*converte a data UTC para a data local do usuário*/

  function changePriceDateTour(obj: any) {
    setDateTour(() => obj);
    setDate(obj[0].data);
  }

  function getVoucher(idItem: any) {
    // setCancelamentoModal(true);

    const getVou = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const { data } = await api.post(`${process.env.REACT_APP_SERVER_URL_API}/checkout/PdfSecondWay/${idItem}`, {

        }, config);
        if (data.status !== 400) {
          if (data.data.log === 0 || data.data.log === '0') {

            const link = document.createElement('a');
            link.href = `data:${data.data.data.voucherFile.contentType};base64, ${data.data.data.voucherFile.content}`;
            link.setAttribute(
              'download',
              data.data.data.voucherFile.fileName,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // // Clean up and remove the link
            link?.parentNode?.removeChild(link);
          } else if (data.data.log === 1 || data.data.log === '1') {
            alert(data.data.data.texto)
          }
        }
      } catch (error: any) {
      }
    };

    getVou();

  }

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    formState: { errors },
  } = useForm();

  const onSubmit = (res: any) => {
    res.name = name;
    res.cellphone = cellphone;
    res.email = email;
    res.cia = cia;
    res.voo = voo;
    res.internalNotesItem = internalNotesItem;
    res.resellerReference = resellerReference;
    res.resellerName = agents === null ? item?.resellerName : agents?.compFantasia;
    res.agentCode = agents?.supplierIdentity;

    if (date === '') {
      if (item.productType === 2) {
        res.date = item.startTimeLocal.split('T')[0] + "T" + item.startTimeLocal.split('T')[1];
      } else {
        res.date = item.startTimeLocal.split('T')[0] + "T" + dateTour[0].time;
      }
    } else {
      res.date = item.productType !== 2 ? date + "T" + dateTour[0].time : date.split('/').reverse().join('-') + "T" + item.startTimeLocal.split('T')[1]
    }

    if (item.productType === 2) {
      if (typeof res.hour === typeof 0) {
        res.hour = item.startTimeLocal.split('T')[1];
      }
    } else {

    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    var language = getValues().language;//(item.idioma === 'Inglês') ? 2 : (item.idioma === 'Espanhol') ? 3 : 1;

    const updateReservation = async () => {
      try {
        const { data } = await api.post(`/Checkout/EditEnviaCheckout`,
          {
            "firstName": res.name,
            "lastName": item.sobrenome,
            "phone": res.cellphone,
            "email": res.email,
            "idTarif": item.idTarifario,
            "internalNotes": res.internalNotesItem,
            "IdNewItem": item.idItem,
            "date": res.date,
            "idBooking": item.idBookings,
            "BookingLoc": item.bookingLoc,
            "Foreign": 1,
            "Language": language,
            "adults": passengers.adult,
            "childs": passengers.child,
            "infants": passengers.infant,
            "elders": passengers.elder,
            "students": passengers.student,
            "globalPeople": passengers.globalPeople,
            "pickup": res.listPickup ? parseInt(res.listPickup.idPickupLocation) : pickup?.id ? pickup.id : pickup,
            "cia": res.cia,
            "voo": res.voo,
            "resellerReference": res.resellerReference,
            "resellerName": res.resellerName,
            "agentCode": res.agentCode
          }, config
        );
        if (data.status !== 400) {
          if (data.data.log === 1 || data.data.log === '1') {
            setCurrentEditResponse('error');
            setEditErrorText(data.data.texto);
            setLoading(false)
          } else {
            setCurrentEditResponse('success');
            setEditErrorText(data.data.texto);
          }
          // window.location.reload();
        }
      } catch (error: any) {
        setCurrentEditResponse('error')
        setEditErrorText(error?.response?.data?.errorMessage)
        setLoading(false)
      }
    }
    if ((res.hour === '') || (typeof res.hour !== typeof 0 && (parseInt(res.hour?.toString().split(':')[0]) >= 24 || parseInt(res.hour?.toString().split(':')[1]) > 60 || res.hour?.toString().includes('_')))) {
      alert('Hora inválida');
    } else {
      setLoading(true);
      setEditSuccess(true);
      setCurrentEditResponse('loading');
      updateReservation();
    }
  }

  const editDefaultFunction = async () => {
    let res: any = item

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    var language = getValues().language;//(item.idioma === 'Inglês') ? 2 : (item.idioma === 'Espanhol') ? 3 : 1;

    const updateReservation = async () => {
      try {
        const { data } = await api.post(`/Checkout/EditEnviaCheckout`,
          {
            "firstName": item.nome,
            "lastName": item.sobrenome,
            "phone": item.telefone,
            "email": item.email,
            "idTarif": item.idTarifario,
            "internalNotes": item.internalNotesItem,
            "IdNewItem": item.idItem,
            "date": item.startTimeLocal,
            "idBooking": item.idBookings,
            "BookingLoc": item.bookingLoc,
            "Foreign": 1,
            "Language": language,
            "adults": passengers.adult,
            "childs": passengers.child,
            "infants": passengers.infant,
            "elders": passengers.elder,
            "students": passengers.student,
            "globalPeople": passengers.globalPeople,
            "pickup": item.pickupListByOrderDTO?.length > 0 ? item.pickupListByOrderDTO[0]?.idPickupLocation : undefined,
            "cia": item.cia,
            "voo": item.voo,
            "resellerReference": item.resellerReference,
            "resellerName": item.resellerName,
            "agentCode": agents?.supplierIdentity//res.agentCode
          }, config
        );
        if (data.status !== 400) {
          if (data.data.log === 1 || data.data.log === '1') {
            setCurrentEditResponse('error');
            setEditErrorText(data.data.texto);
            setLoading(false)
          } else {
            setCurrentEditResponse('success');
            setEditErrorText(data.data.texto);
          }
          // window.location.reload();
        }
      } catch (error: any) {
        setCurrentEditResponse('error')
        setEditErrorText(error?.response?.data?.errorMessage)
        setLoading(false)
      }
    }

    setLoading(true);
    setEditSuccess(true);
    setCurrentEditResponse('loading');
    updateReservation();
  }

  if (item !== null) {
    return (
      <>
        <div>
          <Form
            className="modal-content"
            noValidate
            validated={validated}
            onSubmit={handleSubmit(onSubmit)}
            autoComplete="false"
          >
            <Modal.Body>
              <div className="modal-header rounded-0">
                <div>
                  <h4 className="pt-1 text-primary title-reservation">
                    {item.productName}
                  </h4>
                  {item?.productType === 1 || item?.productType === 4 ? (
                    <small>
                      {item.idioma === "Espanhol"
                        ? item.modalityEs
                        : item.idioma === "Inglês"
                          ? item.modalityEN
                          : item.modalityBR}
                    </small>
                  ) : (
                    <small>{item?.sellingTypeName}</small>
                  )}
                </div>

                <div className="buttons-options">
                  <div>
                    {editProfile === false ? (
                      
                      <Dropdown drop="down">
                        <Dropdown.Toggle className="options-reservations">
                          <FontAwesomeIcon
                            icon={["fal", "angle-down"]}
                            className="mx-2 text-info"
                            size="2x"
                            style={{ marginTop: "-3px" }}
                          />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                        {level === 29 || level === "29" ? 
                      <>
                       <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              getVoucher(item.idItem);
                              setEditProfile(editProfile);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "download"]}
                              className="mr-2"
                            />{" "}
                            {t("reservations.modal.dropdown.voucher")}
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              getReciboEmail(item);
                              setEditProfile(editProfile);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "download"]}
                              className="mr-2"
                            />{" "}
                            {t("reservations.modal.dropdown.receipt")}
                          </Dropdown.Item>                         
                      </>
                    :
                  <>
                   <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              getVoucher(item.idItem);
                              setEditProfile(editProfile);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "download"]}
                              className="mr-2"
                            />{" "}
                            {t("reservations.modal.dropdown.voucher")}
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              getReciboEmail(item);
                              setEditProfile(editProfile);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "download"]}
                              className="mr-2"
                            />{" "}
                            {t("reservations.modal.dropdown.receipt")}
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => {
                              setEditProfile(true /* !editProfile */);
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "edit"]}
                              className="mr-2"
                            />{" "}
                            {t("reservations.modal.dropdown.edit")}
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            onClick={() => { editDefaultFunction() }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "edit"]}
                              className="mr-2"
                            />{" "}
                            Atualizar Reserva
                          </Dropdown.Item>

                          <Dropdown.Item
                            eventKey="1"
                            className="text-danger"
                            onClick={() => {
                              setCancelReservationObj({
                                idNewItems: item.idItem,
                                reservaC2: item.bookingLoc,
                              });
                              action();
                              setDetailsModal(false)
                            }}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "ban"]}
                              className="mr-2 "
                            />
                            <span> {t("reservations.modal.dropdown.cancel")}</span>
                          </Dropdown.Item>
                  </>}
                         
                        </Dropdown.Menu>
                      </Dropdown>
                    ) : (
                      <></>
                    )}
                  </div>
                  <div>
                    <FontAwesomeIcon
                      icon={["fal", "times"]}
                      className="mx-3 text-info"
                      size="lg"
                      data-dismiss="modal"
                      aria-label="Close"
                      onClick={() => {
                        setEditProfile(false);
                        setDetailsModal(false);
                      }}
                    />
                  </div>
                </div>
              </div>

              <hr
                className="w-100 my-4"
                style={{ borderBottom: "1px solid" }}
              />

              <div className="w-100 p-0">
                <Row className="p-0 m-0">
                  <Col md={2}>
                    <Badge
                      className="px-4"
                      bg={
                        item.status === 1
                          ? "success"
                          : item.status === 2
                            ? "danger"
                            : "info"
                      }
                    >
                      {item.status === 1
                        ? `${t("reservations.modal.active")}`
                        : item.status === 2
                          ? `${t("reservations.modal.canceled")}`
                          : `${t("reservations.modal.notInformed")}`}
                    </Badge>
                  </Col>
                  <Col md={3}>
                    <small>
                      {t("reservations.modal.reservationCode")}: <b>{item.bookingLoc}</b>
                    </small>
                  </Col>
                  <div className="divider"></div>
                  <Col md={6} className="p-0 m-0 pl-4">
                    <small>
                      {t("reservations.modal.reservationHolder")}: <b>{item.nomeCompleto}</b>
                    </small>
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">{t("reservations.modal.holderData")}</h6>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.name")}</span>
                    {editProfile === false ? (
                      <span className="response-modal">
                        <b>{item.nomeCompleto}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="name"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                value={name}
                                size="sm"
                                onChange={(e: any) => {
                                  setName(e.target.value);
                                }}
                                required
                                autoComplete='off'
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.telephone")}</span>
                    {editProfile === false ? (
                      <span className="response-modal">
                        <b>{item.telefone}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="cellphone"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                size="sm"
                                value={cellphone}
                                onChange={(e: any) => {
                                  setCellphone(e.target.value);
                                }}
                                required
                                autoComplete='off'
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.email")}</span>
                    {editProfile === false ? (
                      <span className="response-modal">
                        <b>{item.email}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="email"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                size="sm"
                                value={email}
                                onChange={(e: any) => {
                                  setEmail(e.target.value);
                                }}
                                required
                                autoComplete='off'
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>

                  <Col md={3}>
                    <span className="subtitle-modal">Idioma</span>
                    {editProfile === false ? (
                      <span className="response-modal">
                        <b>{item.idioma}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="language"
                            render={({ field }: any) => (
                              <div className="endpoint">
                              <Form.Select
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                size="sm"
                              >
                                <option value=''>{t('accordionFinancial.select.select')}</option>
                                <option value='1'>Português</option>
                                <option value='2'>Inglês</option>
                                <option value='3'>Espanhol</option>
                              </Form.Select>
                              </div>
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">{t("reservations.modal.reservationInfo")}</h6>
                  <Col md={3}>
                    <div className="d-flex">
                      <span className="subtitle-modal mr-2">{t("reservations.modal.people")}</span>
                      <span
                        className="d-flex align-items-center"
                        style={{ cursor: "pointer" }}
                        ref={target}
                        onClick={() => {
                          setShowPassengersDetails(!showPassengersDetails);
                        }}
                      >
                        <FontAwesomeIcon
                          className="ml-2 mt-0"
                          icon={["fal", "info-circle"]}
                        />
                      </span>
                      <Overlay
                        target={target.current}
                        show={showPassengersDetails}
                        placement="right"
                      >
                        {(props: any) => (
                          <Tooltip id="tooltip" {...props}>
                            <div className="d-flex flex-column">
                              {item.quantityReservation.adultSale -
                                item.quantityReservation.adultUsed >
                                0 ? (
                                <p className="m-0">{`${item.quantityReservation.adultSale -
                                  item.quantityReservation.adultUsed
                                  } ${t("reservations.modal.tooltip.adult")}`}</p>
                              ) : (
                                <></>
                              )}
                              {item.quantityReservation.childSale -
                                item.quantityReservation.childUsed >
                                0 ? (
                                <p className="m-0">{`${item.quantityReservation.childSale -
                                  item.quantityReservation.childUsed
                                  } ${t("reservations.modal.tooltip.child")}`}</p>
                              ) : (
                                <></>
                              )}
                              {item.quantityReservation.elderSale -
                                item.quantityReservation.elderUsed >
                                0 ? (
                                <p className="m-0">{`${item.quantityReservation.elderSale -
                                  item.quantityReservation.elderUsed
                                  } ${t("reservations.modal.tooltip.elder")}`}</p>
                              ) : (
                                <></>
                              )}
                              {item.quantityReservation.infantSale -
                                item.quantityReservation.infantUsed >
                                0 ? (
                                <p className="m-0">{`${item.quantityReservation.infantSale -
                                  item.quantityReservation.infantUsed
                                  } ${t("reservations.modal.tooltip.infant")}`}</p>
                              ) : (
                                <></>
                              )}
                              {item.quantityReservation.privateSale -
                                item.quantityReservation.privateUsed >
                                0 ? (
                                <p className="m-0">{`${item.quantityReservation.privateSale -
                                  item.quantityReservation.privateUsed
                                  } ${t("reservations.modal.tooltip.private")}`}</p>
                              ) : (
                                <></>
                              )}
                              {item.quantityReservation.studentSale -
                                item.quantityReservation.studentUsed >
                                0 ? (
                                <p className="m-0">{`${item.quantityReservation.studentSale -
                                  item.quantityReservation.studentUsed
                                  } ${t("reservations.modal.tooltip.student")}`}</p>
                              ) : (
                                <></>
                              )}
                            </div>
                          </Tooltip>
                        )}
                      </Overlay>
                    </div>
                    {/* {editProfile === false ? (  Task 7288 - Foi pedido para bloquear a edição */}
                    <span className="response-modal">
                      <b>
                        {" "}
                        {item?.sellingType === 1 ? (
                          <>
                            <span>{t("reservations.modal.upTo")} {privMaxNumPeople}</span>
                          </>
                        ) : (
                          <>
                            <span>{item.quantidadePax}</span>
                          </>
                        )}{" "}
                        {t("reservations.modal.passengers")}
                      </b>
                    </span>
                    {/* ) : (

                      <div>
                        {item?.sellingType === 1 ?
                          <div>
                            <span><strong>{t("reservations.modal.upTo")} {privMaxNumPeople} {t("reservations.modal.passengers")}</strong></span>
                          </div>
                          :
                          <div className="number-of-people"  >
                            <NumberOfPeople
                              item={item}
                              setPassengers={setPassengers}
                            />
                          </div>

                        }
                      </div>
                    )} */}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.date")}</span>

                    {editProfile === false ||
                      (tourOptions === null && item.productType !== 2) ||
                      tourOptions.length === 0 ? (
                      <span className="response-modal">
                        <b>
                          {item.startTimeLocal
                            .split("T")[0]
                            .split("-")
                            .reverse()
                            .join("/")}
                        </b>
                      </span>
                    ) : (
                      <>
                        <Form.Group as={Col} md="12">
                          <InputGroup
                            hasValidation
                            className=""
                            size="sm"
                            placeholder="Quando?"
                            id="date-"
                          >                         
                            {item.productType === 1 ? (
                              <DoubleCalendar
                                modalityID={item.idModalidade}
                                product={tourOptions[0]}
                                productCode={item.productCode}
                                changePriceDateTour={changePriceDateTour}
                                dateTour={dateTour}
                                index={0}
                                startTimeLocal={convertDate(
                                  item.startTimeLocal
                                )}
                                oneMonth={true}
                              ></DoubleCalendar>
                            ) : item.productType === 4 ? (
                              <DoubleCalendarTicket
                                modalityID={item.idModalidade}
                                product={tourOptions[0]}
                                productCode={item.productCode}
                                changePriceDateTour={changePriceDateTour}
                                dateTour={dateTour}
                                index={0}
                                startTimeLocal={convertDate(
                                  item.startTimeLocal
                                )}
                                oneMonth={true}
                              ></DoubleCalendarTicket>
                            ) : (
                              <>
                                <SingleCalendar
                                  startTimeLocal={item.startTimeLocal}
                                  disablePastDates={true}
                                  setValue={setDate}
                                ></SingleCalendar>
                              </>
                            )}
                          </InputGroup>
                        </Form.Group>
                      </>
                    )}
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.hour")}</span>
                    {editProfile === false || item.productType === 2 ? (
                      <span className="response-modal">
                        <b>{convertDate(item.startTimeLocal, true)}</b>
                      </span>
                    ) : (
                      <>
                        <div>
                          <div className="panel-dropdown bg-white">
                            {
                              dateTour[0]?.isFreeSale === 1
                              ?
                              <DropdownHoursFree
                                changePriceDateTour={changePriceDateTour}
                                dateTour={dateTour}
                                edit={true}
                                item={item}
                              />
                              :
                              <DropdownHours
                                changePriceDateTour={changePriceDateTour}
                                dateTour={dateTour}
                                edit={true}
                                item={item}
                              />
                            }
                          </div>
                        </div>
                      </>
                    )}
                  </Col>
                </Row>
                <Row className="p-0 m-0 mt-5">
                  {item.productType === 1 ? (
                    <Col md={3}>
                      <span className="subtitle-modal">{t("reservations.modal.pickupHour")}</span>
                      {/* {editProfile === false ? ( */}
                      <span className="response-modal">
                        <b>{item.pickupTime}</b>
                      </span>
                      {/* ) : (
                            <>
                              <Form.Group
                                as={Col}
                                md="12"
                                controlId="formInfoCompanySubscription"
                              >
                                <Controller
                                  control={control}
                                  name="listPickup"
                                  render={({ field }: any) => (
                                    <div className="input-select-custom">
                                      <GetPickupList
                                        propsField={field}
                                        propsErrors={errors}
                                        pickupList={item.pickupListByOrderDTO}
                                        defaultEditPickup={item}
                                      />
                                    </div>
                                  )}
                                />
                              </Form.Group>
                            </>
                          )} */}
                    </Col>
                  ) : (
                    <></>
                  )}
                  <Col md={6}>
                    {item.productType === 1 && item.embarkingType === 1 && tourOptions?.length > 0 ? (
                      <>
                        <span className="subtitle-modal">{t("reservations.modal.pickup")}</span>
                        {editProfile === false ? (
                          <span className="response-modal">
                            <b>{item.nomePickup}</b>
                          </span>
                        ) : (
                          <>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="formInfoCompanySubscription"
                            >
                              <Controller
                                control={control}
                                name="listPickup"
                                render={({ field }: any) => (
                                  <>
                                    {
                                      item?.pickupListByOrderDTO?.length !== 0
                                        ?
                                        <div className="input-select-custom">
                                          <GetPickupList
                                            propsField={field}
                                            propsErrors={errors}
                                            pickupList={item.pickupListByOrderDTO}
                                            defaultEditPickup={item}
                                          />
                                        </div>
                                        :
                                        <>
                                          <div className="input-select-custom">
                                            {
                                              pickupListId !== null ?
                                                <GetPickups
                                                  propsId={pickupListId}
                                                  actionPickup={setPickup}
                                                  alert={alert}
                                                />
                                                :
                                                <>
                                                  <GetPickups
                                                    propsId={tourOptions[0]?.pickupListId}
                                                    actionPickup={setPickup}
                                                    alert={alert}
                                                  />
                                                  <small>{t("reservations.modal.emergencialSoluction")}</small>
                                                </>


                                            }
                                          </div>
                                        </>
                                    }
                                  </>
                                )}
                              />
                            </Form.Group>
                          </>
                        )}
                      </>
                    ) : (
                      <></>
                    )}
                  </Col>
                </Row>
                {
                  item.status === 2
                    ?
                    <Row className='p-0 m-0 mt-5'>
                      <h6 className='mb-3'>Informações do Cancelamento</h6>
                      <Col md={3} >
                        <span className="subtitle-modal">Cancelada por:</span>
                        <span className="response-modal"><b>{item.nameCancel + " " + item.lastNameCancel}</b></span>
                      </Col>
                      <Col md={3} >
                        <span className="subtitle-modal">Data</span>
                        <span className="response-modal"><b>{convertDate(item.dataCancelamento)}</b></span>
                      </Col>
                      <Col md={3} >
                        <span className="subtitle-modal">Hora</span>
                        <span className="response-modal"><b>{convertDate(item.dataCancelamento, true)}</b></span>
                      </Col>
                      {
                        item.source !== "BILHETERIA" && item.source !== "TOTEM"
                          ?
                          <Col md={3} >
                            <span className="subtitle-modal">Motivo</span>
                            <span className="response-modal"><b>{item.motivoCancelado}</b></span>
                          </Col>
                          :
                          ""
                      }
                    </Row>
                    :
                    ""
                }

                {item.productType === 2 ? (
                  <>
                    <Row className="p-0 m-0 mt-5">
                      <Col md={6} style={{ paddingRight: "30px" }}>
                        <span className="subtitle-modal">{t("reservations.modal.originWay")}</span>
                        <span className="response-modal">
                          <b>{item?.origemIda}</b>
                        </span>
                      </Col>
                      <Col md={6} style={{ paddingRight: "30px" }}>
                        <span className="subtitle-modal">{t("reservations.modal.destinationWay")}</span>
                        <span className="response-modal">
                          <b>{item?.destinoIda}</b>
                        </span>
                      </Col>
                    </Row>

                    {item?.origemVolta !== null &&
                      item?.destinoVolta !== null &&
                      item?.origemVolta?.length > 5 &&
                      item?.destinoVolta?.length > 5 ? (
                      <Row className="p-0 m-0 mt-5">
                        <Col md={6}>
                          <span className="subtitle-modal">{t("reservations.modal.originReturn")}</span>
                          <span className="response-modal">
                            <b>{item?.origemVolta}</b>
                          </span>
                        </Col>
                        <Col md={6}>
                          <span className="subtitle-modal">{t("reservations.modal.destinationReturn")}</span>
                          <span className="response-modal">
                            <b>{item?.destinoVolta}</b>
                          </span>
                        </Col>
                      </Row>
                    ) : (
                      <></>
                    )}

                    <Row className="p-0 m-0 mt-5">
                      <Col md={6}>
                        <span className="subtitle-modal">{t("reservations.modal.airline")}</span>
                        {editProfile === false ? (
                          <span className="response-modal">
                            <b>{item?.cia}</b>
                          </span>
                        ) : (
                          <>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="formInfoCompanySubscription"
                            >
                              <Controller
                                control={control}
                                name="ciaAerea"
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    variant="standard"
                                    margin="normal"
                                    type="text"
                                    size="sm"
                                    value={cia}
                                    onChange={(e: any) => {
                                      setCia(e.target.value);
                                    }}
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                            </Form.Group>
                          </>
                        )}
                      </Col>
                      <Col md={6}>
                        <span className="subtitle-modal">{t("reservations.modal.flight")}</span>
                        {editProfile === false ? (
                          <span className="response-modal">
                            <b>{item.voo}</b>
                          </span>
                        ) : (
                          <>
                            <Form.Group
                              as={Col}
                              md="12"
                              controlId="formInfoCompanySubscription"
                            >
                              <Controller
                                control={control}
                                name="voo"
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    variant="standard"
                                    margin="normal"
                                    type="text"
                                    size="sm"
                                    value={voo}
                                    onChange={(e: any) => {
                                      setVoo(e.target.value);
                                    }}
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                            </Form.Group>
                          </>
                        )}
                      </Col>
                    </Row>
                  </>
                ) : (
                  <></>
                )}

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">{t("reservations.modal.accessCode")}</h6>
                  {item.qrCodeAccessReservationDTO?.length > 0
                    ? item.qrCodeAccessReservationDTO.map(
                      (unit: any, index: Key) => {
                        return (
                          <div
                            className={
                              unit.activationTimestamp === null
                                ? "code-waiting"
                                : "code-used"
                            }
                            key={index}
                            style={{ display: "flex" }}
                          >
                            <span className="icon-code mr-2">
                              <FontAwesomeIcon
                                icon={["fad", "ticket"]}
                                className={
                                  unit.activationTimestamp === null
                                    ? "mx-2 text-info"
                                    : "mx-2 text-success"
                                }
                                size="lg"
                              />
                            </span>
                            <span>
                              <h6 className="w-auto">
                                {t("reservations.modal.code")}
                                <small style={{ marginLeft: "0.5rem" }}>
                                  <Badge
                                    className=" ml-2"
                                    bg={
                                      unit.activationTimestamp === null
                                        ? "info"
                                        : "success"
                                    }
                                  >
                                    {unit.activationTimestamp === null
                                      ? "em aberto"
                                      : "usado"}
                                  </Badge>
                                </small>
                              </h6>
                              <span className="response-modal">
                                {t("reservations.modal.codeNumber")} {unit.accessCode}
                              </span>{" "}
                              {unit.activationTimestamp === null ? (
                                <></>
                              ) : (
                                <>
                                  <span className="ml-3 response-modal text-strong">
                                    {t("reservations.modal.visitationDate")}
                                  </span>{" "}
                                  <span className="response-modal">
                                    {convertDate(unit.activationTimestamp)} -{" "}
                                    {convertDate(
                                      unit.activationTimestamp,
                                      true
                                    )}
                                  </span>
                                </>
                              )}
                            </span>
                          </div>
                        );
                      }
                    )
                    : `${t("reservations.modal.notInformed")}`}
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">{t("reservations.modal.generalInfo")}</h6>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.issueDate")}</span>
                    <span className="response-modal">
                      <b>
                        {item.dataEmissao
                          .split("T")[0]
                          .split("-")
                          .reverse()
                          .join("/")}
                      </b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.supplier")}</span>
                    <span className="response-modal">
                      <b>{item.nomeFornecedor}</b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">
                      {t("reservations.modal.supplierTel")}
                    </span>
                    <span className="response-modal">
                      <b>{item.telefoneFornecedor}</b>
                    </span>
                  </Col>

                  {editProfile === true && level === '19' ? (
                    <Col md={3}>
                      <span className="subtitle-modal">{t("reservations.modal.agent")}</span>

                      <span className="response-modal">
                        <div className='input-select-custom'>
                          <GetAgentsDetails actionAgents={setAgents} setClient={item.resellerName} />
                        </div>
                      </span>
                    </Col>
                  ) :
                    <Col md={3}>
                      <span className="subtitle-modal">{t("reservations.modal.agent")}</span>

                      <span className="response-modal">
                        <b>{item.resellerName}</b>
                      </span>
                    </Col>
                  }
                </Row>

                <Row className="p-0 mx-0" style={{ marginTop: "20px" }}>
                  {editProfile === false ? (
                    <>
                      <Col md={3}>
                        <span className="subtitle-modal">
                          {t("reservations.modal.agentRef")}
                        </span>
                        <span className="response-modal">
                          <b>{item.resellerReference}</b>
                        </span>
                      </Col>
                    </>
                  ) : (
                    <>
                      <span className="subtitle-modal">
                        {t("reservations.modal.agentRef")}
                      </span>
                      <span className="response-modal">
                        <Form.Group
                          as={Col}
                          md="3"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="resellerReference"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                value={resellerReference}
                                type="text"
                                size="sm"
                                onChange={(e: any) => {
                                  setresellerReference(e.target.value);
                                }}
                                required
                                autoComplete='off'
                              />
                            )}
                          />
                        </Form.Group>
                      </span>
                    </>
                  )}
                </Row>

                <Row className="p-0 m-0 mt-5">
                  <h6 className="mb-3">{t("reservations.modal.paymentData")}</h6>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.value")}</span>
                    <span className="response-modal">
                      <b>R$ {item.amount.toFixed(2).split(".").join(",")}</b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.paymentMethod")}</span>
                    <span className="response-modal">
                      <b>{item.formaPagamento}</b>
                    </span>
                  </Col>
                  <Col md={3}>
                    <span className="subtitle-modal">{t("reservations.modal.discountCoupon")}</span>
                    <span className="response-modal">
                      <b>
                        {item.cupom} -{" "}
                        {item.percentCupom > 0 ? item.percentCupom : ""}
                      </b>
                    </span>
                  </Col>
                </Row>

                <Row className="p-0 m-0 mt-5 flex-column">
                  <h6 className="mb-3">{t("reservations.modal.importantInfo")}</h6>
                  <Col md={6} className="mb-3">
                    {item?.productType === 1 || item?.productType === 4 ? (
                      <>
                        <span className="subtitle-modal">{t("reservations.modal.modalities")}</span>
                        <span className="response-modal">
                          <b>{item.modalityBR}</b>
                        </span>
                      </>
                    ) : (
                      <>
                        <span className="subtitle-modal">{t("reservations.modal.VehicleType")}</span>
                        <span className="response-modal">
                          <b>{item.sellingTypeName}</b>
                        </span>
                      </>
                    )}
                  </Col>
                  <Col md={6} className="p-0 m-0 mt-5">
                    <span className="subtitle-modal">{t("reservations.modal.internalNotes")}</span>
                    {editProfile === false ? (
                      <span className="response-modal">
                        <b>{item.internalNotesItem}</b>
                      </span>
                    ) : (
                      <>
                        <Form.Group
                          as={Col}
                          md="12"
                          controlId="formInfoCompanySubscription"
                        >
                          <Controller
                            control={control}
                            name="internalNotesItem"
                            render={({ field }: any) => (
                              <Form.Control
                                {...field}
                                variant="standard"
                                margin="normal"
                                type="text"
                                as="textarea"
                                rows={4}
                                size="sm"
                                value={internalNotesItem}
                                onChange={(e: any) => {
                                  setInternalNotesItem(e.target.value);
                                }}
                                required
                                autoComplete='off'
                              />
                            )}
                          />
                        </Form.Group>
                      </>
                    )}
                  </Col>
                </Row>

                <Row className="p-0 m-0 my-5">
                  <Col md={6}>
                    <span className="subtitle-modal">{t("reservations.modal.includes")}</span>
                    <ul className="exclusoes small" id="excluiVoucher">
                      {item.itemsIncludedDTO?.length > 0
                        ? item.itemsIncludedDTO?.map((unit: any, index: Key) => {
                          return (
                            <li key={index}>
                              <FontAwesomeIcon
                                icon={["fal", "check"]}
                                className="mx-2 text-success"
                                size="lg"
                              />
                              {unit.atividade}
                            </li>
                          );
                        })
                        : "Não informado"}
                    </ul>
                  </Col>
                  <Col md={6}>
                    <span className="subtitle-modal">{t("reservations.modal.notIncludes")}</span>
                    <ul className="exclusoes small" id="excluiVoucher">
                      {item.itemsNotIncludedDTO?.length > 0
                        ? item.itemsNotIncludedDTO?.map(
                          (unit: any, index: Key) => {
                            return (
                              <li key={index}>
                                <FontAwesomeIcon
                                  icon={["fal", "times"]}
                                  className="mx-2 text-info"
                                  size="lg"
                                />
                                {unit.atividade}
                              </li>
                            );
                          }
                        )
                        : "Não informado"}
                    </ul>
                  </Col>
                </Row>

                <hr />

                <Row className="p-0 m-0 my-5">
                  <h6 className="mb-3">{t("reservations.modal.emailsReminder")}</h6>

                  {/* {test.length > 0
                                        ? test.map((unit: any, index: Key) => { */}
                  {item.messageStatusSends?.length > 0
                    ? item.messageStatusSends?.map((unit: any, index: Key) => {
                      return (
                        <div key={index}>
                          <span className="subtitle-modal">
                            {unit.messageType}
                          </span>
                          {unit.statusSend === "DELIVERED" ? (
                            <div className="d-flex">
                              <small style={{ width: "120px" }}>
                                <Badge
                                  className="ml-2 mr-4 px-4"
                                  bg={"success"}
                                  style={{ width: "120px" }}
                                >
                                  {t("reservations.modal.delivered")}
                                </Badge>
                              </small>
                              <p
                                className="px-4"
                                style={{
                                  fontSize:
                                    "14px" /* , borderRight: "1px solid #ccc" */,
                                }}
                              >
                                {t("reservations.modal.deliveryDate")}{" "}
                                {convertDate(unit.dateMessage)} -{" "}
                                {convertDate(unit.dateMessage, true)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {unit.statusSend === "SEND" ? (
                            <div className="d-flex">
                              <small style={{ width: "120px" }}>
                                <Badge
                                  className="ml-2 mr-4 px-4 badge grey"
                                  style={{ width: "120px" }}
                                >
                                  {t("reservations.modal.shipped")}
                                </Badge>
                              </small>
                              <p
                                className="px-4"
                                style={{ fontSize: "14px" }}
                              >
                                {t("reservations.modal.openingDate")}{" "}
                                {convertDate(unit.dateMessage)} -{" "}
                                {convertDate(unit.dateMessage, true)}
                              </p>
                            </div>
                          ) : (
                            <></>
                          )}
                          {unit.statusSend === "NOT_SEND" ? (
                            <div className="d-flex">
                              <small style={{ width: "120px" }}>
                                <Badge
                                  className="ml-2 mr-4 px-4"
                                  bg={"danger"}
                                  style={{ width: "120px" }}
                                >
                                  {t("reservations.modal.notDelivered")}
                                </Badge>
                              </small>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                      );
                    })
                    : `${t("reservations.modal.noActivityFound")}`}
                </Row>

                <hr />

                <Row className="p-0 m-0 my-5">
                  <h6 className="mb-3">Logs</h6>
                  {item.logs?.length > 0
                    ? item.logs?.map((unit: any, index: Key) => {
                      return (
                        <div key={index}>
                          <p className="mb-1" style={{ fontSize: "14px" }}>
                            <strong>{unit.usuario}</strong> -{" "}
                            {convertDate(unit.dateUpdated)} -{" "}
                            {convertDate(unit.dateUpdated, true)}
                          </p>
                          {/* <p className="mb-1" style={{ fontSize: "14px" }}>
                            <strong>{t("reservations.modal.email")}: </strong>
                            {unit.email} | <strong>{t("reservations.modal.telephone")}: </strong>
                            {unit.telefone} | <strong>{t("reservations.modal.language")} </strong>
                            {unit.idioma}
                          </p>
                          <p style={{ fontSize: "14px" }}>
                            {unit.internalNotesItem
                              ? unit.internalNotesItem
                              : ""}
                          </p> */}
                          {unit.logMessages
                            ? unit.logMessages.map((msg: any) =>
                              <div className="d-flex" style={{ fontSize: "14px" }}>
                                <OverlayTrigger
                                  overlay={
                                    !msg.newValue ?
                                    <Tooltip id="tooltip"><b style={{ fontWeight: 600 }}>{msg.fieldName}</b> '{msg.oldValue}'<b style={{ fontWeight: 600 }}></b> {msg.newValue}<b style={{ fontWeight: 600 }}>{"."}</b></Tooltip>
                                    :
                                    <Tooltip id="tooltip"><b style={{ fontWeight: 600 }}>{msg.fieldName + " alterado de '"}</b>{msg.oldValue}<b style={{ fontWeight: 600 }}>{"' para '"}</b>{msg.newValue}<b style={{ fontWeight: 600 }}>{"'."}</b></Tooltip>
                                  }>
                                  <span className="text-wrap-custom">
                                    {!msg.newValue ?
                                    <><b style={{ fontWeight: 600 }}>{msg.fieldName}</b> '{msg.oldValue}'<b style={{ fontWeight: 600 }}></b> {msg.newValue}<b style={{ fontWeight: 600 }}>{"."}</b></>
                                    :
                                    <><b style={{ fontWeight: 600 }}>{msg.fieldName + " alterado de '"}</b>{msg.oldValue}<b style={{ fontWeight: 600 }}>{"' para '"}</b>{msg.newValue}<b style={{ fontWeight: 600 }}>{"'."}</b></>
                                    }
                                   
                                  </span>
                                </OverlayTrigger>
                              </div>)
                            : <p></p>}

                          <hr style={{ opacity: ".1" }} />
                        </div>
                      );
                    })
                    : `${t("reservations.modal.noActivityFound")}`}
                </Row>
              </div>
            </Modal.Body>

            {editProfile === false ? (
              <></>
            ) : (
              <Modal.Footer>
                <div className="modal-footer rounded-0 border-0">
                  <button
                    type="button"
                    className="btn btn-outline-primary rounded-0"
                    data-dismiss="modal"
                    onClick={() => {
                      setEditProfile(!editProfile);
                    }}
                  >
                    <i className="fas fa-times fa-fw"></i>{" "}
                    <span data-vouchercampo="close">{t("reservations.modal.cancel")}</span>
                  </button>
                  <button
                    type="submit"
                    className="btn btn-primary rounded-0 details-reservations-submit-button"
                  >
                    {loading === true ? (
                      <div className="load"></div>
                    ) : (
                      `${t("reservations.modal.editReservation")}`
                    )}
                  </button>
                </div>
              </Modal.Footer>
            )}
          </Form>
        </div>
        <Modal
          className="modal-custom-sm modal-confirm bg-modal-custom modal-cancel-reservation"
          show={editSuccess !== false}
          onHide={() => {
            setEditSuccess(false);
            currentEditResponse === "success" ? (
              <></>
            ) : (
              setCurrentEditResponse(null)
            );
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <div className="">
            {currentEditResponse === "success" ? (
              <Modal.Body className="center">
                <div className="sucess-pos">
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={["fal", "check-circle"]}
                      size="5x"
                      style={{ fontSize: "7.5em" }}
                    />
                    <h5>{t("reservations.modal.congratulations")}</h5>
                    <p>{editErrorText}</p>
                  </div>
                  <Button
                    className="my-3 mx-auto"
                    onClick={() => {
                      setEditSuccess(false);
                      window.location.reload();
                    }}
                  >
                    {t("reservations.modal.close")}
                  </Button>
                </div>
              </Modal.Body>
            ) : currentEditResponse === "error" ? (
              <Modal.Body className="center">
                <div className="sucess-pos">
                  <div className="text-center">
                    <FontAwesomeIcon
                      icon={["fal", "times-circle"]}
                      size="5x"
                      style={{ fontSize: "7.5em" }}
                    />
                    <h5>{t("reservations.modal.error")}</h5>
                    <p>{editErrorText}</p>
                  </div>
                  <Button
                    className="my-5 mx-auto"
                    onClick={() => setEditSuccess(false)}
                  >
                    {t("reservations.modal.close")}
                  </Button>
                </div>
              </Modal.Body>
            ) : (
              <Modal.Body className="center">
                <div className="loading">
                  <div className="text-center">
                    <div className="load-big mb-3"></div>
                    <h5>{t("reservations.modal.loading")}</h5>
                  </div>
                </div>
              </Modal.Body>
            )}
          </div>
        </Modal>
      </>
    );
  } else {
    return (

      <>
        <Modal.Body>
          <div className="modal-header rounded-0">
            <div>
              <div className="pt-1 text-primary title-reservation animated-background" style={{ height: "30px", width: "500px" }}></div>
              <small></small>
            </div>

            <div className='buttons-options'>
              <div>

              </div>
              <div>
                <FontAwesomeIcon
                  icon={['fal', 'times']}
                  className="mx-3 text-info"
                  size="lg"
                  data-dismiss="modal"
                  aria-label="Close"
                  onClick={() => { setDetailsModal(false) }}
                />
              </div>
            </div>
          </div>

          <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />

          <div className='w-100 p-0'>

            <Row className='p-0 m-0'>
              <Col md={2}>
                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
              </Col>
              <Col md={3} >
                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
              </Col>
              <div className='divider'></div>
              <Col md={6} className="p-0 m-0 pl-4">
                <div className=" animated-background" style={{ height: "18px", width: "80%" }}></div>
              </Col>
            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
                <div className='d-flex'>
                  <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                </div>
              </div>
            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div style={{ display: "flex", flexDirection: "column" }}>
                <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
                <div className='d-flex'>
                  <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                  <Col md={3} >
                    <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                    <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                  </Col>
                </div>
              </div>
            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
              {[1, 2, 3, 4, 5].length > 0
                ? [1, 2, 3, 4, 5].map((unit: any, index: Key) => {
                  return (
                    <div className='my-3' key={index} style={{ display: 'flex' }}>
                      <span className=' mr-2'>
                        <div className="mb-3 animated-background" style={{ height: "50px", width: "50px", borderRadius: "50%" }}></div>
                      </span>
                      <span>
                        <div className="mb-3 animated-background" style={{ height: "24px", width: "100px" }}></div>
                        <div className=" animated-background" style={{ height: "12px", width: "200px" }}></div>
                      </span>

                    </div>
                  )
                })
                : ''
              }
            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
              <div className='d-flex'>
                <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                </Col>
                <Col md={3} >
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                </Col>
                <Col md={3} >
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                </Col>
                <Col md={3} >
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                </Col>
              </div>
            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
              <div className='d-flex'>
                <Col md={3} style={{ display: "flex", flexDirection: "column" }}>
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                </Col>
                <Col md={3} >
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                </Col>
                <Col md={3} >
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "60px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "100px" }}></div>
                </Col>
              </div>

            </Row>

            <Row className='p-0 m-0 mt-5'>
              <div className="mb-3 animated-background" style={{ height: "24px", width: "200px" }}></div>
              <div className='d-flex'>
                <Col md={6} style={{ display: "flex", flexDirection: "column" }}>
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "120px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "300px" }}></div>
                </Col>
                <Col md={6} >
                  <span className="subtitle-modal animated-background" style={{ marginBottom: "10px", height: "18px", width: "120px" }}></span>
                  <div className=" animated-background" style={{ height: "12px", width: "300px" }}></div>
                </Col>

              </div>
            </Row>
          </div>

        </Modal.Body>

      </>
    );
  }
}

export default DetailsReservation;