import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';

import '../../../assets/sass/modal.scss';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import GetCostCenter from '../../C2Points/GetCostCenter';

import { useTranslation } from "react-i18next";


export interface propModal {
  action: any;
  setCloseModal: any;
  controlObject: any;
};

const FormFinancial: React.FC<propModal> = ({
  action, setCloseModal, controlObject
}: propModal) => {
  const { t } = useTranslation();
  const [maxRateio, setMaxRateio] = useState<number>(100);
  const [value, setValue] = useState<any>();
  const [errorColor, setErrorColor] = useState<boolean>(false);
  const [errorMessage, setErrorMessage] = useState<boolean>(false);

  useEffect(()=>{
    if(value > maxRateio){
      setValue(maxRateio);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  },[value])

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const onSubmitCostCenter = (data: any) => {
    if(parseInt(value) !== 0){  
      setErrorMessage(false);
      data.rateio = parseInt(value);
      if (Number(data.rateio) <= maxRateio) {
        data.coastCenterIdDesc = data.coastCenterId.description;
        data.coastCenterId = data.coastCenterId ? data.coastCenterId : data.coastCenterId.id;
        action(data);
        setCloseModal(true);
      } else {
        setErrorColor(true)
      }
    } else {
      setErrorMessage(true);
    }
  };

  useEffect(() => {
    var aux: number = 0;
    if (controlObject.length > 0) {
      controlObject.forEach((elem: any) => {
        aux = aux + Number(elem.rateio);
      });
    }
    setMaxRateio(100 - aux);
  }, [controlObject])

  return (
    <>
      <Container className="bg-modal-contact p-0 mt-3" fluid>
        <Row>
          <div>
            <Form
              className="form-add-contact"
              noValidate
            >
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom01">
                  <Form.Label>{t("accordionFinancial.modalFinancial.centerCoast")}</Form.Label>
                  <Controller
                    control={control}
                    name="coastCenterId"
                    rules={{ required: { value: true, message: t("accordionFinancial.modalFinancial.validation") } }}
                    render={({ field }: any) => (
                      <GetCostCenter propsField={field} propsErrors={errors} propsLabel="Centros de Custo" />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="coastCenterId"
                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="12" controlId="validationCustom03">
                  <Form.Label>{t("accordionFinancial.modalFinancial.rateio")} <span style={{ color: errorColor ? "#F00" : "#707070" }}>(Max: {maxRateio}%)</span> </Form.Label>
                  <Controller
                    control={control}
                    name="rateio"
                    render={({ field }: any) => (
                      <Form.Control type="number"
                        {...field}
                        aria-invalid={errors?.rateio ? "true" : ""}
                        fullWidth
                        variant="standard"
                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                        margin="normal"
                        isInvalid={errorColor}
                        value={value}
                        autoComplete='off'
                        onChange={(e: any)=> setValue(e.target.value)}
                        required />
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="rateio"
                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                  />
                {errorMessage === true ? <small style={{ color:"#F00" }}>Rateio não pode ser 0%</small> : <></>}
                </Form.Group>
              </Row>
              <Row className="buttons-modal d-flex justify-content-end mx-0">
                {/* <Button className="form-button mr-3" onClick={props.onHide}>
                    Cancelar
                  </Button> */}
                <Button type="button" onClick={handleSubmit(onSubmitCostCenter)} className="form-button">
                  {t("accordionFinancial.modalFinancial.save")}
                </Button>
              </Row>
            </Form>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default FormFinancial;
