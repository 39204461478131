/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';

import { destroyCookie } from 'nookies'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Col, Dropdown, Row } from 'react-bootstrap';

import { useAppDispatch } from '../../store/hooks';
import { updateMenu } from "../../store/cart";

//STYLE
import './HeaderNew.scss'
import DropdownLanguage from '../DropdownLanguage/DropdownLanguage';
import i18next from 'i18next';
import { useTranslation } from 'react-i18next';
import api from '../../services/api';


function HeaderNew() {
  const { t } = useTranslation();
  const [controlMenu, setControlMenu] = useState(localStorage.getItem("menuState") === 'false');
  const dispatch = useAppDispatch();
  const [lang, setLang] = useState<any>("pt-BR");
  const [saldo, setSaldo] = useState(0)
  const [saldoLabel, setSaldoLabel] = useState<string>("")

  const c2tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel);

  let user = JSON.parse(localStorage.getItem('c2tourAuth') || '');
  const userName = user.user;
  const userId = user.user.perfil.userId;
  const isAgent: number = user.user.perfil.isAgent;

  useEffect(() => {
    setLang(i18next.language);
    if (i18next.language.includes("pt")) {
      setSaldoLabel("Saldo");
    } else if (i18next.language === "en") {
      setSaldoLabel("Balance");
    } else {
      setSaldoLabel("Saldo");
    }
  }, [i18next.language]);

  const handleLogout = () => {
    localStorage.removeItem('c2tourAuth');
    destroyCookie(null, 'authCookie');
    window.location.href = ('/');
  }


  const handleShowClose = () => {
    dispatch(updateMenu(!controlMenu));
    if (controlMenu === false) {
      setControlMenu(true);
      (document.querySelector('body') as HTMLElement).classList.add('menu-show');
    } else {
      setControlMenu(false);
      (document.querySelector('body') as HTMLElement).classList.remove('menu-show');
    }
  };


  useEffect(() => {
    handleShowClose();
    if (isAgent === 1 || level === '29') {
      getSaldo();
    }
  }, []);

  const getSaldo = async () => {
    //console.log(user)
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {

      const { data } = await api.post('/Supplier/ConsultaSaldoRM', {
        "ownerIdentity": user.user.perfil.supplierIdentity
      }, config);
      if (data.statusCode !== 400) {
        setSaldo(data.data)
      } else {

      }

    } catch (error: any) {

    }

  }


  // const handleClose = () => {
  //     (document.querySelector('body') as HTMLElement).classList.remove('menu-show')
  // }
  return (
    <div className="header bg-white">
      <div style={{ display: "flex" }}>
        <div
          className="bg-toggle-hamb p-2"
          onClick={() => {
            handleShowClose();
          }}
        >
          <FontAwesomeIcon
            icon={["fad", "bars"]}
            size="2x"
            style={{ color: "#707070" }}
          />
        </div>
        <img
          className="logo-main"
          onClick={() => {
            window.location.href = "/sales";
          }}
          src={process.env.REACT_APP_SERVER_LOGO_SHORT}
          alt="Marca SIG"
          style={{
            width: process.env.REACT_APP_SERVER_LOGO_WIDTH_HEADER,
            padding: "10px",
            cursor: "pointer",
            height: '84px'
          }}
        />
        <div className="divisor"></div>
        {lang === "pt-BR" ? (
          <h2 className="titleHeader d-none d-sm-block">
            <b>S</b>istema <b>I</b>ntegrado de <b>G</b>estão
          </h2>
        ) : lang === "en" ? (
          <h2 className="titleHeader d-none d-sm-block">
            <b>I</b>ntegrated <b>M</b>anagement <b>S</b>ystem
          </h2>
        ) : lang === "es" ? (
          <h2 className="titleHeader d-none d-sm-block">
            <b>S</b>istema de
            <br />
            <b>M</b>anejo <b>I</b>ntegrado
          </h2>
        ) : (
          <h2 className="titleHeader d-none d-sm-block">
            <b>S</b>istema <b>I</b>ntegrado de <b>G</b>estão
          </h2>
        )}
      </div>

      <div className="d-flex align-items-center">
        {/* {console.log(level)}
        {console.log(c2tourAuth.user.perfil.canSeeBills)} */}


        {/* {level === '29' &&  */}
        {
          isAgent === 1 && level === '29' && c2tourAuth.user.perfil.canSeeBills === true
            ?
            <Row className="">
              <Col className="col-md-2 d-flex justify-content-center align-items-center">
                <FontAwesomeIcon
                  icon={["fad", "dollar-sign"]}
                  className="text-primary p-1"
                  size="3x"
                />
              </Col>
              <Col className="col-md-10 pt-1">
                <Row>
                  <span className="balance-title mb-0">{saldoLabel}</span>
                  <span className="cart-content">
                    {/* {totalSales.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })} */}
                    {saldo.toLocaleString("pt-br", {
                      style: "currency",
                      currency: "BRL",
                    })}
                  </span>
                </Row>
              </Col>
            </Row>
            :
            ""
        }

        {isAgent === 1 || level === '29' ? (
          <div className="d-flex align-items-center">
            <DropdownLanguage />
          </div>
        ) : <></>}
        <ul className="navbar-nav ml-auto" style={{ maxHeight: "90px" }}>
          <li className="nav-item dropdown nav-item-c2 nav-profile">
            <Dropdown drop="down">
              <Dropdown.Toggle className="menu-profile">
                <div className="profile">
                  <span className="user-name">{`${userName.nome} ${userName.lastName}`}</span>
                  <FontAwesomeIcon icon={["fad", "user-circle"]} size="2x" />
                </div>
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item
                  eventKey="1"
                  onClick={() => {
                    window.location.href = `/users/edit-users?id=${userId}`;
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={["fal", "user-alt"]}
                    className="mr-2"
                  />{" "}
                  {t("headerNew.myAccount")}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="2"
                  disabled={true}
                  onClick={() => {
                    window.location.href = `/profile`;
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={["fal", "users"]}
                    className="mr-2"
                  />{" "}
                  {t("headerNew.changeProfile")}
                </Dropdown.Item>
                <Dropdown.Item
                  eventKey="3"
                  onClick={() => {
                    window.location.href = `/users/edit-users?id=${userId}&editPassword=true`;
                  }}
                >
                  {" "}
                  <FontAwesomeIcon
                    icon={["fal", "key"]}
                    className="mr-2"
                  />{" "}
                  {t("headerNew.changePassword")}
                </Dropdown.Item>
                <Dropdown.Item eventKey="4" disabled={true}>
                  {" "}
                  <FontAwesomeIcon
                    icon={["fal", "cog"]}
                    className="mr-2"
                  />{" "}
                  {t("headerNew.preferences")}
                </Dropdown.Item>
                <Dropdown.Item eventKey="5" onClick={handleLogout}>
                  {" "}
                  <FontAwesomeIcon
                    icon={["fal", "sign-out"]}
                    className="mr-2"
                  />{" "}
                  {t("headerNew.leave")}
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
      </div>
    </div>
  );
}

export default HeaderNew;