import React from 'react';
import { Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import Select from "react-select";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";


export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    isForeign:any,
    t?:any
};

const PaymentCheckoutDebit: React.FC<propForm> = ({
    control, errors, setValue, isForeign, t
}: propForm) => {

    const c2tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");

    /* const onSubmit = (data: any) => {
    }; */

    //const [paymentType, setPaymentType] = useState<any>("139");

    function handleChangePaymentOption(e: any) {
        //setPaymentType(e.value)
        setValue('merchant', e.value);
    }

    const paymentOption = [
        { value: "null", label: "Selecione" },
        { value: "139", label: "PAYPAL - TODOS" },
        { value: "186", label: "CRUZEIRO DO SUL - OUTROS" },
        { value: "209", label: "BANCO ITAU - 21 - TRANSFERÊNCIA ITAU" },
        { value: "210", label: "CAIXA PDV PÃO DE AÇUCAR - CASH" },
        { value: "211", label: "BANCO BRADESCO - TRANFERENCIA BRADESCO" },
        { value: "212", label: "CAIXA HILTON COPA - CASH" },
        { value: "230", label: "CIELO - MASTERCARD" },
        { value: "231", label: "CIELO - VISA" },
        { value: "232", label: "CIELO - OUTROS" },
        { value: "233", label: "CIELO - MAST DB BR" },
        { value: "234", label: "CIELO - VISA DB BR" },
        { value: "235", label: "CIELO - ELO DB" },
        { value: "236", label: "CIELO - ELO CR" },
        { value: "237", label: "CIELO - AMEX" },
        { value: "241", label: "STRIPE - TODOS" },
        { value: "259", label: "CONTA CORRENTE SÓCIO LEO BALARDJISCHVILI - 21 - TRANSFERÊNCIA ITAU" },
        { value: "276", label: "REDECARD - MASTERCARD" },
        { value: "277", label: "REDECARD - VISA" },
        { value: "278", label: "REDECARD - MAST DB BR" },
        { value: "279", label: "REDECARD - VISA DB BR" },
        { value: "280", label: "REDECARD - ELO DB" },
        { value: "281", label: "REDECARD - HIPERCARD" },
        { value: "282", label: "REDECARD - ELO CR" },
        { value: "283", label: "REDECARD - AMEX" },
        { value: "284", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - MASTERCARD" },
        { value: "285", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - VISA" },
        { value: "286", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - DINERS CR BR" },
        { value: "287", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - MAST CR BR" },
        { value: "288", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - MAST CR OC" },
        { value: "289", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - MAST DB BR" },
        { value: "290", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - VISA CR BR" },
        { value: "291", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - VISA DB BR" },
        { value: "292", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - ELO DB" },
        { value: "293", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - HIPERCARD" },
        { value: "294", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - ELO CR" },
        { value: "295", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - Nubank" },
        { value: "296", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - AMEX" },
        { value: "297", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - AURA" },
        { value: "298", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - DISCOVER" },
        { value: "299", label: "BRASPAG TECNOLOGIA EM PAGAMENTO LTDA - JCB" },



    ]

    return (
        <>
            <h3>{t("checkout.paymentCheckout.offlineCredit.offlineCard")}</h3>

            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDebitNSU" className="mb-3">
                    <Form.Label>{t("checkout.paymentCheckout.offlineCredit.paymentMode")}</Form.Label>
                    <Controller
                        control={control}
                        name="merchant"
                        rules={{ required: { value: true, message: t("checkout.paymentCheckout.offlineCredit.paymentModeError") } }}
                        render={({ field }: any) => (
                            <Select
                                placeholder="Selecione"
                                className="endpoint"
                                onChange={handleChangePaymentOption}
                                defaultValue={paymentOption[0]}
                                options={paymentOption}
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="merchant"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDebitNSU" className="mb-3">
                    <Form.Label>{t("checkout.paymentCheckout.offlineCredit.sequentialNumber")}</Form.Label>
                    <Controller
                        control={control}
                        name="refCode"
                        rules={{ required: { value: true, message: t("checkout.paymentCheckout.offlineCredit.sequentialNumberError") } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="refCode"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>

                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDebitNSU" className="mb-3">
                    <Form.Label>{t("checkout.paymentCheckout.offlineCredit.authCode")}</Form.Label>
                    <Controller
                        control={control}
                        name="CodAutorizacaoOffline"
                        rules={{ required: { value: true, message: t("checkout.paymentCheckout.offlineCredit.authCodeError") } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="CodAutorizacaoOffline"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
            </Row>

            {
                isForeign !== true && c2tourAuth.user.perfil.isAgent === 0 && c2tourAuth.user.perfil.canSeeBills !== true ?
                <>
                <Row>
                    <Form.Group as={Col} md="4" controlId="formCheckoutPaymentCreditParcelas">
                        <Form.Label>{t("checkout.paymentCheckout.offlineCredit.installments")}</Form.Label>
                        <Controller
                            control={control}
                            name="parcelas"
                            render={({ field }: any) => (
                                <div className='endpoint'>
                                    <Select
                                        placeholder="Selecione"
                                        onChange={(e: any) => { setValue('parcelas', e.value) }}
                                        options={[
                                            { value: "1x", label: t("checkout.paymentCheckout.offlineCredit.cash"), selected: "true" },
                                            { value: "2x", label: "2x" },
                                            { value: "3x", label: "3x" },
                                        ]}
                                    />
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="parcelas"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            </>
            :
            <></>
            }               

            {/* <Row>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDebitDate" className="mb-3">
                    <Form.Label>Data:</Form.Label>
                    <Controller
                        control={control}
                        name="debitDate"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="debitDate"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDebitNSU" className="mb-3">
                    <Form.Label>Valor:</Form.Label>
                    <Controller
                        control={control}
                        name="valor"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="valor"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
            </Row> */}
        </>
    );
}

export default PaymentCheckoutDebit;