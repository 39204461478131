import React from 'react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from 'react-bootstrap-table2-paginator';

export interface propInfo {
    info: any;
    loading: any;
    printReport: any;
    exportReport: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    loadButton: any;
}

const TableReportCashFlow: React.FC<propInfo> = ({
    info, loading, printReport, exportReport, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loadButton
}) => {
    const printBills = (e: any) => {

    }

    /* dados e funções da coluna  */

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        //var offset = date.getTimezoneOffset() / 60;
        //var hours = date.getHours();

        //newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    }

    function convertDate(cell: any, row: any) {
        var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
        /* console.log(date) */
        return (
          <>
            {date}
          </>
        );
      }
    
      function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
          <>
            <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
          </>
        )
      }

    const columns = [
        { dataField: "data", text: "Data", formatter: convertDate },
        { dataField: "saldoAbertura", text: "Saldo de Abertura", formatter: convertValue },
        { dataField: "totalReceber", text: "Recebimentos", formatter: convertValue },
        { dataField: "totalPagar", text: "Pagamentos", formatter: convertValue },
        { dataField: "saldoFinal", text: "Saldo Final", formatter: convertValue }
    ];

    /*--------------------------------------------------*/

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "contas", text: "Relatórios" }];

    const notFoundProducts = [{ contas: "Nenhum relatório encontrado" }];
    /* [END] In case of empty table*/

    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        )
    }

    const loadingColumns = [{ dataField: "contasd", text: "Carregando relatórios", formatter: loadingFunc }];

    const loadingProducts = [
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
        { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
    ];
    /* [END] loading table */
    /*[END] dados da coluna */

    /* const customTotal = (from: any, to: any, size: any) => (
        <span className="react-bootstrap-table-pagination-total">
            Showing {from} to {to} of {size} Results
        </span>
    ); */

    /* const paginationOption = {
        paginationSize: 4,
        pageStartIndex: 0,
        firstPageText: 'First',
        prePageText: 'Back',
        nextPageText: 'Next',
        lastPageText: 'Last',
        nextPageTitle: 'First page',
        prePageTitle: 'Pre page',
        firstPageTitle: 'Next page',
        lastPageTitle: 'Last page',
        showTotal: true,
        paginationTotalRenderer: customTotal,
        disablePageTitle: true,
        sizePerPageList: [{
            text: '10', value: 10
        }, {
            text: '20', value: 20
        }, {
            text: '30', value: 30
        }] // A numeric array is also available. the purpose of above example is custom the text
    }; */

    const rowClasses = (row: any, rowIndex: any) => {
        return 'border-normal'
    };

    if (info !== null && (info?.length > 0) && loading === false) {
        return (
            <>
                <div className="table-default cashflow-report-table">
                    <div className="table-container">
                        <div>
                            <div className="d-flex justify-content-between">
                                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                    <div>
                                        <button className="d-flex align-items-center btn btn-bills" onClick={printReport}>
                                            {
                                                loadButton[0] === true
                                                    ?
                                                    <div className="load" style={{ marginRight: "5px" }}></div>
                                                    :
                                                    <FontAwesomeIcon
                                                        file-invoice
                                                        icon={["fal", "print"]}
                                                        size="1x"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                            }
                                            Imprimir
                                        </button>
                                    </div>
                                    <div>
                                        <button className="d-flex align-items-center btn btn-bills" onClick={exportReport}>
                                            {
                                                loadButton[1] === true
                                                    ?
                                                    <div className="load" style={{ marginRight: "5px" }}></div>
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "share"]}
                                                        size="1x"
                                                        style={{ marginRight: "5px" }}
                                                    />
                                            }
                                            Exportar
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={info}
                            columns={columns}
                            striped={true}
                            //pagination={paginationFactory(paginationOption)}
                            rowClasses={rowClasses}
                        />

                    </div>
                </div>
            </>
        );
    } else if (info === null || loading === true) {
        return (
            <>
                <div className="table-default cashflow-report-table loading not-found">
                    <div className="table-container">
                        <div className="d-flex justify-content-between">
                            <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                                <div>
                                    <button className="btn btn-bills" onClick={printBills}>
                                        <FontAwesomeIcon
                                            file-invoice
                                            icon={["fal", "print"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Imprimir
                                    </button>
                                </div>
                                <div>
                                    <button className="btn btn-bills">
                                        <FontAwesomeIcon
                                            icon={["fal", "share"]}
                                            size="1x"
                                            style={{ marginRight: "5px" }}
                                        />
                                        Exportar
                                    </button>
                                </div>
                            </div>
                        </div>
                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={loadingProducts}
                            hover={true}
                            columns={loadingColumns}
                            striped={true}
                            rowClasses={rowClasses}
                        />
                    </div>
                </div>
            </>
        )
    } else {
        return (
            <>
                <div className="table-default cashflow-report-table not-found">
                    <div className="table-container">
                        <div className="table-title">
                            <h3 className="">Relatório</h3>
                        </div>

                        <BootstrapTable
                            bootstrap4
                            keyField="id"
                            data={notFoundProducts}
                            hover={true}
                            columns={notFoundColumns}
                            striped={true}
                        />
                    </div>
                </div>
            </>
        );
    }
}

export default TableReportCashFlow;