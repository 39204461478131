import React, { useState, useEffect, useRef } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./DropdownNumberPeopleGroupFile.scss";
import { useTranslation } from "react-i18next";

import iconArrow from '../../assets/icons/arrow_white.svg';

//import iconMale from '../../assets/icons/svg/fa-male.svg';

export interface propsR {
    //actual: any;
    info?: any;
    actionPeople: any;
    //dateTour?: any;
    //isIntegration?: any;
    ranges?: any;
    disabled?: any;
}

const DropdownNumberPeople: React.FC<propsR> = ({
    //actual,
    info,
    actionPeople,
    //dateTour,
    //isIntegration,
    ranges = null
    , disabled
}: propsR) => {
    const [numberAdults, setNumberAdults] = useState<number>(info && info.adults ? info.adults : 0);
    const [numberChilds, setNumberChilds] = useState<number>(info && info.childs ? info.childs : 0);
    const [numberInfants, setNumberInfants] = useState<number>(info && info.infants ? info.infants : 0);
    const [numberStudent, setNumberStudent] = useState<number>(info && info.student ? info.student : 0);
    const [numberElders, setNumberElders] = useState<number>(info && info.elders ? info.elders : 0);
    const [numberPeople, setNumberPeople] = useState<number>(1);
    const [numberTotalPeople, setNumberTotalPeople] = useState<number>(1);
    const [numberTotal, setNumberTotal] = useState<number>(1);

    const [defaultDef, setDefaultDef] = useState<boolean>(false);

    const [isOpen, setIsOpen] = useState<any>(false);

    const { t } = useTranslation();

    /* Detecta clique fora da div#wrapper para fechar*/
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    function useOutsideAlerter(ref: any) {
        useEffect(() => {
            function handleClickOutside(event: any) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setIsOpen(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }

    /* atualiza contagem interna quando tem alteração na contagem externa */
    useEffect(() => {
        if(info) {
            setNumberAdults(info.adults ? info.adults : 0);
            setNumberChilds(info.childs ? info.childs : 0);
            setNumberInfants(info.infants ? info.infants : 0);
            setNumberStudent(info.student ? info.student : 0);
            setNumberElders(info.elders ? info.elders : 0);
        }
    }, [info]);

    /* //Detecta clique fora da div#wrapper para fechar\\ */
    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberInfants);
        setNumberTotalPeople(numberTotalPeople)
        const numberSend = {
            adults: numberAdults,
            childs: numberChilds,
            infants: numberInfants,
            elders: numberElders,
            student: numberStudent,
            globalPeople: numberPeople,
        };

        if(defaultDef) actionPeople(numberSend); else setDefaultDef(true);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [numberAdults, numberChilds, numberInfants, numberPeople, numberStudent, numberElders]);

    const handleClickPeoples = () => {
        isOpen === false ? setIsOpen(true) : setIsOpen(false);
    };

    const handleClickAdults = (controll: string) => {
        let newNumber = numberTotalPeople;
        newNumber = numberAdults;
        if (controll === "sub") {
            if (numberAdults === 0) {
                return false;
            } else {
                newNumber = numberAdults - 1;
            }
        }
        if (controll === "plus") {
            newNumber = numberAdults + 1;
        }
        return setNumberAdults(newNumber);
    };

    const handleClickChilds = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberChilds === 0) {
                return false;
            } else {
                newNumber = numberChilds - 1;
            }
        }
        if (controll === "plus") {
            newNumber = numberChilds + 1;
        }
        return setNumberChilds(newNumber);
    };

    const handleClickInfants = (controll: string) => {
        let newNumber = numberChilds;
        if (controll === "sub") {
            if (numberInfants === 0) {
                return false;
            } else {
                newNumber = numberInfants - 1;
            }
        }
        if (controll === "plus") {
            newNumber = numberInfants + 1;
        }
        return setNumberInfants(newNumber);
    };

    const handleClickStudent = (controll: string) => {
        let newNumber = numberStudent;
        if (controll === "sub") {
            if (numberStudent === 0) {
                return false;
            } else {
                newNumber = numberStudent - 1;
            }
        }
        if (controll === "plus") {

            newNumber = numberStudent + 1;

        }
        return setNumberStudent(newNumber);
    };

    const handleClickElders = (controll: string) => {
        let newNumber = numberElders;
        if (controll === "sub") {
            if (numberElders === 0) {
                return false;
            } else {
                newNumber = numberElders - 1;
            }
        }
        if (controll === "plus") {

            newNumber = numberElders + 1;

        }
        return setNumberElders(newNumber);
    };

    const handleClickPeople = (controll: string) => {
        let newNumber = numberPeople;
        if (controll === "sub") {
            if (numberPeople === 0) {
                return false;
            } else {
                newNumber = numberPeople - 1;
            }
        }
        if (controll === "plus") {
            newNumber = numberPeople + 1;
        }
        return setNumberPeople(newNumber);
    };

    const RenderPeople = () => {
        //if ((info.idSellingType === "2" || info.typeProduct === 'TICKET') && isIntegration === false) {
            return (
                <>
                    {ranges.isActiveAdult === true
                        ?

                        <div className="qtyButtons">
                            <label>
                                {ranges.labelAdult}
                                <small id="idade-adulto">(11-100)</small>
                                {/* <span
                                    className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                    id="valor-adulto-sm"
                                >
                                    {dateTour ? dateTour[actual].adult.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : info.modalities[actual].tarif.price.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span> */}
                            </label>
                            {disabled === true ?
                            <div className="qtyDec disabled">
                                -
                            </div>
                            :
                            <div className="qtyDec" onClick={() => handleClickAdults("sub")}>
                                -
                            </div>
                            }
                            <input
                                type="text"
                                name="qtyInput"
                                value={numberAdults}
                                id="numero-adulto"
                                autoComplete='off'
                            />
                            {disabled === true ?
                            <div className="qtyInc disabled">
                                +
                            </div>
                            :
                            <div className="qtyInc" onClick={() => handleClickAdults("plus")}>
                                +
                            </div>
                            }
                        </div>
                        : <></>
                    }

                    {ranges.isActiveChild === true
                        ?

                        <div className="qtyButtons">
                            <label>
                                {ranges.labelChild}
                                <small id="idade-crianca">(3-10)</small>
                                {/* <span
                                    className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                    id="valor-crianca-sm"
                                >
                                    {" "}
                                    {(dateTour ? dateTour[actual].child.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : info.modalities[actual].tarif.price / 2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span> */}
                            </label>
                            {disabled === true ?
                            <div className="qtyDec disabled">
                                -
                            </div>
                            :
                            <div className="qtyDec" onClick={() => handleClickChilds("sub")}>
                                -
                            </div>
                            }
                            <input
                                type="text"
                                name="qtyInput"
                                value={numberChilds}
                                id="numero-crianca"
                                autoComplete='off'
                            />
                            {disabled === true ?
                            <div className="qtyInc disabled">
                                +
                            </div>
                            :
                            <div className="qtyInc" onClick={() => handleClickChilds("plus")}>
                                +
                            </div>
                            }
                        </div>
                        : <></>
                    }

                    {ranges.isActiveElders === true
                        ?

                        <div className="qtyButtons">
                            <label>
                                {ranges.labelElders}
                                {<small id="idade-crianca">(+ 60 anos)</small>}
                                <small id="idade-crianca"></small>
                                {/* <span
                                    className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                    id="valor-infat-sm"
                                >
                                    {" "}
                                    {(dateTour ? dateTour[actual].elders.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : info.modalities[actual].tarif.price / 2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span> */}
                            </label>
                            {disabled === true ?
                            <div className="qtyDec disabled">
                                -
                            </div>
                            :
                            <div className="qtyDec" onClick={() => handleClickElders("sub")}>
                                -
                            </div>
                            }
                            <input
                                type="text"
                                name="qtyInput"
                                value={numberElders}
                                id="numero-infantil"
                                autoComplete='off'
                            />
                            {disabled === true ?
                            <div className="qtyInc disabled">
                                +
                            </div>
                            :
                            <div className="qtyInc" onClick={() => handleClickElders("plus")}>
                                +
                            </div>
                            }
                        </div>

                        : <></>
                    }

                    {
                        ranges.isActiveInfant === true
                            ?

                            <div className="qtyButtons">
                                <label>
                                    {ranges.labelInfant}
                                    <small id="idade-crianca">(0-2)</small>
                                    {/* <span
                                        className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                        id="valor-infat-sm"
                                    >
                                        {" "}
                                        {(dateTour ? dateTour[actual].infant.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : info.modalities[actual].tarif.price / 2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </span> */}
                                </label>
                                {disabled === true ?
                                <div className="qtyDec disabled">
                                    -
                                </div>
                                :
                                <div className="qtyDec" onClick={() => handleClickInfants("sub")}>
                                    -
                                </div>
                                }
                                <input
                                    type="text"
                                    name="qtyInput"
                                    value={numberInfants}
                                    id="numero-infantil"
                                    autoComplete='off'
                                />
                                {disabled === true ?
                                <div className="qtyInc disabled">
                                    +
                                </div>
                                :
                                <div className="qtyInc" onClick={() => handleClickInfants("plus")}>
                                    +
                                </div>
                                }
                            </div>
                            : <></>
                    }

                    {
                        ranges.isActiveStudent === true
                            ?

                            <div className="qtyButtons">
                                <label>
                                    {ranges.labelStudent}
                                    <small id="idade-crianca">(0-2)</small>
                                    {/* <span
                                        className="price-dropdown text-dark font-weight-bold w-100 d-block"
                                        id="valor-infat-sm"
                                    >
                                        {" "}
                                        {(dateTour ? dateTour[actual].student.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' }) : info.modalities[actual].tarif.price / 2).toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                    </span> */}
                                </label>
                                {disabled === true ?
                                <div className="qtyDec disabled">
                                    -
                                </div>
                                :
                                <div className="qtyDec" onClick={() => handleClickStudent("sub")}>
                                    -
                                </div>
                                }
                                <input
                                    type="text"
                                    name="qtyInput"
                                    value={numberStudent}
                                    id="numero-infantil"
                                    autoComplete='off'
                                />
                                {disabled === true ?
                                    <div className="qtyInc disabled">
                                        +
                                    </div>
                                    :
                                <div className="qtyInc" onClick={() => handleClickStudent("plus")}>
                                    +
                                </div>
                                }
                            </div>
                            : <></>
                    }
                </>
            );
        //} else {
        //    return <></>;
        //}
    };

    useEffect(() => {
        setNumberTotal(numberAdults + numberChilds + numberInfants + numberStudent + numberElders);
        setNumberTotalPeople(numberPeople)
    }, [numberAdults, numberChilds, numberInfants, numberPeople, numberStudent, numberElders]);

    if (isOpen === true) {
        return (
            <div id="wrapper" ref={wrapperRef} className="input-people">
                <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                    <FontAwesomeIcon
                        icon={["fal", "male"]}
                        size="1x"
                        className="mr-2 my-1"
                        style={{ marginLeft: "3.25px" }}
                    />
                    <span style={{ marginLeft: "20px" }}>
                        {t("sales.tourList.accordion.people")}{" "}
                    </span>
                    <span
                        className="qtyTotal"
                        data-min="2"
                        data-max="19"
                        style={{ marginLeft: "5px" }}
                    >
                        {numberTotal}
                    </span>
                    {/* <span style={{ marginLeft: "5px", color: "#FFF" }}>
                        {t("sales.tourList.accordion.passengers")}
                    </span> */}
                    {/* <span className="input-calendar-custom arrow py-0 py-md-2" id="basic-addon1">
                        <img src={iconArrow} alt="pesquisar" className="py-1" />
                    </span> */}
                </div>
                <div className="panel-dropdown-content right active">
                    <RenderPeople />
                    <p id="alertNumber"></p>
                </div>
            </div>
        );
    } else {
        return (
            <>
                <div className="input-people">
                    <div style={{ cursor: "pointer" }} onClick={() => handleClickPeoples()}>
                        <span className="input-calendar-custom icon py-0 py-md-2" id="basic-addon1">

                            <FontAwesomeIcon
                                icon={["fal", "male"]}
                                size="1x"
                                className="mr-2 my-1"
                                style={{ marginLeft: "3.25px" }}
                            />

                            <span style={{ marginLeft: "20px" }}>
                                {t("sales.tourList.accordion.people")}{" "}
                            </span>

                        </span>
                        <span
                            className="qtyTotal"
                            data-min="2"
                            data-max="19"
                            style={{ marginLeft: "5px" }}
                        >
                            {numberTotal}
                        </span>
                        {/* <span style={{ marginLeft: "5px", color: "#FFF" }}>
                            {t("sales.tourList.accordion.passengers")}
                        </span> */}
                        {/* <span className="input-calendar-custom arrow py-0 py-md-2" id="basic-addon1">
                            <img src={iconArrow} alt="pesquisar" className="py-1" />
                        </span> */}
                    </div>
                </div>
            </>
        );
    }
};

export default DropdownNumberPeople;
