import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import Pagination from '../../../../components/Pagination/Pagination';
import { Dropdown } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import "./TableStockGroupSession.scss";
import ModalQuestion from '../../../../components/Modal/ModalQuestion/ModalQuestion';
import api from '../../../../services/api';
import ModalShow from '../../../../components/Modal/ModalShow/ModalShow';

export interface propTable {
    groups: any;
    loading: any;
    totalRows: any;
    pageCount: any;
    setPageCount: any;
    rowsPerPage: any;
    setRowsPerPage: any;
    handleDeleteGroup: any;
}

const TableStockGroupSession: React.FC<propTable> = ({
    groups,
    loading,
    totalRows,
    pageCount,
    setPageCount,
    rowsPerPage,
    setRowsPerPage,
    handleDeleteGroup
}) => {
    const [groupCodeToDelete, setGroupCodeToDelete] = useState<string>('');
    const [showModalQuestion, setShowModalQuestion] = useState<boolean>(false);

    //botao de ação
    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                        </Dropdown.Toggle>
                        <Dropdown.Menu>
                            <Dropdown.Item
                                eventKey="1"
                                href={"/products/edit-stock-group?id=" + cell}
                            >
                                <FontAwesomeIcon icon={["fal", "edit"]} className="" />{" "}
                                Editar
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                href={"/products/stock?groupSessionUniqueCode=" + cell}
                            >
                                <FontAwesomeIcon icon={["fal", "circle"]} className="" />{" "}
                                Visualizar Estoque
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="3"
                                className="text-danger"
                                onClick={() => { setGroupCodeToDelete(cell); setShowModalQuestion(true); }}
                            >
                                <FontAwesomeIcon icon={["fal", "trash-alt"]} className="" />{" "}
                                Excluir
                            </Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    };

    // formatter de status
    function addStatus(cell: any, row: any) {
        let aux: string[] = ["Em análise", "Ativo", "Inativo"];
        let auxClass: string[] = ["bg-analise", "bg-ativo", "bg-inativo"];

        return (
            <div
                className={auxClass[cell]}
            >
                {aux[cell]}
            </div>
        );
    }

    

    const rowClasses = (row: any, rowIndex: any) => {
        if (row.statusStockGroupSession === 0) {
            return "border-analise";
        } else if (row.statusStockGroupSession === 1) {
            return "border-ativo";
        } else {
            return "border-inativo";
        }
    };

    const addProducts = (cell: any, row: any) => {
        return (
            <>
                <div className="list-stock-group-session text-wrap-custom wrap-1">
                    {(cell || []).map((elem: any) => {
                        return (
                            <span className='product-card'>
                                {elem.productName || elem.productCode}
                            </span>
                        );
                    })}
                </div>
            </>
        )
    };

    const columns = [
        { dataField: "groupSessionName", text: "Grupo de estoque" },
        { dataField: "stockGroupSessionItens", text: "Produtos", formatter: addProducts },
        { dataField: "statusStockGroupSession", text: "Status", formatter: addStatus },
        { dataField: "groupSessionUniqueCode", text: "Ações", formatter: addActionButton },
    ];


    /* loading table */

    function loadingFunc() {
        return (
            <>
                <div className="animated-background row-loading"></div>
            </>
        );
    }

    const loadingColumns = [
        { dataField: "categories", text: "Carregando categorias", formatter: loadingFunc },
    ];

    const loadingCategories = [
        { categories: "" },
        { categories: "" },
        { categories: "" },
        { categories: "" },
        { categories: "" },
        { categories: "" },
        { categories: "" },
        { categories: "" },
        { categories: "" },
        { categories: "" }
    ];
    /* [END] loading table */

    /* In case of empty table*/
    const notFoundColumns = [{ dataField: "categories", text: "Categorias" }];

    const notFoundCategories = [{ categories: "Nenhuma categoria encontrada" }];
    /* [END] In case of empty table*/

    if (groups !== null) {
        return (
            <>
                <div className="table-default group-session-table">
                    <div className="table-container">
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={groups}
                                hover={true}
                                columns={columns}
                                striped={true}
                                rowClasses={rowClasses}
                            />

                            <Pagination
                                totalRows={totalRows}
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                selectNumberRows="yes"
                            />
                        </div>
                    </div>
                </div>
                <ModalQuestion
                    modalShow={showModalQuestion}
                    setModalShow={setShowModalQuestion}
                    titulo="Grupo de Estoque"
                    highlight="Excluir Grupo de Estoque"
                    mensagem="Tem certeza que deseja excluir o grupo de estoque?"
                    handleActionConfirmModal={()=>{setShowModalQuestion(false); handleDeleteGroup(groupCodeToDelete)}}
                />
            </>
        );
    } else if (groups === null && loading === true) {
        return (
            <>
                <div className="table-default categories-table loading">
                    <div className="table-container">
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={loadingCategories}
                                hover={true}
                                columns={loadingColumns}
                                striped={true}
                            />

                            <Pagination
                                totalRows={totalRows}
                                pageCount={pageCount}
                                setPageCount={setPageCount}
                                rowsPerPage={rowsPerPage}
                                setRowsPerPage={setRowsPerPage}
                                selectNumberRows="yes"
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className="table-default categories-table loading not-found">
                    <div className="table-container">
                        <div>
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={notFoundCategories}
                                hover={true}
                                columns={notFoundColumns}
                                striped={true}
                            />
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default TableStockGroupSession;