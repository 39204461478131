/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

import "../../../../assets/sass/modal.scss";
import "./ViewExtract.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../services/api';
import { ErrorMessage } from "@hookform/error-message";
import GetPickupLocation from "../../../../components/C2Points/GetPickupLocation";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import Pagination from "../../../../components/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";

export interface propModal {
  extractId: any
};

const ViewExtract: React.FC<propModal> = ({
  extractId
}: propModal, props: any) => {
  const [pickupList, setPickupList] = useState<any>(null);

  const [loading, setLoading] = useState<any>(false);

  const [receberPagar, setReceberPagar] = useState<any>(1);
  const [admOperacao, setAdmOperacao] = useState<any>(1);
  const [date, setDate] = useState<any>('');
  const [client, setClient] = useState<any>('');
  const [valor, setValor] = useState<any>('');
  const [descontos, setDescontos] = useState<any>('');
  const [juros, setJuros] = useState<any>('');
  const [valorTotal, setValorTotal] = useState<any>('');
  const [contaContabil, setContaContabil] = useState<any>('');
  const [centroCusto, setCentroCusto] = useState<any>('');

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;


  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  }

  const getSessionMessages = async () => {
    setLoading(true);
    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {

        const { data } = await api.post(`/Extract/GetExtractItemInfo`, {
          "extractId": extractId
        }, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
            const response = data.data;
            const items = response.payments;

            setReceberPagar(response.receberPagar);
            setAdmOperacao(response.admOperacao);
            setDate(formatDate(response.data));
            setClient(response.clienteFornecedor);
            setValor(formatValue(response.valor));
            setDescontos(formatValue(response.descontos));
            setJuros(formatValue(response.juros));
            setValorTotal(formatValue(response.valorPago));
            setContaContabil(response.contaContabil);
            setCentroCusto(response.centroCusto);
            setPickupList(items);
            
        }   
        setLoading(false);
    }catch(error: any) {
        if (error?.response?.status === 401) {
            window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
            //window.location.href = window.location.origin + '/session-closure';              
        }
        //;
    }
    
  }

  useEffect(() => {
    
    if(extractId) getSessionMessages();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [extractId]);


  if(pickupList !== null && loading === false) {
    return (
      <>
        <Modal.Body className="p-0">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="extract-header mb-3">
              <div className="col-md-2">
                <h6>Data</h6>
                <p className="py-1">{date}</p>
              </div>
              <div className="col-md-4">
                <h6>Cliente/Fornecedor</h6>
                <p className="py-1">{client}</p>
              </div>
              <div className="col-md-6 m-0 d-flex">
                <div className="col-md-3">
                  <h6>Valor</h6>
                  <p className="py-1">{valor}</p>
                </div>
                <div className="col-md-3">
                  <h6>Descontos</h6>
                  <p className="py-1">{descontos}</p>
                </div>
                <div className="col-md-3">
                  <h6>Juros/Multas</h6>
                  <p className="py-1">{juros}</p>
                </div>
                <div className="col-md-3">
                  <h6>Valor Total</h6>
                  <p className="py-1">{valorTotal}</p>
                </div>
              </div>
            </Row>
            {admOperacao === 1 ?
            <Row className="h-100 flex-column view-extract-table">
                <div className="table-container">   
                  <Table striped >
                    <thead>
                      <tr>
                        {receberPagar === 1 ?
                        <>
                          <th>Conta Contabil</th>
                          <th>Data Competência</th>
                          <th>Localizador</th>
                        </>
                        :
                        <>
                          <th>Categoria</th>
                          <th>Centro Custo</th>
                          <th>Data Competência</th>
                        </>
                        }
                        <th>Descrição</th>
                        <th>Valor</th>
                      </tr>
                    </thead>
                    <tbody>
                      {pickupList.length > 0 ? pickupList.map((item: any, index: any) => (
                      <tr key={index}> 
                        <td>{item.contaContabil}</td>
                        {receberPagar === 2 ?
                          <td>{item.centroCusto}</td>
                        :<></>}
                        <td>{formatDate(item.dataCompetencia)}</td>
                        {receberPagar === 1 ?
                          <td>{item.reserva}</td>
                        :<></>}

                        <td>{item.descricao}</td>
                        <td>{formatValue(item.valor)}</td>
                      </tr>
                      ))
                      :
                      <tr><td colSpan={5} className="mb-2 text-center"><span>Nenhum item encontrado</span></td></tr>}
                    </tbody>
                  </Table>
                </div>
            </Row>
            :
            <Row className="extract-header mb-3">
              <div className="col-md-4">
                <h6>Categoria</h6>
                <p className="py-1">{contaContabil}</p>
              </div>
              <div className="col-md-4">
                <h6>Centro Custo</h6>
                <p className="py-1">{centroCusto}</p>
              </div>
            </Row>}
          </Container>
        </Modal.Body>
  
      </>
    );
  } else {
    return (
      <>
        <Modal.Body className="p-0">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="flex-column loading-table">
                <div className="table-container">   
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={loadingProducts}
                    hover={true}
                    columns={loadingColumns}
                    striped={true}
                  />
                </div>
            </Row>
          </Container>
        </Modal.Body>
  
      </>
    );
  }
  
}

export default ViewExtract;
