import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setFilterSupplier?: any
  supplier?: any
};

const GetProductsListSubCategory: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setFilterSupplier, supplier
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/DropDown/GetProductsListSubCategory`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        setTimeout(() => {
          listAccountingAccounts();
        }, 5000);
      }
    }
    listAccountingAccounts();
  }, []);


  if (info !== null) {
    if (setFilterSupplier) {
      setFilterSupplier(propsField.value?.id)
    }

    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            className="basic-multi-select"
            classNamePrefix="select"
            as="select"
            variant="standard"
            margin="normal"
            placeholder={`${supplier ? supplier : "Selecione"}`}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.id}`}
            getOptionLabel={(option: any) => `${option.name}`}
          //value={info.find((a: any) => a.supplierId === propsField.value)}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetProductsListSubCategory;