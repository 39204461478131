import React, { useEffect } from "react";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

/* const ModalDefault: FC<{}> = ({ children }: any) => { */
function ModalQuestion(props: any) {
  const [modalShow, setModalShow] = React.useState(props.modalShow);

  useEffect(() => {

    setModalShow(props.modalShow);
  }, [props.modalShow])

  function onCancelClick(e: any) {
    if (props.handleActionCancelModal) {
      props.handleActionCancelModal(e);
    } else {
      props.setModalShow(false);
    }
  }

  return (
    <div>
      <Modal
        className="modal-confirm loadial"
        show={modalShow}ng-mod
        onHide={() => props.setModalShow(false)}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          {/* <Modal.Header className="p-3" closeButton></Modal.Header> */}
          <Modal.Body className='modal-body text-center sucess-pos'>
            {/* <h5 className="contact-title mb-5">{props?.titulo}</h5> */}
            <div>
              <FontAwesomeIcon
                icon={["fal", "question-circle"]}
                size="5x"
                className="text-primary"
                style={{
                  fontSize: "7.5em"
                }}
              />
            </div>
            <div>
            <h5 className="text-primary">
              <strong>
                {props.highlight}
              </strong>
            </h5>
              {props.mensagem}
            </div>

            <div className="d-flex justify-content-center">
              {props.buttons ? props.buttons :
                <>
                  <button className="btn btn-outline-primary mx-2 w-25" onClick={onCancelClick}>
                    Cancelar
                  </button>
                  <button
                    type="button"
                    className="btn btn-primary mx-2 w-25"
                    onClick={props.handleActionConfirmModal}
                  >
                    {/* <FontAwesomeIcon
                    icon={["fal", "check"]}
                    size="1x"
                    style={{ marginRight: "5px" }}
                  /> */}
                    Confirmar
                  </button>
                </>
              }
            </div>
          </Modal.Body>
        </>
      </Modal>
    </div>
  );
};

export default ModalQuestion;
