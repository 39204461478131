import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from "react-bootstrap/Dropdown";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import Modal from "react-bootstrap/Modal";

import FormSessionChange from "../../../../components/Forms/FormSessionChange/FormSessionChange";
import FormBillReceiveEdit from "../../../../components/Forms/FormBillReceiveEdit/FormBillReceiveEdit";

//STYLES
import "./TableSessionBillsReceive.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

export interface propTable {
  bills: any,
  totalRows: any,
  pageCount: any,
  setPageCount: any,
  rowsPerPage: any,
  setRowsPerPage: any,
  exportReport: any,
  printReport: any,
  sessionReloc: any,
  sessionDate: any,
  sessionOperationType: any,
  refreshData: any,
  monthClosed: any
  loading?: any
}

const TableSessionBillsReceive: React.FC<propTable> = ({
  bills, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportReport, printReport
  , sessionReloc, sessionDate, sessionOperationType, refreshData, monthClosed, loading
}: propTable) => {
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])

  const [sessionChangeModalShow, setSessionChangeModalShow] = useState<any>(false);
  const [billEditModalShow, setBillEditModalShow] = useState<any>(false);
  const [billSessionChange, setBillSessionChange] = useState<any>(null);
  const [billEdit, setBillEdit] = useState<any>('');

  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }

  const handleBillEditClick = (row: any) => {
    setBillEdit(row);
    setBillEditModalShow(true);
  }


  const handleActionBillEditModal = (data: any) => {
    setBillEdit('');
    refreshData();
  }

  const handleActionBillEditHideModal = () => {
    setBillEditModalShow(false);
    refreshData();
  }

  const handleSessionChangeClick = (row: any) => {
    // show modal
    setBillSessionChange(row);
    setSessionChangeModalShow(true);
  }

  const handleActionSessionChangeModal = (data: any) => {
    refreshData();

    setBillSessionChange(null);
    // salvar mudança session
  }

  function addActionButton(cell: any, row: any) {
    if (monthClosed === false) {
      return (
        <>
          <div className="d-flex justify-content-center">
            <Dropdown drop="start">
              <Dropdown.Toggle variant="light ">
                <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="1" onClick={(e: any) => handleBillEditClick(row)}>
                  <FontAwesomeIcon
                    icon={["fal", "edit"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Editar
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" onClick={(e: any) => handleSessionChangeClick(row)}>
                  <FontAwesomeIcon
                    icon={["fal", "exchange"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Trocar Session
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      );
    } else {
      return (
        <>
          <div className="d-flex justify-content-center">
            <Dropdown drop="start">
              <Dropdown.Toggle variant="light ">
                <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
              </Dropdown.Toggle>

              <Dropdown.Menu>
                <Dropdown.Item eventKey="1" disabled={true}>
                  <FontAwesomeIcon
                    icon={["fal", "edit"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Editar
                </Dropdown.Item>
                <Dropdown.Item eventKey="2" disabled={true}>
                  <FontAwesomeIcon
                    icon={["fal", "exchange"]}
                    size="1x"
                  //style={{ marginRight: "1px" }}
                  />
                  Trocar Session
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </div>
        </>
      );
    }
  }

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  function convertDate(cell: any, row: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    /* console.log(date) */
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addStatus(cell: any, row: any) {
    return (
      cell !== 1 ?
        <FontAwesomeIcon
          icon={["fal", "check"]}
          size="1x"
          style={{ marginRight: "1px" }}
        />
        : <></>
    );
  }

  function addNote(cell: any, row: any) {
    return (
      <>
        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">{cell}</Tooltip>
          }>
          <span className="text-wrap-custom">
            {cell}
          </span>
        </OverlayTrigger>
      </>

    )
  }

  function addTotalPax(cell: any, row: any) {
    return (
      <div
        className={"quantities"}
      >
        {cell}
        <div className="quantities-container">
          <h6 className="">Quantidades</h6>
          <div className="table">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={row.quantities ? row.quantities : []}
              columns={columnsQuantities}
              //rowClasses={rowClasses}
              striped={true}
            />
          </div>
        </div>
      </div>
    );
  }

  /* ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  const columns = [
    { dataField: "codigoProduto", text: "Cód. Produto", sort: true },
    { dataField: "formaPagamento", text: "Forma de Pag.", sort: true },
    { dataField: "descricao", text: "Descrição", sort: true },
    //{ dataField: "cliente", text: "Cliente", sort: true },
    { dataField: "agente", text: "Agente", sort: true },
    { dataField: "totalPax", text: "QTD", sort: true, formatter: addTotalPax  },
    //{ dataField: "totalAdult", text: "ADT", sort: true },
    //{ dataField: "totalChild", text: "CHD", sort: true },
    //{ dataField: "totalInfant", text: "INF", sort: true },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: convertValue,
    },
    {
      dataField: "vencimento",
      text: "Vencimento*",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "observacao", text: "Observação*", sort: true, formatter: addNote },
    { dataField: "statusPagamento", text: " ", formatter: addStatus },
    { dataField: "localizador", text: "Ações", formatter: addActionButton },
  ];

  const columnsQuantities = [
    {
      dataField: "description",
      text: "Descrição",
    },
    {
      dataField: "quantity",
      text: "Qtde.",
    },
    {
      dataField: "value",
      text: "Valor",
      formatter: convertValue,
    },
  ];
  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */


  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando sessions...", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 2) {
      return 'border-analise';
    } else if (row.statusPagamento === 3 || row.statusPagamento === 4) {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };
  console.log(bills)
  if (bills !== null) {
    return (
      <>
        <div className="table-default session-bills-receive-table">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={handlePrintClick}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={bills}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            {billSessionChange !== null ?
              <Modal
                className="modalReceber"
                show={sessionChangeModalShow}
                onHide={() => setSessionChangeModalShow(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton></Modal.Header>
                  <FormSessionChange action={handleActionSessionChangeModal} bill={billSessionChange} date={sessionDate} operationType={sessionOperationType} sessionRelocAtual={sessionReloc} />
                </>
              </Modal> : <></>}
            {billEdit !== null ?
              <Modal
                className="modalReceber"
                show={billEditModalShow}
                onHide={handleActionBillEditHideModal}
                size="xl"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header className="p-3" closeButton></Modal.Header>
                  <FormBillReceiveEdit action={handleActionBillEditModal} info={billEdit} />
                </>
              </Modal> : <></>}
          </div>
        </div>
      </>
    );
  } else if (bills === null && loading === true) {

    return (
      <>
        <div className="suppliers-table table-default loading d-flex w-100 justify-content-center mt-4">
          <div className="table-container text-center">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
  else {
    return (
      <>
        <div className="suppliers-table not-found d-flex w-100 justify-content-center mt-4">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSessionBillsReceive;
