/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useEffect, useState } from "react";
import { Container, Row, Col, Table } from "react-bootstrap";

import "../../../../assets/sass/modal.scss";
import "./BillsPayableSettle.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../services/api';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import Pagination from "../../../../components/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import FormBillsToPayExtract from "../../../../components/Forms/FormBillsToPayExtract/FormBillsToPayExtract";

export interface propModal {
  billsToPay: any;
  totalRows: any;
  searchBillsToPay: any;
  sessionLocalation: any;
  accountId: any;
  accountName: any;
  
  show: any;
  setModalContent: any;
  setResponseText: any;
  setLog: any;
  loading: any;
};

const BillsPayableSettle: React.FC<propModal> = ({
  billsToPay, totalRows, searchBillsToPay, sessionLocalation, accountId, accountName
  , show, setModalContent, setResponseText, setLog
  , loading
}: propModal, props: any) => {

  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);

  const [billEdit, setBillEdit] = useState<any>(null);


  const [billUndoSettlementModalShow, setBillUndoSettlementModalShow] = useState<any>(false);
  const [undoSettlementMsg, setUndoSettlementMsg] = useState<any>('');

  const [billSettleModalShow, setBillSettleModalShow] = useState<any>(false);
  

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;


  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  function formatValue(cell: any) {
    //console.log(Math.sign(cell))
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  }

  useEffect(() => {
    
    refreshData();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);


  function refreshData() {
    searchBillsToPay(sessionLocalation, pageCount, rowsPerPage);
  }


  function addStatus(cell: any) {
    return (
      <div
        className={
          cell === "Conciliado"
            ? "bg-analise"
            : cell === "Pago"
              ? "bg-ativo"
              : cell === "Pendente"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  async function handleSettleBill(item: any) {
    // do chama modal
    setBillEdit(item); 
    setBillSettleModalShow(true);
  }

  function handleUndoSettlement(item: any) {
    setBillEdit(item);

    if(item.idLote > 0) {
      setUndoSettlementMsg("Liquidação em lote, deseja mesmo desfazer a liquidação? A liquidação de todo o lote sera desfeita");
    } else {
      setUndoSettlementMsg("Deseja mesmo desfazer a liquidação do pagamento?");
    }
  
    setBillUndoSettlementModalShow(true);
  }

  function handleActionBillUndoSettlementModal() {
    setBillUndoSettlementModalShow(false);

    const token = localStorage.getItem('GroupId') || '';

    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    setModalContent("loading");
    show(true);

    const salvar = async () => {
      try {
          const { data } = await api.post('BillsPayable/UndoBillSettlement', {
            manifestId: billEdit.manifestId,
            tipo: billEdit.tipo,
            uid: uId,
            usuario: `${userName} ${userLastName}`
          }, config);

          if (data.status !== 400) {
              var response = data.data;

              //alert(response.texto);
              setResponseText(response.texto);
              setLog(response.log);

              if(response.log !== 1) {
                setBillEdit(null);
                setModalContent("successNoRefresh");
                searchBillsToPay(sessionLocalation, pageCount, rowsPerPage);

              } else {
                setModalContent("error");
              }
          }   
      }catch(error: any) {
        setResponseText("Erro, tente novamente mais tarde");
        setLog(1);
        setModalContent("error");
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
      }
    }
    salvar();
  }

  

  function viewDetails(item: any) {
    // do
  }

  if(billsToPay !== undefined && loading === false) {
    return (
      <>
        <Modal.Body className="p-0">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="h-100 flex-column view-extract-table">
                <div className="table-container">   
                  <Table striped >
                    <thead>
                      <tr>
                        <th>Vencimento</th>
                        <th>Fornecedor</th>
                        <th>Data Operação</th>
                        <th>Descrição</th>
                        <th>Valor</th>
                        <th>Status Pagamento</th>
                        <th>Ações</th>
                      </tr>
                    </thead>
                    <tbody>
                      {billsToPay.length > 0 ? billsToPay.map((item: any, index: any) => (
                      <tr key={index}> 
                        <td>{formatDate(item.vencimento)}</td>
                        <td>{item.fornecedor}</td>
                        <td>{formatDate(item.dataOperacao)}</td>
                        <td>{item.descricao}</td>
                        <td>{formatValue(item.valor)}</td>

                        <td>{addStatus(item.statusPagamento)}</td>

                        <td>
                          <div className="d-flex justify-content-center btn-acao">
                            {/* ações */}
                            {/* Visualizar (eye) */}
                            <button className="btn btn-default p-0 m-0" style={{width:"20px"}} title="Visualizar" onClick={(e:any) => viewDetails(item)}><FontAwesomeIcon
                              size="sm"
                              icon={["fal", "eye"]}
                              style={{ color: "#707070", margin: "0px", padding: "0px"}}
                            /></button>
                            {/* Conciliar */}
                            {item.statusPagamento === 'Pago' ?
                              <button className="btn btn-default p-0 m-0" style={{width:"20px"}} title="Desfazer Liquidação" onClick={() => handleUndoSettlement(item)}><FontAwesomeIcon
                                  size="sm"
                                  icon={["fal", "strikethrough"]}
                                  style={{ color: "#707070", margin: "0px", padding: "0px"}}
                              /></button>
                            : //item.statusPagamento === 'Conciliado' ?
                              <button className="btn btn-default p-0 m-0" style={{width:"20px"}} title="Liquidar" onClick={() => handleSettleBill(item)}><FontAwesomeIcon
                                  size="sm"
                                  icon={["fal", "dollar-sign"]}
                                  style={{ color: "#707070", margin: "0px", padding: "0px"}}
                              /></button>
                            //: <></>
                            }
                          </div>
                        </td>
                      </tr>
                      ))
                      :
                      <tr><td colSpan={7} className="mb-2 text-center"><span>Nenhum item encontrado</span></td></tr>}
                    </tbody>
                  </Table>
                  <Pagination
                  totalRows={totalRows}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  selectNumberRows="yes"
                />
                </div>

                {/* settle bill modal */}
                <Modal
                  className={"modal-custom modalAuth modal-large"}
                  show={billSettleModalShow}
                  onHide={() => setBillSettleModalShow(false)}
                  size="lg"
                  aria-labelledby="contained-modal-title-vcenter"
                >
                  <>
                    <Modal.Header closeButton>
                      <h3>
                        Liquidar
                      </h3>
                    </Modal.Header>
                    <hr style={{ margin: "30px 0" }} />

                    <FormBillsToPayExtract accountId={accountId} accountName={accountName} billEdit={billEdit} refreshData={refreshData} modalShow={billSettleModalShow} setModalShow={setBillSettleModalShow} show={show} setModalContent={setModalContent} setResponseText={setResponseText} setLog={setLog} />
                  </>
                </Modal>
                {/* END settle bill modal */}

                {/* undo settlement modal */}
                <ModalQuestion
                    modalShow={billUndoSettlementModalShow}
                    setModalShow={setBillUndoSettlementModalShow}
                    titulo="Desfazer Liquidação"
                    mensagem={undoSettlementMsg}
                    handleActionConfirmModal={handleActionBillUndoSettlementModal}
                />
                {/* END undo settlement modal */}
            </Row>
          </Container>
        </Modal.Body>
  
      </>
    );
  } else if(loading === true) {
    return (
      <>
        <Modal.Body className="p-0">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="flex-column loading-table">
                <div className="table-container">   
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={loadingProducts}
                    hover={true}
                    columns={loadingColumns}
                    striped={true}
                  />
                  <Pagination
                    totalRows={totalRows}
                    pageCount={pageCount}
                    setPageCount={setPageCount}
                    rowsPerPage={rowsPerPage}
                    setRowsPerPage={setRowsPerPage}
                    selectNumberRows="yes"
                  />
                </div>
            </Row>
          </Container>
        </Modal.Body>
  
      </>
    );
  } else {
    return (<></>);
  }
  
}

export default BillsPayableSettle;
