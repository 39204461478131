import React, { useEffect, useState } from 'react';
import { Card, Row } from 'react-bootstrap';

import { Chart } from "react-google-charts";

export interface propInfo {
    infoChart: any;
    loading: any;
    minValue: any;
    maxValue: any;
}

const ChartReportCashFlow: React.FC<propInfo> = ({
    infoChart, loading, minValue, maxValue
}) => {
    // const color_primary: any = process.env.REACT_APP_COLOR_PRIMARY;
    // const color_secondary: any = process.env.REACT_APP_COLOR_SECONDARY;

    const [salesInfo, setSalesInfo] = useState<any>(null);

    useEffect(() => {
        const dataVendas: any = [
            ["Data", "Recebimentos", "Pagamentos", "Saldo"],
            ...infoChart
        ]

        setSalesInfo(dataVendas);

    }, [infoChart])

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
    }

    //const ticksize = (maxValue + Math.abs(minValue)) / 4;
    const ticksize = 200000;
    const minV = minValue-(ticksize/2);
    const maxV = maxValue+(ticksize);
    //const minV = minValue-ticksize;
    //const maxV = maxValue+ticksize;

    var ticks: any = [];

    ticks.push({
        v: 0,
        f: formatValue(0)
    });

    for (var i = minV; i <= maxV; i = i + ticksize) {

        ticks.push({
            v: i,
            f: formatValue(i)
        });
    }

    const optionsVendas = {
        title: "Fluxo de Caixa Diário",
        vAxis: { title: "", textStyle: {
            fontSize: 12 
          }, ticks: ticks },
        hAxis: { title: "", textStyle: {
            fontSize: 12 
          } },
        seriesType: "bars",
        series: { 2: { type: "line", pointSize: 5, lineWidth: 3 } },
        legend: { position: 'bottom' },
        //colors: ['#4473C4', '#ED7C31', '#A5A5A5'], // azul, vermelho, cinza
        colors: ['#4ABA58', '#B54848', '#395B7B'], // verde, vermelho, azul
        chartArea: {width: '90%', bottom: 80 },
    };

    return (
        <>
            {/* <Row className="cards">
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                    Total de Vendas
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                    {totalSales.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "coins"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
                <div className="col-md-2 card-unit">
                    <Card>
                        <div className="col-md-10">
                            <Row>
                                <span className='card-title'>
                                    Total de Reservas
                                </span>
                            </Row>
                            <Row>
                                <span className='cart-content'>
                                    {totalReservations}
                                </span>
                            </Row>
                        </div>
                        <div className="col-md-2 d-flex justify-content-center align-items-center">
                            <FontAwesomeIcon
                                icon={["fad", "ballot-check"]}
                                className="text-primary p-1"
                                size="3x"
                            />
                        </div>
                    </Card>
                </div>
            </Row> */}

            <Row >

                <div className="col-md-12 mt-3">
                    <Card className="h-100">
                        <div className="p-3">
                            {salesInfo !== null && loading === false
                                ?
                                <> 
                                    <Chart
                                        chartType="ComboChart"
                                        width="100%"
                                        height="350px"
                                        data={salesInfo}
                                        options={optionsVendas}
                                        formatters={[
                                            {
                                              type: "NumberFormat",
                                              column: 1,
                                              options: { fractionDigits: 2, decimalSymbol: ',', groupingSymbol: '' }
                                            },
                                            {
                                              type: "NumberFormat",
                                              column: 2,
                                              options: { fractionDigits: 2, decimalSymbol: ',', groupingSymbol: '' }
                                            },
                                            {
                                              type: "NumberFormat",
                                              column: 3,
                                              options: { fractionDigits: 2, decimalSymbol: ',', groupingSymbol: '' }
                                            },
                                          ]}
                                    />
                                </>
                                :
                                <>
                                    <div className="animated-background row-loading" style={{ height: '350px' }}></div>
                                </>
                            } 
                        </div>
                    </Card>
                </div>
                
            </Row>
        </>

    );
}

export default ChartReportCashFlow;