import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Col, Card, Form, Row, Button, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import GetProductsList from "../../../Stock/components/ProductList/GetProductsList";
import api from "../../../../services/api";
import ModalShow from "../../../../components/Modal/ModalShow/ModalShow";


//import api from "../../services/api";
export interface props {
    isEdit?: boolean
}

const InsertStockGroupSession: React.FC<props> = ({
    isEdit = false
}) => {
    const [loading, setLoading] = useState<boolean>(true);
    const [showModalProducts, setShowModalProducts] = useState<boolean>(false);
    const [products, setProducts] = useState<any>([]);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");

    const {
        control,
        handleSubmit,
        setValue,
        getValues,
        formState: { errors },
    } = useForm({});

    const onSubmitProducts = () => {
        let data: any = getValues().products;
        setProducts(data.map((elem: any) => { return ({ productCode: elem.value, productName: elem.label }) }));
        setShowModalProducts(false);
    }

    const handleDeleteProduct = (index: number) => {
        setProducts((prev: any) => [...prev.slice(0, index), ...prev.slice(index + 1)]);
        setValue('products', [...products.slice(0, index), ...products.slice(index + 1)]?.map((elem: any) => { return ({ value: elem.productCode, label: elem.productName }) }));
    }

    const putInfoOnScreen = (data: any) => {
        setValue('groupSessionUniqueCode', `${data?.groupSessionUniqueCode}`);
        setValue('name', data?.groupSessionName || '');
        setValue('status', `${data?.statusStockGroupSession}` || '1');
        setValue('products', data?.stockGroupSessionItens?.map((elem: any) => { return ({ value: elem.productCode, label: elem.productName }) }));
        setProducts(data?.stockGroupSessionItens?.map((elem: any) => { return ({ productCode: elem.productCode, productName: elem.productName }) }))
    }

    const getStockGroup = async () => {

        let code: string = window.location.href.split('?id=')[1]

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res: any = await api.post(`/Stock/GetGroupSession`, {
                "GroupSessionUniqueCode": code,
                "ProdModCodeUnique": "",
                "TarCodeUnique": ""
            }, config);

            if (res.status === 200) {
                putInfoOnScreen(res.data.data[0]);
                setLoading(false);
            }
        } catch (error: any) { }
    };

    useEffect(() => {
        if (isEdit) getStockGroup();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const onSubmit = async (data: any) => {
        setModalShow(true);

        let obj: any = {
            "groupSessionUniqueCode": isEdit ? data.groupSessionUniqueCode : "", //No insert mandar vazio -- esse dado sera criado / No editar é obrigatorio
            "groupSessionName": data.name,
            "statusStockGroupSession": data.status,
            "stockGroupSessionItens": products.map((elem: any) => { return ({ productCode: elem.productCode }) })
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        try {
            const res = await api.post(isEdit ? 'Stock/EditStockGroupSession' : 'Stock/InsertStockGroupSession', obj, config);
            if (res.status === 200) {
                if (res.data.statusCode === 200) {
                    if (res.data.data.item1 === undefined) { //Sucesso
                        setModalLog(res.data.data.log);
                        setModalMessage(res.data.data.texto);
                    } else { //ERRO
                        setModalLog(1);
                        setModalMessage(res.data?.data?.item1[0].texto || res.data?.errorMessage || 'Erro ao processar operação!');
                    }
                } else {
                    setModalLog(1);
                    setModalMessage(res.data?.data?.texto || res.data?.errorMessage || 'Erro ao processar operação!');
                }
            } else {
                setModalLog(1);
                setModalMessage('Erro ao processar operação!');
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage(error?.response?.data?.errorMessage || 'Erro ao processar operação!');
        }
    }

    return (
        <>
            <Card className="bg-white mt-4 p-4" >
                <Card.Header className="cart-header bg-white border-0 text-primary font-weight-bold " style={{ padding: '0 0 0 15px' }}>
                    Novo Grupo de Estoque
                </Card.Header>
                <Card.Body>
                    {isEdit && loading ?
                        <>
                            <Col>
                                <Card className="pt-content h-100">
                                    <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                        <div className="load mb-4"></div>
                                        <div>Carregando dados do grupo de estoque</div>
                                    </div>
                                </Card>
                            </Col>
                        </>
                        :
                        <Form
                            className="h-100"
                            noValidate
                            validated={false}
                            onSubmit={handleSubmit(onSubmit)}
                            onKeyDown={(e: any) => {
                                if (e.code === "Enter" || e.code === "NumpadEnter")
                                    e.preventDefault();
                            }}
                        >
                            <Row className="mb-5">
                                <Form.Group as={Col} md="4">
                                    <Form.Label>Nome</Form.Label>
                                    <Controller
                                        control={control}
                                        name="name"
                                        defaultValue={''}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="text"
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="4">
                                    <Form.Label>Status</Form.Label>
                                    <Controller
                                        control={control}
                                        name="status"
                                        defaultValue={'1'}
                                        render={({ field }: any) => (
                                            <div className="endpoint">
                                                <Form.Select
                                                    {...field}
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                >
                                                    <option value='1' >Ativo</option>
                                                    <option value='2' >Inativo</option>
                                                </Form.Select>
                                            </div>
                                        )}
                                    />
                                </Form.Group>

                                <Controller
                                    control={control}
                                    name={`groupSessionUniqueCode`}
                                    render={({ field }: any) => (
                                        <Form.Control
                                            {...field}
                                            type="hidden"
                                            variant="standard"
                                            margin="normal"
                                            autoComplete="off"
                                        />
                                    )}
                                />
                            </Row>
                            <Row className="mb-5">
                                <div>
                                    <button className="d-flex align-items-center btn btn-bills" type="button" onClick={() => { setShowModalProducts(true); }}>
                                        Vincular Produto +
                                    </button>
                                </div>
                            </Row>

                            <div className="mb-5 d-flex flex-wrap">
                                {products.map((elem: any, index: number) => {
                                    return (
                                        <span className="product-card rounded mb-2">
                                            <span className="">
                                                {elem.productName}
                                            </span>
                                            <span className="text-white" onClick={() => { handleDeleteProduct(index) }}>
                                                <FontAwesomeIcon icon={["fal", "trash-alt"]} />
                                            </span>
                                        </span>
                                    )
                                })}
                            </div>

                            <Row className="mx-0 mt-5 d-flex justify-content-end">
                                {/* <Button
                                    variant="outline-primary"
                                    className="form-button mr-3"
                                    onClick={() => { window.location.href = "/products/list-stock-group"; }}
                                >
                                    Voltar
                                </Button> */}
                                <Button
                                    type="submit"
                                    className="form-button"
                                >
                                    Salvar
                                </Button>
                            </Row>

                            <Modal
                                className={"modal-large modal-custom modalAuth"}
                                show={showModalProducts}
                                onHide={() => {
                                    setShowModalProducts(false);
                                }}
                                size="lg"
                                aria-labelledby="contained-modal-title-vcenter"
                            >
                                <Modal.Header closeButton>
                                    <div className="d-flex justify-content-between w-100 mb-4">
                                        <h3>Vincular Produtos</h3>
                                    </div>
                                </Modal.Header>

                                <Row className="mb-3">
                                    <Form.Group as={Col} md="12" className="mb-4">
                                        <Form.Label>Produto:</Form.Label>
                                        <Controller
                                            control={control}
                                            name="products"
                                            defaultValue={[]}
                                            rules={{ required: { value: false, message: 'Por favor, informe ao menos 1 produto' } }}
                                            render={({ field }: any) => (
                                                <div className="">
                                                    <GetProductsList
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        isMulti
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                </Row>

                                <Row as={Col} md="12" className="mt-3 justify-content-md-end">
                                    <Col xs lg="12" className="d-flex justify-content-md-end">

                                        <button className="btn btn-default btn-outline-primary px-4 mr-3" type='button' onClick={() => { setShowModalProducts(false); }}>
                                            Cancelar
                                        </button>

                                        <button className="btn btn-default btn-primary" type='button' onClick={() => { onSubmitProducts() }}>
                                            Confirmar
                                        </button>
                                    </Col>
                                </Row>
                            </Modal>
                        </Form>
                    }
                </Card.Body>
            </Card>

            <ModalShow
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                modalLog={modalLog}
                setModalLog={setModalLog}
                redirect={'/products/list-stock-group'}
            />

            {/* <Modal
                className="modalAuth"
                show={modalShow}
                onHide={() => {
                    if (modalLog === 0) {
                        //redirect
                    }
                    setModalLog(null);
                    setModalMessage("Carregando");
                    setModalShow(false);
                }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                    <Modal.Body
                        className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                        style={{ height: "600px" }}
                    >
                        {modalLog === null ? (
                            <>
                                <div className="loading-modal">
                                    <div className="load"></div>
                                </div>
                            </>
                        ) : (
                            <div>
                                {modalLog === 0 ? (
                                    <FontAwesomeIcon
                                        icon={["fal", "check-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                ) : (
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                )}
                            </div>
                        )}
                        <div>{modalMessage}</div>
                        <div className="d-flex justify-content-center pt-3">
                            <button
                                onClick={() => {
                                    if (modalLog === 0) {
                                        //redirect
                                    }
                                    setModalLog(null);
                                    setModalMessage("Carregando");
                                    setModalShow(false);
                                }}
                                className="btn btn-primary mx-2 w-25"
                            >
                                Confirmar
                            </button>
                        </div>
                    </Modal.Body>
                </>
            </Modal> */}

        </>
    );
}

export default InsertStockGroupSession;
