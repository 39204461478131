import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { Calendar } from "react-multi-date-picker";
import Slider from "react-slick";

export interface propPlus {
  changeDate: any,
  selectedRange: any,
  loadingHours: boolean,
};

const DateSlider: React.FC<propPlus> = ({
  changeDate, selectedRange, loadingHours
}: propPlus) => {

  const [maxDate, setMaxDate] = useState<any>();
  const [minDate, setMinDate] = useState<any>();

  const [dates, setDates] = useState<Date[]>([]);

  const [indexDates, setIndexDates] = useState<any>(0);

  // Obtenha a data atual
  const today = new Date();

  const ano = today.getFullYear();
  const mes = (today.getMonth() + 1).toString().padStart(2, '0'); // +1 porque janeiro é 0
  const dia = today.getDate().toString().padStart(2, '0');

  const getDates = (firsDateClicked: any = new Date(`${ano}-${mes}-${dia}T00:00:00`/* selectedRange.startDate */)) => {
    const firstStockDay: any = firsDateClicked;
    const lastStockDay: any = new Date(selectedRange.endDate);
    const datesArray: any = [];

    const diffInMs: any = Number(lastStockDay - firstStockDay)
    const diffInDays: any = diffInMs / (1000 * 60 * 60 * 24); //diferença de dias entre o inicio e fim do tarifário

    for (let i = 0; i < (diffInDays >= 10 ? 11 : diffInDays); i++) {
      const newDate = new Date(firstStockDay);
      newDate.setDate(firstStockDay.getDate() + i);
      datesArray.push(newDate);
    }

    setDates(datesArray);
    changeDia(firsDateClicked.toJSON().slice(0, 10), 0);
  };

  useEffect(() => {
    setMaxDate(() => new Date(selectedRange.endDate))
    setMinDate(() => new Date(selectedRange.startDate))

    getDates();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function changeDia(data: any, index: any) {
    setIndexDates(index);
    changeDate(() => data.split('/').reverse().join('-'))
  }

  const settings = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 12,
    swipeToSlide: true,
    //slidesToScroll: 1,
    //focusOnSelect: true,
  };

  /* let seila: any = {
    "Amount": 300,
    "StockAmountsByChannel": [
      {
        "Amount": 100,
        "IdSalesChannel": 1
      },
      {
        "Amount": 100,
        "IdSalesChannel": 2
      },
      {
        "Amount": 100,
        "IdSalesChannel": 4,
        "AmountLimit": "10"
      }
    ],
    "StartHour": "12:00:00",
    "EndHour": ""
  }, */


  return (
    <>
      {indexDates !== 'mais'
        ?
        <>
          <Slider {...settings}>
            {dates.map((date, index) => {
              return (
                <div key={date.getTime()} className="">
                  <div onClick={() => { if (!loadingHours) changeDia(date.toLocaleDateString('pt-BR'), index) }} className={index === indexDates ? 'bg-primary rounded text-white' : ''} style={{ padding: '10px', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center' }} >
                    {date.toLocaleDateString('pt-BR', { month: 'short' }).split('.')[0]}
                    <p className="mb-0" style={{ fontSize: '32px', fontWeight: 700 }}>{date.toLocaleDateString('pt-BR').split('/')[0]}</p>
                    {date.toLocaleDateString('pt-BR', { weekday: 'short' }).split('.')[0]}
                  </div>
                </div>
              );
            })}

            <div className="">
              <div onClick={() => setIndexDates('mais')} className={indexDates === 'mais' ? 'bg-primary rounded text-white' : 'bg-secundary rounded'} style={{ padding: '10px', margin: '10px', display: 'flex', flexDirection: 'column', alignItems: 'center', height: '102px', justifyContent: 'center' }} >
                <FontAwesomeIcon className="" icon={['fal', 'plus']} size="2x" />
                <small className="text-center">Ver mais</small>
              </div>
            </div>
          </Slider>
        </>
        :
        <>
          <Calendar
            maxDate={maxDate}
            minDate={minDate}
            onChange={(e: any) => {
              const selectedYear = new Date(e).getFullYear();
              const selectedMonth = (new Date(e).getMonth() + 1).toString().padStart(2, '0'); // +1 porque janeiro é 0
              const selectedDay = new Date(e).getDate().toString().padStart(2, '0');
              if (!loadingHours) {
                getDates(new Date(`${selectedYear}-${selectedMonth}-${selectedDay}T00:00:00`))
              }
            }}
          />
        </>
      }
    </>
  );
};
export default DateSlider;