/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import api from '../../../../services/api';

import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersFilter';

import {
  Row,
  Col,
  InputGroup,
  //Badge,
  Dropdown,
  //ModalBody,
  //ModalFooter,
} from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';
import { ErrorMessage } from '@hookform/error-message';

import Modal from 'react-bootstrap/Modal';
import Form from 'react-bootstrap/Form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './ListCoupom.scss';
//import SingleCalendar from "../../../../components/Calendar/SingleCalendar";
import SingleCalendarEdit from '../../../../components/Calendar/SingleCalendarEdit';
import GetProductsListCod from '../../../../components/C2Points/GetProductsListCod';

export interface propInfo {
  currentCoupom: any;
  editClick: any;
  show?: any;
  setResponseText?: any;
  setModalContent?: any;
  setLog?: any;
}

const ListCoupom: React.FC<propInfo> = ({
  currentCoupom,
  editClick,
  show,
  setResponseText,
  setModalContent,
  setLog,
}: propInfo) => {
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalMessage, setModalMessage] = useState<any>('');
  const [date, setDate] = useState<any>();
  const [vencimento, setVencimento] = useState<any>();
  const [item, setItem] = useState<any>(null);
  const [editCoupom, setEditCoupom] = useState<boolean>(editClick);

  const [cupom, setCupom] = useState<boolean>(false);
  const [supplier, setSupplier] = useState<boolean>();
  const [preLoad, setPreLoad] = useState<any>(false);

  const [valueD, setValueD] = useState<any>(item?.valueDiscount);
  const [valueC, setValueC] = useState<any>(item?.valueCommission);
  const [maxDiscount, setMaxDiscount] = useState<number>(20);
  const [maxDiscountReal, setMaxDiscountReal] = useState<number>(10);

 
  const validated = false;

  const {
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const fixDate = (data: any) => {
    var aux = data.split('/');

    return `${aux[2]}-${aux[1]}-${aux[0]}`;
  };

  const typeD = watch('typeDiscount', 4);
  const typeC = watch('typeCommission', 4);
  const isGlobal = watch('isGlobal', item?.products.length !== 0 ? '2' : '1');

  useEffect(() => {
    if (valueD > maxDiscount) {
      setValueD(maxDiscount);
    }

    if (valueC > maxDiscount) {
      setValueC(maxDiscount);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueD, typeD, valueC, typeC, cupom]);

  
  useEffect(() => {
    setPreLoad(true);

    const getDetails = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
          'Content-Type': 'application/json',
        },
      };
      try {
        const res = await api.get(
          `TourCode/GetTourCodeCupomByCode/${currentCoupom}`,
          config,
        );
        if (res.status !== 400) {
          fillValues(res.data.data);
          setItem(res.data.data);
          setPreLoad(false);
          setValueD(res.data.data.valueDiscount);
          setValueC(res.data.data.valueCommission);
        }
      } catch (error: any) {}
    };
    getDetails();
    function fillValues(e: any) {
      let auxProducts: any = e.products.map((elem: any) => { return { "label": elem.idDesc, "code": elem.productCode } });
      setValue('products', auxProducts);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const [loading, setLoading] = useState<any>(false);

  function verifyDate(now: any, due: any) {
    var nowArray: any = now.split('-').join('').split('T')[0];
    var dueArray: any = due.split('-').join('').split('T')[0];

    if (Number(nowArray) >= Number(dueArray)) {
      return false;
    }

    return true;
  }

  function closeModal() {
    var closeBtn: any = document.getElementsByClassName('btn-close')[0];
    if (closeBtn !== undefined) {
      closeBtn.click();
    }
  }

  const onSubmit = (data: any) => {
    setLoading(true);

    if (item.beginDate === null) {
      data.beginDate = '';
      data.endDate =
        vencimento === '' ? item.endDate.split('T')[0] : fixDate(vencimento);
    } else if (item.endDate === null) {
      data.beginDate =
        date === '' ? item.beginDate.split('T')[0] : fixDate(date);
      data.endDate = '';
    } else {
      data.beginDate =
        date === '' ? item.beginDate.split('T')[0] : fixDate(date);
      data.endDate =
        vencimento === '' ? item.endDate.split('T')[0] : fixDate(vencimento);
    }

    data.supplierId = supplier === undefined ? item.supplierId : supplier;
    data.cupom = cupom !== true ? 2 : 1;
    data.categoriaId =
      data.categoriaId === undefined ? item.categories : data.categoriaId;
    data.productId =
      data.productId === undefined ? item.products : data.productId;
    data.typeCommission = Number(item.typeCommission);
    data.valueCommission = valueC;
    data.typeDiscount = Number(item.typeDiscount);
    data.valueDiscount = valueD;
    data.codeName = cupom === true ? data.codeName : undefined;

    if (verifyDate(data.beginDate, data.endDate)) {
      const insertCoupon = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
          headers: {
            Authorization: `Bearer ${token}`,
            'Content-Type': 'application/json',
          },
        };
        try {
          const res = await api.post(
            `/TourCode/UpdateTourCodeCupomAsync`,
            {
              tourCodeId: item?.tourCodeId,
              beginDate: data.beginDate,
              endDate: data.endDate,
              valueDiscount: Number(data.valueDiscount),
              valueCommission: Number(data.valueCommission),
              codeName: data.descricao,
              products: 
                isGlobal === '1'
                  ? []
                  : data.products.map((e: any) => {
                      return ({
                        "productType": e.productType,
                        "productCode": e.code
                      })
                    }),
             },
            config,
          );
          if (res.status !== 400) {
            if (res.status !== 400) {
              setResponseText(res.data.data.texto);
              setLog(res.data.data.log);
              setModalContent('success');
            } else {
              setResponseText(res.data.data.texto);
              setLog(res.data.data.log);
              setModalContent('error');
            }
            setLoading(false);
            closeModal();
            show(true);
          }
        } catch (error: any) {
          setModalShow(true);
          setModalContent('error');
          setModalMessage('Preencher valor de Desconto e Comissão');
          setLoading(false);
        }
      };

      insertCoupon();
    } else {
      setModalShow(true);
      setModalContent('error');
      setModalMessage('Data de vencimento antes da data atual.');
      setLoading(false);
    }
  };

  useEffect(() => {
    if (item?.isCupom === 1) {
      setCupom(true);
    } else {
      setCupom(false);
    }
  }, [item?.isCupom]);

  useEffect(() => {
    setValue('codeName', item?.code);
    setValue('descricao', item?.codeName);
  }, [item, setValue]);

  if (item !== null) {
    return (
      <>
        <Form
          className="h-100"
          noValidate
          validated={validated}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="false"
        >
          <Modal.Header
            className="align-items-center rounded-0 p-4"
            closeButton
          >
            <div className="d-flex justify-content-between w-100">
              <div>
                <h3 className="pt-1 text-primary">{'Editar cupom'}</h3>
              </div>

              <div className="buttons-options">
                <div className="d-flex justify-content-between align-items-center w-100">
                  {editCoupom === false ? (
                    <Dropdown drop="down">
                      <Dropdown.Toggle className="options-reservations mt-2">
                        <FontAwesomeIcon
                          icon={['fal', 'angle-down']}
                          className="mx-2 text-info"
                          size="2x"
                          //style={{ marginTop: "-3px" }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            setEditCoupom(!editCoupom);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'edit']}
                            //className="mr-2"
                          />
                          <span>Editar</span>
                        </Dropdown.Item>

                        <Dropdown.Item
                          eventKey="1"
                          //className="text-danger"
                          onClick={() => {}}
                          disabled={true}
                        >
                          <FontAwesomeIcon
                            icon={['fal', 'ban']}
                            //className="mr-2 "
                          />
                          <span>Cancelar</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="pt-0" style={{ padding: '25px' }}>
            <hr
              className="w-100 mt-0 mb-4"
              style={{ borderBottom: '1px solid' }}
            />

            <div className="w-100 p-0">
              <div>
                <Row className="mb-3">
                  {editCoupom === false ? (
                    <>
                      {/* <Form.Group as={Col} md="3" controlId="">
                        <div
                          className="d-flex align-items-center pt-4"
                          style={{ height: "70px" }}
                        >
                          <Controller
                            control={control}
                            name="cupom"
                            //defaultValue={"2"}
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                //defaultChecked={item?.isCupom === 2 ? true : false}
                                //onClick={() => { setCupom(false) }}
                                type="radio"
                                variant="standard"
                                margin="normal"
                                value="2"
                                required
                                className="mx-2"
                                disabled={true}
                                checked={item?.isCupom === 2 ? true : false}
                              />
                            )}
                          />
                          <Form.Label className="mb-0">TourCode</Form.Label>
                        </div>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6" controlId="">
                        <div
                          className="d-flex align-items-center pt-4"
                          style={{ height: '70px' }}
                        >
                          <Controller
                            control={control}
                            name="cupom"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                //defaultChecked={item?.isCupom === 1 ? true : false}
                                //onClick={() => { setCupom(true) }}
                                type="radio"
                                variant="standard"
                                margin="normal"
                                value="1"
                                required
                                className="mx-2"
                                disabled={true}
                                checked={item?.isCupom === 1 ? true : false}
                              />
                            )}
                          />
                          <Form.Label className="mb-0">Cupom</Form.Label>
                        </div>
                      </Form.Group>
                      {item?.isCupom === 1 ? (
                        <>
                          <Form.Group as={Col} md="6" controlId="">
                            <Form.Label>Nome do Cupom</Form.Label>
                            <p style={{ fontSize: '1rem' }}>{item?.code}</p>
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group as={Col} md="6" controlId="">
                          <Form.Label>Nome do TourCode</Form.Label>
                          <p style={{ fontSize: '1rem' }}>{item?.code}</p>
                        </Form.Group>
                      )}
                    </>
                  ) : (
                    <>
                      {/* <Form.Group as={Col} md="3" controlId="">
                        <div
                          className="d-flex align-items-center pt-4"
                          style={{ height: "70px" }}
                        >
                          <Controller
                            control={control}
                            name="cupom"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                //defaultChecked={cupom === false}
                                onClick={() => {
                                  setCupom(false);
                                }}
                                type="radio"
                                variant="standard"
                                margin="normal"
                                required
                                className="mx-2"
                                disabled={true}
                                checked={cupom === false}
                              />
                            )}
                          />
                          <Form.Label className="mb-0">TourCode</Form.Label>
                        </div>
                      </Form.Group> */}

                      <Form.Group as={Col} md="6" controlId="">
                        <div
                          className="d-flex align-items-center pt-4"
                          style={{ height: '70px' }}
                        >
                          <Controller
                            control={control}
                            name="cupom"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                //defaultChecked={cupom === true}
                                onClick={() => {
                                  setCupom(true);
                                }}
                                type="radio"
                                variant="standard"
                                margin="normal"
                                required
                                className="mx-2"
                                disabled={true}
                                checked={cupom === true}
                              />
                            )}
                          />
                          <Form.Label className="mb-0">Cupom</Form.Label>
                        </div>
                      </Form.Group>

                      <Form.Group as={Col} md={6} controlId="formGridSupplier">
                        {cupom === true ? (
                          <>
                            <Form.Label>Nome do Cupom</Form.Label>
                            <Controller
                              control={control}
                              name="codeName"
                              //rules={{ required: { value: true, message: "Por favor, informe o nome do cupom" }, }}
                              //defaultValue={item?.code}
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  aria-invalid={errors?.descricao ? 'true' : ''}
                                  variant="standard"
                                  margin="normal"
                                  autoComplete="off"
                                  disabled
                                />
                              )}
                            />
                            {/* <ErrorMessage
                                errors={errors}
                                name="codeName"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                              /> */}
                          </>
                        ) : (
                          <>
                            <Form.Label>Nome do TourCode</Form.Label>
                            <Controller
                              control={control}
                              name="codeName"
                              //rules={{ required: { value: true, message: "Por favor, informe o nome do cupom" }, }}
                              //defaultValue={item?.code}
                              render={({ field }: any) => (
                                <p style={{ fontSize: '1rem' }}>{item?.code}</p>
                              )}
                            />
                            {/* <ErrorMessage
                                errors={errors}
                                name="codeName"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                              /> */}
                          </>
                        )}
                      </Form.Group>
                    </>
                  )}
                </Row>

                <Row className="mt-3">
                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formGridDate"
                  >
                    <Form.Label>Data de início</Form.Label>
                    {editCoupom === false ? (
                      <p style={{ fontSize: '1rem' }}>
                        {item?.beginDate !== null
                          ? item?.beginDate
                              .split('T')[0]
                              .split('-')
                              .reverse()
                              .join('/')
                          : ''}
                      </p>
                    ) : (
                      <>
                        <InputGroup
                          hasValidation
                          className=""
                          size="sm"
                          placeholder="Quando?"
                        >
                          <SingleCalendarEdit
                            defaultDate={item?.beginDate}
                            setValue={setDate}
                          />
                        </InputGroup>
                      </>
                    )}
                  </Form.Group>

                  <Form.Group
                    as={Col}
                    className="mb-3"
                    controlId="formGridDate"
                  >
                    <Form.Label>Data fim</Form.Label>
                    {editCoupom === false ? (
                      <p style={{ fontSize: '1rem' }}>
                        {item?.endDate !== null
                          ? item?.endDate
                              .split('T')[0]
                              .split('-')
                              .reverse()
                              .join('/')
                          : ''}
                      </p>
                    ) : (
                      <>
                        <InputGroup
                          hasValidation
                          className=""
                          size="sm"
                          placeholder="Quando?"
                        >
                          <SingleCalendarEdit
                            defaultDate={item?.endDate}
                            setValue={setVencimento}
                          />
                        </InputGroup>
                      </>
                    )}
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <Form.Label>Tipo de desconto</Form.Label>
                    <p
                      style={{ fontSize: '1rem', marginTop: '0.25rem' }}
                      className="mb-0"
                    >
                      {item?.typeDiscountDesc}
                    </p>
                  </Form.Group>

                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <Form.Label>{`Valor ${
                      item.typeDiscount === 4
                        ? `(máx: ${maxDiscount}%)`
                        : `(máx: R$20,00)`
                    }`}</Form.Label>

                    {editCoupom === false ? (
                      <>
                        <p style={{ fontSize: '1rem' }}>
                          {item?.valueDiscount}
                        </p>
                      </>
                    ) : (
                      <>
                        <Controller
                          control={control}
                          name="valueDiscount"
                          //rules={{ required: { value: true, message: "Por favor, informe o valor." }, }}
                          defaultValue={item?.valueDiscount}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              aria-invalid={errors?.valueDiscount ? 'true' : ''}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              value={valueD}
                              onChange={(e: any) => setValueD(e.target.value)}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="valueDiscount"
                          render={({ message }) => (
                            <small style={{ color: 'red' }}>{message}</small>
                          )}
                        />
                      </>
                    )}
                  </Form.Group>
                </Row>

                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>Nome amigável</Form.Label>
                    {editCoupom === false ? (
                      <>
                        <p style={{ fontSize: '1rem' }}>{item?.codeName}</p>
                      </>
                    ) : (
                      <>
                        <Controller
                          control={control}
                          name="descricao"
                          //rules={{ required: { value: true, message: "Por favor, informe o valor." }, }}
                          //defaultValue={item?.codeName}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              aria-invalid={errors?.descricao ? 'true' : ''}
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              maxLength={15}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="descricao"
                          render={({ message }) => (
                            <small style={{ color: 'red' }}>{message}</small>
                          )}
                        />
                      </>
                    )}
                  </Form.Group>
                </Row>


                <Row className="mb-3">
                  <Form.Group as={Col} controlId="formGridCity">
                    <Form.Label>Produtos com o Cupom:</Form.Label>
                    {editCoupom === false ? (
                      <>
                      {item?.products.length === 0 ? <p style={{ fontSize: '1rem' }}>Cupom aplicado para todos os produtos.</p> : <div style={{display: "flex", flexWrap: "wrap"}}>{item?.products.map((e: any) => <p style={{ fontSize: '1rem', color: "#707070", padding: "2px 7px", border: "2px solid #ccc", borderRadius: "5px", margin: "0px 5px 5px 0px", backgroundColor: "hsl(0, 0%, 90%)"}}>{`${e.idDesc}`}</p>)}</div>}
                      </>
                    ) : (
                      <>
                        <Controller
                  control={control}
                  name="isGlobal"
                  render={({ field }: any) => (
                    <div className="endpoint">
                      <Form.Select
                        {...field}
                        as="select"
                        variant="standard"
                        margin="normal"
                      >
                        <option value="1">
                          Todos os produtos
                        </option>
                        <option value="2" selected={item?.products.length !== 0}>
                          Escolha os produtos
                        </option>
                      </Form.Select>
                    </div>
                    )}
                  />
                        <ErrorMessage
                          errors={errors}
                          name="products"
                          render={({ message }) => (
                            <small style={{ color: 'red' }}>{message}</small>
                          )}
                        />
                      </>
                    )}
                  </Form.Group>
                </Row>

            {isGlobal === '2' && editCoupom !== false ? (
              <Row className="mb-3">
                <Form.Group as={Col} md="12">
                  <Form.Label>Escolha os produtos para o cupom:</Form.Label>
                  <Controller
                    control={control}
                    name="products"
                    rules={{
                      required: {
                        value: true,
                        message: 'Por favor, informe ao menos 1 produto',
                      },
                    }}
                    render={({ field }: any) => (
                      <div className="">
                        <GetProductsListCod
                          propsField={field}
                          propsErrors={errors}
                          isMulti={true}
                        />
                      </div>
                    )}
                  />
                  <ErrorMessage
                    errors={errors}
                    name="products"
                    render={({ message }) => (
                      <small style={{ color: 'red' }}>{message}</small>
                    )}
                  />
                </Form.Group>
              </Row>
            ) : (
              <></>
            )}

                <hr style={{ margin: '30px 0' }} />

                <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <Form.Label>Cliente</Form.Label>
                    {/* {editCoupom === false ? (
                      <> */}
                    <p style={{ fontSize: '1rem', marginTop: '.25rem' }}>
                      {item?.supplierName}
                    </p>
                    {/* </>
                    ) : (
                      <>
                        <Controller
                          control={control}
                          name="supplierId"
                          //rules={{ required: { value: true, message: "Por favor, informe o fornecedor." }, }}
                          //defaultValue={item?.supplierId}
                          render={({ field }: any) => (
                            <GetSuppliersFilter
                              propsField={field}
                              propsErrors={errors}
                              propsLabel="supplierId"
                              setFilterSupplier={setSupplier}
                              supplier={item?.supplierName}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="supplierId"
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </>
                    )} */}
                  </Form.Group>
                </Row>

                {/* <Row className="mb-3">
                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <Form.Label>Tipo de comissão</Form.Label>
                    <p
                      style={{ fontSize: '1rem', marginTop: '0.25rem' }}
                      className="mb-0"
                    >
                      {item?.typeCommissionDesc}
                    </p>
                  </Form.Group>

                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <Form.Label>{`Comissão (máx: ${maxDiscount}%)`}</Form.Label>

                    {editCoupom === false ? (
                      <>
                        <p style={{ fontSize: '1rem' }}>
                          {item?.valueCommission}
                        </p>
                      </>
                    ) : (
                      <>
                        <Controller
                          control={control}
                          name="valueCommission"
                          //rules={{ required: { value: true, message: "Por favor, informe o valor da comissão." }, }}
                          defaultValue={item?.valueCommission}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              aria-invalid={
                                errors?.valueCommission ? 'true' : ''
                              }
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onKeyPress={(e) =>
                                !/[0-9]/.test(e.key) && e.preventDefault()
                              }
                              value={valueC}
                              onChange={(e: any) => setValueC(e.target.value)}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="valueCommission"
                          render={({ message }) => (
                            <small style={{ color: 'red' }}>{message}</small>
                          )}
                        />
                      </>
                    )}
                  </Form.Group>
                </Row> */}
              </div>
            </div>
          </Modal.Body>
          {editCoupom === false ? (
            <></>
          ) : (
            <>
              <div
                className="d-flex justify-content-end"
                style={{ padding: '0px 25px 25px' }}
              >
                <button
                  className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                  onClick={() => {
                    setEditCoupom(!editCoupom);
                  }}
                >
                  Cancelar
                </button>
                <button
                  className="btn btn-default btn-primary px-4 text-uppercase"
                  type="submit"
                >
                  {loading === true ? (
                    <div className="load"></div>
                  ) : (
                    'Editar Cupom'
                  )}
                </button>
              </div>
            </>
          )}
        </Form>

        <Modal
          className="modal-custom modalAuth"
          show={modalShow}
          onHide={() => {
            setModalShow(false);
            setModalMessage('');
          }}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton></Modal.Header>

            <div className="d-flex w-100 h-100 justify-content-center py-5">
              <h4>{modalMessage}</h4>
            </div>
          </>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <Form
          className="h-100"
          noValidate
          validated={validated}
          onSubmit={handleSubmit(onSubmit)}
          autoComplete="false"
        >
          <Modal.Header
            className="align-items-center rounded-0 p-4"
            closeButton
          >
            <div className="d-flex justify-content-between w-100">
              <div>
                <h3 className="pt-1 text-primary">{'Editar cupom'}</h3>
              </div>

              <div className="buttons-options">
                {/* <div className="d-flex justify-content-between align-items-center w-100">
                  {editCoupom === false ? (
                    <Dropdown drop="down">
                      <Dropdown.Toggle className="options-reservations mt-2">
                        <FontAwesomeIcon
                          icon={["fal", "angle-down"]}
                          className="mx-2 text-info"
                          size="2x"
                        //style={{ marginTop: "-3px" }}
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          eventKey="1"
                          onClick={() => {
                            setEditCoupom(!editCoupom);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={["fal", "edit"]}
                          //className="mr-2"
                          />
                          <span>Editar</span>
                        </Dropdown.Item>

                        <Dropdown.Item
                          eventKey="1"
                          //className="text-danger"
                          onClick={() => { }}
                          disabled={true}
                        >
                          <FontAwesomeIcon
                            icon={["fal", "ban"]}
                          //className="mr-2 "
                          />
                          <span>Cancelar</span>
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  ) : (
                    <></>
                  )}
                </div> */}
              </div>
            </div>
          </Modal.Header>
          <Modal.Body className="pt-0" style={{ padding: '25px' }}>
            <hr
              className="w-100 mt-0 mb-4"
              style={{ borderBottom: '1px solid' }}
            />

            <div className="w-100 p-0">
              <div>
                <Row className="mb-4">
                  {editCoupom === false ? (
                    <>
                      <Form.Group as={Col} md="3" controlId="">
                        <div
                          style={{ height: '48px' }}
                          className="animated-background"
                        ></div>
                      </Form.Group>

                      <Form.Group as={Col} md="3" controlId="">
                        <div
                          style={{ height: '48px' }}
                          className="animated-background"
                        ></div>
                      </Form.Group>
                      {item?.isCupom === 1 ? (
                        <>
                          <Form.Group as={Col} md="6" controlId="">
                            <div
                              style={{ height: '48px' }}
                              className="animated-background"
                            ></div>
                          </Form.Group>
                        </>
                      ) : (
                        <Form.Group as={Col} md="6" controlId="">
                          <div
                            style={{ height: '48px' }}
                            className="animated-background"
                          ></div>
                        </Form.Group>
                      )}
                    </>
                  ) : (
                    <>
                      <Form.Group as={Col} md="3" controlId="">
                        <div
                          style={{ height: '48px' }}
                          className="animated-background"
                        ></div>
                      </Form.Group>

                      <Form.Group as={Col} md="3" controlId="">
                        <div
                          style={{ height: '48px' }}
                          className="animated-background"
                        ></div>
                      </Form.Group>

                      <Form.Group as={Col} md={6} controlId="formGridSupplier">
                        <div
                          style={{ height: '48px' }}
                          className="animated-background"
                        ></div>
                      </Form.Group>
                    </>
                  )}
                </Row>

                <Row className="mt-4">
                  <Form.Group as={Col} className="mb-4">
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>

                  <Form.Group as={Col} className="mb-4">
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>

                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group
                    as={Col}
                    controlId="formGridCity"
                    className="mb-3"
                  >
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>
                </Row>

                <hr style={{ margin: '60px 0' }} />

                <Row className="mb-4">
                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>
                </Row>

                <Row className="mb-4">
                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>

                  <Form.Group as={Col} md={6} controlId="formGridSupplier">
                    <div
                      style={{ height: '48px' }}
                      className="animated-background"
                    ></div>
                  </Form.Group>
                </Row>
              </div>
            </div>
          </Modal.Body>
        </Form>
      </>
    );
  }
};

export default ListCoupom;
