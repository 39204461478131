import React, { useEffect, useState } from "react";
import { Accordion, Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { useAppSelector } from '../../../store/hooks';
import GetAllSource from "../../C2Points/GetAllSource";

export interface propsInfo {
    setControlIntegrationModal: any;
    integrationAccess: any;
    setIntegrationAccess: any;
    getValues: any;
    isTicket: any;
}

const IntegrationAccess: React.FC<propsInfo> = ({
    setControlIntegrationModal, integrationAccess, setIntegrationAccess, getValues, isTicket
}: propsInfo) => {
    /* Controle Redux  */
    const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
    /* Controle Redux  */

    const values: any = getValues();

    const integrationAccesLabelsTicket: string[] | boolean[] = isTicket ? [values.labelEldersEnabled ? values.labelEldersBR : false, values.labelStudentEnabled ? values.labelStudentBR : false] : []

    const integrationAccesLabels: string[] | boolean[] = tourSIG.tour.sellingType === 2 ?
        [values.labelAdultBR, values.labelChildEnabled ? values.labelChildBR : false, values.labelInfantEnabled ? values.labelInfantBR : false, ...integrationAccesLabelsTicket, "Rota"] :
        [values.labelPrivateBR, "Rota"];
    const [, setSource] = useState<any>(null);
    const [sourcesErrors, setSourcesErrors] = useState<string[]>([]);

    const {
        control,
        handleSubmit,
        watch,
        setValue,
        formState: { errors, isSubmitting },
    } = useForm({});

    useEffect(() => {
        setSourcesErrors((prev: any) => [])
        let source: string;
        let aux: any = []
        for (const label in errors) {
            source = label.split('-')[1]
            aux.push(source)
        }
        setSourcesErrors((prev: any) => aux)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSubmitting])


    const watchIntegrationType = watch(`idAccessIntegration`, integrationAccess !== null ? integrationAccess[0].idAccessIntegrationType + '' : '');
    const watchSource = watch(`source`, []);

    const onSubmit = (data: any) => {
        if (watchSource.length === 0) { return; }

        switch (watchIntegrationType) {

            case '1': //Skidata
                let skidata: any = [];
                watchSource.forEach((elem: any) => {
                    for (let i: number = 1; i <= integrationAccesLabels.length; i++) {
                        if (integrationAccesLabels[i - 1]) {
                            if (data[`valor-${elem.value}-${i}`] !== '' && data[`valor-${elem.value}-${i}`] !== undefined && data[`valor-${elem.value}-${i}`] !== null) {
                                skidata.push(
                                    {
                                        "idAccessIntegrationType": parseInt(watchIntegrationType),
                                        "chave": integrationAccesLabels[i - 1] === 'Rota' ? "ROUTE" : `FAIXA0${i}_PRODUCT`,
                                        "valor": data[`valor-${elem.value}-${i}`],
                                        "source": elem.value,
                                    }
                                )
                            }
                        }
                    }
                });

                setIntegrationAccess(skidata);

                setControlIntegrationModal('');

                break;
            case '2': //Default - DEFAULT ACCESS INTEGRATION
                /* let limber: any = [];
                for (let i: number = 1; i < 4; i++) {
                    limber.push(
                        {
                            "skuLimber": data[`skuLimber-${i}`],
                            "categoryIdLimber": data[`categoryIdLimber-${i}`],
                            "revenueIdLimber": data[`revenueIdLimber-${i}`],
                            "boardingPlace": data[`boardingPlace-${i}`],
                            "productRange": i
                        }
                    )
                }

                setIntegrationAccess({
                    "IdAccessIntegrationType": parseInt(watchIntegrationType),
                    "limber": limber
                }); */

                setControlIntegrationModal('');

                break;
            case '3': //Anyway 
                let anyway: any = [];
                watchSource.forEach((elem: any, indexSource: number) => {
                    for (let i: number = 1; i <= integrationAccesLabels.length; i++) {
                        if (integrationAccesLabels[i - 1]) {
                            if (data[`valor-${elem.value}-${i}`] !== '' && data[`valor-${elem.value}-${i}`] !== undefined && data[`valor-${elem.value}-${i}`] !== null) {
                                anyway.push(
                                    {
                                        "idAccessIntegrationType": parseInt(watchIntegrationType),
                                        "chave": integrationAccesLabels[i - 1] === 'Rota' ? "ROUTE" : `FAIXA0${i}_PRODUCT`,
                                        "valor": data[`valor-${elem.value}-${i}`],
                                        "source": elem.value,
                                        "flexibilidade": Number(data[`flexibilidade-${elem.value}`] || 0),
                                    }
                                )
                            }
                        }
                    }
                });


                setIntegrationAccess(anyway);
                setControlIntegrationModal('');

                break;
            default:
                alert('Favor selecionar um tipo de integração')
                break;
        }
    }

    useEffect(() => {
        if (integrationAccess !== null/*  && integrationAccess[0].idAccessIntegrationType === 1 */) {
            let sourcesUnit: any = integrationAccess.map((elem: any) => elem.source);
            sourcesUnit = sourcesUnit.filter((elem: any, index: number) => {
                return sourcesUnit.indexOf(elem) === index && elem !== null;
            });

            if (integrationAccess[0].idAccessIntegrationType === 3) {

                sourcesUnit.forEach((element: any, index: number) => {
                    setValue(`flexibilidade-${element}`, integrationAccess?.find((elem: any) => elem.source === element)?.flexibilidade)
                });
            }


        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    return (
        <>
            <div className="integration-type d-flex">
                <div className="ball outside"><div className='inside'></div></div>
                <span className="integration-name">Integração de Acesso</span>
            </div>
            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                <Row>
                    <Form.Group as={Col} md="6" className="mt-2 pr-2">
                        <Form.Label className="label-small">
                            Tipo de Integração
                        </Form.Label>

                        <Controller
                            control={control}
                            name={`idAccessIntegration`}
                            defaultValue={integrationAccess !== null ? integrationAccess[0].idAccessIntegrationType + '' : ''}
                            rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                            render={({ field }: any) => (
                                <>
                                    <div className="endpoint">
                                        <Form.Select
                                            {...field}
                                            type="text"
                                            aria-invalid={errors?.detailsPT ? "true" : ""}
                                            variant="standard"
                                            margin="normal"
                                            required
                                        >
                                            <option value='' selected disabled>Selecione</option>
                                            {/* <option value='1'>Opção ainda sem nome</option> */}
                                            <option value='1'>SKIDATA</option>
                                            <option value='3'>ANYWAY</option>
                                        </Form.Select>
                                    </div>
                                </>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name={`idAccessIntegration`}
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>

                {
                    watchIntegrationType !== '' &&
                    <>

                        {
                            watchIntegrationType === '1' &&
                            <>
                                <Row>
                                    <div className="mt-4">
                                        <strong>Skidata</strong>
                                    </div>
                                </Row>
                            </>
                        }

                        {
                            watchIntegrationType === '2' &&
                            <>
                                <Row>
                                    <div className="mt-4">
                                        <strong>Default</strong>
                                    </div>
                                </Row>
                            </>
                        }

                        {
                            watchIntegrationType === '3' &&
                            <>
                                <Row>
                                    <div className="mt-4">
                                        <strong>Anyway</strong>
                                    </div>
                                </Row>
                            </>
                        }

                        {
                            (watchIntegrationType === '1' || watchIntegrationType === '3') &&
                            <>
                                <Form.Group as={Col} md="12" className="mt-2 p-0">
                                    <Form.Label className="label-small">
                                        Canal
                                    </Form.Label>

                                    <Controller
                                        control={control}
                                        name={`source`}
                                        /* defaultValue={integrationAccess !== null ? integrationAccess[index].source : ''} */
                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                        render={({ field }: any) => (
                                            <GetAllSource
                                                propsField={field}
                                                propsErrors={errors}
                                                setSource={setSource}
                                                isMulti={true}
                                                defaultValue={integrationAccess}
                                                setValue={setValue}
                                                name={'source'}
                                                isTicket={isTicket}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name={`source`}
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </>
                        }

                        <hr className="my-4" />

                        {watchSource?.map((elem: any, index1: number) => {
                            return (
                                <>
                                    <Accordion key={index1} defaultActiveKey="1" className="mb-2" style={{border: sourcesErrors.includes(elem.value) ? '1px solid red' : ''}}>
                                        <Accordion.Item eventKey="0">
                                            <Accordion.Header>{elem.label}</Accordion.Header>
                                            <Accordion.Body>
                                                <Row>
                                                    {integrationAccesLabels.map((label: any, index: number) => {
                                                        return (
                                                            <>
                                                                {label ?
                                                                    <>
                                                                        <Col key={index} md={(watchIntegrationType === '1' || isTicket) ? 3 : 2} className="mb-3">
                                                                            <div>
                                                                                <Row><div>{label}</div></Row>
                                                                                <Row>
                                                                                    <Form.Group as={Col} md="12" className="mt-2">
                                                                                        <Form.Label className="label-small">
                                                                                            Valor
                                                                                        </Form.Label>

                                                                                        <Controller
                                                                                            control={control}
                                                                                            name={`valor-${elem.value}-${index + 1}`}
                                                                                            /* defaultValue={integrationAccess !== null ? integrationAccess[index].valor : ''} */
                                                                                            rules={{ required: { value: label === "Rota", message: 'Por favor, informe o campo' } }}
                                                                                            render={({ field }: any) => (
                                                                                                <Form.Control
                                                                                                    {...field}
                                                                                                    variant="standard"
                                                                                                    margin="normal"
                                                                                                    required
                                                                                                    autoComplete='off'
                                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                        <ErrorMessage
                                                                                            errors={errors}
                                                                                            name={`valor-${elem.value}-${index + 1}`}
                                                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                                        />
                                                                                    </Form.Group>
                                                                                </Row>
                                                                            </div>
                                                                        </Col>
                                                                    </>
                                                                    :
                                                                    <Col key={index} md={(watchIntegrationType === '1' || isTicket) ? 3 : 2}></Col>
                                                                }
                                                            </>
                                                        );
                                                    })}
                                                    <>
                                                        {watchIntegrationType === '3' &&
                                                            <>
                                                                {<Col md={isTicket ? 3 : 1}></Col>}
                                                                <Col md={3}>
                                                                    <div>
                                                                        <Row><div>Flexibilidade</div></Row>
                                                                        <Row>
                                                                            <Form.Group as={Col} md="12" className="mt-2">
                                                                                <Form.Label className="label-small">
                                                                                    Valor
                                                                                </Form.Label>

                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`flexibilidade-${elem.value}`}
                                                                                    /* defaultValue={integrationAccess !== null ? integrationAccess[index1].valor : ''} */
                                                                                    rules={{ required: { value: false, message: 'Por favor, informe o campo' } }}
                                                                                    render={({ field }: any) => (
                                                                                        <Form.Control
                                                                                            {...field}
                                                                                            variant="standard"
                                                                                            margin="normal"
                                                                                            required
                                                                                            autoComplete='off'
                                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <ErrorMessage
                                                                                    errors={errors}
                                                                                    name={`flexibilidade-${elem.value}`}
                                                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                                />
                                                                            </Form.Group>
                                                                        </Row>
                                                                    </div>
                                                                </Col>
                                                            </>
                                                        }
                                                    </>
                                                </Row>
                                            </Accordion.Body>
                                        </Accordion.Item>
                                    </Accordion>
                                </>
                            );
                        })}

                        {/* {integrationAccesLabels.map((elem: any, index: number) => {
                            return (
                                <div key={index} className='mt-4'>
                                    <Row><div>{elem}</div></Row>
                                    <Row>
                                        <Form.Group as={Col} md="6" className="mt-2">
                                            <Form.Label className="label-small">
                                                Chave
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name={`chave-${index + 1}`}
                                                defaultValue={integrationAccess !== null ? integrationAccess[index].chave : ''}
                                                rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="text"
                                                        aria-invalid={errors?.detailsPT ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`chave-${index + 1}`}
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row>
                                        <Form.Group as={Col} md="6" className="mt-2">
                                            <Form.Label className="label-small">
                                                Valor
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name={`valor-${index + 1}`}
                                                defaultValue={integrationAccess !== null ? integrationAccess[index].valor : ''}
                                                rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                render={({ field }: any) => (
                                                    <Form.Control
                                                        {...field}
                                                        type="number"
                                                        aria-invalid={errors?.detailsPT ? "true" : ""}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        autoComplete='off'
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`valor-${index + 1}`}
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>



                                        {
                                            watchIntegrationType === '2' &&
                                            <>
                                                <Form.Group as={Col} md="6" className="mt-2">
                                                    <Form.Label className="label-small">
                                                        Grupo
                                                    </Form.Label>

                                                    <Controller
                                                        control={control}
                                                        name={`grupo-${index + 1}`}
                                                        defaultValue={integrationAccess !== null ? integrationAccess[index].grupo : ''}
                                                        rules={{ required: { value: true, message: 'Por favor, informe o campo' } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="number"
                                                                aria-invalid={errors?.detailsPT ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                                autoComplete='off'
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`grupo-${index + 1}`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </>
                                        }
                                    </Row>
                                </div>
                            )
                        })} */}

                    </>
                }

                <div className="h-100 d-flex justify-content-end align-items-end">
                    <Row className="mx-0 mt-5">
                        <Button
                            variant="outline-primary"
                            className="form-button mr-3"
                            type="button"
                            onClick={() => setControlIntegrationModal('')}
                        >
                            Cancelar
                        </Button>
                        <Button
                            type="submit"
                            className="form-button"
                        >
                            Cadastrar
                        </Button>
                    </Row>

                </div>

            </Form>

        </>
    )
}

export default IntegrationAccess;