/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterExportERPSuppliers.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import GetSuppliersTypeFilter from "../../../../components/C2Points/GetSuppliersTypeFilter";

import { useForm, Controller } from "react-hook-form";

export interface propFilter {
    buscar: any,
}

const FilterBillsReceive: React.FC<propFilter> = ({
    buscar
}) => {
    const { t } = useTranslation();

    const [filterClientType, setFilterClientType] = useState<string>('0');

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({});

    const handleClearClick = (event: any) => {

        setValue('name', '');
        setValue('filterType', '0');
        setValue('status', '0');
        setValue('statusERP', '0');

        setFilterClientType('0');
        buscar('', 0, 0, 0);
    };

    const handleActionBuscar = () => {
        let data: any = getValues();
        buscar(data.name || '', filterClientType, data.statusERP || 0, data.status || 0);
    }

    const onSubmit = (data: any) => {
        buscar(data.name || '', filterClientType, data.statusERP || 0, data.status || 0);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Fornecedores ERP</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form onSubmit={handleSubmit(onSubmit)}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>{t('listSuppliers.filterNameCod')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('listSuppliers.filterClientType')}</Form.Label>
                                                <InputGroup hasValidation>
                                                    <Controller
                                                        control={control}
                                                        name="filterType"
                                                        render={({ field }: any) => (
                                                            <GetSuppliersTypeFilter propsField={field} propsErrors={errors} propsLabel={'Filter Type'} setFilterType={setFilterClientType} />
                                                        )}
                                                    />
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status ERP</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="statusERP"
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <InputGroup hasValidation>
                                                                <Form.Select
                                                                    {...field}
                                                                    aria-describedby="inputGroupPrepend"
                                                                >
                                                                    <option value="0">Todos</option>
                                                                    <option value="1">Não enviado</option>
                                                                    <option value="2">Enviado</option>
                                                                </Form.Select>
                                                            </InputGroup>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('listSuppliers.filterStatus')}</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="status"
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <InputGroup hasValidation>
                                                                <Form.Select
                                                                    {...field}
                                                                    aria-describedby="inputGroupPrepend"
                                                                >
                                                                    <option value="0">{t('listSuppliers.statusAll')}</option>
                                                                    <option value="1">{t('listSuppliers.statusAnalysis')}</option>
                                                                    <option value="2">{t('listSuppliers.statusActive')}</option>
                                                                    <option value="3">{t('listSuppliers.statusInactive')}</option>
                                                                </Form.Select>
                                                            </InputGroup>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" type="button" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" type="submit">{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterBillsReceive;