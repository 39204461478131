import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import './CardConciliation.scss';

//COMPONENTS

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterCardConciliation from './components/FilterCardConciliation/FilterCardConciliation';
import TableCardConciliation from './components/TableCardConciliation/TableCardConciliation';
import DesktopDefault from '../../templates/DesktopDefault';
import { Card, Row } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function CardConciliation() {
    //const { t } = useTranslation();
    const [allRecords, setAllRecords] = useState<any>(null);
    const [sessions, setSessions] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const hoje = new Date();
    const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate() - 1); // hoje - 1 dia = ontem
    const dataPadrao = `${ontem.getDate()}/${ontem.getMonth()+1}/${ontem.getFullYear()}`;
    const apiPadrao = '1';

    /* Filter */
    const [filterDate, setFilterDate] = useState<any>(dataPadrao);
    const [filterApi, setFilterApi] = useState<any>(apiPadrao);
    /* END - Filter */

    const [amountTotal, setAmountTotal] = useState<number>(0);
    const [totalBraspag, setTotalBraspag] = useState<number>(0);
    const [diferenca, setDiferenca] = useState<number>(0);
    
    const [liquidado, setLiquidado] = useState<boolean>(false);

    useEffect(() => {
        if(allRecords !== null && allRecords.length > 0) {
            const pageBegin = (pageCount-1) * rowsPerPage;
            const pageEnd = (pageCount * rowsPerPage);

            setSessions(allRecords.slice(pageBegin, pageEnd));
        }

    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function refreshData() {
        setPageCount(1);
        
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []

        beginDate = filterDate.split('/');

        try {
            const res = await api.post('/CardConciliation/GetReport',
                {
                    //"page": 1,
                    //"rowsPerPage": rowsPerPage,
                    "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "api": filterApi,
                }, config
            );
            if (res.status !== 400) {
                const data = res.data.data;

                calcTotals(data);

                setLoading(false);
                setAllRecords(data);
                //setTotalRows(res.data.data.rowsCount);
                setTotalRows(data.length);

                const pageBegin = 0;
                const pageEnd = rowsPerPage;

                setSessions(data.slice(pageBegin, pageEnd));
            }
        } catch (error: any) {
            setLoading(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function buscar(date: any, adquirente: any) {
        setPageCount(1);
        setFilterDate(date);
        setFilterApi(adquirente);
        
        setSessions(null);
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []

        beginDate = date.split('/');

        try {
            const res = await api.post('/CardConciliation/GetReport',
                {
                    //"page": 1,
                    //"rowsPerPage": rowsPerPage,
                    "dataInicial": date ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataFinal": date ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "api": adquirente,
                }, config
            );
            if (res.status !== 400) {
                const data = res.data.data;

                calcTotals(data);

                setLoading(false);
                setAllRecords(data);
                //setTotalRows(res.data.data.rowsCount);
                setTotalRows(data.length);

                const pageBegin = 0;
                const pageEnd = rowsPerPage;

                setSessions(data.slice(pageBegin, pageEnd));
            }
        } catch (error: any) {
            setLoading(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    function calcTotals(data: any) {
        var total = 0;
        var totalBraspag = 0;
        var liq = false;
        
        for(var i = 0; i < data.length; i++) {
            const amount = data[i].amount - data[i].mdr;
            const amountBraspag = data[i].amountBraspag - data[i].mdrBraspag;
            total += amount;
            totalBraspag += amountBraspag;

            console.log(amountBraspag);

            data[i].conciliado = ((amount - amountBraspag) === 0) && (data[i].vencimento === data[i].vencimentoBraspag) ? 1 : 2;

            if(data[i].statusPagamento === 4) liq = true;
        }

        //console.log(data);

        setAmountTotal(total);
        setTotalBraspag(totalBraspag);
        setDiferenca(total - totalBraspag);
        setLiquidado(liq);
    }

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
        //console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
        let link = document.createElement('a');
        link.href = blob;//window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
        //}
    };

    async function exportReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []

        beginDate = filterDate.split('/')

        try {
            const res = await api.post('CardConciliation/ExportReport',
                {
                    //"page": 1,
                    //"rowsPerPage": 0,
                    "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "api": filterApi,
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []

        beginDate = filterDate.split('/')

        try {
            const res = await api.post('CardConciliation/PrintReport',
                {
                    //"page": 1,
                    //"rowsPerPage": 0,
                    "dataInicial": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "dataFinal": filterDate ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${ontem.getFullYear()}-${ontem.getMonth()+1}-${ontem.getDate()}`,
                    "api": filterApi,
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard card-conciliation">
                    <Breadcrumb title={'Financeiro / Conciliação de Cartões'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Conciliação de Cartões</h2>
                    </div>
                    <FilterCardConciliation
                        buscar={buscar}
                        sessions={sessions}
                        error={error}
                        apiPadrao={apiPadrao}
                    />
                    <Row className="cards">
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Total Receber
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {amountTotal.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "receipt"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Total Receber Adquirente
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {totalBraspag.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "hand-holding-usd"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                        <div className="col-md-2 card-unit">
                            <Card>
                                <div className="col-md-10">
                                    <Row>
                                        <span className='card-title'>
                                            Diferença
                                        </span>
                                    </Row>
                                    <Row>
                                        <span className='cart-content'>
                                            {diferenca.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                        </span>
                                    </Row>
                                </div>
                                <div className="col-md-2 d-flex justify-content-center align-items-center">
                                    <FontAwesomeIcon
                                        icon={["fad", "dollar-sign"]}
                                        className="text-primary p-1"
                                        size="3x"
                                    />
                                </div>
                            </Card>
                        </div>
                    </Row>
                    <TableCardConciliation
                        sessions={sessions}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportReport={exportReport}
                        printReport={printReport} 
                        loading={loading}
                        dateSelect={filterDate}
                        apiSelect={filterApi}
                        totalReceber={amountTotal}
                        liquidado={liquidado}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );
}

export default CardConciliation;