import React, { useEffect, useState } from 'react';
import api from '../../services/api';
import { useTranslation } from 'react-i18next';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export interface propPlus {
  propsField: any,
  isMulti?: any,
};

const GetGroupSessionList: React.FC<propPlus> = ({
  propsField, isMulti = false
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  
    const onMenuOpen = () => {
      if (isMenuOpen === false) {
        setIsMenuOpen(true);
      } else {
        setIsMenuOpen(false);
      }
    }

  useEffect(() => {
    const getStockGroup = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res: any = await api.post(`/Stock/GetGroupSession`, {
                "GroupSessionUniqueCode": '',
                "ProdModCodeUnique": "",
                "TarCodeUnique": ""
            }, config);

            if (res.status === 200) {
                setInfo(res.data.data)
                //setTotalRows(data.data.data.rowsCount);
            }
        } catch (error: any) { }
    };
    getStockGroup();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (info !== null) {
    const transformed = info.map(({ groupSessionUniqueCode, groupSessionName }: any) => ({ value: groupSessionUniqueCode, label: groupSessionName }));
    return (
      <>
        <div className="endpoint">
          {isMulti === false ?
            <>
            <Select
                {...propsField}
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuOpen}
                className="basic-multi-select"
                classNamePrefix="select"
                as="select"
                variant="standard"
                margin="normal"
                placeholder={"Selecione"}
                options={transformed}
                getOptionLabel={(option: any) => `${option.label}`}
              />
              {/* <Form.Control
                as="select"
                {...propsField}
                required
                autoComplete='off'
                className="form-select"
              >
                <option value='0'>{t("salesReport.all")}</option>
                {info.length > 0 ? info.map((info: any) => (
                  <option value={info.code}>{info.name}</option>
                )) : ''}
              </Form.Control> */}
            </>
            :
            <>
              <Select
                {...propsField}
                aria-labelledby="aria-label"
                inputId="aria-example-input"
                name="aria-live-color"
                onMenuOpen={onMenuOpen}
                onMenuClose={onMenuOpen}
                className="basic-multi-select"
                classNamePrefix="select"
                as="select"
                variant="standard"
                margin="normal"
                placeholder={t("salesReport.select")}
                isMulti
                options={transformed}
                getOptionLabel={(option: any) => `${option.label}`}
              />
            </>}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetGroupSessionList;