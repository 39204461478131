/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import Button from "@restart/ui/esm/Button";

import Modal from "react-bootstrap/Modal";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import GetAccountingAccountsFilter from '../../../../components/C2Points/GetAccountingAccountsFilter';
import GetAgentsExtract from '../../../../components/C2Points/GetAgentsExtract';

import GetPaymentMethodsFilter from '../../../../components/C2Points/GetPaymentMethodsFilter';
import GetSuppliersFilter from '../../../../components/C2Points/GetSuppliersInsert';

import { Controller, useForm } from "react-hook-form";
import api from "../../../../services/api";
import { Col, InputGroup, Row } from "react-bootstrap";
import { ErrorMessage } from "@hookform/error-message";
import SingleCalendarEditable from "../../../../components/Calendar/SingleCalendarEditable";
import GetCostCenterEdit from "../../../../components/C2Points/GetCostCenterEdit";

export interface propInsert {
  itemsToEdit: any;
  show?: any;
  setResponseText?: any;
  setModalContent?: any;
  setLog?: any;
  closeModal?: any;
  setCloseModal?: any;
  closeAction?: any;
}

/* const ModalDefault: FC<{}> = ({ children }: any) => { */
const EditExtract: React.FC<propInsert> = ({
    itemsToEdit, show, setResponseText, setModalContent, setLog, closeModal, setCloseModal, closeAction
}: propInsert) => {
    const [modalShow, setModalShow] = useState(false);

    const [items, setItems] = useState<any>([]);

    const [date, setDate] = useState<any>('');
    const [vencimento, setVencimento] = useState<any>('');
    const [loading, setLoading] = useState<any>(false);
    const [erroData, setErroData] = useState<any>(false);
    const [erroVencimento, setErroVencimento] = useState<any>(false);

    const [pago, setPago] = useState<any>(false);
    const [dataPgto, setDataPgto] = useState<any>('');
    const [erroDataPgto, setErroDataPgto] = useState<any>(false);

    const [client, setClient] = useState<any>();

    const [formaPagamento, setFormaPagamento] = useState<any>({
        id: 0,
        descricao: 'Cash',
        possuiMdr: 2,
        possuiOperador: 2,
        tipo: 2,
        alias: 'CASH'
    });

    const [file, setFile] = useState<any>('');
    const [fileName, setFileName] = useState<any>('');

    const [receberPagar, setReceberPagar] = useState<any>(1);
    const [transferencia, setTransferencia] = useState<any>(false);

    const [descricaoReadOnly, setDescricaoReadOnly] = useState<any>(false);
    const [contaContabilReadOnly, setContaContabilReadOnly] = useState<any>(false);
    const [dataReadOnly, setDataReadOnly] = useState<any>(false);
    const [vencimentoReadOnly, setVencimentoReadOnly] = useState<any>(false);
    const [dataPgtoReadOnly, setDataPgtoReadOnly] = useState<any>(false);
    const [clienteFornecedorReadOnly, setClienteFornecedorReadOnly] = useState<any>(false);
    const [centroCustoReadOnly, setCentroCustoReadOnly] = useState<any>(false);
    const [valorReadOnly, setValorReadOnly] = useState<any>(false);
    const [valorPagoReadOnly, setValorPagoReadOnly] = useState<any>(false);
    const [descontoReadOnly, setDescontoReadOnly] = useState<any>(false);
    const [jurosReadOnly, setJurosReadOnly] = useState<any>(false);
    const [pagoReadOnly, setPagoReadOnly] = useState<any>(false);
    const [formaPagamentoReadOnly, setFormaPagamentoReadOnly] = useState<any>(false);
    const [pagoAReadOnly, setPagoAReadOnly] = useState<any>(false);
    const [observacaoReadOnly, setObservacaoReadOnly] = useState<any>(false);

    const [descricao, setDescricao] = useState<any>('');
    const [contaContabil, setContaContabil] = useState<any>('');
    const [centroCusto, setCentroCusto] = useState<any>('');
    const [valor, setValor] = useState<any>('');
    const [valorPago, setValorPago] = useState<any>('');
    const [desconto, setDesconto] = useState<any>('');
    const [juros, setJuros] = useState<any>('');
    const [pagoA, setPagoA] = useState<any>('');
    const [observacao, setObservacao] = useState<any>('');

    
    const validated = false;

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
        
      }

      const formatDate = (data: any) => {
        if(!data) return '';
        
        //2022-05-05T00:00:00
        var aux = data.split('T');
        aux = aux[0].split('-');
    
        return (`${aux[2]}/${aux[1]}/${aux[0]}`);
      }

    const fileHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setFile(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setFileName(e.target.files[0].name);
    }

    function doShowModal() {
        setItems([]);
        reset();

        if(itemsToEdit.length === 0) {
            setResponseText('Selecione ao menos um item para alterar');
            setModalContent('warning');
            setLog(2);
            show(true);
            return;
        }

        async function doGetItemsToEdit() {
            const itemsF: any = [];

            var receberPagar = itemsToEdit[0].receberPagar;
            var transferencia = (itemsToEdit[0].supMerchant === 3) ? 1 : 2;
            itemsF.push(itemsToEdit[0].id);

            for(var i = 1; i < itemsToEdit.length; i++) {
                
                if(itemsToEdit[i].receberPagar !== receberPagar) {
                    setResponseText('Não pode alterar receber e pagar ao mesmo tempo');
                    setModalContent('warning');
                    setLog(2);
                    show(true);
                    return;
                }

                if((transferencia === 1 && itemsToEdit[i].supMerchant !== 3) || (transferencia === 2 && itemsToEdit[i].supMerchant === 3)) {
                    setResponseText('Não pode alterar transferencia e recebimento/pagamento ao mesmo tempo');
                    setModalContent('warning');
                    setLog(2);
                    show(true);
                    return;
                }

                itemsF.push(itemsToEdit[i].id);
            }

            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Extract/GetExtractToEdit`,
                    {
                        "items": itemsF
                    }, config);
                if (res.status !== 400) {
                    console.log(res)

                    const dados = res.data.data;

                    if(dados.log === 1) {
                        setResponseText(dados.texto);
                        setLog(dados.log);
                        setModalContent("error");
                        show(true);
                    } else if(dados.log === 2) {
                        setResponseText(dados.texto);
                        setLog(dados.log);
                        setModalContent("warning");
                        show(true);
                    } else {
                        setReceberPagar(dados.receberPagar);
                        setTransferencia(dados.supMerchant === 3);

                        if( dados.canEditDescricao === 1 ) {
                            setDescricaoReadOnly(false);
                            setDescricao(dados.descricao);
                        } else {
                            setDescricaoReadOnly(true);
                            setDescricao('');
                        }

                        if( dados.receberPagar === 1 ) {
                            setPagoAReadOnly(true);
                            setPagoA('');
                            
                            if( dados.canEditFormaPagamento === 1 ) {
                                setFormaPagamentoReadOnly(false);
                                setFormaPagamento(dados.formaPagamento);
                            } else {
                                setFormaPagamentoReadOnly(true);
                                setFormaPagamento('');
                            }
                        } else {
                            setFormaPagamentoReadOnly(true);
                            setFormaPagamento('');
                            
                            if( dados.canEditFornLiquidacao === 1 ) {
                                setPagoAReadOnly(false);
                                setPagoA(dados.fornLiquidacao);
                            } else {
                                setPagoAReadOnly(true);
                                setPagoA('');
                            }
                        }

                        if( dados.canEditContaContabil === 1 ) {
                            setContaContabilReadOnly(false);
                            setContaContabil(dados.contaContabil);
                        } else {
                            setContaContabilReadOnly(true);
                            setContaContabil('');
                        }

                        if( dados.canEditCentroCusto === 1 ) {
                            setCentroCustoReadOnly(false);
                            setCentroCusto(`${dados.centroCusto}`);
                        } else {
                            setCentroCustoReadOnly(true);
                            setCentroCusto('');
                        }

                        if( dados.canEditDataCompetencia === 1 ) {
                            setDataReadOnly(false);
                            setDate(formatDate(dados.dataCompetencia));
                        } else {
                            setDataReadOnly(true);
                            setDate('');
                        }

                        if( dados.canEditVencimento === 1 ) {
                            setVencimentoReadOnly(false);
                            setVencimento(formatDate(dados.vencimento));
                        } else {
                            setVencimentoReadOnly(true);
                            setVencimento('');
                        }

                        if( dados.canEditClienteFornecedor === 1 ) {
                            setClienteFornecedorReadOnly(false);
                            setClient(dados.clienteFornecedor);
                        } else {
                            setClienteFornecedorReadOnly(true);
                            setClient('');
                        }

                        if( dados.canEditDataPgto === 1 ) {
                            setDataPgtoReadOnly(false);
                            setDataPgto(formatDate(dados.dataPgto));
                        } else {
                            setDataPgtoReadOnly(true);
                            setDataPgto('');
                        }

                        if( dados.canEditDesconto === 1 ) {
                            setDescontoReadOnly(false);
                            setDesconto(formatValue(dados.desconto));
                        } else {
                            setDescontoReadOnly(true);
                            setDesconto('');
                        }

                        if( dados.canEditJuros === 1 ) {
                            setJurosReadOnly(false);
                            setJuros(formatValue(dados.juros));
                        } else {
                            setJurosReadOnly(true);
                            setJuros('');
                        }

                        if( dados.canEditValor === 1 ) {
                            setValorReadOnly(false);
                            setValor(formatValue(dados.valor));
                        } else {
                            setValorReadOnly(true);
                            setValor('');
                        }

                        if( dados.canEditValorPago === 1 ) {
                            setValorPagoReadOnly(false);
                            setValorPago(formatValue(dados.valorPago));
                        } else {
                            setValorPagoReadOnly(true);
                            setValorPago('');
                        }

                        if( dados.canEditPago === 1 ) {
                            setPagoReadOnly(false);
                            setPago( dados.pago === 1 );
                        } else {
                            setPagoReadOnly(true);
                            setPago('');
                        }

                        if( dados.canEditObservacao === 1 ) {
                            setObservacaoReadOnly(false);
                            setObservacao(dados.observacao);
                        } else {
                            setObservacaoReadOnly(true);
                            setObservacao('');
                        }

                        setItems(itemsF);
                        setModalShow(true);
                    }

                } else {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent("error");
                    show(true);
                }
            } catch (error: any) {
                setResponseText('Erro, tente novamente mais tarde');
                setLog(1);
                setModalContent("error");
                show(true);
            }
        }
       
        doGetItemsToEdit();
    }

    function doCloseModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm();

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    function verifyDate(now: any, due: any) {
        var nowArray: any = now.split('-');
        var dueArray: any = due.split('-');

        if (parseInt(dueArray[0]) < parseInt(nowArray[0])) {
            return false;
        }
        if (parseInt(dueArray[1]) < parseInt(nowArray[1])) {
            return false;
        }
        if (parseInt(dueArray[2]) < parseInt(nowArray[2])) {
            return false;
        }
        return true;
    }

    const onSubmit = (data: any) => {
        
        if(dataReadOnly !== true && date === '') {
            setErroData(true);
        } else {
            setErroData(false);
        }

        if(transferencia !== true && vencimentoReadOnly !== true && vencimento === '') {
            setErroVencimento(true);
        } else {
            setErroVencimento(false);
        }

        if((dataReadOnly !== true && date === '') || (transferencia !== true && vencimentoReadOnly !== true && vencimento === '')) {
            return;
        }
        
        data.descricao = data.descricao ? data.descricao : null;
        data.contaContabil = contaContabil ? contaContabil.id : null;
        data.formaPagamento = formaPagamento && formaPagamento.id ? formaPagamento.id : null;
        data.data = date ? fixDate(date) : null;
        data.vencimento = vencimento ? fixDate(vencimento) : null;
        data.valor = data.valor ? parseFloat( data.valor.replace(',','.') ) : null;

        data.pago = pagoReadOnly !== true ?  (pago === true ? 1 : 2) : null;
        data.dataPgto = dataPgto ? fixDate(dataPgto) : null;
        data.desconto = data.desconto ? parseFloat( data.desconto.replace(',','.') ) : null;
        data.jurosMulta = data.juros ? parseFloat( data.juros.replace(',','.') ) : null;
        data.valorPago = data.valorPago ? parseFloat( data.valorPago.replace(',','.') ) : null;
        
        data.supplier = client ? client.supplierId : null;

        data.fornLiquidacao = pagoA ? pagoA.supplierId : null;
        
        data.centroCusto = data.centroCusto ? data.centroCusto.id : null;

        data.observacao = data.observacao ? data.observacao : null;
        //
        setLoading(true)
        setModalContent("loading");
        
        const updateExtractInBath = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Extract/UpdateExtractInBath`,
                    {
                        "items": items,
                        "descricao": data.descricao,
                        "contaContabil": data.contaContabil, // id da conta contabil
                        "formaPagamento": data.formaPagamento, // id da forma pagamento
                        "data": data.data,
                        "vencimento": data.vencimento, // data do vencimento
                        "valor": data.valor,
                        "pago": data.pago,
                        "dataPgto": data.dataPgto,
                        "desconto": data.desconto,
                        "jurosMulta": data.jurosMulta,
                        "valorPago": data.valorPago,
                        "conta": data.conta,
                        "supplier": data.supplier, // identity do fornecedor/cliente
                        "centroCusto":  data.centroCusto, // id do centro custo
                        "observacao": data.observacao,
                        "fornLiquidacao": data.fornLiquidacao, // identity do fornecedor/cliente
                        "nomeAnexo": fileName,
                        "arquivoAnexo": file,
                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent("success");
                    console.log(res)
                } else {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent("error");
                }
                setLoading(false);
                doCloseModal();
                show(true);
            } catch (error: any) {
                setLoading(false);
                setModalContent("error");
                
            }
        };

        const updateAccountTransfer = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Extract/UpdateAccountTransfer`,
                    {
                        "id": items[0].id,
                        "descricao": data.descricao,
                        
                        "data": data.data,
                        
                        "valor": data.valor,
                        
                        "nomeAnexo": fileName,
                        "arquivoAnexo": file,
                        "uid": uId,
                        "usuario": `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent("success");
                    console.log(res)
                } else {
                    setResponseText(res.data.data.texto);
                    setLog(res.data.data.log);
                    setModalContent("error");
                }
                setLoading(false);
                doCloseModal();
                show(true);
            } catch (error: any) {
                setLoading(false);
                setModalContent("error");
                
            }
        };

        if(transferencia !== true) {
            updateExtractInBath();
        } else {
            updateAccountTransfer();
        }
        
    }

    useEffect(() => {
      if (closeModal === true) {
        setModalShow(false);
        setCloseModal(false);
      }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [closeModal]);
  
    //console.log(props.allowEdit)

    return (
        <div>
            <Modal
    
            className={"modal-custom modalAuth modal-large"}
            show={modalShow}
            onHide={() => {
                setModalShow(false);
                closeAction ? closeAction() : <></>;
            }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            >
            <>
                <Modal.Header closeButton>
                <div className="d-flex justify-content-between w-100">
                    <h3>Editar Extrato</h3>  
                </div>
                </Modal.Header>
                <hr style={{ margin: "30px 0" }} />
                <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div>
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Descrição</Form.Label>
                                    {descricaoReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="descricao"
                                        rules={{ required: { value: true, message: "required" }, }}
                                        defaultValue={descricao}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.descricao ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                            />
                                        )}
                                    />
                                    : 
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />}
                                    <ErrorMessage
                                        errors={errors}
                                        name="descricao"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                {transferencia !== true ?
                                <Form.Group as={Col}>
                                    <Form.Label>Categoria</Form.Label>
                                    {contaContabilReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="categoria"
                                        rules={{ required: { value: true, message: "required" }, }}
                                        defaultValue={contaContabil}
                                        render={({ field }: any) => (
                                            <GetAccountingAccountsFilter propsField={field} propsErrors={errors} propsLabel="Categoria" setFilterAccount={setContaContabil} />
                                        )}
                                    />
                                    :
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />}
                                    <ErrorMessage
                                        errors={errors}
                                        name="categoria"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                :<></>}
                            </Row>
                            <Row>
                                {receberPagar === 1 && transferencia !== true ?
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>Método Pagamento</Form.Label>
                                    {formaPagamentoReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="formaPagamento"
                                        rules={{ required: { value: !formaPagamento || formaPagamento.id === 0, message: "required" }, }}
                                        defaultValue={formaPagamento}
                                        render={({ field }: any) => (
                                            <GetPaymentMethodsFilter propsField={field} propsErrors={errors} propsLabel="Método Pagamento" setPaymentMethod={setFormaPagamento} />
                                        )}
                                    />
                                    :
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />}
                                    <ErrorMessage
                                        errors={errors}
                                        name="formaPagamento"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                :<></>}
                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Data Competência</Form.Label>
                                    {dataReadOnly !== true ?
                                    <InputGroup
                                        hasValidation
                                        className={` ${erroData === true ? "endpoint-error" : ""}`} 
                                        size="sm"
                                        placeholder="Quando?"
                                    >
                                        <SingleCalendarEditable setValue={setDate} date={date} />
                                    </InputGroup>
                                    :
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />
                                    }
                                    {erroData === true ?
                                        <small style={{color: 'red'}}>required</small>
                                    :<></>}
                                </Form.Group>
                                {transferencia !== true ?
                                <Form.Group as={Col} className="mb-3">
                                    <Form.Label>Vencimento</Form.Label>
                                    {vencimentoReadOnly !== true ?
                                    <InputGroup
                                        hasValidation
                                        className={` ${erroVencimento === true ? "endpoint-error" : ""}`}
                                        size="sm"
                                        placeholder="Quando?"
                                    >
                                        <SingleCalendarEditable setValue={setVencimento} date={vencimento} />
                                    </InputGroup>
                                    :
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />
                                    }
                                    {erroVencimento === true ?
                                        <small style={{color: 'red'}}>required</small>
                                    :<></>}
                                </Form.Group>
                                : <></>}
    
                                <Form.Group as={Col} md={2}>
                                    <Form.Label>Valor</Form.Label>
                                    {valorReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="valor"
                                        rules={{ required: { value: true, message: "required" }, }}
                                        defaultValue={valor}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.valor ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                            />
                                        )}
                                    />
                                    : <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />}
                                    <ErrorMessage
                                        errors={errors}
                                        name="valor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>

                            <hr style={{ margin: "30px 0" }} />

                            {transferencia !== true ?
                            <Row >
                                <Form.Group as={Col} md={2}>
                                    {pagoReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="pago"
                                        //rules={{ required: { value: true, message: "required" }, }}
                                        render={({ field }: any) => (
                                            <div className='d-flex align-items-center' style={{ height: "86px" }}>
                                                <Form.Check
                                                    {...field}
                                                    type="radio"
                                                    label={receberPagar === 1 ? 'Recebido' : 'Pago'}
                                                    name="formHorizontalRadios"
                                                    id="formHorizontalRadios3"
                                                    onClick={() => { setPago(!pago) }}
                                                    checked={pago}
                                                />
                                            </div>
                                        )}
                                    />
                                    : <Form.Check
                                            type="radio"
                                            label={receberPagar === 1 ? 'Recebido' : 'Pago'}
                                            name="formHorizontalRadios"
                                            id="formHorizontalRadios3"
                                            checked={false}
                                            disabled={true}
                                        />}
                                </Form.Group>
                                
                                    <Form.Group as={Col} className="mb-3">
                                        <Form.Label>Data Pagamento</Form.Label>
                                        {dataPgtoReadOnly !== true ?
                                        <InputGroup
                                            hasValidation
                                            className={` ${erroDataPgto === true ? "endpoint-error" : ""}`}
                                            size="sm"
                                            placeholder="Quando?"
                                        >
                                            <SingleCalendarEditable setValue={setDataPgto} date={dataPgto} />
                                        </InputGroup>
                                        :
                                        <input
                                            type="text"
                                            className="form-control"
                                            disabled={true}
                                            value=""
                                            autoComplete='off'
                                        />
                                        }
                                        {erroDataPgto === true ?
                                            <small style={{color: 'red'}}>required</small>
                                        :<></>}
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Descontos/Taxas</Form.Label>
                                        {descontoReadOnly !== true ?
                                        <Controller
                                            control={control}
                                            name="desconto"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            defaultValue={desconto}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.desconto ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        : <input
                                            type="text"
                                            className="form-control"
                                            disabled={true}
                                            value=""
                                            autoComplete='off'
                                        />}
                                        <ErrorMessage
                                            errors={errors}
                                            name="desconto"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Juros/Multas</Form.Label>
                                        {jurosReadOnly !== true ?
                                        <Controller
                                            control={control}
                                            name="juros"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            defaultValue={juros}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.juros ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        : <input
                                            type="text"
                                            className="form-control"
                                            disabled={true}
                                            value=""
                                            autoComplete='off'
                                        />}
                                        <ErrorMessage
                                            errors={errors}
                                            name="juros"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    <Form.Group as={Col}>
                                        <Form.Label>Valor Pago</Form.Label>
                                        {valorPagoReadOnly !== true ?
                                        <Controller
                                            control={control}
                                            name="valorPago"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            defaultValue={valorPago}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.valorPago ? "true" : ""}
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    // onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                />
                                            )}
                                        />
                                        : <input
                                            type="text"
                                            className="form-control"
                                            disabled={true}
                                            value=""
                                            autoComplete='off'
                                        />}
                                        <ErrorMessage
                                            errors={errors}
                                            name="valorPago"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                            </Row>
                            :<></>}

                            <Row>
                                {receberPagar === 2 ?
                                <Form.Group as={Col} md={6} className="mb-3" controlId="formGridSupplier">
                                    <Form.Label>Pago A</Form.Label>
                                    {pagoAReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="pagoA"
                                        //rules={{ required: { value: pagoA ? false : true, message: "required" }, }}
                                        defaultValue={pagoA}
                                        render={({ field }: any) => (
                                            <GetSuppliersFilter
                                                propsField={field}
                                                propsErrors={errors}
                                                propsLabel="Fornecedor"
                                                setSupplier={setPagoA}
                                                //supplier={info ? info.fornecedor : "Selecione"}
                                            />
                                        )}
                                    />
                                    : <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />}
                                    <ErrorMessage
                                        errors={errors}
                                        name="pagoA"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                : <></>}
                                <Form.Group as={Col} md={3}>
                                    <Form.Label>Anexar Documento</Form.Label>
                                    <Controller
                                        control={control}
                                        name="arquivo"
                                        render={({ field }: any) => (
                                            <Form.Control type="file"
                                                {...field}
                                                label="arquivo"
                                                aria-invalid={errors?.arquivo ? "true" : ""}
                                                fullWidth
                                                variant="standard"
                                                margin="normal"
                                                id="arquivo"
                                                accept=".jpg"
                                                onChange={fileHandler}
                                                autoComplete='off'
                                                required />
                                        )}
                                    />
                                    <label className="btn-file w-100" htmlFor="arquivo">Escolha um arquivo</label>
                                </Form.Group>
                                <div className="col-12 col-md-3 d-flex">
                                    <div className="w-100 mt-auto mb-2" style={{ wordBreak: "break-word", textAlign: "left", lineHeight: 1.5 }}>
                                        {fileName}
                                    </div>
                                </div>
                            </Row>

                            {transferencia !== true ?
                            <>
                            <hr style={{ margin: "30px 0" }} />
    
                            <Row className="mb-3">
                                <Form.Group as={Col} md={6}>
                                    <Form.Label>{receberPagar === 1 ? 'Cliente' : 'Fornecedor'}</Form.Label>
                                    {clienteFornecedorReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="fornecedor"
                                        rules={{ required: { value: !client, message: "required" }, }}
                                        defaultValue={client}
                                        render={({ field }: any) => (
                                            (receberPagar === 1 ?
                                            <GetAgentsExtract
                                                propsField={field}
                                                propsErrors={errors}
                                                propsLabel="Cliente"
                                                actionAgents={setClient}
                                                showCorporateNames={true}
                                                byId={true}
                                            />
                                            :
                                            <GetSuppliersFilter
                                                propsField={field}
                                                propsErrors={errors}
                                                propsLabel="Fornecedor"
                                                setSupplier={setClient}
                                                //supplier={info ? info.fornecedor : "Selecione"}
                                            />
                                            )
                                        )}
                                    />
                                    :
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />}
                                    <ErrorMessage
                                        errors={errors}
                                        name="fornecedor"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
    
                                <Form.Group as={Col} md={6} className="mb-3">
                                    <Form.Label>Centro de Custo</Form.Label>
                                    {centroCustoReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="centroCusto"
                                        defaultValue={centroCusto}
                                        render={({ field }: any) => (
                                            <GetCostCenterEdit propsField={field} propsErrors={errors} propsLabel="Centro de Custo" setCostCenter={setCentroCusto} />
                                        )}
                                    />
                                    :
                                    <input
                                        type="text"
                                        className="form-control"
                                        disabled={true}
                                        value=""
                                        autoComplete='off'
                                    />}
                                    <ErrorMessage
                                        errors={errors}
                                        name="centroCusto"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
    
                            <Row className="mb-3">
                                <Form.Group as={Col}>
                                    <Form.Label>Observação</Form.Label>
                                    {observacaoReadOnly !== true ?
                                    <Controller
                                        control={control}
                                        name="observacao"
                                        defaultValue={observacao}

                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.observacao ? "true" : ""}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                as="textarea"
                                                rows={4}
                                            />
                                        )}
                                    />
                                    : <textarea name="observacao" className="form-control" disabled={true} rows={4}></textarea>}
                                    <ErrorMessage
                                        errors={errors}
                                        name="observacao"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                            </>
                            : <></>}
                        </div>
                        <div className='mt-4 d-flex justify-content-end'>
                            <button
                                className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase"
                                onClick={closeModal}
                            >
                                Cancelar
                            </button>
                            <button
                                className="btn btn-default btn-primary px-4 text-uppercase"
                                type="submit"
                            >
                                {
                                    loading === true
                                        ?
                                        <div className="load"></div>
                                        :
                                        "Cadastrar"
                                }
                            </button>
                        </div>
                    </div>
                </Form>
            </>
            </Modal>

            <div >
            
                <Button
                className="btn btn-bills"
                onClick={() => doShowModal()}
                >
                <FontAwesomeIcon
                    icon={["fal", "edit"]}
                    size="1x"
                />
                Editar em Lote
                </Button>
            
            </div>
        </div>
        );
    
}

export default EditExtract;
