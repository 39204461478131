import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import { Button, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";

import { useForm } from "react-hook-form";
import { Controller } from "react-hook-form";
import SingleCalendarWithDefaultValue from "../../../../components/Calendar/SingleCalendarWithDefaultValue";

import "./AddBannerModal.scss";
import api from "../../../../services/api";
import ModalShow from "../../../../components/Modal/ModalShow/ModalShow";

export interface propEdit {
  isEdit?: any;
  infoEdit?: any;
  setModalShow: any;
  url?: string;
  language?: string;
  bannerListSize?: number;
}

const AddBannerModal: React.FC<propEdit> = ({
  isEdit = false,
  infoEdit,
  setModalShow,
  url,
  language,
  bannerListSize = 0
}: propEdit) => {
  const [modalLoading, setModalLoading] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("Carregando");
  const [modalLog, setModalLog] = useState<any>(null);

  const [allowCloseModal, setAllowCloseModal] = useState<boolean>(false);

  const [bannerByIdImg, setBannerByIdImg] = useState<any>(null);
  const [bannerDeskImg, setBannerDeskImg] = useState<any>(null);
  const [bannerMediumImg, setBannerMediumImg] = useState<any>(null);
  const [bannerMobileImg, setBannerMobileImg] = useState<any>(null);
  const [bannerDeskImgFile, setBannerDeskImgFile] = useState<any>(null);
  const [bannerMediumImgFile, setBannerMediumImgFile] = useState<any>(null);
  const [bannerMobileImgFile, setBannerMobileImgFile] = useState<any>(null);

  const [errorMsg, setErrorMsg] = useState<any>("");
  const validated = false;
  const acceptedFormats: string[] = ['image/jpeg', 'image/gif', 'image/webp'];

  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm();

  let dateStart: any =
    isEdit === true ? new Date(infoEdit.dateStart) : null;
  const beginStr =
    dateStart !== null
      ? `${dateStart.getDate() < 10 ? "0" : ""}${dateStart.getDate()}/${dateStart.getMonth() + 1 < 10 ? "0" : ""
      }${dateStart.getMonth() + 1}/${dateStart.getFullYear()}`
      : null;

  let dateFinish: any =
    isEdit === true ? new Date(infoEdit.dateEnd) : null;
  const endStr =
    dateFinish !== null
      ? `${dateFinish.getDate() < 10 ? "0" : ""}${dateFinish.getDate()}/${dateFinish.getMonth() + 1 < 10 ? "0" : ""
      }${dateFinish.getMonth() + 1}/${dateFinish.getFullYear()}`
      : null;

  const [beginDate, setBeginDate] = useState<any>(beginStr !== null ? [beginStr] : "");
  const [endDate, setEndDate] = useState<any>(endStr !== null ? [endStr] : "");

  const handleClose = () => {
    setModalShow(false);
  };

  useEffect(() => {
    setValue("dateStart", beginDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [beginDate]);

  useEffect(() => {
    setValue("dateEnd", endDate);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [endDate]);

  useEffect(() => {
    if (isEdit) {
      getBannerById();
    } else {
      setValue('bannerCheck', true);
      setValue('bannerCheckDesktop', true);
      setValue('bannerCheckMedium', true);
      setValue('bannerCheckMobile', true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // IMAGE HANDLERS FUNCTIONS
  //!!!!!!!!!!!!!!!!!!!-----PORTUGUESE-----!!!!!!!!!!!!!!!!!!!!!!!
  const imageHandlerDesk = (e: any) => {
    if (e.target.files.length > 0 && e.target.files[0].size <= 1048576) {
      if (!acceptedFormats.includes(e.target.files[0].type)) {
        alert("Favor, inserir uma imagem no formato jpeg, webp ou gif!");
        return;
      }
      setValue("bannerBase64Desk", e.target.value);

      const reader = new FileReader();
      const img: any = new Image();
      reader.onload = () => {
        if (reader.readyState === 2) {

          img.onload = function () {
            if (this.width !== 1920 || this.height !== 533) {
              alert('As medidas devem ser de 1920 x 533');
              // setValue("bannerBase64Desk", '');
              // setBannerDeskPtNameImg('');
              // setBannerDeskImg('');
            } else {
              setBannerDeskImgFile(e.target.files[0]);
              setBannerDeskImg(reader.result);
              //setBannerDeskPtNameImg(e.target.files[0].name);
            }

          };
          img.src = reader.result;
        }
      };
      reader.readAsDataURL(e.target.files[0]);

    } else {
      alert("Tamanho de imagem não suportado!");
      setValue("bannerBase64Desk", '');
      //setBannerDeskPtNameImg('');
      setBannerDeskImg(null);
    }
  };

  const imageHandlerMedium = (e: any) => {
    if (e.target.files.length > 0 && e.target.files[0].size <= 1048576/* 1048576 */) {
      if (!acceptedFormats.includes(e.target.files[0].type)) {
        alert("Favor, inserir uma imagem no formato jpeg, webp ou gif!");
        return;
      }
      setValue("bannerBase64Medium", e.target.value);

      const reader = new FileReader();
      const img: any = new Image();
      reader.onload = () => {
        if (reader.readyState === 2) {

          img.onload = function () {
            if (this.width !== 1024 || this.height !== 480) {
              alert('As medidas devem ser de 1024 x 480');
              // setValue("bannerBase64Medium", '');
              // setBannerMediumPtNameImg('');
              // setBannerMediumImg('');
            } else {
              setBannerMediumImgFile(e.target.files[0]);
              setBannerMediumImg(reader.result);
              //setBannerMediumPtNameImg(e.target.files[0].name);
            }

          };
          img.src = reader.result;
        }
      };
      reader.readAsDataURL(e.target.files[0]);

    } else {
      alert("Tamanho de imagem não suportado!");
      setValue("bannerBase64Medium", '');
      //setBannerMediumPtNameImg('');
      setBannerMediumImg(null);
    }
  };

  const imageHandlerMobile = (e: any) => {
    if (e.target.files.length > 0 && e.target.files[0].size <= 1048576) {
      if (!acceptedFormats.includes(e.target.files[0].type)) {
        alert("Favor, inserir uma imagem no formato jpeg, webp ou gif!");
        return;
      }
      setValue("bannerBase64Mobile", e.target.value);

      const reader = new FileReader();
      const img: any = new Image();
      reader.onload = () => {
        if (reader.readyState === 2) {

          img.onload = function () {
            if (this.width !== 768 || this.height !== 640/* this.width !== 768 || this.height !== 480 */) {
              alert('As medidas devem ser de 768 x 640');
            } else {
              setBannerMobileImgFile(e.target.files[0]);
              setBannerMobileImg(reader.result);
              //setBannerMobilePtNameImg(e.target.files[0].name);
            }

          };
          img.src = reader.result;
        }
      };
      reader.readAsDataURL(e.target.files[0]);

    } else {
      alert("Tamanho de imagem não suportado!");
      setValue("bannerBase64Mobile", '');
      //setBannerMobPtNameImg('');
      setBannerMobileImg(null);
    }
  };

  let endDateString: any =
    typeof endDate === typeof []
      ? endDate[0].toString().split("/").reverse().join("")
      : endDate.toString().split("/").reverse().join("");
  let beginDateString: any =
    typeof beginDate === typeof []
      ? beginDate[0].toString().split("/").reverse().join("")
      : beginDate.toString().split("/").reverse().join("");

  const onSubmit = (obj: any) => {
    if (endDateString >= beginDateString) {
      setModalLoading(true);
      let values: any = getValues();

      obj.bannerUrlDesktop = values.bannerUrlDesktop;
      obj.bannerUrlEnglish = values.bannerUrlEnglish;
      obj.bannerUrlSpanish = values.bannerUrlSpanish;
      obj.bannerDateStart =
        typeof beginDate === typeof []
          ? beginDate[0].split("/").reverse().join("-")
          : beginDate.split("/").reverse().join("-");
      obj.bannerDateEnd =
        typeof endDate === typeof []
          ? endDate[0].split("/").reverse().join("-")
          : endDate.split("/").reverse().join("-");
      obj.bannerBase64 = bannerDeskImg;

      delete obj.title;
      delete values.title;

      const linkBanners = async (banners: any) => {
        const objectTempBanner = {
          alreadyRegistered: false,
          bannerDateStart: obj.bannerDateStart,
          bannerDateEnd: obj.bannerDateEnd,
          bannerUrlDesktop: obj.bannerUrlDesktop,
          bannerUrlMedium: obj.bannerUrlMedium,
          bannerUrlMobile: obj.bannerUrlMobile,
          bannerImgDesktop: banners.bannerDeskImg,
          bannerImgMedium: banners.bannerMediumImg,
          bannerImgMobile: banners.bannerMobileImg,
          statusDesktop: obj.bannerCheckDesktop,
          statusMedium: obj.bannerCheckMedium,
          statusMobile: obj.bannerCheckMobile,
          status: obj.bannerCheck,
          i: 0,
        };
        insertBanner(objectTempBanner)
      };

      let imgObject: any = {
        bannerDeskImg: null,
        bannerMediumImg: null,
        bannerMobileImg: null,
      };

      if (bannerDeskImg !== null) {
        imgObject.bannerDeskImg = bannerDeskImgFile;
      }
      if (bannerMediumImg !== null) {
        imgObject.bannerMediumImg = bannerMediumImgFile;
      }
      if (bannerMobileImg !== null) {
        imgObject.bannerMobileImg = bannerMobileImgFile;
      }

      linkBanners(imgObject);

    } else {
      setErrorMsg(
        "A data de fim do banner deve ser maior que a data de início!"
      );
    }
  };

  const getBannerById = async () => {
    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    try {
      const res = await api.get(`${process.env.REACT_APP_SERVER_URL_API_NODE}/banner_by_id/${language}/${infoEdit.id}`, config);
      if (res.status === 200) {
        let bannerInfo: any = res.data.data.banner;
        setBannerByIdImg(res.data.data.banner);
        setValue('bannerCheck', bannerInfo.status);
        setBeginDate(new Date(bannerInfo.dateStart).toJSON().slice(0, 10).split('-').reverse().join('/'))
        setEndDate(new Date(bannerInfo.dateEnd).toJSON().slice(0, 10).split('-').reverse().join('/'))

        //desktop
        let desktop: any = bannerInfo.images.find((elem: any) => elem.size === "Desktop");
        setValue('bannerCheckDesktop', desktop?.status || false);
        setValue("bannerUrlDesktop", desktop?.link || "");
        setBannerDeskImg(desktop?.image || null)
        //setValue("bannerBase64Desk", bannerInfo.images[0].image);

        //medium
        let medium: any = bannerInfo.images.find((elem: any) => elem.size === "Medium");
        setValue('bannerCheckMedium', medium?.status || false);
        setValue("bannerUrlMedium", medium?.link || '');
        setBannerMediumImg(medium?.image || null)
        //setValue("bannerBase64Medium", bannerInfo.images[1].image);

        //mobile
        let mobile: any = bannerInfo.images.find((elem: any) => elem.size === "Mobile");
        setValue('bannerCheckMobile', mobile?.status || false);
        setValue("bannerUrlMobile", mobile?.link || '');
        setBannerMobileImg(mobile?.image || null)
        //setValue("bannerBase64Mobile", bannerInfo.images[2].image);
      }
    } catch (error: any) {

    }
  }

  const insertBanner = (objectTempBanner: any) => {
    let user: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user;
    let now: any = new Date();
    let images: any = [];
    console.log(objectTempBanner)
    //if (objectTempBanner.bannerImgDesktop) {
    images.push({
      "image": objectTempBanner.statusDesktop ? objectTempBanner.bannerImgDesktop : '',
      "alt": '',
      "link": objectTempBanner.statusDesktop ? objectTempBanner.bannerUrlDesktop : '',
      "status": objectTempBanner.statusDesktop,
      "size": 'Desktop',
    })
    //}
    //if (objectTempBanner.bannerImgMedium) {
    images.push({
      "image": objectTempBanner.statusMedium ? objectTempBanner.bannerImgMedium : '',
      "alt": '',
      "link": objectTempBanner.statusMedium ? objectTempBanner.bannerUrlMedium : '',
      "status": objectTempBanner.statusMedium,
      "size": 'Medium',
    })
    //}
    //if (objectTempBanner.bannerImgMobile) {
    images.push({
      "image": objectTempBanner.statusMobile ? objectTempBanner.bannerImgMobile : '',
      "alt": '',
      "link": objectTempBanner.statusMobile ? objectTempBanner.bannerUrlMobile : '',
      "status": objectTempBanner.statusMobile,
      "size": 'Mobile',
    })
    //}

    let objFinal: any = {
      "author": `${user.nome} ${user.lastName}`,
      "status": objectTempBanner.status,
      "lng": language,
      "dateStart": objectTempBanner.bannerDateStart,
      "dateEnd": objectTempBanner.bannerDateEnd,
      "lastChange": `${user.nome} ${user.lastName} - ${now.toJSON()}`,
      "images": images,
      "order": isEdit ? bannerByIdImg.order : (bannerListSize + 1)
    }

    createBanner(objFinal);
  }

  const createBanner = async (objBanners: any) => {
    const formData = new FormData();
    let endpoint: string = ''

    if (isEdit) {
      endpoint = `${process.env.REACT_APP_SERVER_URL_API_NODE}/banner_edit/${language}`;
      formData.append(`id`, infoEdit.id);
    } else {
      endpoint = `${process.env.REACT_APP_SERVER_URL_API_NODE}/banner/${language}`;
    }

    formData.append(`author`, objBanners.author);
    formData.append(`status`, objBanners.status);
    formData.append(`order`, objBanners.order);
    formData.append(`lng`, objBanners.lng);
    formData.append(`dateStart`, objBanners.dateStart);
    formData.append(`dateEnd`, objBanners.dateEnd);
    formData.append(`lastChange`, objBanners.lastChange);

    ['Desktop', 'Medium', 'Mobile'].forEach((elem: any, index: any) => {
      let aux: any = objBanners.images.find((img: any) => img.size === elem);
      //if (aux !== undefined) {
      formData.append(`images[${index}][image]`, aux.image === null ? bannerByIdImg?.images?.find((img: any) => img.size === elem).image || '' : aux.image);
      formData.append(`images[${index}][alt]`, aux.alt);
      formData.append(`images[${index}][link]`, aux.link || '');
      formData.append(`images[${index}][status]`, aux.status);
      formData.append(`images[${index}][size]`, elem);
      /* } else {
        let img: any = bannerByIdImg.images?.find((img: any) => img.size === elem)
        formData.append(`images[${index}][image]`, img.image);
        formData.append(`images[${index}][alt]`, img.alt);
        formData.append(`images[${index}][link]`, img.link);
        formData.append(`images[${index}][status]`, img.status);
        formData.append(`images[${index}][size]`, elem);
      } */

      /* let aux: any = objBanners.images.find((img: any) => img.size === elem);
      if (aux !== undefined) {
        formData.append(`images[${index}][image]`, aux.image);
        formData.append(`images[${index}][alt]`, aux.alt);
        formData.append(`images[${index}][link]`, aux.link);
        formData.append(`images[${index}][status]`, aux.status);
        formData.append(`images[${index}][size]`, elem);
      } else {
        let img: any = bannerByIdImg.images?.find((img: any) => img.size === elem)
        formData.append(`images[${index}][image]`, img.image);
        formData.append(`images[${index}][alt]`, img.alt);
        formData.append(`images[${index}][link]`, img.link);
        formData.append(`images[${index}][status]`, img.status);
        formData.append(`images[${index}][size]`, elem);
      } */
    });

    const config = {
      headers: { 'Content-Type': 'multipart/form-data' },
    };

    try {
      const res = await api.post(endpoint, formData, config);
      if (res.status === 200) {
        setModalLoading(true);
        setModalLog(0);
        setModalMessage(res.data.message);
        setAllowCloseModal(true);
      } else {
        setModalLoading(true);
        setModalLog(1);
        setModalMessage("Erro ao processar operação!")
      }

    } catch (error: any) {
      setModalLoading(true);
      setModalLog(1);
      setModalMessage("Erro ao processar operação!")
    }
  }

  useEffect(() => {
    if (!modalLoading && allowCloseModal) {
      setModalShow(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modalLoading])

  return (
    <>
      <Modal.Header closeButton className="customized-modal-header mx-2">
        <Modal.Title id="contained-modal-title-vcenter">
          <h2 className="mb-0">Inserir Banner</h2>
        </Modal.Title>
      </Modal.Header>
      <Form
        noValidate
        validated={validated}
        onSubmit={handleSubmit(onSubmit)}
        autoComplete="false"
      >
        <Modal.Body className="modal-body sucess-pos d-flex justify-content-evenly mx-2 banners-modal">

          <div>
            <Row>
              <Form.Group
                as={Col}
                md="12"
                className="d-flex justify-content-start pb-3"
              >
                <Form.Label className="mr-2">Habilitar Banner</Form.Label>
                <Controller
                  control={control}
                  name="bannerCheck"
                  render={({ field }: any) => (
                    <Form.Check
                      {...field}
                      type="switch"
                      id="custom-switch-1"
                      checked={getValues().bannerCheck}
                    />
                  )}
                />
              </Form.Group>
            </Row>
            <Row className="pb-3">
              <Form.Group as={Col} md="6" className=" d-flex flex-column" >
                <Form.Label className="mb-1">Início</Form.Label>
                <Controller
                  control={control}
                  name="dateStart"
                  rules={{
                    required: {
                      value: true,
                      message: "Por favor, insira uma data!",
                    },
                  }}
                  render={({ field }: any) => (
                    <InputGroup hasValidation className="" size="sm">
                      <SingleCalendarWithDefaultValue
                        date={beginDate}
                        setValue={setBeginDate}
                      />
                    </InputGroup>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="dateStart"
                  render={({ message }) => (
                    <small style={{ color: "red" }}>{message}</small>
                  )}
                />
              </Form.Group>

              <Form.Group
                as={Col}
                md="6"
                className="d-flex flex-column"
              >
                <Form.Label className="mb-1">Fim</Form.Label>
                <Controller
                  control={control}
                  name="dateEnd"
                  rules={{
                    required: {
                      value: true,
                      message: "Por favor, insira uma data!",
                    },
                  }}
                  render={({ field }: any) => (
                    <InputGroup hasValidation className="" size="sm">
                      <SingleCalendarWithDefaultValue
                        date={endDate}
                        setValue={setEndDate}
                      />
                    </InputGroup>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="dateEnd"
                  render={({ message }) => (
                    <small style={{ color: "red" }}>{message}</small>
                  )}
                />
                {errorMsg !== "" ? (
                  <small
                    style={{ position: "absolute", top: "220px", color: "red" }}
                  >
                    {errorMsg}
                  </small>
                ) : (
                  <></>
                )}
              </Form.Group>
            </Row>

            <Row className="">
              <Col md={12}>
                <h5>Português</h5>
              </Col>
            </Row>

            <Row className="size-container m-0">
              <Col md={11}>
                <h5 className="title">Desktop</h5>
              </Col>
              <Form.Group
                as={Col}
                md="1"
                className="d-flex justify-content-end align-items-center"
              >
                <Controller
                  control={control}
                  name="bannerCheckDesktop"
                  render={({ field }: any) => (
                    <Form.Check
                      {...field}
                      type="switch"
                      id="custom-switch-2"
                      checked={getValues().bannerCheckDesktop}
                    //onChange={(e: any) => handleCheck(e)}
                    />
                  )}
                />
              </Form.Group>
              <hr />
              <Col md={12} className="">
                <Form.Group as={Col} md="12" className="pb-3 d-flex flex-column" >
                  <Form.Label className="mb-1">Link</Form.Label>
                  <Controller
                    control={control}
                    name="bannerUrlDesktop"
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      />
                    )}
                  />
                </Form.Group>

                <div className="bg-image-info mb-3">
                  <small>
                    Resolução mínima <b>1920 x 533</b> | Formatos aceitos <b>JPEG, JPG, Webp, GIF</b>
                  </small>
                </div>

                <Form.Group
                  as={Col}
                  md="12"
                  className="pb-1 d-flex flex-column"

                >
                  <Form.Label className="mb-1" htmlFor="bannerDesk">
                    {bannerDeskImg === null
                      ?
                      <Col md={12} className={'image empty-image'}>
                        <FontAwesomeIcon
                          size="lg"
                          icon={["fal", "plus"]}
                          style={{ color: "#707070", margin: "0 12px" }}
                        />
                      </Col>
                      :
                      <Col md={12} style={{ backgroundImage: `url('${bannerDeskImg.includes("base64") ? bannerDeskImg : url + bannerDeskImg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', width: '100%', aspectRatio: "1920 / 500" }}></Col>
                    }
                  </Form.Label>
                  <Controller
                    control={control}
                    name="bannerBase64Desk"
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        aria-invalid={errors?.bannerBase64 ? "true" : "false"}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        accept=".jpg"
                        id="bannerDesk"
                        onChange={imageHandlerDesk}
                        autoComplete='off'
                      />
                    )}
                  />

                </Form.Group>


              </Col>
            </Row>

            <Row className="size-container m-0 mt-3">
              <Col md={11}>
                <h5 className="title">Medium</h5>
              </Col>
              <Form.Group
                as={Col}
                md="1"
                className="d-flex justify-content-end align-items-center"
              >
                <Controller
                  control={control}
                  name="bannerCheckMedium"
                  render={({ field }: any) => (
                    <Form.Check
                      {...field}
                      type="switch"
                      id="custom-switch-3"
                      checked={getValues().bannerCheckMedium}
                    //onChange={(e: any) => handleCheck(e)}
                    />
                  )}
                />
              </Form.Group>
              <hr />
              <Col md={12} className="">
                <Form.Group as={Col} md="12" className="pb-3 d-flex flex-column" >
                  <Form.Label className="mb-1">Link</Form.Label>
                  <Controller
                    control={control}
                    name="bannerUrlMedium"
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      />
                    )}
                  />
                </Form.Group>

                <div className="bg-image-info mb-3">
                  <small>
                    Resolução mínima <b>1024 x 480</b> | Formatos aceitos <b>JPEG, JPG, Webp, GIF</b>
                  </small>
                </div>

                <Form.Group
                  as={Col}
                  md="12"
                  className="pb-1 d-flex flex-column"

                >
                  <Form.Label className="mb-1" htmlFor="bannerMedium">
                    {bannerMediumImg === null
                      ?
                      <Col md={12} className={'image empty-image'}>
                        <FontAwesomeIcon
                          size="lg"
                          icon={["fal", "plus"]}
                          style={{ color: "#707070", margin: "0 12px" }}
                        />
                      </Col>
                      :
                      <Col md={12} style={{ backgroundImage: `url('${bannerMediumImg.includes("base64") ? bannerMediumImg : url + bannerMediumImg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '100%', aspectRatio: "1920 / 500" }}></Col>
                    }
                  </Form.Label>
                  <Controller
                    control={control}
                    name="bannerBase64Medium"
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        aria-invalid={errors?.bannerBase64 ? "true" : "false"}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        accept=".jpg"
                        id="bannerMedium"
                        onChange={imageHandlerMedium}
                        autoComplete='off'
                      />
                    )}
                  />

                </Form.Group>
              </Col>
            </Row>

            <Row className="size-container m-0 mt-3">
              <Col md={11}>
                <h5 className="title">Mobile</h5>
              </Col>
              <Form.Group
                as={Col}
                md="1"
                className="d-flex justify-content-end align-items-center"
              >
                <Controller
                  control={control}
                  name="bannerCheckMobile"
                  render={({ field }: any) => (
                    <Form.Check
                      {...field}
                      type="switch"
                      id="custom-switch-4"
                      checked={getValues().bannerCheckMobile}
                    //onChange={(e: any) => handleCheck(e)}
                    />
                  )}
                />
              </Form.Group>
              <hr />
              <Col md={12} className="">
                <Form.Group as={Col} md="12" className="pb-3 d-flex flex-column" >
                  <Form.Label className="mb-1">Link</Form.Label>
                  <Controller
                    control={control}
                    name="bannerUrlMobile"
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      />
                    )}
                  />
                </Form.Group>

                <div className="bg-image-info mb-3">
                  <small>
                    Resolução mínima <b>768 x 640</b> | Formatos aceitos <b>JPEG, JPG, Webp, GIF</b>
                  </small>
                </div>

                <Form.Group
                  as={Col}
                  md="12"
                  className="pb-1 d-flex flex-column"

                >
                  <Form.Label className="mb-1" htmlFor="bannerMobile">
                    {bannerMobileImg === null
                      ?
                      <Col md={12} className={'image empty-image'}>
                        <FontAwesomeIcon
                          size="lg"
                          icon={["fal", "plus"]}
                          style={{ color: "#707070", margin: "0 12px" }}
                        />
                      </Col>
                      :
                      <Col md={12} style={{ backgroundImage: `url('${bannerMobileImg.includes("base64") ? bannerMobileImg : url + bannerMobileImg}')`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '100%', aspectRatio: "1920 / 500" }}></Col>
                    }
                  </Form.Label>
                  <Controller
                    control={control}
                    name="bannerBase64Mobile"
                    render={({ field }: any) => (
                      <Form.Control
                        type="file"
                        {...field}
                        aria-invalid={errors?.bannerBase64 ? "true" : "false"}
                        fullWidth
                        variant="standard"
                        margin="normal"
                        accept=".jpg"
                        id="bannerMobile"
                        onChange={imageHandlerMobile}
                        autoComplete='off'
                      />
                    )}
                  />

                </Form.Group>
              </Col>
            </Row>
          </div>

        </Modal.Body>
        <Modal.Footer>
          <div className="d-flex justify-content-end mb-3 mx-2">
            <Button
              variant="outline-primary"
              className="btn-default mr-2 "
              type="button"
              onClick={handleClose}
            >
              Cancelar
            </Button>
            <Button
              className="btn-default "
              type="submit"
            //onClick={handleSubmit(onSubmit)}
            >
              Salvar
            </Button>
          </div>
        </Modal.Footer>
      </Form>

      <ModalShow
        modalShow={modalLoading}
        setModalShow={setModalLoading}
        modalMessage={modalMessage}
        setModalMessage={setModalMessage}
        modalLog={modalLog}
        setModalLog={setModalLog}
      />
    </>
  );
};
export default AddBannerModal;
