import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsLabel: any,
    propsErrors: any,
    isEdit?: any;
    infoEdit?: any;
    hasSavedInputs?: any;
    setValue: any;
};

const GetProductsListSubCategoryMult: React.FC<propPlus> = ({
    propsField, propsLabel, propsErrors, isEdit = false, infoEdit = [], hasSavedInputs = false, setValue
}: propPlus) => {
    const { t } = useTranslation();

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
    const [defaultItems, setDefaultItems] = useState<any>([]);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listCategories() {
            try {
                const { data } = await api.get(`/DropDown/GetProductsListSubCategory`, config);
                if (data.status !== 400) {
                    let defaultIds: any = infoEdit.map((e: any) => e?.productCategoryId);
                    let defaultValues: any = data.data.filter((e: any) => defaultIds.includes(e.id)).map(({ id, name }: any) => ({ value: id, label: name })) || [];

                    setValue('subcategory', defaultValues);
                    setDefaultItems(defaultValues)
                    setInfo(data.data)
                }
            } catch (error: any) {
                console.log(error);
                setTimeout(() => {
                    listCategories();
                }, 5000);
            }
        }
        listCategories();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);


    if (info !== null) {
        const transformed = info.map(({ id, name }: any) => ({ value: id, label: name }));
        return (
            <>
                <div className={propsErrors.supplierByResourceModel !== undefined ? "endpoint-error" : "endpoint"}>
                    <Select
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        {...propsField}
                        aria-invalid={propsErrors?.supplierByResourceModel ? "true" : ""}
                        label={propsLabel}
                        as="select"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        margin="normal"
                        placeholder={t('accordionFinancial.select.select')}
                        isMulti
                        options={transformed}
                        getOptionLabel={(option: any) => `${option.label}`}
                        defaultValue={defaultItems}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )
    }
}

export default GetProductsListSubCategoryMult;