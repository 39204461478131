import React, { useState, useEffect } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import api from '../../../../services/api';

import Select from 'react-select';
import './FilterSessionClosure.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import SingleCalendarEditable from "../../../../components/Calendar/SingleCalendarEditable";
//import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import GlobalAlert from "../../../../components/Alert/GlobalAlert/GlobalAlert";

function FilterSessionClosure(props: any) {

    const { t } = useTranslation();

    const hoje = new Date();
    const ontem = new Date(hoje.getFullYear(), hoje.getMonth(), hoje.getDate() - 1); // hoje - 1 dia = ontem
    const dataPadrao = `${ontem.getDate()}/${ontem.getMonth()+1}/${ontem.getFullYear()}`;

    const [filterSession, setFilterSession] = useState<any>('');
    const [filterDate, setFilterDate] = useState<any>(dataPadrao);
    const [filterType, setFilterType] = useState<any>('');
    const [filterStatus, setFilterStatus] = useState<any>('1');
    const [sessions, setSessions] = useState<any>([]);

    const [, setAlert] = useState<any>();
    //const [checked, setChecked] = useState<boolean>(true);

    //const [info, setInfo] = useState<any>(null);
    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    const handleFilterClick = () => {
        const sessionId = filterSession ? filterSession.sessionReloc : '';

        props.buscar(sessionId, filterType, filterStatus, filterDate);
        //console.log(filterName, filterState, filterType, filterStatus, filterDate)
    };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterSession("");
        setFilterDate(dataPadrao);
        setFilterType("")
        setFilterStatus("1");
        props.buscar("", "", "1", dataPadrao);
    };

    const incDate = (e: any, dias: number) => {
        e.preventDefault();

        const sessionId = filterSession ? filterSession.sessionReloc : '';

        //const today = new Date();

        //const newDate = (filterDate.length === 0) ? `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}` : filterDate[0];

        const datao = filterDate.split('/');
        
        const newDate1 = new Date(parseInt(datao[2]), parseInt(datao[1])-1, parseInt(datao[0])+dias);
        //console.log(parseInt(datao[0])+dias); return;
        const newDateStr = `${newDate1.getDate()}/${newDate1.getMonth()+1}/${newDate1.getFullYear()}`

        setFilterDate(newDateStr);

        props.buscar(sessionId, filterType, filterStatus, newDateStr);
    };


    /* function handleChecked() {
        if (checked === true) {
            setChecked(false);
        } else {
            setChecked(true);
        }
    } */

    //const handleChangeSession = (e: React.ChangeEvent<HTMLInputElement>) => {
    //    const newValue = e.target.value;
    const handleChangeSession = (data: any) => {
        setFilterSession(data);
        //props.setFilterSession(newValue);
    } 

    /* const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterDate(newValue);
        props.setFilterDate(newValue);
    } */

    // evento altera tipo
    // filtra sessions pelo tipo
    // filtra status pelo tipo (todos mostra todos?)
    const handleChangeType = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterType(newValue);
        //props.setFilterType(newValue);

        updateDropdowns(filterDate, newValue);
    }

    const handleChangeStatus = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterStatus(newValue);
        console.log(newValue);
        //props.setFilterStatus(newValue);
    }

    /* const handleDateClick = (inc: any) => {
        const newDate = new Date(filterDate);
        newDate.setDate(newDate.getDate() + inc);
        const newDateFmt = newDate.toISOString().substring(0, 10);
        setFilterDate(newDateFmt);
        props.setFilterDate(newDateFmt);

        props.buscar(filterSession, filterType, filterStatus, newDateFmt);
    } */

    /* const handleChangeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterDate(newValue);
        console.log(newValue);
        //props.setFilterStatus(newValue);
    } */
    

    const updateDropdowns = (date: any, type: any) => {
        //console.log(`"${date}"`); return;
        const bdate = (date !== "") && date.split ? date.split('/') : [];
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listSessions() {
            try {
                const params = {
                    date: bdate.length === 0 ? `${ontem.getFullYear()}-${ontem.getMonth() + 1}-${ontem.getDate()}` : `${bdate[2]}-${bdate[1]}-${bdate[0]}`,
                    type: type !== '' ? type : null
                };

                const { data } = await api.post(`/SessionClosure/GetSessionsByDateAndTypeAsync`, params, config);
                if (data.status !== 400) {
                    const dados = data.data;
                    setSessions(dados)
                    /* ; */
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                    //;
                }
            }
        }
        listSessions();
    }

    useEffect(() => {
        if (props.sessions?.length === 0 || props.error === true) {
            setAlert(
                <GlobalAlert alertType={"danger"}>
                    Não foi possível encontrar nenhuma session.
                </GlobalAlert>
            )
            setTimeout(() => setAlert(null), 2000);
        } else {
            setAlert(null);
        }

        updateDropdowns(filterDate, filterType);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.sessions, props.error, filterDate, filterType])

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion>
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Fechamento</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {/* {alert} */}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Data</Form.Label>
                                                <div className="d-flex">
                                                    <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, -1)}><FontAwesomeIcon
                                                            size="sm"
                                                            icon={["fal", "arrow-left"]}
                                                            style={{ color: "#707070", margin: "0px", padding: "0px"}}
                                                        /></button>
                                                    <InputGroup
                                                        hasValidation
                                                        className={`""`} 
                                                        size="sm"
                                                        placeholder="Quando?"
                                                    >
                                                        <SingleCalendarEditable setValue={setFilterDate} date={filterDate} />
                                                        {/* <RangeCalendar date={filterDate} setDate={setFilterDate} /> */}
                                                    </InputGroup>
                                                    <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, 1)}><FontAwesomeIcon
                                                            size="sm"
                                                            icon={["fal", "arrow-right"]}
                                                            style={{ color: "#707070", margin: "0px", padding: "0px" }}
                                                        /></button>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('sessionClosure.sessionclosure.tipo')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeType(e)}
                                                            value={filterType}
                                                        >
                                                            <option value="">{t('sessionClosure.sessionclosure.filterAll')}</option>
                                                            <option value="1">{t('sessionClosure.sessionclosure.transfer')}</option>
                                                            <option value="2">{t('sessionClosure.sessionclosure.tour')}</option>
                                                            <option value="3">{t('sessionClosure.sessionclosure.ticket')}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>{t('sessionClosure.sessionclosure.session')}</Form.Label>
                                                <InputGroup hasValidation>
                                                    {/* <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeSession(e)}
                                                        >
                                                            <option value="">{t('sessionClosure.sessionclosure.filterAll')}</option>
                                                            {sessions.length > 0 ? sessions.map((info: any, index: Key) => (
                                                                <option key={index} value={info.sessionReloc}>{info.sessionReloc} - {info.descricao}</option>
                                                            )) : ''}
                                                        </Form.Select> */}
                                                    <div className="endpoint input-select-custom w-100">
                                                        <Select
                                                            aria-labelledby="aria-label"
                                                            inputId="aria-example-input"
                                                            name="aria-live-color"
                                                            onMenuOpen={onMenuOpen}
                                                            onMenuClose={onMenuOpen}
                                                            placeholder={t('accordionInfoCompany.endpoint')}
                                                            options={Array.from(sessions)}
                                                            getOptionLabel={(option: any) => `${option.sessionReloc} - ${option.descricao}`}
                                                            onChange={handleChangeSession}
                                                            value={filterSession}
                                                            isClearable={true}
                                                        />
                                                    </div>
                                                </InputGroup>
                                            </Form.Group>
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>{t('sessionClosure.sessionclosure.sessionStatus')}</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeStatus(e)}
                                                            value={filterStatus}
                                                        >
                                                            <option value="">{t('sessionClosure.sessionclosure.filterAll')}</option>
                                                            <option value="1">{t('sessionClosure.sessionclosure.pending')}</option>
                                                            <option value="2">{t('sessionClosure.sessionclosure.conciliated')}</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            {/* <Form.Group as={Col} md="2" className="d-flex flex-column justify-content-end">
                                                <div className="d-flex justify-content-md-start">
                                                    <Button className="px-3 text-uppercase" onClick={handleFilterClick}>
                                                        <FontAwesomeIcon
                                                            icon={["fal", "search"]}
                                                            size="1x"
                                                            className="mr-2"
                                                        />
                                                        {t('sessionClosure.sessionclosure.find')}
                                                    </Button>
                                                </div>
                                            </Form.Group> */}
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleFilterClick}>{t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div>
    )
}

export default FilterSessionClosure;