import React from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { Controller } from "react-hook-form";


export interface propsField {
  isEdit?: any; infoEdit?: any; control: any; setValue: any;
}

const AddOrEditResourceType: React.FC<propsField> = ({
  isEdit = false, infoEdit, control, setValue
}) => {

  console.log(infoEdit)

  return (
    <>
      <Card style={{ padding: "16px 20px" }}>
        <Card.Body className="px-3">
          <Row>
            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label className="mr-2">Descrição:</Form.Label>
                <Controller
                  control={control}
                  name="resourceTypeName"
                  defaultValue={isEdit === true ? infoEdit.resourceTypeName : ""}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom">
                      <Form.Control
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      />
                    </div>
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label>Veículo:</Form.Label>
                <Controller
                  control={control}
                  name="vehicle"
                  defaultValue={isEdit === true ? infoEdit.vehicle : 0}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom endpoint">
                      <Form.Select
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      >
                        <option value="0"></option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </Form.Select>

                    </div>
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label>Motorista:</Form.Label>
                <Controller
                  control={control}
                  name="driver"
                  defaultValue={isEdit === true ? infoEdit.driver : 0}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom endpoint">
                      <Form.Select
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      >
                        <option value="0"></option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </Form.Select>

                    </div>
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label>Guia:</Form.Label>
                <Controller
                  control={control}
                  name="guide"
                  defaultValue={isEdit === true ? infoEdit.guide : 0}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom endpoint">
                      <Form.Select
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      >
                        <option value="0"></option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </Form.Select>

                    </div>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-3">
            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label>Ticket:</Form.Label>
                <Controller
                  control={control}
                  name="ticket"
                  defaultValue={isEdit === true ? infoEdit.ticket : 0}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom endpoint">
                      <Form.Select
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      >
                        <option value="0"></option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </Form.Select>

                    </div>
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label>Restaurante:</Form.Label>
                <Controller
                  control={control}
                  name="restaurant"
                  defaultValue={isEdit === true ? infoEdit.restaurant : 0}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom endpoint">
                      <Form.Select
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      >
                        <option value="0"></option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </Form.Select>

                    </div>
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label>É uma pessoa?</Form.Label>
                <Controller
                  control={control}
                  name="people"
                  defaultValue={isEdit === true ? infoEdit.people : 0}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom endpoint">
                      <Form.Select
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      >
                        <option value="0"></option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </Form.Select>

                    </div>
                  )}
                />
              </Form.Group>
            </Col>

            <Col lg={3} md={3}>
              <Form.Group>
                <Form.Label>Cobra CHD?</Form.Label>
                <Controller
                  control={control}
                  name="chd"
                  defaultValue={isEdit === true ? infoEdit.chd : 0}
                  // rules={{ required: { value: true, message: "required" }, }}
                  render={({ field }: any) => (
                    <div className="input-text-custom endpoint">
                      <Form.Select
                        {...field}
                        type="text"
                        variant="standard"
                        margin="normal"
                        autoComplete="off"
                      >
                        <option value="0"></option>
                        <option value="1">Sim</option>
                        <option value="2">Não</option>
                      </Form.Select>

                    </div>
                  )}
                />
              </Form.Group>
            </Col>
          </Row>

          <Row className="mt-5">
            <div className="d-flex justify-content-end">
              <button className="btn btn-default btn-primary px-4 bg-primary" type="submit"> Salvar</button>
            </div>
          </Row>
        </Card.Body>
      </Card>

    </>
  )
}

export default AddOrEditResourceType;