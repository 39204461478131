import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import SuppliersTariffForm from '../components/SuppliersTariffForm/SuppliersTariffForm';
//import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function EditSuppliersTariff() {
    //const { t } = useTranslation();
    const [editAccounts,setEditAccounts] = useState(null);

    function formatValue(cell: any) {
        //console.log(Math.sign(cell))
        if(cell && cell !== '') {
          return cell.toFixed(2).replace(".", ",");
        } else {
          return '0,00';
        }
    }

    useEffect(() => {
        const idSup = window.location.href.split('id=')[1];

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditAccounts = async () => {
            try {
                const { data } = await api.get(`SupplierTariff/GetSupplierTariffAsync/${idSup}`, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    const tariff = data.data;

                    for(let i = 0; i < tariff.blocks.length; i++) {
                        const block = tariff.blocks[i];

                        if(block.valor) {
                            block.valor = formatValue(block.valor);
                        }

                        for(let j = 0; j < block.items.length; j++) {
                            block.items[j].valor = formatValue(block.items[j].valor);
                        }
                    }

                    setEditAccounts(tariff);
                }   
            }catch(error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';              
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-accounts';              
                }
            }
        }
        EditAccounts();
    }, [])

    if(editAccounts !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={'Fornecedores / Tarifario '} />

                        <div className="register-users mt-4">
                            <h2 className="title">Tarifario Fornecedor</h2>
                        </div>

                        <SuppliersTariffForm action={2} info={editAccounts} />

                    </div>

                </DesktopDefault>
            </>

        );
    } else {
        return (
            <></>
        )
    }
}

export default EditSuppliersTariff;