/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import InputMask from "react-input-mask";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

import GetAgents from '../../C2Points/GetAgents';
import GetUsersFromPDV from '../../C2Points/GetUsersFromPDV';
import GetCountrysCheckout from '../../C2Points/GetCountrysCheckout';
import DropdownStatesAndProvinces from '../../DropdownStatesAndProvinces/DropdownStatesAndProvinces';
//import GetDDI from '../../C2Points/GetDDI';

export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    watch?: any,
    setEmail?: any;
    isQuotation?: any;
    isForeign: boolean;
    setIsForeign: any;
    t?: any
};

const BookingInformation: React.FC<propForm> = ({
    control, errors, setValue, watch, setEmail, isQuotation = false, setIsForeign, isForeign, t
}: propForm) => {
    const c2tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");
    const level = atob(JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel)

    const [state, setState] = useState<any>({
        value: '',
        mask: '(99)99999-9999',
    });

    const addAgents = (objAgents: any) => {
        setValue(`companyName`,objAgents.compName);
        setValue(`agent`, objAgents.supplierIdentity);
    };

    useEffect(() => {
        if (c2tourAuth.user.perfil.isAgent === 1) {
            setValue(`agent`, null);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addCountrys = (objCountrys: any) => {        
        setValue(`country`, objCountrys.id);
    };

    const addSeller = (objUser: any) => {        
        setValue(`seller`, objUser.id);
    };
    const watchAgent = watch('agent');
    const watchCompanyName = watch('companyName');
   

    const handleMaskChange = (event: any, onChange: any) => {
        onChange(event);
        var value = event.target.value;
        var newState = {
            mask: '(99)99999-9999',
            value: value
        };

        setState(newState);
    }

    const handleMaskBlur = (event: any, onChange: any) => {
        onChange(event);
        var value = event.target.value;

        var newState: any;

        if (event.target.value.split('_').join('').split('(').join('').split(')').join('').split('-').join('').length === 11) {
            newState = {
                mask: '(99)99999-9999',
                value: value
            };
        } else {
            newState = {
                mask: '(99)9999-9999',
                value: value
            };
        }

        setState(newState);
    }

    const handleMaskFocus = (event: any, onChange: any) => {
        onChange(event);
        var value = event.target.value;

        var newState: any;

        newState = {
            mask: '(99)99999-9999',
            value: value
        };

        setState(newState);
    }

    return (
        <>
            <div className="card-title text-primary">
                <h5>{t("checkout.bookingInfo.subtitle")}</h5>
            </div>
            <Card className="card-unit bg-white mt-2">
                <Card.Body>

                    {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
                    <Row>
                        <Form.Group as={Col} md="4" controlId="formCheckoutName" className='mb-3'>
                            <Form.Label>{t("checkout.bookingInfo.name")}</Form.Label>
                            <Controller
                                control={control}
                                name="name"
                                rules={{ required: { value: true, message: t("checkout.bookingInfo.nameError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.compName ? "true" : ""}
                                        label="Nome"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="name"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutLastName" className='mb-3'>
                            <Form.Label>{t("checkout.bookingInfo.lastname")}</Form.Label>
                            <Controller
                                control={control}
                                name="lastName"
                                rules={{ required: { value: true, message: t("checkout.bookingInfo.lastnameError") } }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.compFantasia ? "true" : ""}
                                        label="Sobrenome"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="lastName"
                                render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutEmail" className='mb-3'>
                            <Form.Label>{t("checkout.bookingInfo.email")}</Form.Label>
                            <Controller
                                control={control}
                                name="email"
                                rules={{
                                    required: {
                                        value: true,
                                        message: t("checkout.bookingInfo.emailError"),
                                    },
                                    pattern: {
                                        value: /\S+@\S+.\S+/,
                                        message: t("checkout.bookingInfo.validEmail"),
                                    },
                                }}
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        type="email"
                                        aria-invalid={errors?.website ? "true" : ""}
                                        label="Email"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="email"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>

                        <Form.Group as={Col} md="4" controlId="formCheckoutPhone" className='mb-3'>
                            <Form.Label>{t("checkout.bookingInfo.telephone")}</Form.Label>
                            <Row>
                                <Col md="12">
                                    <Controller
                                        control={control}
                                        name="phone"
                                        rules={{
                                            required: {
                                                value: true,
                                                message: t("checkout.bookingInfo.telephoneError")
                                            },
                                            pattern: {
                                                value: /^[+]*[0-9]{2,3}[-\s]{0,1}[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]{8,15}$/g,
                                                message: t("checkout.bookingInfo.validTelephone"),
                                            },
                                        }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.compName ? "true" : ""}
                                                label="Telefone"
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                required
                                                placeholder='+55 99 999999999'
                                            />
                                        )}
                                    />

                                    <ErrorMessage
                                        errors={errors}
                                        name="phone"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Col>
                            </Row>
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutLanguage" className='mb-3'>
                            <Form.Label>{t("checkout.bookingInfo.language")}</Form.Label>
                            <Controller
                                control={control}
                                name="language"
                                rules={{ required: { value: true, message: t("checkout.bookingInfo.languageError") } }}
                                render={({ field }: any) => (
                                    <Form.Select
                                        {...field}
                                        as="select"
                                        variant="standard"
                                        margin="normal"
                                        style={{ border: "1px solid #ced4da" }}
                                        required
                                    >
                                        <option value='' selected disabled>{t("checkout.bookingInfo.languageSelect.select")}</option>
                                        <option value='1'>{t("checkout.bookingInfo.languageSelect.portuguese")}</option>
                                        <option value='2'>{t("checkout.bookingInfo.languageSelect.english")}</option>
                                        <option value='3'>{t("checkout.bookingInfo.languageSelect.spanish")}</option>

                                    </Form.Select>
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="language"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="1" controlId="">
                            <div className="d-flex align-items-center pt-3 px-3 h-100">
                                <Controller
                                    control={control}
                                    name="foreign"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            defaultChecked={isForeign === false}
                                            onClick={() => { setIsForeign(false) }}
                                            type="radio"
                                            variant="standard"
                                            margin="normal"
                                            className="mx-2"
                                            required
                                        />
                                    )}
                                />
                                <Form.Label className="mb-0">
                                    {t("checkout.bookingInfo.brazilian")}
                                </Form.Label>
                            </div>
                        </Form.Group>

                        <Form.Group as={Col} md="1" controlId="">
                            <div className="d-flex align-items-center pt-3 px-5 h-100">
                                <Controller
                                    control={control}
                                    name="foreign"
                                    render={({ field }: any) => (
                                        <Form.Check
                                            {...field}
                                            defaultChecked={isForeign === true}
                                            onClick={() => { setIsForeign(true) }}
                                            type="radio"
                                            variant="standard"
                                            margin="normal"
                                            className="mx-2"
                                            required
                                        />
                                    )}
                                />
                                <Form.Label className="mb-0">
                                    {t("checkout.bookingInfo.foreigner")}
                                </Form.Label>
                            </div>
                        </Form.Group>
                    </Row>
                    <Row>
                        <Form.Group as={Col} md="4" controlId="formCheckoutLastName" className='mb-3'>
                            {
                                isForeign === false
                                    ?
                                    <>
                                        <Form.Label>{t("checkout.bookingInfo.cpf")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="cpf"
                                            rules={{ required: { value: true, message: t("checkout.bookingInfo.cpfError") } }}
                                            render={({ field }: any) => (
                                                <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange}>
                                                    {(inputProps: any) => (
                                                        <Form.Control
                                                            {...inputProps}
                                                            aria-invalid={errors?.cpfResp ? "true" : ""}
                                                            label={'CPF'}
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                </InputMask>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="cpf"
                                            render={({ message }) => (
                                                <small style={{ color: "red" }}>{message}</small>
                                            )}
                                        />
                                    </>
                                    :
                                    <>
                                        <Form.Label>{t("checkout.bookingInfo.id")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="id"
                                            rules={{ required: { value: true, message: t("checkout.bookingInfo.idError") } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    aria-invalid={errors?.id ? "true" : ""}
                                                    label="id"
                                                    variant="standard"
                                                    margin="normal"
                                                    autoComplete="off"
                                                    required
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="id"
                                            render={({ message }) => (
                                                <small style={{ color: "red" }}>{message}</small>
                                            )}
                                        />
                                    </>
                            }
                        </Form.Group>
                        {
                            isForeign === true &&
                            <Form.Group as={Col} md="4" controlId="formCheckoutCountey" className='mb-3'>

                                <Form.Label>{t("checkout.bookingInfo.country")}</Form.Label>
                                <Controller
                                    control={control}
                                    name="country"
                                    rules={{ required: { value: true, message: t("checkout.bookingInfo.countryError") } }}
                                    render={({ field }: any) => (
                                        <GetCountrysCheckout
                                            propsField={field}
                                            propsLabel="Test"
                                            propsErrors={errors}
                                            actionCountrys={addCountrys}
                                        />
                                    )}
                                />
                                <ErrorMessage
                                    errors={errors}
                                    name="country"
                                    render={({ message }) => (
                                        <small style={{ color: "red" }}>{message}</small>
                                    )}
                                />
                            </Form.Group>
                        }
                    </Row>

                    <Row >
                        <Controller
                            control={control}
                            name="isMain"
                            rules={{ required: { value: false, message: '' } }}
                            render={({ field }: any) => (
                                <label htmlFor={"isMainContact"} className="d-flex">
                                    <Form.Check
                                        {...field}
                                        type="checkbox"
                                        id="isMainContact"
                                        value=' '
                                        required
                                    />
                                    <small className="mx-2">{t("checkout.bookingInfo.mainContact")}</small>
                                </label>
                            )}
                        />
                    </Row>
                    <Row>
                        <Controller
                            control={control}
                            name="keepInformed"
                            /* rules={{ required: { value: true, message: '' } }} */
                            render={({ field }: any) => (
                                <label htmlFor={"keepInformed"} className="d-flex">
                                    <Form.Check
                                        {...field}
                                        type="checkbox"
                                        id="keepInformed"
                                        value=' '
                                        required
                                    />
                                    <small className="mx-2">{t("checkout.bookingInfo.keepMeInformed")}</small>
                                </label>
                            )}
                        />
                    </Row>

                    {
                        c2tourAuth.user.perfil.isAgent !== 1 && level !== '29' ?
                        <>
                            <hr />

                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="formCheckoutAgent">
                                    <Form.Label>{t("checkout.bookingInfo.agent")}</Form.Label>
                                    <GetAgents actionAgents={addAgents} />
                                    <Controller
                                        rules={{ required: { value: true, message: t("checkout.bookingInfo.agentError") } }}
                                        control={control}
                                        name="agent"
                                        render={({ field }) => {
                                            // sending integer instead of string.
                                            return <input {...field} type="hidden" autoComplete='off' />;
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="agent"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                
                                <Form.Group as={Col} md="4" controlId="formCheckoutReference">
                                    <Form.Label>{t("checkout.bookingInfo.reference")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="reference"
                                        rules={{ required: { value: true, message: t("checkout.bookingInfo.referenceError") } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                aria-invalid={errors?.compFantasia ? "true" : ""}
                                                label="Referência"
                                                variant="standard"
                                                margin="normal"
                                                autoComplete="off"
                                                required
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="reference"
                                        render={({ message }) => (
                                            <small style={{ color: "red" }}>{message}</small>
                                        )}
                                    />
                                </Form.Group>
                                
                                {watchAgent !== undefined && (watchCompanyName.includes("pdv") || watchCompanyName.includes("PDV")) ?
                                <Form.Group as={Col} md="4" controlId="formCheckoutSeller">
                                    <Form.Label>{t("checkout.bookingInfo.seller")}</Form.Label>                                    
                                    <Controller
                                        control={control}
                                        name="seller"
                                        // rules={{ required: { value: true, message: t("checkout.bookingInfo.sellerError") } }}
                                        render={({ field }: any) => (
                                            <GetUsersFromPDV 
                                            propsField={field}
                                            propsLabel="Seller"
                                            propsErrors={errors}
                                            actionSeller={addSeller}
                                            agentCode={watchAgent}
                                            />
                                        )}
                                    />
                                    {/* <ErrorMessage
                                        errors={errors}
                                        name="seller"
                                        render={({ message }) => (
                                            <small style={{ color: "red" }}>{message}</small>
                                        )}
                                    /> */}
                                </Form.Group>
                                :
                                <></>}

                                {isQuotation === true
                                    ?
                                    <Form.Group as={Col} md="4" controlId="formCheckoutLanguage" className='mb-3'>
                                        <Form.Label>{t("checkout.bookingInfo.expiration")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="expiration"
                                            rules={{ required: { value: true, message: t("checkout.bookingInfo.expirationError") } }}
                                            render={({ field }: any) => (
                                                <Form.Select
                                                    {...field}
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    style={{ border: "1px solid #ced4da" }}
                                                    required
                                                >
                                                    <option value='' selected disabled>{t("checkout.bookingInfo.expirationSelect.select")}</option>
                                                    <option value='1'>{t("checkout.bookingInfo.expirationSelect.oneHour")}</option>
                                                    <option value='2'>{t("checkout.bookingInfo.expirationSelect.twoHours")}</option>
                                                    <option value='4'>{t("checkout.bookingInfo.expirationSelect.fourHours")}</option>
                                                    <option value='6'>{t("checkout.bookingInfo.expirationSelect.sixHours")}</option>
                                                    <option value='8'>{t("checkout.bookingInfo.expirationSelect.eightHours")}</option>
                                                    <option value='12'>{t("checkout.bookingInfo.expirationSelect.twelveHours")}</option>

                                                </Form.Select>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="expiration"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                    :
                                    <></>
                                }

                            </Row>

                        </>
                        :
                        ""
                    }
                </Card.Body>
            </Card>
        </>
    );
}

export default BookingInformation;