/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import api from "../../../services/api";

import { Card, Modal, Row } from "react-bootstrap";
import BootstrapTable, { SelectRowProps } from "react-bootstrap-table-next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Breadcrumb from "../../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../../templates/DesktopDefault";
//import ModalAssociateCategorie from "./components/ModalAssociateCategorie/ModalAssociateCategorie";
import ModalAssociateAffiliates from "../AssociateCategories/components/ModalAssociateAffiliates/ModalAssociateAffiliates";

import '../AssociateCategories/AssociateCategories.scss';
import RequestResponseModal from "../../../components/Modal/RequestResponseModal/RequestResponseModal";

function EditAssociation() {
  const [categorie, setCategorie] = useState<any>([]);
  const [affiliates, setAffiliates] = useState<any>([]);
  const [modalCategorieShow, setModalCategorieShow] = useState<any>(false);
  const [modalAffiliatesShow, setModalAffiliatesShow] = useState<any>(false);

  const [selectedCategory, setSelectedCategory] = useState<any>(null);

  const [modalResponse, setModalResponse] = useState<any>(null);
  const [modalText, setModalText] = useState<any>('Loading');
  const [log, setLog] = useState<any>();

  const rowClasses = (row: any, rowIndex: any) => {
    if (row.resellerStatusString === "Em Análise" || row.categorieStatus === "Em Análise" || row.statusDesc === "Em Análise") {
      return 'border-analise';
    } else if (row.resellerStatusString === "Ativo" || row.categorieStatus === "Ativo" || row.statusDesc === "Ativo") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Em Análise" || row.statusDesc === "Em Análise" 
            ? "bg-analise"
            : cell === "Ativo" || row.statusDesc === "Ativo"
              ? "bg-ativo"
              : cell === "Inativo" || row.statusDesc === "Inativo"
                ? "bg-inativo"
                : ""
        }
      >
        {cell || row.statusDesc}
      </div>
    );
  }

  const addName = (cell: any, row: any) => {
    return cell || row.nomeFantasia;
  }

  const addCode = (cell: any, row: any) => {
    return cell || row.identity;
  }

  const columnsCategorie = [
    { dataField: "categorieName", text: "Nome da Categoria" },
    { dataField: "categorieCode", text: "Código da Categoria" },
    { dataField: "categorieStatus", text: "Status da Categoria", formatter: addStatus },
  ];

  const columnsAffiliates = [
    { dataField: "resellerName", text: "Nome do Afiliado", formatter: addName },
    { dataField: "resellerCode", text: "Código do Afiliado", formatter: addCode },
    { dataField: "resellerStatusString", text: "Status", formatter: addStatus },
  ];

  useEffect(() => {
    const id: string = window.location.href.split('?id=')[1];

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    async function getCategorie() {
      try {
        const { data } = await api.get(
          `/Categories/GetCategoriesByIdAsync/${id}`,
          config
        );
        if (data.statusCode !== 400) {
          if (data.data.log === 0) {
            setSelectedCategory(data.data.data);
            setCategorie([data.data.data]);
            setAffiliates(data.data.data.resellerList);
          }
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
        }
      }
    }

    getCategorie();
  }, [])

  const associateCategory = () => {
    setModalResponse('loading');
    setModalText("Loading");
    let resellers: any = [];

    console.log(affiliates)

    affiliates.forEach((elem: any) => {
      resellers.push({
        resellerCode: elem.resellerCode || elem.identity,
      })
    });

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };

    async function enableCategory() {
      try {
        const { data } = await api.post(
          "Categories/UpdateCategoriesResellersAsync",
          {
            "categorieId": selectedCategory.categorieId,
            "resellerList": resellers
          },
          config
        );

        if (data.status !== 400) {
          setModalResponse('success');
          setLog(data.data.log);
          setModalText(data.data.texto);
        } else {
          setModalResponse('error');
          setLog(1);
          setModalText("Erro ao processar operação");
        }
      } catch (error: any) {
        setModalResponse('error');
        setLog(1);
        setModalText("Erro ao processar operação");
      }
    }

    if (affiliates.length > 0) {
      enableCategory();
    } else {
      setModalResponse('error');
      setModalText('Favor selecionar ao menos uma categoria e um revendedor.');
    }
  }

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard associate-category">
          <Breadcrumb title={"Associar Categoria"} />

          <div className="mt-4 ">
            <div className="d-flex align-items-center">
              <span
                className="back"
                onClick={() => {
                  window.location.href = "/listar-associar-categorias";
                }}
              >
                <FontAwesomeIcon
                  icon={["fal", "angle-double-left"]}
                  style={{ margin: "0 5px 0 0", fontSize: "16px" }}
                />
                Voltar
              </span>
            </div>
            <h2 className="title m-0" style={{ color: "#707070" }}>
              Editar Categorias
            </h2>
          </div>

          <Card className="card-unit bg-white my-4">
            <Card.Body>
              <div className="d-flex head">
                <h4 className="">Categoria</h4>
                {/* <button onClick={() => { setModalCategorieShow(true); }}>Associar categoria</button> */}
              </div>
              <hr />
              <div className="table-default father m-0">
                <div className="table-container p-0">
                  {categorie?.length !== 0
                    ?
                    <BootstrapTable
                      bootstrap4
                      keyField="productCode"
                      data={categorie}
                      hover={true}
                      columns={columnsCategorie}
                      striped={true}
                      rowClasses={rowClasses}
                    />
                    :
                    <>
                      <div className="d-flex justify-content-center">
                        <span>Associe uma categoria</span>
                      </div>
                    </>
                  }
                </div>
              </div>

            </Card.Body>


            {/* <Modal
              className={"modal-large modal-custom modalAuth"}
              show={modalCategorieShow}
              onHide={() => {
                setModalCategorieShow(false);
              }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <Modal.Header closeButton>
                <div className="d-flex justify-content-between w-100 mb-4">
                  <h3>Associar Categoria</h3>
                </div>
              </Modal.Header>

              <ModalAssociateCategorie
                setCategorie={setCategorie}
                setModalCategorieShow={setModalCategorieShow}
                categorie={categorie}
              />

            </Modal> */}

          </Card>

          {categorie?.length !== 0
            ?
            <Card className="card-unit bg-white my-4">
              <Card.Body>
                <div className="d-flex head">
                  <h4 className="">Revendedores</h4>
                  <button onClick={() => { setModalAffiliatesShow(true); }}>Associar Revendedores</button>
                </div>
                <hr />
                <div className="table-default father m-0">
                  <div className="table-container p-0">
                    {affiliates?.length !== 0
                      ?
                      <BootstrapTable
                        bootstrap4
                        keyField="identity"
                        data={affiliates}
                        hover={true}
                        columns={columnsAffiliates}
                        striped={true}
                        rowClasses={rowClasses}
                      />
                      :
                      <>
                        <div className="d-flex justify-content-center">
                          <span>Associe Revendedores</span>
                        </div>
                      </>
                    }
                  </div>
                </div>

              </Card.Body>


              <Modal
                className={"modal-large modal-custom modalAuth"}
                show={modalAffiliatesShow}
                onHide={() => {
                  setModalAffiliatesShow(false);
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <Modal.Header closeButton>
                  <div className="d-flex justify-content-between w-100 mb-4">
                    <h3>Associar Revendedores</h3>
                  </div>
                </Modal.Header>

                <ModalAssociateAffiliates
                  setAffiliates={setAffiliates}
                  setModalAffiliatesShow={setModalAffiliatesShow}
                  affiliates={affiliates}
                />

              </Modal>

            </Card>
            :
            <></>
          }

          <Row className="d-flex justify-content-end mx-0 mt-5">

            <div className="data" style={{ width: 'unset' }}>
              <div className="btn-default mr-2 text-uppercase btn btn-primary" onClick={() => { associateCategory() }}>Associar</div>
            </div>


          </Row>


          <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
            <div className="btn-sucess">
              <Modal
                className="modal-custom modalAuth"
                show={modalResponse !== null}
                onHide={() => setModalResponse(null)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Modal.Header></Modal.Header>
                  <RequestResponseModal name={"Categoria"} modalResponse={modalResponse} setModalResponse={setModalResponse} modalText={modalText} log={log} />
                </>
              </Modal>
            </div>
          </Row>

        </div>
      </DesktopDefault>
    </>
  );
}

export default EditAssociation;
