import React, { useEffect, useState } from 'react';

import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import GroupFileForm from '../components/GroupFileForm/GroupFileForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';
import api from '../../../services/api';


function CopyGroupFile() {
    const { t } = useTranslation();
    const [editFile,setEditFile] = useState(null);

    useEffect(() => {
        const idSup = window.location.href.split('id=')[1];

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditAccounts = async () => {
            try {
                const { data } = await api.get(`GroupFile/GetGroupFile/${idSup}`, config);
                /* console.log(data.data); */

                if (data.status !== 400) {
                    const fileData = data.data;

                    fileData.id = -1;
                    fileData.fileNo = '';

                    for(let i = 0; i < fileData.passengers.length; i++) {
                        fileData.passengers[i].id = -1;
                    }

                    for(let i = 0; i < fileData.services.length; i++) {
                        fileData.services[i].id = -1;
                    }

                    setEditFile(fileData);
                } 
            }catch(error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';              
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-groups-customized';              
                }
            }
        }
        EditAccounts();
    }, [])

    if(editFile !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={t("groupsAndCustomized.add.path")} />

                        <div className="register-users mt-4">
                            <h2 className="title">{t("groupsAndCustomized.add.title")}</h2>
                        </div>

                        <GroupFileForm action={1} info={editFile} />

                    </div>

                </DesktopDefault>
            </>
        );
    } else {
        return (
            <></>
        )
    }    
}

export default CopyGroupFile;