/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './ExportERPSpreadsheet.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterExportERPSpreadsheet from './components/FilterExportERPSpreadsheet/FilterExportERPSpreadsheet';
import ModalShow from '../../components/Modal/ModalShow/ModalShow';

function ExportERPSpreadsheet() {
    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>("Carregando");
    const [modalLog, setModalLog] = useState<any>(null);

    const buscar = (date: any) => {
        setModalShow(true)

        let dataInicio: string = date[0].split('/').reverse().join('-');
        let dataFim: string = date[date.length - 1].split('/').reverse().join('-');

        const exportFunction = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config: any = {
                headers: {
                    'Authorization': `Bearer ${token}`,
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                    //"Access-Control-Allow-Origin": "*"
                }/* ,
                responseType: 'blob' */
            };
            try {
                const exp = await api.post('Manifest/ExportarPlanilhasRM',
                    {
                        dataInicio: dataInicio,
                        dataFim: dataFim
                    }, config
                );
                if (exp.status !== 400) {
                    
                    /* const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                    const objectUrl = window.URL.createObjectURL(blob); */

                    const link = document.createElement('a');
                    link.href = `data:${exp.data.fileType};base64, ${exp.data.file}`;
                    link.setAttribute(
                        'download',
                        'planilha_RM.xlsx',
                    );

                    // Append to html link element page
                    document.body.appendChild(link);

                    // Start download
                    link.click();

                    // // Clean up and remove the link
                    link?.parentNode?.removeChild(link);

                    setModalLog(0);
                    setModalMessage("Planilha exportada com sucesso.");
                } else {
                    setModalLog(1);
                    setModalMessage("Erro ao processar operação");
                }
            } catch (error: any) {
                setModalLog(1);
                setModalMessage("Erro ao processar operação");
                console.log(error)
            }
        }
        exportFunction();
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard export-erp-suppliers">
                    <Breadcrumb title={'Financeiro / Exportar Planilhas RM'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Exportar Planilhas RM</h2>
                    </div>
                    <FilterExportERPSpreadsheet
                        buscar={buscar}
                    />
                </div>
                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                />
            </DesktopDefault>
        </>
    );
}

export default ExportERPSpreadsheet;