import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { Key, useEffect, useState } from "react";
import { Row, Col, Modal, Card } from "react-bootstrap";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import ProductTour from "./ProductTour";
import { useAppSelector, useAppDispatch } from "../../store/hooks";
import { listProduct } from "../../store/product";
import { parseCookies, setCookie, destroyCookie } from 'nookies'
import axios from "axios";

function EditTour() {

    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
    /* Controle Redux  */

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");
    const [loading, setLoading] = useState<boolean>(true);

    const [tourObject, setTourObject] = useState<any>({ isTranslate: 1 }); //objetão
    const [/* editTourObject */, setEditTourObject] = useState<any>(null);

    //process.env.NODE_ENV === "development" -> ambiente de desenvolvimento
    const [management, setManagement] = useState(1);
    const [alreadyPassed, setAlreadyPassed] = useState(1); //11

    const productCode = window.location.href.split('productCode=')[1];

    /* const getIntegration = async (obj: any) => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.get(`Dropdown/GetReservationSystemsBySupplierId/${obj.supplierId}`, config);
            if (data.statusCode !== 400) {
                if (data.data.length > 0) {
                    return data.data[0].id;
                }
            }
        } catch (error: any) {
            console.log(error)
            return '0';
        }
        return '0';
    } */

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const TranslateText = async (name: string, lang: string) => {
        if (name !== undefined) {
            try {
                const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                    "q": name,
                    "source": "pt-BR",
                    "target": lang,
                    "format": "text"
                });
                if (res.status !== 400) {
                    return res?.data?.data?.translations[0]?.translatedText;
                }
            } catch (error: any) { }
        }
    };

    const transformObject = async (obj: any) => {
        let arrayImages: any = []

        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        let imagensParaConverter = obj.product_images.map((image: any, index: Key) => {
            return urlToBase64(`https://c2.tours/images-products/${image.name}`, function (base64: any) {
                arrayImages.push(base64)
            })

        });

        /* let modalidade: any = obj.modalidade;
 
         let integrationAccessType: any = '2'; // sem integração de acesso
         let integrationPurchaseType: any = '0';
         let supplierIdentity: string | null = null;
 
         modalidade.forEach((elem: any) => {
             if (elem.referenceCode[0].text === null) {
                 delete elem.referenceCode;
             }
             if (elem.haveAccessIntegration === 1) {
                 integrationAccessType = '1';
             }
             if (elem.havePurchaseIntegration === 1) {
                 if (elem.productPurchaseIntegration[0].idPurchaseIntegration === 2) {
                     //integrationPurchaseType = '9';
                     supplierIdentity = elem.productPurchaseIntegration[0].novaXs[0].supplierIdentity;
                 } else if (elem.productPurchaseIntegration[0].idPurchaseIntegration === 3) {
                     //integrationPurchaseType = '8';
                 }
             }
         });
 
         integrationPurchaseType = await getIntegration(obj);*/

        let newProduct = {
            "productCode": productCode,

            //Dados do tour 
            "productName": [
                {
                    "text": obj.product_name_BR,
                    "lang": 1
                },
                {
                    "text": obj.product_name_EN,
                    "lang": 2
                },
                {
                    "text": obj.product_name_ES,
                    "lang": 3
                }
            ],
            "minNumPeople": obj.min_num_people,
            "maxNumPeople": obj.max_num_people,
            "sellingType": obj.id_selling_type,
            "productSubCategory": obj.subCat.map((item: any) => {
                return {
                    "productCategoryId": item.id_subcat
                };
            }),

            //Operação
            "operationType": obj.operation_type,
            "supplierId": obj.id_terceiro,
            /*"site": obj.site === 1 ? true : false,
            "app": obj.app === 1 ? true : false,
            "b2b": obj.b2b === 1 ? true : false,
            "marketplaces": obj.b2b === 1 ? true : false,*/

            //Tipo de Operação Própria
            "resourcesId": obj.our_resourcestype.map((item: any) => {
                return item.resources_id;
            }),
            "ourVehicleType": obj.our_vehicletype.map((item: any) => {
                return item.resources_id;
            }),
            "sessionGroupId": obj.group_id,


            //Resumo e detalhes
            "summary": [
                {
                    "text": obj.product_info_BR,
                    "lang": 1
                },
                {
                    "text": obj.product_info_EN,
                    "lang": 2
                },
                {
                    "text": obj.product_info_ES,
                    "lang": 3
                }
            ],
            "details": obj.details.map((item: any) => {
                return [
                    {
                        "text": (item.details_BR.length > 9 ? item.details_BR : item.details_BR + 'aaaaaaaaaa'),
                        "lang": 1
                    },
                    {
                        "text": (item.details_EN.length > 9 ? item.details_EN : item.details_EN + 'aaaaaaaaaa'),
                        "lang": 2
                    },
                    {
                        "text": (item.details_ES.length > 9 ? item.details_ES : item.details_ES + 'aaaaaaaaaa'),
                        "lang": 3
                    }
                ];
            }),

            //Descrição e destaques
            "description": [
                {
                    "text": obj.product_description_BR,
                    "lang": 1
                },
                {
                    "text": obj.product_description_EN,
                    "lang": 2
                },
                {
                    "text": obj.product_description_ES,
                    "lang": 3
                }
            ],
            "highLight": obj.highLight.map((item: any) => {
                return [
                    {
                        "text": item.highlights_BR.slice(0, 99),
                        "lang": 1
                    },
                    {
                        "text": item.highlights_EN.slice(0, 99),
                        "lang": 2
                    },
                    {
                        "text": item.highlights_ES.slice(0, 99),
                        "lang": 3
                    }
                ];
            }),

            //Fotos
            "productUrl": "https://storage.googleapis.com/cdn.hmo.c2rio.travel/images-tour",
            "productImages": [],

            "modalidade": obj.modalidade.map((item: any) => {
                return (
                    {
                        "modalityName": [
                            {
                                "text": item.modality_BR,
                                "lang": 1
                            },
                            {
                                "text": item.modality_EN,
                                "lang": 2
                            },
                            {
                                "text": item.modality_ES,
                                "lang": 3
                            }
                        ],
                        "modalityDescription": [
                            {
                                "text": item.modality_description_BR,
                                "lang": 1
                            },
                            {
                                "text": item.modality_description_EN,
                                "lang": 2
                            },
                            {
                                "text": item.modality_description_ES,
                                "lang": 3
                            }
                        ],
                        "referenceCode": [
                            {
                              "text": item.reference_code_BR,
                              "lang": 1
                            },
                            {
                              "text": item.reference_code_EN,
                              "lang": 2
                            },
                            {
                              "text": item.reference_code_ES,
                              "lang": 3
                            }
                          ],
                        "daysOfWeek": {
                            "seg": item.seg === 1 ? true : false,
                            "ter": item.ter === 1 ? true : false,
                            "qua": item.qua === 1 ? true : false,
                            "qui": item.qui === 1 ? true : false,
                            "sex": item.sex === 1 ? true : false,
                            "sab": item.sab === 1 ? true : false,
                            "dom": item.dom === 1 ? true : false
                        },
                        "tariffAgeInfantMin": 0,
                        "tariffAgeInfantMax": 0,
                        "tariffAgeChildMin": 0,
                        "tariffAgeChildMax": 0,
                        "tariffAgeAdultMin": 0,
                        "tariffAgeAdultMax": 0,
                        "tariffAgeGlobalMin": 0,
                        "tariffAgeGlobalMax": 0,
                        "durationHours": item.duration_hours,
                        "durationMinutes": item.duration_minutes,
                        "activityStart": `0001-01-01T${item.activity_start}:00`,
                        "buyingAdvance": item.buying_advance,
                        "embarkingType": item.embarking_type,
                        "meetingPoint": item.embarking_type === 0 ? item.meeting_point : '',
                        "pickupDescription": item.embarking_type === 0 ? '' : item.pickups_list,
                        "pickupList": item.embarking_type === 0 ? '' : item.pickups_list,
                        "freeSale": item.free_sale === 1 ? true : false,
                        "havePurchaseIntegration": 2,
                        "haveAccessIntegration": 2,
                        "haveSigSigIntegration": 2,
                        "sigSigIntegration": {},
                        "productPurchaseIntegration": [],
                        "accessIntegration": [],
                        //"productModCode": item.prod_mod_code,
                        //"uniqueModCode": item.prod_mod_code_unique,
                        "sigIntegrationCode": item.integration_sig_code,
                        "idJs": 0,
                        "statusModalidade": item.status_modalidade,
                        "offerGuidingDetails": true,
                        "guidingDetails": (obj.guidingDetails?.length === 3 ? obj.guidingDetails.map((item: any) => {
                            return {
                                "lang": item.id_idioma,
                                "tourGuide": item.tour_guide === 1,
                                "audioGuide": item.audio_guide === 1,
                                "writingGuide": item.writing_guide === 1
                            };
                        }) : [1, 2, 3].map((item: any) => {
                            return {
                                "lang": item,
                                "tourGuide": false,
                                "audioGuide": false,
                                "writingGuide": false
                            }
                        })),
                        "refundRules": obj.refund_policy.map((item: any) => {
                            return {
                                "refundRules": item.refund_rules,
                                "daysBeforeCheckin": item.prior_days,
                                "refundPolicyType": item.policy,
                                "refundPercentage": item.percentage
                            };
                        }),
                        "itemsIncluded": item.itemsIncluded.filter((elem: any) => elem.lingua === 'BR').map((inc: any, index: number) => {
                            return [
                                {
                                    "text": item.itemsIncluded.filter((elem: any) => elem.lingua === 'BR')[index].atividade.slice(0, 99) || ' ',
                                    "lang": 1
                                },
                                {
                                    "text": '',
                                    "lang": 2
                                },
                                {
                                    "text": '',
                                    "lang": 3
                                }

                            ]
                        }),
                        // eslint-disable-next-line array-callback-return
                        /* "itemsIncluded": item.itemsIncluded.map((inc: any) => {
                            if (inc.lingua === 'BR') {
                                return [
                                    {
                                        "text": inc.atividade,
                                        "lang": 1
                                    },
                                    {
                                        "text": ' ',
                                        "lang": 2
                                    },
                                    {
                                        "text": ' ',
                                        "lang": 3
                                    }

                                ]
                            }
                        }), */

                        "itemsNotIncluded": item.itemsNotIncluded.filter((elem: any) => elem.lingua === 'BR').map((inc: any, index: number) => {
                            return [
                                {
                                    "text": item.itemsNotIncluded.filter((elem: any) => elem.lingua === 'BR')[index].atividade.slice(0, 99) || ' ',
                                    "lang": 1
                                },
                                {
                                    "text": '',
                                    "lang": 2
                                },
                                {
                                    "text": '',
                                    "lang": 3
                                }

                            ]
                        }),

                        // eslint-disable-next-line array-callback-return
                        /* "itemsNotIncluded": item.itemsNotIncluded.map((incNot: any) => {
                            if (incNot.lingua === 'BR') {
                                return [
                                    {
                                        "text": incNot.atividade,
                                        "lang": 1
                                    },
                                    {
                                        "text": ' ',
                                        "lang": 2
                                    },
                                    {
                                        "text": ' ',
                                        "lang": 3
                                    }

                                ]
                            }
                        }), */

                        "productModalityRange": [
                            {
                                "id": 0,
                                "rangeId": "Adult",
                                "rangeLabel": [
                                    {
                                        "text": "Adulto",
                                        "lang": 1
                                    },
                                    {
                                        "text": "Adult",
                                        "lang": 2
                                    },
                                    {
                                        "text": "Adulto",
                                        "lang": 3
                                    }
                                ],
                                "rangeLabelSub": [
                                    {
                                        "text": "Adulto",
                                        "lang": 1
                                    },
                                    {
                                        "text": "Adult",
                                        "lang": 2
                                    },
                                    {
                                        "text": "Adulto",
                                        "lang": 3
                                    }
                                ],
                                "rangeStatus": 1,
                                "rangeIncrement": 1,
                                "rangeOrder": 1,
                                "flagStockUse": 1
                            },
                            {
                                "id": 0,
                                "rangeId": "Child",
                                "rangeLabel": [
                                    {
                                        "text": "Criança",
                                        "lang": 1
                                    },
                                    {
                                        "text": "Child",
                                        "lang": 2
                                    },
                                    {
                                        "text": "Niño",
                                        "lang": 3
                                    }
                                ],
                                "rangeLabelSub": [
                                    {
                                        "text": "Criança",
                                        "lang": 1
                                    },
                                    {
                                        "text": "Child",
                                        "lang": 2
                                    },
                                    {
                                        "text": "Niño",
                                        "lang": 3
                                    }
                                ],
                                "rangeStatus": 1,
                                "rangeIncrement": 1,
                                "rangeOrder": 2,
                                "flagStockUse": 1
                            },
                            {
                                "id": 0,
                                "rangeId": "Infant",
                                "rangeLabel": [
                                    {
                                        "text": "Infante",
                                        "lang": 1
                                    },
                                    {
                                        "text": "Infant",
                                        "lang": 2
                                    },
                                    {
                                        "text": "Infante",
                                        "lang": 3
                                    }
                                ],
                                "rangeLabelSub": [
                                    {
                                        "text": "Infante",
                                        "lang": 1
                                    },
                                    {
                                        "text": "Infant",
                                        "lang": 2
                                    },
                                    {
                                        "text": "Infante",
                                        "lang": 3
                                    }
                                ],
                                "rangeStatus": 1,
                                "rangeIncrement": 1,
                                "rangeOrder": 3,
                                "flagStockUse": 2
                            }
                        ],
                        "tariff": [],
                        // eslint-disable-next-line array-callback-return
                        /*"tariff": obj.tarifario.map((tariff: any) => {
                            if (Number(tariff.id_js_front) === Number(item.id_js)) {
                                return (
                                    {
                                        "tariffName": (tariff.p_tarif_name.length > 4 ? tariff.p_tarif_name.slice(0, 49) : tariff.p_tarif_name + 'aaaaa'),
                                        "modalityBr": tariff.modality_BR,
                                        "tariffStart": `${tariff.tarif_start}T00:00:00`,
                                        "tariffEnd": `${tariff.tarif_end}T00:00:00`,
                                        "tariffAgeInfantMin": tariff.tarif_infant_min,
                                        "tariffAgeInfantMax": tariff.tarif_infant_max,
                                        "tariffAgeChildMin": tariff.tarif_age,
                                        "tariffAgeChildMax": tariff.tarif_age,
                                        "tariffAgeAdultMin": tariff.tarif_age,
                                        "tariffAgeAdultMax": tariff.tarif_age,
                                        "tariffAgeGlobalMin": tariff.tarif_age,
                                        "tariffAgeGlobalMax": tariff.tarif_age,
                                        "tariffAdultAgeText": "Entre 11 a 100 anos",
                                        "tariffChildAgeText": "Entre 5 a 10 anos",
                                        "tariffInfantAgeText": "Entre 0 a 4 anos",
                                        "valorUnicoOuPorDia": 0,
                                        //"tarCode": "TAR-EY304367",
                                        //"gradeCode": "GRD1234",
                                        "gradeId": 1,
                                        "observacao": null,
                                        "bloqueado": false,
                                        "statusTarifario": 0,
                                        "promoHourTariff": [],
                                        "activityStartTariff": [
                                            {
                                                "hora": "07:00",
                                                "productType": 1,
                                                "status": 1
                                            },
                                        ],
                                        "canaisTarifario": [
                                            {
                                                "idSalesChannel": 1,
                                                "productType": 1,
                                                "status": 1
                                            },
                                            {
                                                "idSalesChannel": 2,
                                                "productType": 1,
                                                "status": 1
                                            },
                                            {
                                                "idSalesChannel": 3,
                                                "productType": 1,
                                                "status": 1
                                            }
                                        ],
                                        "daysTariff": tariff.dias_tarifario.map((day: any) => {
                                            const dataReferencia = new Date('2023-12-14');
                                            const data = new Date(tariff.tarif_end);
                                            return (
                                                {
                                                    "daysOfWeek": {
                                                        "seg": day.seg === 1 ? true : false,
                                                        "ter": day.ter === 1 ? true : false,
                                                        "qua": day.qua === 1 ? true : false,
                                                        "qui": day.qui === 1 ? true : false,
                                                        "sex": day.sex === 1 ? true : false,
                                                        "sab": day.sab === 1 ? true : false,
                                                        "dom": day.dom === 1 ? true : false
                                                    },
                                                    "horariosTarifario": [
                                                        {
                                                            "hora": "07:00",
                                                            "percentTarifInfant": data > dataReferencia ? day.percent_tarif_infant : day.percent_tarif_adult < 1 ? 2 : day.percent_tarif_adult,
                                                            "percentTarifChild": data > dataReferencia ? day.percent_tarif_child : day.percent_tarif_adult < 1 ? 2 : day.percent_tarif_adult ,
                                                            "percentTarifAdult": data > dataReferencia ? day.percent_tarif_adult : day.percent_tarif_adult < 1 ? 2 : day.percent_tarif_adult ,
                                                            "percentTarifGlobal": day.percent_tarif_global,

                                                            "costTarifInfant": day.tarif_infant_price_net_net,
                                                            "sellingTarifInfant": day.tarif_infant_price_alterado,

                                                            "costTarifChild": day.tarif_child_price_net_net,
                                                            "sellingTarifChild": day.tarif_child_price_alterado,

                                                            "costTarifAdult": day.tarif_adult_price_net_net,
                                                            "sellingTarifAdult": day.tarif_adult_price_alterado,

                                                            "costTarifGlobal": day.tarif_global,
                                                            "sellingTarifGlobal": day.tarif_global_price_net_net,
                                                            "productType": 1,
                                                            "status": day.status,
                                                            "statusDesc": ""
                                                        },
                                                    ],
                                                    "status": day.status,
                                                    "statusDesc": "",
                                                }


                                            )

                                        }),
                                        "status": 1,
                                        "statusDesc": "Ativo"


                                    }

                                )
                            }

                        }),*/


                    }
                );
            }),

            "tourLocationLat": obj.meeting_point_lat,
            "tourLocationLong": obj.meeting_point_long,
            "eventLocation": obj.event_location,
            "travellerInfoIsNeed": obj.traveller_info_is_needed === 1 ? true : false,
            "voucherInstruction": [
                {
                    "text": obj.voucher_instructions_BR,
                    "lang": 1
                },
                {
                    "text": obj.voucher_instructions_EN,
                    "lang": 2
                },
                {
                    "text": obj.voucher_instructions_ES,
                    "lang": 3
                }
            ],

            "travellerInfoForAll": false,
            "fullName": obj.fullname === 1 ? true : false,
            "sex": obj.sex === 1 ? true : false,
            "nationality": obj.nationality === 1 ? true : false,
            "birthday": obj.birthday,
            "numDocument": obj.num_document === 1 ? true : false,
            "cellPhone": false,//obj.s_phone_cel,
            "metaTitleBR": obj.metatitile,
            "metaTitleEN": obj.metatitileen,
            "metaTitleES": obj.metatitilees,
            "metaDescriptionBR": obj.meta_description,
            "metaDescriptionEN": obj.meta_descriptionen,
            "metaDescriptionES": obj.meta_descriptiones,
            "palavraChaveBR": obj.slug,
            "palavraChaveEN": obj.slugen,
            "palavraChaveES": obj.sluges,
            "slug": [
                {
                    "text": obj.slug,
                    "lang": 1
                },
                {
                    "text": obj.slugen,
                    "lang": 2
                },
                {
                    "text": obj.sluges,
                    "lang": 3
                }
            ]
        }



        /* let aux: any = {
             "productCode": obj.productCode,
 
             //Dados do tour 
             "productName": obj.productName,
             "minNumPeople": obj.minNumPeople,
             "maxNumPeople": obj.maxNumPeople,
             "sellingType": obj.sellingType,
             "productSubCategory": obj.productSubCategory,
 
             //Operação
             "operationType": obj.operationType,
             "site": obj.site,
             "app": obj.app,
             "b2b": obj.b2b,
             "marketplaces": obj.marketPlace,
 
             //Tipo de Operação Própria
             "resourcesId": obj.resourcesId,
             "ourVehicleType": obj.ourVehicleType,
             "sessionGroupId": obj.sessionGroupId,
 
             //Tipo de Operação Terceiros
             "supplierId": obj.supplierId,
             "supplierIdentity": supplierIdentity,
 
             //falta adicionar Integração
             "integrationPurchaseType": integrationPurchaseType,
             "integrationAccessType": integrationAccessType,
 
             //Resumo e detalhes
             "summary": obj.summary,
             "details": obj.details,
 
             //Descrição e destaques
             "description": obj.description,
             "highLight": obj.highLight,
 
             //Fotos
             "productUrl": obj.productUrl,
             "productImages": obj.productImages,
 
             //Modalidade
             "modalidade": modalidade,
 
             //Tarifário
 
             //Localização
             "eventLocation": obj.eventLocation,
             "tourLocationLat": obj.tourLocationLat,
             "tourLocationLong": obj.tourLocationLong,
 
             //Instruções
             "travellerInfoIsNeed": obj.travellerInfoIsNeed,
             "travellerInfoForAll": obj.travellerInfoForAll,
             "fullName": obj.fullName,
             "sex": obj.sex,
             "nationality": obj.nationality,
             "birthday": obj.birthday,
             "numDocument": obj.numDocument,
             "cellPhone": obj.cellPhone,
             "voucherInstruction": obj.voucherInstruction,
 
             //SEO
             "metaTitleBR": obj.metaTitleBR,
             "metaTitleEN": obj.metaTitleEN,
             "metaTitleES": obj.metaTitleES,
             "metaDescriptionBR": obj.metaDescriptionBR,
             "metaDescriptionEN": obj.metaDescriptionEN,
             "metaDescriptionES": obj.metaDescriptionES,
             "palavraChaveBR": obj.palavraChaveBR,
             "palavraChaveEN": obj.palavraChaveEN,
             "palavraChaveES": obj.palavraChaveES,
             "slug": obj.slug,
         }*/

        //console.log(aux)

        for (let i = newProduct.modalidade.length - 1; i >= 0; i--) {

            for (let j = newProduct.modalidade[i].tariff.length - 1; j >= 0; j--) {
                if (newProduct.modalidade[i].tariff[j] === undefined) {
                    newProduct.modalidade[i].tariff.splice(j, 1);
                }
            }

            for (let k = newProduct.modalidade[i].itemsIncluded.length - 1; k >= 0; k--) {
                if (newProduct.modalidade[i].itemsIncluded[k] === undefined) {
                    newProduct.modalidade[i].itemsIncluded.splice(k, 1);
                }
            }

            for (let l = newProduct.modalidade[i].itemsNotIncluded.length - 1; l >= 0; l--) {
                if (newProduct.modalidade[i].itemsNotIncluded[l] === undefined) {
                    newProduct.modalidade[i].itemsNotIncluded.splice(l, 1);
                }
            }
        }

        setTimeout(() => {

            //let tokensImages = convertImageToToken(arrayImages);
            let newArrayObjectImages: any = []

            //tokensImages.then((result) => {
            for (let i = 0; i < arrayImages.length; i++) {
                let aux = {
                    "imageName": arrayImages[i],
                    "imageBase64": arrayImages[i],
                    "picOrder": (i + 1)
                }
                newArrayObjectImages.push(aux)
            }

            //})

            setTimeout(() => {

                newProduct.productImages = newArrayObjectImages;
                setTourObject((prev: any) => ({ "isTranslate": 1, "allImages": newArrayObjectImages}))
                dispatch(listProduct(newProduct))
                setEditTourObject(newProduct)
                const cookies = parseCookies();
                if (cookies.idManagement) {
                    setManagement(Number(cookies.idManagement));
                }
                destroyCookie(undefined, 'idManagement');
                setLoading(false);
            }, 3000);


        }, 10000);
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const convertImageToToken = async (data: any) => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post(`File/UploadBase64?switchCaseParam=1`,
                data
                , config);
            if (res.status !== 400) {
                if (res.data.data.log === 0) {
                    setModalShow(false);
                    return res.data.data.data;
                } else {
                    setModalLog(1);
                    setModalMessage(res.data.data.texto);
                }
            } else {
                setModalLog(1);
                setModalMessage('Erro ao processar operação');
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage('Erro ao processar operação');
        }
    }

    function urlToBase64(url: string, callback: any) {
        // Use Fetch API para obter a imagem como um array de bytes
        fetch(url)
            .then(response => response.arrayBuffer())
            .then(buffer => {
                // Converta o buffer para base64
                const base64 = arrayBufferToBase64(buffer);
                // Chame a função de retorno de chamada com o resultado
                callback('data:image/jpeg;base64,' + base64);
            })
            .catch(error => console.error('Erro ao converter a URL para base64:', error));
    }

    function arrayBufferToBase64(buffer: any) {
        // Converta o buffer para uma string base64
        let binary = '';
        const bytes = new Uint8Array(buffer);
        const len = bytes.byteLength;

        for (let i = 0; i < len; i++) {
            binary += String.fromCharCode(bytes[i]);
        }

        return btoa(binary);
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditSupplier = async () => {
            try {
                const { data } = await axios.create({
                    baseURL: 'https://sig-php.destinow.com.br',
                }).get(`/listNewProduct?product_code=${productCode}`, config);


                transformObject(data[0]);
                /*if (data.statusCode !== 400) {
                    if (data.data.log === 0) {
                        transformObject(data[0]);
                    } else {
                        alert(data.data.texto);
                    }
                }*/
            } catch (error: any) {
                setTimeout(() => {
                    EditSupplier();
                }, 5000);
            }
        }

        EditSupplier();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleNext = (newStep: any, newObject: any = {}) => {
        setTourObject({ ...tourObject, ...newObject })
        
        switch (newStep) {
            case 'startProduct':
                setManagement(0);
                break;
            case 'dataTour':
                if (alreadyPassed === 0) {
                    setAlreadyPassed(1);
                }
                setManagement(1);
                break;
            case 'operation':
                if (alreadyPassed === 1) {
                    setAlreadyPassed(2);
                }
                setManagement(2);
                break;
            case 'summaryAndDetails':
                if (alreadyPassed === 2) {
                    setAlreadyPassed(3);
                }
                setManagement(3);
                break;
            case 'descriptionsAndHigh':
                if (alreadyPassed === 3) {
                    setAlreadyPassed(4);
                }
                setManagement(4);
                break;
            case 'photo':
                if (alreadyPassed === 4) {
                    setAlreadyPassed(5);
                }
                setManagement(5);
                break;
            case 'modalities':
                if (alreadyPassed === 5) {
                    setAlreadyPassed(6);
                }
                setManagement(6);
                break;
            case 'tariffs':
                if (alreadyPassed === 6) {
                    setAlreadyPassed(7);
                }
                setManagement(7);
                break;
            case 'location':
                if (alreadyPassed === 7) {
                    setAlreadyPassed(8);
                }
                setManagement(8);
                break;
            case 'instructions':
                if (alreadyPassed === 8) {
                    setAlreadyPassed(9);
                }
                setManagement(9);
                break;
            case 'seo':
                if (alreadyPassed === 9) {
                    setAlreadyPassed(10);
                }
                setManagement(10);
                break;
            case 'finish':
                setAlreadyPassed(11);
                /* setModalShow(true)
                setTimeout(() => {
                    updateTour({});
                }, 1000); */
                break;
            default:
                break;
        }
    };

    const updateTour = async (receivedData: any, isPhotoEdit: any = false) => {
        setModalShow(true)

        let infoEdit: any;// = handleFinalObject();

        //infoEdit = { ...editTourObject, ...receivedData };
        infoEdit = { ...tourSIG.tour, ...receivedData };

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.post('Tour/EditProductTour', infoEdit, config);
            if (data.statusCode !== 400) {
                setCookie(null, 'idManagement', `${management}`, {
                    maxAge: (60),
                    path: '/',
                })
                setModalLog(data.data.log);
                if (data.data.log === 0) {
                    handleRevokeCache();
                    setModalMessage(isPhotoEdit ? "Alteração feita com sucesso. As mudanças feitas nas images podem demorar um pouco para refletir no banco" : data.data.texto);
                } else {
                    setModalMessage(data.data.texto);
                }
            } else {
                setModalLog(1);
                setModalMessage(data?.data?.texto || data?.errorMessage);
            }

        } catch (error: any) {
            setTimeout(() => {
                updateTour(receivedData);
            }, 5000);
        }

        //localStorage.removeItem('productTour');
    }

    const receiveObject = async (data: any) => {

    }

    const handleRevokeCache = async () => {
        try {
            const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/products/revokecache`);
            if (data.status !== 400) {

            }
        } catch (error: any) {

        }
    }

    const reload = () => {
        if (modalLog === 0) {
            window.location.reload();
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Produtos / Editar Tour"} />

                    <div className="d-flex align-items-center my-3">
                        <span
                            className="back m-0"
                            onClick={() => {
                                window.location.href = "/products/list-products";
                            }}
                        >
                            <FontAwesomeIcon
                                icon={["fal", "angle-double-left"]}
                                style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                            />
                            Voltar
                        </span>
                    </div>


                    <Row className="add-product">
                        {/* MENU */}
                        {
                            management !== 0
                                ?
                                <Col md={3} lg={2}>
                                    <div className='card menu border-0'>
                                        <div className="list-menu">
                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Informações Básicas
                                                </span>
                                                <span className={management === 1 ? "list-menu-item item-action active" : management > 1 || 1 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 1 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (1 < alreadyPassed) { handleNext('dataTour', {}) } }}>
                                                        <p>Dados do tour</p>
                                                        {management > 1 || 1 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                        {/*ICONE DE CHECKED PARA QUANDO PASSAR PRO PROXIMO STEP <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]}/> */}
                                                    </div>
                                                </span>
                                                <span className={management === 2 ? "list-menu-item item-action active" : management > 2 || 2 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 2 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (2 < alreadyPassed) { handleNext('operation', {}) } }}>
                                                        <p>Operação</p>
                                                        {management > 2 || 2 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 3 ? "list-menu-item item-action active" : management > 3 || 3 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 3 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (3 < alreadyPassed) { handleNext('summaryAndDetails', {}) } }}>
                                                        <p>Resumo e Detalhes</p>
                                                        {management > 3 || 3 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 4 ? "list-menu-item item-action active" : management > 4 || 4 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 4 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (4 < alreadyPassed) { handleNext('descriptionsAndHigh', {}) } }}>
                                                        <p>Descrição e Destaques</p>
                                                        {management > 4 || 4 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>

                                                <span className={management === 5 ? "list-menu-item item-action active" : management > 5 || 5 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 5 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (5 < alreadyPassed) { handleNext('photo', {}) } }}>
                                                        <p>Fotos</p>
                                                        {management > 5 || 5 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Opções e Preços
                                                </span>
                                                <span className={management === 6 ? "list-menu-item item-action active" : management > 6 || 6 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 6 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (6 < alreadyPassed) { handleNext('modalities', {}) } }}>
                                                        <p>Modalidades</p>
                                                        {management > 6 || 6 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 7 ? "list-menu-item item-action active" : management > 7 || 7 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 7 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (7 < alreadyPassed) { handleNext('tariffs', {}) } }}>
                                                        <p>Tarifários</p>
                                                        {management > 7 || 7 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                            <div className="sub-list-menu">
                                                <span className="list-menu-item item-title">
                                                    Conteúdo
                                                </span>
                                                <span className={management === 8 ? "list-menu-item item-action active" : management > 8 || 8 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 8 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (8 < alreadyPassed) { handleNext('location', {}) } }}>
                                                        <p>Localização</p>
                                                        {management > 8 || 8 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 9 ? "list-menu-item item-action active" : management > 9 || 9 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 9 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (9 < alreadyPassed) { handleNext('instructions', {}) } }}>
                                                        <p>Instruções </p>
                                                        {management > 9 || 9 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                                <span className={management === 10 ? "list-menu-item item-action active" : management > 10 || 10 < alreadyPassed ? "list-menu-item item-action success d-flex justify-content-between align-items-center" : "list-menu-item item-action"}>
                                                    <div className="d-flex align-items-center justify-content-between w-100 border-bottom" style={{ cursor: 10 < alreadyPassed ? 'pointer' : 'default' }} onClick={() => { if (10 < alreadyPassed) { handleNext('seo', {}) } }}>
                                                        <p>SEO </p>
                                                        {management > 10 || 10 < alreadyPassed ?
                                                            <FontAwesomeIcon className="ml-2" size="lg" color="green" icon={["fal", "check"]} />
                                                            :
                                                            <></>
                                                        }
                                                    </div>
                                                </span>
                                            </div>

                                        </div>
                                    </div>

                                </Col>
                                :
                                <></>
                        }

                        {loading ?
                            <>
                                <Col md={9} lg={10}>
                                    <Card className="pt-content h-100">
                                        <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                            <div className="load mb-4"></div>
                                            <div>Carregando dados do produto</div>
                                        </div>
                                    </Card>
                                </Col>
                            </>
                            :
                            <>
                                <ProductTour receiveObject={receiveObject} tourObject={tourObject} handleNext={handleNext} management={management} isEdit={false} isMigration={true} editFunction={updateTour} />
                            </>
                        }
                    </Row>
                </div>
                <Modal
                    className="modalAuth"
                    show={modalShow}
                    onHide={() => {
                        if (modalLog === 0) {
                            reload();
                        }
                        setModalLog(null);
                        setModalMessage("Carregando");
                        setModalShow(false);
                    }}
                    aria-labelledby="contained-modal-title-vcenter"
                    backdrop="static"
                >
                    <>
                        <Modal.Body
                            className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                            style={{ height: "600px" }}
                        >
                            {modalLog === null ? (
                                <>
                                    <div className="loading-modal">
                                        <div className="load"></div>
                                    </div>
                                </>
                            ) : (
                                <div>
                                    {modalLog === 0 ? (
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    ) : (
                                        <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                    )}
                                </div>
                            )}
                            <div>{modalMessage}</div>
                            {modalLog !== null &&
                                <div className="d-flex justify-content-center pt-3">
                                    <button
                                        onClick={() => {
                                            reload();
                                            setModalLog(null);
                                            setModalMessage("Carregando");
                                            setModalShow(false);
                                        }}
                                        className="btn btn-primary mx-2 w-25"
                                    >
                                        Confirmar
                                    </button>
                                </div>
                            }
                        </Modal.Body>
                    </>
                </Modal>
            </DesktopDefault>
        </>
    )
}

export default EditTour;