import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
};

const GetAllBadges: React.FC<propPlus> = ({
    propsField
}: propPlus) => {

    const [info, setInfo] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listYesOrNot() {
            try {
                const { data } = await api.get(`/Comission/GetAllBadges`, config);
                if (data.statusCode !== 400) {
                    setInfo(data.data.result)
                }else{
                    setTimeout(()=>{
                        listYesOrNot();
                    },5000);
                }
            } catch (error: any) {
                setTimeout(()=>{
                    listYesOrNot();
                },5000);
            }
        }
        listYesOrNot();
    }, []);

    if (info !== null) {
        return (
            <>
                <div className={"endpoint"}>
                    <Form.Select
                        {...propsField}
                        as="select"
                        variant="standard"
                        margin="normal"
                        
                    >
                        {info.map((item: any) => {
                            return (
                                <>
                                    <option value={item.id}>{item.rangeName}</option>
                                </>
                            )
                        })}

                    </Form.Select>
                    {/* <Select
                        {...propsField}
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        as="select"
                        variant="standard"
                        margin="normal"
                        defaultInputValue={{ "id": 4, "rangeName": "DEFAULT" }}
                        placeholder={t('accordionFinancial.select.select')}
                        options={Array.from(info)}
                        getOptionLabel={(option: any) => `${option.rangeName}`}
                    /> */}
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )
    }
}

export default GetAllBadges;