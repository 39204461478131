import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";
import CardReactFormContainer from 'react-credit-cards';
import 'react-credit-cards/es/styles-compiled.css';

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import Select from "react-select";
import InputMask from "react-input-mask";

import GetStates from '../../C2Points/GetStates';
import GetCitysByState from '../../C2Points/GetCitysByState';
import DropdownStatesAndProvinces from '../../DropdownStatesAndProvinces/DropdownStatesAndProvinces';
import { useAppSelector } from '../../../store/hooks';
//import { useAppSelector } from '../../../store/hooks';
//import { watch } from 'fs';

export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    isForeign: any,
    getValues: any,
    watchCountry?: any;
    stateError?: any;
    t?: any
};

const PaymentCheckoutCredit: React.FC<propForm> = ({
    control, errors, setValue, watchCountry, stateError, isForeign, getValues, t
}: propForm) => {

    const c2tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");
    const finalPrice = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart.totalCart)));
        
    const [cvc, setCvc] = useState<string>('');
    const [expiry, setExpiry] = useState<string>('');
    const [focus, setFocus] = useState<any>('');
    const [name, setName] = useState<string>('');
    const [number, setNumber] = useState<string>('');
    const [brand, setBrand] = useState<string>('');

    const handleInputFocus = (e: any) => {
        setFocus(e.target.name);
    }

    const handleInputFocusOut = (e: any) => {
        setFocus('number');
    }

    const handleInputChange = (e: any) => {
        setNumber(e.target.value);
        setValue('number', e.target.value);
    }

    const handleCallback = ({ issuer }: any, isValid: any) => {
        setBrand(issuer);
        setValue('brand', brand);
        if (isValid) {
            setValue('brand', brand);
        }

    }

    const handleInputChangeName = (e: any) => {
        setName(e.target.value);
        setValue('titular', e.target.value);
    }

    const handleInputChangeExpiry = (e: any) => {
        setExpiry(e.target.value);
        setValue('expiry', e.target.value);
    }

    const handleInputChangeCVC = (e: any) => {
        setCvc(e.target.value);
        setValue('cvc', e.target.value);
    }

    const addState = (objState: any) => {
        //console.log(objCountrys);
        setValue(`state`, objState.id);
    };

    /* const handlePaymentType = (e: any) => {
    } */

    useEffect(()=>{
        setValue('cpfTitular', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[isForeign])

   function renderParcelas(){
    const novoValor = parseFloat(finalPrice);
    var parcelas;
    const parce = [
        { value: "1x", label: t("checkout.paymentCheckout.credit.cash"), selected: "true" }
    ]

    if (!isNaN(novoValor)) {
      if (novoValor >= 200) {
        // Calcula o número máximo de parcelas permitido
         parcelas = Math.min(6, Math.floor(novoValor / 100));
         for(let i = 1; i <= parcelas; i++){
            if(i > 1){
            parce.push({ value: `${i}x`, label: `${i}x`, selected: "false"})
            }
         }
       }
    }
    return parce
   };     
       

    return (
        <>
            <h3>{t("checkout.paymentCheckout.credit.creditCard")}</h3>

            <div className="credit-card" id="payment-div">
                <CardReactFormContainer
                    cvc={cvc}
                    expiry={expiry}
                    focused={focus}
                    name={name}
                    number={number}
                    callback={handleCallback}
                />
            </div>

            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCreditNumber">
                    <Form.Label>{t("checkout.paymentCheckout.credit.number")}</Form.Label>
                    <Controller
                        control={control}
                        name="number"
                        /* rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }} */
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                onChange={(e) => handleInputChange(e)}
                                onFocus={(e) => handleInputFocus(e)}
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="number"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCreditTitular">
                    <Form.Label>{t("checkout.paymentCheckout.credit.owner")}</Form.Label>
                    <Controller
                        control={control}
                        name="titular"
                        /* rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }} */
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                                onChange={(e) => handleInputChangeName(e)}
                                onFocus={(e) => handleInputFocus(e)}
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="titular"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
            </Row>
            <Row className="mb-3">
                <Form.Group as={Col} md="2" controlId="formCheckoutPymentCreditValidDate">
                    <Form.Label>{t("checkout.paymentCheckout.credit.expiration")}</Form.Label>
                    <Controller
                        control={control}
                        name="expiry"
                        /* rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }} */
                        // render={({ field }: any) => (
                        //     <Form.Control
                        //         {...field}
                        //         aria-invalid={errors?.compName ? "true" : ""}
                        //         variant="standard"
                        //         margin="normal"
                        //         autoComplete="off"
                        //         required
                        //         onChange={(e) => handleInputChangeExpiry(e)}
                        //         onFocus={(e) => handleInputFocus(e)}
                        //     />
                        // )}
                        render={({ field }: any) => (
                            <InputMask mask="99/9999" value={field?.value} onChange={(e) => handleInputChangeExpiry(e)}>
                                {(inputProps: any) => (
                                    <Form.Control
                                        {...inputProps}
                                        aria-invalid={errors?.cpf ? "true" : ""}
                                        placeholder="99/9999"
                                        variant="standard"
                                        margin="normal"
                                        type="text"
                                        required
                                        autoComplete='off'
                                    />
                                )}
                            </InputMask>

                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="expiry"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="2" controlId="formCheckoutPymentCreditCode">
                    <Form.Label>{t("checkout.paymentCheckout.credit.code")}</Form.Label>
                    <Controller
                        control={control}
                        name="cvc"
                        /* rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }} */
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                                onChange={(e) => handleInputChangeCVC(e)}
                                onFocus={(e) => handleInputFocus(e)}
                                onBlur={(e) => handleInputFocusOut(e)}
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="cvc"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCreditCPF">
                    <Form.Label>{isForeign !== true ? t("checkout.paymentCheckout.credit.cpf") : t("checkout.paymentCheckout.credit.passport")}</Form.Label>
                    <Controller
                        control={control}
                        name="cpfTitular"
                        /* rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }} */
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="cpfTitular"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
            </Row>
            <Row>
                {isForeign === true
                    ?
                   <>
                    {watchCountry === "CA" || watchCountry === "US" ?
                    <>
                     <DropdownStatesAndProvinces control={control} watchCountry={watchCountry} errors={errors} actionState={addState} t={t}/>
                     <Form.Group as={Col} md="4" controlId="formCheckoutCountey" className='mb-3'>
                            <Form.Label>{t("checkout.paymentCheckout.credit.city")}</Form.Label>
                            <Controller
                                control={control}
                                name="city"
                                /* rules={{ required: { value: true, message: 'Por favor, informe o país.' } }} */
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.compFantasia ? "true" : ""}
                                        label="Cidade"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="city"
                                render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                )}
                            />
                        </Form.Group>
                        </>
                    :
                    <>
                        <Form.Group as={Col} md="4" controlId="formCheckoutCountey" className='mb-3'>
                            <Form.Label>{t("checkout.paymentCheckout.credit.state")}</Form.Label>
                            <Controller
                                control={control}
                                name="state"
                                /* rules={{ required: { value: true, message: 'Por favor, informe o país.' } }} */
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.compFantasia ? "true" : ""}
                                        label="Estado"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="state"
                                render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                )}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutCountey" className='mb-3'>
                            <Form.Label>{t("checkout.paymentCheckout.credit.city")}</Form.Label>
                            <Controller
                                control={control}
                                name="city"
                                /* rules={{ required: { value: true, message: 'Por favor, informe o país.' } }} */
                                render={({ field }: any) => (
                                    <Form.Control
                                        {...field}
                                        aria-invalid={errors?.compFantasia ? "true" : ""}
                                        label="Cidade"
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="city"
                                render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                )}
                            />
                        </Form.Group>
                    </>
                    }
                    </>
                    :
                    <>
                        <Form.Group as={Col} md="4" controlId="formInfoCompanyState" className='mb-3'>
                            <Form.Label>{t("checkout.paymentCheckout.credit.state")}</Form.Label>
                            <Controller
                                control={control}
                                name="state"
                                rules={{ required: { value: true, message: t('checkout.paymentCheckout.credit.stateError') } }}
                                render={({ field }: any) => (
                                    <GetStates
                                        propsField={field}
                                        propsLabel={t('accordionInfoCompany.state')}
                                        propsErrors={errors}
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name="state"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formInfoCompanyCity" className='mb-3'>
                            <Form.Label>{t("checkout.paymentCheckout.credit.city")}</Form.Label>
                            <Controller
                                control={control}
                                name="city"
                                rules={{ required: { value: true, message: t('checkout.paymentCheckout.credit.cityError') } }}
                                render={({ field }: any) => (
                                    <GetCitysByState propsField={field} propsErrors={errors} uf={getValues().state?.id} />
                                )}
                            />
                            {/* <GetCitys propsField={field} propsLabel={t('accordionInfoCompany.city')} propsErrors={errors} /> */}
                            <ErrorMessage
                                errors={errors}
                                name="city"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </>
                }
            </Row>
            <Row>
                <Form.Group as={Col} md="8" controlId="formCheckoutCountey" className='mb-3'>
                    <Form.Label>{t("checkout.paymentCheckout.credit.address")}</Form.Label>
                    <Controller
                        control={control}
                        name="street"
                        /* rules={{ required: { value: true, message: 'Por favor, informe o país.' } }} */
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compFantasia ? "true" : ""}
                                label="Endereço"
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="street"
                        render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                        )}
                    />
                </Form.Group>
            </Row>
            {
                isForeign !== true && c2tourAuth.user.perfil.isAgent === 0 && c2tourAuth.user.perfil.canSeeBills !== true ?
                <>
                <Row>
                    <Form.Group as={Col} md="4" controlId="formCheckoutPaymentCreditParcelas">
                        <Form.Label>{t("checkout.paymentCheckout.credit.installments")}</Form.Label>
                        <Controller
                            control={control}
                            name="parcelas"
                            render={({ field }: any) => (
                                <div className='endpoint'>
                                    <Select
                                        placeholder="Selecione"
                                        onChange={(e: any) => { setValue('parcelas', e.value) }}
                                        options={renderParcelas() }
                                    />
                                </div>
                            )}
                        />
                        <ErrorMessage
                            errors={errors}
                            name="parcelas"
                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                        />
                    </Form.Group>
                </Row>
            </>
            :
            <></>
            }
        </>
    );
}

export default PaymentCheckoutCredit;