import React, { useEffect, useState, Key } from 'react';
import api from '../../services/api';

//import { Col } from 'react-bootstrap';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  isGroup?: any,
  //setIsMaster?:any
};

const GetMasterOrSon: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, isGroup
}: propPlus) => {

  const [info, setInfo] = useState<any>(null);

  //const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  /* const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  } */

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetMasterOrSon`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.masterFilho !== undefined ? "endpoint-error" : "endpoint"}>
          <Form.Select
            {...propsField}
            aria-invalid={propsErrors?.masterFilho ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            disabled={isGroup !== "1" ? true : false}
            required
          >
            <option value='' selected disabled>Selecione</option>
            {
              info.length > 0
                ?
                info.map((info: any, index: Key) => (
                  <option key={index} value={info.id}>{info.description}</option>
                ))
                :
                ''
            }
          </Form.Select>
        </div>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetMasterOrSon;