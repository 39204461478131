import React, { useState, useEffect } from "react";

import "./Contact.scss"
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import api from "../../../../services/api";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any,
    isEdit: any
};

const Contact: React.FC<productTransfer> = React.memo(({ action, receiveObject, transferObject, isEdit
}: productTransfer) => {

    //const [service, setService] = useState(0);
    const [translate, setTranslate] = useState(false);
    const [portText, setPortText] = useState<any>(transferObject.brInstructions === undefined ? "" : transferObject.brInstructions);
    const [engText, setEngText] = useState<any>(transferObject.enInstructions === undefined ? "" : transferObject.enInstructions);
    const [espText, setEspText] = useState<any>(transferObject.esInstructions === undefined ? "" : transferObject.esInstructions);

    const [loading, setLoading] = useState<boolean>(false);

    //const [textErrorP, setTextErrorP] = useState<string>('');
    const [textErrorD, setTextErrorD] = useState<string>('');

    const getChoiceTranslate = (num: boolean) => {
        setTranslate(num);

        if (num === true) {
            TranslateTextEN();
            TranslateTextES();
        }
    };

    const TranslateTextEN = async () => {
        setLoading(true);

        try {
            const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                "q": portText.split("<p>")[1].split("</p>")[0],
                "source": "pt-BR",
                "target": "en",
                "format": "text"
            });
            //const res = await api.post(`https://api.mymemory.translated.net/get?q=${portText.split("<p>")[1].split("</p>")[0]}&langpair=pt-BR|en-GB`);
            if (res.status !== 400) {
                setLoading(false);
                setEngText(res?.data?.data?.translations[0]?.translatedText);
            }
        } catch (error: any) {

        }
    };

    const TranslateTextES = async () => {
        setLoading(true);

        try {
            const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
                "q": portText.split("<p>")[1].split("</p>")[0],
                "source": "pt-BR",
                "target": "es",
                "format": "text"
            });
            //const res = await api.post(`https://api.mymemory.translated.net/get?q=${portText.split("<p>")[1].split("</p>")[0]}&langpair=pt-BR|es-ES`);
            if (res.status !== 400) {
                setLoading(false);
                setEspText(res?.data?.data?.translations[0]?.translatedText);
            }
        } catch (error: any) {

        }
    };

    useEffect(() => {
        if (translate === true) {
            setEngText(portText);
            setEspText(portText);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [translate]);

    const modules: any = {
        toolbar: [
            ['bold'],
        ],
    };

    const onSubmit = (data: any) => {
        if ((portText === '' || engText === '' || espText === '')) {
            setTextErrorD("Por favor, informe as instruções");

            setTimeout(() => {
                setTextErrorD('');
            }, 2000);
        } else {
            data.brInstructions = portText.replace("<p>", "").replace("</p>", "");
            data.enInstructions = engText.replace("<p>", "").replace("</p>", "");
            data.esInstructions = espText.replace("<p>", "").replace("</p>", "");

            if (isEdit !== true) {
                action('blackout', data);
            } else {
                receiveObject(data);
            }
        }
    }

    const formats = ["bold"]
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [value, setValue] = useState('');

    // const getChoiceService = (num: number) => {
    //     setService(num)
    // }

    const {
        control,
        handleSubmit,
        //setValue
        formState: { errors },
    } = useForm({});

    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                    <div className='card h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Instruções e contato"}</h3>
                        </div>

                        <section className="">
                            <Row className="mb-3 mt-3">
                                <Col md="12">
                                    <div className="label-small mb-3">
                                        Digite as seguintes informações para que os passageiros possam entrar em contato, se necessário.
                                    </div>
                                </Col>
                                <Form.Group as={Col} md="6" controlId="">
                                    <Form.Label className="label-small">
                                        Email
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="email"
                                        defaultValue={transferObject.email}
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                type="email"
                                                variant="standard"
                                                margin="normal"
                                                required
                                                autoComplete='off'
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="email"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="">
                                    <Form.Label className="label-small">
                                        Endereço
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="adress"
                                        defaultValue={transferObject.adress}
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                variant="standard"
                                                margin="normal"
                                                required
                                                autoComplete='off'
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="adress"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="" className="mt-3">
                                    <Form.Label className="label-small">
                                        Telefone
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="phone"
                                        defaultValue={transferObject.phone}
                                        rules={{ required: { value: true, message: 'Por favor, preencha o campo' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                variant="standard"
                                                margin="normal"
                                                required
                                                autoComplete='off'
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="phone"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                        </section>

                        <section className="my-3">
                            <Row>
                                <Col md="12">
                                    <div className="label-small">
                                        Instruções para o passageiro
                                    </div>

                                    <div className="transfer-meeting-point my-2">
                                        <label className="label">Português</label>
                                        <ReactQuill id="textPt" theme="snow" value={portText} onChange={setPortText} formats={formats} modules={modules} placeholder="Escreva aqui..." />
                                    </div>

                                    {/* <textarea className="w-100 mt-2" name="" id="" rows={5} placeholder="Português"></textarea> */}
                                </Col>

                                <Col md="12">
                                    <div className="d-flex w-auto mt-2">
                                        <Controller
                                            control={control}
                                            name="checkTranslation"
                                            render={({ field }: any) => (
                                                <Form.Check
                                                    {...field}
                                                    type="checkbox"
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    className="mr-2"
                                                    onClick={() => getChoiceTranslate(!translate)}
                                                />
                                            )}
                                        />
                                        <Form.Label className="d-flex align-items-center label-small mb-0">
                                            Traduzir automaticamente
                                            {
                                                loading === true
                                                    ?
                                                    <div className="load mx-3"></div>
                                                    :
                                                    <></>
                                            }
                                        </Form.Label>
                                    </div>
                                </Col>

                                <Col md="6">
                                    {translate === false ?
                                        <div className="transfer-meeting-point my-2">
                                            <label className="label">Inglês</label>
                                            <ReactQuill id="textEn" theme="snow" value={engText} onChange={setEngText} formats={formats} modules={modules} />
                                        </div>
                                        :
                                        <div>
                                            <div style={{ marginLeft: "3px", marginTop: "9px" }} ><span>Inglês</span></div>
                                            <Controller
                                                control={control}
                                                name="textEn"
                                                render={({ field }: any) => (
                                                    <Form.Text
                                                        {...field}
                                                        className="w-100 mt-2"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        style={{ position: 'relative', top: '11px', left: '3px', fontSize: '16px', fontWeight: '400' }}
                                                    > <div dangerouslySetInnerHTML={{ __html: engText }}></div></Form.Text>
                                                )}
                                            />
                                        </div>
                                    }
                                </Col>

                                <Col md="6">
                                    {translate === false ?
                                        <div className="transfer-meeting-point my-2">
                                            <label className="label">Espanhol</label>
                                            <ReactQuill id="textEs" theme="snow" value={espText} onChange={setEspText} formats={formats} modules={modules} />
                                        </div>
                                        :
                                        <div>
                                            <div style={{ marginLeft: "3px", marginTop: "9px" }} ><span>Espanhol</span></div>
                                            <Controller
                                                control={control}
                                                name="textEs"
                                                render={({ field }: any) => (
                                                    <Form.Text
                                                        {...field}
                                                        className="w-100 mt-2"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete="off"
                                                        style={{ position: 'relative', top: '11px', left: '3px', fontSize: '16px', fontWeight: '400' }}
                                                    > <div dangerouslySetInnerHTML={{ __html: espText }}></div></Form.Text>
                                                )}
                                            />
                                        </div>
                                    }
                                </Col>
                            </Row>

                            <small style={{ color: "red" }}>{textErrorD}</small>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true
                                    ?
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            variant="outline-primary"
                                            className="form-button mr-3"
                                            onClick={() => action('features')}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Continuar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </section>
                    </div>
                </Form>
            </Col>

        </>
    )
});

export default Contact;