/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableCardConciliation.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import SettleInBatch from "../SettleInBatch/SettleInBatch";

export interface propTable {
  sessions: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportReport: any;
  printReport: any;
  loading: any;
  dateSelect: any;
  apiSelect: any;
  totalReceber: any;
  liquidado: any,
  refreshData: any
}

const TableCardConciliation: React.FC<propTable> = ({
  sessions, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportReport, printReport, loading, dateSelect, apiSelect, totalReceber, liquidado, refreshData
}: propTable) => {
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])
  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [settleInBatchShow, setSettleInBatchShow] = useState<boolean>(false);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;
  

  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }
  
  function settleInBatch() {
    if(sessions.length === 0) {
      // TODO show no data dialog
      setResponseText('Nenhum registro selecionado');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
    } else if(liquidado === true) { // verificar se tem pagamento liquidado no dia
      //msg
      setResponseText('Essa data ja possui valores liquidados para o adquirente selecionado');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
    } else {
      // TODO call question
      setSettleInBatchShow(true);
    }
  }

  /*converte a data UTC para a data local do usuário*/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  // function convertDate(cell: any, row: any) {
  //   var date = convertUTCDateToLocalDate(new Date(cell));
  //   /* console.log(date) */
  //   return (
  //     <>
  //       {date}
  //     </>
  //   );
  // }
  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addTotalRps(cell: any, row: any) {
    return (
      <div
        className={row.totalRps === row.totalMargem ?
          "invoice-blue"
          : row.totalRps === 0 ?
            "invoice-red"
            : row.totalRps < row.totalMargem ?
              "invoice-orange"
              : ""}
      >
        {cell.toFixed(2).replace(".", ",")}
      </div>
    );
  }

  function addTotalErp(cell: any, row: any) {
    return (
      <div
        className={row.totalErp === row.totalMargem ?
          "invoice-blue"
          : row.totalErp === 0 ?
            "invoice-red"
            : row.totalErp < row.totalMargem ?
              "invoice-orange"
              : ""}
      >
        {cell.toFixed(2).replace(".", ",")}
      </div>
    );
  }

  const columns = [
    { dataField: "agente", text: "Agente", },
    {
      dataField: "dataReserva",
      text: "Data Reserva",

      formatter: convertDate,
    },
    {
      dataField: "dataOperacao",
      text: "Data Operação",

      formatter: convertDate,
    },
    { dataField: "tour", text: "Tour / Descrição", },
    { dataField: "cliente", text: "Cliente", },
    {
      dataField: "amount",
      text: "Valor",

      formatter: convertValue,
    },
    {
      dataField: "amountBraspag",
      text: "Valor Adquirente",

      formatter: convertValue,
    },
    {
      dataField: "mdr",
      text: "MDR",

      formatter: convertValue,
    },
    {
      dataField: "mdrBraspag",
      text: "MDR Adquirente",

      formatter: convertValue,
    },
    {
      dataField: "vencimento",
      text: "Vencimento",

      formatter: convertDate,
    },
    {
      dataField: "vencimentoBraspag",
      text: "Venc. Adquirente",

      formatter: convertDate,
    },
    // { dataField: "id", text: " ", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando recebimentos", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "sessions", text: "Recebimentos" }];

  const notFoundProducts = [{ sessions: "Nenhum recebimento encontrado" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === 4) {
      return 'border-ativo';
    } else if (row.statusPagamento !== 1 && row.conciliado === 1) {
      return 'border-analise';
    } else {
      return 'border-inativo';
    }
  };

  function handleReload() {
    window.location.reload();
  }

  if (sessions !== null) {
    return (
      <>
        <div className="table-default card-conciliation-table">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                {/* <div>
                  <button className="btn btn-bills" onClick={handlePrintClick}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div> */}
                <div>
                  <button className="btn btn-bills" onClick={() => settleInBatch()}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "dollar-sign"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Liquidar em Lote
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={sessions}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            <Modal
              className={"modal-custom modalAuth modal-large"}
              show={settleInBatchShow}
              onHide={() => setSettleInBatchShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Header closeButton>
                  <div className="d-flex justify-content-between w-100">
                    <h3>Liquidar em Lote</h3>
                  </div>
                </Modal.Header>
                <hr style={{ margin: "30px 0" }} />
                <SettleInBatch 
                  show={setModalSuccess}
                  setModalContent={setModalContent}
                  setResponseText={setResponseText}
                  setLog={setLog}
                  dataPgto={dateSelect}
                  apiSelect={apiSelect}
                  totalReceber={totalReceber}
                />
              </>
            </Modal>

            {/* Modal de Success */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => { if(modalContent !== 'loading') { if(modalContent === 'success') {refreshData();} setModalSuccess(false); } }}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos'>
                  {
                    modalContent !== "loading"
                      ?
                      <>
                        <div>
                          {
                            log === 2 || log === "2"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "exclamation-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              log === 1 || log === "1"
                                ?
                                <FontAwesomeIcon
                                  icon={["fal", "times-circle"]}
                                  size="5x"
                                  className="text-primary"
                                  style={{ fontSize: "7.5em"}}
                                />
                                :
                                log === 0 || log === "0"
                                  ?
                                  <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                                  :
                                  <FontAwesomeIcon
                                    icon={["fal", "question-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                          }
                        </div>
                        <div>
                          {responseText}
                        </div>
                        <div className="d-flex justify-content-center">
                          {
                            modalContent === 'success'
                              ?
                              <button
                                onClick={() => {refreshData(); setModalSuccess(false);}}
                                className="btn btn-primary mx-2 w-25"
                              >
                                Confirmar
                              </button>
                              :
                              modalContent === 'warning'
                                ?
                                <button
                                  onClick={() => setModalSuccess(false)}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                                :
                                <button
                                  onClick={() => setModalSuccess(false)}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                          }
                        </div>
                      </>
                      :
                      <div className="modal-body">
                        <div className="text-center">
                          <div className="load"></div>
                        </div>
                        <div>
                          <span>Processando</span>
                        </div>
                        <div></div>
                      </div>
                  }
                </Modal.Body>
              </>
            </Modal>
            {/* END - Modal de Success */}

          </div>
        </div>
      </>
    );
  } else if (sessions === null && loading === true) {
    return (
      <>
        <div className="table-default card-conciliation-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" >
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                {/* <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div> */}
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "dollar-sign"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Liquidar em Lote
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default card-conciliation-table not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableCardConciliation;
