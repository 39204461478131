import React, { useEffect, useState } from "react";
import { Container, Row, Col, Form, InputGroup, Badge } from "react-bootstrap";
// import ProductOptions from "../ProductOptions/ProductOptions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ProductSlider from "../ProductCarrousel/ProductCarrousel";

import "./InfoProducts.scss";
//import RefundRules from "../RefundRules/RefundRules";

import { useTranslation } from "react-i18next";
import DropdownHoursFree from "../../../../components/DropdownHoursFree/DropdownHoursFree";
import DoubleCalendarCombo from "../../../../components/Calendar/DoubleCalendarCombo";
import DropdownHoursCombo from "../../../../components/DropdownHoursCombo/DropdownHoursCombo";
import DropdownNumberPeopleCombo from '../../../../components/DropdownNumberPeopleCombo/DropdownNumberPeopleCombo';
import api from "../../../../services/api";
import { useAppSelector, useAppDispatch } from "../../../../store/hooks";
import { addItemCart } from "../../../../store/cart";

export interface tourList {
  tourDetails: any;
  setModalShow?: any;
}

const InfoProductsCombo = ({
  tourDetails,
  setModalShow
}: any) => {
  const cartSIG = useAppSelector((state) => state.cart);
  const dispatch = useAppDispatch();

  const { t } = useTranslation();
  const [numberPeople, setNumberPeople] = useState<any>([]);
  const [dateTour, setDateTour] = useState<any>([]);
  const [indexCombo, setIndexCombo] = useState<number>(0);
  const [ranges, setRanges] = useState<any>(null);
  const [indexador, setIndexador] = useState<any>([]); ///array que me diz quais dos ComboOptions vai habilitar o dropdown
  const [preloader, ] = useState<boolean>(false);
  const [, setPeopleDisponible] = useState<boolean>(false);
  const [totalPriceCombo, setTotalPriceCombo] = useState<number | null>(null);

  function ScrollTop() {
    window.scrollTo(0, 0);
  }

  function scrollView(id: any) {
    var scrollElement = document.getElementById(id);
    scrollElement?.scrollIntoView({ behavior: "smooth" });
  }

  function changePriceDateTour(obj: any, index: number, isDateChange: boolean = false) {
    let newNumberPeople: any = numberPeople;
    newNumberPeople[index] = {
      "adults": 0,
      "childs": 0,
      "elders": 0,
      "globalPeople": 0,
      "infants": 0,
      "student": 0
    }
    setNumberPeople(newNumberPeople)

    if (isDateChange) {
      delete obj[index].time
      delete obj[index].adult
      delete obj[index].child
      delete obj[index].infant
      delete obj[index].student
      delete obj[index].tieldersme
      delete obj[index].global
      delete obj[index].maxStockNumber
    }

    setDateTour([...obj]);
  }

  useEffect(() => {
    if (tourDetails !== null) {
      var aux: any = [];
      for (var i = 0; i < tourDetails.modalities.length; i++) {
        aux.push({
          adult: 0,
          child: 0,
          infant: 0,
        })
      }
      setDateTour(aux);
    }

  }, [tourDetails])

  const setNewNumberPeople = (peoples: any, index: number) => {
    numberPeople[index] = peoples;
    setNumberPeople([...numberPeople]);
  };

  function addIndex(index: any) {
    var aux: any = indexador;
    if (aux.includes(index) === false) {
      aux.push(index);
    }
    setIndexador(aux);
  }

  const totalPriceComboCalculator = async () => {
    const obj = {
      "comboCode": tourDetails.productCode,
      "comboList": dateTour.map((elem: any, index: number) => {
        return ({
          "qtdAdulto": numberPeople[index].adults,
          "qtdChild": numberPeople[index].childs,
          "qtdInfant": numberPeople[index].infants,
          "qtdElders": numberPeople[index].elders,
          "qtdStudents": numberPeople[index].student,
          "idTarifario": elem.auxTarif[0].idTarifario,
          "qtdPrivativo": numberPeople[index].globalPeople,
          "productCode": tourDetails.modalities[tourDetails.modalities.findIndex((e: any) => e.tarCode === elem.tarUniqueCode)].productCode/* elem.productCode */,
          "date": elem.data,
          "time": elem.time,
          "productType": tourDetails.modalities[tourDetails.modalities.findIndex((e: any) => e.tarCode === elem.tarUniqueCode)].productType
        })
      })
    }

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    try {
      const data = await api.post(`/Products/ComboCalculaPreco`, obj, config);
      if (data.status !== 400) {
        setTotalPriceCombo(data.data.data.data.valor);
        /* setTourDetails(data.data.data); */
      }
    } catch (error) { }

    return <></>
  }

  useEffect(() => {
    if (tourDetails !== null && tourDetails?.modalities?.length === indexCombo) {
      totalPriceComboCalculator()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [indexCombo])

  function keepDropdownOpen() {
    var elem: any = document.getElementById("authCartDropdown");

    if (elem && !elem.classList.contains("active")) {
      elem.classList.add("active");
    }
  }

  const addToCart = (itemOption: any) => {
    var repeatedItem: any = false; //impede de adicionar produto repetido no carrinho

    cartSIG.dados.forEach((elem: any) => {
      if (elem.comboCode === itemOption.productCode) {
        repeatedItem = true
      }
    });

    if (repeatedItem === true) {
      alert(`${t("sales.tourList.accordion.productExists")}`);
    } else {
      let comboObject: any = [];

      itemOption.modalities.forEach((elem: any, index: number) => {
        comboObject.push({
          code: itemOption.productCode,
          modalityName: elem.modalityName,
          qtdAdulto: numberPeople[index].adults,
          qtdChild: numberPeople[index].childs,
          qtdInfant: numberPeople[index].infants,
          qtdElders: numberPeople[index].elders,
          qtdStudents: numberPeople[index].student,
          qtdPrivativo: numberPeople[index].globalPeople,
          productCode: elem.productCode,
          idTarifario: dateTour[index].auxTarif[0].idTarifario,
          date: dateTour[index].data,
          time: dateTour[index].time,
          productType: elem.productType,
          pickupListId: elem.pickupListId,
          //idPickup: 0,
          supplierId: 414,
          meetingPoint: elem.meetingPoint,
          typePickup: elem.typePickup
        })
      });

      const item = {
        productName: itemOption.productName,
        productNameBR: itemOption.productNameBR,
        productNameEN: itemOption.productNameEN,
        productNameES: itemOption.productNameES,
        modalityName: "",
        imgCart: tourDetails.images[0],
        imagesBaseUrl: tourDetails.imagesBaseUrl,
        typeProduct: tourDetails.typeProduct,
        productType: 1,
        price: totalPriceCombo,

        idTarif: 0, //PENDENTE

        priceAdults: 112, //PENDENTE
        priceChilds: 112, //PENDENTE
        priceInfants: 112, //PENDENTE
        priceElders: 0, //PENDENTE
        priceStudent: 0, //PENDENTE
        priceGlobalPeople: 0, //PENDENTE
        reservationSystem: 1, //PENDENTE

        sellingType: 2, //PENDENTE
        adults: 1,
        childs: 2,
        infants: 0,
        elders: 0,
        student: 0,
        globalPeople: 0,

        comboObject, //add os treco do combo
        comboCode: itemOption.productCode,
        isCombo: true,
        productCode: "",
        time: dateTour[0].time,
        date: dateTour[0].data,
        supplier: itemOption.supplier,
        supplierFantasyName: itemOption.supplierFantasyName,
        typePickup: "0",
        meetingPoint: itemOption.eventLocation,
        pickupListId: 0,
        discount: 0,
        customValueNet: 0,
        customValueSell: 0,
        goingSource: "null",
        commingSource: "null",
        latOrigem: "null",
        lngOrigem: "null",
        latDestino: "null",
        lngDestino: "null",
        cia: "null",
        voo: "null",
        smallSuitcase: 0,
        bigSuitcase0: 0,
        idVeiculo: 0,
        passengers: [],
        labelsSorted: ["Adulto", "Criança", "Infante"]
      }

      dispatch(addItemCart(item));
      ScrollTop();
      keepDropdownOpen();
    }
  };

  if (tourDetails !== null) {
    return (
      <div className="info-products pb-4">
        <div
          style={{
            backgroundImage: `url('${tourDetails.imagesBaseUrl + tourDetails.images[0]}')`,
            backgroundSize: "cover",
            backgroundPosition: "center",
          }}
        >
          <h1 style={{ color: "#FFF" }}>
            {tourDetails.productName.split("-", 1)}
          </h1>
          {/* <div className="jumbotron">
            <Row>
              <div className="col-6 subtitle-product pb-2">
                <h3 className="m-0">
                  {tourDetails.modalities[0].activityDuration}
                </h3>
                <span className="m-0 pb-3">{t('sales.infoProducts.duration')}</span>
              </div>
              <div className="col-6 subtitle-product pb-2">
                <h3 className="m-0">{tourDetails.maxNumPeople}</h3>
                <span className="m-0">{t('sales.infoProducts.capacity')}</span>
              </div>
            </Row>
          </div> */}
        </div>
        <nav id="navbar-destino" className="navbar navbar-light bg-white">
          <div
            className="container text-center px-5"
            style={{ display: "block" }}
          >
            <ul className="nav-pills scroll-nav">
              <Row>
                <Col md={7} className="flex-column">
                  <Row>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-experience")}
                        >
                          {" "}
                          <p className="d-md-inline d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "grin-wink"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              {t('sales.infoProducts.exp')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-options")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "list"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              {t('sales.infoProducts.options')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-info")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "info-circle"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              {t('sales.infoProducts.info')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                    <Col md={3}>
                      <li className="nav-item">
                        <span
                          className="nav-link link-tour nav-tour"
                          onClick={() => scrollView("session-contact")}
                        >
                          {" "}
                          <p className="d-lg-inline text-md-uppercase">
                            <FontAwesomeIcon
                              icon={["fal", "phone-rotary"]}
                              size="1x"
                              style={{ marginRight: "5px" }}
                            />
                            <span style={{ verticalAlign: "text-bottom" }}>
                              {t('sales.infoProducts.contact')}
                            </span>
                          </p>
                        </span>
                      </li>
                    </Col>
                  </Row>
                </Col>
                <Col md={5}>
                  <li className="nav-item">
                    <span
                      className="bg-primary nav-link link-tour nav-tour"
                      onClick={() => scrollView("session-options")}
                      style={{
                        width: "135px",
                        marginLeft: "auto",
                      }}
                    >
                      {" "}
                      <p
                        className="d-lg-inline text-md-uppercase"
                        style={{ color: "#FFF" }}
                      >
                        {t('sales.infoProducts.seeOptions')}
                      </p>
                    </span>
                  </li>
                </Col>
              </Row>
            </ul>
          </div>
        </nav>
        <Container
          className="scroll-product"
          fluid
          style={{ backgroundColor: "#F2F2F2" }}
        >
          <Row>
            <div className="col-12 col-md-12 text-info-products px-0 py-4">
              <ProductSlider arrayImages={tourDetails} />
              <h4>{t('sales.infoProducts.other')}</h4>
              <div
                dangerouslySetInnerHTML={{
                  __html: tourDetails.productDescription,
                }}
              ></div>
              {/* <div
                id="session-highlights"
                className="col-12 text-info-products mt-4"
              >
                <h4>{t('sales.infoProducts.dest')}</h4>
                <ul className="mx-4">
                  {tourDetails.highlights.map((h: any, index: any) => {
                    return <li key={index}> {h} </li>;
                  })}
                </ul>
              </div> */}
            </div>
            {/* <div className="col-12 mt-4 text-info-products">
              <h4>{t('sales.infoProducts.det')}</h4>
              <div className="experience mx-4">
                {tourDetails.details.map((d: any, index: any) => {
                  return <li key={index}> {d} </li>;
                })}
              </div>
            </div> */}
            <div id="session-options" className="col-12 mt-4 text-info-products">
              <h4>{t('sales.infoProducts.options')}</h4>
              <div>
                <div className="modal-prod-options">
                  {tourDetails.modalities.map((tour: any, index: any) => {
                    return (
                      <>
                        <div className="bg-white mt-4">
                          {
                            (indexCombo === index) ?
                              <>
                                <Row className="m-0">
                                  <div className="col-12 col-md-9 py-3">
                                    <div className="d-flex flex-column justify-content-between h-100">
                                      <div>
                                        <h3>
                                          {`${tour.modalityName} - ${tour.productName}`}
                                          {tour.rangeComissionLabel !== null && tour.rangeComissionLabel !== "DEFAULT" ? (
                                            <span className="rangecomission">
                                              <Badge className={tour.rangeComissionLabel.toString().toLowerCase()}></Badge>{" "}
                                              {tour.rangeComissionLabel}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                        </h3>
                                        <div className="sub-info">
                                          <div className="d-flex flex-wrap mt-2">
                                            <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].dom === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sun")}</div>
                                            <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].seg === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.mon")}</div>
                                            <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].ter === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.tue")}</div>
                                            <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qua === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.wed")}</div>
                                            <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qui === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.thu")}</div>
                                            <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sex === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.fri")}</div>
                                            <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sab === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sat")}</div>
                                          </div>
                                        </div>
                                        <div className="sub-info">
                                          <small>
                                            {
                                              tour.activityStart === undefined || tour.activityStart === null
                                                ?
                                                ''
                                                :
                                                <span>
                                                  <FontAwesomeIcon
                                                    icon={["fal", "clock"]}
                                                    size="1x"
                                                    className="mr-2"
                                                  />
                                                  {`${t('sales.infoProducts.beginTour')}: ${tour.activityStart}`}
                                                </span>
                                            }
                                          </small>
                                        </div>
                                        <Row className="my-2">
                                          <div className="col-12 col-md-6">
                                            <h5 className="tour-name">{t("sales.tourList.accordion.includes")}</h5>
                                            <div className="session-not-yes yes">
                                              <small>
                                                {tour.includedItems?.map(
                                                  (text: any, index: any) => {
                                                    return (
                                                      <p key={index}>
                                                        <FontAwesomeIcon
                                                          icon={["fal", "check"]}
                                                          size="1x"
                                                        />
                                                        {text.replace("•	", "")}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                              </small>
                                            </div>
                                          </div>
                                          <div className="col-12 col-md-6">
                                            <h5 className="tour-name">{t("sales.tourList.accordion.notIncludes")}</h5>
                                            <div className="session-not-yes not">
                                              <small>
                                                {tour.excludedItems?.map(
                                                  (text: any, index: any) => {
                                                    return (
                                                      <p key={index}>
                                                        <FontAwesomeIcon
                                                          icon={["fal", "times"]}
                                                          size="1x"
                                                        />
                                                        {text.replace("•	", "")}
                                                      </p>
                                                    );
                                                  }
                                                )}
                                              </small>
                                            </div>
                                          </div>
                                        </Row>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3 m-auto text-center d-flex justify-content-center justify-content-md-end">
                                    <div style={{ maxWidth: "300px", width: "100%" }}>
                                      <div className="bg-accordion-options">
                                        <Form>
                                          <Form.Group as={Col}>
                                            <InputGroup
                                              hasValidation
                                              className=""
                                              placeholder="Quando?"
                                              id="date-"
                                              onClick={() => {
                                                addIndex(index);
                                              }}
                                            >
                                              <DoubleCalendarCombo
                                                modalityID={tour.id}
                                                product={tour}
                                                productCode={tourDetails.productCode}
                                                changePriceDateTour={changePriceDateTour}
                                                dateTour={dateTour}
                                                index={index}
                                                isTicket={false}
                                                infoCombo={[]}
                                                setRanges={setRanges}
                                              />
                                            </InputGroup>
                                          </Form.Group>
                                          <div className="col-12 mb-2">
                                            {numberPeople !== null &&
                                              indexador?.includes(index) === true ? (
                                              preloader === false ? (
                                                <div className="panel-dropdown bg-white">
                                                  {
                                                    dateTour[index]?.isFreeSale === 1
                                                      ?
                                                      <DropdownHoursFree
                                                        changePriceDateTour={changePriceDateTour}
                                                        dateTour={dateTour}
                                                        index={index}
                                                        setPeopleDisponible={setPeopleDisponible}
                                                        info={tourDetails}
                                                      />
                                                      :
                                                      <DropdownHoursCombo
                                                        changePriceDateTour={changePriceDateTour}
                                                        dateTour={dateTour}
                                                        index={index}
                                                        setPeopleDisponible={setPeopleDisponible}
                                                        info={tourDetails}
                                                      />
                                                  }

                                                </div>
                                              ) : (
                                                <div className="panel-dropdown bg-white d-flex">
                                                  <div style={{ height: "20px" }}>
                                                    <div className="load"></div>
                                                  </div>
                                                </div>
                                              )
                                            ) : (
                                              <div className="panel-dropdown bg-white d-flex">
                                                <div style={{ height: "20px" }}></div>
                                              </div>
                                            )}
                                          </div>
                                          <div className="col-12 my-2">
                                            <div className="panel-dropdown bg-white">
                                              {
                                                dateTour?.length > 0 && dateTour[index].data !== undefined && dateTour[index].time !== undefined
                                                  ?
                                                  <DropdownNumberPeopleCombo
                                                    actionPeople={setNewNumberPeople}
                                                    info={tourDetails}
                                                    dateTour={dateTour}
                                                    index={index}
                                                    defaultNumberPeople={numberPeople}
                                                    ranges={ranges[ranges.findIndex((elem: any) => elem.tarCode === tour?.tarCode)]}
                                                  />
                                                  :
                                                  <></>
                                              }
                                            </div>
                                          </div>
                                          <span
                                            className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                                            onClick={() => { setIndexCombo((prev: number) => { return (prev + 1) })/* auth(tour, index) */ }}
                                          >

                                            {tourDetails.modalities.length !== indexCombo ?
                                              'Próximo'
                                              :
                                              <>
                                                <FontAwesomeIcon
                                                  icon={["fal", "cart-arrow-down"]}
                                                  size="1x"
                                                  className="mr-2"
                                                />
                                                {t("sales.tourList.accordion.add")}
                                              </>
                                            }
                                          </span>
                                        </Form>
                                      </div>
                                    </div>
                                  </div>
                                </Row>
                              </>
                              :
                              <>
                                <Row>
                                  <div className="col-12 col-md-9">
                                    <div className="d-flex flex-column h-100" style={{ padding: '16px 12px'}}>
                                      <div>
                                        <h4 className="tour-name">
                                          {`${tour.modalityName} - ${tour.productName}`}
                                          {tour.rangeComissionLabel !== null && tour.rangeComissionLabel !== "DEFAULT"
                                            ?
                                            <span className='rangecomission'><Badge className={(tour.rangeComissionLabel).toString().toLowerCase()}></Badge> {tour.rangeComissionLabel}</span>
                                            :
                                            <></>
                                          }
                                        </h4>
                                      </div>
                                      <div className="sub-info">
                                        <div className="d-flex flex-wrap mt-2">
                                          <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].dom === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sun")}</div>
                                          <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].seg === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.mon")}</div>
                                          <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].ter === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.tue")}</div>
                                          <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qua === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.wed")}</div>
                                          <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].qui === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.thu")}</div>
                                          <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sex === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.fri")}</div>
                                          <div style={{ height: 'unset' }} className={tour.comboMarkup[0].markupDays[0].sab === 1 ? "weekDay enable" : "weekDay disable"}>{t("sales.tourList.accordion.weekDays.sat")}</div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-12 col-md-3 m-auto text-center d-flex justify-content-center justify-content-md-end">
                                    {
                                      dateTour?.length > 0 && dateTour[index].data !== undefined && dateTour[index].time !== undefined &&
                                      <>
                                        <div className="d-flex flex-column w-100 align-items-end" style={{ maxWidth: "300px", padding: '5px 22px' }}>
                                          <div className="d-flex justify-content-between w-100">
                                            <div className="d-flex flex-column align-items-center">
                                              <FontAwesomeIcon
                                                icon={["fal", "calendar-alt"]}
                                                size="1x"
                                                style={{ color: "#000" }}
                                                className='text-primary'
                                              />
                                              {dateTour[index].data.split('-').reverse().join('/')}
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                              <FontAwesomeIcon
                                                icon={["fal", "clock"]}
                                                className="size-icon-hours mr-2 text-primary"
                                                style={{ position: 'unset' }}
                                              />
                                              {dateTour[index].time}
                                            </div>
                                            <div className="d-flex flex-column align-items-center">
                                              <FontAwesomeIcon
                                                icon={["fal", "male"]}
                                                size="1x"
                                                className="mr-2 text-primary"
                                              />
                                              {`${numberPeople[index].adults + numberPeople[index].childs + numberPeople[index].infants + numberPeople[index].elders + numberPeople[index].student} Pessoas`}
                                            </div>
                                          </div>
                                          <div className='w-100'>
                                            <span
                                              className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                                              onClick={() => { setIndexCombo(index) }}
                                              style={{ margin: '10px 0 0 0 ' }}
                                            >
                                              Voltar
                                            </span>
                                          </div>
                                        </div>
                                      </>
                                    }
                                  </div>
                                </Row>
                              </>
                          }
                        </div>
                      </>
                    )
                  })}
                  {tourDetails.modalities.length === indexCombo &&
                    <>
                      <Row>
                        <div className="col-12 col-md-12">
                          <div className="d-flex flex-column justify-content-between h-100">
                            <div>
                              {totalPriceCombo !== null &&
                                <>
                                  <span className="small text-muted w-100 d-flex justify-content-end">
                                    Total a pagar:
                                  </span>
                                  <div className="price price-add w-100 d-flex justify-content-end">
                                    <h5>
                                      R${" "}
                                      {(totalPriceCombo).toFixed(2).replace(".", ",")}
                                    </h5>
                                  </div>
                                  <div className='w-100 d-flex justify-content-end'>

                                    <span
                                      className="btn btn-options btn-primary btn-block btn-add-cart link-add"
                                      style={{ maxWidth: '300px' }}
                                      onClick={() => addToCart(tourDetails)}
                                    >
                                      <FontAwesomeIcon
                                        icon={["fal", "cart-arrow-down"]}
                                        size="1x"
                                        className="mr-2"
                                      />
                                      {t("sales.tourList.accordion.add")}
                                    </span>
                                  </div>
                                </>
                              }
                            </div>
                          </div>
                        </div>
                      </Row>
                    </>
                  }
                </div>
              </div>
            </div>
            <div
              className="col-12 col-md-12 text-info-products mt-5"
              id="session-info"
            >
              <div>
                <h4>{t('sales.infoProducts.addInfo')}</h4>
                <div
                  dangerouslySetInnerHTML={{
                    __html: tourDetails.voucherInstructions,
                  }}
                ></div>
              </div>
            </div>
            <div className="row m-0 rounded overflow-hidden my-4">
              <div className="col-12 col-sm-6 col-md- col-lg- col-xl-8 align-items-center d-flex bg-white px-3 py-2">
                <span id="questoes" className="py-0 m-0">
                  {t('sales.infoProducts.code')}?
                </span>
              </div>

              <div className="col-12 col-sm-6 col-xl-4 p-0 bg-primary text-white align-items-center justify-content-center d-flex">
                <span id="product-code">
                  {t('sales.infoProducts.code')}: {tourDetails.productCode}
                </span>
              </div>
            </div>
            <div className="row text-center" id="session-contact">
              <div>
                <div className="col-12 text-center">
                  <p className="my-2">
                    {t('sales.infoProducts.textContact')}
                  </p>
                </div>
              </div>
              <div className="col-12 col-md-4">
                <strong className="d-none">{t('sales.infoProducts.phone')}:</strong>
                <span className="d-block py-2" id="tel-info">
                  {process.env.REACT_APP_INFO_PHONE}
                </span>
                <a
                  href="tel:+552135045730"
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  {t('sales.infoProducts.phone')}
                </a>
              </div>
              <div className="col-12 col-md-4">
                <strong className="d-none">WhatsApp:</strong>
                <span className="d-block py-2" id="whats-info">
                  {process.env.REACT_APP_INFO_WHATSAPP}
                </span>
                <a
                  href={`https://api.whatsapp.com/send?phone=${process.env.REACT_APP_INFO_WHATSAPP}`}
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  Whatsapp
                </a>
              </div>
              <div className="col-12 col-md-4">
                <strong className="d-none">Email:</strong>
                <span className="d-block py-2" id="mail-info">
                  {" "}
                  {process.env.REACT_APP_INFO_EMAIL}
                </span>
                <a
                  href={`mailto:${process.env.REACT_APP_INFO_EMAIL}?subject=Question%20to%20C2Rio&amp;body=Dear%20C2Rio,%0D%0A`}
                  title=""
                  className="btn btn-sm btn-outline-primary w-50"
                >
                  Email
                </a>
              </div>
            </div>
          </Row>
        </Container>
      </div>
    );
  } else {
    return (
      <>
        <div style={{ width: "100%", height: "150%" }}>
          <div className="animated-background" style={{ width: "100%", height: "361px", marginBottom: "10px" }}></div>
          <div className="d-flex justify-content-between" style={{ padding: "0px 42px" }}>
            <div className="d-flex">
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
              <div className="animated-background" style={{ width: "150px", height: "60px", marginRight: "10px" }}></div>
            </div>
            <div className="animated-background" style={{ width: "150px", height: "60px" }}></div>
          </div>
          <div className="d-flex" style={{ padding: "24px 42px", backgroundColor: "#fff" }}>
            <div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
              <div className="animated-background" style={{ width: "300px", height: "198px", marginRight: "15px", marginBottom: "10px" }}></div>
            </div>
            <div>
              <div className="animated-background" style={{ width: "880px", height: "625px" }}></div>
            </div>
          </div>
          <div style={{ backgroundColor: "#fff", height: "300px" }}>
            <div className="animated-background" style={{ width: "50%", height: "20px", marginBottom: "5px" }}></div>
            <div className="animated-background" style={{ width: "100%", height: "100px", marginBottom: "15px" }}></div>
            <div className="animated-background" style={{ width: "50%", height: "20px", marginBottom: "5px" }}></div>
            <div className="animated-background" style={{ width: "100%", height: "100px", marginBottom: "15px" }}></div>
          </div>
        </div>
      </>
    )
  }
}

export default InfoProductsCombo;
