import React from 'react';
import { Card } from "react-bootstrap";

//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';
import { IconCombo } from '../../../assets/icons';

export interface propCard {
    info: any
};

const DetailsCkeckoutCombo: React.FC<propCard> = ({
    info
}: propCard) => {
    const { t } = useTranslation();

    console.log(info)
    return (
        <>
            <Card className="card-unit bg-white">
                <div className="container-fluid">
                    {/* <FontAwesomeIcon
                        className='icon-primary'
                        icon={["fad", "map-marked-alt"]}
                        size="1x"
                        style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                    /> */}

                    <div className='icon-combo-checkout' style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}>
                        {IconCombo}
                    </div>

                    <div className="d-flex justify-content-between">
                        <h4>{info.productName}</h4>
                    </div>
                    {info.comboObject.map((comboObjectElem: any, comboObjectIndex: number) => {
                        return (
                            <>
                                {comboObjectIndex !== 0 && <hr />}
                                <div className="my-2">
                                    <h5 className='details-title'>{comboObjectElem.modalityName}</h5>
                                </div>

                                <div className="details-date d-flex justify-content-between">
                                    <small>{t("checkout.detailsCheckout.date")} {comboObjectElem.date.split('-').reverse().join('/')}</small>
                                    <small>{t("checkout.detailsCheckout.hour")} {comboObjectElem.time}</small>
                                </div>

                                <div className='details-boarding'>
                                    {
                                        comboObjectElem.sellingType === 2
                                            ?
                                            <>
                                                {comboObjectElem.tourEmbarque && <p><strong>{t("checkout.detailsCheckout.boarding")}</strong> {comboObjectElem.tourEmbarque}</p>}
                                                {comboObjectElem.meetingPoint && <p><strong>{t("checkout.detailsCheckout.address")}</strong> {comboObjectElem.meetingPoint}</p>}
                                                {comboObjectElem.tourHoraEmbarque && <p><strong>{t("checkout.detailsCheckout.boardingHour")}</strong> {comboObjectElem.tourHoraEmbarque}</p>}
                                            </>
                                            :
                                            <>
                                                {comboObjectElem.meetingPoint && <p><strong>{t("checkout.detailsCheckout.address")}</strong> {comboObjectElem.meetingPoint}</p>}
                                            </>
                                    }
                                </div>

                            </>
                        )
                    })}


                    <hr />

                    <div className='total-price'>
                        <p><strong>R${(info.price).toFixed(2).replace('.', ',')}</strong></p>
                    </div>
                </div>
            </Card>
        </>
    );

}

export default DetailsCkeckoutCombo;