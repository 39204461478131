/* eslint-disable array-callback-return */
import { createSlice } from '@reduxjs/toolkit'

const counterSlice = createSlice({
  name: 'counter',
  initialState: localStorage.getItem('cartSIG') === null ? {
      Country: "BR",
      agent: 'CPA3120'/* "CLI-XZ18690" */,
      discount: "0",
      foreign: 2,
      language: 1,
      resellerReference: "",
      source: "sig",
      totalCart: 0,
      dados: [],
      payments: [],
      cupom:{
        type: 0
      }

    }
      :
      JSON.parse(localStorage.getItem('cartSIG') || '{}')
  ,
  reducers: {

    addItemCart: (state: any, action) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.dados = [...state.dados, action.payload]
      //if(state?.cupom?.type === 3) {
        state.cupom = {
          type: 0
        }
      //};
      const tempTotalCart = updateTotalCart(state);
      state.totalCart =  tempTotalCart;
      saveToLocalStorage(state);
    },

    updateDadosCart: (state: any, action) => {
      state.dados = action.payload
      const tempTotalCart = updateTotalCart(state);
      state.totalCart =  tempTotalCart;
      saveToLocalStorage(state);
    },

    removeItemCart: (state: any, action) => {
      //console.log(state, action)
      state.dados = state.dados.filter((item: any) => item.productCode !== action.payload.productCode);
      const tempTotalCart = updateTotalCart(state);
      state.totalCart =  tempTotalCart;
      saveToLocalStorage(state);
    },

    removeTotalCart: (state: any, action) => {
      //console.log(state, action);
      state.dados = [];
      const tempTotalCart = updateTotalCart(state);
      state.totalCart =  tempTotalCart;
      saveToLocalStorage(state);
    },

    updateCupomCart: (state: any, action) => {
      // state-= 1
      console.log(action.payload)
      state.cupom = action.payload;

      // state.TourCode = {
      //   code: action.payload.codeName
      // }
      if(action.payload.type !== 3){
        state.dados.forEach((element: any) => {
          element.discount = 0;
          element.discountBool = false;
        });
      }

      const tempTotalCart = updateTotalCart(state);
      state.totalCart =  tempTotalCart;
      saveToLocalStorage(state);
    },

    removeCupomCart: (state: any) => {
      // state-= 1
      state.cupom = {
        type: 0
      };
      const tempTotalCart = updateTotalCart(state);
      state.totalCart =  tempTotalCart;
      saveToLocalStorage(state);
    },

    updateMenu: (state: any, action) => {
      localStorage.setItem("menuState", JSON.stringify(action.payload));
    }
  }
})

export const { addItemCart, updateDadosCart, removeItemCart, removeTotalCart, updateCupomCart, removeCupomCart, updateMenu } = counterSlice.actions

// const store = configureStore({
//   reducer: counterSlice.reducer
// })

// Can still subscribe to the store
// store.subscribe(() => console.log(store.getState()))

// convert object to string and store in localStorage
function saveToLocalStorage(state: any) {
  try {    
    const serialisedState = JSON.stringify(state);
    localStorage.setItem("cartSIG", serialisedState);
    
  } catch (e) {
    console.warn(e);
  }
}

function updateTotalCart(state: any){
  let totalCart = 0;
  try {
    let cartTemp = JSON.stringify(state);
    let cartObj = JSON.parse(cartTemp);
    let priceProduct = 0;
    cartObj.dados.map((cItem: any, index: any) => {      
      if(cItem.sellingType === 1){
        priceProduct = (
          (Number(cItem.priceGlobalPeople))/*  * Number(cItem.globalPeople)) */
        );
      }else{
        priceProduct = (
          (Number(cItem.adults) * Number(cItem.priceAdults))
          + (Number(cItem.childs) * Number(cItem.priceChilds))
          + (Number(cItem.infants) * Number(cItem.priceInfants))
          + (Number(cItem.elders) * Number(cItem.priceElders))
          + (Number(cItem.student) * Number(cItem.priceStudent))
          + (Number(cItem.globalPeople) * Number(cItem.priceGlobalPeople))
      );
      }
      
      if (cItem.isCombo) {
        priceProduct = cItem.price;
      }

      if(cItem.discountBool === true && cartObj.cupom.type === 3){
        priceProduct = priceProduct - ((priceProduct * (cItem.discount / 100)));
      }
      
      totalCart = Number(totalCart ) + (priceProduct );      
      
    });
    if(cartObj.cupom.type === 2){
      totalCart = Number(totalCart ) - (cartObj.cupom.value);
    }else if(cartObj.cupom.type === 4){
      totalCart = Number(totalCart ) - ((totalCart * (cartObj.cupom.value / 100)));
    }
    
    return totalCart;

    
  } catch (e) {
    console.warn(e);
  }

}



// // Infer the `RootState` and `AppDispatch` types from the store itself
// export type RootState = ReturnType<typeof store.getState>
// // Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
// export type AppDispatch = typeof store.dispatch

export default counterSlice;