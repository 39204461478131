/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterAccountsExtract from './components/FilterAccountsExtract/FilterAccountsExtract';
import TableAccountsExtract from './components/TableAccountsExtract/TableAccountsExtract';

import './AccountsExtract.scss';

function AccountsExtract() {

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [billsPay, setBillsPay] = useState<any>(null);
    const [parameters, setParameters] = useState<any>({});
    const [error, setError] = useState<any>();
    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    /* Filtragem */
    const [actualAccount, setActualAccount] = useState<any>("");
    const [actualTypeDate, setActualTypeDate] = useState<any>(2);
    const [actualDate, setActualDate] = useState<any>([todayStr]);

    //console.log(actualDate)

    /* END - Filtragem */

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const [selectedCheck, setSelectedCheck] = useState<any>([]);
    const [itemsToSettle, setItemsToSettle] = useState<any>([]);


    function clearActualValues() {
        setActualAccount("");
        setActualTypeDate(2);
        setActualDate([todayStr]);
    }

    function refreshData() {
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsPay(null);
        }
        setLoading(true);
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function billTopPay() {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            try {
                const { data } = await api.post('/Extract/GetReportAsync',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "conta": actualAccount,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );

                if (data.status !== 400) {
                    setLoading(false);
                    
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "conta": actualAccount,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsPay(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        billTopPay();
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage/* , actualStatus */]);

    async function buscar(typeDate: any, date: any, account: any) {
        console.log(account);
        setBillsPay(null);
        setItemsToSettle([]);
        setSelectedCheck([]);
        setLoading(true);
        setAllowLoadFromFilter(true);
        setActualAccount(account);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setPageCount(1)

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            const res = await api.post('/Extract/GetReportAsync',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "conta": account,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);
                setAllowLoadFromFilter(false);

                setParameters({
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "conta": account,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsPay(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
            setAllowLoadFromFilter(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        try {
            const token = localStorage.getItem('GroupId') || '{}';

            const exp = await api.post('/Extract/ExportReportAsync',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "page": 1,
                    "rowsPerPage": 0,
                    "conta": actualAccount
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"

                    },
                    //responseType: 'blob'
                },

            );
            if (exp.status !== 400) {
                const dados = exp.data.data;

                const objectUrl = `data:${dados.fileType};base64,${dados.file}`;                

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    dados.fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        try {
            const token = localStorage.getItem('GroupId') || '{}';

            const print = await api.post('/Extract/PrintReportAsync',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "page": 1,
                    "rowsPerPage": 0,
                    "conta": actualAccount
                },
                {
                    headers: {
                        "Authorization": `Bearer ${token}`,
                        "Content-Type": "application/json",
                        "Accept": "*/*"
                        //"Access-Control-Allow-Origin": "*"

                    },
                    //responseType: 'blob'
                },

            );
            if (print.status !== 400) {
                const dados = print.data.data;

                const objectUrl = `data:${dados.fileType};base64,${dados.file}`;                

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    dados.fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Extrato'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Extratos</h2>
                    </div>
                    <FilterAccountsExtract
                        buscar={buscar}
                        billsPay={billsPay}
                        error={error}
                        clearActualValues={clearActualValues}
                        allowLoadFromFilter={allowLoadFromFilter}
                    />
                    <TableAccountsExtract
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        loading={loading}
                        refreshData={refreshData}
                        selectedCheck={selectedCheck}
                        setSelectedCheck={setSelectedCheck}
                        itemsToSettle={itemsToSettle}
                        setItemsToSettle={setItemsToSettle}
                    />
                </div>
            </DesktopDefault>
        </>
    )
}

export default AccountsExtract;