import React, { useState } from "react";
import api from '../../../../../services/api';

import { Container, Row, Col } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "../../../../../assets/sass/modal.scss";

export interface propModal {
  action: any,
  info: any,
  setModalSuccess: any,
  setModalResponse: any,
  setModalMessage: any,
  setLog: any
};

const FormBillReceivableItemEdit: React.FC<propModal> = ({
  action, info
  , setModalSuccess, setModalResponse, setModalMessage, setLog
}: propModal, props: any) => {

  function formatValue(cell: any) {
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  }

  const [valorAtual, setValorAtual] = useState<any>(info.valor ? formatValue(info.valor) : 0);
  const [loading, setLoading] = useState<any>(false);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      itemId: info.newItemsId,
      //centroCusto: info.centroCustoId ? info.centroCustoId : null,
      amount: info.valor ? formatValue(info.valor) : '',
    },
  });

  const onSubmitForm = (dataObj: any) => {
    dataObj.amount = valorAtual;
 
    if(dataObj.amount !== '') dataObj.amount = parseFloat(dataObj.amount.replace(',','.'));
    
    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    setLoading(true);
    setModalResponse("loading");
    setModalSuccess(true);

    const salvar = async () => {
      try {
        const { data } = await api.post('BillsReceivable/UpdateItemAmountAsync', dataObj, config);
        if (data.status !== 400) {
          var response = data.data;

          setModalMessage(response.texto);
          setLog(response.log);

          if(response.log !== 1) {
            setModalResponse("success");
            action(data);
          } else {
            setModalResponse("error");
          }
        }   
      }catch(error: any) {
        setModalMessage("Erro, tente novamente mais tarde");
        setLog(1);
        setModalResponse("error");
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
      }

      setLoading(false);
    }
    salvar();
  };

  function handleValorChange(e: any) {
    const newValue = e.target.value;
    setValorAtual(newValue);
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  /* function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    return (
      <>
        {date}
      </>
    );
  } */

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Alterar Item</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="mb-3">
                  <Form.Group as={Col} md="3">
                    <Form.Label>Data:</Form.Label>
                    <Form.Control 
                      type="date" 
                      value={info.dataOperacao?.substring(0,10)} 
                      disabled
                      autoComplete='off'
                       />
                  </Form.Group>
                  <Form.Group as={Col} md="8">
                    <Form.Label>Serviço:</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={info.tour} 
                      autoComplete='off'
                      disabled />
                  </Form.Group>
                </Row>
                <Row className="mb-3">
                  <Form.Group as={Col} md="3">
                    <Form.Label>Valor:</Form.Label>
                      <Controller
                        control={control}
                        name="amount"
                        //rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            aria-invalid={errors?.amount ? "true" : ""}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required
                            onChange={handleValorChange}
                            value={valorAtual}
                          />
                        )}
                      />
                    </Form.Group>
                  <Form.Group as={Col} md="2">
                    <Form.Label>Nº:</Form.Label>
                    <Form.Control 
                      type="text" 
                      autoComplete='off'
                      value={info.qtdePax} 
                      disabled />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>Centro Custo:</Form.Label>
                    <Form.Control 
                      type="text" 
                      value={info.centroCusto} 
                      autoComplete='off'
                      disabled />
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {loading !== true ?
            <Button
              type="button"
              className="form-button"
              onClick={handleSubmit(onSubmitForm)}
            >
              <FontAwesomeIcon
                icon={["fal", "save"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Salvar 
            </Button>
          :
            <Button
              type="button"
              className="form-button"
              disabled={true}
            >
              <FontAwesomeIcon
                icon={["fal", "save"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Salvar 
            </Button>
          }
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormBillReceivableItemEdit;
