import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './SessionBilling.scss'
import Button from "react-bootstrap/Button";

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';

import TableSessionBillingBillsReceive from '../components/TableSessionBillingBillsReceive/TableSessionBillingBillsReceive';
import TableSessionBillingBillsPay from '../components/TableSessionBillingBillsPay/TableSessionBillingBillsPay';
import ModalQuestion from '../../../components/Modal/ModalQuestion/ModalQuestion';
import DesktopDefault from '../../../templates/DesktopDefault';
import { Card, Modal, Row } from 'react-bootstrap';
import ModalSessionMessages from '../../../components/Modal/ModalSessionMessages/ModalSessionMessages';


function SessionBilling() {
    //const { t } = useTranslation();
    const [billsReceive, setBillsReceive] = useState<any>(null);
    const [billsPay, setBillsPay] = useState<any>(null);
    //const [error, setError] = useState<any>();

    /* Pagination */
    //const [totalRows, setTotalRows] = useState<any>();
    const pageCount: any = 1; //const [pageCount, setPageCount] = useState<any>(1);
    const rowsPerPage: any = 10; //const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    /* Filter */
    const [sessionId, setSessionId] = useState<any>(null);
    const [sessionType, setSessionType] = useState<any>(1);
    /* END - Filter */

    /* session data */
    const [sessionReloc, setSessionReloc] = useState<any>('');
    const [sessionDesc, setSessionDesc] = useState<any>('');
    const [sessionDate, setSessionDate] = useState<any>('');
    //const [sessionOperationType, setSessionOperationType] = useState<any>(1);
    /* END - session data */

    /* totais */
    const [totalRecebimentos, setTotalRecebimentos] = useState<any>(0);
    const [totalPagamentos, setTotalPagamentos] = useState<any>(0);
    const [totalMargem, setTotalMargem] = useState<any>(0);
    const [taxaMargem, setTaxaMargem] = useState<any>(0);
    /* END - totais */

    /* Success Modal (Inserir) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    /* END - Success Modal (Inserir) */

    const [issueInvoiceModalShow, setIssueInvoiceModalShow] = React.useState(false);
    const [issueInvoiceModal2Show, setIssueInvoiceModal2Show] = React.useState(false);
    const [issueInvoiceModal3Show, setIssueInvoiceModal3Show] = React.useState(false);

    const [exportERPModalShow, setExportERPModalShow] = React.useState(false);

    const [monthClosed, setMonthClosed] = React.useState(false);

    const [loading, setLoading] = useState<any>(false);

    const [sessionMessagesShow, setSessionMessagesShow] = useState<any>(false);
    const [sessionMessageList, setSessionMessageList] = useState<any>([]);


    function calcula(pBillsReceive: any, pBillsPay: any) {
        var recebe = 0;
        var paga = 0;

        if(pBillsReceive) {
            pBillsReceive.forEach((e: any) => {
                recebe += e.valor;
            });
        }
        
        if(pBillsPay) {
            pBillsPay.forEach((e: any) => {
                paga += e.valor;
            });
        }
        
        const margem = recebe - paga;
        const taxaMargem = recebe !== 0 ? margem / recebe : 0;

        setTotalRecebimentos(recebe);
        setTotalPagamentos(paga);
        setTotalMargem(margem);
        setTaxaMargem((taxaMargem) * 100);
    }

    async function getIsMonthClosed(sessionId: any, sessionType: any) {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        //const month = data.substring(5,7);
        //const year = data.substring(0,4);

        try {
            const { data } = await api.post('SessionClosure/IsSessionClosedAsync',
                {
                    //"month": parseInt(month),
                    //"year": parseInt(year),
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );

            if (data.status !== 400) {
                const dados = data.data;

                setMonthClosed(dados === 1);
                //console.log("obj ", dados);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    const getSessionMessages = async (sessionId: any) => {
        const token = localStorage.getItem('GroupId') || '';
    
        const config = {
          headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
    
            const { data } = await api.post(`Manifest/GetSessionMessagesAsync`, {
              "sessionId": sessionId
            }, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;
    
                setSessionMessageList(response);
                if(response.length > 0) setSessionMessagesShow(true);
            }   
        }catch(error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';              
            }
            if (error?.response?.status === 400) {
                //window.location.href = window.location.origin + '/session-closure';              
            }
            //;
        }
      }

    useEffect(() => {
        const session = window.location.href.split('session=')[1].replace('#', '');

        const sessionId = session.substring(1);

        const sessionTypeTemp = session.substring(0,1);

        const sessionTypeTemp2 = (sessionTypeTemp === 'S')? 1 : 2;

        setSessionId(sessionId);
        setSessionType(sessionTypeTemp2);
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        async function listSuppliers() {
            setLoading(true);

            if(sessionTypeTemp2 === 1) {
                await getSessionMessages(sessionId);
            }

            var billsReceiveT = billsReceive;
            var billsPayT = billsPay;
            try {
                const { data } = await api.post('SessionClosure/GetSessionBillsToReceive',
                    {
                        "page": 1,
                        "rowsPerPage": 0,
                        "sessionId": sessionId,
                        "sessionType": sessionTypeTemp2
                    }, config
                );

                if (data.status !== 400) {
                    const dados = data.data;
                    billsReceiveT = dados.rows;

                    //var monthClosed = 
                    await getIsMonthClosed(sessionId, sessionTypeTemp2);

                    setBillsReceive(billsReceiveT);
                    //setSessionId(dados.sessionId);
                    setSessionReloc(dados.sessionReloc);
                    setSessionDesc(dados.descricao);
                    setSessionDate(dados.data);

                    //if(monthClosed === false)
                    //    setMonthClosed(dados.statusErp === 2);
                    //setSessionOperationType(dados.operationType);
                    //(dados.rowsCount);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }

            try {
                const { data } = await api.post('SessionClosure/GetSessionBillsToPay',
                    {
                        "page": 1,
                        "rowsPerPage": 0,
                        "sessionId": sessionId,
                        "sessionType": sessionTypeTemp2
                    }, config
                );
    
                if (data.status !== 400) {
                    const dados = data.data.rows;
                    billsPayT = [];

                    for(var i = 0; i < dados.length; i++) {
                        if(dados[i].statusPagamento !== 1 && dados[i].operacional === 1) {
                            billsPayT.push(dados[i]);
                        }
                    }

                    setBillsPay(billsPayT);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
            calcula(billsReceiveT, billsPayT);
            setLoading(false);
        }
        
        listSuppliers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
//console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
          let link = document.createElement('a');
          link.href = blob;//window.URL.createObjectURL(blob);
          link.download = filename;
          document.body.appendChild(link);
          link.target = "_blank";
          link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
          link.remove();
          window.URL.revokeObjectURL(link.href);
        //}
    };

    async function refreshData() {
        setLoading(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        var billsReceiveT = billsReceive;
        var billsPayT = billsPay;

        try {
            const { data } = await api.post('SessionClosure/GetSessionBillsToReceive',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );

            if (data.status !== 400) {
                billsReceiveT = data.data.rows;
                setBillsReceive(billsReceiveT);
                setSessionDesc(data.data.descricao);
                setSessionDate(data.data.data);
                //setSessionOperationType(data.data.operationType);
                //setTotalRows(data.data.rowsCount);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }

        try {
            const { data } = await api.post('SessionClosure/GetSessionBillsToPay',
                {
                    "page": 1,
                    "rowsPerPage": 0,
                    "sessionId": sessionId,
                    "sessionType": sessionType
                }, config
            );

            if (data.status !== 400) {
                const dados = data.data.rows;
                billsPayT = [];

                for(var i = 0; i < dados.length; i++) {
                    // eslint-disable-next-line
                    if(dados[i].statusPagamento != 1) {
                        billsPayT.push(dados[i]);
                    }
                }

                setBillsPay(billsPayT);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }

        calcula(billsReceiveT, billsPayT);
        setLoading(false);
    }

    async function exportReport(report: any) {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        var path = 'ExportBillingReportAsync';
        if(report === 2) path = 'ExportBillingContaAzulReportAsync';

        try {
            const res = await api.post(`SessionClosure/${path}`,
                {
                    "date": sessionDate
                }, config
            );
            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printReport() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/PrintBillingReportAsync',
                {
                    "date": sessionDate
                }, config
            );
            if (res.status !== 400) {
                //download file
                //console.log(res.data.data);
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    /* function exportERP() {
        setExportERPModalShow(true);
    } */

    async function exportERP1() {
        setExportERPModalShow(false);
        setModalContent("loading");
        setModalSuccess(true);
     
        let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
        const user = userData.user;

        const uid = user.uId;
        const usuario = `${user.nome} ${user.lastName}`;

        const token = localStorage.getItem('GroupId') || '';

        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/ExportSessionToErpAsync',
                {
                    "sessionId": sessionId,
                    "sessionType": sessionType,
                    "uid": uid,
                    "usuario": usuario
                }, config
            );
            if (res.status !== 400) {
                const dados = res.data.data;

                //alert(dados.texto);
                setResponseText(dados.texto);
                setLog(dados.log);

                if(dados.log !== 1) {
                    //refreshData();
                    setModalContent("success");
                } else {
                    setModalContent("error");
                }
            }
        } catch (error: any) {
            setModalContent("error");
            setLog(1);
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    function issueInvoiceInBath() {
        var tour_dat = new Date(sessionDate.substring(0,4), parseInt(sessionDate.substring(5,7))-1, sessionDate.substring(8,10));//new Date(data_tour);
        var hoje = new Date();
        hoje.setHours(0,0,0,0);
        
        if(tour_dat < hoje && tour_dat.getMonth() < hoje.getMonth()) {
            //console.log('menor');
            setIssueInvoiceModalShow(true);
        } else {
            //console.log('n menor');
            issueInvoiceInBath1();
        }
    }

    function issueInvoiceInBath1() {
        setIssueInvoiceModalShow(false);

        var receita = 0;

        for(var i = 0; i < billsReceive.length; i++) {
          receita += billsReceive[i].valorReceita ? billsReceive[i].valorReceita : billsReceive[i].valor * (taxaMargem/100);
        }
        
        if(receita < totalMargem) {
            setIssueInvoiceModal2Show(true);
        } else {
            issueInvoiceInBath2();
        }
    }

    function issueInvoiceInBath2() {
        setIssueInvoiceModal2Show(false);

        setIssueInvoiceModal3Show(true);
    }

    async function issueInvoiceInBath3(notaCliente: any) {
        setIssueInvoiceModal3Show(false);
        setModalContent("loading");
        setModalSuccess(true);
     
        let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
        const user = userData.user;

        const uid = user.uId;
        const usuario = `${user.nome} ${user.lastName}`;

        const token = localStorage.getItem('GroupId') || '';

        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('SessionClosure/IssueInvoiceInBathAsync',
                {
                    "sessionId": sessionId,
                    "sessionType": sessionType,
                    "notaCliente": notaCliente,
                    "uid": uid,
                    "usuario": usuario
                }, config
            );
            if (res.status !== 400) {
                const dados = res.data.data;

                //alert(dados.texto);
                setResponseText(dados.texto);
                setLog(dados.log);

                if(dados.log !== 1) {
                    //refreshData();
                    setModalContent("success");
                } else {
                    setModalContent("error");
                }
            }
        } catch (error: any) {
            setModalContent("error");
            setLog(1);
            //setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    /*converte a data UTC para a data local do usuário*/
    function convertUTCDateToLocalDate(date: any) {
        var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

        //var offset = date.getTimezoneOffset() / 60;
        //var hours = date.getHours();

        //newDate.setHours(hours - offset);

        return newDate.toLocaleString().split(' ')[0];
    }

    function convertDate(cell: any) {
        var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
        /* console.log(date) */
        return (
        <>
            {date}
        </>
        );
    }

    function convertValue(cell: any) {
        //console.log(Math.sign(cell))
        return (
        <>
            <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
        </>
        )
    }

    //if (billsReceive !== null && billsPay !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard session-billing">
                        <Breadcrumb title={'Financeiro / Fechamento / Faturamento'} />
                        <div className="suppliers mt-4 justify-content-between align-items-center d-flex">
                            <h2 className="title" style={{ color: "#707070" }}>{`Session - ${sessionReloc} - ${sessionDesc}`}</h2>
                            <h3 style={{color: "#c0c0c0" }}>Data Tour - {convertDate(sessionDate)}</h3>
                        </div>
                        <div style={{ padding: "20px 10px 0px" }}>
                            <div className="d-flex">
                                {/* <div className="px-2">
                                    <button className="btn btn-b-bills" onClick={(e:any) => issueInvoiceInBath()}>
                                        <FontAwesomeIcon
                                        icon={["fal", "file-invoice"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Emitir Nota Fiscal em Lote
                                    </button>
                                </div> */}
                                <div className="px-2">
                                    <button className="btn btn-b-bills" onClick={(e:any) => exportReport(1)}>
                                        <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Exportar
                                    </button>
                                </div>
                                {/* <div className="px-2">
                                    <button className="btn btn-b-bills" onClick={(e:any) => exportReport(2)}>
                                        <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Exportar Conta Azul
                                    </button>
                                </div> */}
                                {/* <div className="px-2">
                                    <button className="btn btn-b-bills" onClick={(e:any) => exportERP()}>
                                        <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Exportar ERP
                                    </button>
                                </div> */}
                                <div className="px-2">
                                    <button className="btn btn-b-bills" onClick={(e:any) => printReport()}>
                                        <FontAwesomeIcon
                                        file-invoice
                                        icon={["fal", "print"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                        />
                                        Imprimir
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Row className="cards">
                            <div className="col-md-2 card-unit">
                                <Card>
                                    <div className="col-md-10">
                                        <Row>
                                            <span className='card-title'>
                                                Total Recebimentos
                                            </span>
                                        </Row>
                                        <Row>
                                            <span className='cart-content'>
                                                {totalRecebimentos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </span>
                                        </Row>
                                    </div>
                                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={["fad", "receipt"]}
                                            className="text-primary p-1"
                                            size="3x"
                                        />
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-2 card-unit">
                                <Card>
                                    <div className="col-md-10">
                                        <Row>
                                            <span className='card-title'>
                                                Total Pagamentos
                                            </span>
                                        </Row>
                                        <Row>
                                            <span className='cart-content'>
                                                {totalPagamentos.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </span>
                                        </Row>
                                    </div>
                                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={["fad", "hand-holding-usd"]}
                                            className="text-primary p-1"
                                            size="3x"
                                        />
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-2 card-unit">
                                <Card>
                                    <div className="col-md-10">
                                        <Row>
                                            <span className='card-title'>
                                                Margem
                                            </span>
                                        </Row>
                                        <Row>
                                            <span className='cart-content'>
                                                {totalMargem.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}
                                            </span>
                                        </Row>
                                    </div>
                                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={["fad", "dollar-sign"]}
                                            className="text-primary p-1"
                                            size="3x"
                                        />
                                    </div>
                                </Card>
                            </div>
                            <div className="col-md-2 card-unit">
                                <Card>
                                    <div className="col-md-10">
                                        <Row>
                                            <span className='card-title'>
                                                Margem / Receita (%)
                                            </span>
                                        </Row>
                                        <Row>
                                            <span className='cart-content'>
                                                {convertValue(taxaMargem)}
                                            </span>
                                        </Row>
                                    </div>
                                    <div className="col-md-2 d-flex justify-content-center align-items-center">
                                        <FontAwesomeIcon
                                            icon={["fad", "chart-line"]}
                                            className="text-primary p-1"
                                            size="3x"
                                        />
                                    </div>
                                </Card>
                            </div>
                            {/* <div className="card px-2 mr-2">
                                <div className="card-body p-2">
                                    <div className="text-xs font-weight-bold text-uppercase mb-1">Total Recebimentos</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {convertValue(totalRecebimentos)}</div>
                                </div>
                            </div> */}
                            {/* <div className="card px-2 mr-2">
                                <div className="card-body p-2">
                                    <div className="text-xs font-weight-bold text-uppercase mb-1">Total Pagamentos</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {convertValue(totalPagamentos)}</div>
                                </div>
                            </div> */}
                            {/* <div className="card px-2 mr-2">
                                <div className="card-body p-2">
                                    <div className="text-xs font-weight-bold text-uppercase mb-1">Margem</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">R$ {convertValue(totalMargem)}</div>
                                </div>
                            </div> */}
                            {/* <div className="card px-2">
                                <div className="card-body p-2">
                                    <div className="text-xs font-weight-bold text-uppercase mb-1">Margem / Receita (%)</div>
                                    <div className="h5 mb-0 font-weight-bold text-gray-800">{convertValue(taxaMargem)}</div>
                                </div>
                            </div> */}
                        </Row>
                        <TableSessionBillingBillsReceive
                            bills={billsReceive}
                            dataOperacao={sessionDate}
                            refreshData={refreshData}
                            issueInvoiceInBath={issueInvoiceInBath}  
                            monthClosed={monthClosed}
                            setModalSuccess={setModalSuccess}
                            setModalContent={setModalContent}
                            setResponseText={setResponseText}
                            setLog={setLog}
                            loading={loading}
                            taxaMargem={taxaMargem}
                        />
                        <TableSessionBillingBillsPay
                            bills={billsPay}
                            loading={loading}
                        />

                        <ModalQuestion 
                            modalShow={issueInvoiceModalShow}
                            setModalShow={setIssueInvoiceModalShow}
                            titulo="Emitir Nota Fiscal"
                            mensagem="Data com mes anterior ao da data corrente, Continua?"
                            handleActionConfirmModal={(e: any) => issueInvoiceInBath1()}
                            //handleActionCancelModal={handleActionCancelDeleteClick}
                        />

                        <ModalQuestion 
                            modalShow={issueInvoiceModal2Show}
                            setModalShow={setIssueInvoiceModal2Show}
                            titulo="Emitir Nota Fiscal"
                            mensagem="A soma das receitas e inferior a margem , continua?"
                            handleActionConfirmModal={(e: any) => issueInvoiceInBath2()}
                            //handleActionCancelModal={handleActionCancelDeleteClick}
                        />

                        <ModalQuestion 
                            modalShow={exportERPModalShow}
                            setModalShow={setExportERPModalShow}
                            titulo="Exportar para ERP"
                            mensagem="Confirma que verificou todos os dados e que estão corretos ?"
                            handleActionConfirmModal={(e: any) => exportERP1()}
                            //handleActionCancelModal={handleActionCancelDeleteClick}
                        />


                        <ModalQuestion 
                            modalShow={issueInvoiceModal3Show}
                            setModalShow={setIssueInvoiceModal3Show}
                            titulo="Emitir Nota Fiscal"
                            mensagem="Nota emitida contra (P)assageiro ou (C)liente ?"
                            buttons={
                            <>
                                <Button className="form-button mr-3" onClick={(e:any) => issueInvoiceInBath3(2)}>
                                Passageiro
                                </Button>
                                <Button className="form-button mr-3" onClick={(e:any) => issueInvoiceInBath3(1)}>
                                Cliente
                                </Button>
                            </>
                            }
                            //handleActionConfirmModal={(e: any) => issueInvoice2(invoiceToIssue,2)}
                            //handleActionCancelModal={handleActionCancelDeleteClick}//1=cliente;2=passageiro
                        />
                        {/* Modal de Success */}
                        <Modal
                        className={"modal-confirm loading-modal"}
                        show={modalSuccess}
                        onHide={() => setModalSuccess(false)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                        >
                        <>
                            <Modal.Body className='modal-body text-center sucess-pos'>
                            {
                                modalContent !== "loading"
                                ?
                                <>
                                    <div>
                                    {
                                        log === 2 || log === "2"
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", "exclamation-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                        :
                                        log === 1 || log === "1"
                                            ?
                                            <FontAwesomeIcon
                                            icon={["fal", "times-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                            />
                                            :
                                            log === 0 || log === "0"
                                            ?
                                            <FontAwesomeIcon
                                                icon={["fal", "check-circle"]}
                                                size="5x"
                                                className="text-primary"
                                                style={{ fontSize: "7.5em" }}
                                            />
                                            :
                                            <FontAwesomeIcon
                                                icon={["fal", "question-circle"]}
                                                size="5x"
                                                className="text-primary"
                                                style={{ fontSize: "7.5em" }}
                                            />
                                    }
                                    </div>
                                    <div>
                                    {responseText}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                    {
                                        modalContent === 'success'
                                        ?
                                        <button
                                            onClick={() => {refreshData(); setModalSuccess(false);}}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                        :
                                        <button
                                            onClick={() => setModalSuccess(false)}
                                            className="btn btn-primary mx-2 w-25"
                                        >
                                            Confirmar
                                        </button>
                                    }
                                    </div>
                                </>
                                :
                                <div className="modal-body">
                                    <div className="text-center">
                                    <div className="load"></div>
                                    </div>
                                    <div>
                                    <span>Processando</span>
                                    </div>
                                    <div></div>
                                </div>
                            }
                            </Modal.Body>
                        </>
                        </Modal>
                        {/* END - Modal de Success */}

                        {/* modal mensagens session */}
                        <Modal
                            className={"modal-confirm loading-modal"}
                            show={sessionMessagesShow}
                            onHide={() => setSessionMessagesShow(false)}
                            size="lg"
                            aria-labelledby="contained-modal-title-vcenter"
                        >
                            <>
                                <Modal.Header className="p-3" closeButton></Modal.Header>
                                <ModalSessionMessages
                                sessionId={sessionId}
                                show={setModalSuccess}
                                setResponseText={setResponseText}
                                setModalContent={setModalContent}
                                setLog={setLog}
                                messageList={sessionMessageList}
                                getSessionMessages={getSessionMessages}
                                />
                            </>
                        </Modal>    
                        {/* END - modal mensagens session */}

                    </div>
                </DesktopDefault>
            </>
        );
    // } else {
    //     return (
    //         <>
    //         </>
    //     )
    // }
}

export default SessionBilling;