/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from "react";
import api from '../../../../../../services/api';
import { Container, Row, Col } from "react-bootstrap";

import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import ModalQuestion from "../../../../../../components/Modal/ModalQuestion/ModalQuestion";

import "../../../../../../assets/sass/modal.scss";
//import SingleCalendarEditable from "../../../../Calendar/SingleCalendarEditable";

export interface propModal {
  action: any,
  info: any,
  accountId: any,
  setModalSuccess: any,
  setModalResponse: any,
  setModalMessage: any,
  setLog: any
};

const FormBillReceivableSettle: React.FC<propModal> = ({
  action, info, accountId
  , setModalSuccess, setModalResponse, setModalMessage, setLog
}: propModal, props: any) => {

  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  function formatValue(cell: any) {
    if(cell && cell !== '') {
      return cell.toFixed(2).replace(".", ",");
    } else {
      return '0,00';
    }
    
  }

  const [loading, setLoading] = useState<any>(false);

  const [billConciliateModalShow, setBillConciliateModalShow] = useState<any>(false);
  const [billToConciliate, setBillToConciliate] = useState<any>(null);
  const [conciliateMsg, setConciliateMsg] = useState<any>('');

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: info ? info.id : 0,
      conta: accountId,

      vencimento: info && info.vencimento ? info.vencimento.substring(0,10) : '',

      observacao: info ? info.observacao : '',
    },
  });

  const onSubmitForm = (dataObj: any) => {

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    setLoading(true);
    setModalResponse("loading");
    setModalSuccess(true);

    const salvar = async () => {
      try {
        const { data } = await api.post('Extract/SettleBillsReceivable', dataObj, config);
        if (data.status !== 400) {
          var response = data.data;

          //alert(response.texto);
          setModalMessage(response.texto);
          setLog(response.log);

          if(response.log !== 1) {
            setModalResponse("successNoRefresh");
            action(data);
          } else {
            setModalResponse("error");
          }
        }   
      }catch(error: any) {
        setModalMessage("Erro, tente novamente mais tarde");
        setLog(1);
        setModalResponse("error");
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';              
        }
        if (error?.response?.status === 400) {
          //window.location.href = window.location.origin + '/session-closure';              
        }
      }

      setLoading(false);
    }
    salvar();
  };

  const onConciliate = (data: any) => {

    const agora = new Date();
    const agoraStr = convertUTCDateToLocalDate(agora);

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    const usuario = `${user.nome} ${user.lastName}`;

    setConciliateMsg('"Liquidado por '+usuario+' em '+agoraStr+'" confirma?');
    setBillToConciliate(data);
    setBillConciliateModalShow(true);
  };

  function handleActionBillConciliateModal(e: any) {
    setBillConciliateModalShow(false);

    billToConciliate.conciliate = 1;

    onSubmitForm(billToConciliate);
  }

  useEffect(() => {
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  /*converte a data UTC para a data local do usuário*/
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    //var offset = date.getTimezoneOffset() / 60;
    //var hours = date.getHours();

    //newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  /* function convertDate(cell: any) {
    var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    return (
      <>
        {date}
      </>
    );
  } */

  if(info !== null) {
    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row>
              <h5 className="contact-title mb-5">Liquidar Recebimento</h5>
              <Form
                className="form-add-contact"
                noValidate
              >
                <div className="">
                  <Row className="mb-3">
                    <Form.Group as={Col} md="3">
                      <Form.Label>Vencimento:</Form.Label>
                        {/* <SingleCalendarEditable setValue={setVencimento} date={vencimento} /> */}
                      <Controller
                        control={control}
                        name="vencimento"
                        rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                        render={({ field }: any) => (
                          <Form.Control type="date"
                            {...field}
                            aria-invalid={errors?.vencimento ? "true" : ""}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required />
                        )}
                      />
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                      <Form.Label>Observação:</Form.Label>
                      <Controller
                        control={control}
                        name="observacao"
                        render={({ field }: any) => (
                          <Form.Control type="text"
                            {...field}
                            autoComplete='off'
                            variant="standard"
                            
                            margin="normal"
                            required />
                        )}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Form>

              <ModalQuestion
                modalShow={billConciliateModalShow}
                setModalShow={setBillConciliateModalShow}
                titulo="Liquidar Pagamento"
                mensagem={conciliateMsg}
                handleActionConfirmModal={handleActionBillConciliateModal}
                //handleActionCancelModal={handleActionCancelDeleteClick}
              />
            </Row>
          </Container>
        </Modal.Body>

        <Modal.Footer>
          <Row className="buttons-modal d-flex justify-content-end mx-0">
            <Button
              type="button"
              className="btn btn-primary px-4 mr-3 text-uppercase"
              onClick={handleSubmit(onConciliate)}
              disabled={loading===true}
            >
              <FontAwesomeIcon
                icon={["fal", "check-circle"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Liquidar
            </Button>
          </Row>
        </Modal.Footer>


      </>
    );
  } else {
    return (<></>);
  }
}

export default FormBillReceivableSettle;
