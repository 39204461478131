/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

//STYLES
import "./FinancialModalBillToPayTable.scss";
import { Dropdown, Form, Modal } from "react-bootstrap";
import api from "../../../../services/api";
import FinancialModalBillToPayModal from "../FinancialModalBillToPayModal/FinancialModalBillToPayModal";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
//import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
//import FormAddFeeTerm from "../../../../../components/Forms/FormAddFeeTerm/FormAddFeeTerm";


export interface propModal {
  info: any,
  fileId: any,
  setModalSuccess: any,
  setResponseText: any,
  setModalContent: any,
  setLog: any,
  loading: any
};

const FinancialModalBillToPayTable: React.FC<propModal> = ({
  info, fileId, setModalSuccess, setResponseText, setModalContent, setLog, loading
}: propModal) => {
  const { t } = useTranslation();

  const [billEditModalShow, setBillEditModalShow] = useState<any>(false);
  const [billDeleteModalShow, setBillDeleteModalShow] = useState<any>(false);
  const [billEdit, setBillEdit] = useState<any>(null);

  function removeRow(row: any) {
    // confirma antes de excluir
    setBillEdit(row);
    setBillDeleteModalShow(true);
  } 

  function handleActionBillDeleteModal(e: any) {
    // do delete
    setBillDeleteModalShow(false);
    setModalContent('loading');
    setModalSuccess(true);
    
    async function excluir() {
      try {
        const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

        const userName: any = tourAuth.user.nome;
        const userLastName: any = tourAuth.user.lastName;
        const uId: any = tourAuth.user.uId;

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
          headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        const { data } = await api.post('BillsPayable/DeleteBill',
          {
            "id": billEdit.manifestId,
            "tipo": 3,
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );

        if (data.status !== 400) {
          const dados = data.data;

          //alert(dados.texto);
          setResponseText(dados.texto);
          setLog(dados.log);

          if (dados.log !== 1) {
            setBillDeleteModalShow(false);
            setBillEdit(null);
            setModalContent("success");
            //refreshData();
          } else {
            setModalContent("error");
          }
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }

    excluir();
  }

  function editRow(row: any) {
    // chama modal
    setBillEdit(row);
    setBillEditModalShow(true);
  }
  
  
  function handleActionBillEditModal(data: any) {
    setBillEdit(null);
    setBillEditModalShow(false);
    //refreshData();
  }

  function handleActionBillEditHideModal() {
    //() => setBillEditModalShow(false)
    setBillEditModalShow(false);
    setBillEdit(null);
  }

  function convertDate(cell: any) {
    if(!cell) return '';
    //var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }


  return (
    <>
      <div className="table-add-default groupfile-cap-table">
        <div className="table-container">
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <div className="table-title mb-3">
            <h3>Pagamentos</h3>
          </div>
          <Table striped bordered>
            <thead>
              <tr>
                <th>Data</th>
                <th>Fornecedor</th>
                <th>Descrição</th>
                <th>Valor</th>
                <th>Vencimento</th>
                <th>Observação</th>
                <th></th>
                <th>Ações</th>
              </tr>
            </thead>
            <tbody>
              {loading === false
                ? info.length > 0
                ? info.map((item: any, index: Key) => (
                  <tr key={index}>
                    <td>{convertDate(item.date)}</td>
                    <td>{item.supplierName}</td>
                    <td>{item.description}</td>
                    <td>{convertValue(item.amount)}</td>
                    <td>{convertDate(item.dueDate)}</td>
                    <td>{item.observation}</td>
                    <td>
                        {
                          item.paymentStatus === 2 ?
                            <FontAwesomeIcon
                              icon={["fal", "check"]}
                              size="1x"
                              style={{ marginRight: "1px" }}
                              className="fg-blue"
                            />
                          : item.paymentStatus === 3 || item.paymentStatus === 4 ?
                            <FontAwesomeIcon
                              icon={["fal", "check"]}
                              size="1x"
                              style={{ marginRight: "1px" }}
                              className="fg-ativo"
                            />
                          : <></>
                        }
                    </td>
                    <td>
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={['fal', 'ellipsis-h']}
                            />
                          </Dropdown.Toggle>

                          <Dropdown.Menu>
                            <Dropdown.Item
                              onClick={() => editRow(item)}
                              eventKey="1"
                            >
                              <FontAwesomeIcon
                                icon={['fal', 'edit']}
                              //className="mr-2"
                              />
                              {t('suppliersTable.edit')}
                            </Dropdown.Item>
                            <Dropdown.Item
                              onClick={() => removeRow(item)}
                              eventKey="2"
                              className="text-danger removeRow">
                              <FontAwesomeIcon
                                icon={['fal', 'trash-alt']}
                                //className="mr-2"
                              />
                              {t('suppliersTable.remove')}
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                      </div>
                    </td>
                  </tr>
                ))
                : <tr className="d-flex justify-content-center align-items-center">Nenhum pagamento</tr>
                : <tr><td className="animated-background row-loading"></td></tr>
              }
              {/* <div className="add-contact">
                <div className="text-center btn-add-contact">
                  <span className='btn' style={{ height: "40px" }} onClick={(e: any) => addRow()}>
                    <FontAwesomeIcon
                      size="lg"
                      icon={["fal", "plus"]}
                      style={{ color: "#707070", margin: "0 12px" }}
                    />
                  </span>
                </div>
              </div> */}
            </tbody>
          </Table>

          <Modal
            className="modalReceber"
            show={billEditModalShow}
            onHide={handleActionBillEditHideModal}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header className="p-3" closeButton></Modal.Header>
              <FinancialModalBillToPayModal 
                action={handleActionBillEditModal} 
                info={billEdit} 
                fileId={fileId}
                show={setModalSuccess}
                setModalContent={setModalContent}
                setResponseText={setResponseText}
                setLog={setLog}
              />
            </>
          </Modal>

          <ModalQuestion
            modalShow={billDeleteModalShow}
            setModalShow={setBillDeleteModalShow}
            titulo="Excluir Recebimento"
            mensagem="Deseja excluir o Recebimento?"
            handleActionConfirmModal={handleActionBillDeleteModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />

        </div>
      </div>
    </>
  );
}

export default FinancialModalBillToPayTable;
