import React, { useState, Key, useRef, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { version } from '../../../package.json';

import './Menu.scss';
import objectMenu from './locales/menu.json';
import objectMenuEn from './locales/menu.en.json';
import objectMenuEs from './locales/menu.es.json';

// import logo from `../../assets/img/logo-c2tours.svg`;

import MenuPrimary from './MenuPrimary/MenuPrimary';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import i18next from 'i18next';
//import { useTranslation } from "react-i18next";

function Menu() {
  //const { t } = useTranslation();
  const [open, setOpen] = useState(true);
  const [menuName, setMenuName] = useState('.');
  const [objMenu, setObjMenu] = useState(objectMenu);
  const [, setLng] = useState('pt-BR');

  /* useEffect(() => {
    if (i18next.language.includes('pt')) {
      setLng('pt-BR');
      setObjMenu(objectMenu);
    } else if (i18next.language === 'en') {
      setLng(i18next.language);
      setObjMenu(objectMenuEn);
    } else {
      setLng(i18next.language);
      setObjMenu(objectMenuEs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [i18next.language]); */

  /* Detecta clique fora da div#wrapper para fechar o menu */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setMenuName('.');
        }
      }
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  /* //Detecta clique fora da div#wrapper para fechar o menu\\ */
  const level = atob(
    JSON.parse(localStorage.getItem('c2tourAuth') || '{}').user.perfil.nivel,
  );
  const blockedMenus: any =
    process.env.REACT_APP_HIDDEN_MENU_ITEMS?.split(',') || [];

  if (process.env.REACT_APP_CLIENT_NAME !== 'C2rio') {
    blockedMenus.push('Planilhas');
  }

  return (
    <div id="wrapper" ref={wrapperRef}>
      {/* <ul className="navbar-nav bg-primary sidebar sidebar-dark accordion toggled" id="accordionSidebar" style={{ zIndex: 11 }}> */}
      <ul
        className="navbar-nav sidebar sidebar-dark toggled"
        id="accordionSidebar"
        style={{ zIndex: 11 }}
      >
        {/* <Link className="sidebar-brand d-flex align-items-center justify-content-center py-6 border-bottom-primary" to="#">
                    <div className="sidebar-brand-icon">
                        <img src={process.env.REACT_APP_SERVER_LOGO_SHORT} alt="Marca C2tours" style={{ width: '60px', padding: '10px' }} />
                    </div>
                    <div className="sidebar-brand-text d-none">C2tours<span className="font-weight-light">SIG</span></div>
                  </Link> */}

        {objMenu.menu.default.length > 0
          ? objMenu.menu.default.map((menu: any, index: Key) => (
            <>
              {menu.level.find(
                (element: any) => element === Number(level),
              ) !== undefined &&
                !blockedMenus.includes(menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]) &&
                (menu.cliente === '*' ||
                  menu.cliente === process.env.REACT_APP_CLIENT_NAME) ? (
                <li key={index} className="nav-item" title={menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]}>
                  {menu.href === '#' ? (
                    <OverlayTrigger
                      placement={'right'}
                      overlay={<Tooltip id="tooltip">{menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]}</Tooltip>}
                    >
                      <div
                        style={{ cursor: 'pointer' }}
                        className="nav-link select"
                        id={menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]}
                        onClick={() => {
                          setOpen(!open);
                          setMenuName(menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]);
                        }}
                        aria-controls={menu.id}
                        aria-expanded={open}
                      >
                        <FontAwesomeIcon icon={['fad', menu.icon]} />
                        <span className="menu-name">{menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]}</span>
                      </div>
                    </OverlayTrigger>
                  ) : (
                    <OverlayTrigger
                      placement={'right'}
                      overlay={<Tooltip id="tooltip">{menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]}</Tooltip>}
                    >
                      <Link
                        className="nav-link select"
                        to={menu.href}
                        id={'link-' + menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]}
                        onClick={() => {
                          setOpen(!open);
                          setMenuName(menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]);
                        }}
                        aria-controls={menu.id}
                        aria-expanded={open}
                      >
                        <FontAwesomeIcon icon={['fad', menu.icon]} />
                        <span className="menu-name">{menu[`name${(i18next.language || 'pt')?.slice(0, 2)?.toUpperCase()}`]}</span>
                      </Link>
                    </OverlayTrigger>
                  )}

                  {menu.submenu.length > 0 ? (
                    <MenuPrimary
                      menu={menu}
                      menuName={menuName}
                      setMenuName={setMenuName}
                    />
                  ) : (
                    ''
                  )}
                </li>
              ) : (
                <></>
              )}
            </>
          ))
          : ''}
        {process.env.NODE_ENV === 'development' ? (
          <li className="versionMenu">
            Staging: <strong>{version}</strong>
          </li>
        ) : (
          <li className="versionMenu">
            Version: <strong>{version}</strong>
          </li>
        )}
      </ul>
    </div>
  );
}

export default Menu;
