import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterBillsPayERP from './components/FilterBills/FilterBillsPayERP';
import TableBillsPayERP from './components/TableBills/TableBillsPayERP';

import './BillsToPayERP.scss';

function BillsToPayERP() {

    const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [billsPay, setBillsPay] = useState<any>(null);
    const [totalPay, setTotalPay] = useState<any>(0);
    const [parameters, setParameters] = useState<any>({});
    const [, setError] = useState<any>();
    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    const defaultStartDate = new Date(today.getFullYear(), today.getMonth(), today.getDate()-1);
    const defaultStartDateStr = `${defaultStartDate.getDate()}/${defaultStartDate.getMonth() + 1}/${defaultStartDate.getFullYear()}`;
    const defaultDate = [defaultStartDateStr];

    /* Filtragem */
    const [actualStatusERP, setActualStatusERP] = useState<any>(1);
    const [actualSupplier, setActualSupplier] = useState<any>("");
    const [actualTypeDate, setActualTypeDate] = useState<any>(2);
    const [actualDate, setActualDate] = useState<any>(defaultDate);

    //console.log(actualDate)

    /* END - Filtragem */

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const goToParcial = () => {
        var parcialObj: any = {
            local: "",
            status: 1,
            type: 1,
            supplier: actualSupplier,
            typeDate: actualTypeDate,
            date: actualDate
        }

        localStorage.setItem('parcialObj', JSON.stringify(parcialObj));

        window.location.href = "/settle-bills-to-pay";
    }

    function clearActualValues() {
        setActualStatusERP(1);
        setActualSupplier("");
        setActualTypeDate(2);
        setActualDate(defaultDate);
    }

    function refreshData() {
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setBillsPay(null);
        }
        setLoading(true);
        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        async function billTopPay() {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
                };

                const { data } = await api.post('/BillsPayable/GetReport',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": "",
                        // "statusPagamento": 2,
                        "statusErp": actualStatusERP ? actualStatusERP : null,
                        // "statusSession": 2,
                        "fornecedor": actualSupplier,
                        "tipoConta": 1,
                        "tipoData": actualTypeDate,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    }, config
                );

                if (data.status !== 400) {
                    setLoading(false);
                    
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "item": "",
                        // "statusPagamento": 2,
                        "statusErp": actualStatusERP ? actualStatusERP : null,
                        // "statusSession": 2,
                        "fornecedor": actualSupplier,
                        "tipoConta": 1,
                        "tipoData": actualTypeDate,
                        "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setBillsPay(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                    setTotalPay(data.data.valorTotal);
                }
            } catch (error: any) {
                setLoading(false);
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        billTopPay();
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage/* , actualStatus */]);

    async function buscar(statusERP: any, supplier: any, typeDate: any, date: any) {
        setBillsPay(null);
        setLoading(true);
        setAllowLoadFromFilter(true);
        setActualStatusERP(statusERP);
        setActualSupplier(supplier);
        setActualTypeDate(typeDate);
        setActualDate(date);
        setPageCount(1)

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };

            const res = await api.post('/BillsPayable/GetReport',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": "",
                    // "statusPagamento": 2,
                    "statusErp": statusERP ? statusERP : null,
                    // "statusSession": 2,
                    "fornecedor": supplier,
                    "tipoConta": 1,
                    "tipoData": typeDate,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);
                setAllowLoadFromFilter(false);

                setParameters({
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "item": "",
                    // "statusPagamento": 2,
                    "statusErp": statusERP ? statusERP : null,
                    // "statusSession": 2,
                    "fornecedor": supplier,
                    "tipoConta": 1,
                    "tipoData": typeDate,
                    "dataInicial": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setBillsPay(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
                setTotalPay(res.data.data.valorTotal);
            }
        } catch (error: any) {
            setLoading(false);
            setAllowLoadFromFilter(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config: any = {
                headers: { 
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json',
                    "Accept": "*/*",
                    //"Access-Control-Allow-Origin": "*"
                },
                responseType: 'blob'
            };

            const exp = await api.post('/BillsPayable/ExportReport',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "fornecedor": actualSupplier,
                    "item": "",
                    "page": 1,
                    "rowsPerPage": 0,
                    "statusPagamento": 2,
                    "tipoconta": 1,
                    "tipodata": actualTypeDate,
                    "statusErp": actualStatusERP,
                    "erp": 1,
                    "statusSession": 2
                },
                config

            );
            if (exp.status !== 400) {
                console.log(exp)
                const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_pagar.xlsx',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config: any = {
                headers: { 
                    'Authorization': `Bearer ${token}`, 
                    'Content-Type': 'application/json',
                    "Accept": "*/*"
                            //"Access-Control-Allow-Origin": "*"
                },
                responseType: 'blob'
            };

            const print = await api.post('/BillsPayable/PrintReport',
                {
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[0]}-${endDate[1]}-${endDate[2]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "fornecedor": actualSupplier,
                    "item": "",
                    "page": 1,
                    "rowsPerPage": 0,
                    "statusPagamento": 2,
                    "tipoconta": 1,
                    "tipodata": actualTypeDate,
                    "statusErp": actualStatusERP,
                    "erp": 1,
                    "statusSession": 2
                },
                config

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_pagar.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Exportação ERP / Contas a Pagar'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Conciliação de Contas a Pagar</h2>
                    </div>
                    <FilterBillsPayERP
                        buscar={buscar}
                        defaultDate={defaultDate}
                        clearActualValues={clearActualValues}
                        allowLoadFromFilter={allowLoadFromFilter}
                    />
                    <TableBillsPayERP
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        parameters={parameters}
                        totalPay={totalPay}
                        actualDate={actualDate}
                        loading={loading}
                        goToParcial={goToParcial}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    )
}

export default BillsToPayERP;