import React, { Key, useState, useEffect } from 'react';
import { Row, Col, Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
//import Select from "react-select";
//import SingleCalendar from '../../../../components/Calendar/SingleCalendar';
import deleteIcon from '../../../assets/icons/icon-garbage.png';

//import DropdownNumberPeople from "../../../../components/DropdownNumberPeople/DropdownNumberPeople";
import GetPickups from '../../C2Points/GetPickups';

import { useAppDispatch } from "../../../store/hooks";
import { removeItemCart } from "../../../store/cart";
//import GetDDI from '../../C2Points/GetDDI';

export interface propCart {
    info: any,
    control: any,
    errors: any,
    setValue: any,
    data: any
    mainContactObj?: any;
    t?: any
};

const CartCheckoutTour: React.FC<propCart> = ({
    info, control, errors, setValue, data, mainContactObj, t
}: propCart) => {
    const [numAdultos, setNumAdultos] = useState<any>([]);
    const [pickupSelect, setPickupSelect] = useState<any>(null);
    const [allowOthers, setAllowOthers] = useState<boolean>(false);

    const dispatch = useAppDispatch(); 
    
    useEffect(() => {
        var aux: any = [];
        for (var i: any = 0; i < info.adults; i++) {
            aux.push(i)
        }
        setNumAdultos(aux)
        setValue(`dados[${data}].internalNotes`, info?.internalNotes || '')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const addPickup = (objPickup: any) => {
        setPickupSelect(objPickup.id);
        setValue(`dados[${data}].pickup`, objPickup.id);
    };

    useEffect(() => {
        setValue(`dados[${data}].passengers.0.firstName`, mainContactObj?.name);
        setValue(`dados[${data}].passengers.0.lastName`, mainContactObj?.lastName);
        setValue(`dados[${data}].passengers.0.email`, mainContactObj?.email);
        setValue(`dados[${data}].passengers.0.phone`, mainContactObj?.phone);
        setValue(`dados[${data}].passengers.0.ddi`, mainContactObj?.ddi);
    }, [data, setValue,/*  */ mainContactObj])

    // useEffect(() => {

    //   }, [pickupSelect])

    /* const cartSIG: any = localStorage.getItem("cartSIG"); */
    /* const [cartItemJSON, setCartItemJSON] = useState<any>((cartSIG)); */

    const handleClickRemove = (productE: any) => {
        dispatch(removeItemCart(productE))
        /* cartSIG.dados.forEach((cItem: any, index: any) => {
            if (productE.productCode === cItem.productCode) {
                cartItemJSON.dados.splice(index, 1);
                setCartItemJSON(cartItemJSON);
                localStorage.setItem("cartSIG", JSON.stringify(cartItemJSON));
                window.location.reload();
            }
        }); */
    };

    /* const setNewNumberPeople = (peoples: any) => {
        setNumberPeople(peoples)
        setValue('numberOfPeople', peoples)
    } */

    const formatDateString = (date: string) => {
        const dateRegex1 = /^\d{4}-\d{2}-\d{2}$/;
        const dateRegex2 = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d+)?([+-]\d{2}:\d{2})?$/;

        if (dateRegex1.test(date)) {
            // Se o formato é YYYY-MM-DD
            return date.split('-').reverse().join('/');
        } else if (dateRegex2.test(date)) {
            // Se o formato é YYYY-MM-DDTHH:MM:SS.SSSZ ou YYYY-MM-DDTHH:MM:SS.SSSSSSS-Z
            return date.split('T')[0].split('-').reverse().join('/');
        } else {
            // Se a data não está em nenhum dos formatos esperados
            return date;
        }
    };

    return (
        <>
            <Card className="cart-card-unit card-unit bg-white mt-4">
                <Card.Body>
                    <div className="card-title">
                        <div className="logo-button">
                            <FontAwesomeIcon
                                className='icon-primary'
                                icon={["fad", "map-marked-alt"]}
                                size="1x"
                                style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem" }}
                            />
                            <button onClick={() => { handleClickRemove(info) }}><img src={deleteIcon} alt="delete" /></button>
                        </div>
                        <div className="mb-2" style={{ borderBottom: "1px solid rgb(112 112 112 / 19%)", paddingBottom: ".875rem" }}>
                            <h4 className='mb-0'>{info.productName}</h4>
                            <p>{info.modalityName}</p>
                        </div>
                        <p>{t("checkout.cartCheckout.supplier")} <span>{info.supplierFantasyName}</span></p>
                    </div>

                    {/* <Form noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false"> */}
                    <Row className="mb-3">
                        <Form.Group as={Col} md="4" controlId="formCheckoutDate">
                            <Form.Label>{t("checkout.cartCheckout.date")}</Form.Label>
                            <p>{formatDateString(info.date)}</p>
                            {/* <Controller
                                control={control}
                                name="date"
                                render={({ field }: any) => (
                                    <SingleCalendar date={info.date} setValue={setValue} />
                
                                )}
                                /> */}
                            <ErrorMessage
                                errors={errors}
                                name="date"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutHour">
                            <Form.Label>{t("checkout.cartCheckout.hour")}</Form.Label>
                            <p>{info.time}</p>
                            {/* <Controller
                                control={control}
                                name="hour"
                                render={({ field }: any) => (
                                    <Form.Control
                                    {...field}
                                    aria-invalid={errors?.hour ? "true" : ""}
                                    variant="standard"
                                    margin="normal"
                                    autoComplete="off"
                                    required
                                    />
                                )}
                                /> */}
                            <ErrorMessage
                                errors={errors}
                                name="hour"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                        <Form.Group as={Col} md="4" controlId="formCheckoutNumberOfPeople">
                            <Form.Label>{t("checkout.cartCheckout.people")}</Form.Label>
                            {info.sellingType === 2 ?
                                <p> <small>{t("checkout.cartCheckout.adults")} {info.isCombo === true ? 
                                <>
                                {info.comboObject[0].qtdAdulto} 
                                </>
                                :
                                <>
                                {info.adults}
                                </>
                                }
                                
                                </small></p>
                                :
                                <p> <small>{t("checkout.cartCheckout.total")} {info.globalPeople}</small></p>
                            }
                            {/* <Controller
                                control={control}
                                name="numberOfPeople"
                                render={({ field }: any) => (
                                    <div className="col-6 col-sm-6 col-md-12">
                                    <div className="panel-dropdown bg-white">
                                        <DropdownNumberPeople
                                        actual={1}
                                        info={{ idSellingType: "2" }}
                                        actionPeople={setNewNumberPeople}
                                        ></DropdownNumberPeople>
                
                                    </div>
                                    </div>
                                )}
                                /> */}
                            <ErrorMessage
                                errors={errors}
                                name="numberOfPeople"
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>

                    <hr />

                    {info.typePickup === '0'
                        ?
                        <>
                            <Row className="mb-3">
                                <div><h3 className='mt-0'>{t("checkout.cartCheckout.meetingPoint")}</h3></div>
                                <div /* className='col-md-8' style={{boxShadow: "0px 4px 2.5px 0px #70707025", border: "1px solid #ced4da", padding: "0.375rem 0.75rem", borderRadius: "0.25rem", width: "100%"}} */><span>{info.meetingPoint}</span></div>
                            </Row>
                            <hr />
                        </>
                        :
                        <>

                            <Row className="mb-3">
                                <Form.Group as={Col} md="8" controlId="formCheckoutPickup">
                                    <Form.Label>{t("checkout.cartCheckout.pickup")}</Form.Label>
                                    <GetPickups
                                        propsId={info.pickupListId}
                                        actionPickup={addPickup}
                                        alert={alert}
                                    />
                                    <Controller
                                        control={control}
                                        name="pickup"
                                        render={({ field }) => {
                                            // sending integer instead of string.
                                            return <input {...field} value={pickupSelect} type="hidden" autoComplete='off' />;
                                        }}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="pickup"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>


                        </>
                    }


                    <div className="participants">
                        <h3>{t("checkout.cartCheckout.attractionParticipants")}</h3>

                        {numAdultos.length > 0
                            ? numAdultos.slice(0, allowOthers === false ? 1 : numAdultos.length).map((person: any, index: Key) => {
                                return (
                                    <>
                                        <div className='person-form' key={index}>
                                            <h4>{`${t("checkout.cartCheckout.participant")} ${index === 0 ? t("checkout.cartCheckout.main") : ''}${t("checkout.cartCheckout.adultPart")}`}</h4>

                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonName">
                                                    <Form.Label>{t("checkout.cartCheckout.name")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        rules={{ required: { value: index === 0, message: t("checkout.cartCheckout.nameError") } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.firstName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonLastname">
                                                    <Form.Label>{t("checkout.cartCheckout.lastname")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        rules={{ required: { value: index === 0, message: t("checkout.cartCheckout.lastnameError") } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.lastName`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonEmail">
                                                    <Form.Label>{t("checkout.cartCheckout.email")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: t("checkout.cartCheckout.emailError"),
                                                            },
                                                            pattern: {
                                                                value: /\S+@\S+.\S+/,
                                                                message: t("checkout.cartCheckout.validEmail"),
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.email`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>
                                            </Row>
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4" controlId="formCheckoutPersonPhone">
                                                    <Form.Label>{t("checkout.cartCheckout.telephone")}</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name={`dados[${data}].passengers.${index}.phone`}
                                                        rules={{
                                                            required: {
                                                                value: true,
                                                                message: t("checkout.cartCheckout.telephoneError")
                                                            },
                                                            pattern: {
                                                                value: /^[+]*[0-9]{2,3}[-\s]{0,1}[(]{0,1}[0-9]{1,3}[)]{0,1}[-\s./0-9]{8,15}$/g,
                                                                message: t("checkout.cartCheckout.validTelephone"),
                                                            },
                                                        }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                aria-invalid={errors?.pickup ? "true" : ""}
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                                placeholder='+55 99 999999999'
                                                                required
                                                            />
                                                        )}
                                                    />
                                                    <ErrorMessage
                                                        errors={errors}
                                                        name={`dados[${data}].passengers.${index}.phone`}
                                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                    />
                                                </Form.Group>

                                            </Row>

                                        </div>
                                    </>
                                )
                            })
                            : ''}

                        <div>
                            <span className='text-primary' style={{ cursor: 'pointer' }} onClick={() => { setAllowOthers(!allowOthers) }}>
                                {allowOthers === false ? t("checkout.cartCheckout.addInfo") : t("checkout.cartCheckout.hideInfo")}
                            </span>
                        </div>

                    </div>

                    <hr />

                    <Row className="mb-3">
                        <Form.Group as={Col} md="8" className="form-textarea" controlId="formCheckoutNotes">
                            <Form.Label>{t("checkout.cartCheckout.internalNotes")}</Form.Label>
                            <Controller
                                control={control}
                                name={`dados[${data}].internalNotes`}
                                // rules={{ required: { value: true, message: 'Por favor, informe esse campo.' } }}
                                render={({ field }: any) => (
                                    <textarea
                                        {...field}
                                        className='form-control'
                                        aria-invalid={errors?.pickup ? "true" : ""}
                                        variant="standard"
                                        margin="normal"
                                        autoComplete="off"
                                        required
                                    />
                                )}
                            />
                            <ErrorMessage
                                errors={errors}
                                name={`dados[${data}].internalNotes`}
                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                            />
                        </Form.Group>
                    </Row>

                    <hr />

                    {/* <Row className="mb-3">
              <Form.Group as={Col} md="4" controlId="formCheckoutPersonPhone">
                <Form.Label>Desconto:</Form.Label>
                <Controller
                  control={control}
                  name="discount"
                  render={({ field }: any) => (
                    <div className='endpoint'>
                      <Select
                        options={[
                          { value: "10%", label: "10%" },
                          { value: "20%", label: "20%" },
                          { value: "30%", label: "30%" }
                        ]}
                      />
                    </div>
                  )}
                />
                <ErrorMessage
                  errors={errors}
                  name="discount"
                  render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                />
              </Form.Group>
            </Row> */}

                    {/* <hr /> */}

                    {/* </Form> */}

                </Card.Body>
            </Card>
        </>
    );
}

export default CartCheckoutTour;