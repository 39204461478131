import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import { Container, Row, Col, Card, Accordion, Form, Button } from 'react-bootstrap';
import ModalShow from "../../../components/Modal/ModalShow/ModalShow";
import api from "../../../services/api";


export interface propChannel {
    isEdit?: boolean
    idSalesChannel?: any
    setIdSalesChannel?: any
    setCloseModal?: any
}

const ChannelForm: React.FC<propChannel> = ({
    isEdit = false, idSalesChannel = null, setIdSalesChannel = () => {}, setCloseModal = () => {}
}: propChannel) => {
    const [infoEdit, setInfoEdit] = useState<any>(null);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");
    const [allowCloseModal, setAllowCloseModal] = useState<boolean>(false);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm()

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const GetSalesChannel = async () => {
            try {
                const { data } = await api.get(`SalesChannel/GetSalesChannelFilterById?id=${idSalesChannel}`, config);

                if (data.statusCode === 200) {
                    setInfoEdit(data.data.data)
                }
            } catch (error: any) {
                setTimeout(() => {
                    GetSalesChannel();
                }, 5000);
            }
        }
        if (idSalesChannel !== null) {
            GetSalesChannel();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idSalesChannel])

    useEffect(() => {
        if (infoEdit !== null) {
            setValue("idSalesChannel", infoEdit.idSalesChannel);
            setValue("salesChannelName", infoEdit.salesChannelName);
            setValue("salesChannelDescription", infoEdit.description);
            setValue("statusSalesChannel", infoEdit.statusSalesChannel);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [infoEdit])

    const onSubmit = (data: any) => {
        submitChannel(data)
    }

    const submitChannel = async (obj: any) => {
        setModalShow(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const { data } = await api.post(isEdit ? 'SalesChannel/EditSalesChannel' : 'SalesChannel/InsertSalesChannel', {
                idSalesChannel: isEdit ? obj.idSalesChannel : undefined,
                salesChannelName: obj.salesChannelName,
                salesChannelDescription: obj.salesChannelDescription,
                statusSalesChannel: obj.statusSalesChannel,
            }, config);

            if (data.statusCode === 200) {
                if (data.data?.length > 0) { //error
                    setModalLog(data.data[0].log);
                    setModalMessage(data.data[0].texto)
                } else { //sucesso
                    setAllowCloseModal(true);
                    setModalLog(data.data.log);
                    setModalMessage(data.data.texto);
                }
            } else {
                setModalLog(1);
                setModalMessage("Erro ao processar operação");
            }

        } catch (error: any) {
            setModalLog(1);
            setModalMessage("Algo deu errado! Favor entrar em contato com o suporte.");
        }
    }
    
    useEffect(()=>{
        if(allowCloseModal){
            setIdSalesChannel(null);
            setCloseModal(true);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    },[modalShow])

    return (
        <>
            <Card className="bg-white" style={{ border: 'none' }}>
                <Accordion >
                    <Card.Body className="p-0">
                        <Container className="p-0" fluid>
                            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                {/* {
                                    infoEdit !== null &&
                                    <>
                                        <div className="h-100 d-flex justify-content-end align-items-end mb-2">
                                            Última alteração feita por:{'\u00A0'}<b>{`${infoEdit.createdBy}`}</b>{'\u00A0'}em{'\u00A0'}<b>{`${infoEdit.date?.split('T')[0].split('-').reverse().join('/')}`}</b>
                                        </div>
                                    </>
                                } */}
                                <Row className="mb-3">
                                    <Form.Group as={Col} md="8" controlId="">
                                        <Form.Label>
                                            Nome do Canal:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="salesChannelName"
                                            rules={{ required: { value: true, message: `Favor, preencher esse campo` } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="salesChannelName"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="4" controlId="">
                                        <Form.Label>
                                            Status:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="statusSalesChannel"
                                            rules={{ required: { value: false, message: `Favor, preencher esse campo` } }}
                                            defaultValue={'2'}
                                            render={({ field }: any) => (
                                                <div className='endpoint'>
                                                    <Form.Select
                                                        {...field}
                                                        as="select"
                                                        variant="standard"
                                                        margin="normal"
                                                    >
                                                        <option value="1">Ativo</option>
                                                        <option value="2" defaultChecked>Inativo</option>
                                                    </Form.Select>
                                                </div>
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="statusSalesChannel"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>

                                    <Form.Group as={Col} md="12" controlId="" className="mt-3">
                                        <Form.Label>
                                            Descrição do Canal:
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="salesChannelDescription"
                                            rules={{ required: { value: true, message: `Favor, preencher esse campo` } }}
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    {...field}
                                                    type="text"
                                                    as="textarea"
                                                    rows={4}
                                                    variant="standard"
                                                    margin="normal"
                                                    required
                                                    autoComplete='off'
                                                />
                                            )}
                                        />
                                        <ErrorMessage
                                            errors={errors}
                                            name="salesChannelDescription"
                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                    </Form.Group>
                                </Row>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    <Row className="mx-0 mt-3">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Cadastrar
                                        </Button>
                                    </Row>
                                </div>
                            </Form>
                        </Container>
                    </Card.Body>
                </Accordion>
            </Card>
            <ModalShow
                modalShow={modalShow}
                setModalShow={setModalShow}
                modalMessage={modalMessage}
                setModalMessage={setModalMessage}
                modalLog={modalLog}
                setModalLog={setModalLog}
            />
        </>
    )
}

export default ChannelForm;