import React, { useState } from 'react';

import FinancialTable from '../../../AccordionFinancial/FinancialTable';

export interface propEdit {
    setChangeEdit: any,
    info: any,
};

const EditFinancial: React.FC<propEdit> = ({
    setChangeEdit, info
}: propEdit) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const [costCenter, setCostCenter] = useState<any>('');
    const [messageError, setMessageError] = useState<boolean>(false);
    
    const getCostCenters = (objCostCenter: any) => {
        setCostCenter(objCostCenter);
    }

    enum payment {
        "Term" = 'Faturado' as any,
        "At_sight" = 'À vista' as any,
    }

    enum ciclo {
        "Days" = 'Diário' as any,
        "Week" = 'Semanal' as any,
        "Daily" = 'Quinzenal' as any,
        "Months" = 'Mensal' as any,
        "Dec" = 'Decendial' as any,
    }

    return (
        <>
            <div className="border" id="editFinancial">
                <div style={{ backgroundColor: "#fff", paddingBottom: "60px" }}>
                    <div className="edit-title container-fluid">
                        <h3 className="">Financeiro</h3>
                        <button onClick={() => {setChangeEdit(false)}}>EDITAR</button>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>BANCO:</p>
                            <p>{info.bancDesc ? info.bancDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>AGÊNCIA:</p>
                            <p>{info.agency ? info.agency : "não definido"}</p>
                        </div>
                        <div className="data-unit col-20">
                            <p>CONTA:</p>
                            <p>{info.account ? info.account : "não definido"}</p>
                        </div>
                        <div className="data-unit col-10">
                            <p>DIV:</p>
                            <p>{info.verificadorDiv}</p>
                        </div>
                        <div className="data-unit">
                            <p>COMISSÕES:</p>
                            <p>{info.comissaoFornecedor !== null ? info.comissaoFornecedor : "não definido"}</p>
                        </div>
                        <div className="data-unit ">
                            <p>LIMITE DE CRÉDITO:</p>
                            <p>{info.creditLimitSupplier !== null ? info.creditLimitSupplier : "não definido"}</p>
                        </div>
                        <div className="data-unit col-20 data-unit-white-bg"></div>
                        <div className="data-unit col-10 data-unit-white-bg"></div>
                        <hr className="w-100" />
                    </div>

                    <div className="edit-title container-fluid">
                        <h3 className="">BRASPAG</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>CADASTRAR NA BRASPAG:</p>
                            <p>{info.braspagDesc !== null ? info.braspagDesc : "não definido"}</p>
                        </div>
                        <div className="data-unit">
                            <p>FREE BRASPAG:</p>
                            <p>{info.feeBraspag === 1 ? 'Sim' : "Não"}</p>
                        </div>
                        <div className="data-unit col-20">
                            <p>MDR BRASPAG %:</p>
                            <p>{info.mdrBraspag !== null ? `${info.mdrBraspag}%` : "não definido"}</p>
                        </div>
                        {/* <div className="data-unit col-10">
                            <p>STATUS:</p>
                            <p>{info.status ? info.status : "não definido"}</p>
                        </div> */}

                        <hr className="w-100" />
                    </div>

                    <div className="edit-title container-fluid">
                        <h3 className="">PAGAMENTO:</h3>
                    </div>
                    <div className="data">
                        <div className="data-unit">
                            <p>CONCILIAÇÃO AUTOMÁTICA:</p>
                            <p>{info.conciliacaoAutomaticaDesc !== null ? info.conciliacaoAutomaticaDesc : "não definido"}</p>
                        </div>

                        <div className="data-unit blank"></div>
                        <div className="data-unit blank"></div>

                        <div className="data-unit col-20">
                            <p>MOEDA:</p>
                            <p>{info.currency ? info.currency : "não definido"}</p>
                        </div>

                        <div className="data-unit col-20">
                            <p>PAGAMENTO:</p>
                            <p>{info.paymentType ? payment[info.paymentType] : "não definido"}</p>
                        </div>
                        <div className="data-unit blank col-30"></div>
                        <div className="data-unit blank col-20"></div>

                        {info.paymentType === 'Term'
                            ?
                            <>
                                <div className="data-unit col-10">
                                    <p>A CONTAR DE:</p>
                                    <p>{info.reserve ? info.reserve : "não definido"}</p>
                                </div>
                                <div className="data-unit col-10">
                                    <p>CICLO:</p>
                                    <p>{info.ciclo ? ciclo[info.ciclo] : "não definido"}</p>
                                </div>

                                {info.ciclo === 'Week'
                                    ?
                                    <div className="data-unit col-10">
                                        <p>CORTE - WEEK:</p>
                                        <p>{info.diaDeCorte ? info.diaDeCorte : "não definido"}</p>
                                    </div>
                                    : ''
                                }

                                {info.ciclo === 'Daily'
                                    ?
                                    <div className="data-unit col-10">
                                        <p>CORTE - DAILY:</p>
                                        <p>{info.diaDeCorte}</p>
                                    </div>
                                    : ''
                                }

                                {info.ciclo === 'Dec'
                                    ?
                                    <div className="data-unit col-10">
                                        <p>CORTE - DEC:</p>
                                        <p>{info.diaDeCorte ? info.diaDeCorte : "não definido"}</p>
                                    </div>
                                    : ''
                                }

                                <div className="data-unit col-10">
                                    <p>FATURAMENTO:</p>
                                    <p>{info.faturamento60Dias}</p>
                                </div>
                            </>
                            : ''
                        }
                    </div>

                    <div className="edit-title container-fluid">
                        <h3 className="">CENTROS DE CUSTO</h3>
                    </div>
                    <div className="data">
                        <FinancialTable isEdit={true} setMessageError={setMessageError} messageError={messageError} action={getCostCenters} infoEdit={info} disableAddContact={true} />
                    </div>
                </div>
            </div>
        </>
    );
}

export default EditFinancial;