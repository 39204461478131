import React, { useState, useEffect, Key } from "react";
import { Card, Form, Row, Col, Button, Dropdown, OverlayTrigger, Tooltip } from "react-bootstrap";
import api from "../../../../services/api";
import { Controller, useForm } from "react-hook-form";

import "./TableStock.scss"
//import SingleCalendarStock from "../CalendarStock/SingleCalendarStock";

// import RequestResponseModal from "../../../components/Modal/RequestResponseModal/RequestResponseModal";
import Modal from "react-bootstrap/Modal";
import RequestResponseModal from "../../../../components/Modal/RequestResponseModal/RequestResponseModal";
import { ErrorMessage } from "@hookform/error-message";
import MultipleRange from "../../../../components/Calendar/MultipleRange";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { DateObject } from "react-multi-date-picker";

export interface propPlus {
    tarCode: string | null,
    prodCode: string | null,
    modCode: string | null,
};

const TableStock: React.FC<propPlus> = ({
    tarCode, prodCode, modCode
}: propPlus) => {

    const [loading, setLoading] = useState<boolean>(true);
    const [tariffsInfo, setTariffsInfo] = useState<any>(null);
    const [modalResponse, setModalResponse] = useState<any>(null); /// ["loading","error","sucess"] para abrir o modal pós envio
    const [modalText, setModalText] = useState<any>(null);
    const [log, setLog] = useState<any>();
    const [capacidades, setCapacidades] = useState<any>([]);
    const [isEdit, setIsEdit] = useState<boolean>(false);
    const [registeredStockOnRanges, setRegisteredStockOnRanges] = useState<any>([]);
    const [id, setId] = useState<any>([]);
    const [allRangesHistory, setAllRangesHistory] = useState<any>([]);
    const [allRanges, setAllRanges] = useState<any>([]);
    const [selectedRange, setSelectedRange] = useState<any>(null);
    const [totalRange, setTotalRange] = useState<any>([]); //usado pra saber o range máximo de dias que esse tarifário possui.
    const [modalShowConflict, setModalShowConflict] = useState<boolean>(false);
    const [modalShowDeleteStock, setModalShowDeleteStock] = useState<boolean>(false);
    const [isAllTariff, setIsAllTariff] = useState<boolean>(false);
    const [modalShowFillAllFields, setModalShowFillAllFields] = useState<boolean>(false);
    const [allFieldsValue, setAllFieldsValue] = useState<string>('');

    useEffect(() => {
        setRegisteredStockOnRanges(() => { return ([]) });
        setId(() => { return ([]) });
        setAllRangesHistory(() => { return ([]) });
        setAllRanges(() => { return ([]) });
        setSelectedRange(() => { return (null) });
        setIsAllTariff(false);
    }, [tariffsInfo])

    const getStockByTarCode = async (tarifaId: any) => {
        setLoading(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const { data } = await api.get(`/Products/GetTariffAndDaysAndHours?TarUniqueCode=${tarifaId}`, config);

            if (data.statusCode === 200) {
                setTariffsInfo(data.data);
                getStockByGeneral();

                let minDate: any = data.data.data.find((elem: any) => Number(new Date(elem.tarifStart).toJSON()?.slice(0, 10).split('-').join('')) === Math.min(...data.data.data.map((e: any) => Number(new Date(e.tarifStart).toJSON()?.slice(0, 10).split('-').join(''))))).tarifStart;
                let maxDate: any = data.data.data.find((elem: any) => Number(new Date(elem.tarifEnd).toJSON()?.slice(0, 10).split('-').join('')) === Math.max(...data.data.data.map((e: any) => Number(new Date(e.tarifEnd).toJSON()?.slice(0, 10).split('-').join(''))))).tarifEnd;

                setTotalRange(() => {
                    return (
                        {
                            "startDate": minDate,
                            "endDate": maxDate
                        }
                    )
                })
                setLoading(false);
            } else {

            }
        } catch (error: any) {

        }
    }

    const getStockByGeneral = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let obj: any = {
            "groupSessionUniqueCode": "",
            "productCode": prodCode,
            "prodModCodeUnique": modCode,
            "tarCodeUnique": tarCode,
        }

        try {
            const { data } = await api.post(`Stock/GetStockGeneralUp`, obj, config);

            if (data.data.item1 !== undefined) {
                setIsEdit(true);
                listStockData(data.data.item1);
            } else {
                setIsEdit(false);
            }
        } catch (error: any) {
            setIsEdit(false);
        }
    }

    const deleteStock = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let obj: any = {
            "productCode": prodCode,
            "prodModCodeUnique": modCode,
            "tarCodeUnique": tarCode,
        }

        try {
            const { data } = await api.post(`Stock/DeleteStockGeneral`, obj, config);

            if (data.statusCode !== 400) {
                if (data?.data?.log === 0) {
                    setModalResponse("success");
                    setModalText(data.data.texto);
                    setLog(data.data.log);

                    resetForm();
                } else {
                    setModalResponse("error");
                    setModalText(data.data[0].texto);
                    setLog(data.data[0].log);
                }

            } else {
                setModalText("Erro ao cadastrar estoque. Entre em contato com o suporte.");
                //setModalText(data.data.texto);
                setModalResponse("error");
                setLog(data.data.log);
            }
        } catch (error: any) {
            setModalResponse("error");
        }
    }

    const listStockData = (data: any) => {
        let ranges: any = data.stockGeneralOnSaleUp.map((elem: any) => {
            return ([new DateObject(elem.stockGeneralRangeDates.startDate), new DateObject(elem.stockGeneralRangeDates.endDate)])
        })

        let allIds: any = data.stockGeneralOnSaleUp.map((elem: any) => {
            return (`${new Date(elem.stockGeneralRangeDates.startDate).toJSON()?.slice(0, 10).split('-').join('')}${new Date(elem.stockGeneralRangeDates.endDate).toJSON()?.slice(0, 10).split('-').join('')}`)
        })

        let allRegistered: any = data.stockGeneralOnSaleUp.map((elem: any) => {
            return ({
                formId: `${new Date(elem.stockGeneralRangeDates.startDate).toJSON()?.slice(0, 10)}${new Date(elem.stockGeneralRangeDates.endDate).toJSON()?.slice(0, 10)}`,
                stockAmountsUp: elem.stockAmountsUp,
                stockGeneralRangeDates: elem.stockGeneralRangeDates,
                domainDaysOfWeek: elem.domainDaysOfWeek
            });
        })

        let listOfRepeats: any = [];
        let newRanges: any = [];

        ranges.forEach((elem: any) => {
            if (!listOfRepeats.includes(new Date(elem[0]).toJSON()?.slice(0, 10).split('-').join(''))) {
                listOfRepeats.push(new Date(elem[0]).toJSON()?.slice(0, 10).split('-').join(''))
                newRanges.push([new DateObject(elem[0].toJSON()), new DateObject(elem[1].toJSON())])
            }
        });

        allIds = allIds.filter(function (elem: any, i: any) {
            return allIds.indexOf(elem) === i;
        });

        setAllRanges(() => newRanges)
        setAllRangesHistory(() => newRanges)
        setId(() => allIds)
        setRegisteredStockOnRanges(() => allRegistered)


        let rangesSize: any = data.stockGeneralOnSaleUp.map((elem: any) => { return (`${elem.stockGeneralRangeDates.startDate.split('T')[0]}${elem.stockGeneralRangeDates.endDate.split('T')[0]}`) })
        rangesSize = rangesSize.filter(function (este: any, i: any) { return rangesSize.indexOf(este) === i; });

        rangesSize.forEach((e: any, rangesSizeIndex: number) => {
            let num: number = 0
            data.stockGeneralOnSaleUp.forEach((tariff: any, arrayIndex: number) => {
                tariff.stockAmountsUp.forEach((hour: any, indexHour: number) => {
                    if (e === `${tariff.stockGeneralRangeDates.startDate?.slice(0, 10)}${tariff.stockGeneralRangeDates.endDate?.slice(0, 10)}`) {
                        setValue(`range[${rangesSizeIndex}].stockGeneralOnSaleUp[${num}].stockAmountsUp[${indexHour}].Amount`, hour.amount);
                        setValue(`range[${rangesSizeIndex}].stockGeneralOnSaleUp[${num}].stockGeneralRangeDates.startDate`, tariff.stockGeneralRangeDates.startDate?.slice(0, 10));
                        setValue(`range[${rangesSizeIndex}].stockGeneralOnSaleUp[${num}].stockGeneralRangeDates.endDate`, tariff.stockGeneralRangeDates.endDate?.slice(0, 10));

                        hour.stockAmountsByChannel.forEach((item: any, indexChannel: number) => {
                            setValue(`range[${rangesSizeIndex}].stockGeneralOnSaleUp[${num}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`, item.amount);
                            setValue(`range[${rangesSizeIndex}].stockGeneralOnSaleUp[${num}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`, item.idSalesChannel);
                        });
                    }
                });
                if (e === `${tariff.stockGeneralRangeDates.startDate?.slice(0, 10)}${tariff.stockGeneralRangeDates.endDate?.slice(0, 10)}`) {
                    num += 1;
                }
            });
        });
    }

    //setLoading(true);

    useEffect(() => {
        if (tarCode !== null) {
            getStockByTarCode(tarCode);
        } else {
            setTariffsInfo(null)
        }

        resetValues();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tarCode])

    function organizeData(data: any) {
        // Criar um objeto vazio para armazenar os horários divididos
        const horariosDivididos: any = {};
        // Iterar sobre os objetos de horários e canais
        data.hoursAndChannel.forEach((item: any) => {
            const hora = item.hora;

            // Se a hora ainda não estiver no objeto de horários divididos, crie um array vazio
            if (!horariosDivididos[hora]) {
                horariosDivididos[hora] = [];
            }

            // Adicionar o item atual ao array correspondente à hora
            horariosDivididos[hora].push(item);
        });

        // Converter o objeto de horários divididos em um array
        const horariosArray = Object.entries(horariosDivididos).map(([hora, items]) => ({ hora, items, daysUniqueTarCode: data.daysUniqueTarCode }));

        return horariosArray;
    }

    const generateWeekdaysForSubmit = (data: any) => {
        const dataNewWeekDays: any = [
            data.domainDaysOfWeek?.dom === 1 || (data.domainDaysOfWeek?.length > 0 && data.domainDaysOfWeek?.map((elem: any) => { return elem.idDomainDaysOfWeek }).includes(1)) ? { "idDomainDaysOfWeek": 1 } : false,
            data.domainDaysOfWeek?.seg === 1 || (data.domainDaysOfWeek?.length > 0 && data.domainDaysOfWeek?.map((elem: any) => { return elem.idDomainDaysOfWeek }).includes(2)) ? { "idDomainDaysOfWeek": 2 } : false,
            data.domainDaysOfWeek?.ter === 1 || (data.domainDaysOfWeek?.length > 0 && data.domainDaysOfWeek?.map((elem: any) => { return elem.idDomainDaysOfWeek }).includes(3)) ? { "idDomainDaysOfWeek": 3 } : false,
            data.domainDaysOfWeek?.qua === 1 || (data.domainDaysOfWeek?.length > 0 && data.domainDaysOfWeek?.map((elem: any) => { return elem.idDomainDaysOfWeek }).includes(4)) ? { "idDomainDaysOfWeek": 4 } : false,
            data.domainDaysOfWeek?.qui === 1 || (data.domainDaysOfWeek?.length > 0 && data.domainDaysOfWeek?.map((elem: any) => { return elem.idDomainDaysOfWeek }).includes(5)) ? { "idDomainDaysOfWeek": 5 } : false,
            data.domainDaysOfWeek?.sex === 1 || (data.domainDaysOfWeek?.length > 0 && data.domainDaysOfWeek?.map((elem: any) => { return elem.idDomainDaysOfWeek }).includes(6)) ? { "idDomainDaysOfWeek": 6 } : false,
            data.domainDaysOfWeek?.sab === 1 || (data.domainDaysOfWeek?.length > 0 && data.domainDaysOfWeek?.map((elem: any) => { return elem.idDomainDaysOfWeek }).includes(7)) ? { "idDomainDaysOfWeek": 7 } : false,
        ]

        // Filtra os valores falsos do array
        const filteredDataNewWeekDays = dataNewWeekDays.filter((item: boolean) => item !== false);

        return filteredDataNewWeekDays;
    }

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        clearErrors,
        formState: { errors },
    } = useForm({});

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputChange = (e: any, capac: any, hora: any) => {
        // setNumber(e.target.value);
        // setValue('number', e.target.value);
        let teste = capacidades;
        teste[hora] = { 'capacidade': e.target.value };
        setCapacidades(teste)
    }

    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const handleInputChangeChanel = (e: any, canal: any, hora: any) => {

        // setNumber(e.target.value);
        // setValue('number', e.target.value);
        let teste = capacidades;
        // let testeCnal = `{${canal}: ${e.target.value}}`
        // teste[hora].push(testeCnal);
        setCapacidades(teste)
    }

    const onSubmit = (data: any) => {
        // eslint-disable-next-line array-callback-return
        let allExistingFormIds: any = allRanges.map((elem: any) => {
            if (elem.length === 2) {
                return `${new Date(elem[0]).toJSON()?.slice(0, 10)}${new Date(elem[1]).toJSON()?.slice(0, 10)}`
            }
        })

        let result: any = registeredStockOnRanges.filter((elem: any) => allExistingFormIds.includes(elem.formId)).map((elem: any) => {

            return (
                {
                    domainDaysOfWeek: generateWeekdaysForSubmit(elem),
                    stockGeneralRangeDates: {
                        ...elem.stockGeneralRangeDates,
                        "prodModCodeUnique": modCode,//"MOD-YB487694",
                        "tarCodeUnique": tarCode,//"TAR-TT199785",
                    },
                    stockAmountsUp: elem.stockAmountsUp.map((amountElem: any) => {
                        return ({
                            "StartHour": amountElem.StartHour,
                            "EndHour": "",
                            "StockAmountsByChannel": amountElem.StockAmountsByChannel.map((channelElem: any) => {
                                return ({
                                    "IdSalesChannel": channelElem.IdSalesChannel,
                                    "Amount": channelElem.Amount || 0
                                })
                            }),
                            "Amount": amountElem.Amount
                        })
                    })
                }
            )
        });

        const dataStock = {
            "productCode": prodCode,
            "stockGeneralOnSaleUp": result
        }

        sendStock(dataStock);
    }

    const sendStock = async (objectStock: object) => {
        console.log(objectStock)
        setModalResponse("loading");

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const str = JSON.stringify(objectStock);
            const { data } = await api.post(isEdit ? 'Stock/EditStockGeneralUp' : 'Stock/InsertStockGeneralUp', str, config);
            if (data.statusCode !== 400) {
                if (data?.data?.log === 0) {
                    setModalResponse("success");
                    setModalText(data.data.texto);
                    setLog(data.data.log);

                    resetForm();
                } else {
                    setModalResponse("error");
                    setModalText(data.data[0].texto);
                    setLog(data.data[0].log);
                }

            } else {
                setModalText("Erro ao cadastrar estoque. Entre em contato com o suporte.");
                //setModalText(data.data.texto);
                setModalResponse("error");
                setLog(data.data.log);
            }

        } catch (error: any) {
            if (error?.response?.status === 400) {
                setModalResponse("error");
                // setErrors(error.response.data.errors)
            }
        }
    }

    function generateWeekdays(allWeeks: any, weeks: any) {
        const newWeekDays: any = [
            {
                state: weeks.dom === 1 ? 1 : 0,
                day: 'dom'
            },
            {
                state: weeks.seg === 1 ? 1 : 0,
                day: 'seg'
            },
            {
                state: weeks.ter === 1 ? 1 : 0,
                day: 'ter'
            },
            {
                state: weeks.qua === 1 ? 1 : 0,
                day: 'qua'
            },
            {
                state: weeks.qui === 1 ? 1 : 0,
                day: 'qui'
            },
            {
                state: weeks.sex === 1 ? 1 : 0,
                day: 'sex'
            },
            {
                state: weeks.sab === 1 ? 1 : 0,
                day: 'sab'
            }
        ];

        //Linhas abaixo usadas para resolver o problema de inconsistências nos dias da semana caso mude de tarifário várias vezes
        setValue(`domainDaysOfWeek.dom`, allWeeks.map((elem: any) => elem.dom).includes(1) ? 1 : 0);
        setValue(`domainDaysOfWeek.seg`, allWeeks.map((elem: any) => elem.seg).includes(1) ? 1 : 0);
        setValue(`domainDaysOfWeek.ter`, allWeeks.map((elem: any) => elem.ter).includes(1) ? 1 : 0);
        setValue(`domainDaysOfWeek.qua`, allWeeks.map((elem: any) => elem.qua).includes(1) ? 1 : 0);
        setValue(`domainDaysOfWeek.qui`, allWeeks.map((elem: any) => elem.qui).includes(1) ? 1 : 0);
        setValue(`domainDaysOfWeek.sex`, allWeeks.map((elem: any) => elem.sex).includes(1) ? 1 : 0);
        setValue(`domainDaysOfWeek.sab`, allWeeks.map((elem: any) => elem.sab).includes(1) ? 1 : 0);

        // setValue(`domainDaysOfWeek.dom`, weeks.dom === 1 ? 1 : 0);
        // setValue(`domainDaysOfWeek.seg`, weeks.seg === 1 ? 1 : 0);
        // setValue(`domainDaysOfWeek.ter`, weeks.ter === 1 ? 1 : 0);
        // setValue(`domainDaysOfWeek.qua`, weeks.qua === 1 ? 1 : 0);
        // setValue(`domainDaysOfWeek.qui`, weeks.qui === 1 ? 1 : 0);
        // setValue(`domainDaysOfWeek.sex`, weeks.sex === 1 ? 1 : 0);
        // setValue(`domainDaysOfWeek.sab`, weeks.sab === 1 ? 1 : 0);

        return newWeekDays;
    }

    const handleBlurChannelAmount = (amount: number, index: number, arrayIndex: number, indexHour: number, indexChannel: any) => {
        clearErrors();

        let data: any = getValues();
        let somaCanais: number;

        somaCanais = data.range[arrayIndex].stockGeneralOnSaleUp[index].stockAmountsUp[indexHour].StockAmountsByChannel.map((elem: any) => Number(elem.Amount || 0)).reduce((accumulator: any, currentValue: any) => accumulator + currentValue, 0);

        setValue(`range[${arrayIndex}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].Amount`, somaCanais)
    }

    const resetValues = () => {
        let thisHour: any;

        tariffsInfo?.data.forEach((tariff: any, arrayIndex: number) => {
            thisHour = organizeData(tariff);

            id.forEach((elemId: any, indexId: number) => {//
                thisHour.forEach((hour: any, indexHour: number) => {
                    setValue(`range[${indexId}].stockGeneralOnSaleUp[${arrayIndex}].stockAmountsUp[${indexHour}].Amount`, '');

                    hour.items.forEach((item: any, indexChannel: number) => {
                        setValue(`range[${indexId}].stockGeneralOnSaleUp[${arrayIndex}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`, '')
                        setValue(`range[${indexId}].stockGeneralOnSaleUp[${arrayIndex}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`, '')
                    });
                });
            });

            id.forEach((elemId: any, indexId: number) => {//
                thisHour.forEach((hour: any, indexHour: number) => {
                    hour.items.forEach((item: any, indexChannel: number) => {
                        delete getValues()?.range[indexId]?.stockGeneralOnSaleUp[arrayIndex]?.stockAmountsUp[indexHour]?.StockAmountsByChannel[indexChannel]?.Amount;
                        delete getValues()?.range[indexId]?.stockGeneralOnSaleUp[arrayIndex]?.stockAmountsUp[indexHour]?.StockAmountsByChannel[indexChannel]?.IdSalesChannel;
                    });
                    delete getValues()?.range[indexId]?.stockGeneralOnSaleUp[arrayIndex]?.stockAmountsUp[indexHour]?.Amount;
                    delete getValues()?.range[indexId]?.stockGeneralOnSaleUp[arrayIndex]?.stockAmountsUp[indexHour];
                });
                delete getValues()?.range[indexId]?.stockGeneralOnSaleUp[arrayIndex]?.stockAmountsUp;
                delete getValues()?.range[indexId]?.stockGeneralOnSaleUp[arrayIndex];
                delete getValues()?.range[indexId]?.stockGeneralOnSaleUp;
                delete getValues()?.range[indexId];
                delete getValues()?.range;
            });
        });

        setValue('range', []);

        setValue(`domainDaysOfWeek.dom`, 0);
        setValue(`domainDaysOfWeek.seg`, 0);
        setValue(`domainDaysOfWeek.ter`, 0);
        setValue(`domainDaysOfWeek.qua`, 0);
        setValue(`domainDaysOfWeek.qui`, 0);
        setValue(`domainDaysOfWeek.sex`, 0);
        setValue(`domainDaysOfWeek.sab`, 0);

        setAllRangesHistory(() => []);
        setAllRanges(() => []);
        setId(() => []);
        setSelectedRange(() => null);
        setTotalRange(() => []);
    }

    useEffect(() => {
        let hasConflict: boolean = false;

        if (allRanges.length > 0) {
            if (allRanges[0].length > 1) {
                let auxInicial: any = allRanges.map((elem: any) => new Date(elem[0]).toJSON()?.slice(0, 10).split('-').join(''))
                let auxFinal: any = allRanges.map((elem: any) => elem.length > 1 ? new Date(elem[1]).toJSON()?.slice(0, 10).split('-').join('') : 0)

                for (let i: number = 0; i < allRangesHistory.length; i++) {
                    if (auxInicial.includes(new Date(allRangesHistory[i][0])?.toJSON()?.slice(0, 10)?.split('-')?.join(''))) { //verifica se a data inicial anterior ainda existe, se ainda existir, deve verificar a data final caso exista

                        if (!auxFinal.includes(0) && !auxFinal.includes(new Date(allRangesHistory[i][1])?.toJSON()?.slice(0, 10)?.split('-')?.join(''))) {//caso a data final anterior não exista, houve uma alteração nos ranges selecionados (conflito)
                            hasConflict = true
                        }
                    } else { //caso a data inicial anterior não exista, houve uma alteração nos ranges selecionados (conflito)
                        hasConflict = true
                    }
                }

                //reseta o checkbox quando for preciso
                if (!auxInicial.includes(totalRange.startDate?.slice(0, 10).replaceAll('-', '')) || !auxFinal.includes(totalRange.endDate?.slice(0, 10).replaceAll('-', ''))) {
                    setIsAllTariff(false);
                }
            }
        }

        if (hasConflict) {
            setModalShowConflict(true);
        } else {
            setAllRangesHistory(allRanges.filter((elem: any) => elem.length === 2));
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [allRanges])

    const handleActionConflictModal = () => {
        let data: any = getValues();

        if (data?.stockGeneralOnSaleUp?.length > 0) {
            data.stockGeneralOnSaleUp.forEach((elem: any, index: number) => {
                //elem
            });
        }
        setSelectedRange(() => null);
        setAllRangesHistory(allRanges);
        setModalShowConflict(false);
    }

    const handleActionCancelConflictModal = () => {
        setSelectedRange(() => null);
        setAllRanges(allRangesHistory);
        setModalShowConflict(false);
    }

    const registerStockOnSelectedRange = () => {
        let data: any = getValues();

        //verificar conflito e apagar se necessario
        setRegisteredStockOnRanges((prev: any) => {
            if (!(prev.map((elem: any) => elem.formId)).includes(data.range[selectedRange].stockGeneralOnSaleUp[0].formId)) {
                return [...prev, ...data.range[selectedRange].stockGeneralOnSaleUp];
            } else {
                //testar com mais de 1 daystariff quando arrumarem a api
                let aux: any = prev;
                //let indexToChange: number = prev.map((elem: any) => elem.formId).findIndex((elem: any) => elem === data.range[selectedRange].stockGeneralOnSaleUp[0].formId);
                let selectedId: any = prev.map((elem: any) => elem.formId).find((elem: any) => elem === data.range[selectedRange].stockGeneralOnSaleUp[0].formId)

                //todos os index com o mesmo ID
                let indexToChange: number[] = prev.map((elem: any) => elem.formId).reduce(function (a: any, e: any, i: any) {
                    if (e === selectedId)
                        a.push(i);
                    return a;
                }, []);

                indexToChange.forEach((elem: any, indexElem: number) => {
                    aux[elem] = data.range[selectedRange].stockGeneralOnSaleUp[indexElem];
                });

                return aux;
            }
        })
        setSelectedRange(() => null);
    }

    const resetForm = () => {
        //setTariffsInfo(null);
    }

    useEffect(() => {
        if (isAllTariff) {
            setId((prev: any) => {
                if (!prev.includes(`${totalRange.startDate.slice(0, 10).replaceAll('-', '')}${totalRange.endDate.slice(0, 10).replaceAll('-', '')}`)) {
                    return [...prev, `${totalRange.startDate.slice(0, 10).replaceAll('-', '')}${totalRange.endDate.slice(0, 10).replaceAll('-', '')}`];
                } else {
                    return [...prev];
                }
            })

            setAllRangesHistory(() => allRanges);
            setAllRanges(() => [[new DateObject(totalRange.startDate), new DateObject(totalRange.endDate)]]);
            //setRegisteredStockOnRanges(() => [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isAllTariff])

    const fillAllFields = () => {
        let thisHour: any;

        tariffsInfo?.data.forEach((tariff: any, arrayIndex: number) => {
            thisHour = organizeData(tariff);

            id.forEach((elemId: any, indexId: number) => {//
                thisHour.forEach((hour: any, indexHour: number) => {
                    let contador: number = 0

                    hour.items.forEach((item: any, indexChannel: number) => {
                        contador += Number(allFieldsValue || 0);
                        setValue(`range[${indexId}].stockGeneralOnSaleUp[${arrayIndex}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`, `${allFieldsValue || 0}`)
                        //setValue(`range[${indexId}].stockGeneralOnSaleUp[${arrayIndex}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`, '')
                    });

                    setValue(`range[${indexId}].stockGeneralOnSaleUp[${arrayIndex}].stockAmountsUp[${indexHour}].Amount`, `${contador}`);
                });
            });
        });

        setAllFieldsValue('');
        setModalShowFillAllFields(false);
    }

    return (
        <>
            {tariffsInfo !== null &&
                <>
                    <Card className="bg-white my-4">
                        {
                            loading ?
                                <>
                                    <div className="py-5">
                                        <div className="load primary"></div>
                                    </div>
                                </>
                                :
                                <>
                                    <div className='py-2 px-4'>
                                        <Form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="title_stock">Controle de Estoque</h5>
                                                {isEdit &&
                                                    <div>
                                                        <Button
                                                            variant="outline-primary"
                                                            className="form-button mt-2"
                                                            onClick={() => { setModalShowDeleteStock(true); }}
                                                        >
                                                            Remover Estoque
                                                        </Button>
                                                    </div>
                                                }
                                            </div>
                                            {
                                                tariffsInfo?.data.map((tariff: any, index: any) => {
                                                    let thisHour = organizeData(tariff)
                                                    let weekdays = generateWeekdays(tariffsInfo?.data, tariff);

                                                    /* setValue(`stockGeneralOnSale[${arrayIndex}].stockGeneralRangeDates.startDate`, tariff.tarifStart.split("T")[0])
                                                        setValue(`stockGeneralOnSale[${arrayIndex}].stockGeneralRangeDates.endDate`, tariff.tarifEnd.split("T")[0]) */

                                                    return (
                                                        <>
                                                            <div>
                                                                {index === 0 &&
                                                                    <>
                                                                        <Row className="mb-3">
                                                                            <div className="d-flex justify-content-between">
                                                                                <Form.Label>Selecionar períodos</Form.Label>
                                                                                <Form.Group as={Col} md="6" >
                                                                                    <Row className="mx-0 gap-2 ">
                                                                                        <Controller
                                                                                            control={control}
                                                                                            name="isAllTariff"
                                                                                            render={({ field }: any) => (
                                                                                                <Form.Check
                                                                                                    {...field}
                                                                                                    className="col-12 gap-2  mb-1 d-flex justify-content-end"
                                                                                                    id={"checkbox-1"}
                                                                                                    type="checkbox"
                                                                                                    label={"Selecionar o período inteiro do tarifário"}
                                                                                                    checked={isAllTariff}
                                                                                                    onClick={() => { setIsAllTariff(!isAllTariff) }}
                                                                                                />
                                                                                            )}
                                                                                        />
                                                                                    </Row>
                                                                                </Form.Group>
                                                                            </div>
                                                                            <Form.Group as={Col} mb="6" >
                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`multipleRange`}
                                                                                    render={({ field }: any) => (
                                                                                        <MultipleRange field={field} values={allRanges} setValues={setAllRanges} setId={setId} totalRange={totalRange} setSelectedRange={setSelectedRange} />
                                                                                    )}
                                                                                />
                                                                            </Form.Group>

                                                                        </Row>

                                                                        <Row className="registered-ranges px-3 mb-3">
                                                                            {id.filter((elem: any) => elem.length > 8).map((range: any, indexRange: number) => {
                                                                                let startDate: any = new Date(`${range?.slice(0, 4)}-${range?.slice(4, 6)}-${range?.slice(6, 8)}`).toJSON()?.slice(0, 10);
                                                                                let endDate: any = new Date(`${range?.slice(8, 12)}-${range?.slice(12, 14)}-${range?.slice(14, 16)}`).toJSON()?.slice(0, 10);

                                                                                let aux: any = allRanges.map((elem: any) => {
                                                                                    return (`${new Date(elem[0]).toJSON()?.slice(0, 10).split('-').join('')}${elem.length === 2 ? new Date(elem[1]).toJSON()?.slice(0, 10).split('-').join('') : ''}`)
                                                                                })
                                                                                let registered: any = registeredStockOnRanges.find((elem: any) => elem.formId === `${startDate}${endDate}`)

                                                                                return (
                                                                                    <>
                                                                                        {
                                                                                            aux.includes(`${startDate.split('-').join('')}${endDate.split('-').join('')}`) &&
                                                                                            <Col md={3} /* className="pl-0" */ style={{ paddingLeft: '0px', paddingRight: "25px", marginTop: "25px" }}>
                                                                                                <div className="modality-cards" style={{ boxShadow: "0px 4px 2.5px 0px #70707025" }}>
                                                                                                    <div className={`modality-card-name ${registered ? 'primary' : ''}`} style={{ borderBottom: '1px solid #ccc' }}>
                                                                                                        <Row /* style={{ backgroundColor: "#F2F2F2" }} */>
                                                                                                            <Col md={10} className="d-flex align-items-center px-2">
                                                                                                                <p className='m-0'>
                                                                                                                    {registered ? 'Cadastrado' : 'Nenhum estoque cadastrado'}
                                                                                                                </p>
                                                                                                                {/* <p className='m-0'>{`${startDate.split('-').reverse().join('/')} até ${endDate.split('-').reverse().join('/')}`}</p> */}


                                                                                                                {/* <div className="d-flex flex-column">

                                                                                                            <p className='m-0'>{`${startDate.split('-').reverse().join('/')} até ${endDate.split('-').reverse().join('/')}`}</p>

                                                                                                            {
                                                                                                                registered &&
                                                                                                                <p>Coisado</p>
                                                                                                            }
                                                                                                        </div> */}
                                                                                                            </Col>
                                                                                                            <Col md={2} className="d-flex align-items-center justify-content-end">
                                                                                                                <div>
                                                                                                                    <Dropdown drop="start" className="d-flex justify-content-end">
                                                                                                                        <Dropdown.Toggle variant="light">
                                                                                                                            <FontAwesomeIcon
                                                                                                                                size="lg"
                                                                                                                                icon={["fal", "ellipsis-v"]}
                                                                                                                                className={`${registered ? '' : 'text-primary'}`}
                                                                                                                            />
                                                                                                                        </Dropdown.Toggle>

                                                                                                                        {(selectedRange === indexRange || selectedRange === null) &&
                                                                                                                            <>
                                                                                                                                <Dropdown.Menu>
                                                                                                                                    <Dropdown.Item
                                                                                                                                        onClick={() => {
                                                                                                                                            setSelectedRange(() => selectedRange === indexRange ? null : indexRange)
                                                                                                                                        }}
                                                                                                                                        eventKey="0"
                                                                                                                                    >
                                                                                                                                        <FontAwesomeIcon
                                                                                                                                            icon={["fal", "edit"]}
                                                                                                                                        />
                                                                                                                                        {`${selectedRange === indexRange ? "Cancelar" : "Editar"}`}
                                                                                                                                    </Dropdown.Item>
                                                                                                                                </Dropdown.Menu>
                                                                                                                            </>
                                                                                                                        }
                                                                                                                    </Dropdown>
                                                                                                                </div>
                                                                                                            </Col>
                                                                                                        </Row>
                                                                                                    </div>
                                                                                                    <div className="p-2">
                                                                                                        <h6>Período</h6>
                                                                                                        <p className="m-0">de: &nbsp;<strong>{`${startDate.split('-').reverse().join('/')}`}</strong></p>
                                                                                                        <p className="m-0">até: <strong>{`${endDate.split('-').reverse().join('/')}`}</strong></p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </Col>
                                                                                        }
                                                                                    </>
                                                                                )
                                                                            })}
                                                                        </Row>
                                                                    </>
                                                                }

                                                                {selectedRange !== null && index === 0 &&
                                                                    <div className="mb-3">
                                                                        <Button
                                                                            variant="outline-primary"
                                                                            className="mt-2"
                                                                            onClick={() => { setModalShowFillAllFields(true); }}
                                                                        >
                                                                            Preencher todos os campos
                                                                        </Button>
                                                                    </div>
                                                                }

                                                                {thisHour !== null && selectedRange !== null &&
                                                                    <>
                                                                        <Row className="mb-3">
                                                                            <Col md={4}>
                                                                                <Form.Group className="pr-4">
                                                                                    <div className="d-flex justify-content-between">
                                                                                        <Form.Label className="label-small">
                                                                                            Dias de funcionamento
                                                                                        </Form.Label>
                                                                                    </div>
                                                                                    <div className="d-flex justify-content-between">
                                                                                        {
                                                                                            weekdays.map((elem: any, indexDays: number) => {
                                                                                                if (elem.state === 1) {
                                                                                                    return (
                                                                                                        <div>
                                                                                                            <div className="d-flex w-auto">
                                                                                                                <Controller
                                                                                                                    control={control}
                                                                                                                    name={`domainDaysOfWeek.${elem.day}`}
                                                                                                                    defaultValue={elem.state}
                                                                                                                    render={({ field }: any) => (
                                                                                                                        <Form.Control
                                                                                                                            {...field}
                                                                                                                            type="hidden"
                                                                                                                            variant="standard"
                                                                                                                            margin="normal"
                                                                                                                            autoComplete="off"
                                                                                                                        />
                                                                                                                    )}
                                                                                                                />
                                                                                                                <div>

                                                                                                                    <Form.Label className={elem.value === false ? "btn btn-outline-primary label-small" : "btn btn-primary label-small"}>
                                                                                                                        <span className="text-capitalize">{elem.day}</span>
                                                                                                                    </Form.Label>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                    )
                                                                                                }
                                                                                                else {
                                                                                                    return <></>
                                                                                                }
                                                                                            })
                                                                                        }

                                                                                    </div>
                                                                                </Form.Group>
                                                                            </Col>
                                                                        </Row>

                                                                        {id.map((range: any, indexRange: number) => {
                                                                            let startDate: any = new Date(`${range?.slice(0, 4)}-${range?.slice(4, 6)}-${range?.slice(6, 8)}`).toJSON()?.slice(0, 10);
                                                                            let endDate: any = new Date(`${range?.slice(8, 12)}-${range?.slice(12, 14)}-${range?.slice(14, 16)}`).toJSON()?.slice(0, 10);

                                                                            return (
                                                                                <>
                                                                                    <Row className={`${selectedRange === indexRange ? "d-block" : "d-none"}`}>
                                                                                        {thisHour.map((hour: any, indexHour: any) => {
                                                                                            setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].StartHour`, hour.hora);
                                                                                            setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].EndHour`, '');
                                                                                            setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].formId`, `${startDate}${endDate}`);
                                                                                            setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockGeneralRangeDates.startDate`, startDate);
                                                                                            setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockGeneralRangeDates.endDate`, endDate);
                                                                                            setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockGeneralRangeDates.daysUniqueCode`, hour.daysUniqueTarCode);

                                                                                            return (
                                                                                                <>
                                                                                                    <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                                                                        <Controller
                                                                                                            control={control}
                                                                                                            name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockGeneralRangeDates.startDate`}
                                                                                                            defaultValue={startDate}
                                                                                                            render={({ field }: any) => (
                                                                                                                <Form.Control
                                                                                                                    {...field}
                                                                                                                    type="hidden"
                                                                                                                    variant="standard"
                                                                                                                    margin="normal"
                                                                                                                    autoComplete="off"
                                                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </Form.Group>

                                                                                                    <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                                                                        <Controller
                                                                                                            control={control}
                                                                                                            name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockGeneralRangeDates.endDate`}
                                                                                                            defaultValue={endDate}
                                                                                                            render={({ field }: any) => (
                                                                                                                <Form.Control
                                                                                                                    {...field}
                                                                                                                    type="hidden"
                                                                                                                    variant="standard"
                                                                                                                    margin="normal"
                                                                                                                    autoComplete="off"
                                                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </Form.Group>
                                                                                                    <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                                                                        <Controller
                                                                                                            control={control}
                                                                                                            name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockGeneralRangeDates.daysUniqueCode`}
                                                                                                            defaultValue={hour.daysUniqueTarCode}
                                                                                                            render={({ field }: any) => (
                                                                                                                <Form.Control
                                                                                                                    {...field}
                                                                                                                    type="hidden"
                                                                                                                    variant="standard"
                                                                                                                    margin="normal"
                                                                                                                    autoComplete="off"
                                                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </Form.Group>
                                                                                                    <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                                                                        <Controller
                                                                                                            control={control}
                                                                                                            name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].formId`}
                                                                                                            defaultValue={`${startDate}${endDate}`}
                                                                                                            render={({ field }: any) => (
                                                                                                                <Form.Control
                                                                                                                    {...field}
                                                                                                                    type="hidden"
                                                                                                                    variant="standard"
                                                                                                                    margin="normal"
                                                                                                                    autoComplete="off"
                                                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                    </Form.Group>

                                                                                                    {
                                                                                                        weekdays.map((elem: any, indexDays: number) => {
                                                                                                            if (elem.state === 1) {
                                                                                                                setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].domainDaysOfWeek.${elem.day}`, elem.state)

                                                                                                                return (
                                                                                                                    <Form.Group as={Col} lg="4" mb="4" sm="4">
                                                                                                                        <Controller
                                                                                                                            control={control}
                                                                                                                            name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].domainDaysOfWeek.${elem.day}`}
                                                                                                                            defaultValue={elem.state}
                                                                                                                            render={({ field }: any) => (
                                                                                                                                <Form.Control
                                                                                                                                    {...field}
                                                                                                                                    type="hidden"
                                                                                                                                    variant="standard"
                                                                                                                                    margin="normal"
                                                                                                                                    autoComplete="off"
                                                                                                                                />
                                                                                                                            )}
                                                                                                                        />
                                                                                                                    </Form.Group>
                                                                                                                )
                                                                                                            }
                                                                                                            else {
                                                                                                                return <></>
                                                                                                            }
                                                                                                        })
                                                                                                    }

                                                                                                    <div className="font-sub-title" >{hour.hora.split(':')[0] + ':' + hour.hora.split(':')[1]}</div>
                                                                                                    <Form.Group className="mr-2 mb-3" as={Col} md="2">
                                                                                                        <Form.Label className="">Capacidade Total</Form.Label>
                                                                                                        <Controller
                                                                                                            control={control}
                                                                                                            name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].Amount`}
                                                                                                            defaultValue={''}
                                                                                                            rules={{ required: { value: true, message: "Favor selecionar um valor" } }}
                                                                                                            render={({ field }: any) => (
                                                                                                                <Form.Control
                                                                                                                    {...field}
                                                                                                                    type="text"
                                                                                                                    variant="standard"
                                                                                                                    margin="normal"
                                                                                                                    autoComplete="off"
                                                                                                                    defaultValue={''}
                                                                                                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                    disabled
                                                                                                                //onFocus={(e: any) => { setAmountOnFocus(e.target.value || 0)} }
                                                                                                                //onBlur={(e: any) => { handleBlurTotalAmount(e.target.value, arrayIndex, indexHour) }}
                                                                                                                // onChange={(e) => handleInputChange(e, 'capacidade total', hour.hora)}
                                                                                                                />
                                                                                                            )}
                                                                                                        />
                                                                                                        <ErrorMessage
                                                                                                            errors={errors}
                                                                                                            name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].Amount`}
                                                                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                                                        />
                                                                                                    </Form.Group>

                                                                                                    <Controller
                                                                                                        control={control}
                                                                                                        name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].StartHour`}
                                                                                                        defaultValue={hour.hora}
                                                                                                        render={({ field }: any) => (
                                                                                                            <Form.Control
                                                                                                                {...field}
                                                                                                                type="hidden"
                                                                                                                variant="standard"
                                                                                                                margin="normal"
                                                                                                                autoComplete="off"
                                                                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                            />
                                                                                                        )}
                                                                                                    />
                                                                                                    <Controller
                                                                                                        control={control}
                                                                                                        name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].EndHour`}
                                                                                                        defaultValue={''}
                                                                                                        render={({ field }: any) => (
                                                                                                            <Form.Control
                                                                                                                {...field}
                                                                                                                type="hidden"
                                                                                                                variant="standard"
                                                                                                                margin="normal"
                                                                                                                autoComplete="off"
                                                                                                                onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                            />
                                                                                                        )}
                                                                                                    />

                                                                                                    <span className="mb-2">Capacidade por canal:</span>
                                                                                                    <div className="d-flex mb-5">
                                                                                                        {hour.items.map((item: any, indexChannel: Key) => {
                                                                                                            //let indexChannel = index;

                                                                                                            setValue(`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`, item.canal)

                                                                                                            return (
                                                                                                                <>
                                                                                                                    <Form.Group className="d-flex mr-2">
                                                                                                                        <Form.Label className="nome-canal text-wrap-custom wrap-1">{item.nomeCanal}</Form.Label>
                                                                                                                        <div>
                                                                                                                            <Controller
                                                                                                                                control={control}
                                                                                                                                name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`}
                                                                                                                                render={({ field }: any) => (
                                                                                                                                    <Form.Control
                                                                                                                                        {...field}
                                                                                                                                        type="text"
                                                                                                                                        variant="standard"
                                                                                                                                        margin="normal"
                                                                                                                                        autoComplete="off"
                                                                                                                                        className="input-canal"
                                                                                                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                                                                        onBlur={(e: any) => handleBlurChannelAmount(e.target.value, index, indexRange, indexHour, indexChannel)}
                                                                                                                                    // onChange={(e) => handleInputChangeChanel(e, item.canal, hour.hora)}
                                                                                                                                    />
                                                                                                                                )}
                                                                                                                            />
                                                                                                                            <ErrorMessage
                                                                                                                                errors={errors}
                                                                                                                                name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].Amount`}
                                                                                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                                                                            />
                                                                                                                        </div>
                                                                                                                    </Form.Group>
                                                                                                                    <Controller
                                                                                                                        control={control}
                                                                                                                        name={`range[${indexRange}].stockGeneralOnSaleUp[${index}].stockAmountsUp[${indexHour}].StockAmountsByChannel[${indexChannel}].IdSalesChannel`}
                                                                                                                        defaultValue={item.canal}
                                                                                                                        render={({ field }: any) => (
                                                                                                                            <Form.Control
                                                                                                                                {...field}
                                                                                                                                type="hidden"
                                                                                                                                variant="standard"
                                                                                                                                margin="normal"
                                                                                                                                autoComplete="off"
                                                                                                                            />
                                                                                                                        )}
                                                                                                                    />
                                                                                                                </>
                                                                                                            )
                                                                                                        })}

                                                                                                    </div>


                                                                                                </>
                                                                                            )
                                                                                        })}

                                                                                    </Row>
                                                                                </>
                                                                            )
                                                                        })}
                                                                    </>
                                                                }
                                                            </div>
                                                        </>
                                                    )
                                                })
                                            }

                                            <Row className="mx-0 mt-5 text-right">
                                                {selectedRange === null ?
                                                    <>
                                                        <Button
                                                            variant="outline-primary"
                                                            className="form-button mr-3"
                                                        >
                                                            Voltar
                                                        </Button>

                                                        <Button
                                                            type="submit"
                                                            className="form-button"
                                                        >
                                                            Salvar Estoques
                                                        </Button>
                                                    </>
                                                    :
                                                    <>
                                                        <Button
                                                            type="button"
                                                            variant="outline-primary"
                                                            className="form-button mr-3"
                                                            onClick={() => { setSelectedRange(null) }}
                                                        >
                                                            Cancelar
                                                        </Button>

                                                        <p
                                                            //type="button"
                                                            className="form-button btn btn-primary m-0"
                                                            onClick={() => { registerStockOnSelectedRange() }}
                                                        >
                                                            Confirmar
                                                        </p>
                                                    </>
                                                }
                                            </Row>
                                        </Form>
                                    </div>
                                </>
                        }
                    </Card>
                </>
            }
            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                <div className="btn-sucess">
                    <Modal
                        className="modal-custom modalAuth"
                        show={modalResponse !== null}
                        onHide={() => setModalResponse(null)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            {/* <Modal.Header closeButton></Modal.Header> */}
                            <RequestResponseModal
                                name={"Fornecedor"}
                                modalResponse={modalResponse}
                                setModalResponse={setModalResponse}
                                errors={errors}
                                modalText={modalText}
                                log={log}
                            />
                        </>
                    </Modal>
                </div>
            </Row>

            <Modal
                className="modal-custo modalAuth"
                show={modalShowFillAllFields}
                onHide={() => setModalShowFillAllFields(false)}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
            >
                <div className="" style={{ padding: '30px' }}>
                    <div className=" rounded-0" >
                        <div>
                            <h4 className="pt-1 mb-4 text-primary title-reservation">Distribuir quantidades</h4>
                        </div>
                    </div>
                    <Form.Group className="mr-2 mb-3" as={Col} md="12">
                        <div className="d-flex">

                            <Form.Label className="">Selecione uma quantidade</Form.Label>
                            <OverlayTrigger
                                overlay={
                                    <Tooltip id="tooltip">A quantidade selecionada será replicada em todos os canais disponíveis para esse período!</Tooltip>
                                }>
                                <span className="text-wrap-custom">
                                    <FontAwesomeIcon
                                        className="ml-2"
                                        icon={["fal", "question-circle"]}
                                    />
                                </span>
                            </OverlayTrigger>
                        </div>
                        <Controller
                            control={control}
                            name={`allFields`}
                            defaultValue={''}
                            render={({ field }: any) => (
                                <Form.Control
                                    {...field}
                                    type="text"
                                    variant="standard"
                                    margin="normal"
                                    autoComplete="off"
                                    defaultValue={''}
                                    value={allFieldsValue}
                                    onChange={(e: any) => { setAllFieldsValue(e.target.value) }}
                                    onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                />
                            )}
                        />
                    </Form.Group>
                    <div className="d-flex justify-content-end">
                        <Button
                            type="button"
                            variant="outline-primary"
                            className="form-button mr-3"
                            onClick={() => { setModalShowFillAllFields(false) }}
                        >
                            Cancelar
                        </Button>

                        <p
                            //type="button"
                            className="form-button btn btn-primary m-0"
                            onClick={() => { fillAllFields() }}
                        >
                            Confirmar
                        </p>
                    </div>
                </div>
            </Modal>

            <ModalQuestion
                modalShow={modalShowConflict}
                setModalShow={setModalResponse}
                titulo="Exportar para ERP"
                mensagem="Conflito de datas. Deseja sobrescrever os estoques das datas em conflito?"
                handleActionConfirmModal={handleActionConflictModal}
                handleActionCancelModal={handleActionCancelConflictModal}
            />

            <ModalQuestion
                modalShow={modalShowDeleteStock}
                setModalShow={setModalShowDeleteStock}
                titulo=""
                mensagem="Deseja remover o estoque desse tarifário?"
                handleActionConfirmModal={() => { setModalShowDeleteStock(false); deleteStock(); }}
                handleActionCancelModal={() => { setModalShowDeleteStock(false) }}
            />

        </>
    );
}



export default TableStock;
