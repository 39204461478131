import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField?: any,
    propsLabel?: any,
    propsErrors?: any,
    actionAgents?: any,
    setClient?: any
}

const GetAgentsDetails: React.FC<propPlus> = ({
    propsField, propsLabel, propsErrors, actionAgents, setClient
}: propPlus) => {

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const [check, setCheck] = useState<any>(true);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { Authorization: `Bearer ${token}` },
        };

        async function listAgents() {
            try {
                const { data } = await api.get(`/BillsReceivable/GetAgents`, config);
                if (data.status !== 400) {
                    setInfo(data.data);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + "/";
                }
            }
        }
        listAgents();
    }, []);

    const handleChange = (options: any) => {
        //setClient(options)
        if (actionAgents)
            actionAgents(options)
        setCheck(false)
    };

    if (info !== null) {
        return (
            <>
                <Select
                    className="endpoint"
                    onMenuOpen={onMenuOpen}
                    onMenuClose={onMenuOpen}
                    onChange={handleChange}
                    options={Array.from(info)}
                    /* defaultValue={{ compFantasia: "Teste Cliente BA", supplierIdentity: "CLI-JC98894" }} */
                    getOptionValue={option => option.supplierIdentity}
                    getOptionLabel={(option: any) => `${option.compFantasia}`}
                    placeholder={`${setClient}`}
                />
                {
                    check === true
                        ?
                        alert
                        :
                        ""
                }
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )

    }
};

export default GetAgentsDetails;