import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

import './ListMerchantFlags.scss';

//COMPONENTS
import MerchantFlagsFilter from '../components/MerchantFlagsFilter/MerchantFlagsFilter';
import MerchantFlagsTable from '../components/MerchantFlagsTable/MerchantFlagsTable';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';

function ListMerchantFlags() {
  //const { t } = useTranslation();
  const [merchantFlags, setMerchantFlags] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  const [actualName, setActualName] = useState<any>("");
  const [actualStatus, setActualStatus] = useState<any>(0);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */


  useEffect(() => {
    setMerchantFlags(null);
    setLoading(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listMerchantFlags() {
      try {
        const { data } = await api.post('MerchantAccount/GetMerchantFlagsReportAsync',
          {
            "page": pageCount,
            "rowsPerPage": rowsPerPage,
            "descricao": actualName,
            "status": actualStatus || 0
          }, config
        );

        if (data.status !== 400) {
          setLoading(false);
          setMerchantFlags(data.data.rows);
          setTotalRows(data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listMerchantFlags();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  async function buscar(name: string, status: any) {
    setMerchantFlags(null);
    setActualName(name);
    setLoading(true);
    setActualStatus(status);
    setPageCount(1);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {
      const res = await api.post('/MerchantAccount/GetMerchantFlagsReportAsync',
        {
          "page": 1,
          "rowsPerPage": rowsPerPage,
          "descricao": name,
          "status": (status === '') ? 0 : status,
        }, config
      );
      if (res.status !== 400) {
        setLoading(false);
        setMerchantFlags(res.data.data.rows);
        setTotalRows(res.data.data.rowsCount);
      }
    } catch (error: any) {
      setLoading(false);
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
    }
  };

  function clearActualValues() {
    setActualName("");
    setActualStatus(0);
  }

    return (
      <>
        <DesktopDefault>
            <div className="container-fluid content-dashboard">

              <Breadcrumb title={'Financeiro / Parâmetros / Lista de Bandeiras Cartão'} />

              <div className="accounts mt-4">
                <h2 className="title">Lista de Bandeiras Cartão</h2>
              </div>

              <MerchantFlagsFilter
                buscar={buscar}
                clearActualValues={clearActualValues}
              />

              <MerchantFlagsTable
                merchantFlags={merchantFlags}
                totalRows={totalRows}
                pageCount={pageCount}
                setPageCount={setPageCount}
                rowsPerPage={rowsPerPage}
                setRowsPerPage={setRowsPerPage}
                loading={loading}
              />
            </div>
        </DesktopDefault>

      </>

    );
}

export default ListMerchantFlags;