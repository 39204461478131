import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";

import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import * as signalR from "@microsoft/signalr";

import "./ModalQrCodePixPayment.scss";
import api from "../../../../../../services/api";

import picpay from "../../../assets/icons/picpay_logo.png";

export interface propContent {
    modalContent: any;
    checkoutResponse: any;
    resetCart: any;
    setModalResponse: any;
    //pix: boolean;
    //getValues: any;
    watchLanguage?: any
}

const ModalQrCodePixPayment: React.FC<propContent> = ({
    modalContent,
    checkoutResponse,
    resetCart,
    setModalResponse,
    //pix,
    //getValues,
    watchLanguage
}: propContent) => {
    const { t } = useTranslation();

    const [downloadTicketIntegration, setDownloadTicketIntegration] = useState<boolean>(false);
    const [urlDownload, setUrlDownload] = useState<string>("");
    const [fileName, setFileName] = useState<string>("Voucher");
    const [texts, setTexts] = useState<any>();
    const [scanResponse, setScanResponse] = useState<any>(null); // pixSuccess / pixFailed / pixExpired
    const [pixResponse, setPixResponse] = useState<any>();

    const [minutes, setMinutes] = useState<any>(10);
    const [seconds, setSeconds] = useState<any>(0);

    const downloadVou = () => {
        const link = document.createElement("a");
        link.href = urlDownload;
        link.setAttribute("download", fileName);

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
    };

    // const getVou = async () => {
    //     //setDownloadTicketIntegration(true);

    //     const token = localStorage.getItem("GroupId") || "{}";
    //     const config = {
    //         headers: {
    //             Authorization: `Bearer ${token}`,
    //             "Content-Type": "application/json",
    //         },
    //     };
    //     try {
    //         const { data } = await api.get(
    //             `${process.env.REACT_APP_SERVER_URL_API}/checkout/PdfSecondWayHaveNovaXs/${checkoutResponse.data.data.reservationLoc}`,
    //             config
    //         );
    //         if (data.status !== 400) {
    //             if (data.data.log !== 1) {
    //                 setUrlDownload(
    //                     `data:${data.data.data.voucherFile?.contentType};base64, ${data.data.data.voucherFile?.content}`
    //                 );
    //                 setFileName(data.data.data.voucherFile?.fileName);
    //                 setDownloadTicketIntegration(false);
    //             }
    //         }
    //     } catch (error: any) { }
    // };

    // useEffect(() => {
    //     if (checkoutResponse !== "" && checkoutResponse?.data?.log === 0) {
    //         setUrlDownload(
    //             `data:${checkoutResponse.data.data.voucherFile?.contentType};base64, ${checkoutResponse.data.data.voucherFile?.content}`
    //         );
    //         setDownloadTicketIntegration(checkoutResponse.data.data.novaXsMultiClubs);
    //         setFileName(checkoutResponse.data.data.voucherFile?.fileName);

    //         if (checkoutResponse?.data?.data?.novaXsMultiClubs === true) {
    //             let myInterval = setInterval(() => {
    //                 getVou();
    //             }, 45000);

    //             return () => {
    //                 clearInterval(myInterval);
    //             };

    //             /* setTimeout(() => {
    //                                 getVou();
    //                             }, (checkoutResponse.data.data.awaitTime * 1000)); */
    //         }
    //     }

    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [checkoutResponse]);

    useEffect(() => {
        let myInterval = setInterval(() => {
            if (seconds > 0) {
                setSeconds(seconds - 1);
            }
            if (seconds === 0) {
                if (minutes === 0) {
                    clearInterval(myInterval);
                } else {
                    setMinutes(minutes - 1);
                    setSeconds(59);
                }
            }
        }, 1000);
        return () => {
            clearInterval(myInterval);
        };
    });

    useEffect(() => {
        let timer1 = setTimeout(() => setTexts(t("modalPayment.loading01")), 1000);
        let timer2 = setTimeout(() => setTexts(t("modalPayment.loading02")), 2000);
        let timer3 = setTimeout(() => setTexts(t("modalPayment.loading03")), 3000);
        let timer4 = setTimeout(() => setTexts(t("modalPayment.loading04")), 4000);

        return () => {
            clearTimeout(timer1);
            clearTimeout(timer2);
            clearTimeout(timer3);
            clearTimeout(timer4);
        };
    }, [t]);

    useEffect(() => {
        if (modalContent === "success") {
            if (
                parseInt(checkoutResponse.data.log) === 0 &&
                checkoutResponse.data.data !== undefined
            ) {
                let connection = new signalR.HubConnectionBuilder()
                    .withUrl(process.env.REACT_APP_SERVER_URL_API_PIX + "/streaming/")
                    .build();

                connection.on(checkoutResponse.data.data.channelPix, (data) => {
                    if (data.log === 0) {
                        //success
                        console.log(data);
                        setPixResponse(data);
                        setScanResponse("pixSuccess");
                    } else if (data.log === 1) {
                        //error
                        setPixResponse(data);
                        setScanResponse("pixFailed");
                    }
                });

                connection
                    .start()
                    .then(function () {
                        connection.invoke(
                            "JoinToGroup",
                            checkoutResponse.data.data.channelPix
                        );
                    })
                    .catch(function (err) {
                        return console.error(err.toString());
                    });
                let closeChannel = setTimeout(() => {
                    if (scanResponse !== "pixSuccess" || scanResponse !== "pixFailed") {
                        setScanResponse("pixExpired");
                    }
                    connection.stop();
                }, 600000 /* 660000 */);
                return () => {
                    clearTimeout(closeChannel);
                };
            }
        } else {
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalContent]);

    function copyText(text: string | null = null) {
        if (text === null) {
            var text_to_copy = (document.getElementById("qrcodelink") as HTMLElement)
                .innerHTML;

            if (!navigator.clipboard) {
            } else {
                navigator.clipboard.writeText(text_to_copy);
            }
        } else {
            const urlOrigin: any = text.split("?")[0]
            const token: any = text.split("?")[1];
            let lang: any = "";

            if(watchLanguage === '1') {
                lang = "?";
            } else if (watchLanguage === '2') {
                lang = "/en?";
            } else {
                lang = "/es?";
            }
            
            const link:any = urlOrigin + lang + token;

            if (!navigator.clipboard) {
            } else {
                navigator.clipboard.writeText(link);
            }
        }
    }

    // function whatsappFunction(link: string | null = null) {
    //     if (link !== null) {
    //         link = link.replaceAll(":", "%3A");
    //         link = link.replaceAll("/", "%2F");
    //         link = link.replaceAll("?", "%3F");
    //         let whatsappLink: string = `https://wa.me/${getValues("phone").replaceAll('+','').replaceAll(' ','')}?text=${link}`;
    //         return (whatsappLink)
    //     } else {
    //         return '';
    //     }
    // }

    if (modalContent === "loading") {
        return (
            <div className="sucess-pos" id="wrapper">
                <div className="text-center">
                    <div className="load"></div>
                    {/* <h5>{t("modalPayment.finalizingPurchase")}</h5>
                    <p>{texts}</p> */}
                    <h5>Processando...</h5>
                </div>
            </div>
        );
    } else if (modalContent === "success") {
        if (
            checkoutResponse?.data?.log === 1 ||
            checkoutResponse?.data?.log === "1"
        ) {
            // Erro
            return (
                <div className="sucess-pos" id="wrapper">
                    <div className="text-center">
                        {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
                        <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            className="color-danger"
                        />
                        <h4 className="mt-4">{t("modalPayment.creditCard.error")}</h4>
                        <p>{checkoutResponse.data.texto}</p>
                        {/* <p>Tente novamente com outros dados de pagamento.<br />
                            Se persistir, entre em contato pelo e-mail <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a></p> */}
                    </div>
                    <div className="modal-btn-controller">
                        <Button
                            className="my-5 mx-auto"
                            onClick={() => {
                                setModalResponse(null);
                            }}
                        >
                            {t("modalPayment.creditCard.success.closeBtn")}
                        </Button>
                    </div>
                </div>
            );
        } else {
            //sucesso pix
            const pixInfo = checkoutResponse?.data?.data;
            //const pixInfo = checkoutResponse?.data?.data[0]?.data;
            //const isPicpay: boolean =
            //    checkoutResponse.data?.data[0]?.data?.merchantPayMethod === 8;
            //const isQRCode: boolean =
            //    checkoutResponse.data?.data.paymentLink !== undefined;
            //const qrCodeInfo = isQRCode ? checkoutResponse?.data?.data : null;

            
            if (scanResponse === null /* && !isPicpay && !isQRCode*/) {
                return (
                    <>
                        <div className="pix-container">
                            <svg
                                style={{ width: "62px", height: "62px" }}
                                className="mb-4"
                                xmlns="http://www.w3.org/2000/svg"
                                viewBox="0 0 512 512"
                            >
                                <path
                                    fill="#32bcad"
                                    d="M242.4 292.5C247.8 287.1 257.1 287.1 262.5 292.5L339.5 369.5C353.7 383.7 372.6 391.5 392.6 391.5H407.7L310.6 488.6C280.3 518.1 231.1 518.1 200.8 488.6L103.3 391.2H112.6C132.6 391.2 151.5 383.4 165.7 369.2L242.4 292.5zM262.5 218.9C256.1 224.4 247.9 224.5 242.4 218.9L165.7 142.2C151.5 127.1 132.6 120.2 112.6 120.2H103.3L200.7 22.76C231.1-7.586 280.3-7.586 310.6 22.76L407.8 119.9H392.6C372.6 119.9 353.7 127.7 339.5 141.9L262.5 218.9zM112.6 142.7C126.4 142.7 139.1 148.3 149.7 158.1L226.4 234.8C233.6 241.1 243 245.6 252.5 245.6C261.9 245.6 271.3 241.1 278.5 234.8L355.5 157.8C365.3 148.1 378.8 142.5 392.6 142.5H430.3L488.6 200.8C518.9 231.1 518.9 280.3 488.6 310.6L430.3 368.9H392.6C378.8 368.9 365.3 363.3 355.5 353.5L278.5 276.5C264.6 262.6 240.3 262.6 226.4 276.6L149.7 353.2C139.1 363 126.4 368.6 112.6 368.6H80.78L22.76 310.6C-7.586 280.3-7.586 231.1 22.76 200.8L80.78 142.7H112.6z"
                                />
                            </svg>
                            <h6>{t("modalPayment.pix.pixText01")}</h6>
                            {/* <p>Faça o pagamento via Pix. O processo sera concluído assim que a confirmação do pagamento for efetuada.</p> */}
                            <h4>{t("modalPayment.pix.qrCode")}</h4>
                            {pixInfo?.bookingLoc !== undefined ? (
                                <>
                                    <p className="m-0">
                                        Bookloc: <strong>{pixInfo?.bookingLoc}</strong>
                                    </p>
                                    <p className="m-0">
                                        Total:{" "}
                                        <strong>
                                            R$ {pixInfo?.totalAmount.toFixed(2).split(".").join(",")}
                                        </strong>
                                    </p>
                                </>
                            ) : (
                                <></>
                            )}
                            <img
                                src={"data:image/png;base64," + pixInfo?.urlQrCode.toString()}
                                className="qr-code"
                                alt="QR Code"
                            />
                            <div className="pix-separator">
                                <hr />
                                <p>{t("modalPayment.pix.or")}</p>
                            </div>
                            <h4>
                                {t("modalPayment.pix.pixCopy")} {"&"}{" "}
                                {t("modalPayment.pix.pixPaste")}
                            </h4>
                            <div className="pix-copy-paste">
                                <p id="qrcodelink" /* className="d-none" */>
                                    {pixInfo?.urlCopyAndPaste}
                                </p>
                                <button className="btn text-uppercase" onClick={() => { copyText() }}>
                                    <strong>{t("modalPayment.pix.copyBtn")}</strong>
                                </button>
                            </div>

                            <p className="text-align-center">
                                {t("modalPayment.pix.expire")}
                                <br />
                                {minutes === 0 && seconds === 0 ? null : (
                                    <h2>
                                        {" "}
                                        {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
                                    </h2>
                                )}
                            </p>
                        </div>
                    </>
                );
            } 
            // else if (scanResponse === null && isPicpay) {
            //     return (
            //         <>
            //             <div className="pix-container">
            //                 <img
            //                     src={picpay}
            //                     alt=""
            //                     style={{ width: "62px", height: "62px" }}
            //                     className="mb-4"
            //                 />
            //                 <h6>{t("modalPayment.picpay.picpayText01")}</h6>
            //                 <p>{t("modalPayment.picpay.picpayText02")}</p>
            //                 <h4>{t("modalPayment.pix.qrCode")}</h4>
            //                 {pixInfo?.bookingLoc !== undefined ? (
            //                     <>
            //                         <p className="m-0">
            //                             Bookloc: <strong>{pixInfo?.bookingLoc}</strong>
            //                         </p>
            //                         <p className="m-0">
            //                             Total:{" "}
            //                             <strong>
            //                                 R$ {pixInfo?.totalAmount.toFixed(2).split(".").join(",")}
            //                             </strong>
            //                         </p>
            //                     </>
            //                 ) : (
            //                     <></>
            //                 )}
            //                 <img
            //                     src={(pixInfo?.urlQrCode).toString()}
            //                     className="qr-code"
            //                     alt="QR Code"
            //                 />

            //                 <p className="text-align-center">
            //                     {t("modalPayment.pix.expire")}
            //                     <br />
            //                     {minutes === 0 && seconds === 0 ? null : (
            //                         <h2>
            //                             {" "}
            //                             {minutes}:{seconds < 10 ? `0${seconds}` : seconds}
            //                         </h2>
            //                     )}
            //                 </p>
            //             </div>
            //         </>
            //     );
            // } else if (scanResponse === null && isQRCode) {
            //     return (
            //         <>
            //             <div className="pix-container">
            //                 <img className='logo-main' src={process.env.REACT_APP_SERVER_LOGO_SHORT} alt="Marca SIG" style={{ width: process.env.REACT_APP_SERVER_LOGO_WIDTH_HEADER, padding: '10px', cursor: 'pointer' }} />

            //                 <h6>{t("modalPayment.stripe.stripeText01")}</h6>
            //                 <p>{t("modalPayment.stripe.stripeText02")}</p>
            //                 <h4>{t("modalPayment.pix.qrCode")}</h4>

            //                 <img
            //                     src={"data:image/png;base64," + (qrCodeInfo?.qrCode).toString()}
            //                     className="qr-code"
            //                     alt="QR Code"
            //                 />

            //                 {/* <a className="whatsapp-link" href={whatsappFunction(qrCodeInfo?.paymentLink)} target="_blank" rel="noopener noreferrer">

            //                     <button className="btn whatsapp-btn text-uppercase d-flex">
            //                         <div style={{ height: "18px" }}>
            //                             <FontAwesomeIcon
            //                                 icon={['fab', 'whatsapp']}
            //                             />
            //                         </div>
            //                         &nbsp;
            //                         <strong>Whatsapp</strong>
            //                     </button>
            //                 </a> */}

            //                 <div className="modal-btn-controller">
            //                     <Button
            //                         variant="outline-primary"
            //                         className="mt-4 mx-auto"
            //                         type="button"
            //                         style={{ width: 'unset' }}
            //                         onClick={() => { copyText(qrCodeInfo?.paymentLink) }}
            //                     >
            //                         Copiar link
            //                     </Button>
            //                 </div>
            //             </div>
            //         </>
            //     );
            // }
             else if (scanResponse === "pixSuccess") {
                return (
                    <>
                        <div className="sucess-pos" id="wrapper">
                            <div className="text-center">
                                {/* <img className="mb-3" src={IconSucess} alt="Sucesso" /> */}
                                <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="color-primary"
                                />
                                <h4 className="mt-4">
                                    Sucesso
                                </h4>
                                <p className="color-success">
                                    {/* {t("modalPayment.creditCard.success.purchaseSuccess")} */}
                                    {pixResponse?.texto}
                                </p>
                                <p>
                                    {t("modalPayment.creditCard.success.locNumber")}{" "}
                                    <strong>{pixResponse?.data?.reservationLoc}</strong>
                                </p>
                                {/* <p className="mb-0">
                                    <small>{t("modalPayment.creditCard.success.voucher")}</small>
                                </p> */}
                                {/* <p><small>{t("modalPayment.creditCard.success.contact")}</small></p>
                                <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}

                                {/* <p>
                                    <small>
                                        <em>{t("modalPayment.creditCard.success.spam")}</em>
                                    </small>
                                </p> */}
                            </div>
                            <div className="modal-btn-controller">
                                <Button
                                    variant="outline-primary"
                                    className="mt-5 mx-auto"
                                    onClick={() => {
                                        resetCart();
                                    }}
                                >
                                    {t("modalPayment.creditCard.success.closeBtn")}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else if (scanResponse === "pixFailed") {
                return (
                    <>
                        <div className="sucess-pos" id="wrapper">
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={["fal", "times-circle"]}
                                    size="5x"
                                    className="color-danger"
                                />
                                <h4 className="mt-4">
                                    {t("modalPayment.pix.pixFailed.text01")}
                                </h4>
                                <p>
                                    {/* {t("modalPayment.pix.pixFailed.text02")}
                                    <br />
                                    {t("modalPayment.pix.pixFailed.text03")}{" "} */}
                                    {/* <a href="mailto:sac@bondinho.com.br">sac@bondinho.com.br</a> */}
                                </p>
                            </div>
                            <div className="modal-btn-controller">
                                <Button
                                    className="my-5 mx-auto"
                                    onClick={() => {
                                        setModalResponse(null);
                                    }}
                                >
                                    {t("modalPayment.creditCard.success.closeBtn")}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else if (scanResponse === "pixExpired") {
                return (
                    <>
                        <div className="sucess-pos" id="wrapper">
                            <div className="text-center">
                                <FontAwesomeIcon
                                    icon={["fal", "exclamation-circle"]}
                                    size="5x"
                                    className="color-info"
                                />
                                <h4 className="mt-4">{t("modalPayment.pix.expired")}</h4>
                                <p className="mb-0">
                                    <small>Caso tenha realizado o pagamento, verifique se o pagemento foi processado!</small>
                                </p>
                            </div>
                            <div className="modal-btn-controller">
                                <Button
                                    className="my-5 mx-auto"
                                    onClick={() => {
                                        resetCart();
                                    }}
                                >
                                    {t("modalPayment.creditCard.success.closeBtn")}
                                </Button>
                            </div>
                        </div>
                    </>
                );
            } else {
                return <></>;
            }
        } 
    } else if (modalContent === "error") {
        return (
            <div className="sucess-pos" id="wrapper">
                <div className="text-center">
                    <FontAwesomeIcon
                        icon={["fal", "times-circle"]}
                        size="5x"
                        className="color-danger"
                    />
                    <h4 className="mt-4">{t("modalPayment.pix.pixFailed.text01")}</h4>
                    {/*<p>
                        {t("modalPayment.pix.pixFailed.text02")}
                        <br />
                        {t("modalPayment.pix.pixFailed.text03")}{" "}
                    </p> */}
                </div>
                <div className="modal-btn-controller">
                    <Button
                        className="my-5 mx-auto"
                        onClick={() => {
                            setModalResponse(null);
                        }}
                    >
                        {t("modalPayment.creditCard.success.closeBtn")}
                    </Button>
                </div>
            </div>
        );
    } else {
        return <></>;
    }
};

export default ModalQrCodePixPayment;