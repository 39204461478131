import React, { useEffect, useState, Key } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Modal, Dropdown } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";

import api from "../../../../services/api";

import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

//STYLES
import "./TableManifestResponsive.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";

import CreateSession from "./components/CreateSession/CreateSession";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import ModalSessionMessages from "../../../../components/Modal/ModalSessionMessages/ModalSessionMessages";
import { Table, Tbody, Td, Th, Tr } from "react-super-responsive-table";
import ExecuteAIModel from "../ExecuteAIModel/ExecuteAIModel";

export interface propTable {
  manifestObj: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportManifest: any;
  printManifest: any;
  loading: any;
}

const TableManifestResponsive: React.FC<propTable> = ({
  manifestObj,
  totalRows,
  pageCount,
  setPageCount,
  rowsPerPage,
  setRowsPerPage,
  exportManifest,
  printManifest,
  loading,
}: propTable) => {
  /* VARIÁVEIS */
  const { t } = useTranslation();

  //const [selectedSingleRow, setSelectedSingleRow] = useState<any>();
  const [modalShow, setModalShow] = useState<any>(false);
  
  const [sessionSelected, setSessionSelected] = useState<any>(null);
  const [sessionDeleteModalShow, setSessionDeleteModalShow] =
  useState<any>(false);
  
  const [sessionMessagesId, setSessionMessagesId] = useState<any>(null);
  const [sessionMessageList, setSessionMessageList] = useState<any>([]);
  const [sessionMessagesShow, setSessionMessagesShow] = useState<any>(false);
  const [width, setWidth] = useState<any>(window.innerWidth);
  
  /* Success Modal (Criar Session) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Criar Session) */
  
  /* Modal Coginitivo */
  const [aiModelSuccess, setAiModelSuccess] = useState<boolean>(false);
 const [modalCognitivoOpen, setModalCognitivoOpen] = useState<boolean>(false);
 const [cognitivoSessionId, setCognitivoSessionId] = useState<string>('');  
 /* END - Modal Coginitivo */

 /* FUNÇÕES */

 /* async function settleBills(row: any) {
       setSelectedSingleRow(row);  //recebe array vazio ([]) para liquidar consulta
       setModalShow(true);
   } */

 /* function convertValue(cell: any) {
       return (
           <>
               <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
           </>
       )
   } */

 function handleReload() {
   window.location.reload();
 }

 useEffect(() => {
  if(aiModelSuccess === true) handleReload()
 }, [aiModelSuccess])

 /*converte a data UTC para a data local do usuário*/
 /* function convertUTCDateToLocalDate(date: any) {
       var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

       var offset = date.getTimezoneOffset() / 60;
       var hours = date.getHours();

       newDate.setHours(hours - offset);

       return newDate.toLocaleString().split(' ')[0];
   } */

 function convertDate(cell: any, row: any) {
   if (cell !== null) {
     //var date: any = convertUTCDateToLocalDate(new Date(cell));
     var aux: any = cell.split("T")[0].split("-");
     var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;

     return (
       <>
         <div
           className="d-flex align-items-center"
           style={{ flexDirection: "column" }}
         >
           <span>{date}</span>
           <span>{row.horaOperacao}</span>
         </div>
       </>
     );
   } else {
     return <></>;
   }
 }

 function handleDeleteClick(row: any) {
   setSessionSelected(row);
   setSessionDeleteModalShow(true);
 }

 const  handleModalCognitivoClick = async(row: any)=> {
   setModalCognitivoOpen(true);   
   setCognitivoSessionId(row);
 }

 function handleActionSessionDeleteModal(e: any) {
   setModalContent("loading");
   setModalSuccess(true);

   const salvar = async () => {
     const token = localStorage.getItem("GroupId") || "{}";
     const config = {
       headers: { Authorization: `Bearer ${token}` },
     };
     try {
       const { data } = await api.post(
         "Manifest/DeleteSession",
         {
           sessionid: sessionSelected.sessionReloc,
         },
         config
       );
       if (data.status !== 400) {
         var response = data.data;

         setResponseText(response.texto);
         setLog(response.log);

         if (response.log !== 1) {
           setModalContent("success"); // <----- nada apareceu, so excluiu

           setSessionDeleteModalShow(false);
           setSessionSelected(null);
         } else {
           setModalContent("error");
         }
       }
     } catch (error: any) {
       if (error?.response?.status === 401) {
         window.location.href = window.location.origin + "/";
       }
       if (error?.response?.status === 400) {
         //window.location.href = window.location.origin + '/session-closure';
       }
       //;
     }
   };
   salvar();
 }

 async function handleSessionMessagesClick(cell: any) {
   setSessionMessagesId(cell);
   //setSessionMessagesShow(true);
   await getSessionMessages(cell);
 }

 const getSessionMessages = async (sessionId: any) => {
   const token = localStorage.getItem('GroupId') || '';

   const config = {
     headers: { 'Authorization': `Bearer ${token}` },
   };
   try {

       const { data } = await api.post(`Manifest/GetSessionMessagesAsync`, {
         "sessionId": sessionId
       }, config);
       if (data.status !== 400) {
           var response = data.data;

           setSessionMessageList(response);
           setSessionMessagesShow(true);
       }   
   }catch(error: any) {
       if (error?.response?.status === 401) {
           window.location.href = window.location.origin + '/';              
       }
       if (error?.response?.status === 400) {
           //window.location.href = window.location.origin + '/session-closure';              
       }
       //;
   }
 }

 /* SOBRE A TABELA */

 function addActionButton(cell: any, row: any) {
   return (
     <>
       <div className="d-flex justify-content-center">
         <Dropdown drop="start">
           <Dropdown.Toggle variant="light ">
             <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
           </Dropdown.Toggle>

           <Dropdown.Menu>
             {row.tipo === "Tour" ? (
               <>
                 <Dropdown.Item
                   eventKey="1"
                   onClick={() => {
                     window.open(
                       `single-session?id=${cell}?operation=${
                         row.operacao === "Própria" ? 1 : 2
                       }?status=${
                         row.status === "Pendente"
                           ? 0
                           : row.status === "Em Operação"
                           ? 1
                           : 2
                       }`,
                       "_blank"
                     );
                   }}
                 >
                   <FontAwesomeIcon icon={["fal", "edit"]} className="mr-2" />
                   Ver
                 </Dropdown.Item>
                 <Dropdown.Item
                   eventKey="2"
                   onClick={() => handleSessionMessagesClick(cell)}
                 >
                   <FontAwesomeIcon
                     icon={["fal", "envelope"]}
                     className="mr-2"
                   />{" "}
                   Mensagens
                 </Dropdown.Item>
                 <Dropdown.Item
                   eventKey="3"
                   onClick={() => handleModalCognitivoClick(cell)}
                 >
                   <FontAwesomeIcon
                     icon={["fal", "brain"]}
                     className="mr-2"
                   />{" "}
                   Cognitivo
                 </Dropdown.Item>
                 <Dropdown.Item
                   eventKey="4"
                   disabled={row.items.length !== 0}
                   onClick={() => handleDeleteClick(row)}
                   className="text-danger remove"
                 >
                   <FontAwesomeIcon
                     icon={["fal", "trash-alt"]}
                     className="mr-2"
                   />{" "}
                   {t("suppliersTable.remove")}
                 </Dropdown.Item>
               </>
             ) : row.tipo === "Transfer" ? (
               <Dropdown.Item
                 eventKey="1"
                 onClick={() => {
                   window.open(`single-session-transfer?id=${cell}`, "_blank");
                 }}
               >
                 <FontAwesomeIcon
                   icon={["fal", "edit"]}
                   //className="mr-2"
                 />
                 Ver
               </Dropdown.Item>
             ) : (
               <Dropdown.Item
                 eventKey="1"
                 onClick={() => {
                   window.open(`single-session?id=${row.id}?operation=${
                         row.operacao === "Própria" ? 1 : 2
                       }?status=${
                         row.status === "Pendente"
                           ? 0
                           : row.status === "Em Operação"
                           ? 1
                           : 2
                       }`, "_blank");
                 }}
               >
                 <FontAwesomeIcon
                   icon={["fal", "edit"]}
                   //className="mr-2"
                 />
                 Ver
               </Dropdown.Item>
             )}
           </Dropdown.Menu>
         </Dropdown>
       </div>
     </>
   );
 }

 function addStatus(cell: any, row: any) {
   return (
     <div
       className={
         cell === "Alocação Parcial" || cell === "Alocação Total"
           ? "resumo bg-parcial w-100"
           : cell === "Emitido"
           ? "resumo bg-emitido"
           : cell === "Pendente"
           ? "resumo bg-pendente"
           : cell === "Pendente Escala" || cell === "Pendente Aceite"
           ? "resumo bg-pendente-escala"
           : cell === "Confirmado"
           ? "resumo bg-emitido"
           : cell === "Em Operação"
           ? "resumo bg-emitido"
           : "resumo"
       }
     >
       {cell}
       <div className="resumo-container">
         <h6 className="">Resumo</h6>
         <span>
           {row.horaOperacao} - {row.descricao}
           {row.guia ? " - Guia " + row.guia : ""}
         </span>
         <div className="d-flex my-2">
           {row.resourcesStatus?.length > 0 ? (
             row.resourcesStatus.map((elem: any, index: Key) => {
               if (elem.tipo === 1) {
                 return (
                   <>
                     <p
                       className={
                         elem.status === 0 || elem.status === 1
                           ? "icon-inactive"
                           : elem.status === 2
                           ? "icon-solicitado"
                           : "icon-active"
                       }
                     >
                       <FontAwesomeIcon
                         file-invoice
                         icon={["fal", "bus"]}
                         size="2x"
                         style={{ padding: "5px" }}
                       />
                     </p>
                   </>
                 );
               } else if (elem.tipo === 2) {
                 return (
                   <>
                     <p
                       className={
                         elem.status === 0 || elem.status === 1
                           ? "icon-inactive"
                           : elem.status === 2
                           ? "icon-solicitado"
                           : "icon-active"
                       }
                     >
                       <FontAwesomeIcon
                         icon={["fal", "address-card"]}
                         size="2x"
                         style={{ padding: "5px" }}
                       />
                     </p>
                   </>
                 );
               } else if (elem.tipo === 3) {
                 return (
                   <>
                     <p
                       className={
                         elem.status === 0 || elem.status === 1
                           ? "icon-inactive"
                           : elem.status === 2
                           ? "icon-solicitado"
                           : "icon-active"
                       }
                     >
                       <FontAwesomeIcon
                         icon={["fal", "users"]}
                         size="2x"
                         style={{ padding: "5px" }}
                       />
                     </p>
                   </>
                 );
               } else if (elem.tipo === 4) {
                 return (
                   <>
                     <p
                       className={
                         elem.status === 0 || elem.status === 1
                           ? "icon-inactive"
                           : elem.status === 2
                           ? "icon-solicitado"
                           : "icon-active"
                       }
                     >
                       <FontAwesomeIcon
                         icon={["fal", "ticket-alt"]}
                         size="2x"
                         style={{ padding: "5px" }}
                       />
                     </p>
                   </>
                 );
               } else if (elem.tipo === 5) {
                 return (
                   <>
                     <p
                       className={
                         elem.status === 0 || elem.status === 1
                           ? "icon-inactive"
                           : elem.status === 2
                           ? "icon-solicitado"
                           : "icon-active"
                       }
                     >
                       <FontAwesomeIcon
                         icon={["fal", "utensils"]}
                         size="2x"
                         style={{ padding: "5px" }}
                       />
                     </p>
                   </>
                 );
               } else {
                 return <></>;
               }
             })
           ) : (
             <></>
           )}
         </div>
         <div className="table">
           <BootstrapTable
             bootstrap4
             keyField="id"
             data={row.items}
             columns={columnsResumo}
             //rowClasses={rowClasses}
             striped={true}
           />
         </div>
       </div>
     </div>
   );
 }

 const columns = [
   {
     dataField: "dataOperacao",
     text: "Data Operação",
     formatter: convertDate,
   },
   {
     dataField: "status",
     text: "Status",
     formatter: addStatus,
   },
   {
     dataField: "tipo",
     text: "Tipo",
   },
   {
     dataField: "sessionReloc",
     text: "Session/Reloc",
   },
   {
     dataField: "operacao",
     text: "Operação",
   },
   {
     dataField: "descricao",
     text: "Tour/Descrição",
   },
   {
     dataField: "qtdePax",
     text: "Passageiros",
   },
   {
     dataField: "sessionReloc",
     text: "Ação",
     formatter: addActionButton,
   },
 ];

 const columnsResumo = [
   {
     dataField: "localizador",
     text: "Localizador",
   },
   {
     dataField: "horario",
     text: "Hora",
   },
   {
     dataField: "nomePax",
     text: "Nome",
   },
   {
     dataField: "telefone",
     text: "Telefone",
   },
   {
     dataField: "localizacao",
     text: "Localização",
   },
 ];

 /* loading table */

 function loadingFunc() {
   return (
     <>
       <div className="animated-background row-loading"></div>
     </>
   );
 }

 const loadingColumns = [
   {
     dataField: "contasd",
     text: "Carregando Sessões",
     formatter: loadingFunc,
   },
 ];

 const loadingProducts = [
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
   { contas: "" },
 ];
 /* [END] loading table */

 /* In case of empty table*/
 const notFoundColumns = [{ dataField: "sessoes", text: "Sessões" }];

 const notFoundProducts = [{ sessoes: "Nenhuma sessão encontrada" }];
 /* [END] In case of empty table*/

 const rowClasses = (row: any, rowIndex: any) => {
   if (row.status === "Conciliado" || row.status === "Alocação Parcial") {
     return "border-analise";
   } else if (
     row.status === "Confirmado" ||
     row.status === "Emitido" ||
     row.status === "Em Operação"
   ) {
     return "border-ativo";
   } else if (
     row.status === "Pendente Escala" ||
     row.status === "Pendente Aceite"
   ) {
     return "border-pendente-escala";
   } else {
     return "border-inativo";
   }
 };

 /* FORMULÁRIO */

 const { reset } = useForm();

 useEffect(() => {
   if (modalShow === false) {
     reset({
       data: undefined,
     });
   }
 }, [reset, /*  */ modalShow]);

 /*     const onSubmit = (data: any) => {
       ,
           //
       } */

 const borderResp = (row: any) => {
   if (row.status === 'Pendente Escala' || row.status === 'Pendente Aceite') {
     return "border-analise-resp";
   } else if (row.status === 'Em Operação' || row.status === 'Confirmado' || row.status === 'Emitido' ) {
     return "border-ativo-resp";
   } else if (row.status === 'Pendente') {
     return "border-inativo-resp";
   } else {
     return "";
   }
 };
 

 useEffect(() => {
   //tablesizing
   window.addEventListener("resize", function () {
     var newWidth = window.innerWidth;
     setWidth(newWidth);
   });
 }, []);

  /* RENDER */
  if (manifestObj !== null) {
    return (
      <>
        <div className="table-default manifest-table">
          <div className="table-container">
            <div>
              <div
                className="d-flex flex-wrap align-items-center"
                style={{ gap: "15px" }}
              >
                <div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="btn btn-bills"
                    onClick={exportManifest}
                  >
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </div>
                </div>
                <div>
                  <div
                    style={{ cursor: "pointer" }}
                    className="btn btn-bills"
                    onClick={printManifest}
                  >
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </div>
                </div>
                <div>
                  <ModalDefault
                    title="Criar Nova Session"
                    name="Criar Session"
                    icon="yes"
                    typeIcon="plus-circle"
                    classBtn="btn btn-bills"
                    classModal="modal-large"
                  >
                    <CreateSession
                      show={setModalSuccess}
                      setResponseText={setResponseText}
                      setModalContent={setModalContent}
                      setLog={setLog}
                    />
                  </ModalDefault>
                </div>
              </div>
            </div>
          { width > 640 ?

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={manifestObj}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            :
            <div className="table-mobile my-4">
              <Table className="table table-bordered">
               
                <Tbody>
                  {manifestObj.map((elem: any, index: any) => {
                    return (
                      <Tr className={borderResp(elem)}>
                        <Th key={elem.idNewItems}>
                          <Td>
                            <strong>{columns[0].text}:</strong> &nbsp;
                            {elem.dataOperacao
                              .split("T")[0]
                              .split("-")
                              .reverse()
                              .join("/")}
                          </Td>
                          <Td>
                            <strong>{columns[1].text}:</strong> &nbsp;{" "}
                            {elem.status}
                          </Td>
                          <Td>
                            <strong>{columns[2].text}:</strong> &nbsp;{" "}
                            {elem.tipo}
                          </Td>
                          <Td>
                            <strong>{columns[3].text}:</strong> &nbsp; {elem.id}
                          </Td>
                          <Td>
                            <strong>{columns[4].text}:</strong> &nbsp;{" "}
                            {elem.operacao}
                          </Td>
                          <Td>
                            <strong>{columns[5].text}:</strong> &nbsp;{" "}
                            {elem.descricao}
                          </Td>
                          <Td>
                            <strong>{columns[6].text}:</strong> &nbsp;{" "}
                            {elem.qtdePax}
                          </Td>
                          <Td className="d-flex">
                            <strong>{columns[7].text}:</strong> &nbsp;
                            {
                              <div className="d-flex justify-content-center">
                                <Dropdown drop="start" className="dropdown-custom btn-drop py-0">
                                  <Dropdown.Toggle variant="light ">
                                    {/* <FontAwesomeIcon
                                      size="lg"
                                      icon={["fal", "ellipsis-h"]}
                                    /> */}
                                    <span style={{fontSize: '14px', color: "#707070"}}>...</span>
                                  </Dropdown.Toggle>

                                  <Dropdown.Menu>
                                    {elem.tipo === "Tour" ? (
                                      <>
                                        <Dropdown.Item
                                          eventKey="1"
                                          onClick={() => {
                                            window.open(
                                              `single-session?id=${
                                                elem.sessionReloc
                                              }?operation=${
                                                elem.operacao === "Própria"
                                                  ? 1
                                                  : 2
                                              }?status=${
                                                elem.status === "Pendente"
                                                  ? 0
                                                  : elem.status ===
                                                    "Em Operação"
                                                  ? 1
                                                  : 2
                                              }`,
                                              "_blank"
                                            );
                                          }}
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "edit"]}
                                            className="mr-2"
                                          />
                                          Ver
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="2"
                                          onClick={() =>
                                            handleSessionMessagesClick(elem.id)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "envelope"]}
                                            className="mr-2"
                                          />{" "}
                                          Mensagens
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="3"
                                          onClick={() =>
                                            handleSessionMessagesClick(elem.id)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "envelope"]}
                                            className="mr-2"
                                          />{" "}
                                          Cognitivo
                                        </Dropdown.Item>
                                        <Dropdown.Item
                                          eventKey="4"
                                          disabled={elem.items.length !== 0}
                                          onClick={() =>
                                            handleDeleteClick(elem)
                                          }
                                          className="text-danger remove"
                                        >
                                          <FontAwesomeIcon
                                            icon={["fal", "trash-alt"]}
                                            className="mr-2"
                                          />{" "}
                                          {t("suppliersTable.remove")}
                                        </Dropdown.Item>
                                      </>
                                    ) : elem.tipo === "Transfer" ? (
                                      <Dropdown.Item
                                        eventKey="1"
                                        onClick={() => {
                                          window.open(
                                            `single-session-transfer?id=${elem.sessionReloc}`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={["fal", "edit"]}
                                          //className="mr-2"
                                        />
                                        Ver
                                      </Dropdown.Item>
                                    ) : (
                                      <Dropdown.Item
                                        eventKey="1"
                                        onClick={() => {
                                          window.open(
                                            `single-session?id=${elem.id}?operation=${
                                              elem.operacao === "Própria" ? 1 : 2
                                            }`,
                                            "_blank"
                                          );
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          icon={["fal", "edit"]}
                                          //className="mr-2"
                                        />
                                        Ver
                                      </Dropdown.Item>
                                    )}
                                  </Dropdown.Menu>
                                </Dropdown>
                              </div>
                            }
                          </Td>
                        </Th>
                      </Tr>
                    );
                  })}
                </Tbody>
              </Table>
            </div>
            }

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            {/* Modal de Success */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => setModalSuccess(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className="modal-body text-center sucess-pos">
                  {modalContent !== "loading" ? (
                    <>
                      <div>
                        {log === 1 || log === "1" ? (
                          <FontAwesomeIcon
                            icon={["fal", "times-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        ) : log === 0 || log === "0" ? (
                          <FontAwesomeIcon
                            icon={["fal", "check-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={["fal", "question-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                        )}
                      </div>
                      <div>{responseText}</div>
                      <div className="d-flex justify-content-center">
                        {modalContent === "success" ? (
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        ) : modalContent === "ok" ? (
                          <button
                            onClick={() => setModalSuccess(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        ) : (
                          <button
                            onClick={() => setModalShow(false)}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                        )}
                      </div>
                    </>
                  ) : (
                    <div className="modal-body">
                      <div className="text-center">
                        <div className="load"></div>
                      </div>
                      <div>
                        <span>Processando</span>
                      </div>
                      <div></div>
                    </div>
                  )}
                </Modal.Body>
              </>
            </Modal>
            {/* END - Modal de Success */}

            {/* modal delete session */}
            <ModalQuestion
              modalShow={sessionDeleteModalShow}
              setModalShow={setSessionDeleteModalShow}
              titulo="Excluir Session"
              mensagem="Tem Certeza que deseja excluir esta session?"
              handleActionConfirmModal={handleActionSessionDeleteModal}
              //handleActionCancelModal={handleActionCancelDeleteClick}
            />
            {/* END - modal delete session */}

            {/* modal mensagens session */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={sessionMessagesShow}
              onHide={() => setSessionMessagesShow(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Header className="p-3" closeButton></Modal.Header>
                <ModalSessionMessages
                  sessionId={sessionMessagesId}
                  show={setModalSuccess}
                  setResponseText={setResponseText}
                  setModalContent={setModalContent}
                  setLog={setLog}
                  messageList={sessionMessageList}
                  getSessionMessages={getSessionMessages}
                />
              </>
            </Modal>
            {/* END - modal mensagens session */}

            {/* START - modal Cognitivo */}
            <ExecuteAIModel
              modalOpen={modalCognitivoOpen}
              setModalOpen={setModalCognitivoOpen}
               _sessionId={cognitivoSessionId} 
               setSuccess={setAiModelSuccess}
               />
            {/* END - modal Cognitivo */}
          </div>
        </div>
      </>
    );
  } else if (manifestObj === null && loading === true) {
    return (
      <>
        <div className="table-default manifest-table loading not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default manifest-table not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
};

export default TableManifestResponsive;
