import React, { useState } from "react";
import Accordion from "react-bootstrap/Accordion";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import GetResourceVehicleTypeFilter from "../../../../components/C2Points/GetResourceVehicleTypeFilter";
import GetResourcesTypeIsActiveFilter from "../../../../components/C2Points/GetResourcesTypeIsActiveFilter";

export interface propFilter {
  buscar: any;
  resources: any;
  clearActualValues: any;
}

const ResourcesFilter: React.FC<propFilter> = ({
  buscar,
  resources,
  clearActualValues,
}) => {
  const [t] = useTranslation();
  const [filterName, setFilterName] = useState<any>("");
  const [filterType, setFilterType] = useState<any>(0);
  const [filterVehicleType, setFilterVehicleType] = useState<any>(0);
  const {
    control,
    setValue,
    //watch,
    formState: { errors },
  } = useForm({});
  
  /* const watchIsType = watch(`type`, false); */

  const handleFilterClick = () => {
    buscar(filterName, filterType, filterVehicleType);
  };

  const handleClearClick = () => {
    setValue("resources", "");

    let inputValue = document.querySelectorAll("input");
    for (let i = 0; i < inputValue.length; i++) {
      inputValue[i].value = "";
    }

    let selectValue = document.querySelectorAll("select");
    for (let i = 0; i < selectValue.length; i++) {
      if (selectValue[i].id === "rowsPerPage") {
        selectValue[i].value = "10";
      } else {
        selectValue[i].value = "";
      }
    }

    setFilterName("");
    setFilterType(0);
    setFilterVehicleType(0);
    buscar("", 0, 0);
    clearActualValues();

    setValue('type', {value: '0', label: ''});
    setValue('vehicleType', {value: '0', label: ''});
  };

  const handleChangeName = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    setFilterName(newValue);
  };

  return (
    <div
      className="suppliers-filter d-flex justify-content-center bg-white mt-4"
      style={{ textAlign: "left" }}
    >
      <Accordion defaultActiveKey="1">
        <Accordion.Item eventKey="0">
          <Accordion.Header>
            <FontAwesomeIcon
              icon={["fal", "sliders-h"]}
              size="lg"
              className="mr-2"
            />
            <span className="h5 mb-0">Buscar resources</span>
          </Accordion.Header>
          <Accordion.Body className="pt-0">
            <Container className="p-0" fluid>
              <Row>
                <div>
                  <Form>
                    <Row className="mb-4">
                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Buscar por nome</Form.Label>
                        <Controller
                          control={control}
                          name="name"
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              variant="standard"
                              margin="normal"
                              autoComplete="off"
                              onChange={handleChangeName}
                            />
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Buscar por tipo</Form.Label>
                        <Controller
                          control={control}
                          name="type"
                          render={({ field }: any) => (
                            <div className="input-select-custom">
                              <GetResourcesTypeIsActiveFilter
                                propsField={field}
                                propsLabel={"tipo"}
                                propsErrors={errors}
                                setFilterType= {setFilterType}
                              />
                            </div>
                          )}
                        />
                      </Form.Group>

                      <Form.Group as={Col} lg="4" mb="4" sm="4">
                        <Form.Label>Buscar por tipo de veículo</Form.Label>
                        <Controller
                          control={control}
                          name="vehicleType"
                          render={({ field }: any) => (
                            <div className="input-select-custom">
                                <GetResourceVehicleTypeFilter
                                propsField={field}
                                propsLabel={"tipo"}
                                propsErrors={errors}
                                setFilterVehicleType={setFilterVehicleType}                                
                                />
                            </div>
                          )}
                        />
                      </Form.Group>
                    </Row>

                    <Row
                      as={Col}
                      md="12"
                      className="mt-5 justify-content-md-end"
                    >
                      <Col xs lg="12" className="d-flex justify-content-md-end">
                        <Button
                          variant="outline-primary"
                          className="btn-default mr-2 text-uppercase"
                          onClick={handleClearClick}
                        >
                          {t("reservations.filter.btnClear")}                          
                        </Button>
                        <Button
                          className="btn-default text-uppercase"
                          onClick={handleFilterClick}
                        >
                          {t("reservations.filter.btnSearch")}                          
                        </Button>
                      </Col>
                    </Row>
                  </Form>
                </div>
              </Row>
            </Container>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    </div>
  );
};

export default ResourcesFilter;
