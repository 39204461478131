//import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Col, Form} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import api from "../../../../services/api";
import GetResourceScoreOpts from "../../../../components/C2Points/GetResourceScoreOpts";
import { useState } from "react";

//import ModalConfirm from "../../../../components/Modal/ModalConfirm/ModalConfirm";

export interface propEdit {
  resourceId: any;
  updateScoreList: any;
  setModalSuccess: any;
  setModalContent: any;
  setResponseText: any; 
  setLog: any;
}

const ResourceAddScore: React.FC<propEdit> = ({
  resourceId, updateScoreList, setModalSuccess, setModalContent, setResponseText, setLog
}) => {

  const [optionId, setOptionId] = useState<any>(null);
  const [points, setPoints] = useState<any>(null);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({});
  
  function onSubmit() {
    setModalContent('loading');
    setModalSuccess(true);

    if(optionId === null) {
      setResponseText('Selecione o Erro/Acerto');
      setModalContent('warning');
      setLog(2);
      return;
    }

    const token = localStorage.getItem("GroupId") || "{}";
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function addResource() {
      try {
        const { data } = await api.post(
          "Resources/AddResourceScoreItem",
          {
            resourceId: resourceId,
            optionId: optionId
          },
          config
        );

        if (data.status !== 400) {
          setLog(data.data.log);
          setResponseText(data.data.texto);

          if(data.data.log !== 1) {
            setModalContent('success');
            setOptionId(null);
            setPoints(null);
            updateScoreList();
          } else {
            setModalContent('error');
          }
        }
      } catch (error: any) {
        setResponseText('Erro, tente novamente mais tarde.');
        setModalContent('error');
        setLog(1);
      }
    }
    addResource();
  };

  return (
    <>
      {/* cadastro de pontos */}
      <Form.Group as={Col} md="3" controlId="" className="mb-3">
        <Form.Label>Erros e Acertos:</Form.Label>
        <Controller
          control={control}
          name="optionId"
          rules={{required: { value: true, message: "Por favor, informe o nome do resource",},}}
          render={({ field }: any) => (
            <GetResourceScoreOpts propsField={field} propsLabel={"Erros e Acertos"} propsErrors={errors} setScoreOpt={(score: any) => { setOptionId(score.id); setPoints(score.points); }} />
          )}
        />
      </Form.Group>
      <Form.Group as={Col} md="1" controlId="" className="mb-3">
        <Form.Label>Pontos:</Form.Label>
        <Form.Control
          name="points"
          autoComplete='off'
          value={points}
          disabled
        />
      </Form.Group>
      <Form.Group as={Col} md="2" controlId="" className="mb-3 d-flex align-items-end">
        <button
          className="btn btn-default btn-primary px-4 text-uppercase"
          type="button"
          onClick={onSubmit}
        >
          Adicionar
        </button>
      </Form.Group>
    </>
  );
};

export default ResourceAddScore;
