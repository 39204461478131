import React, { useEffect, useState } from 'react';
import Select from 'react-select';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsErrors: any,
    pickupList: any, 
    defaultEditPickup?: any
};


const GetPickupList: React.FC<propPlus> = ({
    propsField, propsErrors, pickupList, defaultEditPickup = null
}: propPlus) => {
    const { t } = useTranslation();

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        setInfo(pickupList)
    }, [pickupList /*  */]);

    if (info !== null) {
        return (
            <>
                <div className={propsErrors.country !== undefined ? "endpoint-error" : "endpoint"}>
                    <Select
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        name="aria-live-color"
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        {...propsField}
                        aria-invalid={propsErrors?.country ? "true" : ""}
                        as="select"
                        variant="standard"
                        margin="normal"
                        placeholder={t('accordionInfoCompany.endpoint')}
                        defaultValue={defaultEditPickup !== null ? { nomePickup: defaultEditPickup.nomePickup , idPickupLocation: parseInt(defaultEditPickup.idPickup) } : '' }
                        options={Array.from(info)}
                        getOptionLabel={(option: any) => `${option.nomePickup}`}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value='' disabled></option>
                </Form.Select>
            </>
        )
    }
}

export default GetPickupList;