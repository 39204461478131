/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Accordion, Card, Container, Form, Row, Col, OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';
import GetSupplierGroupId from '../../../../components/C2Points/GetSupplierGroupId';
import GetSupplierSupplierIdentity from '../../../../components/C2Points/GetSupplierSupplierIdentity';


export interface propEdit {
    isEdit?: any;
    infoEdit?: any;
    actionFunc: any
}

const AddVehicleComponent: React.FC<propEdit> = ({
    isEdit = false, infoEdit, actionFunc
}: propEdit) => {

    const [vehicleImg, setVehicleImg] = useState<any>(null);
    const [vehicleImgName, setVehicleImgName] = useState<any>(isEdit === true ? infoEdit.vehiclePhoto : '');

    const [crlvImg, setCrlvImg] = useState<any>(null);
    const [crlvImgName, setCrlvImgName] = useState<any>(isEdit === true ? infoEdit.vehicleCrlvPhoto : '');

    const [dpvatImg, setDpvatImg] = useState<any>(null);
    const [dpvatImgName, setDpvatImgName] = useState<any>(isEdit === true ? infoEdit.vehicleDpvatPhoto : '');

    const [apoliceImg, setApoliceImg] = useState<any>(null);
    const [apoliceImgName, setApoliceImgName] = useState<any>(isEdit === true ? infoEdit.vehicleSeguroPhoto : '');

    const vehicles: any = [
        'img_transfer/veiculos/nophoto.png',
        //'img/default.png',
        'img/onibus.png',
        'img/micro-onibus-png-2.png',
        'img/van.png',
        'img/fusion-png-5.png'
    ];

    const {
        control,
        handleSubmit,
        watch,
        formState: { errors },
    } = useForm({
        defaultValues: {
            vehiclesType: isEdit === true ? infoEdit.vehicleType : 0,
            passengers: isEdit === true ? infoEdit.vehicleSeats : 4,
            model: isEdit === true ? infoEdit.vehicleModel : '',
            year: isEdit === true ? parseInt(infoEdit.vehicleYear) : '',
            licensePlate: isEdit === true ? infoEdit.vehicleLicensePlate : '',
            color: isEdit === true ? infoEdit.vehicleColor : '',
            vehicleArmored: isEdit === true ? infoEdit.vehicleArmored === 'não' ? 2 : 1 : 2,
            baggage: isEdit === true ? infoEdit.vehicleBagsG : 0,
            handBaggage: isEdit === true ? infoEdit.vehicleBagsM : 0,
            supplier: isEdit === true ? { id: infoEdit.vehicleSupplier, description: infoEdit.vehicleSupplierDesc } : '',
            vehiclePicture: isEdit === true ? '' : '',
            crlv: isEdit === true ? '' : '',
            dpvat: isEdit === true ? '' : '',
            apolice: isEdit === true ? '' : ''
        },
    });

    const watchIndexVehicle = watch(`vehiclesType`);

    const onSubmit = (data: any) => {
        data.vehiclePicture = vehicleImg;
        data.crlv = crlvImg;
        data.dpvat = dpvatImg;
        data.apolice = apoliceImg;
        data.supplier = parseInt(data.supplier.id);

        if (data.vehicleType === 1) {
            data.vehicleTypeString = "Ônibus";
        } else if (data.vehicleType === 2) {
            data.vehicleTypeString = "Micro-ônibus";
        } else if (data.vehicleType === 3) {
            data.vehicleTypeString = "Van";
        } else if (data.vehicleType === 4) {
            data.vehicleTypeString = "Carro";
        }

        actionFunc(data)
    }

    const vehiclePictureHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setVehicleImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setVehicleImgName(e.target.files[0].name);
    }

    const crlvHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setCrlvImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setCrlvImgName(e.target.files[0].name);
    }

    const dpvatHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setDpvatImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setDpvatImgName(e.target.files[0].name);
    }

    const apoliceHandler = (e: any) => {
        const reader = new FileReader();
        reader.onload = () => {
            if (reader.readyState === 2) {
                setApoliceImg(reader.result)
            }
        }
        reader.readAsDataURL(e.target.files[0]);
        setApoliceImgName(e.target.files[0].name);
    }


    return (
        <>
            <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                <Card className="bg-white mt-4">
                    <Accordion >
                        <Card.Body>
                            <Container className="p-0 mt-3" fluid>
                                <h5 className="text-uppercase mb-4">Cadastro de Veículo</h5>
                                <Row className="mb-3">
                                    <Col md={4}>

                                        <div className="w-100"
                                            style={{ backgroundImage: `url(${process.env.REACT_APP_SERVER_URL_IMG}${vehicles[watchIndexVehicle]})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', height: '300px', backgroundPosition: 'center center' }}>
                                        </div>

                                    </Col>
                                    <Col md={8}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3" controlId="">
                                                <Form.Label>
                                                    Tipo:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="vehiclesType"
                                                    rules={{ required: { value: true, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <div className='endpoint'>
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                            >
                                                                <option value="0" selected disabled>Selecione</option>
                                                                <option value="1">Ônibus</option>
                                                                <option value="2">Micro-ônibus</option>
                                                                <option value="3">Van</option>
                                                                <option value="4">Carro</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="vehiclesType"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="3" controlId="">
                                                <Form.Label>
                                                    Passageiros:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="passengers"
                                                    rules={{ required: { value: false, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <div className='endpoint'>
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                            >
                                                                <option value="4">4</option>
                                                                <option value="6">6</option>
                                                                <option value="12">12</option>
                                                                <option value="30">30</option>
                                                                <option value="40">40</option>

                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />

                                            </Form.Group>

                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label>
                                                    Modelo Veículo:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="model"
                                                    rules={{ required: { value: true, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="model"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="2" controlId="">
                                                <Form.Label>
                                                    Ano:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="year"
                                                    rules={{ required: { value: true, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="year"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="3" controlId="">
                                                <Form.Label>
                                                    Placa:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="licensePlate"
                                                    rules={{ required: { value: true, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="licensePlate"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />

                                            </Form.Group>

                                            <Form.Group as={Col} md="3" controlId="">
                                                <Form.Label>
                                                    Cor:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="color"
                                                    rules={{ required: { value: true, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="color"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="2" controlId="">
                                                <Form.Label>
                                                    Blindado:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="vehicleArmored"
                                                    rules={{ required: { value: true, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <div className='endpoint'>
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                            >
                                                                <option value="1">Sim</option>
                                                                <option value="2">Não</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="vehicleArmored"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="2" controlId="">
                                                <Form.Label>
                                                    Malas:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="baggage"
                                                    defaultValue={0}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number"
                                                            min={0}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />

                                            </Form.Group>

                                            <Form.Group as={Col} md="2" controlId="">
                                                <Form.Label>
                                                    Malas de mão:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="handBaggage"
                                                    defaultValue={0}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="number"
                                                            min={0}
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete='off'
                                                        />
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>

                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="6" controlId="">
                                                <Form.Label>
                                                    Fornecedor:
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="supplier"
                                                    rules={{ required: { value: true, message: 'Por favor, informe esse campo' } }}
                                                    render={({ field }: any) => (
                                                        <div className="input-select-custom">
                                                            <GetSupplierSupplierIdentity
                                                                propsField={field}
                                                                propsLabel={"supplier"}
                                                                propsErrors={errors}
                                                            //setFilterSupplier={setFilterSupplier}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                                {/* <Form.Control
                                                            {...field}
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        /> */}
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="supplier"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </Row>
                                    </Col>
                                </Row>

                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-4">Fotos</h5>

                                <Row className="mb-3">

                                    <Form.Group as={Col} md="3">
                                        <Form.Label>Adicione aqui fotos do veículo.
                                            <OverlayTrigger
                                                overlay={
                                                    <Tooltip id="tooltip">
                                                        As fotos devem estar em formato jpg ou png e ter um tamanho mínimo de 2MB
                                                    </Tooltip>
                                                }>
                                                <span>
                                                    <FontAwesomeIcon
                                                        className="ml-2"
                                                        icon={["fal", "question-circle"]}
                                                    />
                                                </span>
                                            </OverlayTrigger>
                                        </Form.Label>
                                        <Controller
                                            control={control}
                                            name="vehiclePicture"
                                            render={({ field }: any) => (
                                                <Form.Control
                                                    type="file"
                                                    {...field}
                                                    fullWidth
                                                    variant="standard"
                                                    margin="normal"
                                                    id="vehiclePicture"
                                                    accept=".jpg"
                                                    onChange={vehiclePictureHandler}
                                                    required 
                                                    autoComplete='off'/>
                                            )}
                                        />
                                        <br />
                                        <label className="btn-file" htmlFor="vehiclePicture">Anexar</label>
                                        <small>{vehicleImgName}</small>
                                    </Form.Group>

                                    <Col md={4}>
                                        {vehicleImg === null
                                            ?
                                            <div className="w-100"
                                                style={{ backgroundImage: `url(${infoEdit?.url}${infoEdit?.vehiclePhoto})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '350px' }}>
                                            </div>
                                            :
                                            <div className="w-100"
                                                style={{ backgroundImage: `url(${vehicleImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                            </div>
                                        }
                                    </Col>


                                </Row>

                                <hr className="my-5" />
                                <h5 className="text-uppercase mb-4">Documentos</h5>

                                <Row className="mb-3">
                                    <Col md={4}>
                                        <Row>
                                            <Form.Group as={Col} md="6">

                                                <Form.Label>CRLV.
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Adicione o documento em formato jpg ou png.
                                                            </Tooltip>
                                                        }>
                                                        <span>
                                                            <FontAwesomeIcon
                                                                className="ml-2"
                                                                icon={["fal", "question-circle"]}
                                                            />
                                                        </span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="crlv"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            type="file"
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="crlv"
                                                            accept=".jpg"
                                                            onChange={crlvHandler}
                                                            required 
                                                            autoComplete='off'/>
                                                    )}
                                                />
                                                <br />
                                                <label className="btn-file" htmlFor="crlv">Anexar</label>
                                                <small>{crlvImgName}</small>
                                            </Form.Group>

                                            <Col md={6}>
                                                {crlvImg === null
                                                    ?
                                                    <div className="w-100"
                                                        style={{ backgroundImage: `url(${infoEdit?.url}${infoEdit?.vehicleCrlvPhoto})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                    </div>
                                                    :
                                                    <div className="w-100"
                                                        style={{ backgroundImage: `url(${crlvImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>DPVAT.
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Adicione o documento em formato jpg ou png.
                                                            </Tooltip>
                                                        }>
                                                        <span>
                                                            <FontAwesomeIcon
                                                                className="ml-2"
                                                                icon={["fal", "question-circle"]}
                                                            />
                                                        </span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="dpvat"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            type="file"
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="dpvat"
                                                            accept=".jpg"
                                                            onChange={dpvatHandler}
                                                            required 
                                                            autoComplete='off'/>
                                                    )}
                                                />
                                                <br />
                                                <label className="btn-file" htmlFor="dpvat">Anexar</label>
                                                <small>{dpvatImgName}</small>
                                            </Form.Group>
                                            <Col md={6}>
                                                {dpvatImg === null
                                                    ?
                                                    <div className="w-100"
                                                        style={{ backgroundImage: `url(${infoEdit?.url}${infoEdit?.vehicleDpvatPhoto})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                    </div>
                                                    :
                                                    <div className="w-100"
                                                        style={{ backgroundImage: `url(${dpvatImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>

                                    <Col md={4}>
                                        <Row>
                                            <Form.Group as={Col} md="6">
                                                <Form.Label>Apólice de seguro.
                                                    <OverlayTrigger
                                                        overlay={
                                                            <Tooltip id="tooltip">
                                                                Adicione o documento em formato pdf.
                                                            </Tooltip>
                                                        }>
                                                        <span>
                                                            <FontAwesomeIcon
                                                                className="ml-2"
                                                                icon={["fal", "question-circle"]}
                                                            />
                                                        </span>
                                                    </OverlayTrigger>
                                                </Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="apolice"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            type="file"
                                                            {...field}
                                                            fullWidth
                                                            variant="standard"
                                                            margin="normal"
                                                            id="apolice"
                                                            accept=".jpg"
                                                            onChange={apoliceHandler}
                                                            required 
                                                            autoComplete='off'/>
                                                    )}
                                                />
                                                <br />
                                                <label className="btn-file" htmlFor="apolice">Anexar</label>
                                                <small>{apoliceImgName}</small>
                                            </Form.Group>
                                            <Col md={6}>
                                                {apoliceImg === null
                                                    ?
                                                    <div className="w-100"
                                                        style={{ backgroundImage: `url(${infoEdit?.url}${infoEdit?.vehicleSeguroPhoto})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                    </div>
                                                    :
                                                    <div className="w-100"
                                                        style={{ backgroundImage: `url(${apoliceImg})`, backgroundRepeat: 'no-repeat', backgroundSize: 'contain', backgroundPosition: 'center', height: '150px' }}>
                                                    </div>
                                                }
                                            </Col>
                                        </Row>
                                    </Col>



                                </Row>

                            </Container>
                        </Card.Body>
                    </Accordion>
                </Card>
                <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">

                    <Button
                        className="form-button cancel"
                        onClick={() => { window.location.href = '/vehicles/list-vehicles' }}
                    >
                        Cancelar
                    </Button>

                    <Button
                        type="submit"
                        className="form-button"
                    >
                        Cadastrar
                    </Button>
                </Row>
            </Form>
        </>
    );
}

export default AddVehicleComponent;