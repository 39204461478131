import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Dropdown, Form, Modal, OverlayTrigger, InputGroup, Tooltip, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import api from "../../../services/api";

import AutoComplete from "react-google-autocomplete";
import { geocodeByAddress, getLatLng } from "react-google-places-autocomplete";

import { useAppSelector, useAppDispatch } from "../../../store/hooks";
import { addModality, removeModality, duplicateModality, editModality, ticketAddModality, ticketEditModality, ticketDuplicateModality } from "../../../store/product";
import IntegrationSig from "../IntegrationSig/IntegrationSig";
import IntegrationPurchase from "../integrationPurchase/integrationPurchase";
import IntegrationAccess from "../integrationAccess/integrationAccess";
import GetTransfersPickup from "../../C2Points/GetTranfersPickup";
//import ListModalityForSigXSig from "../../C2Points/ListModalityForSigXSig";
import ModalShow from "../../Modal/ModalShow/ModalShow";
import DraggableProductImputs from "../../DraggableProductImputs/DraggableProductImputs";
import GetAllBadges from "../../C2Points/GetAllBadges";
import SingleCalendarWithDefaultValue from "../../Calendar/SingleCalendarWithDefaultValue";
import './Modalities.scss';
import GetFetchListLineDetails from "../../C2Points/GetFetchListLineDetails";
import GetFetchListTicketValidationTypes from "../../C2Points/GetFetchListTicketValidationTypes";

export interface operation {
  action: any;
  receiveObject: any;
  tourObject: any;
  isEdit?: any;
  isTicket?: boolean;
  editFunction?: any;
}

const Modalities: React.FC<operation> = React.memo(({
  action, receiveObject, tourObject, isEdit = false, isTicket = false, editFunction = () => { }
}: operation) => {
  /* Controle Redux  */
  const dispatch = useAppDispatch();
  const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
  /* Controle Redux  */

  const [allowCreateObjectModality, setAllowCreateObjectModality] = useState<boolean>(false);
  /* ModalControl */
  const [modalShow, setModalShow] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>("");
  const [modalLog, setModalLog] = useState<any>(0);

  /* Edit control v */
  const [allowEdit, setAllowEdit] = useState<boolean>(false);
  const [allowEditDescription, setAllowEditDescription] = useState<boolean>(false);
  const [allowEditModal, setAllowEditModal] = useState<boolean>(false);
  const [allowEditIncludes, setAllowEditIncludes] = useState<boolean>(false);
  const [allowEditExcludes, setAllowEditExcludes] = useState<boolean>(false);
  const [allowEditRange, setAllowEditRange] = useState<boolean>(false);
  const [allowEditWithdrawlAddress, setAllowEditWithdrawlAddress] = useState<boolean>(false);

  const [refcodeBool, setRefcodeBool] = useState<boolean>(false);
  const [check, setCheck] = useState<boolean>(false);
  const [reRender, setReRender] = useState<boolean>(false);

  // const [indexToRemove, setIndexToRemove] = useState<any>(null);
  // const [refundRules, setRefundRules] = useState<any>([]);
  const [isEditing, setIsEditing] = useState<any>(null); //usado pra identificar se alguma modalidade está sendo editada. Diferente do "isEdit" (página de edição)

  const [includesArray, setIncludesArray] = useState<any>([
    { PT: "", EN: "", ES: "" },
  ]);
  const [excludesArray, setExcludesArray] = useState<any>([
    { PT: "", EN: "", ES: "" },
  ]);
  const [days, setDays] = useState<any>([
    { day: "dom", value: tourObject?.dom || false },
    { day: "seg", value: tourObject?.seg || false },
    { day: "ter", value: tourObject?.ter || false },
    { day: "qua", value: tourObject?.qua || false },
    { day: "qui", value: tourObject?.qui || false },
    { day: "sex", value: tourObject?.sex || false },
    { day: "sab", value: tourObject?.sab || false },
  ]);

  const activityDurationOptions: any = ["00:30", "01:00", "01:30", "02:00", "02:30", "03:00", "03:30", "04:00", "04:30", "05:00", "05:30", "06:00", "06:30", "07:00", "07:30", "08:00", "08:30", "09:00", "09:30", "10:00", "10:30", "11:00", "11:30", "12:00", "12:30", "13:00", "13:30", "14:00", "14:30", "15:00", "15:30", "16:00", "16:30", "17:00", "17:30", "18:00"];

  //const buyingAdvanceOptionsMinutes: any = ["0 Minutos", "5 Minutos", "10 Minutos", "15 Minutos", "20 Minutos", "25 Minutos", "30 Minutos", "35 Minutos", "40 Minutos", "45 Minutos", "50 Minutos", "55 Minutos"];
  //const buyingAdvanceOptions: any = [...(isTicket ? ["0 horas"] : buyingAdvanceOptionsMinutes), "1 hora", "2 horas", "3 horas", "4 horas", "5 horas", "6 horas", "7 horas", "8 horas", "9 horas", "10 horas", "11 horas", "12 horas", "13 horas", "14 horas", "15 horas", "16 horas", "17 horas", "18 horas", "19 horas", "20 horas", "21 horas", "22 horas", "23 horas", "24 horas", "48 horas"];

  /* Integrações */
  const [controlIntegrationModal, setControlIntegrationModal] = useState<string>(""); // 'access' - 'purchase' - 'sig'
  const [integrationAccess, setIntegrationAccess] = useState<any>(null);
  const [integrationPurchase, setIntegrationPurchase] = useState<any>(null);
  const [integrationSig, setIntegrationSig] = useState<any>(null);

  const integrationPurchaseType: string[] = ["", "", "NovaXs", "Limber"];
  //const integrationAccesType: string[] = ['', 'Skidata', 'Default'];

  const [withdrawQtd, setWithdrawQtd] = useState<any>([true]);
  const [validationDefinedDate, setValidationDefinedDate] = useState<any>('');
  const [defaultLineDetail, setDefaultLineDetail] = useState<any>(0);
  const [defaultTicketValidationType, setDefaultTicketValidationType] = useState<any>(0);
  const [refundQtd, setRefundQtd] = useState<any>([true]);
  const [policyArray, setPolicyArray] = useState<string[] | null>(['1']);

  const {
    control,
    handleSubmit,
    setValue,
    getValues,
    watch,
    setError,
    clearErrors,
    formState: { errors },
  } = useForm({});

  const watchValidationType = watch(`validationType`, 0);
  const watchShipmentType = watch(`embarkingType`, 0);
  const watchOfferTransportation = watch(`offerTransportation`, 'no');
  const watchTicketSellingType = watch(`ticketSellingType`, '1');
  const watchLineDetailBoolean = watch(`lineDetailBoolean`, false);
  const watchTicketWithdrawl = watch(`ticketWithdrawl`, false);
  const watchIsTurism = watch(`isTurism`, false);
  const watchCancel = watch(`cancelationType`, null);
  const watchModalityNamePt = watch(`modalityNamePT`, "");
  const watchModalityNameEn = watch(`modalityNameEN`, "");
  const watchModalityNameEs = watch(`modalityNameES`, "");
  const watchModalityDescriptionPt = watch(`modalityDescriptionPT`, "");
  const watchModalityDescriptionEn = watch(`modalityDescriptionEN`, "");
  const watchModalityDescriptionEs = watch(`modalityDescriptionES`, "");
  const watchCognitivoPriority = watch('isModalityPriorityCognitivo');
  const watchCognitivoIAModel = watch('isModalityExecuteIACognitivo');

  const nextStep = () => {
    if (tourSIG.tour.modalidade?.length > 0) {
      let data: any = getValues();
      action("tariffs", data);
    } else {
      alert("Favor cadastrar ao menos 1 modalidade");
    }
  };

  useEffect(() => {
    if (!modalShow) {
      setTimeout(() => {
        window.scrollTo(0, 0);
      }, 300);
    }
  }, [modalShow]);

  const resetForm = () => {
    clearErrors(`errorIntegrationPurchase`);
    if (isTicket) {
      setValue("validationType", '');
      setValue("validationPeriodType", '0');
      setValue("validationPeriod", '');
      setValue("validationDefinedDate", '');
      setValidationDefinedDate('');
      setValue("offerTransportation", 'no');
      setValue("wheelchairAccessibility", '2');
      setValue("ticketSellingType", '1');
      setValue("lineDetailBoolean", 'false');
      setValue("lineDetail", '');
      setDefaultLineDetail(0)
      setDefaultTicketValidationType(0)

      for (let i: number = 0; i < withdrawQtd.length; i++) {
        setValue(`withdrawAddress-${i}`, "");
        setValue(`withdrawlLatitude-${i}`, "");
        setValue(`withdrawlLongetude-${i}`, "");
        setValue(`ticketWithdrawDescription-PT-${i}`, "");
        setValue(`ticketWithdrawDescription-EN-${i}`, "");
        setValue(`ticketWithdrawDescription-ES-${i}`, "");
      }

      for (let i: number = 0; i < refundQtd.length; i++) {
        setValue(`daysBeforeCheckIn-${i}`, '')
        setValue(`policy-${i}`, '0')
        setValue(`percentage-${i}`, '')
      }

      setValue("ticketWithdrawl", 'false');
      setWithdrawQtd([true]);
      setRefundQtd([true]);
      setPolicyArray(['1']);
    }

    setValue("modalityNamePT", "");
    setValue("modalityNameEN", "");
    setValue("modalityNameES", "");

    setValue("referenceCodePT", "");
    setValue("referenceCodeEN", "");
    setValue("referenceCodeES", "");

    setValue("modalityDescriptionPT", "");
    setValue("modalityDescriptionEN", "");
    setValue("modalityDescriptionES", "");

    setValue("allDays", false);
    (document.getElementById("allDays") as HTMLFormElement).checked = false;
    changeAllDays(false);

    setValue("options", "00:30");
    setValue("activityDuration", "01:00");
    //handlerOpeningHours('00:00');
    //setValue("buyingAdvance", "10h");

    //setValue("dailyStockTotal", "");
    setValue("meetingPoint", "");
    setValue("pickupList", "");

    if (tourSIG.tour.sellingType === 2) {
      setValue("labelAdultEnabled", true);
      setValue("labelAdultBR", "Adulto");
      setValue("labelAdultEN", "Adult");
      setValue("labelAdultES", "Adulto");
      setValue("tariffAdultAgeText", "Entre 11 a 100 anos");
      setValue("sellingIncrementAdult", 1);
      setValue("flagStockUseAdult", true);

      setValue("labelChildEnabled", true);
      setValue("labelChildBR", "Criança");
      setValue("labelChildEN", "Child");
      setValue("labelChildES", "Niño");
      setValue("tariffChildAgeText", "Entre 5 a 10 anos");
      setValue("sellingIncrementChild", 1);
      setValue("flagStockUseChild", true);

      setValue("labelInfantEnabled", true);
      setValue("labelInfantBR", "Infante");
      setValue("labelInfantEN", "Infant");
      setValue("labelInfantES", "Infantil");
      setValue("tariffInfantAgeText", "Entre 0 a 4 anos");
      setValue("sellingIncrementInfant", 1);
      setValue("flagStockUseInfant", false);
    } else {
      setValue("labelPrivateEnabled", true);
      setValue("labelPrivateBR", "Privativo");
      setValue("labelPrivateEN", "Private");
      setValue("labelPrivateES", "Privativo");
      setValue("tariffGlobalAgeText", "Entre 0 a 100 anos");
      setValue("sellingIncrementPrivate", 1);
      setValue("flagStockUsePrivate", true);
    }

    setValue("cancelationType", "1");
    setValue("daysBeforeCheckIn", "");
    setValue("percentage", "");

    for (let i: number = 0; i < includesArray.length; i++) {
      setValue(`itemsIncludedPT-${i}`, "");
      setValue(`itemsIncludedEN-${i}`, "");
      setValue(`itemsIncludedES-${i}`, "");
    }

    for (let i: number = 0; i < excludesArray.length; i++) {
      setValue(`itemsNotIncludedPT-${i}`, "");
      setValue(`itemsNotIncludedEN-${i}`, "");
      setValue(`itemsNotIncludedES-${i}`, "");
    }

    setValue("stock", "no");
    setValue("meetingPoint", "");

    setIncludesArray([{ PT: "", EN: "", ES: "" }]);
    setExcludesArray([{ PT: "", EN: "", ES: "" }]);

    setIntegrationAccess(null);
    setIntegrationPurchase(null);
    setIntegrationSig(null);

    setValue("tourGuidePT", false);
    setValue("audioGuidePT", false);
    setValue("writingGuidePT", false);
    setValue("tourGuideEN", false);
    setValue("audioGuideEN", false);
    setValue("writingGuideEN", false);
    setValue("tourGuideES", false);
    setValue("audioGuideES", false);
    setValue("writingGuideES", false);

    setValue("isTurism", "false");

    //setRefundRules([]);
  };



  const validateLabels = (data: any) => {
    let errorOnLabels: boolean = false;

    if (data.labelChildEnabled) {
      if (data.labelChildBR === '') {
        setError(`labelChildBR`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelChildEN === '') {
        setError(`labelChildEN`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelChildES === '') {
        setError(`labelChildES`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
    }

    if (data.labelInfantEnabled) {
      if (data.labelInfantBR === '') {
        setError(`labelInfantBR`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelInfantEN === '') {
        setError(`labelInfantEN`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelInfantES === '') {
        setError(`labelInfantES`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
    }

    if (data.labelStudentEnabled) {
      if (data.labelStudentBR === '') {
        setError(`labelStudentBR`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelStudentEN === '') {
        setError(`labelStudentEN`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelStudentES === '') {
        setError(`labelStudentES`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
    }

    if (data.labelEldersEnabled) {
      if (data.labelEldersBR === '') {
        setError(`labelEldersBR`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelEldersEN === '') {
        setError(`labelEldersEN`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
      if (data.labelEldersES === '') {
        setError(`labelEldersES`, { type: 'custom', message: `Por favor, informe esse campo` });
        errorOnLabels = true;
      }
    }

    return errorOnLabels;
  }

  const onSubmit = (data: any) => {
    
    let validateDays: boolean = false;
    for (let i: number = 0; i < days.length; i++) {
      if (days[i].value === true) {
        validateDays = true;
      }
    }

    let errorOnLabels: boolean = false;

    errorOnLabels = validateLabels(data);

    if (errorOnLabels) {
      let rangeDeIdades: any = document.getElementById("rangeDeIdades");
      const y = rangeDeIdades.getBoundingClientRect().top + window.scrollY - 130;
      window.scroll({
        top: y,
        behavior: 'smooth'
      });

      return;
    }

    if (['8', '9'].includes(tourSIG.tour.integrationPurchaseType) && integrationPurchase === null) {
      setError('errorIntegrationPurchase', { type: 'custom', message: 'Favor, adicionar uma integração de compra' })
      return;
    }

    if (validateDays === false) {
      setModalShow(true);
      setModalLog(1);
      setModalMessage("Selecione ao menos 1 dia da semana");
      return;
    } else {
      setModalShow(true);
      setModalLog(null);
      setModalMessage("Carregando");
    }

    setAllowCreateObjectModality(true);
  };

  useEffect(() => {
    if (allowCreateObjectModality === true) {
      setTimeout(() => {

        createObjectModality();

        setModalShow(true);
        setModalLog(0);
        setModalMessage(
          isEditing === null
            ? "Modalidade inserida com sucesso!"
            : "Modalidade editada com sucesso!"
        );
        setIsEditing(null);

        resetForm();
      }, 1000)
    }
    setAllowCreateObjectModality(false)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allowCreateObjectModality])

  const createTicketObject = async (data: any) => {
    data.isTicket = true;

    data.descriptionPT = data.modalityDescriptionPT;
    data.descriptionEN = data.modalityDescriptionEN;
    data.descriptionES = data.modalityDescriptionES;

    data.dom = days[0].value ? 1 : 2;
    data.seg = days[1].value ? 1 : 2;
    data.ter = days[2].value ? 1 : 2;
    data.qua = days[3].value ? 1 : 2;
    data.qui = days[4].value ? 1 : 2;
    data.sex = days[5].value ? 1 : 2;
    data.sab = days[6].value ? 1 : 2;

    if (data.offerTransportation === 'yes' && data.embarkingType && parseInt(data.embarkingType || 0) === 1) {
      let pickupObj: any = JSON.parse(`${data.pickupList}`)

      data.pickupList = pickupObj.id;
      data.pickupDescription = pickupObj.description;
    }

    data.itemsIncluded = includesArray.filter((e: any) => e !== null).map((e: any) => [
      {
        itensIncluded: {
          text: e.PT,
          lang: 1,
        },
        status: 1
      },
      {
        itensIncluded: {
          text: e.EN,
          lang: 2,
        },
        status: 1
      },
      {
        itensIncluded: {
          text: e.ES,
          lang: 3,
        },
        status: 1
      },
    ]);

    data.itemsNotIncluded = excludesArray.filter((e: any) => e !== null).filter((e: any) => e.PT.length > 0 && e.EN.length > 0 && e.ES.length > 0).map((e: any) => [
      {
        itensNotIncluded: {
          text: e.PT,
          lang: 1,
        },
        status: 1
      },
      {
        itensNotIncluded: {
          text: e.EN,
          lang: 2,
        },
        status: 1
      },
      {
        itensNotIncluded: {
          text: e.ES,
          lang: 3,
        },
        status: 1
      },
    ]);

    let auxRefund: any = [];
    refundQtd.forEach((elem: any, index: number) => {
      if (elem !== null) {
        auxRefund.push({
          refundRule: 3,
          days: parseInt(data[`daysBeforeCheckIn-${index}`]),
          ticketPolicy: parseInt(data[`policy-${index}`]) || 1,
          percentage: data[`policy-${index}`] === '0' ? 0 : parseInt(data[`percentage-${index}`] || 0),
        })
      }
    });

    data.refundRules = auxRefund;

    data.offerGuidingDetails = watchIsTurism === "true" || watchIsTurism === true;

    data.guidingDetails = [
      {
        lang: 1,
        tourGuide: data.tourGuidePT === true ? 1 : 2,
        audioGuide: data.audioGuidePT === true ? 1 : 2,
        booklet: data.writingGuidePT === true ? 1 : 2,
      },
      {
        lang: 2,
        tourGuide: data.tourGuideEN === true ? 1 : 2,
        audioGuide: data.audioGuideEN === true ? 1 : 2,
        booklet: data.writingGuideEN === true ? 1 : 2,
      },
      {
        lang: 3,
        tourGuide: data.tourGuideES === true ? 1 : 2,
        audioGuide: data.audioGuideES === true ? 1 : 2,
        booklet: data.writingGuideES === true ? 1 : 2,
      },
    ];

    data.offerTransportation = watchOfferTransportation === "yes" ? 1 : 2;
    data.embarkingType = data.offerTransportation === 2 ? 0 : parseInt(data.embarkingType || 0) === 0 ? 2 : 1;
    data.freeSale = data.stock === "no" ? true : false;
    data.wheelchairAccessibility = parseInt(data.wheelchairAccessibility || 2);

    /* integrações */
    data.productPurchaseIntegration = integrationPurchase !== null ? [{ ...integrationPurchase }] : [];
    data.accessIntegration = integrationAccess !== null ? integrationAccess : [];
    data.sigSigIntegration = integrationSig !== null ? { ...integrationSig } : {};

    let productModalityRange: any = [];

    (tourSIG.tour?.sellingType === 2 ? ['Adult', "Child", "Infant", "Elders", "Student"] : ['Private']).forEach((elem: any, index: number) => {
      productModalityRange.push({
        "id": 0,
        //"productCode": data.productModCode, // repetir produto
        //"productModCode": data.productModCode, //repetir modalidade
        "rangeId": elem,
        "rangeLabel": [
          {
            "text": data[`label${elem}BR`],
            "lang": 1
          },
          {
            "text": data[`label${elem}EN`],
            "lang": 2
          },
          {
            "text": data[`label${elem}ES`],
            "lang": 3
          }
        ],
        "rangeLabelSub": [
          {
            "text": data[`label${elem}BR`],
            "lang": 1
          },
          {
            "text": data[`label${elem}EN`],
            "lang": 2
          },
          {
            "text": data[`label${elem}ES`],
            "lang": 3
          }
        ],
        "rangeStatus": elem === "Adult" || elem === "Private" || data[`label${elem}Enabled`] === true || data[`label${elem}Enabled`] === undefined ? 1 : 2,
        "rangeIncrement": data[`sellingIncrement${elem}`],
        "rangeOrder": index + 1,
        "flagStockUse": data[`flagStockUse${elem}`] ? 1 : 2
      })
    })

    data.productModalityRange = productModalityRange;

    data.ticketWithdrawl = watchTicketWithdrawl === "true" ? 1 : 2;

    // eslint-disable-next-line array-callback-return
    data.withDrawlAddress = withdrawQtd.map((e: any, index: number) => {
      if (e !== null) {
        return (
          {
            "withdrawlAddress": data[`withdrawAddress-${index}`],
            "withdrawlLatitude": data[`withdrawlLatitude-${index}`],
            "withdrawlLongetude": data[`withdrawlLongetude-${index}`],
            "ticketWithdrawlDescription": [
              {
                "text": data[`ticketWithdrawDescription-PT-${index}`],
                "lang": 1
              },
              {
                "text": data[`ticketWithdrawDescription-EN-${index}`],
                "lang": 2
              },
              {
                "text": data[`ticketWithdrawDescription-ES-${index}`],
                "lang": 3
              }
            ],
            "status": 1
          }
        )
      }
    }).filter((e: any) => e !== undefined);

    data.ticketSellingType = Number(watchTicketSellingType);
    data.peopleGroupLimit = Number(watchTicketSellingType) === 2 ? data.peopleGroupLimit : undefined;
    data.lineDetail = (watchLineDetailBoolean === "true" || watchLineDetailBoolean === true) ? data.lineDetail.value : 0;
    data.validationType = watchValidationType?.value;
    data.validationPeriodMinutes = ((watchValidationType?.value === 1 || watchValidationType?.value === 2) && parseInt(data.validationPeriodType) === 0) ? data.validationPeriod : 0;
    data.validationPeriodHours = ((watchValidationType?.value === 1 || watchValidationType?.value === 2) && parseInt(data.validationPeriodType) === 1) ? data.validationPeriod : 0;
    data.validationPeriodDays = ((watchValidationType?.value === 1 || watchValidationType?.value === 2) && parseInt(data.validationPeriodType) === 2) ? data.validationPeriod : 0;

    if (tourSIG.tour?.sellingType === 2) {
      data.tariffAgeTextAdult = [
        [
          {
            "text": data.tariffAgeTextAdultBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextAdultEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextAdultES || '',
            "lang": 3
          }
        ]
      ]

      data.tariffAgeTextChild = [
        [
          {
            "text": data.tariffAgeTextChildBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextChildEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextChildES || '',
            "lang": 3
          }
        ]
      ]

      data.tariffAgeTextInfant = [
        [
          {
            "text": data.tariffAgeTextInfantBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextInfantEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextInfantES || '',
            "lang": 3
          }
        ]
      ]

      data.tariffAgeTextStudent = [
        [
          {
            "text": data.tariffAgeTextStudentBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextStudentEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextStudentES || '',
            "lang": 3
          }
        ]
      ]

      data.tariffAgeTextElder = [
        [
          {
            "text": data.tariffAgeTextElderBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextElderEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextElderES || '',
            "lang": 3
          }
        ]
      ]

    } else {
      data.tariffAgeTextGlobal = [
        [
          {
            "text": data.tariffAgeTextGlobalBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextGlobalEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextGlobalES || '',
            "lang": 3
          }
        ]
      ]
    }

    return data;
  }

  const createTourObject = async (data: any) => {   

    data.isModalityPriorityCognitivo = Number(watchCognitivoPriority) === 1;
    data.isModalityExecuteIACognitivo = Number(watchCognitivoIAModel) === 1;

    data.descriptionPT = data.modalityDescriptionPT;
    data.descriptionEN = data.modalityDescriptionEN;
    data.descriptionES = data.modalityDescriptionES;

    data.dom = days[0].value;
    data.seg = days[1].value;
    data.ter = days[2].value;
    data.qua = days[3].value;
    data.qui = days[4].value;
    data.sex = days[5].value;
    data.sab = days[6].value;

    if (data.embarkingType && data.embarkingType !== 0 && data.embarkingType !== '0') {
      let pickupObj: any = JSON.parse(`${data.pickupList}`)

      data.pickupList = pickupObj.id;
      data.pickupDescription = pickupObj.description;
    }

    data.itemsIncluded = includesArray
      .filter((e: any) => e !== null)
      .map((e: any) => [
        {
          text: e.PT,
          lang: 1,
        },
        {
          text: e.EN,
          lang: 2,
        },
        {
          text: e.ES,
          lang: 3,
        },
      ]);

    data.itemsNotIncluded = excludesArray
      .filter((e: any) => e !== null)
      .filter(
        (e: any) => e.PT.length > 0 && e.EN.length > 0 && e.ES.length > 0
      )
      .map((e: any) => [
        {
          text: e.PT,
          lang: 1,
        },
        {
          text: e.EN,
          lang: 2,
        },
        {
          text: e.ES,
          lang: 3,
        },
      ]);

    let auxRefund: any = [];
    refundQtd.forEach((elem: any, index: number) => {
      if (elem !== null) {
        auxRefund.push({
          refundRules: 3,
          daysBeforeCheckin: parseInt(data[`daysBeforeCheckIn-${index}`]),
          refundPolicyType: parseInt(data[`policy-${index}`]) || 1,
          refundPercentage: data[`policy-${index}`] === '0' ? 0 : parseInt(data[`percentage-${index}`] || 0),
        })
      }
    });

    data.refundRules = auxRefund;

    data.offerGuidingDetails = watchIsTurism === "true" || watchIsTurism === true;

    data.guidingDetails = [
      {
        lang: 1,
        tourGuide: data.tourGuidePT || false,
        audioGuide: data.audioGuidePT || false,
        writingGuide: data.writingGuidePT || false,
      },
      {
        lang: 2,
        tourGuide: data.tourGuideEN || false,
        audioGuide: data.audioGuideEN || false,
        writingGuide: data.writingGuideEN || false,
      },
      {
        lang: 3,
        tourGuide: data.tourGuideES || false,
        audioGuide: data.audioGuideES || false,
        writingGuide: data.writingGuideES || false,
      },
    ];

    data.embarkingType = data.embarkingType === undefined || data.embarkingType === null ? 0 : data.embarkingType;
    data.freeSale = data.stock === "no" ? true : false;      

    /* integrações */
    data.haveAccessIntegration = integrationAccess !== null ? 1 : 2;
    data.havePurchaseIntegration = ['8', '9'].includes(tourSIG.tour.integrationPurchaseType) ? 1 : 2;
    data.haveSigSigIntegration = ['99'].includes(tourSIG.tour.integrationPurchaseType) ? 1 : 2;

    data.productPurchaseIntegration = integrationPurchase !== null ? [{ ...integrationPurchase }] : [];
    data.accessIntegration = integrationAccess !== null ? integrationAccess : [];
    data.sigSigIntegration = integrationSig !== null ? { ...integrationSig } : {};

    let productModalityRange: any = [];

    (tourSIG.tour?.sellingType === 2 ? ['Adult', "Child", "Infant"] : ['Private']).forEach((elem: any, index: number) => {
      productModalityRange.push({
        "id": 0,
        //"productCode": data.productModCode, // repetir produto
        //"productModCode": data.productModCode, //repetir modalidade
        "rangeId": elem,
        "rangeLabel": [
          {
            "text": data[`label${elem}BR`],
            "lang": 1
          },
          {
            "text": data[`label${elem}EN`],
            "lang": 2
          },
          {
            "text": data[`label${elem}ES`],
            "lang": 3
          }
        ],
        "rangeLabelSub": [
          {
            "text": data[`label${elem}BR`],
            "lang": 1
          },
          {
            "text": data[`label${elem}EN`],
            "lang": 2
          },
          {
            "text": data[`label${elem}ES`],
            "lang": 3
          }
        ],
        "rangeStatus": elem === "Adult" || elem === "Private" || data[`label${elem}Enabled`] === true || data[`label${elem}Enabled`] === undefined ? 1 : 2,
        "rangeIncrement": data[`sellingIncrement${elem}`],
        "rangeOrder": index + 1,
        "flagStockUse": data[`flagStockUse${elem}`] ? 1 : 2
      })
    })

    data.productModalityRange = productModalityRange;

    if (tourSIG.tour?.sellingType === 2) {
      data.tariffAgeTextAdult = [
        [
          {
            "text": data.tariffAgeTextAdultBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextAdultEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextAdultES || '',
            "lang": 3
          }
        ]
      ]

      data.tariffAgeTextInfant = [
        [
          {
            "text": data.tariffAgeTextInfantBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextInfantEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextInfantES || '',
            "lang": 3
          }
        ]
      ]

      data.tariffAgeTextChild = [
        [
          {
            "text": data.tariffAgeTextChildBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextChildEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextChildES || '',
            "lang": 3
          }
        ]
      ]
    } else {
      data.tariffAgeTextGlobal = [
        [
          {
            "text": data.tariffAgeTextGlobalBR || '',
            "lang": 1
          },
          {
            "text": data.tariffAgeTextGlobalEN || '',
            "lang": 2
          },
          {
            "text": data.tariffAgeTextGlobalES || '',
            "lang": 3
          }
        ]
      ]
    }

    return data;
  }

  async function createObjectModality() {
    setModalShow(true);
    setModalLog(null);

    let data: any = getValues();
    let dataToInsert: any;

    if (isTicket) {
      dataToInsert = await createTicketObject(data);
    } else {
      dataToInsert = await createTourObject(data);
    }

    if (isEditing === null) {
      dispatch(isTicket ? ticketAddModality(dataToInsert) : addModality(dataToInsert));
    } else {
      
      if (isEdit) {
        if (isTicket) {
          dataToInsert.id = tourSIG.tour.modalidade[isEditing].id;
          dataToInsert.ticketModCode = tourSIG.tour.modalidade[isEditing].ticketModCode;
          dataToInsert.ticketModUniqueCode = tourSIG.tour.modalidade[isEditing].ticketModUniqueCode;
        } else {
          dataToInsert.idModalidade = tourSIG.tour.modalidade[isEditing].idModalidade;
          dataToInsert.productModCode = tourSIG.tour.modalidade[isEditing].productModCode;
          dataToInsert.uniqueModCode = tourSIG.tour.modalidade[isEditing].uniqueModCode;
        }
      }

      dataToInsert.keepModCode = isEdit;
      dataToInsert.indexToEdit = isEditing;

      if (isTicket) {
        dataToInsert.ticketTariff = tourSIG.tour.modalidade[isEditing]?.ticketTariff;

        if (dataToInsert.ticketTariff?.length > 0) {
          dataToInsert.ticketTariff.forEach((elem: any) => {
            elem.ticketDailyTariff.forEach((dailyElem: any) => {
              dailyElem.dom = dataToInsert.dom === 2 ? 2 : dailyElem.dom;
              dailyElem.seg = dataToInsert.seg === 2 ? 2 : dailyElem.seg;
              dailyElem.ter = dataToInsert.ter === 2 ? 2 : dailyElem.ter;
              dailyElem.qua = dataToInsert.qua === 2 ? 2 : dailyElem.qua;
              dailyElem.qui = dataToInsert.qui === 2 ? 2 : dailyElem.qui;
              dailyElem.sex = dataToInsert.sex === 2 ? 2 : dailyElem.sex;
              dailyElem.sab = dataToInsert.sab === 2 ? 2 : dailyElem.sab;
            });
          });
        }
      } else {
        dataToInsert.tariff = tourSIG.tour.modalidade[isEditing]?.tariff;

        if (dataToInsert.tariff?.length > 0) {
          dataToInsert.tariff.forEach((elem: any) => {
            elem.daysTariff.forEach((dailyElem: any) => {
              dailyElem.daysOfWeek.dom = dataToInsert.dom === false ? false : dailyElem.daysOfWeek.dom;
              dailyElem.daysOfWeek.seg = dataToInsert.seg === false ? false : dailyElem.daysOfWeek.seg;
              dailyElem.daysOfWeek.ter = dataToInsert.ter === false ? false : dailyElem.daysOfWeek.ter;
              dailyElem.daysOfWeek.qua = dataToInsert.qua === false ? false : dailyElem.daysOfWeek.qua;
              dailyElem.daysOfWeek.qui = dataToInsert.qui === false ? false : dailyElem.daysOfWeek.qui;
              dailyElem.daysOfWeek.sex = dataToInsert.sex === false ? false : dailyElem.daysOfWeek.sex;
              dailyElem.daysOfWeek.sab = dataToInsert.sab === false ? false : dailyElem.daysOfWeek.sab;
            });
          });
        }
      }

      dispatch(isTicket ? ticketEditModality(dataToInsert) : editModality(dataToInsert));

      setModalShow(true);
      setModalLog(0);
      setModalMessage("Modalidade editada com sucesso!");
      setIsEditing(null);

      resetForm();
    }
  }

  function changeDays(index: any) {
    let aux: any = days;
    aux[index].value = !aux[index].value;
    setDays(aux);

    setReRender(!reRender);
  }

  function changeAllDays(newValue: boolean) {
    setCheck(newValue);
    let aux: any = days;

    aux.forEach((elem: any) => {
      elem.value = newValue;
    });
    setDays(aux);

    setReRender(!reRender);
  }

  /* const handleClickCustomCancelation = () => {
    let tempValues: any = getValues();

    let id: number = refundRules
      .map((e: any) => e.id)
      .reduce(
        (prev: any, current: any) => current + prev,
        refundRules.length
      );

    setRefundRules((prev: any) => [
      ...prev,
      {
        id: id,
        daysBeforeCheckin: tempValues.daysBeforeCheckIn,
        refundPolicyType: tempValues.policy,
        refundPercentage: tempValues.percentage || 0,
      },
    ]);
  }; */

  useEffect(() => {
    setValue("labelAdultEnabled", true);
    setValue("labelChildEnabled", true);
    setValue("labelInfantEnabled", true);
    setValue("labelEldersEnabled", true);
    setValue("labelStudentEnabled", true);
    setValue("labelPrivateEnabled", true);
    setValue("flagStockUseAdult", true);
    setValue("flagStockUseChild", true);
    setValue("flagStockUseInfant", false);
    setValue("flagStockUsePrivate", true);
    setValue("flagStockUseElders", true);
    setValue("flagStockUseStudent", true);
    setValue("ticketSellingType", '1');
    setValue("validationPeriodType", '0');
    setValue("isFreeSale", '1');
    setValue("isModalityPriorityCognitivo", 2);
    setValue("isModalityExecuteIACognitivo", 2);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleBlurModalityName = (e: any, name: string) => {
    if (tourObject.isTranslate === 1) {
      TranslateTextEN(e.target.value, name);
      TranslateTextES(e.target.value, name);
    }
  };

  const handleBlurWithdrawlAddress = (e: any, name: string, index: number) => {
    if (tourObject.isTranslate === 1) {
      TranslateTextEN(e.target.value, name, index);
      TranslateTextES(e.target.value, name, index);
    }
  };

  const TranslateTextEN = async (value: string, name: string, index: number | null = null) => {
    try {
      const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
        "q": value,
        "source": "pt-BR",
        "target": "en",
        "format": "text"
      });
      if (res.status !== 400) {
        //setSummaryEN(res.data.responseData.translatedText);
        if (index === null) {
          setValue(`${name}EN`, res?.data?.data?.translations[0]?.translatedText);
        } else {
          setValue(`${name}-EN-${index}`, res?.data?.data?.translations[0]?.translatedText);
        }
      }
    } catch (error: any) { }
  };

  const TranslateTextES = async (value: string, name: string, index: number | null = null) => {
    try {
      const res = await api.post(`https://translation.googleapis.com/language/translate/v2?key=${process.env.REACT_APP_GOOGLE_CLOUD_TRANSLATE_API}`, {
        "q": value,
        "source": "pt-BR",
        "target": "es",
        "format": "text"
      });
      if (res.status !== 400) {
        //setSummaryES(res.data.responseData.translatedText);
        if (index === null) {
          setValue(`${name}ES`, res?.data?.data?.translations[0]?.translatedText);
        } else {
          setValue(`${name}-ES-${index}`, res?.data?.data?.translations[0]?.translatedText);
        }
      }
    } catch (error: any) { }
  };

  const removeModalityFunc = (index: number) => {
    if (isEditing === index) {
      setIsEditing(null);
    }
    //console.log(index)
    dispatch(removeModality(index));
  };

  const duplicateModalityFunc = (index: number) => {
    if (isTicket) {
      dispatch(ticketDuplicateModality({ index: index, isEdit: isEdit }));
    } else {
      dispatch(duplicateModality({ index: index, isEdit: isEdit }));
    }
  };

  const editModalityFunc = (elem: any, index: number) => {
    if (isEditing === index) {
      setIsEditing(null);
      resetForm();
      return;
    } else {
      setIsEditing(index);
    }

    let modalidade: any = document.getElementById("modalidade");

    const y = modalidade.getBoundingClientRect().top + window.scrollY - 130;
    window.scroll({
      top: y,
      behavior: 'smooth'
    });

    if (isTicket) {
      setValue("ticketWithdrawl", 'false');
      setValue("ticketSellingType", '' + elem.ticketSellingType);
      setValue("peopleGroupLimit", '' + elem.peopleGroupLimit);
      setDefaultLineDetail(elem.lineDetail);
      setValue("lineDetailBoolean", elem.lineDetail === 0 ? 'false' : 'true');
      setValue("offerTransportation", elem.offerTransportation === 1 ? 'yes' : 'no');
      setValue("wheelchairAccessibility", elem.wheelchairAccessibility === 1 ? '1' : '2');

      setDefaultTicketValidationType(elem.validationType);

      if (elem.validationType === 4) {
        let newValidationDefinedDate = new Date(elem.validationDefinedDate + "T00:00:00").toLocaleDateString();
        setValidationDefinedDate(newValidationDefinedDate);
      } else if (elem.validationType === 1 || elem.validationType === 2) {
        if (parseInt(elem.validationPeriodMinutes) > 0) {
          setValue("validationPeriod", elem.validationPeriodMinutes);
          setValue("validationPeriodType", 0);
        } else if (parseInt(elem.validationPeriodHours) > 0) {
          setValue("validationPeriod", elem.validationPeriodHours);
          setValue("validationPeriodType", 1);
        } else if (parseInt(elem.validationPeriodDays) > 0) {
          setValue("validationPeriod", elem.validationPeriodDays);
          setValue("validationPeriodType", 2);
        }
      }

      let newWithdrawQtd: any = [];

      for (let i: number = 0; i < elem.withDrawlAddress?.length; i++) {
        newWithdrawQtd.push(true);
      }

      setWithdrawQtd(
        newWithdrawQtd.length > 0
          ? newWithdrawQtd
          : [true]
      );

      for (let i: number = 0; i < elem.withDrawlAddress?.length; i++) {
        setValue(`withdrawAddress-${i}`, elem.withDrawlAddress[i].withdrawlAddress);
        setValue(`withdrawlLatitude-${i}`, elem.withDrawlAddress[i].withdrawlLatitude);
        setValue(`withdrawlLongetude-${i}`, elem.withDrawlAddress[i].withdrawlLongetude);
        setValue(`ticketWithdrawDescription-PT-${i}`, elem.withDrawlAddress[i].ticketWithdrawlDescription && elem.withDrawlAddress[i].ticketWithdrawlDescription[0].text);
        setValue(`ticketWithdrawDescription-EN-${i}`, elem.withDrawlAddress[i].ticketWithdrawlDescription && elem.withDrawlAddress[i].ticketWithdrawlDescription[1].text);
        setValue(`ticketWithdrawDescription-ES-${i}`, elem.withDrawlAddress[i].ticketWithdrawlDescription && elem.withDrawlAddress[i].ticketWithdrawlDescription[2].text);

      }
      setValue("ticketWithdrawl", elem.ticketWithdrawl === 1 ? 'true' : 'false');
    }

    setValue("modalityNamePT", elem.modalityName[0]?.text);
    setValue("modalityNameEN", elem.modalityName[1]?.text);
    setValue("modalityNameES", elem.modalityName[2]?.text);

    if (elem.referenceCode) {
      setValue("referenceCodePT", elem.referenceCode[0]?.text || "");
      setValue("referenceCodeEN", elem.referenceCode[1]?.text || "");
      setValue("referenceCodeES", elem.referenceCode[2]?.text || "");
    }
    setValue("modalityDescriptionPT", elem.modalityDescription[0]?.text);
    setValue("modalityDescriptionEN", elem.modalityDescription[1]?.text);
    setValue("modalityDescriptionES", elem.modalityDescription[2]?.text);

    let duration: string = ''

    if (isTicket) {
      duration = (parseInt(elem.eventDurationHours || 0) >= 10 ? '' + elem.eventDurationHours : `0${parseInt(elem.eventDurationHours || 0)}`) + ":" + (elem.eventDurationMinutes === 0 ? "00" : elem.eventDurationMinutes);
    } else {
      duration = (parseInt(elem.durationHours || 0) >= 10 ? '' + elem.durationHours : `0${parseInt(elem.durationHours || 0)}`) + ":" + (elem.durationMinutes === 0 ? "00" : elem.durationMinutes);
    }

    setValue("activityDuration", duration);

    let auxEmbarkingType: any = elem.embarkingType;
    setValue("embarkingType", [0, 2].includes(elem.embarkingType) ? '0' : '1');
    setTimeout(() => {
      if (isTicket) {
        if (parseInt(elem.offerTransportation) === 1 && parseInt(auxEmbarkingType) === 2) {
          setValue("meetingPoint", elem.meetingPointLocation);
        } else if (parseInt(elem.offerTransportation) === 1 && parseInt(elem.embarkingType) === 1) {
          setTimeout(() => {
            let listaPickup: any = document.getElementById("pickupList");

            for (let i: number = 0; i < listaPickup.length; i++) {
              if (listaPickup[i].value.includes(`"id": ${elem.pickupLocationId},`)) {
                setValue("pickupList", listaPickup[i].value);
              }
            }
          }, 1000);
        }
      } else {
        if (elem.embarkingType === 0 || elem.embarkingType === "0") {
          setValue("meetingPoint", elem.meetingPoint);
        } else {
          setTimeout(() => {
            let listaPickup: any = document.getElementById("pickupList");

            for (let i: number = 0; i < listaPickup.length; i++) {
              if (listaPickup[i].value.includes(`"id": ${elem.pickupList},`)) {
                setValue("pickupList", listaPickup[i].value);
              }
            }
          }, 2000);
        }
      }
    }, 1000);

    if (elem.guidingDetails.length > 0) {
      setValue("isTurism", "true");
      setValue("tourGuidePT", isTicket ? elem.guidingDetails.length > 0 && elem.guidingDetails[0].tourGuide === 1 : elem.guidingDetails[0].tourGuide);
      setValue("tourGuideEN", isTicket ? elem.guidingDetails.length > 1 && elem.guidingDetails[1].tourGuide === 1 : elem.guidingDetails[1].tourGuide);
      setValue("tourGuideES", isTicket ? elem.guidingDetails.length > 2 && elem.guidingDetails[2].tourGuide === 1 : elem.guidingDetails[2].tourGuide);

      setValue("audioGuidePT", isTicket ? elem.guidingDetails.length > 0 && elem.guidingDetails[0].audioGuide === 1 : elem.guidingDetails[0].audioGuide);
      setValue("audioGuideEN", isTicket ? elem.guidingDetails.length > 1 && elem.guidingDetails[1].audioGuide === 1 : elem.guidingDetails[1].audioGuide);
      setValue("audioGuideES", isTicket ? elem.guidingDetails.length > 2 && elem.guidingDetails[2].audioGuide === 1 : elem.guidingDetails[2].audioGuide);

      setValue("writingGuidePT", isTicket ? elem.guidingDetails.length > 0 && elem.guidingDetails[0].booklet === 1 : elem.guidingDetails[0].writingGuide);
      setValue("writingGuideEN", isTicket ? elem.guidingDetails.length > 1 && elem.guidingDetails[1].booklet === 1 : elem.guidingDetails[1].writingGuide);
      setValue("writingGuideES", isTicket ? elem.guidingDetails.length > 2 && elem.guidingDetails[2].booklet === 1 : elem.guidingDetails[2].writingGuide);
    } else {
      setValue("isTurism", "false");
    }

    //setValue("buyingAdvance", isTicket ? elem.buyingAdvanceHours + 'h' : elem.buyingAdvance);

    setDays([
      {
        day: "dom",
        value: isTicket ? elem.dom === 1 : elem.daysOfWeek.dom,
      },
      {
        day: "seg",
        value: isTicket ? elem.seg === 1 : elem.daysOfWeek.seg,
      },
      {
        day: "ter",
        value: isTicket ? elem.ter === 1 : elem.daysOfWeek.ter,
      },
      {
        day: "qua",
        value: isTicket ? elem.qua === 1 : elem.daysOfWeek.qua,
      },
      {
        day: "qui",
        value: isTicket ? elem.qui === 1 : elem.daysOfWeek.qui,
      },
      {
        day: "sex",
        value: isTicket ? elem.sex === 1 : elem.daysOfWeek.sex,
      },
      {
        day: "sab",
        value: isTicket ? elem.sab === 1 : elem.daysOfWeek.sab,
      },
    ]);

    if (tourSIG.tour.sellingType === 1) {
      setValue("labelPrivateBR", elem.productModalityRange[0].rangeLabel[0].text);
      setValue("labelPrivateEN", elem.productModalityRange[0].rangeLabel[1].text);
      setValue("labelPrivateES", elem.productModalityRange[0].rangeLabel[2].text);
      setValue("tariffAgePrivateText", elem.tariffAgePrivateText);
      setValue("sellingIncrementPrivate", elem.productModalityRange[0].rangeIncrement);
      setValue("flagStockUsePrivate", elem.productModalityRange[0].flagStockUse === 1);
      setValue("labelPrivateEnabled", [0, 1].includes(elem.productModalityRange[0].rangeStatus));

      setValue('tariffAgeTextAdultBR', elem.tariffAgeAdultText || '');
      setValue('tariffAgeTextAdultEN', elem.tariffAgeAdultTextEN || '');
      setValue('tariffAgeTextAdultES', elem.tariffAgeAdultTextES || '');

      setValue('tariffAgeTextChildBR', elem.tariffAgeChildText || '');
      setValue('tariffAgeTextChildEN', elem.tariffAgeChildTextEN || '');
      setValue('tariffAgeTextChildES', elem.tariffAgeChildTextES || '');

      setValue('tariffAgeTextInfantBR', elem.tariffAgeInfantText || '');
      setValue('tariffAgeTextInfantEN', elem.tariffAgeInfantTextEN || '');
      setValue('tariffAgeTextInfantES', elem.tariffAgeInfantTextES || '');

      setValue('tariffAgeTextGlobalBR', elem.tariffAgeGlobalText || '');
      setValue('tariffAgeTextGlobalEN', elem.tariffAgeGlobalTextEN || '');
      setValue('tariffAgeTextGlobalES', elem.tariffAgeGlobalTextES || '');

    } else {
      setValue("labelAdultBR", elem.productModalityRange[0].rangeLabel[0].text);
      setValue("labelAdultEN", elem.productModalityRange[0].rangeLabel[1].text);
      setValue("labelAdultES", elem.productModalityRange[0].rangeLabel[2].text);
      setValue("tariffAdultAgeText", elem.tariffAdultAgeText);
      setValue("sellingIncrementAdult", elem.productModalityRange[0].rangeIncrement);
      setValue("flagStockUseAdult", elem.productModalityRange[0].flagStockUse === 1);

      setValue('tariffAgeTextAdultBR', elem.tariffAgeAdultText || '');
      setValue('tariffAgeTextAdultEN', elem.tariffAgeAdultTextEN || '');
      setValue('tariffAgeTextAdultES', elem.tariffAgeAdultTextES || '');

      setValue('tariffAgeTextChildBR', elem.tariffAgeChildText || '');
      setValue('tariffAgeTextChildEN', elem.tariffAgeChildTextEN || '');
      setValue('tariffAgeTextChildES', elem.tariffAgeChildTextES || '');

      setValue('tariffAgeTextInfantBR', elem.tariffAgeInfantText || '');
      setValue('tariffAgeTextInfantEN', elem.tariffAgeInfantTextEN || '');
      setValue('tariffAgeTextInfantES', elem.tariffAgeInfantTextES || '');

      setValue('tariffAgeTextElderBR', elem.tariffAgeElderText ? elem.tariffAgeElderText : '');
      setValue('tariffAgeTextElderEN', elem.tariffAgeElderTextEN ? elem.tariffAgeElderTextEN : '');
      setValue('tariffAgeTextElderES', elem.tariffAgeElderTextES ? elem.tariffAgeElderTextES : '');

      setValue('tariffAgeTextStudentBR', elem.tariffAgeStudentText || '');
      setValue('tariffAgeTextStudentEN', elem.tariffAgeStudentTextEN || '');
      setValue('tariffAgeTextStudentES', elem.tariffAgeStudentTextES || '');

      setValue('tariffAgeTextGlobalBR', elem.tariffAgeGlobalText || '');
      setValue('tariffAgeTextGlobalEN', elem.tariffAgeGlobalTextEN || '');
      setValue('tariffAgeTextGlobalES', elem.tariffAgeGlobalTextES || '');

      if (elem.productModalityRange.length > 1) {
        setValue("labelChildBR", elem.productModalityRange[1].rangeLabel[0].text);
        setValue("labelChildEN", elem.productModalityRange[1].rangeLabel[1].text);
        setValue("labelChildES", elem.productModalityRange[1].rangeLabel[2].text);
        setValue("tariffChildAgeText", elem.tariffChildAgeText);
        setValue("sellingIncrementChild", elem.productModalityRange[1].rangeIncrement);
        setValue("flagStockUseChild", elem.productModalityRange[1].flagStockUse === 1);
        setValue("labelChildEnabled", [0, 1].includes(elem.productModalityRange[1].rangeStatus));
      } else {
        setValue("labelChildBR", "Criança");
        setValue("labelChildEN", "Child");
        setValue("labelChildES", "Niño");
        setValue("sellingIncrementChild", 1);
        setValue("flagStockUseChild", true);
        setValue("labelChildEnabled", false);
      }

      if (elem.productModalityRange.length > 2) {
        setValue("labelInfantBR", elem.productModalityRange[2].rangeLabel[0].text);
        setValue("labelInfantEN", elem.productModalityRange[2].rangeLabel[1].text);
        setValue("labelInfantES", elem.productModalityRange[2].rangeLabel[2].text);
        setValue("tariffInfantAgeText", elem.tariffInfantAgeText);
        setValue("sellingIncrementInfant", elem.productModalityRange[2].rangeIncrement);
        setValue("flagStockUseInfant", elem.productModalityRange[2].flagStockUse === 1);
        setValue("labelInfantEnabled", [0, 1].includes(elem.productModalityRange[2].rangeStatus));
      } else {
        setValue("labelInfantBR", "Infante");
        setValue("labelInfantEN", "Infant");
        setValue("labelInfantES", "Infantil");
        setValue("sellingIncrementInfant", 1);
        setValue("flagStockUseInfant", false);
        setValue("labelInfantEnabled", false);
      }

      if (isTicket) {
        if (elem.productModalityRange.length > 3) {
          setValue("labelEldersBR", elem.productModalityRange[3].rangeLabel[0].text);
          setValue("labelEldersEN", elem.productModalityRange[3].rangeLabel[1].text);
          setValue("labelEldersES", elem.productModalityRange[3].rangeLabel[2].text);
          setValue("tariffEldersAgeText", elem.tariffEldersAgeText);
          setValue("sellingIncrementElders", elem.productModalityRange[3].rangeIncrement);
          setValue("flagStockUseElders", elem.productModalityRange[3].flagStockUse === 1);
          setValue("labelEldersEnabled", [0, 1].includes(elem.productModalityRange[3].rangeStatus));
        }

        if (elem.productModalityRange.length > 4) {
          setValue("labelStudentBR", elem.productModalityRange[4].rangeLabel[0].text);
          setValue("labelStudentEN", elem.productModalityRange[4].rangeLabel[1].text);
          setValue("labelStudentES", elem.productModalityRange[4].rangeLabel[2].text);
          setValue("tariffStudentAgeText", elem.tariffStudentAgeText);
          setValue("sellingIncrementStudent", elem.productModalityRange[4].rangeIncrement);
          setValue("flagStockUseStudent", elem.productModalityRange[4].flagStockUse === 1);
          setValue("labelStudentEnabled", [0, 1].includes(elem.productModalityRange[4].rangeStatus));
        }
      }
    }

    let includedEditArray: any = [];

    for (let i: number = 0; i < elem[`${isTicket ? 'itensIncluded' : 'itemsIncluded'}`]?.length; i++) {
      includedEditArray.push({
        PT: isTicket ? elem.itensIncluded[i][0].itensIncluded.text : elem.itemsIncluded[i][0].text,
        EN: isTicket ? elem.itensIncluded[i][1].itensIncluded.text : elem.itemsIncluded[i][1].text,
        ES: isTicket ? elem.itensIncluded[i][2].itensIncluded.text : elem.itemsIncluded[i][2].text,
      });

      setValue(`itemsIncludedPT-${i}`, isTicket ? elem.itensIncluded[i][0].itensIncluded.text : elem.itemsIncluded[i][0].text);
      setValue(`itemsIncludedEN-${i}`, isTicket ? elem.itensIncluded[i][1].itensIncluded.text : elem.itemsIncluded[i][1].text);
      setValue(`itemsIncludedES-${i}`, isTicket ? elem.itensIncluded[i][2].itensIncluded.text : elem.itemsIncluded[i][2].text);
    }

    setIncludesArray(
      includedEditArray.length > 0
        ? includedEditArray
        : [{ PT: "", EN: "", ES: "" }]
    );

    let excludedEditArray: any = [];

    for (let i: number = 0; i < elem[`${isTicket ? 'itensNotIncluded' : 'itemsNotIncluded'}`]?.length; i++) {
      excludedEditArray.push({
        PT: isTicket ? (elem.itensNotIncluded[i].length > 0 ? elem.itensNotIncluded[i][0].itensNotIncluded.text : '') : elem.itemsNotIncluded[i][0].text,
        EN: isTicket ? (elem.itensNotIncluded[i].length > 0 ? elem.itensNotIncluded[i][1].itensNotIncluded.text : '') : elem.itemsNotIncluded[i][1].text,
        ES: isTicket ? (elem.itensNotIncluded[i].length > 0 ? elem.itensNotIncluded[i][2].itensNotIncluded.text : '') : elem.itemsNotIncluded[i][2].text,
      });

      setValue(`itemsNotIncludedPT-${i}`, isTicket ? (elem.itensNotIncluded[i].length > 0 ? elem.itensNotIncluded[i][0].itensNotIncluded.text : '') : elem.itemsNotIncluded[i][0].text);
      setValue(`itemsNotIncludedEN-${i}`, isTicket ? (elem.itensNotIncluded[i].length > 0 ? elem.itensNotIncluded[i][1].itensNotIncluded.text : '') : elem.itemsNotIncluded[i][1].text);
      setValue(`itemsNotIncludedES-${i}`, isTicket ? (elem.itensNotIncluded[i].length > 0 ? elem.itensNotIncluded[i][2].itensNotIncluded.text : '') : elem.itemsNotIncluded[i][2].text);
    }

    setExcludesArray(
      excludedEditArray.length > 0
        ? excludedEditArray
        : [{ PT: "", EN: "", ES: "" }]
    );

    if (elem[`${isTicket ? 'ticketRefundPolicy' : 'refundRules'}`].length > 0) {

      setValue("cancelationType", "" + elem[`${isTicket ? 'ticketRefundPolicy' : 'refundRules'}`][0][`${isTicket ? 'refundRule' : 'refundRules'}`]);

      let refundAux: any = [];
      let policyArrayAux: any = [];

      if (isTicket) {
        if (elem.ticketRefundPolicy[0].refundRule === 3 || elem.ticketRefundPolicy[0].refundRule === "3") {
          for (let i: number = 0; i < elem.ticketRefundPolicy.length; i++) {
            refundAux.push(true);
            policyArrayAux.push('' + elem.ticketRefundPolicy[i].ticketPolicy);
          }

          setRefundQtd(refundAux);
          setPolicyArray(policyArrayAux);

          setTimeout(() => {
            for (let i: number = 0; i < elem.ticketRefundPolicy.length; i++) {
              setValue(`daysBeforeCheckIn-${i}`, elem.ticketRefundPolicy[i].days);
              setValue(`policy-${i}`, elem.ticketRefundPolicy[i].ticketPolicy);
              setValue(`percentage-${i}`, elem.ticketRefundPolicy[i].percentage);
            }
          }, 1000);

        }

      } else {
        if (elem.refundRules[0].refundRules === 3 || elem.refundRules[0].refundRules === "3") {
          for (let i: number = 0; i < elem.refundRules.length; i++) {
            refundAux.push(true);
            policyArrayAux.push('' + elem.refundRules[i].refundPolicyType);
          }

          setRefundQtd(refundAux);
          setPolicyArray(policyArrayAux);

          setTimeout(() => {
            for (let i: number = 0; i < elem.refundRules.length; i++) {
              setValue(`daysBeforeCheckIn-${i}`, elem.refundRules[i].daysBeforeCheckin);
              setValue(`policy-${i}`, elem.refundRules[i].refundPolicyType);
              setValue(`percentage-${i}`, elem.refundRules[i].refundPercentage);
            }
          }, 1000);
        }
      }
    }

    setIntegrationPurchase(
      elem.productPurchaseIntegration.length === 0
        ? null
        : elem.productPurchaseIntegration[0]
    );
    setIntegrationAccess(parseInt(elem.haveAccessIntegration || 0) === 1 ? elem.accessIntegration : null);
    setIntegrationSig(parseInt(elem.haveSigSigIntegration || 0) === 1 ? elem.sigSigIntegration : null);

    setValue(`isFreeSale`, elem.isFreeSale || '1');
    setValue(`isModalityPriorityCognitivo`, elem.isModalityPriorityCognitivo);
    setValue(`isModalityExecuteIACognitivo`, elem.isModalityExecuteIACognitivo);
  };

  const updateModality = () => {
    editFunction({ modalidade: tourSIG.tour.modalidade })
  }

  const setLatitude = (lat: any, index: number) => {
    setValue(`withdrawlLatitude-${index}`, lat);
    //setWithdrawlLatitude(lat);
  }

  const setLongetude = (lng: any, index: number) => {
    setValue(`withdrawlLongetude-${index}`, lng);
    //setWithDrawlLongetude(lng);
  }

  const handleWithdrawAddress = () => {
    setWithdrawQtd((prev: any) => [...prev, true]);
  }

  const handleRefund = () => {
    setPolicyArray((prev: any) => [...prev, '1'])
    setRefundQtd((prev: any) => [...prev, true]);
  }

  const handleDeleteWithdrawItems = (indexToExclude: number) => {
    let aux: any = [];

    withdrawQtd.forEach((e: any, index: number) => {
      aux.push(index !== indexToExclude ? e : null);
    });

    setWithdrawQtd(() => [...aux]);
  }

  const handleDeleteRefund = (indexToExclude: number) => {
    let aux: any = [];

    refundQtd.forEach((e: any, index: number) => {
      aux.push(index !== indexToExclude ? e : null);
    });

    setRefundQtd(() => [...aux]);
  }

  useEffect(() => {
    if (typeof validationDefinedDate === typeof "") {
      setValue("validationDefinedDate", validationDefinedDate.split("/").reverse().join("-"));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [validationDefinedDate]);

  useEffect(() => {
    setValue('pickupList', '');
    setValue('meetingPoint', '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [watchShipmentType]);

  const callModalShow = () => {
    return (
      <>
        <ModalShow
          modalShow={modalShow}
          setModalShow={setModalShow}
          modalMessage={modalMessage}
          setModalMessage={setModalMessage}
          modalLog={modalLog}
          setModalLog={setModalLog}
        />
      </>
    )
  }

  const handleChangePolicy = (event: any, index: number) => {
    setPolicyArray((prev: any) => prev.map((e: any, i: number) => { return (index !== i ? e : (e === '1' ? (isTicket ? '2' : '0') : '1')) }));
    setValue(`policy-${index}`, event)
  }

  useEffect(() => {
    if (integrationPurchase !== null) {
      clearErrors(`errorIntegrationPurchase`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [integrationPurchase])

  /* const isNonNegative = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;
    console.log(newValue)
    if (Number(newValue) >= 0) {
      console.log('AQUIII')
      return newValue
    } else {
      return 0;
    }
    return Number(newValue) >= 0;
  }; */

  return (
    <>
      {
        (isEdit === false || tourSIG?.tour?.productName) ?
          <Col md={9} lg={10}>
            <Card className="pt-content summary">
              <div>
                <h3 className="text-primary pt-title mb-0" onClick={() => { console.log(tourSIG.tour) }}>Modalidades</h3>
              </div>
              <div className="">
                <Row>
                  {tourSIG?.tour?.modalidade?.length > 0 && (
                    <>
                      <div className="d-flex justify-content-between py-3">
                        <h5 className="mb-0 pt-subtitle">
                          Modalidades cadastradas
                        </h5>
                      </div>

                      <div className="modalidades-cadastradas w-100">
                        <Row className="w-100" style={{ rowGap: '12px', columnGap: '1%' }}>
                          {tourSIG?.tour?.modalidade?.map(
                            (elem: any, index: number) => {
                              let weekDaysAvailable: string[] = [];
                              let ticketDaysOfWeek: any = {
                                "dom": elem?.dom === 1,
                                "seg": elem?.seg === 1,
                                "ter": elem?.ter === 1,
                                "qua": elem?.qua === 1,
                                "qui": elem?.qui === 1,
                                "sex": elem?.sex === 1,
                                "sab": elem?.sab === 1,
                              };

                              for (const weekDayLabel in (isTicket ? ticketDaysOfWeek : elem?.daysOfWeek)) {
                                if ((isTicket ? ticketDaysOfWeek : elem?.daysOfWeek)[weekDayLabel] === true) {
                                  weekDaysAvailable.push(weekDayLabel);
                                }
                              }

                              return (
                                <>
                                  <Col md={6} className='p-0' style={{ maxWidth: "49%" }}>
                                    <div className="modality-cards">
                                      <div className={`d-flex justify-content-between align-items-center w-100 modality-card-name primary`}>
                                        <span>
                                          <b>{elem.modalityName[0].text}</b>
                                        </span>
                                        <div>
                                          <Dropdown drop="start">
                                            <Dropdown.Toggle variant="light ">
                                              <FontAwesomeIcon
                                                size="lg"
                                                icon={["fal", "ellipsis-v"]}
                                              />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                onClick={() => {
                                                  editModalityFunc(elem, index);
                                                }}
                                                eventKey="0"
                                              >
                                                <FontAwesomeIcon
                                                  icon={["fal", "edit"]}
                                                />
                                                {`${isEditing === index ? "Cancelar edição" : "Editar"}`}
                                              </Dropdown.Item>
                                              {isEditing === null &&
                                                <>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      duplicateModalityFunc(index);
                                                    }}
                                                    eventKey="0"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={["fal", "copy"]}
                                                    />
                                                    Duplicar
                                                  </Dropdown.Item>
                                                  <Dropdown.Item
                                                    onClick={() => {
                                                      removeModalityFunc(index);
                                                    }}
                                                    eventKey="1"
                                                    className="text-danger"
                                                  >
                                                    <FontAwesomeIcon
                                                      icon={["fal", "trash-alt"]}
                                                    />
                                                    Remover
                                                  </Dropdown.Item>
                                                </>
                                              }
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </div>
                                      </div>
                                      <div>
                                        <div className="d-flex flex-column modality-card-info mt-1">
                                          <span className='mb-2 text-wrap-custom'>
                                            <strong>Descrição: </strong>
                                            {elem.modalityDescription[0].text}
                                          </span>
                                          {
                                            isTicket ?
                                              <>
                                                {
                                                  elem.offerTransportation === 1 &&
                                                  <span className="text-wrap-custom">
                                                    <strong>{elem.embarkingType === 2 ? "Ponto de encontro: " : "Pickup: "}</strong>
                                                    {elem.embarkingType === 2 ? elem.meetingPointLocation || elem.meetingPoint : elem.pickupDescription}
                                                  </span>
                                                }
                                              </>
                                              :
                                              <>
                                                <span className="text-wrap-custom">
                                                  <strong>{elem.meetingPoint ? "Ponto de encontro: " : "Pickup: "}</strong>
                                                  {elem.meetingPoint || elem.pickupDescription}
                                                </span>
                                              </>
                                          }
                                          <div className="row weekDays my-2">
                                            <span className="col-12"><b>Dias da atividade: </b></span>
                                            {['dom', 'seg', 'ter', 'qua', 'qui', 'sex', 'sab'].map(
                                              (day: string, key: number) => {
                                                return (
                                                  <div
                                                    className="pr-2"
                                                  /* style={{ width: "unset" }} */
                                                  >
                                                    <div
                                                      key={key}
                                                      className={weekDaysAvailable.includes(day) ? "btn btn-primary label-small" : "btn btn-disabled label-small"}
                                                    >
                                                      <span className="text-capitalize">
                                                        {day}
                                                      </span>
                                                    </div>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </Col>
                                </>
                              );
                            }
                          )}
                        </Row>
                      </div>
                    </>
                  )}
                </Row>
              </div>

              <div className="content-modality-add mt-5">
                <Form
                  className="h-100"
                  noValidate
                  validated={false}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <div className="pt-content-info pt-0">
                    <Row>
                      <div className="d-flex justify-content-between pt-2">
                        <h5 className="mb-0 pt-subtitle" id="modalidade">Modalidade</h5>
                        {tourObject.isTranslate === 1 && (
                          <>
                            <div
                              className="btn-edit ml-2"
                              style={{ position: "unset" }}
                              onClick={() => setAllowEditModal(!allowEditModal)}
                            >
                              <FontAwesomeIcon
                                icon={["fal", "edit"]}
                                size="1x"
                                className="mr-2"
                              />
                              Editar
                            </div>
                          </>
                        )}
                      </div>

                      <Form.Group as={Col} md="12" controlId="" className="mt-4">
                        <Form.Label className="label-small d-flex justify-content-between">
                          <span>Nome da modalidade (Português)</span>

                          <span style={{ fontWeight: 400 }}>
                            {`${watchModalityNamePt.length}/1000 caracteres`}
                          </span>
                        </Form.Label>

                        <Controller
                          control={control}
                          name="modalityNamePT"
                          defaultValue={tourObject?.modalityNamePT || ""}
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe um nome para essa modalidade",
                            },
                            minLength: {
                              value: 10,
                              message: "Por favor, insira ao menos 10 caracteres",
                            },
                            maxLength: {
                              value: 1000,
                              message:
                                "Por favor, insira no máximo 1000 caracteres",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              aria-invalid={errors?.modalityNamePT ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              onBlur={(e: any) => {
                                allowEditModal === false && handleBlurModalityName(e, "modalityName");
                              }}
                              required
                              autoComplete='off'
                            />
                          )}
                        />
                        <div className="d-flex justify-content-between">
                          {errors.modalityNamePT ? (
                            <ErrorMessage
                              errors={errors}
                              name="modalityNamePT"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          ) : (
                            <>
                              <p></p>
                            </>
                          )}
                          <small>Mínimo 10 caracteres </small>
                        </div>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="" className="mt-4">
                        <Form.Label className="label-small d-flex justify-content-between">
                          <span>Nome da modalidade (Inglês)</span>

                          <span style={{ fontWeight: 400 }}>
                            {`${watchModalityNameEn.length}/1000 caracteres`}
                          </span>
                        </Form.Label>

                        <Controller
                          control={control}
                          name="modalityNameEN"
                          defaultValue={tourObject?.modalityNameEN || ""}
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe um nome para essa modalidade",
                            },
                            minLength: {
                              value: 10,
                              message: "Por favor, insira ao menos 10 caracteres",
                            },
                            maxLength: {
                              value: 1000,
                              message:
                                "Por favor, insira no máximo 1000 caracteres",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled={
                                tourObject.isTranslate === 1 &&
                                allowEditModal === false
                              }
                              aria-invalid={errors?.modalityNameEN ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              required
                              autoComplete='off'
                            />
                          )}
                        />

                        <div className="d-flex justify-content-between">
                          {errors.modalityNameEN ? (
                            <ErrorMessage
                              errors={errors}
                              name="modalityNameEN"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          ) : (
                            <>
                              <p></p>
                            </>
                          )}
                          <small>Mínimo 10 caracteres </small>
                        </div>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="" className="mt-4">
                        <Form.Label className="label-small d-flex justify-content-between">
                          <span>Nome da modalidade (Espanhol)</span>

                          <span style={{ fontWeight: 400 }}>
                            {`${watchModalityNameEs.length}/1000 caracteres`}
                          </span>
                        </Form.Label>

                        <Controller
                          control={control}
                          name="modalityNameES"
                          defaultValue={tourObject?.modalityNameES || ""}
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe um nome para essa modalidade",
                            },
                            minLength: {
                              value: 10,
                              message: "Por favor, insira ao menos 10 caracteres",
                            },
                            maxLength: {
                              value: 1000,
                              message:
                                "Por favor, insira no máximo 1000 caracteres",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              disabled={
                                tourObject.isTranslate === 1 &&
                                allowEditModal === false
                              }
                              aria-invalid={errors?.modalityNameES ? "true" : ""}
                              variant="standard"
                              margin="normal"
                              required
                              autoComplete='off'
                            />
                          )}
                        />
                        <div className="d-flex justify-content-between">
                          {errors.modalityNameES ? (
                            <ErrorMessage
                              errors={errors}
                              name="modalityNameES"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          ) : (
                            <>
                              <p></p>
                            </>
                          )}
                          <small>Mínimo 10 caracteres </small>
                        </div>
                      </Form.Group>

                      {/*{!(['8', '9'].includes(tourSIG.tour?.integrationPurchaseType) || tourSIG.tour?.integrationAccessType === '1') && (*/}
                      <>
                        <div className="d-flex justify-content-between align-items-baseline">
                          <small
                            className="mt-4 mb-0 cursor-pointer"
                            onClick={() => {
                              setRefcodeBool(!refcodeBool);
                            }}
                          >
                            <span style={{ fontWeight: 600 }}>
                              Inclua o código de referência da modalidade?
                            </span>{" "}
                            (Opcional)
                          </small>
                          {tourObject.isTranslate === 1 &&
                            refcodeBool === true && (
                              <>
                                <span
                                  className="btn-edit ml-2"
                                  style={{ position: "unset" }}
                                  onClick={() => setAllowEdit(!allowEdit)}
                                >
                                  <FontAwesomeIcon
                                    icon={["fal", "edit"]}
                                    size="1x"
                                    className="mr-2"
                                  />
                                  Editar
                                </span>
                              </>
                            )}
                        </div>

                        {refcodeBool === true ? (
                          <>
                            <Form.Group
                              as={Col}
                              md="4"
                              controlId=""
                              className="mt-4"
                            >
                              <Form.Label className="label-small">
                                Referênce Code (Português)
                              </Form.Label>

                              <Controller
                                control={control}
                                name="referenceCodePT"
                                defaultValue={tourObject?.referenceCodePT || ""}
                                rules={{
                                  required: { value: false, message: "22" },
                                }}
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    type="text"
                                    //disabled={tourObject.isTranslate === 1}
                                    aria-invalid={
                                      errors?.referenceCodePT ? "true" : ""
                                    }
                                    variant="standard"
                                    margin="normal"
                                    onBlur={(e: any) => {
                                      handleBlurModalityName(
                                        e,
                                        "referenceCode"
                                      );
                                    }}
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="referenceCodePT"
                                render={({ message }) => (
                                  <small style={{ color: "red" }}>
                                    {message}
                                  </small>
                                )}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId=""
                              className="mt-4"
                            >
                              <Form.Label className="label-small">
                                Referênce Code (Inglês)
                              </Form.Label>

                              <Controller
                                control={control}
                                name="referenceCodeEN"
                                defaultValue={tourObject?.referenceCodeEN || ""}
                                rules={{
                                  required: { value: false, message: "22" },
                                }}
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    type="text"
                                    disabled={
                                      tourObject.isTranslate === 1 &&
                                      allowEdit === false
                                    }
                                    aria-invalid={
                                      errors?.referenceCodeEN ? "true" : ""
                                    }
                                    variant="standard"
                                    margin="normal"
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="referenceCodeEN"
                                render={({ message }) => (
                                  <small style={{ color: "red" }}>
                                    {message}
                                  </small>
                                )}
                              />
                            </Form.Group>

                            <Form.Group
                              as={Col}
                              md="4"
                              controlId=""
                              className="mt-4"
                            >
                              <Form.Label className="label-small">
                                Referênce Code (Espanhol)
                              </Form.Label>

                              <Controller
                                control={control}
                                name="referenceCodeES"
                                defaultValue={tourObject?.referenceCodeES || ""}
                                rules={{
                                  required: { value: false, message: "22" },
                                }}
                                render={({ field }: any) => (
                                  <Form.Control
                                    {...field}
                                    type="text"
                                    disabled={
                                      tourObject.isTranslate === 1 &&
                                      allowEdit === false
                                    }
                                    aria-invalid={
                                      errors?.referenceCodeES ? "true" : ""
                                    }
                                    variant="standard"
                                    margin="normal"
                                    required
                                    autoComplete='off'
                                  />
                                )}
                              />
                              <ErrorMessage
                                errors={errors}
                                name="referenceCodeES"
                                render={({ message }) => (
                                  <small style={{ color: "red" }}>
                                    {message}
                                  </small>
                                )}
                              />
                            </Form.Group>
                          </>
                        ) : (
                          <></>
                        )}
                      </>
                      {/*)}*/}
                    </Row>
                  </div>

                  <div className="pt-content-info">
                    <Row>
                      <div className="d-flex justify-content-between pt-3">
                        <h5 className="mb-0 pt-subtitle">Descrição</h5>
                        {tourObject.isTranslate === 1 && (
                          <>
                            <div
                              className="btn-edit ml-2"
                              style={{ position: "unset" }}
                              onClick={() =>
                                setAllowEditDescription(!allowEditDescription)
                              }
                            >
                              <FontAwesomeIcon
                                icon={["fal", "edit"]}
                                size="1x"
                                className="mr-2"
                              />
                              Editar
                            </div>
                          </>
                        )}
                      </div>

                      <Form.Group as={Col} md="12" controlId="" className="mt-4">
                        <Form.Label className="label-small d-flex justify-content-between">
                          <span>Descrição do Tour (Português)</span>

                          <span style={{ fontWeight: 400 }}>
                            {`${watchModalityDescriptionPt.length}/1000 caracteres`}
                          </span>
                        </Form.Label>

                        <Controller
                          control={control}
                          name="modalityDescriptionPT"
                          defaultValue={tourObject?.modalityDescriptionPT || ""}
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe uma descrição para essa modalidade",
                            },
                            minLength: {
                              value: 10,
                              message: "Por favor, insira ao menos 10 caracteres",
                            },
                            maxLength: {
                              value: 1000,
                              message:
                                "Por favor, insira no máximo 1000 caracteres",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              as="textarea"
                              rows={4}
                              aria-invalid={
                                errors?.modalityDescriptionPT ? "true" : ""
                              }
                              variant="standard"
                              margin="normal"
                              onBlur={(e: any) => {
                                allowEditDescription === false && handleBlurModalityName(e, "modalityDescription");
                              }}
                              required
                              autoComplete='off'
                            />
                          )}
                        />
                        <div className="d-flex justify-content-between">
                          {errors.modalityDescriptionPT ? (
                            <ErrorMessage
                              errors={errors}
                              name="modalityDescriptionPT"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          ) : (
                            <>
                              <p></p>
                            </>
                          )}
                          <small>Mínimo 10 caracteres </small>
                        </div>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="" className="mt-4">
                        <Form.Label className="label-small d-flex justify-content-between">
                          <span>Descrição do Tour (Inglês) </span>

                          <span style={{ fontWeight: 400 }}>
                            {`${watchModalityDescriptionEn.length}/1000 caracteres`}
                          </span>
                        </Form.Label>

                        <Controller
                          control={control}
                          name="modalityDescriptionEN"
                          defaultValue={tourObject?.modalityDescriptionEN || ""}
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe uma descrição para essa modalidade",
                            },
                            minLength: {
                              value: 10,
                              message: "Por favor, insira ao menos 10 caracteres",
                            },
                            maxLength: {
                              value: 1000,
                              message:
                                "Por favor, insira no máximo 1000 caracteres",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              type="text"
                              as="textarea"
                              rows={4}
                              aria-invalid={
                                errors?.modalityDescriptionEN ? "true" : ""
                              }
                              variant="standard"
                              margin="normal"
                              disabled={
                                tourObject.isTranslate === 1 &&
                                allowEditDescription === false
                              }
                              required
                              autoComplete='off'
                            />
                          )}
                        />
                        <div className="d-flex justify-content-between">
                          {errors.modalityDescriptionEN ? (
                            <ErrorMessage
                              errors={errors}
                              name="modalityDescriptionEN"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          ) : (
                            <>
                              <p></p>
                            </>
                          )}
                          <small>Mínimo 10 caracteres </small>
                        </div>
                      </Form.Group>

                      <Form.Group as={Col} md="6" controlId="" className="mt-4">
                        <Form.Label className="label-small d-flex justify-content-between">
                          <span>Descrição do Tour (Espanhol)</span>

                          <span style={{ fontWeight: 400 }}>
                            {`${watchModalityDescriptionEs.length}/1000 caracteres`}
                          </span>
                        </Form.Label>

                        <Controller
                          control={control}
                          name="modalityDescriptionES"
                          defaultValue={tourObject?.modalityDescriptionES || ""}
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe uma descrição para essa modalidade",
                            },
                            minLength: {
                              value: 10,
                              message: "Por favor, insira ao menos 10 caracteres",
                            },
                            maxLength: {
                              value: 1000,
                              message:
                                "Por favor, insira no máximo 1000 caracteres",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Control
                              {...field}
                              as="textarea"
                              rows={4}
                              type="text"
                              aria-invalid={
                                errors?.modalityDescriptionES ? "true" : ""
                              }
                              variant="standard"
                              margin="normal"
                              disabled={
                                tourObject.isTranslate === 1 &&
                                allowEditDescription === false
                              }
                              required
                              autoComplete='off'
                            />
                          )}
                        />
                        <div className="d-flex justify-content-between">
                          {errors.modalityDescriptionES ? (
                            <ErrorMessage
                              errors={errors}
                              name="modalityDescriptionES"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          ) : (
                            <>
                              <p></p>
                            </>
                          )}
                          <small>Mínimo 10 caracteres </small>
                        </div>
                      </Form.Group>
                    </Row>
                  </div>

                  <div className="pt-content-info">
                    <Row>
                      <div className="d-flex justify-content-between pt-3">
                        <h5 className="mb-0 pt-subtitle">Funcionamento</h5>
                      </div>

                      <Form.Group as={Col} md="5" className="mt-4">
                        <div className="d-flex justify-content-between">
                          <Form.Label className="label-small">
                            Horários de funcionamento
                          </Form.Label>

                          <div className="d-flex">
                            <Controller
                              control={control}
                              name="allDays"
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  id={`allDays`}
                                  type="checkbox"
                                  variant="standard"
                                  margin="normal"
                                  onChange={(e: any) => changeAllDays(!check)}
                                  required
                                  className="mx-2"
                                />
                              )}
                            />
                            <Form.Label className="label-small" htmlFor="allDays">
                              Selecionar tudo
                            </Form.Label>
                          </div>
                        </div>

                        <div className="d-flex justify-content-between">
                          {days.map((elem: any, index: any) => {
                            return (
                              <div key={index}>
                                <div className="d-flex w-auto">
                                  <Controller
                                    control={control}
                                    name={elem.day}
                                    render={({ field }: any) => (
                                      <Form.Check
                                        {...field}
                                        id={`days${index}`}
                                        type="checkbox"
                                        variant="standard"
                                        margin="normal"
                                        onChange={(e: any) => changeDays(index)}
                                        required
                                        className="d-none mx-2"
                                      />
                                    )}
                                  />
                                  <div>
                                    <Form.Label
                                      className={
                                        elem.value === false
                                          ? "btn btn-outline-primary label-small"
                                          : "btn btn-primary label-small"
                                      }
                                      htmlFor={`days${index}`}
                                    >
                                      <span className="text-capitalize">
                                        {elem.day}
                                      </span>
                                    </Form.Label>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </Form.Group>

                      <Row>
                        <Form.Group as={Col} md="5" controlId="" className="mt-4">
                          <Form.Label className="label-small">Duração</Form.Label>

                          <Controller
                            control={control}
                            name="activityDuration"
                            rules={{
                              required: {
                                value: true,
                                message: "Por favor, informe o tipo de operação",
                              },
                            }}
                            defaultValue={"01:00"}
                            render={({ field }: any) => (
                              <div className="endpoint">
                                <Form.Select
                                  {...field}
                                  aria-invalid={
                                    errors?.activityDuration ? "true" : ""
                                  }
                                  as="select"
                                  variant="standard"
                                  margin="normal"
                                >
                                  {activityDurationOptions.map((item: any) => {
                                    return (
                                      <>
                                        <option value={item} >{item}</option>
                                      </>
                                    )
                                  })}
                                </Form.Select>
                              </div>
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="activityDuration"
                            render={({ message }) => (
                              <small style={{ color: "red" }}>{message}</small>
                            )}
                          />
                        </Form.Group>
                        {/* {isTicket
                      ?
                      <>
                        <Col md='5'>
                          <Form.Group as={Col} md="12" controlId="" className="mt-4">
                            <Form.Label className="label-small">
                              Quanto tempo dura toda sua experiência?
                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Controller
                                control={control}
                                name="typeDuration"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-4 mb-1"
                                    type="radio"
                                    id={"typeDuration-ckeck-yes"}
                                    value="1"
                                    required
                                    label={"Duração definida"}
                                    checked={getValues().typeDuration === "1"}
                                  />
                                )}
                              />

                              <Controller
                                control={control}
                                name="typeDuration"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-4 mb-1"
                                    type="radio"
                                    id={"typeDuration-ckeck-no"}
                                    value="2"
                                    required
                                    //defaultChecked={tourObject?.typeDuration === 'no' || true}
                                    label={"Duração flexível"}
                                    checked={
                                      getValues().typeDuration === "2" || !getValues().typeDuration
                                    }
                                  />
                                )}
                              />
                            </Row>
                          </Form.Group>
                          {
                            watchTypeDuration === '1'
                              ?
                              <>
                                <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                  <Form.Label className="label-small">Duração</Form.Label>

                                  <Controller
                                    control={control}
                                    name="activityDuration"
                                    rules={{
                                      required: {
                                        value: true,
                                        message: "Por favor, informe o tipo de operação",
                                      },
                                    }}
                                    defaultValue={"01:00"}
                                    render={({ field }: any) => (
                                      <div className="endpoint">
                                        <Form.Select
                                          {...field}
                                          aria-invalid={
                                            errors?.activityDuration ? "true" : ""
                                          }
                                          as="select"
                                          variant="standard"
                                          margin="normal"
                                        >
                                          {activityDurationOptions.map((item: any) => {
                                            return (
                                              <>
                                                <option value={item} >{item}</option>
                                              </>
                                            )
                                          })}
                                        </Form.Select>
                                      </div>
                                    )}
                                  />
                                  <ErrorMessage
                                    errors={errors}
                                    name="activityDuration"
                                    render={({ message }) => (
                                      <small style={{ color: "red" }}>{message}</small>
                                    )}
                                  />
                                </Form.Group>
                              </>
                              :
                              <>
                                <Row>
                                  <Form.Group as={Col} md="5" controlId="" className="mt-4">
                                    <Form.Label className="label-small">Duração</Form.Label>

                                    <Controller
                                      control={control}
                                      name="activityDuration"
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Por favor, informe o tipo de operação",
                                        },
                                      }}
                                      defaultValue={"01:00"}
                                      render={({ field }: any) => (
                                        <div className="endpoint">
                                          <Form.Select
                                            {...field}
                                            aria-invalid={
                                              errors?.activityDuration ? "true" : ""
                                            }
                                            as="select"
                                            variant="standard"
                                            margin="normal"
                                          >
                                            {activityDurationOptions.map((item: any) => {
                                              return (
                                                <>
                                                  <option value={item} >{item}</option>
                                                </>
                                              )
                                            })}
                                          </Form.Select>
                                        </div>
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="activityDuration"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>{message}</small>
                                      )}
                                    />
                                  </Form.Group>
                                  <Col md='2' className="d-flex justify-content-center align-items-end">à</Col>
                                  <Form.Group as={Col} md="5" controlId="" className="mt-4">
                                    <Form.Label className="label-small">Duração</Form.Label>

                                    <Controller
                                      control={control}
                                      name="activityDuration"
                                      rules={{
                                        required: {
                                          value: true,
                                          message: "Por favor, informe o tipo de operação",
                                        },
                                      }}
                                      defaultValue={"01:00"}
                                      render={({ field }: any) => (
                                        <div className="endpoint">
                                          <Form.Select
                                            {...field}
                                            aria-invalid={
                                              errors?.activityDuration ? "true" : ""
                                            }
                                            as="select"
                                            variant="standard"
                                            margin="normal"
                                          >
                                            {activityDurationOptions.map((item: any) => {
                                              return (
                                                <>
                                                  <option value={item} >{item}</option>
                                                </>
                                              )
                                            })}
                                          </Form.Select>
                                        </div>
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="activityDuration"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>{message}</small>
                                      )}
                                    />
                                  </Form.Group>
                                </Row>
                              </>
                          }
                        </Col>
                      </>
                      :
                      <Form.Group as={Col} md="5" controlId="" className="mt-4">
                        <Form.Label className="label-small">Duração</Form.Label>

                        <Controller
                          control={control}
                          name="activityDuration"
                          rules={{
                            required: {
                              value: true,
                              message: "Por favor, informe o tipo de operação",
                            },
                          }}
                          defaultValue={"01:00"}
                          render={({ field }: any) => (
                            <div className="endpoint">
                              <Form.Select
                                {...field}
                                aria-invalid={
                                  errors?.activityDuration ? "true" : ""
                                }
                                as="select"
                                variant="standard"
                                margin="normal"
                              >
                                {activityDurationOptions.map((item: any) => {
                                  return (
                                    <>
                                      <option value={item} >{item}</option>
                                    </>
                                  )
                                })}
                              </Form.Select>
                            </div>
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name="activityDuration"
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </Form.Group>
                    } */}
                      </Row>

                      {/* <Row>
                        <Form.Group as={Col} md="5" controlId="" className="mt-4">
                          <Form.Label className="label-small">
                            Antecedência de compra
                          </Form.Label>

                          <Controller
                            control={control}
                            name="buyingAdvance"
                            defaultValue={
                              "10h"
                            }
                            rules={{
                              required: {
                                value: true,
                                message: "Por favor, informe o tipo de operação",
                              },
                            }}
                            render={({ field }: any) => (
                              <div className="endpoint">
                                <Form.Select
                                  {...field}
                                  aria-invalid={
                                    errors?.buyingAdvance ? "true" : ""
                                  }
                                  as="select"
                                  variant="standard"
                                  margin="normal"
                                >
                                  {buyingAdvanceOptions.map((item: any) => {
                                    return (
                                      <>
                                        <option value={item.includes("Minutos") ? `${item.split(" ")[0]}min` : `${item.split(" ")[0]}h`}>{item}</option>
                                      </>
                                    )
                                  })}

                                </Form.Select>
                              </div>
                            )}
                          />

                          <ErrorMessage
                            errors={errors}
                            name="buyingAdvance"
                            render={({ message }) => (
                              <small style={{ color: "red" }}>{message}</small>
                            )}
                          />
                        </Form.Group>
                      </Row> */}

                      {
                        isTicket &&
                        <Row>
                          <Form.Group as={Col} md="5" controlId="" className="mt-4">
                            <Form.Label className="label-small">Validade do ticket</Form.Label>

                            <Controller
                              control={control}
                              name="validationType"
                              rules={{ required: { value: true, message: "Por favor, informe esse campo", }, }}
                              render={({ field }: any) => (
                                <GetFetchListTicketValidationTypes propsField={field} propsErrors={errors} setValue={setValue} defaultValue={defaultTicketValidationType} />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="validationType"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>
                        </Row>
                      }

                      {
                        watchValidationType?.value === 4 &&
                        <Row>
                          <Form.Group as={Col} md="5" controlId="" className="mt-4">
                            <Form.Label className="label-small">Data da validade</Form.Label>

                            <Controller
                              control={control}
                              name="validationDefinedDate"
                              rules={{ required: { value: true, message: "Por favor, informe esse campo", }, }}
                              render={({ field }: any) => (
                                <InputGroup hasValidation className="" size="sm">
                                  <SingleCalendarWithDefaultValue
                                    {...field}
                                    date={validationDefinedDate}
                                    setValue={setValidationDefinedDate}
                                  />
                                </InputGroup>
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="validationDefinedDate"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>
                        </Row>
                      }

                      {
                        (watchValidationType?.value === 1 || watchValidationType?.value === 2) &&
                        <Row>
                          <Form.Group as={Col} md="3" controlId="" className="mt-4">
                            <Form.Label className="label-small">Período</Form.Label>

                            <Controller
                              control={control}
                              name="validationPeriod"
                              rules={{ required: { value: true, message: "Por favor, informe esse campo", }, }}
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  type="number"
                                  aria-invalid={
                                    errors?.validationPeriod ? "true" : ""
                                  }
                                  variant="standard"
                                  margin="normal"
                                  required
                                  autoComplete='off'
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="validationPeriod"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>
                          <Form.Group as={Col} md="2" controlId="" className="mt-4">
                            <Form.Label className="label-small">-</Form.Label>

                            <Controller
                              control={control}
                              name="validationPeriodType"
                              rules={{ required: { value: true, message: "Por favor, informe esse campo", }, }}
                              render={({ field }: any) => (
                                <div className={"endpoint"}>
                                  <Form.Select
                                    {...field}
                                    as="select"
                                    variant="standard"
                                    margin="normal"
                                    required
                                  >
                                    <option value="0">Minuto(s)</option>
                                    <option value="1">Hora(s)</option>
                                    <option value="2">Dia(s)</option>
                                  </Form.Select>
                                </div>
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="validationPeriodType"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>
                        </Row>
                      }

                      {/* {isTicket &&
                    <>
                      <Row>
                        <Col md='5'>
                          <Form.Group as={Col} md="12" controlId="" className="mt-4">
                            <Form.Label className="label-small">
                              Seu ticket pode ser admitido em mais de uma atração?
                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Controller
                                control={control}
                                name="isAttraction"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    id={"isAttraction-ckeck-yes"}
                                    value="yes"
                                    required
                                    //defaultChecked={tourObject?.isAttraction === 'yes'}
                                    label={"Sim"}
                                    checked={getValues().isAttraction === "yes"}
                                  />
                                )}
                              />

                              <Controller
                                control={control}
                                name="isAttraction"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    id={"isAttraction-ckeck-no"}
                                    value="no"
                                    required
                                    //defaultChecked={tourObject?.isAttraction === 'no' || true}
                                    label={"Não"}
                                    checked={
                                      getValues().isAttraction === "no" || !getValues().isAttraction
                                    }
                                  />
                                )}
                              />
                            </Row>
                          </Form.Group>
                          {
                            watchIsAttraction === 'yes' &&
                            <>
                              <Form.Group as={Col} md="12" controlId="" className="mt-4">
                                <Form.Label className="label-small d-flex justify-content-between">
                                  <span>Informe todas as atrações para as quais esse ticket tem acesso</span>
                                </Form.Label>

                                <Controller
                                  control={control}
                                  name="attractions"
                                  defaultValue={tourObject?.attractions || ""}
                                  rules={{ required: { value: false, message: "Por favor, informe esse campo", } }}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      aria-invalid={errors?.attractions ? "true" : ""}
                                      variant="standard"
                                      margin="normal"
                                      required
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="attractions"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                  )}
                                />
                              </Form.Group>
                            </>
                          }

                        </Col>
                      </Row>
                    </>
                  } */}
                    </Row>
                  </div>

                  <div className="pt-content-info">
                    <Row>
                      <div className="d-flex justify-content-between pt-3">
                        <h5 className="mb-0 pt-subtitle">Informações do estoque</h5>
                      </div>

                      <Form.Group as={Col} md="6" controlId="" className="mt-4">
                        <Form.Label className="label-small">
                          Tipo de venda
                        </Form.Label>

                        <Row className="mx-0 gap-2">
                          <Controller
                            control={control}
                            name="isFreeSale"
                            defaultValue={'1'}
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                className="col-5 mb-1"
                                type="radio"
                                id={"radio-1"}
                                value={2}
                                label={"Contabiliza estoque"}
                                checked={
                                  getValues().isFreeSale === "2" ||
                                  getValues().isFreeSale === 2
                                }
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name="isFreeSale"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                className="col-3 mb-1"
                                type="radio"
                                name="isFreeSale"
                                id={"radio-2"}
                                value={1}
                                defaultChecked={true}
                                label={"Venda livre"}
                                checked={
                                  getValues().isFreeSale === "1" ||
                                  getValues().isFreeSale === 1 ||
                                  !getValues().isFreeSale
                                }
                              />
                            )}
                          />
                        </Row>
                      </Form.Group>

                    </Row>
                  </div>

                  {!isTicket &&
                  <div className="pt-content-info">
                      <div className="d-flex justify-content-between pt-3">
                        <h5 className="mb-0 pt-subtitle">Cognitivo</h5>
                      </div>

                    <Row>
                      <Form.Group as={Col} md="6" controlId="" className="mt-4">
                        <Form.Label className="label-small">
                          Possui prioridade para Cognitivo?
                        </Form.Label>                      
                        <Row className="mx-0 gap-2">
                          <Controller
                            control={control}
                            name="isModalityPriorityCognitivo"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                className="col-5 mb-1"
                                type="radio"
                                id={"isModalityPriorityCognitivo-yes"}
                                value={1}
                                label={"Sim"}                                
                                checked={getValues().isModalityPriorityCognitivo === 1 || getValues().isModalityPriorityCognitivo === "1" || getValues().isModalityPriorityCognitivo == true}
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name="isModalityPriorityCognitivo"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                className="col-3 mb-1"
                                type="radio"                                
                                id={"isModalityPriorityCognitivo-no"}
                                value={2}
                                defaultChecked={!getValues().isModalityPriorityCognitivo}
                                label={"Não"}   
                                checked={getValues().isModalityPriorityCognitivo === 2 || getValues().isModalityPriorityCognitivo === "2" || getValues().isModalityPriorityCognitivo == false}
                              />
                            )}
                          />
                        </Row>
                      </Form.Group>
                    </Row>

                    <Row>
                      <Form.Group as={Col} md="6" controlId="" className="mt-4">
                        <Form.Label className="label-small">
                          Execução do modelo de IA da Cognitivo
                        </Form.Label>
                       
                        <Row className="mx-0 gap-2">
                          <Controller
                            control={control}
                            name="isModalityExecuteIACognitivo"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                className="col-5 mb-1"
                                type="radio"
                                id={"isModalityExecuteIACognitivo-yes"}
                                value={1}
                                label={"Sim"}                                
                                checked={getValues().isModalityExecuteIACognitivo === 1 || getValues().isModalityExecuteIACognitivo === "1" || getValues().isModalityExecuteIACognitivo == true}
                              />
                            )}
                          />
                          <Controller
                            control={control}
                            name="isModalityExecuteIACognitivo"
                            render={({ field }: any) => (
                              <Form.Check
                                {...field}
                                className="col-3 mb-1"
                                type="radio"                               
                                id={"isModalityExecuteIACognitivo-no"}
                                value={2}
                                defaultChecked={!getValues().isModalityExecuteIACognitivo}
                                label={"Não"}                                
                                checked={getValues().isModalityExecuteIACognitivo === 2 || getValues().isModalityExecuteIACognitivo === '2' || getValues().isModalityExecuteIACognitivo == false}
                              />
                            )}
                          />
                        </Row>
                      </Form.Group>
                    </Row>
                  </div>
}

                  <div className="pt-content-info">
                    <Row>
                      <div className="d-flex justify-content-between pt-3">
                        <h5 className="mb-0 pt-subtitle">Detalhes</h5>
                      </div>

                      {
                        isTicket
                          ?
                          <>
                            <Form.Group as={Col} md="6" controlId="" className="mt-4">
                              <Form.Label className="label-small">
                                Esse ticket oferece transporte?
                              </Form.Label>

                              <Row className="mx-0 gap-4">
                                <Controller
                                  control={control}
                                  name="offerTransportation"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-1 mb-1"
                                      type="radio"
                                      id={"offerTransportation-ckeck-yes"}
                                      value="yes"
                                      //defaultChecked={tourObject?.offerTransportation === 'yes'}
                                      label={"Sim"}
                                      checked={getValues().offerTransportation === "yes"}
                                    />
                                  )}
                                />

                                <Controller
                                  control={control}
                                  name="offerTransportation"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-1 mb-1"
                                      type="radio"
                                      id={"offerTransportation-ckeck-no"}
                                      value="no"
                                      //defaultChecked={tourObject?.offerTransportation === 'no' || true}
                                      label={"Não"}
                                      checked={
                                        getValues().offerTransportation === "no" || !getValues().offerTransportation
                                      }
                                    />
                                  )}
                                />
                              </Row>
                            </Form.Group>

                            {watchOfferTransportation === "yes" &&
                              <>
                                <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                  <Form.Label className="label-small">
                                    Tipo de embarque
                                  </Form.Label>

                                  <Row className="mx-0 gap-5">
                                    <Controller
                                      control={control}
                                      name="embarkingType"
                                      render={({ field }: any) => (
                                        <Form.Check
                                          {...field}
                                          className="col-1 mb-1"
                                          type="radio"
                                          id={"radio-1"}
                                          value={1}
                                          label={"Pickup"}
                                          checked={
                                            getValues().embarkingType === "1" ||
                                            getValues().embarkingType === 1
                                          }
                                        />
                                      )}
                                    />
                                    <Controller
                                      control={control}
                                      name="embarkingType"
                                      render={({ field }: any) => (
                                        <Form.Check
                                          {...field}
                                          className="col-5 mb-1"
                                          type="radio"
                                          name="embarkingType"
                                          id={"radio-2"}
                                          value={0}
                                          defaultChecked={true}
                                          label={"Ponto de Encontro"}
                                          checked={
                                            getValues().embarkingType === "0" ||
                                            getValues().embarkingType === 0 ||
                                            !getValues().embarkingType
                                          }
                                        />
                                      )}
                                    />
                                  </Row>
                                </Form.Group>

                                <Col md="6"></Col>

                                <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                  <Row>
                                    <Col md={6}>
                                      <Form.Label className="label-small">Local</Form.Label>

                                      {watchShipmentType === 1 ||
                                        watchShipmentType === "1" ? (
                                        <>
                                          <Controller
                                            control={control}
                                            name="pickupList"
                                            rules={{
                                              required: {
                                                value: true,
                                                message: "Por favor, informe esse campo",
                                              },
                                            }}
                                            render={({ field }: any) => (
                                              <>
                                                <GetTransfersPickup
                                                  propsField={field}
                                                  propsLabel={"label"}
                                                  propsErrors={errors}
                                                  getDataLikeObject={false}
                                                />
                                              </>
                                            )}
                                          />
                                          <ErrorMessage
                                            errors={errors}
                                            name="pickupList"
                                            render={({ message }) => (
                                              <small style={{ color: "red" }}>
                                                {message}
                                              </small>
                                            )}
                                          />
                                        </>
                                      ) : (
                                        <>
                                          <Controller
                                            control={control}
                                            name="meetingPoint"
                                            rules={{
                                              required: {
                                                value: true,
                                                message: "Por favor, informe esse campo",
                                              },
                                            }}
                                            defaultValue={
                                              tourSIG.tour.meetingPoint || ""
                                            }
                                            render={({ field }: any) => (
                                              <>
                                                <Form.Control
                                                  {...field}
                                                  type="text"
                                                  aria-invalid={errors?.tariffName ? "true" : ""}
                                                  variant="standard"
                                                  margin="normal"
                                                  required
                                                  autoComplete='off'
                                                />
                                                {/* <AutoComplete
                                                  {...field}
                                                  options={{
                                                    types: ["address"],
                                                    fields: ["name"],
                                                  }}
                                                  defaultValue={
                                                    tourSIG.tour.meetingPoint || ""
                                                  }
                                                  autoComplete="new-password"
                                                  placeholder=""
                                                  apiKey={process.env.REACT_APP_GOOGLE_API}
                                                  onPlaceSelected={(place: any) => {
                                                    setValue("meetingPoint", place.name);
                                                    // eslint-disable-next-line no-lone-blocks
                                                    {
                                                      geocodeByAddress(`${place.name}`)
                                                        .then((results) =>
                                                          getLatLng(results[0])
                                                        )
                                                        .then(({ lat, lng }) => { });
                                                    }
                                                  }}
                                                /> */}
                                                <ErrorMessage
                                                  errors={errors}
                                                  name="meetingPoint"
                                                  render={({ message }) => (
                                                    <small style={{ color: "red" }}>
                                                      {message}
                                                    </small>
                                                  )}
                                                />
                                              </>
                                            )}
                                          />
                                        </>
                                      )}
                                    </Col>
                                  </Row>
                                </Form.Group>
                              </>
                            }
                          </>
                          :
                          <>
                            {/* <Form.Group as={Col} md="6" controlId="" className="mt-4">
                              <Form.Label className="label-small">
                                Possui estoque?
                              </Form.Label>

                              <Row className="mx-0 gap-4">
                                <Controller
                                  control={control}
                                  name="stock"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-1 mb-1"
                                      type="radio"
                                      id={"stock-ckeck-yes"}
                                      value="yes"
                                      //defaultChecked={tourObject?.stock === 'yes'}
                                      label={"Sim"}
                                      checked={getValues().stock === "yes"}
                                    />
                                  )}
                                />

                                <Controller
                                  control={control}
                                  name="stock"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-1 mb-1"
                                      type="radio"
                                      id={"stock-ckeck-no"}
                                      value="no"
                                      //defaultChecked={tourObject?.stock === 'no' || true}
                                      label={"Não"}
                                      checked={
                                        getValues().stock === "no" || !getValues().stock
                                      }
                                    />
                                  )}
                                />
                              </Row>
                            </Form.Group> */}

                            <Form.Group as={Col} md="6" controlId="" className="mt-4">
                              <Form.Label className="label-small">
                                Tipo de embarque
                              </Form.Label>

                              <Row className="mx-0 gap-2">
                                <Controller
                                  control={control}
                                  name="embarkingType"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-5 mb-1"
                                      type="radio"
                                      id={"radio-1"}
                                      value={1}
                                      label={"Pickup"}
                                      checked={
                                        getValues().embarkingType === "1" ||
                                        getValues().embarkingType === 1
                                      }
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="embarkingType"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-4 mb-1"
                                      type="radio"
                                      name="embarkingType"
                                      id={"radio-2"}
                                      value={0}
                                      defaultChecked={true}
                                      label={"Ponto de Encontro"}
                                      checked={
                                        getValues().embarkingType === "0" ||
                                        getValues().embarkingType === 0 ||
                                        !getValues().embarkingType
                                      }
                                    />
                                  )}
                                />
                              </Row>
                            </Form.Group>

                            <Col md={6}></Col>
                            {/* <Form.Group as={Col} md="6" controlId="" className="mt-4">
                              {watchStock === "yes" && (
                                <>
                                  <Row>
                                    <Col md={6}>
                                      <Form.Label className="label-small">
                                        Quantidade
                                      </Form.Label>

                                      <Controller
                                        control={control}
                                        name="dailyStockTotal"
                                        rules={{
                                          required: {
                                            value: true,
                                            message:
                                              "Por favor, informe o tipo de operação",
                                          },
                                        }}
                                        render={({ field }: any) => (
                                          <Form.Control
                                            {...field}
                                            type="number"
                                            aria-invalid={
                                              errors?.dailyStockTotal ? "true" : ""
                                            }
                                            variant="standard"
                                            margin="normal"
                                            required
                                            autoComplete='off'
                                          />
                                        )}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name="dailyStockTotal"
                                        render={({ message }) => (
                                          <small style={{ color: "red" }}>
                                            {message}
                                          </small>
                                        )}
                                      />
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Form.Group> */}

                            <Form.Group as={Col} md="6" controlId="" className="mt-4">
                              <Row>
                                <Col md={6}>
                                  <Form.Label className="label-small">Local</Form.Label>

                                  {watchShipmentType === 1 ||
                                    watchShipmentType === "1" ? (
                                    <>
                                      <Controller
                                        control={control}
                                        name="pickupList"
                                        rules={{
                                          required: {
                                            value: true,
                                            message: "Por favor, informe esse campo",
                                          },
                                        }}
                                        render={({ field }: any) => (
                                          <>
                                            <GetTransfersPickup
                                              propsField={field}
                                              propsLabel={"label"}
                                              propsErrors={errors}
                                              getDataLikeObject={false}
                                            />
                                          </>
                                        )}
                                      />
                                      <ErrorMessage
                                        errors={errors}
                                        name="pickupList"
                                        render={({ message }) => (
                                          <small style={{ color: "red" }}>
                                            {message}
                                          </small>
                                        )}
                                      />
                                    </>
                                  ) : (
                                    <>
                                      <Controller
                                        control={control}
                                        name="meetingPoint"
                                        rules={{
                                          required: {
                                            value: true,
                                            message: "Por favor, informe esse campo",
                                          },
                                        }}
                                        defaultValue={
                                          tourSIG.tour.meetingPoint || ""
                                        }
                                        render={({ field }: any) => (
                                          <>
                                            <Form.Control
                                              {...field}
                                              type="text"
                                              aria-invalid={errors?.tariffName ? "true" : ""}
                                              variant="standard"
                                              margin="normal"
                                              required
                                              autoComplete='off'
                                            />
                                            {/* <AutoComplete
                                              {...field}
                                              options={{
                                                types: ["address"],
                                                fields: ["name"],
                                              }}
                                              defaultValue={
                                                tourSIG.tour.meetingPoint || ""
                                              }
                                              autoComplete="new-password"
                                              placeholder=""
                                              apiKey={process.env.REACT_APP_GOOGLE_API}
                                              onPlaceSelected={(place: any) => {
                                                setValue("meetingPoint", place.name);
                                                // eslint-disable-next-line no-lone-blocks
                                                {
                                                  geocodeByAddress(`${place.name}`)
                                                    .then((results) =>
                                                      getLatLng(results[0])
                                                    )
                                                    .then(({ lat, lng }) => { });
                                                }
                                              }}
                                            /> */}
                                            <ErrorMessage
                                              errors={errors}
                                              name="meetingPoint"
                                              render={({ message }) => (
                                                <small style={{ color: "red" }}>
                                                  {message}
                                                </small>
                                              )}
                                            />
                                          </>
                                        )}
                                      />
                                    </>
                                  )}
                                </Col>
                              </Row>
                            </Form.Group>
                          </>
                      }
                    </Row>
                  </div>

                  <div className="pt-content-info">
                    <Row>
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-0 pt-subtitle">Range de Comissões</h5>
                      </div>
                      <Row>
                        <Form.Group as={Col} md="3" controlId="" className="mt-4">
                          <Form.Label className="label-small">
                            Badge de comissão
                          </Form.Label>

                          <Controller
                            control={control}
                            name="idComissionRange"
                            defaultValue={'4'}
                            rules={{ required: { value: true, message: "Por favor, informe o tipo de operação" } }}
                            render={({ field }: any) => (
                              <GetAllBadges propsField={field} />
                            )}
                          />
                          <ErrorMessage
                            errors={errors}
                            name="idComissionRange"
                            render={({ message }) => (
                              <small style={{ color: "red" }}>{message}</small>
                            )}
                          />
                        </Form.Group>
                      </Row>
                    </Row>
                  </div>

                  <div className="pt-content-info">
                    {tourSIG.tour?.sellingType === 2 ? (
                      <>
                        <Row>
                          <div className="d-flex justify-content-between">
                            <h5 className="mb-0 pt-subtitle" id="rangeDeIdades">Range de Idades</h5>
                            {tourObject.isTranslate === 1 && (
                              <>
                                <div
                                  className="btn-edit ml-2"
                                  style={{ position: "unset" }}
                                  onClick={() =>
                                    setAllowEditRange(!allowEditRange)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={["fal", "edit"]}
                                    size="1x"
                                    className="mr-2"
                                  />
                                  Editar
                                </div>
                              </>
                            )}
                          </div>

                          <Form.Group
                            controlId=""
                            className="d-flex mt-4 col-md-auto"
                          >
                            <Controller
                              control={control}
                              name="labelAdultEnabled"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, informe o tipo de operação",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check"
                                  style={{ marginTop: "35px" }}
                                  aria-invalid={
                                    errors?.modalityNameES ? "true" : ""
                                  }
                                  variant="standard"
                                  checked={true}
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="3"
                            controlId=""
                            className="mt-4"
                            style={{ flex: '1' }}
                          >
                            <Form.Label className="label-small">
                              Labels
                            </Form.Label>

                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelAdultBR"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelAdultBR || "Adulto"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "labelAdult");
                                      }}
                                      aria-invalid={
                                        errors?.labelAdultBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelAdultBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelAdultEN"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelAdultEN || "Adult"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelAdultEN ? "true" : ""
                                      }
                                      /* disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditLabels === false
                                      } */
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelAdultEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelAdultES"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelAdultES || "Adulto"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelAdultES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelAdultES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId=""
                            className="mt-4"
                          >
                            <Form.Label className="label-small">
                              Condição do tour
                            </Form.Label>

                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextAdultBR"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  defaultValue={"Entre 11 a 100 anos"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "tariffAgeTextAdult");
                                      }}
                                      aria-invalid={
                                        errors?.tariffAgeTextAdultBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextAdultBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextAdultEN"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  defaultValue={"Between 11 and 100 years old"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextAdultEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextAdultEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextAdultES"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  defaultValue={"Entre 11 y 100 años"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextAdultES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextAdultES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            controlId=""
                            className="mt-4 col-md-auto"
                            style={{ maxWidth: '90px' }}
                          >
                            <Form.Label className="label-small">
                              Incrementos
                            </Form.Label>

                            <Controller
                              control={control}
                              name="sellingIncrementAdult"
                              rules={{
                                required: {
                                  value: true,
                                  message:
                                    "Por favor, informe o tipo de operação",
                                },
                              }}
                              defaultValue={1}
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  type="number"
                                  aria-invalid={
                                    errors?.sellingIncrementAdult ? "true" : ""
                                  }
                                  variant="standard"
                                  margin="normal"
                                  min={1}
                                  required
                                  autoComplete='off'
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sellingIncrementAdult"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="1"
                            className="mt-4"
                            style={{ position: 'relative' }}
                          >
                            <Form.Label className="label-small" style={{ position: 'absolute', top: '-15px' }}>
                              Contabiliza estoque?
                            </Form.Label>
                            <Controller
                              control={control}
                              name="flagStockUseAdult"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, esse campo",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check"
                                  style={{ marginTop: "35px" }}
                                  variant="standard"
                                  checked={getValues().flagStockUseAdult === true}
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group
                            controlId=""
                            className="d-flex mt-4 col-md-auto"
                          >
                            <Controller
                              control={control}
                              name="labelChildEnabled"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, informe o tipo de operação",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check my-auto"
                                  aria-invalid={
                                    errors?.labelChildEnabled ? "true" : ""
                                  }
                                  variant="standard"
                                  margin="normal"
                                  checked={getValues().labelChildEnabled === true}
                                  required
                                />
                              )}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="3"
                            controlId=""
                            className="mt-4"
                            style={{ flex: '1' }}
                          >
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelChildBR"
                                  rules={{
                                    required: {
                                      value: false,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelChildBR || "Criança"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "labelChild");
                                      }}
                                      aria-invalid={
                                        errors?.labelChildBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelChildBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelChildEN"
                                  rules={{
                                    required: {
                                      value: false,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelChildEN || "Child"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelChildEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelChildEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelChildES"
                                  rules={{
                                    required: {
                                      value: false,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelChildES || "Niño"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelChildES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelChildES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId=""
                            className="mt-4"
                          >
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextChildBR"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={"Entre 5 a 10 anos"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "tariffAgeTextChild");
                                      }}
                                      aria-invalid={
                                        errors?.tariffAgeTextChildBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextChildBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextChildEN"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={"Between 5 to 10 years"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextChildEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextChildEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextChildES"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={"Entre 5 a 10 años"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextChildES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextChildES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            controlId=""
                            className="mt-4 col-md-auto"
                            style={{ maxWidth: '90px' }}
                          >
                            <Controller
                              control={control}
                              name="sellingIncrementChild"
                              rules={{
                                required: {
                                  value: true,
                                  message:
                                    "Por favor, informe esse campo",
                                },
                              }}
                              defaultValue={1}
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  type="number"
                                  aria-invalid={
                                    errors?.sellingIncrementChild ? "true" : ""
                                  }
                                  variant="standard"
                                  min={1}
                                  margin="normal"
                                  required
                                  autoComplete='off'
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sellingIncrementChild"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>

                          <Form.Group as={Col} md="1" controlId="" className="d-flex mt-4 align-items-center">
                            <Controller
                              control={control}
                              name="flagStockUseChild"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, esse campo",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check"
                                  variant="standard"
                                  checked={getValues().flagStockUseChild === true}
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </Form.Group>
                        </Row>

                        <Row>
                          <Form.Group
                            controlId=""
                            className="d-flex mt-4 col-md-auto"
                          >
                            <Controller
                              control={control}
                              name="labelInfantEnabled"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, informe esse campo",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check my-auto"
                                  aria-invalid={
                                    errors?.labelInfantEnabled ? "true" : ""
                                  }
                                  variant="standard"
                                  margin="normal"
                                  checked={getValues().labelInfantEnabled === true}
                                  required
                                />
                              )}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="3"
                            controlId=""
                            className="mt-4"
                            style={{ flex: '1' }}
                          >
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelInfantBR"
                                  rules={{
                                    required: {
                                      value: false,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelInfantBR || "Infante"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "labelInfant");
                                      }}
                                      aria-invalid={
                                        errors?.labelInfantBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelInfantBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelInfantEN"
                                  rules={{
                                    required: {
                                      value: false,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelInfantEN || "Infant"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelInfantEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelInfantEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelInfantES"
                                  rules={{
                                    required: {
                                      value: false,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelInfantES || "Infante"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelInfantES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelInfantES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId=""
                            className="mt-4"
                          >
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextInfantBR"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={"Entre 0 a 4 anos"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "tariffAgeTextInfant");
                                      }}
                                      aria-invalid={
                                        errors?.tariffAgeTextInfantBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextInfantBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextInfantEN"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={"Between 0 to 4 years"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextInfantEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextInfantEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextInfantES"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={"Entre 0 a 4 años"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextInfantES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextInfantES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            controlId=""
                            className="mt-4 col-md-auto"
                            style={{ maxWidth: '90px' }}
                          >
                            <Controller
                              control={control}
                              name="sellingIncrementInfant"
                              rules={{
                                required: {
                                  value: true,
                                  message:
                                    "Por favor, informe o tipo de operação",
                                },
                              }}
                              defaultValue={1}
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  type="number"
                                  aria-invalid={
                                    errors?.sellingIncrementInfant ? "true" : ""
                                  }
                                  variant="standard"
                                  min={1}
                                  margin="normal"
                                  required
                                  autoComplete='off'
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sellingIncrementInfant"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="1"
                            className="mt-4"
                            style={{ position: 'relative' }}
                          >
                            <Controller
                              control={control}
                              name="flagStockUseInfant"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, esse campo",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check"
                                  variant="standard"
                                  /* defaultChecked={false} */
                                  checked={getValues().flagStockUseInfant === true}
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </Form.Group>
                        </Row>

                        {isTicket &&
                          <>
                            <Row>
                              <Form.Group
                                controlId=""
                                className="d-flex mt-4 col-md-auto"
                              >
                                <Controller
                                  control={control}
                                  name="labelEldersEnabled"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="switch"
                                      className="pt-age-check my-auto"
                                      aria-invalid={
                                        errors?.labelEldersEnabled ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      checked={getValues().labelEldersEnabled === true}
                                      required
                                    />
                                  )}
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="3"
                                controlId=""
                                className="mt-4"
                                style={{ flex: '1' }}
                              >
                                <Row>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="labelEldersBR"
                                      rules={{
                                        required: {
                                          value: false,
                                          message: "Por favor, informe um label",
                                        },
                                        minLength: {
                                          value: 3,
                                          message:
                                            "Por favor, insira ao menos 3 caracteres",
                                        },
                                        maxLength: {
                                          value: 30,
                                          message:
                                            "Por favor, insira no máximo 30 caracteres",
                                        },
                                      }}
                                      defaultValue={tourObject?.labelEldersBR || "Idoso"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          onBlur={(e: any) => {
                                            allowEditRange === false &&
                                              handleBlurModalityName(e, "labelElders");
                                          }}
                                          aria-invalid={
                                            errors?.labelEldersBR ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="labelEldersBR"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="labelEldersEN"
                                      rules={{
                                        required: {
                                          value: false,
                                          message: "Por favor, informe um label",
                                        },
                                        minLength: {
                                          value: 3,
                                          message:
                                            "Por favor, insira ao menos 3 caracteres",
                                        },
                                        maxLength: {
                                          value: 30,
                                          message:
                                            "Por favor, insira no máximo 30 caracteres",
                                        },
                                      }}
                                      defaultValue={tourObject?.labelEldersEN || "Elder"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.labelEldersEN ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="labelEldersEN"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="labelEldersES"
                                      rules={{
                                        required: {
                                          value: false,
                                          message: "Por favor, informe um label",
                                        },
                                        minLength: {
                                          value: 3,
                                          message:
                                            "Por favor, insira ao menos 3 caracteres",
                                        },
                                        maxLength: {
                                          value: 30,
                                          message:
                                            "Por favor, insira no máximo 30 caracteres",
                                        },
                                      }}
                                      defaultValue={tourObject?.labelEldersES || "Anciano"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.labelEldersES ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="labelEldersES"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="6"
                                controlId=""
                                className="mt-4"
                              >
                                <Row>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="tariffAgeTextElderBR"
                                      rules={{
                                        required: {
                                          value: false,
                                          message:
                                            "Por favor, informe esse campo",
                                        },
                                      }}
                                      defaultValue={"À partir dos 60 anos"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          onBlur={(e: any) => {
                                            allowEditRange === false &&
                                              handleBlurModalityName(e, "tariffAgeTextElder");
                                          }}
                                          aria-invalid={
                                            errors?.tariffAgeTextElderBR ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="tariffAgeTextElderBR"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="tariffAgeTextElderEN"
                                      rules={{
                                        required: {
                                          value: false,
                                          message:
                                            "Por favor, informe esse campo",
                                        },
                                      }}
                                      defaultValue={"From 60 years old"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.tariffAgeTextElderEN ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="tariffAgeTextElderEN"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="tariffAgeTextElderES"
                                      rules={{
                                        required: {
                                          value: false,
                                          message:
                                            "Por favor, informe esse campo",
                                        },
                                      }}
                                      defaultValue={"A partir de 60 años"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.tariffAgeTextElderES ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="tariffAgeTextElderES"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>

                              <Form.Group
                                controlId=""
                                className="mt-4 col-md-auto"
                                style={{ maxWidth: '90px' }}
                              >
                                <Controller
                                  control={control}
                                  name="sellingIncrementElders"
                                  rules={{
                                    required: {
                                      value: true,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={1}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="number"
                                      aria-invalid={
                                        errors?.sellingIncrementElders ? "true" : ""
                                      }
                                      variant="standard"
                                      min={1}
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="sellingIncrementElders"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                  )}
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="1"
                                className="mt-4"
                                style={{ position: 'relative' }}
                              >
                                <Controller
                                  control={control}
                                  name="flagStockUseElders"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, esse campo",
                                    },
                                  }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="switch"
                                      className="pt-age-check"
                                      variant="standard"
                                      checked={getValues().flagStockUseElders === true}
                                      margin="normal"
                                      required
                                    />
                                  )}
                                />
                              </Form.Group>
                            </Row>

                            <Row>
                              <Form.Group
                                controlId=""
                                className="d-flex mt-4 col-md-auto"
                              >
                                <Controller
                                  control={control}
                                  name="labelStudentEnabled"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="switch"
                                      className="pt-age-check my-auto"
                                      aria-invalid={
                                        errors?.labelStudentEnabled ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      checked={getValues().labelStudentEnabled === true}
                                      required
                                    />
                                  )}
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="3"
                                controlId=""
                                className="mt-4"
                                style={{ flex: '1' }}
                              >
                                <Row>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="labelStudentBR"
                                      rules={{
                                        required: {
                                          value: false,
                                          message: "Por favor, informe um label",
                                        },
                                        minLength: {
                                          value: 3,
                                          message:
                                            "Por favor, insira ao menos 3 caracteres",
                                        },
                                        maxLength: {
                                          value: 30,
                                          message:
                                            "Por favor, insira no máximo 30 caracteres",
                                        },
                                      }}
                                      defaultValue={tourObject?.labelStudentBR || "Estudante"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          onBlur={(e: any) => {
                                            allowEditRange === false &&
                                              handleBlurModalityName(e, "labelStudent");
                                          }}
                                          aria-invalid={
                                            errors?.labelStudentBR ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="labelStudentBR"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="labelStudentEN"
                                      rules={{
                                        required: {
                                          value: false,
                                          message: "Por favor, informe um label",
                                        },
                                        minLength: {
                                          value: 3,
                                          message:
                                            "Por favor, insira ao menos 3 caracteres",
                                        },
                                        maxLength: {
                                          value: 30,
                                          message:
                                            "Por favor, insira no máximo 30 caracteres",
                                        },
                                      }}
                                      defaultValue={tourObject?.labelStudentEN || "Student"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.labelStudentEN ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="labelStudentEN"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="labelStudentES"
                                      rules={{
                                        required: {
                                          value: false,
                                          message: "Por favor, informe um label",
                                        },
                                        minLength: {
                                          value: 3,
                                          message:
                                            "Por favor, insira ao menos 3 caracteres",
                                        },
                                        maxLength: {
                                          value: 30,
                                          message:
                                            "Por favor, insira no máximo 30 caracteres",
                                        },
                                      }}
                                      defaultValue={tourObject?.labelStudentES || "Alumno"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.labelStudentES ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="labelStudentES"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="6"
                                controlId=""
                                className="mt-4"
                              >
                                <Row>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="tariffAgeTextStudentBR"
                                      rules={{
                                        required: {
                                          value: false,
                                          message:
                                            "Por favor, informe esse campo",
                                        },
                                      }}
                                      defaultValue={"Comprovante necessário"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          onBlur={(e: any) => {
                                            allowEditRange === false &&
                                              handleBlurModalityName(e, "tariffAgeTextStudent");
                                          }}
                                          aria-invalid={
                                            errors?.tariffAgeTextStudentBR ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="tariffAgeTextStudentBR"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="tariffAgeTextStudentEN"
                                      rules={{
                                        required: {
                                          value: false,
                                          message:
                                            "Por favor, informe esse campo",
                                        },
                                      }}
                                      defaultValue={"Proof required"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.tariffAgeTextStudentEN ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="tariffAgeTextStudentEN"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                  <Col md={4}>
                                    <Controller
                                      control={control}
                                      name="tariffAgeTextStudentES"
                                      rules={{
                                        required: {
                                          value: false,
                                          message:
                                            "Por favor, informe esse campo",
                                        },
                                      }}
                                      defaultValue={"Se requiere prueba"}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="text"
                                          disabled={
                                            tourObject.isTranslate === 1 &&
                                            allowEditRange === false
                                          }
                                          aria-invalid={
                                            errors?.tariffAgeTextStudentES ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="tariffAgeTextStudentES"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </Form.Group>

                              <Form.Group
                                controlId=""
                                className="mt-4 col-md-auto"
                                style={{ maxWidth: '90px' }}
                              >
                                <Controller
                                  control={control}
                                  name="sellingIncrementStudent"
                                  rules={{
                                    required: {
                                      value: true,
                                      message:
                                        "Por favor, informe esse campo",
                                    },
                                  }}
                                  defaultValue={1}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="number"
                                      aria-invalid={
                                        errors?.sellingIncrementStudent ? "true" : ""
                                      }
                                      variant="standard"
                                      min={1}
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="sellingIncrementStudent"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                  )}
                                />
                              </Form.Group>

                              <Form.Group
                                as={Col}
                                md="1"
                                className="mt-4"
                                style={{ position: 'relative' }}
                              >
                                <Controller
                                  control={control}
                                  name="flagStockUseStudent"
                                  rules={{
                                    required: {
                                      value: false,
                                      message:
                                        "Por favor, esse campo",
                                    },
                                  }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="switch"
                                      className="pt-age-check"
                                      variant="standard"
                                      checked={getValues().flagStockUseStudent === true}
                                      margin="normal"
                                      required
                                    />
                                  )}
                                />
                              </Form.Group>
                            </Row>
                          </>
                        }
                      </>
                    ) : (
                      <>
                        <Row>
                          <div className="d-flex justify-content-between">
                            <h5 className="mb-0 pt-subtitle">Range de Idades</h5>
                            {tourObject.isTranslate === 1 && (
                              <>
                                <div
                                  className="btn-edit ml-2"
                                  style={{ position: "unset" }}
                                  onClick={() =>
                                    setAllowEditRange(!allowEditRange)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={["fal", "edit"]}
                                    size="1x"
                                    className="mr-2"
                                  />
                                  Editar
                                </div>
                              </>
                            )}
                          </div>
                          <Form.Group
                            controlId=""
                            className="d-flex mt-4 col-md-auto"
                          >
                            <Controller
                              control={control}
                              name="labelPrivateEnabled"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, informe o tipo de operação",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check my-auto"
                                  aria-invalid={
                                    errors?.labelInfantEnabled ? "true" : ""
                                  }
                                  variant="standard"
                                  margin="normal"
                                  //checked={true}
                                  checked={getValues().labelPrivateEnabled === true}
                                  required
                                />
                              )}
                            />
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="3"
                            controlId=""
                            className="mt-4"
                            style={{ flex: '1' }}
                          >
                            <Form.Label className="label-small">
                              Labels
                            </Form.Label>
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelPrivateBR"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelPrivateBR || "Privativo"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "labelPrivate");
                                      }}
                                      aria-invalid={
                                        errors?.labelPrivateBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelPrivateBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelPrivateEN"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelPrivateEN || "Private"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelPrivateEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelPrivateEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="labelPrivateES"
                                  rules={{
                                    required: {
                                      value: true,
                                      message: "Por favor, informe um label",
                                    },
                                    minLength: {
                                      value: 3,
                                      message:
                                        "Por favor, insira ao menos 3 caracteres",
                                    },
                                    maxLength: {
                                      value: 30,
                                      message:
                                        "Por favor, insira no máximo 30 caracteres",
                                    },
                                  }}
                                  defaultValue={tourObject?.labelPrivateES || "Privado"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.labelPrivateES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="labelPrivateES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="6"
                            controlId=""
                            className="mt-4"
                          >
                            <Form.Label className="label-small">
                              Condição do tour
                            </Form.Label>
                            <Row>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextGlobalBR"
                                  rules={{
                                    required: {
                                      value: true,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  defaultValue={"Entre 0 a 100 anos"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      onBlur={(e: any) => {
                                        allowEditRange === false &&
                                          handleBlurModalityName(e, "tariffAgeTextGlobal");
                                      }}
                                      aria-invalid={
                                        errors?.tariffAgeTextGlobalBR ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextGlobalBR"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextGlobalEN"
                                  rules={{
                                    required: {
                                      value: true,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  defaultValue={"Between 0 and 100 years"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextGlobalEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextGlobalEN"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                              <Col md={4}>
                                <Controller
                                  control={control}
                                  name="tariffAgeTextGlobalES"
                                  rules={{
                                    required: {
                                      value: true,
                                      message:
                                        "Por favor, informe o tipo de operação",
                                    },
                                  }}
                                  defaultValue={"Entre 0 y 100 años"}
                                  render={({ field }: any) => (
                                    <Form.Control
                                      {...field}
                                      type="text"
                                      disabled={
                                        tourObject.isTranslate === 1 &&
                                        allowEditRange === false
                                      }
                                      aria-invalid={
                                        errors?.tariffAgeTextGlobalES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      autoComplete='off'
                                    />
                                  )}
                                />
                                <ErrorMessage
                                  errors={errors}
                                  name="tariffAgeTextGlobalES"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>
                                      {message}
                                    </small>
                                  )}
                                />
                              </Col>
                            </Row>
                          </Form.Group>

                          <Form.Group
                            as={Col}
                            md="1"
                            controlId=""
                            className="mt-4"
                          >
                            <Form.Label className="label-small">
                              Incrementos
                            </Form.Label>
                            <Controller
                              control={control}
                              name="sellingIncrementPrivate"
                              rules={{
                                required: {
                                  value: true,
                                  message:
                                    "Por favor, informe o tipo de operação",
                                },
                              }}
                              defaultValue={1}
                              render={({ field }: any) => (
                                <Form.Control
                                  {...field}
                                  type="number"
                                  aria-invalid={
                                    errors?.sellingIncrementPrivate ? "true" : ""
                                  }
                                  variant="standard"
                                  margin="normal"
                                  min={1}
                                  required
                                  autoComplete='off'
                                />
                              )}
                            />
                            <ErrorMessage
                              errors={errors}
                              name="sellingIncrementPrivate"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>{message}</small>
                              )}
                            />
                          </Form.Group>

                          <Form.Group as={Col} md="1" controlId="" className="mt-4" style={{ position: 'relative' }}>
                            <Form.Label className="label-small" style={{ position: 'absolute', top: '-15px' }}>
                              Contabiliza estoque?
                            </Form.Label>
                            <Controller
                              control={control}
                              name="flagStockUsePrivate"
                              rules={{
                                required: {
                                  value: false,
                                  message:
                                    "Por favor, esse campo",
                                },
                              }}
                              render={({ field }: any) => (
                                <Form.Check
                                  {...field}
                                  type="switch"
                                  className="pt-age-check"
                                  style={{ marginTop: "35px" }}
                                  variant="standard"
                                  checked={getValues().flagStockUsePrivate === true}
                                  margin="normal"
                                  required
                                />
                              )}
                            />
                          </Form.Group>
                        </Row>
                      </>
                    )}
                  </div>

                  <div className="pt-content-info">
                    <Row>

                      <Col md={6}>
                        <Row>
                          <div className="d-flex justify-content-between pt-3">
                            <h5 className="mb-0 pt-subtitle">Guias de Idiomas</h5>
                          </div>

                          {/* <Form.Group as={Col} md="6" controlId="" className="my-4">
                    <Form.Label className="label-small">
                      Você irá oferecer guia de turismo, audioguia ou escrito?
                    </Form.Label>

                    <Row className="mx-0 gap-4">
                      <Controller
                        control={control}
                        name="isTurism"
                        render={({ field }: any) => (
                          <Form.Check
                            {...field}
                            className="col-1 mb-1"
                            type="radio"
                            name="isTurism"
                            id={"isTurism-yes"}
                            value={true}
                            required
                            label={"Sim"}
                            checked={getValues().isTurism === "true"}
                          />
                        )}
                      />
                      <Controller
                        control={control}
                        name="isTurism"
                        render={({ field }: any) => (
                          <Form.Check
                            {...field}
                            className="col-1 mb-1"
                            type="radio"
                            name="isTurism"
                            id={"isTurism-no"}
                            value={false}
                            required
                            //defaultChecked={true}
                            label={"Não"}
                            checked={
                              getValues().isTurism === "false" ||
                              !getValues().isTurism
                            }
                          />
                        )}
                      />
                    </Row>
                  </Form.Group> */}


                          <Row>
                            <Col md={4} className="mt-4">
                              <Form.Label className="label-small">
                                Idiomas
                              </Form.Label>

                              <div>
                                <p className="my-4">Português</p>
                              </div>

                              <div>
                                <p className="mb-4">Inglês</p>
                              </div>

                              <div>
                                <p className="mb-0">Espanhol</p>
                              </div>
                            </Col>
                            <Col md={2} className="mt-4 text-center">
                              <Form.Label className="label-small">
                                Presencial
                              </Form.Label>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="tourGuidePT"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.tourGuidePT ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().tourGuidePT}
                                    />
                                  )}
                                />
                              </div>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="tourGuideEN"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.tourGuideEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().tourGuideEN}
                                    />
                                  )}
                                />
                              </div>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="tourGuideES"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.tourGuideES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().tourGuideES}
                                    />
                                  )}
                                />
                              </div>
                            </Col>
                            <Col md={2} className="mt-4 text-center">
                              <Form.Label className="label-small">Audio</Form.Label>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="audioGuidePT"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.audioGuidePT ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().audioGuidePT}
                                    />
                                  )}
                                />
                              </div>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="audioGuideEN"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.audioGuideEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().audioGuideEN}
                                    />
                                  )}
                                />
                              </div>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="audioGuideES"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.audioGuideES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().audioGuideES}
                                    />
                                  )}
                                />
                              </div>
                            </Col>

                            <Col md={2} className="mt-4 text-center">
                              <Form.Label className="label-small">
                                Impresso
                              </Form.Label>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="writingGuidePT"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.writingGuidePT ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().writingGuidePT}
                                    />
                                  )}
                                />
                              </div>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="writingGuideEN"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.writingGuideEN ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().writingGuideEN}
                                    />
                                  )}
                                />
                              </div>

                              <div className="mt-4">
                                <Controller
                                  control={control}
                                  name="writingGuideES"
                                  //rules={{ required: { value: true, message: 'Por favor, informe o tipo de operação' } }}
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      type="checkbox"
                                      className="m-auto"
                                      aria-invalid={
                                        errors?.writingGuideES ? "true" : ""
                                      }
                                      variant="standard"
                                      margin="normal"
                                      required
                                      checked={getValues().writingGuideES}
                                    />
                                  )}
                                />
                              </div>
                            </Col>
                          </Row>
                        </Row>
                      </Col>

                      {
                        isTicket &&
                        <Col md={6}>
                          <div className="d-flex justify-content-between pt-3">
                            <h5 className="mb-0 pt-subtitle">Acessibilidade</h5>
                          </div>
                          <Row>
                            <Form.Group as={Col} md="6" controlId="" className="my-4">
                              <Form.Label className="label-small">
                                Possui acessibilidade ?
                              </Form.Label>

                              <Row className="mx-0 gap-4">
                                <Controller
                                  control={control}
                                  name="wheelchairAccessibility"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-1 mb-1"
                                      type="radio"
                                      name="wheelchairAccessibility"
                                      id={"wheelchairAccessibility-yes"}
                                      value={1}
                                      required
                                      label={"Sim"}
                                      checked={getValues().wheelchairAccessibility === "1"}
                                    />
                                  )}
                                />
                                <Controller
                                  control={control}
                                  name="wheelchairAccessibility"
                                  render={({ field }: any) => (
                                    <Form.Check
                                      {...field}
                                      className="col-1 mb-1"
                                      type="radio"
                                      name="wheelchairAccessibility"
                                      id={"wheelchairAccessibility-no"}
                                      value={2}
                                      required
                                      //defaultChecked={true}
                                      label={"Não"}
                                      checked={
                                        getValues().wheelchairAccessibility === "2" ||
                                        !getValues().wheelchairAccessibility
                                      }
                                    />
                                  )}
                                />
                              </Row>
                            </Form.Group>
                          </Row>

                        </Col>
                      }
                    </Row>
                  </div>

                  <div className="pt-content-info">
                    <div>
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-2 pt-subtitle">Inclui</h5>
                        {tourObject.isTranslate === 1 && (
                          <>
                            <div
                              className="btn-edit ml-2"
                              style={{ position: "unset" }}
                              onClick={() =>
                                setAllowEditIncludes(!allowEditIncludes)
                              }
                            >
                              <FontAwesomeIcon
                                icon={["fal", "edit"]}
                                size="1x"
                                className="mr-2"
                              />
                              Editar
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <DraggableProductImputs
                      items={includesArray}
                      setItems={setIncludesArray}
                      tourObject={tourObject}
                      allowEditItems={allowEditIncludes}
                      portuguese={"itemsIncludedPT"}
                      english={"itemsIncludedEN"}
                      spanish={"itemsIncludedES"}
                      address={"itemsIncluded"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      minLength={5}
                      maxLength={100}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </div>

                  <div
                    className="pt-content-info" /* style={{ border: "none" }} */
                  >
                    <div>
                      <div className="d-flex justify-content-between">
                        <h5 className="mb-2 pt-subtitle">Não inclui</h5>
                        {tourObject.isTranslate === 1 && (
                          <>
                            <div
                              className="btn-edit ml-2"
                              style={{ position: "unset" }}
                              onClick={() =>
                                setAllowEditExcludes(!allowEditExcludes)
                              }
                            >
                              <FontAwesomeIcon
                                icon={["fal", "edit"]}
                                size="1x"
                                className="mr-2"
                              />
                              Editar
                            </div>
                          </>
                        )}
                      </div>
                    </div>

                    <DraggableProductImputs
                      items={excludesArray}
                      setItems={setExcludesArray}
                      tourObject={tourObject}
                      allowEditItems={allowEditExcludes}
                      portuguese={"itemsNotIncludedPT"}
                      english={"itemsNotIncludedEN"}
                      spanish={"itemsNotIncludedES"}
                      address={"itemsNotIncluded"}
                      control={control}
                      setValue={setValue}
                      errors={errors}
                      minLength={5}
                      maxLength={100}
                      required={false}
                      setError={setError}
                      clearErrors={clearErrors}
                    />
                  </div>

                  {/*  */}
                  {isTicket &&
                    <>
                      <div className="pt-content-info">
                        <div>
                          <h5 className="mb-2 pt-subtitle">Tipos de Tickets</h5>
                        </div>

                        <Row>
                          <Form.Group as={Col} md="6" controlId="" className="mt-4">
                            <Form.Label className="label-small">
                              Tipo de venda
                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Controller
                                control={control}
                                name="ticketSellingType"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    name="ticketSellingType"
                                    value={1}
                                    required
                                    label={"Pessoa"}
                                    checked={getValues().ticketSellingType === '1'}
                                  />
                                )}
                              />
                              <Controller
                                control={control}
                                name="ticketSellingType"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    name="ticketSellingType"
                                    value={2}
                                    required
                                    //defaultChecked={true}
                                    label={"Grupo"}
                                    checked={
                                      getValues().ticketSellingType === '2' ||
                                      !getValues().ticketSellingType
                                    }
                                  />
                                )}
                              />
                            </Row>
                          </Form.Group>

                          <Form.Group as={Col} md="6" controlId="" className="mt-4">
                            <Form.Label className="label-small">
                              Ticket cortará alguma fila existente?
                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Controller
                                control={control}
                                name="lineDetailBoolean"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    name="lineDetailBoolean"
                                    id={"lineDetailBoolean-yes"}
                                    value={true}
                                    required
                                    label={"Sim"}
                                    checked={getValues().lineDetailBoolean === "true"}
                                  />
                                )}
                              />
                              <Controller
                                control={control}
                                name="lineDetailBoolean"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    name="lineDetailBoolean"
                                    id={"lineDetailBoolean-no"}
                                    value={false}
                                    required
                                    //defaultChecked={true}
                                    label={"Não"}
                                    checked={
                                      getValues().lineDetailBoolean === "false" ||
                                      !getValues().lineDetailBoolean
                                    }
                                  />
                                )}
                              />
                            </Row>
                          </Form.Group>

                          <Form.Group as={Col} md="6" controlId="" className="mt-4">
                            {(watchTicketSellingType === "2" || watchTicketSellingType === 2) && (
                              <>
                                <Row>
                                  <Col md={6}>
                                    <Form.Label className="label-small">
                                      Quantidade máxima
                                    </Form.Label>

                                    <Controller
                                      control={control}
                                      name="peopleGroupLimit"
                                      rules={{
                                        required: {
                                          value: true,
                                          message:
                                            "Por favor, informe o tipo de operação",
                                        },
                                      }}
                                      render={({ field }: any) => (
                                        <Form.Control
                                          {...field}
                                          type="number"
                                          aria-invalid={
                                            errors?.peopleGroupLimit ? "true" : ""
                                          }
                                          variant="standard"
                                          margin="normal"
                                          required
                                          autoComplete='off'
                                        />
                                      )}
                                    />
                                    <ErrorMessage
                                      errors={errors}
                                      name="peopleGroupLimit"
                                      render={({ message }) => (
                                        <small style={{ color: "red" }}>
                                          {message}
                                        </small>
                                      )}
                                    />
                                  </Col>
                                </Row>
                              </>
                            )}
                          </Form.Group>

                          {(watchLineDetailBoolean === "true" || watchLineDetailBoolean === true) && (
                            <>
                              <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                <Form.Label className="label-small">
                                  Tipo de corte de fila
                                </Form.Label>

                                <Controller
                                  control={control}
                                  name="lineDetail"
                                  rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                                  render={({ field }: any) => (
                                    <GetFetchListLineDetails propsField={field} propsErrors={errors} setValue={setValue} defaultValue={defaultLineDetail} />
                                  )}
                                />

                                <ErrorMessage
                                  errors={errors}
                                  name="lineDetail"
                                  render={({ message }) => (
                                    <small style={{ color: "red" }}>{message}</small>
                                  )}
                                />
                              </Form.Group>
                            </>
                          )}
                        </Row>


                      </div>

                      <div className="pt-content-info">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-2 pt-subtitle">Retirada</h5>
                          {(tourObject.isTranslate === 1 && watchTicketWithdrawl === "true") && (
                            <>
                              <div
                                className="btn-edit ml-2"
                                style={{ position: "unset" }}
                                onClick={() =>
                                  setAllowEditWithdrawlAddress(!allowEditWithdrawlAddress)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={["fal", "edit"]}
                                  size="1x"
                                  className="mr-2"
                                />
                                Editar
                              </div>
                            </>
                          )}
                        </div>

                        <Row>
                          <Form.Group as={Col} md="6" controlId="" className="my-4">
                            <Form.Label className="label-small">
                              Esse ticket possui pontos de retirada
                            </Form.Label>

                            <Row className="mx-0 gap-4">
                              <Controller
                                control={control}
                                name="ticketWithdrawl"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    name="ticketWithdrawl"
                                    id={"ticketWithdrawl-yes"}
                                    value={true}
                                    required
                                    label={"Sim"}
                                    checked={getValues().ticketWithdrawl === "true"}
                                  />
                                )}
                              />
                              <Controller
                                control={control}
                                name="ticketWithdrawl"
                                render={({ field }: any) => (
                                  <Form.Check
                                    {...field}
                                    className="col-1 mb-1"
                                    type="radio"
                                    name="ticketWithdrawl"
                                    id={"ticketWithdrawl-no"}
                                    value={false}
                                    required
                                    //defaultChecked={true}
                                    label={"Não"}
                                    checked={
                                      getValues().ticketWithdrawl === "false" ||
                                      !getValues().ticketWithdrawl
                                    }
                                  />
                                )}
                              />
                            </Row>
                          </Form.Group>
                        </Row>

                        {
                          watchTicketWithdrawl === "true" &&
                          <>
                            {withdrawQtd.map((elem: any, index: number) => {
                              if (elem !== null) {
                                return (
                                  <>
                                    <Row>
                                      <Form.Group as={Col} md="5" controlId="" className="mt-4">
                                        <Form.Label className="label-small">
                                          Endereço de retirada
                                          <OverlayTrigger
                                            overlay={
                                              <Tooltip id="tooltip">
                                                Pesquise e selecione usando os resultados de endereços do Google.
                                              </Tooltip>
                                            }>
                                            <span>
                                              <FontAwesomeIcon
                                                className="ml-2"
                                                icon={["fal", "question-circle"]}
                                              />
                                            </span>
                                          </OverlayTrigger>
                                        </Form.Label>
                                        <Controller
                                          control={control}
                                          name={`withdrawAddress-${index}`}
                                          rules={{ required: { value: true, message: 'Por favor, informe un endereço válido' } }}
                                          render={({ field }: any) => (
                                            <AutoComplete
                                              options={{
                                                types: ["address"],
                                                fields: ["name"]
                                              }}
                                              defaultValue={tourSIG.tour.modalidade?.length > 0 && isEditing !== null && tourSIG.tour.modalidade[isEditing]?.withDrawlAddress?.length > 0 ? tourSIG.tour.modalidade[isEditing]?.withDrawlAddress[index]?.withdrawlAddress || '' : ''}
                                              placeholder=""
                                              apiKey={process.env.REACT_APP_GOOGLE_API}
                                              // onChange={(e: any) => handleLocation(e.target.value)}
                                              onPlaceSelected={(place) => {
                                                setValue(`withdrawAddress-${index}`, place.name);
                                                //setIsValidLocal(true);
                                                // eslint-disable-next-line no-lone-blocks
                                                {
                                                  geocodeByAddress(`${place.name}`)
                                                    .then(results => getLatLng(results[0]))
                                                    .then(({ lat, lng }) => {
                                                      setLatitude(lat, index);
                                                      setLongetude(lng, index);
                                                    }
                                                    );
                                                }
                                              }}
                                            />
                                          )}
                                        />
                                        <ErrorMessage
                                          errors={errors}
                                          name={`withdrawAddress-${index}`}
                                          render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                        />
                                      </Form.Group>
                                      <Form.Group as={Col} md="2" controlId="" className="mt-4">
                                        <Form.Label className="label-small d-flex justify-content-between">
                                          <span>Descrição (Português)</span>
                                        </Form.Label>

                                        <Controller
                                          control={control}
                                          name={`ticketWithdrawDescription-PT-${index}`}
                                          //defaultValue={tourObject?.modalityDescriptionPT || ""}
                                          rules={{ required: { value: false, message: "Por favor, informe esse campo" } }}
                                          render={({ field }: any) => (
                                            <Form.Control
                                              {...field}
                                              type="text"
                                              as="textarea"
                                              rows={2}
                                              variant="standard"
                                              margin="normal"
                                              onBlur={(e: any) => {
                                                allowEditWithdrawlAddress === false && handleBlurWithdrawlAddress(e, `ticketWithdrawDescription`, index);
                                              }}
                                              required
                                              autoComplete='off'
                                            />
                                          )}
                                        />
                                      </Form.Group>
                                      <Form.Group as={Col} md="2" controlId="" className="mt-4">
                                        <Form.Label className="label-small d-flex justify-content-between">
                                          <span>Descrição (Inglês)</span>
                                        </Form.Label>

                                        <Controller
                                          control={control}
                                          name={`ticketWithdrawDescription-EN-${index}`}
                                          //defaultValue={tourObject?.modalityDescriptionPT || ""}
                                          rules={{ required: { value: false, message: "Por favor, informe esse campo" } }}
                                          render={({ field }: any) => (
                                            <Form.Control
                                              {...field}
                                              type="text"
                                              as="textarea"
                                              rows={2}
                                              disabled={
                                                tourObject.isTranslate === 1 &&
                                                allowEditWithdrawlAddress === false
                                              }
                                              variant="standard"
                                              margin="normal"
                                              onBlur={(e: any) => {
                                                //handleBlurModalityName(e, "modalityDescription");
                                              }}
                                              required
                                              autoComplete='off'
                                            />
                                          )}
                                        />
                                      </Form.Group>
                                      <Form.Group as={Col} md="2" controlId="" className="mt-4">
                                        <Form.Label className="label-small d-flex justify-content-between">
                                          <span>Descrição (Espanhol)</span>
                                        </Form.Label>

                                        <Controller
                                          control={control}
                                          name={`ticketWithdrawDescription-ES-${index}`}
                                          //defaultValue={tourObject?.modalityDescriptionPT || ""}
                                          rules={{ required: { value: false, message: "Por favor, informe esse campo" } }}
                                          render={({ field }: any) => (
                                            <Form.Control
                                              {...field}
                                              type="text"
                                              as="textarea"
                                              rows={2}
                                              disabled={
                                                tourObject.isTranslate === 1 &&
                                                allowEditWithdrawlAddress === false
                                              }
                                              variant="standard"
                                              margin="normal"
                                              onBlur={(e: any) => {
                                                //handleBlurModalityName(e, "modalityDescription");
                                              }}
                                              required
                                              autoComplete='off'
                                            />
                                          )}
                                        />
                                      </Form.Group>
                                      <Col md="1">
                                        {withdrawQtd.filter((e: any) => e !== null).length > 1 &&
                                          <div className="d-flex align-items-end justify-content-center h-100">
                                            <FontAwesomeIcon
                                              icon={["fal", "trash-alt"]}
                                              className="mb-4"
                                              style={{ cursor: "pointer" }}
                                              onClick={() => {
                                                handleDeleteWithdrawItems(index);
                                              }}
                                            />
                                          </div>
                                        }
                                      </Col>
                                    </Row>
                                  </>
                                )
                              } else {
                                return (<></>)
                              }
                            })}

                            <div className="d-flex justify-content-center mt-5">
                              <div className="btn-add-details" onClick={handleWithdrawAddress}>
                                <FontAwesomeIcon
                                  icon={["fal", "plus"]}
                                  size="1x"
                                  className="mr-2"
                                />
                                Adicionar
                              </div>
                            </div>
                          </>
                        }
                      </div>
                    </>
                  }

                  {/*  */}
                  <div className="pt-content-info">
                    <div>
                      <h5 className="mb-2 pt-subtitle">Regras de cancelamento</h5>
                    </div>
                    <Form.Group as={Col} md="6" controlId="" className="my-4">
                      <Form.Label className="label-small">
                        Se o hóspede quiser cancelar o pedido, como calcular a
                        taxa de cancelamento?
                      </Form.Label>

                      <Row className="mx-0 mt-2 gap-2 flex-column">
                        <Controller
                          control={control}
                          name="cancelationType"
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe o tipo de cancelamento",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Check
                              {...field}
                              className=""
                              type="radio"
                              //id={"radio-1"}
                              value="1"
                              required
                              label={"Reembolso total"}
                              checked={getValues().cancelationType === "1"}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="cancelationType"
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe o tipo de cancelamento",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Check
                              {...field}
                              className=""
                              type="radio"
                              //id={"radio-2"}
                              value="2"
                              required
                              label={"Sem reembolso"}
                              checked={getValues().cancelationType === "2"}
                            />
                          )}
                        />
                        <Controller
                          control={control}
                          name="cancelationType"
                          rules={{
                            required: {
                              value: true,
                              message:
                                "Por favor, informe o tipo de cancelamento",
                            },
                          }}
                          render={({ field }: any) => (
                            <Form.Check
                              {...field}
                              className=""
                              type="radio"
                              //id={"radio-2"}
                              value="3"
                              required
                              label={"Definir regras de cancelamento"}
                              checked={getValues().cancelationType === "3"}
                            />
                          )}
                        />
                        <ErrorMessage
                          errors={errors}
                          name={`cancelationType`}
                          render={({ message }) => (
                            <small style={{ color: "red" }}>{message}</small>
                          )}
                        />
                      </Row>
                    </Form.Group>

                    {(watchCancel === 3 || watchCancel === "3") && (
                      <>
                        {refundQtd.map((elem: any, index: number) => {
                          if (elem !== null) {
                            return (
                              <>
                                <div
                                  className="custom-cancelation-type"
                                >
                                  <Row>
                                    <Col md="11">
                                      <Row>
                                        <Form.Group
                                          as={Col}
                                          md="4"
                                          controlId=""
                                          className="mt-4"
                                        >
                                          <Form.Label className="label-small">
                                            Dias antes do Check-in
                                          </Form.Label>

                                          <Controller
                                            control={control}
                                            name={`daysBeforeCheckIn-${index}`}
                                            rules={{
                                              required: {
                                                value: true,
                                                message:
                                                  "Por favor, informe esse campo",
                                              },
                                            }}
                                            render={({ field }: any) => (
                                              <Form.Control
                                                {...field}
                                                type="number"
                                                variant="standard"
                                                aria-invalid={
                                                  errors[`daysBeforeCheckIn-${index}`] ? "true" : ""
                                                }
                                                margin="normal"
                                                required
                                                min={0}
                                                autoComplete='off'
                                              />
                                            )}
                                          />
                                          <ErrorMessage
                                            errors={errors}
                                            name={`daysBeforeCheckIn-${index}`}
                                            render={({ message }) => (
                                              <small style={{ color: "red" }}>
                                                {message}
                                              </small>
                                            )}
                                          />
                                        </Form.Group>

                                        <Form.Group
                                          as={Col}
                                          md="4"
                                          controlId=""
                                          className="mt-4"
                                        >
                                          <Form.Label className="label-small">
                                            Política
                                          </Form.Label>

                                          <Controller
                                            control={control}
                                            name={`policy-${index}`}
                                            rules={{
                                              required: {
                                                value: false,
                                                message:
                                                  "Por favor, informe esse campo",
                                              },
                                            }}
                                            render={({ field }: any) => (
                                              <div className={"endpoint"}>
                                                <Form.Select
                                                  {...field}
                                                  as="select"
                                                  variant="standard"
                                                  margin="normal"
                                                  required
                                                  onChange={(e: any) => { handleChangePolicy(e.target.value, index) }}
                                                >
                                                  {isTicket ?
                                                    <>
                                                      <option value="1">Dias após compra</option>
                                                      <option value="2">Dias antes do evento</option>
                                                    </>
                                                    :
                                                    <>
                                                      <option value="1">Percentagem</option>
                                                      <option value="0">Sem custo</option>
                                                    </>
                                                  }
                                                </Form.Select>
                                              </div>
                                            )}
                                          />
                                          <ErrorMessage
                                            errors={errors}
                                            name={`policy-${index}`}
                                            render={({ message }) => (
                                              <small style={{ color: "red" }}>
                                                {message}
                                              </small>
                                            )}
                                          />
                                        </Form.Group>

                                        {((policyArray !== null && policyArray[index] === '1') || isTicket) &&
                                          <Form.Group
                                            as={Col}
                                            md="4"
                                            controlId=""
                                            className="mt-4"
                                          >
                                            <Form.Label className="label-small">
                                              Porcentagem
                                            </Form.Label>

                                            <Controller
                                              control={control}
                                              name={`percentage-${index}`}
                                              rules={{
                                                required: {
                                                  value: true,
                                                  message:
                                                    "Por favor, informe esse campo",
                                                },
                                              }}
                                              render={({ field }: any) => (
                                                <Form.Control
                                                  {...field}
                                                  type="text"
                                                  aria-invalid={
                                                    errors[`percentage-${index}`] ? "true" : ""
                                                  }
                                                  maxLength={3}
                                                  variant="standard"
                                                  margin="normal"
                                                  onBlur={(e: any) => { if (Number(e.target.value || 0) > 100) setValue(`percentage-${index}`, '100') }}
                                                  onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                  required
                                                  autoComplete='off'
                                                />
                                              )}
                                            />
                                            <ErrorMessage
                                              errors={errors}
                                              name={`percentage-${index}`}
                                              render={({ message }) => (
                                                <small style={{ color: "red" }}>
                                                  {message}
                                                </small>
                                              )}
                                            />
                                          </Form.Group>
                                        }
                                      </Row>
                                    </Col>
                                    <Col md="1">
                                      {refundQtd.filter((e: any) => e !== null).length > 1 &&
                                        <div className="d-flex align-items-end justify-content-center h-100">
                                          <FontAwesomeIcon
                                            icon={["fal", "trash-alt"]}
                                            className="mb-4"
                                            style={{ cursor: "pointer" }}
                                            onClick={() => {
                                              handleDeleteRefund(index);
                                            }}
                                          />
                                        </div>
                                      }
                                    </Col>
                                  </Row>


                                </div>
                              </>
                            )
                          } else {
                            return (<></>)
                          }
                        })}
                        <div className="d-flex justify-content-center mt-5">
                          <div
                            className="btn-add-details"
                            onClick={handleRefund}
                          >
                            <FontAwesomeIcon
                              icon={["fal", "plus"]}
                              size="1x"
                              className="mr-2"
                            />
                            Adicionar
                          </div>
                        </div>
                      </>
                    )}
                  </div>

                  {(['99', '8', '9'].includes(tourSIG.tour.integrationPurchaseType) || (tourSIG.tour?.integrationAccessType === '1' || (tourSIG.tour.modalidade?.length > 0 && tourSIG.tour.modalidade[0].haveAccessIntegration === 1))) &&
                    <div className="pt-content-info" style={{ border: "none" }}>
                      <div className="mb-2">
                        <h5 className="mb-4 pt-subtitle">Integração</h5>
                        <small>
                          <strong>Sua modalidade possui integração?</strong>{" "}
                          (Opcional)
                        </small>
                      </div>

                      <div className="integrations p-0">

                        {
                          ['99'].includes(tourSIG.tour.integrationPurchaseType) &&
                          <div className="integration-name d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-center my-2">
                              <div
                                className="btn-add-details"
                                onClick={() => {
                                  setControlIntegrationModal("sig");
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={["fal", "plus"]}
                                  size="1x"
                                  className="mr-2"
                                />
                                Adicionar Integração de SIG
                              </div>
                              {integrationSig !== null && (
                                <>
                                  <div className="ml-2"> Integrado</div>
                                  <div className="d-flex justify-content-end">
                                    <span
                                      className="btn"
                                      style={{ height: "20px", padding: "0 0.75rem" }}
                                      onClick={() => {
                                        setIntegrationSig(null);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={["fal", "trash-alt"]}
                                        style={{ color: "#707070" }}
                                      />
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        }

                        {
                          ['8', '9'].includes(tourSIG.tour.integrationPurchaseType) &&
                          <>
                            <div className="integration-name d-flex justify-content-between align-items-center">
                              <div className="d-flex justify-content-center my-2">
                                <div
                                  className="btn-add-details"
                                  onClick={() => {
                                    setControlIntegrationModal("purchase");
                                  }}
                                >
                                  <FontAwesomeIcon
                                    icon={["fal", "plus"]}
                                    size="1x"
                                    className="mr-2"
                                  />
                                  Adicionar Integração de compra
                                </div>
                                {integrationPurchase !== null && (
                                  <>
                                    <div className="ml-2">
                                      Integrado com{" "}{integrationPurchaseType[integrationPurchase.idPurchaseIntegration]}
                                    </div>
                                    <div className="d-flex justify-content-end">
                                      <span
                                        className="btn"
                                        style={{ height: "20px", padding: "0 0.75rem" }}
                                        onClick={() => {
                                          setIntegrationPurchase(null);
                                        }}
                                      >
                                        <FontAwesomeIcon
                                          size="lg"
                                          icon={["fal", "trash-alt"]}
                                          style={{ color: "#707070" }}
                                        />
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </div>
                            <ErrorMessage
                              errors={errors}
                              name="errorIntegrationPurchase"
                              render={({ message }) => (
                                <small style={{ color: "red" }}>
                                  {message}
                                </small>
                              )}
                            />
                          </>
                        }
                        {
                          (tourSIG.tour?.integrationAccessType === '1' || (tourSIG.tour.modalidade?.length > 0 && tourSIG.tour.modalidade[0].haveAccessIntegration === 1)) &&
                          <div className="integration-name d-flex justify-content-between align-items-center">
                            <div className="d-flex justify-content-center mb-2">
                              <div
                                className="btn-add-details"
                                onClick={() => {
                                  setControlIntegrationModal("access");
                                }}
                              >
                                <FontAwesomeIcon
                                  icon={["fal", "plus"]}
                                  size="1x"
                                  className="mr-2"
                                />
                                Adicionar Integração de acesso
                              </div>
                              {integrationAccess !== null && (
                                <>
                                  <div className="ml-2">Integrado</div>
                                  <div className="d-flex justify-content-end">
                                    <span
                                      className="btn"
                                      style={{ height: "20px", padding: "0 0.75rem" }}
                                      onClick={() => {
                                        setIntegrationAccess(null);
                                      }}
                                    >
                                      <FontAwesomeIcon
                                        size="lg"
                                        icon={["fal", "trash-alt"]}
                                        style={{ color: "#707070" }}
                                      />
                                    </span>
                                  </div>
                                </>
                              )}
                            </div>
                          </div>
                        }

                      </div>
                    </div>
                  }


                  <Button type="submit" style={{ marginTop: '15px' }}>
                    {isEditing === null
                      ? "Cadastrar Modalidade"
                      : "Editar Modalidade"}
                  </Button>
                </Form>
              </div>
              {
                isEditing === null &&
                <div className="h-100 d-flex justify-content-end align-items-end">
                  {isEdit !== true ? (
                    <Row className="mx-0" style={{ marginTop: "24px" }}>
                      {tourSIG.tour.modalidade?.length > 0 &&
                        <>
                          <Button
                            variant="outline-primary"
                            className="form-button mr-3"
                            onClick={() => action("photo")}
                          >
                            Voltar
                          </Button>
                          <Button
                            type="button"
                            className="form-button"
                            onClick={nextStep}
                          >
                            Continuar
                          </Button>
                        </>
                      }
                    </Row>
                  ) : (
                    <>
                      {isEditing === null &&
                        <>
                          <Row className="mt-2 mx-0">
                            <Button
                              type="button"
                              className="form-button"
                              onClick={() => updateModality()}
                            >
                              Salvar
                            </Button>
                          </Row>
                        </>
                      }
                    </>
                  )}
                </div>
              }

              <Modal
                className="modal-custom reservation-details product-integration"
                show={controlIntegrationModal !== ""}
                onHide={() => {
                  setControlIntegrationModal("");
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
              >
                <>
                  <Col md={12} lg={12}>
                    <Card className="pt-content p-0">
                      <div className="modal-header d-flex justify-content-between">
                        <h3 className="text-primary pt-title mb-0">
                          Inserir Integração
                        </h3>
                        <div className="close-btn">
                          <FontAwesomeIcon
                            icon={["fal", "times"]}
                            className="mx-3 text-info"
                            size="lg"
                            data-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              setControlIntegrationModal("");
                            }}
                          />
                        </div>
                      </div>
                    </Card>
                  </Col>
                  {controlIntegrationModal === "access" && (
                    <IntegrationAccess
                      setControlIntegrationModal={setControlIntegrationModal}
                      integrationAccess={integrationAccess}
                      setIntegrationAccess={setIntegrationAccess}
                      getValues={getValues}
                      isTicket={isTicket}
                    />
                  )}
                  {controlIntegrationModal === "purchase" && (
                    <IntegrationPurchase
                      setControlIntegrationModal={setControlIntegrationModal}
                      integrationPurchase={integrationPurchase}
                      setIntegrationPurchase={setIntegrationPurchase}
                      getValues={getValues}
                      isTicket={isTicket}
                    />
                  )}
                  {controlIntegrationModal === "sig" && (
                    <IntegrationSig
                      setControlIntegrationModal={setControlIntegrationModal}
                      integrationSig={integrationSig}
                      setIntegrationSig={setIntegrationSig}
                      supplierIdentity={tourSIG.tour.supplierIdentity}
                    />
                  )}
                </>
              </Modal>

              {modalShow &&
                <>
                  {callModalShow()}
                </>
              }
            </Card>
          </Col>
          :
          <>
            <Col md={9} lg={10}>
              <Card className="pt-content h-100">
                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                  <div className="load mb-4"></div>
                  <div>Carregando dados do produto</div>
                </div>
              </Card>
            </Col>
          </>
      }
    </>
  );
});

export default Modalities;