/* eslint-disable @typescript-eslint/no-unused-vars */
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState, useEffect } from "react";
import { Button, Col, Container, Form, Row } from "react-bootstrap";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useForm } from "react-hook-form";
import api from "../../../../services/api";

import { Modal } from "react-bootstrap";


import "./ResourcesVehicleRankingTable.scss";


export interface propEdit {
  resources: any,
  loading: any,
  reloadTable: any
}

const ResourcesVehicleRankingTable: React.FC<propEdit> = ({
  resources,
  loading,
  reloadTable
}) => {
  const tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");

  //const userName: any = tourAuth.user.nome;
  //const userLastName: any = tourAuth.user.lastName;
  //const groupId: any = tourAuth.user.perfil.groupId;

  //const [pickups, setPickups] = useState<any>(null);
  //const [compFantasiaAux, setCompFantasiaAux] = useState<any>(null);
  //const [latitude, setLatitude] = useState<any>([]);
  //const [longetude, setLongitude] = useState<any>([]);

  const [addLocation, setAddLocation] = useState<any>([]);
  //const [excludedItems, setExcludedItems] = useState<any>([]);
  //const [local, setLocal] = useState<any>([]);

  const [modalShow, setModalShow] = useState(false);
  const [modalLog, setModalLog] = useState<any>(null);
  const [modalMessage, setModalMessage] = useState<any>("Carregando");

  const validated = false;

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
    watch,
  } = useForm();
 
  const onSubmit = (data: any) => {
    if(addLocation.length === 0) {
      setModalShow(true);
      setModalLog(2);
      setModalMessage('Nenhum veiculo na lista');
      return;
    }
    let auxLocation: any = []; 
       
    let controlPosition: any = 1;
    for (let i: any = 0; i < addLocation.length; i++) {
      //if (!excludedItems.includes(addLocation[i].id)) {
        
        auxLocation.push({
          resourceId: addLocation[i].resourceId,
          ranking: controlPosition,
        });
        controlPosition += 1;
      //}
    }

    //console.log(data, auxLocation)
    setModalShow(true);
    //setLoading(true)

    //setModalContent("loading");

    const UpdatePickup = async () => {
      try {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
          headers: { 'Authorization': `Bearer ${token}` },
        };

        const res = await api.post(`Resources/UpdateVehicleRanking`, {
          list: auxLocation,
        }, config);

        if (res.status !== 400) {
          setModalLog(res.data.data.log);
          setModalMessage(res.data.data.texto);
        }
      } catch (error: any) {}
    };

     UpdatePickup();    
  };

  // useEffect(() => {
  //   const idEdit: any = window.location.href.split("?id=")[1];
    
  //   async function ListPickups() {
  //     try {
  //       const { data } = await api.post("Pickup/ListPickupLocation", {
  //         page: 0,
  //         rowsPerPage: 0,
  //         id: Number(idEdit),
  //       });

  //       if (data.status !== 400) {
  //         if (data.data !== null) {
  //           const arrAux: any = []
  //           //setPickups(data.data.data);
  //           data.data.data.list.map((item:any)=>{
  //              arrAux.push(
  //                item.name
  //             );  
  //             setLocal(arrAux)            
  //           })
  //         } else {   
  //            setModalShow(true);
  //            setModalMessage(data.texto);
  //            setInterval(()=>{
  //             setModalLog(1)
  //            }, 2000)    
  //           //window.location.href = '/pickup';           
  //         }
  //       }
  //     } catch (error: any) {

  //       if (error.response?.status === 401) {
  //         window.location.href = window.location.origin + "/";
  //       }
  //     }
  //   }
  //   ListPickups();

  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, []);

  useEffect(() => {
    
    if (resources !== null) {
      
      let defineAddLocationSize: any = [];

      resources?.forEach((elem: any, index: any) => {
        elem.id = index;
        elem.ranking = index;
        defineAddLocationSize.push(elem);
      });

      setAddLocation(defineAddLocationSize);

      // resources?.list.forEach((elem: any, index: any) => {
      //   setValue(`pickupName${index}`, elem.name);
      //   setValue(`search${index}`, elem.minutesPrior);
      //   setValue(`instruction${index}`, elem.additionalInstructions);
      // });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resources]);

  function handleOnDragEnd(result: any) {
    if (!result.destination) return;

    const items = Array.from(addLocation);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setAddLocation(items);
  }

  // const reload = () => {
  //   if (modalLog === 0) {
  //     window.location.reload();
  //   }
  // };

  if (resources !== null) {
    
    return (
      <>

        <div className="table-default resources-vehicle-ranking-table">
          <div className="table-container">
            <Container fluid className="bg-white">
              <Row className='justify-content-between p-2 bg-white ranking-header'>
                <Form.Group as={Col} lg="1" md="1" className="my-2 text-right" style={{ padding: '0px', maxWidth: '55px' }}>
                  #
                </Form.Group>

                <Form.Group as={Col} lg="11" md="11">
                  <Row className="my-2">
                    <Form.Group as={Col} lg="9" md="9">
                      Descrição
                    </Form.Group>
                    <Form.Group as={Col} lg="1" md="1" className="text-center">
                      Assentos
                    </Form.Group>
                    <Form.Group as={Col} lg="2" md="2" className="text-center">
                      Tipo de Pagamento
                    </Form.Group>
                  </Row>
                </Form.Group>
              </Row>
              <Form
                noValidate
                validated={validated}
                onSubmit={handleSubmit(onSubmit)}
                autoComplete="false"
              >
                <DragDropContext onDragEnd={handleOnDragEnd}>
                  <Droppable droppableId="addLocation">
                    {(provided: any) => (
                      <ul
                        className="addLocation react-draggable"
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                      >
                        {addLocation
                          //.filter((e: any) => !excludedItems.includes(e.id))
                          .map((elem: any, index: any) => {
                            return (
                              <Draggable
                                key={elem.ranking}
                                draggableId={elem.ranking.toString()}
                                index={index}
                              >
                                {(provided: any) => (
                                  <li
                                    ref={provided.innerRef}
                                    {...provided.draggableProps}
                                    {...provided.dragHandleProps}
                                    id={`draggable-list-item-${elem.ranking}`}
                                  >
                                    <Container className="px-0 py-2 bg-transparent" fluid>
                                      <Row className='justify-content-between bg-white p-2 ranking-border'>
                                        <Form.Group as={Col} lg="1" md="1" style={{ padding: '0px', maxWidth: '55px' }}>
                                          <div
                                            className="d-flex justify-content-between align-items-center h-100"
                                            style={{ backgroundColor: "#f2f2f2", cursor: "move", position: 'relative' }}
                                          >
                                            <span><FontAwesomeIcon icon={["fal", "bars"]} /></span>
                                            <span>{index + 1}</span>
                                          </div>
                                        </Form.Group>

                                        <Form.Group as={Col} lg="11" md="11">
                                          <Row className="my-3">
                                            <Form.Group as={Col} lg="9" md="9">
                                              {elem.resourceName} - {elem.vehicleIdentification}
                                            </Form.Group>
                                            <Form.Group as={Col} lg="1" md="1" className="text-center">
                                              {elem.seats}
                                            </Form.Group>
                                            <Form.Group as={Col} lg="2" md="2" className="text-center">
                                              {elem.paymentTypeDesc}
                                            </Form.Group>
                                          </Row>
                                        </Form.Group>
                                      </Row>
                                    </Container>
                                  </li>
                                )}
                              </Draggable>
                            );
                          })}
                        {provided.placeholder}
                      </ul>
                    )}
                  </Droppable>
                </DragDropContext>

                <Row>
                  <Form.Group
                    as={Col}
                    md="12"
                    lg="12"
                    className="d-flex justify-content-md-end mt-5 mb-3"
                  >
                    <Button
                      type="submit"
                      className="btn-default text-uppercase"
                      disabled={addLocation.length === 0}
                    >
                      Salvar
                    </Button>
                  </Form.Group>
                </Row>
              </Form>
            </Container>
          </div>
        </div>
        <Modal
          className="modalAuth"
          show={modalShow}
          onHide={() => {
            reloadTable();
            setModalLog(null);
            setModalMessage("Carregando");
            setModalShow(false);
          }}
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body
              className="modal-body text-center sucess-pos d-flex justify-content-evenly"
              style={{ height: "600px" }}
            >
              {modalLog === null ? (
                <>
                  <div className="loading-modal">
                    <div className="load"></div>
                  </div>
                </>
              ) : (
                <div>
                  {modalLog === 0 ? (
                    <FontAwesomeIcon
                      icon={["fal", "check-circle"]}
                      size="5x"
                      className="text-primary"
                      style={{ fontSize: "7.5em"}}
                    />
                  ) : (
                    <FontAwesomeIcon
                      icon={["fal", "times-circle"]}
                      size="5x"
                      className="text-primary"
                      style={{ fontSize: "7.5em"}}
                    />
                  )}
                </div>
              )}
              <div>{modalMessage}</div>
              <div className="d-flex justify-content-center pt-3">
                <button
                  onClick={() => {
                    reloadTable();
                    setModalLog(null);
                    setModalMessage("Carregando");
                    setModalShow(false);
                  }}
                  className="btn btn-primary mx-2 w-25"
                >
                  Confirmar
                </button>
              </div>
            </Modal.Body>
          </>
        </Modal>
      </>
    );
  } else {
    return (
      <>
        <div className="accordion bg-white">
          <Container fluid>
            <Row className='justify-content-between p-2 bg-white ranking-header'>
              <Form.Group as={Col} lg="1" md="1" className="my-2 text-right" style={{ padding: '0px', maxWidth: '55px' }}>
                #
              </Form.Group>

              <Form.Group as={Col} lg="11" md="11">
                <Row className="my-2">
                  <Form.Group as={Col} lg="9" md="9">
                    Descrição
                  </Form.Group>
                  <Form.Group as={Col} lg="1" md="1" className="text-center">
                    Assentos
                  </Form.Group>
                  <Form.Group as={Col} lg="2" md="2" className="text-center">
                    Tipo de Pagamento
                  </Form.Group>
                </Row>
              </Form.Group>
            </Row>
            <Form
              noValidate
              //validated={validated}
              //onSubmit={handleSubmit(onSubmit)}
              autoComplete="false"
            >
                    
              <ul
                className="addLocation react-draggable"
              >
                <li
                  id={`draggable-list-item-0`}
                >
                  <Container className="px-0 py-2 bg-transparent" fluid>
                    <Row className='justify-content-center bg-white p-2 ranking-border'>
                      {/* <Form.Group as={Col} lg="12" md="12"> */}
                        Nenhum veículo encontrado
                      {/* </Form.Group> */}
                    </Row>
                  </Container>
                </li>
              </ul>

              <Row>
                <Form.Group
                  as={Col}
                  md="12"
                  lg="12"
                  className="d-flex justify-content-md-end mt-5 mb-3"
                >
                  <Button
                    type="submit"
                    className="btn-default text-uppercase"
                    disabled
                  >
                    Salvar
                  </Button>
                </Form.Group>
              </Row>
            </Form>
          </Container>
        </div>
      </>
    );
  }
};

export default ResourcesVehicleRankingTable;
