import React from 'react';
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';


const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors }: any) => {
  const { t } = useTranslation();

  // if(Array.isArray(value)) {
  //   setValue(value[0]);
  // } else {
  //   setValue(value);
  // }

  return (
    <input
      className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
      onFocus={openCalendar}
      value={date}
      readOnly
      placeholder={t("billsToPay.table.paymentAndDebitNotes.when")}
      autoComplete='off'
    />
  )
}



const SingleCalendarEditable = ({date, setValue, field, errors = undefined }: any) => {
  const { t } = useTranslation();
  //const [aux, setAux] = useState<any>(date);


  /* useEffect(()=>{
    setValue('date',aux);
  },[aux]) */

  return (
    <DatePicker
      //{...field}
      value={date}
      onChange={(e:any) => { setValue(e.format()); }}
      weekDays={t('calendar.weekDays').split(',')}
      months={t('calendar.months').split(',')}
      format={configData.DATE.PT}
      render={<CustomMultipleInput date={date} setValue={setValue} errors={errors} />}
    />
  )
}

export default SingleCalendarEditable;



