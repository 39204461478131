import React from "react";

import "./ProductTransfer.scss"
import DataTransfer from "./components/DataTransfer/DataTransfer";
import MeetingPoint from "./components/MeetingPoint/MeetingPoint";
import Tariffs from "./components/Tariffs/Tariffs";
import DaysHours from "./components/DaysHours/DaysHours";
import Features from "./components/Features/Features";
import Contact from "./components/Contact/Contact";
import Blackout from "./components/Blackout/Blackout";
import Administrative from "./components/Administrative/Administrative";

export interface propTransfer {
  receiveObject?: any;
  setTransferObject?: any;
  transferObject?: any;
  handleNext?: any;
  management?: any;
  isEdit:any;
}

const ProductTransfer: React.FC<propTransfer> = ({
  receiveObject, 
  setTransferObject, 
  transferObject,
  handleNext,
  management,
  isEdit
}: propTransfer) => {

  return (
    <>
        {/* CONTENT */}

        {(() => {
          switch (management) {
            case 1:
              return <DataTransfer action={handleNext} receiveObject={receiveObject} transferObject={transferObject} isEdit={isEdit} />;
            case 2:
              return <MeetingPoint action={handleNext} receiveObject={receiveObject} transferObject={transferObject} isEdit={isEdit} />;
            case 3:
              return <Tariffs action={handleNext} receiveObject={receiveObject} transferObject={transferObject} isEdit={isEdit} />;
            case 4:
              return <DaysHours action={handleNext} receiveObject={receiveObject} transferObject={transferObject} isEdit={isEdit} />;
            case 5:
              return <Features action={handleNext} receiveObject={receiveObject} transferObject={transferObject} isEdit={isEdit} />;
            case 6:
              return <Contact action={handleNext} receiveObject={receiveObject} transferObject={transferObject} isEdit={isEdit} />;
            case 7:
              return <Blackout action={handleNext} receiveObject={receiveObject} transferObject={transferObject} isEdit={isEdit} />;
            case 8:
              return <Administrative action={handleNext} transferObject={transferObject} receiveObject={receiveObject} isEdit={isEdit} />;
            default:
              return null;
          }
        })()}
    </>
  )
}

export default ProductTransfer;