import React, { Key, useEffect, useState } from "react";
import Table from "react-bootstrap/Table";
//import Button from "react-bootstrap/Button";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Dropdown from 'react-bootstrap/Dropdown';
import { useTranslation } from 'react-i18next';
import Modal from "react-bootstrap/Modal";



//STYLES
import "./SuppliersTableCompany.scss";

//JSON
// import objectSuppliers from "../SuppliersTableCompany/locales/suppliers.json";
import ModalDefault from "../../../../../components/Modal/ModalDefault/ModalDefault";
import FormAddContact from "../../../../../components/Forms/FormAddContact/FormAddContact";


export interface propModal {
  action: any;
  setMessageError: any;
  messageError: any;
  isEdit: any;
  info: any;
  disableAddContact?: any;
  hasSavedInputs?: any;
};

const SuppliersTableCompany: React.FC<propModal> = ({
  action, setMessageError, messageError, isEdit = false, info, disableAddContact = false, hasSavedInputs = false
}: propModal) => {

  const { t } = useTranslation();
  const [controlObject, setControlObject] = useState<any>((isEdit === true || hasSavedInputs === true) ? info.supplierContact : []);
  const [modalEdit, setModalEdit] = useState<any>([]);
  const [closeModal, setCloseModal] = useState<any>(false);

  const handleActionModal = (objectModal: any) => {
    if (controlObject.length === 0) {
      objectModal.isMain = 1;
    } else {

      if (objectModal.isMain === 1) {     /// permite apenas 1 contato principal
        controlObject.forEach((elem: any) => {
          elem.isMain = 0;
        });
      }
    }
    setControlObject([...controlObject, objectModal]);
  }

  useEffect(() => {
    if (controlObject?.length > 0) {
      setMessageError(false);
    }
    action(controlObject);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [controlObject])

  function removeRow(index: any) {
    var aux: any = [];
    var excludeIsMain: any = false;
    if (controlObject[index].isMain === 1) {
      excludeIsMain = true;
    }

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== index) {
        aux.push(controlObject[i]);
      }
    }

    if (excludeIsMain === true && aux.length > 0) {
      aux[0].isMain = 1;
    }

    setControlObject(aux);
  }

  function editRow(index: any) {
    var aux: any = controlObject[index];

    aux.index = index;

    setModalEdit([aux])
  }

  function handleEdit(row: any) {
    var aux: any = [];

    for (var i: any = 0; i < controlObject.length; i++) {
      if (i !== row.index) {
        aux.push(controlObject[i]);
      } else {
        aux.push(row);
      }
    }

    setControlObject(aux);
    setModalEdit([]);
  }

  return (
    <>
      <div className="table-add-default suppliers-contact-table">
        <div className="table-container" style={{ border: messageError === true ? '1px solid #dc3545' : '' }}>
          {/* <BootstrapTable rowEvents={ rowEvents } bootstrap4 keyField='email' data={controlObject} hover={true} columns={columns} pagination={pagination} striped={true} /> */}
          <Table striped bordered>
            <thead>
              <tr>
                <th>{t('accordionInfoCompany.tableContact.main')}</th>
                <th>{t('accordionInfoCompany.tableContact.name')}</th>
                <th>{t('accordionInfoCompany.tableContact.email')}</th>
                <th>{t('accordionInfoCompany.tableContact.phone')}</th>
                <th>{t('accordionInfoCompany.tableContact.cellphone')}</th>
                <th>{t('accordionInfoCompany.tableContact.area')}</th>
                <th>{t('accordionInfoCompany.tableContact.action')}</th>
              </tr>
            </thead>
            <tbody>
              {controlObject?.length > 0
                ? controlObject.map((info: any, index: Key) => (
                  <tr key={index}>
                    <td>{info.isMain === 1 ? <FontAwesomeIcon size="lg" icon={['fal', 'check-circle']} style={{ margin: "0 20px" }} /> : ""}</td>
                    <td>{info.name}</td>
                    <td>{info.email}</td>
                    <td>{info.phone}</td>
                    <td>{info.cellPhone}</td>
                    <td>{info.area}</td>
                    <td>{
                      <div className="center-table options-table">
                        <Dropdown drop="down" >
                          <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                              size="lg"
                              icon={['fal', 'ellipsis-h']}
                            />
                          </Dropdown.Toggle>

                          {disableAddContact === false
                            ?
                            <Dropdown.Menu>
                              <Dropdown.Item
                                onClick={() => editRow(index)}
                                eventKey="1"
                                className="removeRow">
                                <FontAwesomeIcon
                                  icon={['fal', 'edit']}
                                //className="mr-2"
                                />
                                {t('accordionInfoCompany.tableContact.edit')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                onClick={() => removeRow(index)}
                                eventKey="1"
                                className="text-danger removeRow">
                                <FontAwesomeIcon
                                  icon={['fal', 'trash-alt']}
                                  //className="mr-2"
                                />
                                {t('accordionInfoCompany.tableContact.remove')}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                            :
                            <></>
                          }
                        </Dropdown>
                      </div>
                    }</td>
                  </tr>
                ))
                : ""
              }
              {disableAddContact === false
                ?
                <div className="add-contact">
                  <div className="text-center btn-add-contact">
                    <ModalDefault
                      name={'+'}
                      title="Adicionar Contato"
                      class="btn-add"
                      classModal="modal-pos-tax"
                      closeModal={closeModal}
                      setCloseModal={setCloseModal}
                    >
                      <div className="form-add-contact">
                        <FormAddContact
                          action={handleActionModal}
                          setCloseModal={setCloseModal}
                          modalEdit={modalEdit}
                          setModalEdit={setModalEdit}
                          handleEdit={handleEdit}
                        />
                      </div>
                    </ModalDefault>
                    <p style={{ color: "#dc3545", marginTop: "10px", marginBottom: "0", fontSize: "14px" }}>
                      {
                        messageError === true
                          ?
                          'Por favor, adicione um contato'
                          :
                          ''
                      }
                    </p>
                  </div>
                </div>

                : ''
              }
            </tbody>
          </Table>
          <Modal
            className="modal-custom modalAuth modal-pos-tax"
            show={modalEdit.length > 0}
            onHide={() => { setModalEdit([]) }}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header closeButton>
                <div>
                  <h3>Adicionar Contato</h3>
                </div>
              </Modal.Header>
              <hr style={{ margin: "30px 0" }} />
              <div className="form-add-contact">
                <FormAddContact
                  action={handleActionModal}
                  setCloseModal={setCloseModal}
                  modalEdit={modalEdit}
                  setModalEdit={setModalEdit}
                  handleEdit={handleEdit}
                />
              </div>

            </>
          </Modal>
        </div>
      </div>
    </>
  );
}

export default SuppliersTableCompany;
