import React from "react";
import { Container, Row } from "react-bootstrap";

import "../../../assets/sass/modal.scss";
import "./ModalResourceVoucherViewer.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  voucherViewModalShow : any, 
  setVoucherViewModalShow: any, 
  voucher: any, 
};

const ModalResourceVoucherViewer: React.FC<propModal> = ({
  voucherViewModalShow, setVoucherViewModalShow, voucher
}: propModal, props: any) => {
  //const [tipo, setTipo] = useState<string>(tipo);

  //const [voucherViewModalShow, setVoucherViewModalShow] = useState<any>(false);

  const onSubmitContact = (dataObj: any) => {
    
    setVoucherViewModalShow(false);
  };

  return (
    <>
      <Modal
        className="modalAuth"
        show={voucherViewModalShow}
        onHide={() => setVoucherViewModalShow(false)}
        size="xl"
        aria-labelledby="contained-modal-title-vcenter"
      >
        <>
          <Modal.Header className="p-3" closeButton></Modal.Header>
               
          <Modal.Body>
            <Container className="bg-modal-contact p-0" fluid>
              <Row>
                <h5 className="contact-title mb-5">Visualizar Voucher</h5>
                <Form
                  className="form-add-contact"
                  noValidate
                >
                  <div className="">
                    <Row className="">
                      <img 
                        alt='voucher'
                        className="mx-auto" 
                        src={voucher} 
                      />
                    </Row>
                  </div>
                </Form>
              </Row>
            </Container>
          </Modal.Body>

          <Modal.Footer>
            <Row className="buttons-modal d-flex justify-content-end mx-0">
              {/* <Button className="form-button mr-3" onClick={props.onHide}>
                Cancelar
              </Button> */}
              <Button
                type="button"
                className="form-button"
                onClick={onSubmitContact}
              >
                Confirmar
              </Button>
            </Row>
          </Modal.Footer>
        </>
      </Modal>
    </>
  );
}

export default ModalResourceVoucherViewer;
