import React, { useState } from "react";

import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import DesktopDefault from "../../templates/DesktopDefault";
import './StockGroupSession.scss';
import InsertStockGroupSession from "./components/InsertStockGroupSession/InsertStockGroupSession";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link } from "react-router-dom";

//import api from "../../services/api";

function StockGroupSession() {

    //const [tarCode, setTarCode] = useState<string | null>(null)

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard stock-group-session">
                    <Breadcrumb title={"Estoque / Grupo de Estoque"} />

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>
                            Grupo de Estoque
                        </h2>
                    </div>

                    <div className="d-flex align-items-center my-3">
                        <Link
                            className="back m-0"
                            to={"/products/list-stock-group"}
                        >
                            <FontAwesomeIcon
                                icon={["fal", "angle-double-left"]}
                                style={{ margin: "0 5px 0 0", fontSize: '16px' }}
                            />
                            Voltar
                        </Link>
                    </div>

                    <InsertStockGroupSession />


                </div>
            </DesktopDefault>
        </>
    );
}

export default StockGroupSession;
