import React, { useEffect, useState } from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../assets/sass/modal.scss";
import "./ModalScoreListDetails.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../services/api';
import { ErrorMessage } from "@hookform/error-message";
import BootstrapTable from "react-bootstrap-table-next";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

export interface propModal {
  info: any,
  setModalShow: any,
  setModalSuccess: any,
  setModalContent: any,
  setResponseText: any,
  setLog: any
};

const ModalScoreListDetails: React.FC<propModal> = ({
  info, setModalShow, setModalSuccess, setModalContent, setResponseText, setLog
}: propModal, props: any) => {
  //const [messageList, setMessageList] = useState<any>(null);

  const [modalDetailList, setModalDetailList] = useState<any>(null);
  const [scoreSelected, setScoreSelected] = useState<any>(null);
  const [removeScoreModalShow, setRemoveScoreModalShow] = useState<any>(false);

  function updateList() {
    async function getResource() {
      const token = localStorage.getItem("GroupId") || "{}";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };

      try {
        const { data } = await api.post(
          `Resources/GetSupplierResourceScoreList`, {
            resourceId: info.resourceId,
            optionId: info.optionId
          }, config
        );

        if (data.status !== 400) {
          const dados = data.data;

          setModalDetailList(dados);
        }
      } catch (error: any) {}
    }
    getResource();
  }

  useEffect(() => {
    if(info !== null) updateList();
  }, [info]);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  const formatDate = (data: any) => {
    if(!data) return '';
    
    //2022-05-05T00:00:00
    var aux = data.split('T');
    aux = aux[0].split('-');

    return (`${aux[2]}/${aux[1]}/${aux[0]}`);
  }

  function handleClickRemoveScoreItem(row: any) {
    setScoreSelected(row);
    setRemoveScoreModalShow(true);
  }

  function handleActionRemoveScoreModal() {
    setRemoveScoreModalShow(false);
    
    setModalSuccess(true);
    setModalContent("loading");

    const scoreId = scoreSelected.id;

    setScoreSelected(null);
    
    const doDeleteExtract = async () => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post(`/Resources/DeleteResourceScoreItem`, {
              scoreId: scoreId
            }, config);
            if (res.status !== 400) {
                setResponseText(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent("success");
                updateList();
                //console.log(res)
            } else {
                setResponseText(res.data.data.texto);
                setLog(res.data.data.log);
                setModalContent("error");
            }
            
        } catch (error: any) {
            setModalContent("error");
        }
    };

    doDeleteExtract();
  }

  //botao de ação
  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <button type="button" className="btn btn-default p-0 m-0" style={{width:"20px"}} onClick={(e:any) => handleClickRemoveScoreItem(row)}><FontAwesomeIcon
            size="sm"
            icon={["fal", "trash"]}
            style={{ color: "#707070", margin: "0px", padding: "0px"}}
          /></button>
          {/* <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg"  icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item eventKey="1" disabled={true} className="text-danger remove"><FontAwesomeIcon icon={['fal', 'trash-alt']} />{t('suppliersTable.remove')}</Dropdown.Item> 
              <Dropdown.Item eventKey="2" onClick={() => handleClickShowModalDetails(row)}><FontAwesomeIcon icon={['fal', 'glasses-alt']} />Detalhes</Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown> */}
        </div>
      </>
    );
  }


  function convertDateTime(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    const aux2 = cell.split("T");

    const aux: any = aux2[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;

    var time: any = aux2[1];
    return (
      <>
        {date} {time}
      </>
    );
  }

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell}</span>
      </>
    )
  }

  const columns = [
    { dataField: "dateCreated", text: "Data Hora", formatter: convertDateTime },
    { dataField: "userName", text: "Login" },
    { dataField: "description", text: "Erros e Acertos" },
    { dataField: "status", text: "Status" },
    { dataField: "points", text: "Pontos", formatter: convertValue },
    { dataField: "id", text: "Ações", formatter: addActionButton },
  ];

  if(modalDetailList !== null) {
    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="h-100 flex-column">
              <h5 className="contact-title mb-1">Recurso - Detalhes Score</h5>
              <div className="table-default resource-scoredetail-table">
                <div className="table-container">   
                  <BootstrapTable
                    bootstrap4
                    keyField="id"
                    data={modalDetailList}
                    hover={true}
                    columns={columns}
                    striped={true}
                    //rowClasses={rowClasses}
                  />
                </div>
              </div> 
              <div className="mt-auto">
                <Row>
                  <Form.Group as={Col} md="10">
                    {/* nd */}
                  </Form.Group>
                  <Form.Group as={Col} md="2">
                    <button
                      type="button"
                      className="btn btn-primary text-uppercase w-100"
                      onClick={() => setModalShow(false)}
                    >
                      Fechar
                    </button>
                  </Form.Group>
                </Row>
              </div>
            </Row>
          </Container>
        </Modal.Body>

        {/* modal excluir acerto/erro */}
        <ModalQuestion
            modalShow={removeScoreModalShow}
            setModalShow={setRemoveScoreModalShow}
            titulo="Excluir Acerto/Erro"
            mensagem="Tem Certeza que deseja excluir este acerto/erro ?"
            handleActionConfirmModal={handleActionRemoveScoreModal}
            //handleActionCancelModal={handleActionCancelDeleteClick}
          />
        {/* END - modal excluir acerto/erro */}
      </>
    );
  } else {
    return (<></>);
  }
  
}

export default ModalScoreListDetails;
