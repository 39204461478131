/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { Key, useState, useEffect } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Form, Dropdown, Modal, Row, Col } from "react-bootstrap";
import { useAppDispatch } from "../../../../store/hooks";
import { addItemCart } from "../../../../store/cart";
import DropdownNumberPeopleIntegrated from "../../../../components/DropdownNumberPeopleIntegrated/DropdownNumberPeopleIntegrated";


import BootstrapTable from "react-bootstrap-table-next";
import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";

import ModalDefault from '../../../../components/Modal/ModalDefault/ModalDefault';

import api from "../../../../services/api";

//COMPONENTS

import './SessionPassengers.scss';
import SessionChange from './components/SessionChange/SessionChange';

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import Reminder from '../../../../components/Reminder/Reminder';
import SessionChangeRepasse from './components/SessionChangeRepasse/SessionChangeRepasse';

export interface propPassengers {
    items: any,
    refreshData: any,
    sessionInfo?: any,
    operationType: any,
    exportSessionItems: any,
};

const SessionPassengers: React.FC<propPassengers> = ({
    items, refreshData, sessionInfo, operationType, exportSessionItems
}: propPassengers) => {

    /* Success Modal (Mudar Session) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();

    // Go Show States
    const [numberPeople, setNumberPeople] = useState<any>(null);
    const [dateTour, setDateTour] = useState<any>([]);
    const [ranges, setRanges] = useState<any>(null);
    const [modalGoShow, setModalGoShow] = useState<boolean>(false)
    const [infoGoShow, setInfoGoShow] = useState<any>(null)
    const [infoGoShowDate, setInfoGoShowDate] = useState<any>(null)
    const [loading, setLoading] = useState<any>(false);
    const [tourDetails, setTourDetails] = useState<any>(null);
    const dispatch = useAppDispatch();
    const today = new Date().toLocaleDateString();
    const setNewNumberPeople = (peoples: any) => {
        setNumberPeople(peoples);
    };
    // End Go Show States

    // ModalFetchSingleTour
    const [modalFetchSingle, setModalFetchSingle] = useState<boolean>(false)
    const [responseTextFetchSingle, setResponseTextFetchSingle] = useState<any>();
    const [modalContentFetchSingle, setModalContentFetchSingle] = useState<any>('');
    const [logFetchSingle, setLogFetchSingle] = useState<any>(0);
    // ModalFetchSingleTour

    const [reminderModal, setReminderModal] = useState<boolean>(false);
    const [currentReservation, setCurrentReservation] = useState<any>(null);
    /* END - Success Modal (Mudar Session) */

    const [selectedSingleRow, setSelectedSingleRow] = useState<any>(null);

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const statusTour = window.location.href.split('status=')[1];

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId;

    useEffect(() => {
        let aux: any = [];
        for (let i = 0; i < tourDetails?.modalities?.length; i++) {
            aux.push({
                data: today,
                idSellingType: +tourDetails?.idSellingType,
                isFreeSale: 1,
                auxTarif: [],
                adultText: '',
                childText: '',
                infantText: '',
                globalText: '',
                time: infoGoShow[0]?.hora,
                adult: infoGoShow[0]?.priceAdult,
                child: infoGoShow[0]?.priceChild,
                infant: infoGoShow[0]?.priceInfant,
                student: infoGoShow[0]?.pricestudent,
                elders: infoGoShow[0]?.priceElders,
                global: infoGoShow[0]?.vagasUsadas,
                maxStockNumber: infoGoShow[0]?.vagasDisponiveis,
                idTarifario: infoGoShow[0]?.idTarifario,
            });
        }
        setDateTour(aux);
    }, [tourDetails, today, infoGoShow]);

    const TourDetailRender = async (productCode: any) => {
        setModalContentFetchSingle('loading')
        try {
            const data = await api.post(
                `/Products/FetchSingleTourAsync`,
                {
                    "productCode": productCode,
                    "lang": 'BR',
                    "idCanal": localStorage.getItem('idCanal') || 1
                }
            );
            if (data.status === 200) {
                setTourDetails(data.data.data);
                setModalGoShow(true);
                setModalFetchSingle(false);
            } else {
                setModalFetchSingle(true)
                setModalContentFetchSingle('error')
                setLogFetchSingle(1)
                setResponseTextFetchSingle(`Erro ao acessar informações do tour!`)
            }
        } catch (error) {
            setModalFetchSingle(true)
            setModalContentFetchSingle('error')
            setLogFetchSingle(1)
            setResponseTextFetchSingle(`Erro ao acessar informações do tour!`)
        }
    }

    const goShowButton = async () => {
        // setModalGoShow(true)
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const { data } = await api.post(`/manifest/GetGoShow`,
                {
                    "sessionId": sessionInfo.sessionId
                }, config);

            if (data.status !== 400) {
                if (data.data !== null) {
                    setInfoGoShow([data.data])
                    setInfoGoShowDate(data.dateTime)
                } else {
                    setInfoGoShow(null)
                }
            }
        } catch (error: any) {
        }
    }

    useEffect(() => {
        if (infoGoShow && infoGoShow.length > 0) {
            TourDetailRender(infoGoShow[0]?.productCode);
        }
    }, [infoGoShow]);

    const addToCart = (itemOption: any) => {
        const item = {
            productName: tourDetails?.productName,
            productNameEN: tourDetails?.productNameEN,
            productNameES: tourDetails?.productNameES,
            modalityName: tourDetails?.modalities[0]?.modalityName,
            imgCart: tourDetails?.images[0],
            imagesBaseUrl: tourDetails?.imagesBaseUrl,
            typeProduct: tourDetails?.typeProduct,
            price: tourDetails?.modalities[0]?.tarif.price,
            isGoShow: true,
            idSession: infoGoShow[0]?.sessionId,


            idTarif: infoGoShow[0]?.idTarifario,
            idPickup: '',
            reservationSystem: tourDetails?.reservationSystem,


            priceAdults: infoGoShow[0]?.priceAdult,
            priceChilds: infoGoShow[0]?.priceChild,
            priceInfants: infoGoShow[0]?.priceInfant,
            priceElders: infoGoShow[0]?.priceElders || 0,
            priceStudent: infoGoShow[0]?.priceStudents || 0,
            priceGlobalPeople: infoGoShow[0]?.priceGlobalPeople || 0,


            sellingType: tourDetails?.idSellingType,
            adults: numberPeople.adults,
            childs: numberPeople.childs,
            infants: numberPeople.infants,
            elders: numberPeople.elders,
            student: numberPeople.student,
            globalPeople: dateTour.idSellingType === 1 ? 1 : numberPeople.adults + numberPeople.childs + numberPeople.infants + numberPeople.elders + numberPeople.student + numberPeople.globalPeople,
            //      option.totalPeople = dateTour[index].idSellingType === 1 ? numberPeople.globalPeople : undefined;
            totalPeople: itemOption?.totalPax,

            productType: 1,
            productCode: itemOption?.productCode,
            time: infoGoShow[0]?.hora,
            date: infoGoShowDate,
            supplier: tourDetails?.supplier,
            supplierFantasyName: tourDetails?.supplierFantasyName,
            typePickup: tourDetails?.modalities[0]?.embarkingType,
            meetingPoint: tourDetails?.modalities[0]?.embarkingType === "0" ? tourDetails?.modalities[0]?.meetingPoint : '',
            pickupListId: tourDetails?.modalities[0]?.embarkingType === "0" ? 0 : tourDetails?.modalities[0]?.pickupListId,
            pickup: 0,

            discount: 0,
            customValueNet: 0,
            customValueSell: 0,
            goingSource: "null",
            commingSource: "null",
            latOrigem: "null",
            lngOrigem: "null",
            latDestino: "null",
            lngDestino: "null",
            cia: "null",
            voo: "null",
            smallSuitcase: 0,
            bigSuitcase: 0,
            internalNotes: `Lançamento Go Show: ${today} - ${infoGoShow[0]?.hora}`,
            idVeiculo: 0,
            passengers: [],
            labelsSorted: tourDetails?.modalities[0]?.ranges?.labelsSorted
        }
        dispatch(addItemCart(item));
        window.location.href = '/checkout'
    }


    function SendEmail(row: any) {
        setModalContent("loading");
        const email = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendEmailToClient`,
                    {
                        id: row.newItemsId,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        email();
    }

    function SendWhatsapp(row: any) {
        setModalContent("loading");
        const whatsapp = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendWhatsAppToClient`,
                    {
                        id: row.newItemsId,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        whatsapp();
    }

    function SendEmailSupplier(row: any) {
        setModalContent("loading");
        const email = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendEmailToSupplier`,
                    {
                        id: row.newItemsId,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        email();
    }

    function SendWhatsappSupplier(row: any) {
        setModalContent("loading");
        const whatsapp = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendWhatsAppToSupplier`,
                    {
                        id: row.newItemsId,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    setResponseText("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    setResponseText("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        whatsapp();
    }

    function handleSessionChange(sucesso: any) {
        if (sucesso === true) {
            // setModalSuccess(true); // não mostar sucesso atualizar direto
            refreshData();
        } else {
            setModalSuccess(true); // mostrar erro
        }


    }

    function addActionButton(cell: any, row: any) {
        return (
            <>
                <div className="d-flex justify-content-center dropdown-custom">
                    <Dropdown drop="start">
                        <Dropdown.Toggle variant="light ">
                            <FontAwesomeIcon
                                size="lg"
                                icon={["fal", "ellipsis-h"]}
                            />
                        </Dropdown.Toggle>

                        <Dropdown.Menu>
                            <Dropdown.Item eventKey="1" disabled={false}>
                                <ModalDefault
                                    title="Trocar Session"
                                    icon="yes"
                                    name={"Mudar de Session"}
                                    typeIcon="exchange"
                                    classBtn="px-0 w-100 d-flex justify-content-start align-items-center"
                                    classModal="modal-pos-tax modal-change-session"
                                >
                                    <SessionChange
                                        sessionInfo={sessionInfo}
                                        items={items}
                                        row={row}
                                        show={handleSessionChange}
                                        setResponseText={setResponseText}
                                        setModalContent={setModalContent}
                                        setLog={setLog}
                                    />
                                </ModalDefault>
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                disabled={false}
                                onClick={() => {
                                    setCurrentReservation({ idNewItems: row.newItemsId });
                                    setReminderModal(true);
                                }}>
                                <button className='px-0'>
                                    <FontAwesomeIcon
                                        icon={['fas', 'envelope']}
                                        style={{ fontSize: "16px" }}
                                    />
                                    Reminder
                                </button>
                            </Dropdown.Item>
                            {row.repass === 1 ?
                                <Dropdown.Item eventKey="3" disabled={false}>
                                    <ModalDefault
                                        title="Trocar Session - Produtos de Repasse"
                                        icon="yes"
                                        name={"Mudar de Session Repasse"}
                                        typeIcon="exchange"
                                        classBtn="px-0 w-100 d-flex justify-content-start align-items-center"
                                        classModal="modal-pos-tax modal-change-session"
                                    >
                                        <SessionChangeRepasse
                                            sessionInfo={sessionInfo}
                                            items={items}
                                            row={row}
                                            show={handleSessionChange}
                                            setResponseText={setResponseText}
                                            setModalContent={setModalContent}
                                            setLog={setLog}
                                        />
                                    </ModalDefault>
                                </Dropdown.Item>
                                : <></>}
                            {/* <Dropdown.Item
                                eventKey="2"
                                disabled={false}
                                onClick={() => {
                                    SendEmail(row)
                                    setModalSuccess(true);
                                }}>
                                <button className='px-0'>
                                    <FontAwesomeIcon
                                        icon={['fas', 'envelope']}
                                        style={{ fontSize: "16px" }}
                                        //className="mr-2"
                                    />
                                    Enviar Email
                                </button>
                            </Dropdown.Item>
                            <Dropdown.Item
                                eventKey="2"
                                disabled={false}
                                onClick={() => {
                                    SendWhatsapp(row)
                                    setModalSuccess(true);
                                }}
                            >
                                <button className='d-flex align-items-center px-0'>
                                    <FontAwesomeIcon
                                        icon={['fab', 'whatsapp']}
                                        style={{ fontSize: "18px" }}
                                        //className="mr-2"
                                    />
                                    Enviar Whatsapp
                                </button>
                            </Dropdown.Item> */}
                            {operationType === '2' ?
                                <>
                                    <Dropdown.Item
                                        eventKey="4"
                                        disabled={false}
                                        onClick={() => {
                                            SendEmailSupplier(row)
                                            setModalSuccess(true);
                                        }}>
                                        <button className='px-0'>
                                            <FontAwesomeIcon
                                                icon={['fas', 'envelope']}
                                                style={{ fontSize: "16px" }}
                                            //className="mr-2"
                                            />
                                            Enviar Email Fornecedor
                                        </button>
                                    </Dropdown.Item>
                                    <Dropdown.Item
                                        eventKey="5"
                                        disabled={false}
                                        onClick={() => {
                                            SendWhatsappSupplier(row)
                                            setModalSuccess(true);
                                        }}
                                    >
                                        <button className='d-flex align-items-center px-0'>
                                            <FontAwesomeIcon
                                                icon={['fab', 'whatsapp']}
                                                style={{ fontSize: "18px" }}
                                            //className="mr-2"
                                            />
                                            Enviar Whatsapp Fornecedor
                                        </button>
                                    </Dropdown.Item>
                                </>
                                : <></>}
                            {/* <Dropdown.Item
                                eventKey="2"
                                disabled={true}
                            >
                                <button className='text-danger remove px-0'>
                                    <FontAwesomeIcon
                                        icon={['fal', 'trash-alt']}
                                        className="mr-2"
                                    />
                                    Cancelar Item
                                </button>
                            </Dropdown.Item> */}
                        </Dropdown.Menu>
                    </Dropdown>
                </div>
            </>
        );
    }

    function addLocalizador(cell: any, row: any) {
        return (
            <>
                <div>
                    {cell}<br />
                    <div>
                        {
                            row.paxConfirmed === 2 && row.userValidation === 1
                                ?
                                <span className="point red"></span>
                                :
                                row.userValidation === 1 && row.paxConfirmed === 1
                                    ?
                                    <span className="point green"></span>
                                    :
                                    <span className="point gray"></span>
                        }
                        <span style={{ fontSize: "12px" }}> Check-in</span>
                    </div>
                </div>
            </>
        )
    }

    function addClientPhone(cell: any, row: any) {

        return (
            <>
                {cell} <br /> <span style={{ fontSize: "12px" }}>Telefone: {row.telefone}</span>
            </>
        )
    }

    function addParticipantes(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell} Participantes</span> <br /> <span style={{ fontSize: "12px" }}>{row.qtdeAdult} Adultos - {row.qtdeChild} Crianças - {row.qtdeInfant} Infantes</span>
                </div>
            </>
        )
    }

    function addLanguage(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell}</span>
                </div>
            </>
        )
    }

    function addLocalizacao(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell}</span> <br /> <span style={{ fontSize: "12px" }}>Horário {row.horario}</span>
                </div>
            </>
        )
    }

    function addTour(cell: any, row: any) {
        return (
            (row.tour) ?
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{row.tour}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
                :
                <span className="text-wrap-custom">
                    {cell}
                </span>);
    }

    function addModalidade(cell: any, row: any) {
        return (
            (row.modalidade) ?
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{row.modalidade}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
                :
                <span className="text-wrap-custom">
                    {cell}
                </span>);
    }

    function addComplements(cell: any, row: any) {
        return (
            cell > 0 ?
                <div className="complements">
                    <FontAwesomeIcon
                        size="lg"
                        icon={["fal", "puzzle-piece"]}
                    />
                    <div className="complements-container">
                        <h6 className="">Complementos</h6>
                        <div className="table">
                            <BootstrapTable
                                bootstrap4
                                keyField="id"
                                data={row.complements}
                                columns={columnsComplements}
                                //rowClasses={rowClasses}
                                striped={true}
                            />
                        </div>
                    </div>
                </div>
                :
                <></>
        );
    }

    function addCompra(cell: any, row: any) {
        return (
            <>
                <div>
                    <span>{cell}</span> <br />
                    <div>
                        {
                            row.pago === 2
                                ?
                                <span className="point gray"></span>
                                :
                                row.pago === 1
                                    ?
                                    <span className="point green"></span>
                                    :
                                    <span className="point red"></span>
                        }
                        <span style={{ fontSize: "12px" }}> Status</span>
                    </div>
                </div>
            </>
        )
    }

    function handleStatusChange(evt: any, row: any) {
        evt.preventDefault();
        row.status = evt.target.value;
        setSelectedSingleRow(row);
        setModalSuccess(true);
        setResponseText('Deseja mesmo trocar o status do item ?');
        setModalContent('statusChange');
        setLog(2);
    }

    function handleStatusChangeConfirm() {

        setModalContent("loading");
        const changeStatus = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/UpdateItemStatus`,
                    {
                        id: selectedSingleRow.newItemsId,
                        status: selectedSingleRow.status,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    setSelectedSingleRow(null);
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    alert("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    alert("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        changeStatus();
    }

    function addStatus(cell: any, row: any) {
        return (
            <>
                <div>
                    <div>
                        <Form.Select
                            aria-labelledby="aria-label"
                            //inputId="aria-example-input"
                            name="aria-live-color"
                            //{...field}
                            as="select"
                            //variant="standard"
                            //margin="normal"
                            value={row.status}
                            onChange={(e: any) => handleStatusChange(e, row)}
                        >
                            <option value="1">Pendente</option>
                            <option value="2">Solicitado</option>
                            <option value="3">Confirmado</option>
                        </Form.Select>
                    </div>
                </div>
            </>
        )
    }

    function addNote(cell: any, row: any) {
        return (
            <>
                <OverlayTrigger
                    overlay={
                        <Tooltip id="tooltip">{cell}</Tooltip>
                    }>
                    <span className="text-wrap-custom">
                        {cell}
                    </span>
                </OverlayTrigger>
            </>

        )
    }

    const columnsComplements =
        [
            {
                dataField: "localizador",
                text: "Localizador",
            },
            {
                dataField: "qtdePax",
                text: "Quantidade",
                formatter: addParticipantes,
            },
            {
                dataField: "descricao",
                text: "Complemento",
                formatter: addModalidade,
            }
        ]

    const columns =
        (operationType === '2') ?
            [
                {
                    dataField: "localizador",
                    text: "Localizador",
                    formatter: addLocalizador,
                },
                {
                    dataField: "cliente",
                    text: "Cliente",
                    formatter: addClientPhone,
                },
                {
                    dataField: "qtdePax",
                    text: "Quantidade",
                    formatter: addParticipantes,
                },
                {
                    dataField: "idiomas",
                    text: "Idiomas",
                    formatter: addLanguage,
                },
                {
                    dataField: "localizacao",
                    text: "Localização",
                    formatter: addLocalizacao,
                },
                {
                    dataField: "tour",
                    text: "Tour",
                    formatter: addTour,
                },
                {
                    dataField: "modalidade",
                    text: "Modalidade",
                    formatter: addModalidade,
                },
                {
                    dataField: "qtdeComplementos",
                    text: "Complementos",
                    formatter: addComplements,
                },
                {
                    dataField: "vendedor",
                    text: "Compra",
                    formatter: addCompra,
                },
                {
                    dataField: "notas",
                    text: "Notas",
                    formatter: addNote,
                },
                {
                    dataField: "notasGuia",
                    text: "Notas Guia",
                    formatter: addNote,
                },
                {
                    dataField: "status",
                    text: "Status",
                    formatter: addStatus
                },
                {
                    dataField: "newItemsId",
                    text: "Ação",
                    formatter: addActionButton
                },
            ]
            :
            [
                {
                    dataField: "localizador",
                    text: "Localizador",
                    formatter: addLocalizador,
                },
                {
                    dataField: "cliente",
                    text: "Cliente",
                    formatter: addClientPhone,
                },
                {
                    dataField: "qtdePax",
                    text: "Quantidade",
                    formatter: addParticipantes,
                },
                {
                    dataField: "idiomas",
                    text: "Idiomas",
                    formatter: addLanguage,
                },
                {
                    dataField: "localizacao",
                    text: "Localização",
                    formatter: addLocalizacao,
                },
                {
                    dataField: "tour",
                    text: "Tour",
                    formatter: addTour,
                },
                {
                    dataField: "modalidade",
                    text: "Modalidade",
                    formatter: addModalidade,
                },
                {
                    dataField: "complementsQty",
                    text: "Complementos",
                    formatter: addComplements,
                },
                {
                    dataField: "vendedor",
                    text: "Compra",
                    formatter: addCompra,
                },
                {
                    dataField: "notas",
                    text: "Notas",
                    formatter: addNote,
                },
                {
                    dataField: "notasGuia",
                    text: "Notas Guia",
                    formatter: addNote,
                },
                {
                    dataField: "newItemsId",
                    text: "Ação",
                    formatter: addActionButton
                },
            ];



    function handleReload() {
        window.location.reload();
    }

    return (
        <>
            <div className={"table-default passengers-table" + ((operationType === '2') ? " passengers-table-tercerizado" : "")}>
                <div className="table-container">
                    <div className="d-flex justify-content-between">
                        <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                            <div className='d-flex gap-2'>
                                <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportSessionItems}>
                                    <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Exportar
                                </div>
                                {statusTour === '1' ? <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={goShowButton}>
                                    <FontAwesomeIcon
                                        icon={["fal", "share"]}
                                        size="1x"
                                        style={{ marginRight: "5px" }}
                                    />
                                    Go Show
                                </div> : <></>}

                            </div>
                        </div>
                    </div>
                    <BootstrapTable
                        bootstrap4
                        keyField="id"
                        data={items}
                        columns={columns}
                        rowClasses={'border-analise'}
                        striped={true}
                    />
                </div>
                {/* Modal de Success */}
                <Modal
                    className={"modal-confirm loading-modal"}
                    show={modalSuccess}
                    onHide={() => setModalSuccess(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos'>
                            {
                                modalContent !== "loading"
                                    ?
                                    <>
                                        <div>
                                            {
                                                log === 1 || log === "1"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "times-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                                    :
                                                    log === 0 || log === "0"
                                                        ?
                                                        <FontAwesomeIcon
                                                            icon={["fal", "check-circle"]}
                                                            size="5x"
                                                            className="text-primary"
                                                            style={{ fontSize: "7.5em" }}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={["fal", "question-circle"]}
                                                            size="5x"
                                                            className="text-primary"
                                                            style={{ fontSize: "7.5em" }}
                                                        />
                                            }
                                        </div>
                                        <div>
                                            {responseText}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {
                                                modalContent === 'success'
                                                    ?
                                                    <button
                                                        onClick={handleReload}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Confirmar
                                                    </button>
                                                    :
                                                    modalContent === 'statusChange'
                                                        ?
                                                        <>
                                                            <button
                                                                onClick={() => { setModalSuccess(false); refreshData(); }}
                                                                className="btn btn-primary mx-2 w-25"
                                                            >
                                                                Não
                                                            </button>
                                                            <button
                                                                onClick={() => handleStatusChangeConfirm()}
                                                                className="btn btn-primary mx-2 w-25"
                                                            >
                                                                Sim
                                                            </button>
                                                        </>
                                                        :
                                                        <button
                                                            onClick={() => { setModalSuccess(false); } /* setModalShow(false) */}
                                                            className="btn btn-primary mx-2 w-25"
                                                        >
                                                            Confirmar
                                                        </button>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="load"></div>
                                        </div>
                                        <div>
                                            <span>Processando</span>
                                        </div>
                                        <div></div>
                                    </div>
                            }
                        </Modal.Body>
                    </>
                </Modal>
                {/* END - Modal de Success */}

                {/* Modal GoShow */}
                <Modal
                    className="modal-pos-tax modal-change-session px-0 w-100 d-flex justify-content-start align-items-center"
                    show={modalGoShow}
                    onHide={() => {
                        setModalGoShow(false);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className='goshow-content'>
                        <div className='title-goshow'>
                            <h2>Go Show</h2>
                        </div>
                        <div>
                            <p className="subtitle-change-session">Session Destino</p>
                        </div>
                        <Form.Group>
                            <Row>
                                <Col md="9">
                                    <Form.Select disabled>
                                        {
                                            infoGoShow?.map((goShow: any) => {
                                                return (
                                                    <option value={`${goShow?.sessionId}`}>{`${goShow?.sessionId} - Data: ${today} - Horário:  ${goShow?.hora} - ${goShow?.produto} - ${goShow?.vagasUsadas} PAX`}</option>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Col>
                                <Col md="3">
                                    <div className="panel-dropdown bg-white mt-0">
                                        <DropdownNumberPeopleIntegrated
                                            actionPeople={setNewNumberPeople}
                                            info={tourDetails}
                                            dateTour={dateTour}
                                            index={0}
                                            ranges={tourDetails?.modalities[0]?.ranges}
                                        />
                                    </div>
                                </Col>
                            </Row>


                        </Form.Group>
                    </div>

                    <Modal.Footer>
                        <Row className="buttons-modal d-flex justify-content-end mx-0">
                            <div className='mt-4 d-flex justify-content-end px-0'>
                                <div>
                                    <button className="btn btn-default btn-outline-primary px-4 mr-3 text-uppercase" onClick={() => { setModalGoShow(false) }}>
                                        Cancelar
                                    </button>
                                </div>
                                <div>
                                    <button className="btn btn-default btn-primary px-4 text-uppercase" disabled={tourDetails === null} type="submit" onClick={() => addToCart(sessionInfo)}>
                                        {
                                            loading === true
                                                ?
                                                <div className="d-flex align-items-center" style={{ width: "113px", height: "30px" }}>
                                                    <div className="load"></div>
                                                </div>
                                                :
                                                "Enviar"
                                        }
                                    </button>
                                </div>
                            </div>
                        </Row>
                    </Modal.Footer>
                </Modal>
                {/* END - Modal GoShow */}

                <Modal
                    className="reminder modal-custom reservation-details"
                    show={reminderModal !== false}
                    onHide={() => {
                        setCurrentReservation(null);
                        setReminderModal(false);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <div className="reminder-container">
                        <Reminder id={currentReservation} />
                    </div>
                </Modal>

                {/* Modal FETCHSINGLETOUR */}
                <Modal
                    className="modal-pos-tax modal-confirm loading-modal modal-change-session px-0 w-100 d-flex justify-content-start align-items-center"
                    show={modalFetchSingle}
                    onHide={() => {
                        setModalFetchSingle(false);
                    }}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >

                    <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalContentFetchSingle !== "loading"
                                ?
                                <>
                                    <div>
                                        {
                                            logFetchSingle === 1 || logFetchSingle === "1"
                                                ?
                                                <FontAwesomeIcon
                                                    icon={["fal", "times-circle"]}
                                                    size="5x"
                                                    className="text-primary"
                                                    style={{ fontSize: "7.5em" }}
                                                />
                                                :
                                                logFetchSingle === 0 || logFetchSingle === "0"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "check-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                                    :
                                                    <FontAwesomeIcon
                                                        icon={["fal", "question-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em" }}
                                                    />
                                        }
                                    </div>
                                    <div>
                                        {responseTextFetchSingle}
                                    </div>
                                    <div className="d-flex justify-content-center">
                                        {
                                            modalContentFetchSingle === 'success'
                                                ?
                                                <button
                                                    onClick={handleReload}
                                                    className="btn btn-primary mx-2 w-25"
                                                >
                                                    Confirmar
                                                </button>
                                                :
                                                modalContentFetchSingle === 'statusChange'
                                                    ?
                                                    <>
                                                        <button
                                                            onClick={() => { setModalFetchSingle(false); refreshData(); }}
                                                            className="btn btn-primary mx-2 w-25"
                                                        >
                                                            Não
                                                        </button>
                                                        <button
                                                            onClick={() => handleStatusChangeConfirm()}
                                                            className="btn btn-primary mx-2 w-25"
                                                        >
                                                            Sim
                                                        </button>
                                                    </>
                                                    :
                                                    <button
                                                        onClick={() => { setModalFetchSingle(false); } /* setModalShow(false) */}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Confirmar
                                                    </button>
                                        }
                                    </div>
                                </>
                                :
                                <div className="modal-body">
                                    <div className="text-center">
                                        <div className="load"></div>
                                    </div>
                                    <div>
                                        <span>Processando</span>
                                    </div>
                                    <div></div>
                                </div>
                        }
                    </Modal.Body>
                </Modal>
                {/* END - Modal FETCHSINGLETOUR */}
            </div>
        </>
    );

}

export default SessionPassengers;