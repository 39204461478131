import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import bg01 from "../../assets/img/backgrounds/Bg-Profile.jpg";

import "./Profile.scss";
import ProfileCards from "./components/ProfileCards/ProfileCards";
//import i18next from "i18next";
//import LanguageLoginMenu from "../../components/LanguageLoginMenu/LanguageLoginMenu";

function Login() {
  
  return (
    <Container fluid className="container-login p-0">
      <Row className="h-100 w-100 m-0">
        <Col
          sm={12} 
          className="bg-login-image p-0"
          id="random"
          style={{ backgroundImage: `url(${bg01})` }}
        >
          <div className="split-screen">
            <Row className="h-100 w-100 m-0">
              <Col className="logo-login" sm={12} md={6}>
                <div className="h-100 w-100 d-flex flex-column justify-content-center align-items-center">
                  <img src={process.env.REACT_APP_SERVER_LOGO_WHITE} alt="C2SIG" />
                </div>
                {/* <LanguageLoginMenu /> */}
              </Col>
              <Col sm={12} md={6} className="bg-white valign valign-profile left p-0 my-auto">
                <ProfileCards />
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default Login;
