import React, { useState } from "react";
import api from '../../../../services/api';

import { Form, Modal, Dropdown, Row, Col } from "react-bootstrap";
import { useForm } from "react-hook-form";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from 'react-i18next';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";
//import paginationFactory from "react-bootstrap-table2-paginator";

//STYLES
import "./TableGroupFiles.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";

//import CreditNote from "./components/CreditNote/CreditNote";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";
import GroupFileDetails from "./components/GroupFileDetails/GroupFileDetails";
import SendQuotation from "./components/SendQuotation/SendQuotation";

export interface propTable {
  billsReceive: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  loading: any;
  exportBills: any;
  printBills: any;
  //totalBillsReceive?: any;
  actualDate: any;
  parameters?: any;
  refreshData?: any;
}

const TableGroupFiles: React.FC<propTable> = ({
  billsReceive, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, loading, exportBills, printBills /*, totalBillsReceive*/, actualDate, parameters,
  refreshData
}: propTable) => {
  const { t } = useTranslation();

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [expiration, setExpiration] = useState<any>();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>(null);
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);

  const [confirmCancelShow, setConfirmCancelShow] = useState<any>(false);
  const [fileDetailModalShow, setFileDetailModalShow] = useState<any>(false);
  const [confirmSendQuotationShow, setConfirmSendQuotationShow] = useState<any>(false);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  const userName: any = tourAuth.user.nome;
  const userLastName: any = tourAuth.user.lastName;
  const uId: any = tourAuth.user.uId;

  async function editRow(row: any) {
    //setSelectedSingleRow(row);
    //setEditShow(true);
    window.open(`edit-groups-customized?id=${row.id}`, "_blank");
  }

  async function duplicateRow(row: any) {
    window.open(`copy-groups-customized?id=${row.id}`, "_blank");
  }

  async function cancelRow(row: any) {
    // dialogo antes de cancelar
    setSelectedSingleRow(row);
    setConfirmCancelShow(true);
  }

  async function handleActionCancelFileModal() {
    setConfirmCancelShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var fileId = selectedSingleRow.id;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/GroupFile/CancelGroupFile`,
        {
          "fileId": fileId,
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function rowDetails(row: any) {
    // abrir modal detalhes do file
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };
    try {
      const res = await api.get(`/GroupFile/GetGroupFile/${row.id}`, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setSelectedSingleRow(dados);
        setFileDetailModalShow(true);
      }
    } catch (error: any) {

    }
  }

  async function sendQuotation(row: any) {
    // chamar envio de cotação na api
    setSelectedSingleRow(row);
    setConfirmSendQuotationShow(true);
  }

  async function downloadQuotation(row: any) {
    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var fileId = row.id;

    //setSelectedSingleRow(null);

    try {
      const res = await api.post(`/GroupFile/CreateQuotationReportPdf`,
        {
          "fileId": fileId,
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
          const objectUrl = `data:${dados.data.fileType};base64,${dados.data.file}`;                

          const link = document.createElement('a');
          link.href = objectUrl;
          link.setAttribute(
              'download',
              dados.data.fileName,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function handleActionSendQuotationModal() {
    setConfirmSendQuotationShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var fileId = selectedSingleRow.id;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/GroupFile/SendQuotationReportPdfToClient`,
        {
          "fileId": fileId,
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function sendPreInvoice(row: any) {
    // chamar envio de pre invoice na api
  }

  async function downloadVoucher(row: any) {
    // baixar voucher das reservas??? ou do file???
  }

  async function downloadRecipe(row: any) {
    // baixar recibo do file
  }

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm();

  function addActionButton(cell: any, row: any) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                onClick={() => { editRow(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'edit']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.edit')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => { duplicateRow(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'copy']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.duplicate')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                className="text-danger remove"
                onClick={() => { cancelRow(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'ban']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.cancel')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                onClick={() => { rowDetails(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'file-alt']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.details')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="5"
                onClick={() => { sendQuotation(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'envelope']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.sendQuotation')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="6"
                onClick={() => { downloadQuotation(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'download']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.downloadQuotation')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="7"
                disabled={true}
                onClick={() => { sendPreInvoice(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'envelope']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.preInvoice')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="8"
                disabled={true}
                onClick={() => { downloadVoucher(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'download']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.voucher')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="9"
                disabled={true}
                onClick={() => { downloadRecipe(row) }}>
                <FontAwesomeIcon
                  icon={['fal', 'download']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.recipe')}
              </Dropdown.Item>
              {/* <Dropdown.Item
                eventKey="10"                
                onClick={() => {}}>
                <FontAwesomeIcon
                  icon={['fal', 'dollar-sign']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.recipe')} inanceiro
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="10"                
                onClick={() => {}}>
                <FontAwesomeIcon
                  icon={['fal', 'badge-check']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.recipe')}Operações
              </Dropdown.Item> */}
            </Dropdown.Menu>
          </Dropdown>
        </div>
      </>
    );
  }

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell}</span>
      </>
    )
  }

  function formatValue(cell: any, row: any) {
    if (cell) {
      return (
        <>
          <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")} {row.sufixovalor}</span>
        </>
      )
    } else {
      return (
        <>
          <span className=""></span>
        </>
      )
    }
  }

  function addOperationStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Cotação"
              ? "bg-inativo"
            : cell === "Reservas Solicitadas"
              ? "bg-analise"
            : cell === "Reservas Confirmadas"
              ? "bg-analise"
            : cell === "Pagamento Parcial"
              ? "bg-analise"
            : cell === "Em Operação"
              ? "bg-ativo"
            : cell === "Operado"
              ? "bg-ativo"
            : cell === "Cancelado"
              ? "bg-inativo"
            : ""
        }
      >
        {cell}
      </div>
    );
  }

  function addFinancialStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
              ? "bg-inativo"
            : cell === "INVOICE Enviada"
              ? "bg-analise"
            : cell === "Recebido Parcial"
              ? "bg-analise"
            : cell === "Recebido Integral"
              ? "bg-ativo"
            : ""
        }
      >
        {cell}
      </div>
    );
  }

  const columns = [
    { dataField: "fileNo", text: t("groupsAndCustomized.report.fileNo"), sort: true },
    { dataField: "clientName", text: t("groupsAndCustomized.report.clientName"), sort: true },
    { dataField: "groupName", text: t("groupsAndCustomized.report.groupName"), sort: true },
    {
      dataField: "firstServiceDate",
      text: t("groupsAndCustomized.report.firstServiceDate"),
      sort: true,
      formatter: convertDate,
    },
    {
      dataField: "operationStatusDesc",
      text: t("groupsAndCustomized.report.operationStatus"),
      sort: true,
      formatter: addOperationStatus,
    },
    {
      dataField: "financialStatusDesc",
      text: t("groupsAndCustomized.report.financialStatus"),
      sort: true,
      formatter: addFinancialStatus,
    },
    { dataField: "id", text: "Ações", formatter: addActionButton },
  ];

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "files", text: "Files" }];

  const notFoundProducts = [{ contas: "Nenhum file encontrado" }];
  /* [END] In case of empty table*/

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando files", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const rowClasses = (row: any, rowIndex: any) => {
    const cell = row.operationStatusDesc;

    return (cell === "Cotação"
              ? "border-inativo"
            : cell === "Reservas Solicitadas"
              ? "border-analise"
            : cell === "Reservas Confirmadas"
              ? "border-analise"
            : cell === "Pagamento Parcial"
              ? "border-analise"
            : cell === "Em Operação"
              ? "border-ativo"
            : cell === "Operado"
              ? "border-ativo"
            : cell === "Cancelado"
              ? "border-inativo"
            : "");
  };


  /*NOTA AVULSA PESQUISAR */

  const [singleNoteInfo, setSingleNoteInfo] = useState<any>(undefined);

  const searchSingleNote = (localizador: any = '') => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function getSingleSearch() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetOrderInfo/${localizador}`, config);
        if (data.statusCode !== 400) {
          //;
          setSingleNoteInfo(data.data);
        }
      } catch (error: any) {
        console.log(error);
        //if (error.response.status === 401) {
          //
        //}
      }
    }
    getSingleSearch();
  }

  function closeAction() {
    setSingleNoteInfo(undefined);
  }
  /* [END] NOTA AVULSA PESQUISAR */


  const onSubmit = (data: any) => {
    setModalContent("loading");
    setModalSuccess(true);
    
    var fixDate = datapgto.split('/');
    data.dataPgto = `${fixDate[2]}-${fixDate[1]}-${fixDate[0]}`;
    data.contaLiquidacao = data.contaLiquidacao ? data.contaLiquidacao.id : 0;
    
    
    var valor: any = selectedSingleRow.length > 0 ? selectedSingleRow[0].valor : 0;
    
    var items: any = selectedSingleRow.length > 0 ? [{
      "id": parseInt((selectedSingleRow[0].nParcela > 1) ? selectedSingleRow[0].installmentsId : selectedSingleRow[0].newPaymentsId),
      "tipo": ((selectedSingleRow[0].nParcela > 1) ? 2 : 1) //0//itemsToSettle[i].tipo
    }] : [];

    async function settleQuery() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
          headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const res = await api.post('/BillsReceivable/SettleBills',
          {
            "parametros": selectedSingleRow.length > 0 ? null : parameters, // objeto com os parametros da consulta
            "items": items, // array com os itens a serem liquidados
            "valor": valor,
            "descTaxa": data.descTaxa ? parseInt(data.descTaxa) : 0, // valor taxa desconto
            "jurosMulta": data.jurosMulta ? parseInt(data.jurosMulta) : 0, // valor taxa juros
            "contaLiquidacao": data.contaLiquidacao ? parseInt(data.contaLiquidacao) : 0, // conta liquidação
            "dataPgto": data.dataPgto, // data do pagamento
            "uid": uId,
            "usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );
        if (res.status !== 400) {
          setResponseText(res.data.data.texto);
          setLog(res.data.data.log);

          if (res.data.data.log === 0) {
            setModalContent('success'); 
            //setAllowReload(true);
            setModalShow(false);
          }
          else if (res.data.data.log === 1) {
            setModalContent('error');
          }
        }
      } catch (error: any) {
        
        if (error?.response?.status === 401) {
          //window.location.href = window.location.origin + '/';
        }
      }
    }

    settleQuery();
  }

  function handleReload() {
    window.location.reload();
  }

  function validaFaturar() {
    if (parameters.formaPagamento !== 5) {
      setResponseText('Selecione Forma de Pagamento Faturado');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
      //console.log('aqui');
    } else if (parameters.agente === '' || parameters.agente === '0' || parameters.agente === null) {
      setResponseText('Selecione um único Agente');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
    } else if ((parameters.dataInicial === '') || (parameters.dataFinal === '')) {
      setResponseText('Selecione o periodo de data');
      setModalContent('warning');
      setLog(2);
      setModalSuccess(true);
    }
  }

  if (billsReceive !== null) {
    return (
      <>
        <div className="table-default bills-receive-table">
          <div className="table-container">
            <div>
              <div className="d-flex justify-content-between">
                <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={exportBills}>
                      <FontAwesomeIcon
                        icon={["fal", "share"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t('groupsAndCustomized.report.export')}
                    </div>
                  </div>
                  <div>
                    <div style={{ cursor: 'pointer' }} className="btn btn-bills" onClick={printBills}>
                      <FontAwesomeIcon
                        file-invoice
                        icon={["fal", "print"]}
                        size="1x"
                        style={{ marginRight: "5px" }}
                      />
                      {t('groupsAndCustomized.report.print')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsReceive}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        

        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em"}}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em"}}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalShow(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}

        {/* confirm cancel modal */}
        <ModalQuestion
          modalShow={confirmCancelShow}
          setModalShow={setConfirmCancelShow}
          titulo={t('groupsAndCustomized.report.cancelAction.title')}
          mensagem={t('groupsAndCustomized.report.cancelAction.message')}
          handleActionConfirmModal={handleActionCancelFileModal}
        />
        {/* END - confirm cancel modal */}

        {/* confirm send quotation modal */}
        <Modal
          className="modal-custom modalAuth modal-medium"
          show={confirmSendQuotationShow}
          onHide={() => setConfirmSendQuotationShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <div className="d-flex justify-content-between w-100">
                <h3>{t('groupsAndCustomized.report.sendQuotationAction.title')}</h3>
              </div>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <SendQuotation info={selectedSingleRow} setModalContent={setModalContent} setModalSuccess={setModalSuccess} setLog={setLog} setResponseText={setResponseText} t={t} />
          </>
        </Modal>
        {/* <ModalQuestion
          modalShow={confirmSendQuotationShow}
          setModalShow={setConfirmSendQuotationShow}
          titulo={t('groupsAndCustomized.report.sendQuotationAction.title')}
          mensagem={t('groupsAndCustomized.report.sendQuotationAction.message')}
          handleActionConfirmModal={handleActionSendQuotationModal}
        /> */}
        {/* END - confirm send quotation modal */}
        
        {/* file detail modal */}
        <Modal
          className="modal-custom modalAuth modalFileDetails"
          show={fileDetailModalShow}
          onHide={() => setFileDetailModalShow(false)}
          size="xl"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <div className="d-flex justify-content-between w-100">
                <h3>{t('groupsAndCustomized.detailModal.title')}</h3>
              </div>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <GroupFileDetails info={selectedSingleRow} onSuccess={() => { refreshData(); setFileDetailModalShow(false); }} t={t} />
          </>
        </Modal>
        {/* END - file detail modal */}
      </>
    );
  } else if (billsReceive === null && loading === true) {
    return (
      <>
        <div className="table-default bills-receive-table loading not-found">
          <div className="table-container">
            <div className="d-flex justify-content-between">
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default bills-receive-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableGroupFiles;
