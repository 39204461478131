import React, { useEffect, useState } from 'react';

import api from '../../../../../services/api';

import EditSuppliersDataFields from '../EditSuppliersDataFields/EditSuppliersDataFields';
//import { Link } from 'react-router-dom';


//STYLE
import './EditSuppliersData.scss'

export interface propInfo {
    info?: any;
};

const EditSuppliersData: React.FC<propInfo> = ({
    info
}: propInfo) => {

    const [editSuppliers, setEditSuppliers] = useState<any>(null);
    const idSup = window.location.href.split('id=')[1];
    

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditSupplier = async () => {
            try {
                const { data } = await api.get(`Supplier/GetSuppliersByIdAsync/${idSup}`, config);
                if (data.status !== 400) {
                    setEditSuppliers(data.data);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
                if (error?.response?.status === 400) {
                    //window.location.href = window.location.origin + '/list-suppliers';
                }
            }
        }
        EditSupplier();
    }, [idSup])   
    

    if (editSuppliers !== null) {
        return (
            <>
                <div className="edit-suppliers-data">
                    <div className="observation">
                        <div className="observation-content">
                            <p>Observação:</p>
                            <p>Dados inválidos</p>
                        </div>
                    </div>
                    <div>
                        <div className="d-flex">
                            <div className="edit">
                                <EditSuppliersDataFields info={editSuppliers} />
                            </div>
                        </div>
                    </div>
                    {/* <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                <div className="btn-sucess">
                    <Modal
                        className="modal-custom modalAuth"
                        show={modalResponse !== null}
                        onHide={() => setModalResponse(null)}
                        size="lg"
                        aria-labelledby="contained-modal-title-vcenter"
                    >
                        <>
                            <Modal.Header closeButton></Modal.Header>
                            <RequestResponseModal name={"Fornecedor editado"} modalResponse={modalResponse} setModalResponse={setModalResponse} errors={errors} />
                        </>
                    </Modal>
                </div>
            </Row> */}


                </div>
            </>
        );
    } else {
        return (
            <></>
        )
    }

}

export default EditSuppliersData;