import React, { useEffect, useState } from "react";
import { Container, Row, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { useTranslation } from "react-i18next";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import '../../../../assets/sass/accordion.scss';

import GetReservationSystems from '../../../../components/C2Points/GetReservationSystems';

export interface propAccordion {
    setChangeEdit: any,
    action: any,
    ContextAwareToggle: any,
    infoEdit: any,
    setStepCtrl: any,
    editSuppliers: any,
    scrollTop?: any,
    isEdit?: any;
};

const AccordionInfoAdd: React.FC<propAccordion> = ({
    setChangeEdit, action, ContextAwareToggle, infoEdit, setStepCtrl, editSuppliers, scrollTop, isEdit = false
}: propAccordion) => {
    const { t } = useTranslation();
    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);

    const idSup = window.location.href.split('id=')[1]?.replace(/#/g, '');

    const {
        control,
        handleSubmit,
        formState: { errors },
        watch,
        setValue,
        getValues
    } = useForm();

    const watchReservations = watch(`reservationSystems`, null);
    const watchReservationsYesOrNo = watch(`reservationSystem`, null);

    const onSubmit = (data: any) => {
        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            let obj: any = { ...data };
            obj.integrationApiKey = data?.reservationSystems?.value === "6" ? data.integrationApiKey : "";
            /* data.integrationUrl = data.integrationUrl;
            data.reservationSystem = data.reservationSystem; */
            obj.reservationSystems = data?.reservationSystems?.value || '1'; 

            setCtrlEventKey(true);

            action(obj);
        } else {
            /// EDIT SUPPLIERS PAGE

            /* window.location.href = window.location.origin + '/suppliers/edit-suppliers?id=' + idSup;
            const aux: any = data;  //"aux" usado para garantir que todos os outros campos de informação do fornecedor sejam mantidos
            data = info; */
            let edit: any = { ...infoEdit, ...data };
            edit.supplierId = parseInt(idSup);

            edit.supplierContactModel = edit.supplierContact;
            edit.usersSupplierModel = edit.usersSupplierDTO?.map((elem: any) => { return { "userId": elem.userId } });
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;
            edit.supplierByResourceModel = edit.supplierByResourceDTO;
            edit.supplierVehicleTypeModel = edit.supplierVehicleTypeDTO;

            edit.reservationSystem = edit.reservationSystem ? parseInt(edit.reservationSystem) : "";
            edit.reservationSystems = data?.reservationSystems?.value || 1;

            //remover
            edit.tarTipo = edit.tarTipo === null ? 1 : edit.tarTipo
            edit.masterFilho = edit.masterFilho === null ? 1 : edit.masterFilho
            //[end] remover

            editSuppliers(edit);
        }
    };

    function handleCancel() {
        window.location.reload();
    }

    useEffect(() => {
        if (isEdit) {
            setValue('reservationSystem', infoEdit ? `${infoEdit.reservationSystem}` : '');
            setValue('reservationSystems', infoEdit ? { value: infoEdit.reservationSystems, label: infoEdit.reservationSystemsDesc } : '');
            setValue('integrationUrl', infoEdit ? infoEdit.integrationUrl : '');
            setValue('integrationApiKey', infoEdit ? infoEdit.integrationApiKey : '');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <Card className="bg-white mt-4" id="editAdditionalInfoForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionInfoAdd.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Row>
                            <div>
                                <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6">
                                            <Form.Label>{t('accordionInfoAdd.hasReserve?')}?</Form.Label>
                                            <Controller
                                                control={control}
                                                name="reservationSystem"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        aria-invalid={errors?.reservationSystem ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        type="radio"
                                                        name="radioReservation"
                                                        id="reservationSystem-1"
                                                        label={t('accordionInfoAdd.radio.yes')}
                                                        checked={getValues()?.reservationSystem === '1'}
                                                        value="1"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name="reservationSystem"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        aria-invalid={errors?.reservationSystem ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        type="radio"
                                                        name="radioReservation"
                                                        id="reservationSystem-2"
                                                        label={t('accordionInfoAdd.radio.no')}
                                                        checked={getValues()?.reservationSystem === '2'}
                                                        value="2"
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name="reservationSystem"
                                                rules={{ required: { value: true, message: t('accordionInfoAdd.validation.radio') } }}
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        aria-invalid={errors?.reservationSystem ? "true" : ""}
                                                        fullWidth
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        type="radio"
                                                        name="radioReservation"
                                                        id="reservationSystem-3"
                                                        label={t('accordionInfoAdd.radio.idontknow')}
                                                        checked={getValues()?.reservationSystem === '3'}
                                                        value="3"
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="reservationSystem"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <Form.Group as={Col} md="6" controlId="formInfoAddSystem">
                                            <Form.Label>{t('accordionInfoAdd.sisReserve')}:</Form.Label>
                                            <Controller
                                                control={control}
                                                name="reservationSystems"
                                                rules={{ required: { value: watchReservationsYesOrNo === '1', message: 'Por favor, selecione uma das opções' } }}
                                                render={({ field }: any) => (
                                                    <GetReservationSystems
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        defaultValue={infoEdit?.reservationSystems}
                                                        setValue={setValue}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="reservationSystems"
                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                            />
                                        </Form.Group>
                                    </Row>
                                    <Row className="mb-3">
                                        <div className="col-md-6">
                                            <Row>
                                                <Form.Group as={Col} md="12" controlId="formInfoAddEndPoint" className="mb-3">
                                                    <Form.Label>{t('accordionInfoAdd.endSuppliers')}:</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="integrationUrl"
                                                        //rules={{ required: { value: true, message: t('accordionInfoAdd.validation.global') } }}
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                label={t('accordionAccount.endSuppliers')}
                                                                //aria-invalid={errors?.integrationUrl ? "true" : ""}
                                                                fullWidth
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete='off'
                                                            //required={true}
                                                            />
                                                        )}
                                                    />
                                                </Form.Group>
                                                {
                                                    watchReservations?.value === "6"
                                                        ?
                                                        <Form.Group as={Col} md="12" controlId="formInfoAddAPI">
                                                            <Form.Label>{t('accordionInfoAdd.intAPIKey')}:</Form.Label>
                                                            <Controller
                                                                control={control}
                                                                name="integrationApiKey"
                                                                rules={{ required: { value: true, message: t('accordionInfoAdd.validation.global') } }}
                                                                render={({ field }: any) => (
                                                                    <Form.Control
                                                                        {...field}
                                                                        label={t('accordionAccount.intAPIKey')}
                                                                        aria-invalid={errors?.integrationApiKey ? "true" : ""}
                                                                        fullWidth
                                                                        variant="standard"
                                                                        margin="normal"
                                                                        required
                                                                        autoComplete='off'
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="integrationApiKey"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                        :
                                                        <></>
                                                }
                                            </Row>
                                        </div>
                                    </Row>
                                    <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                        {
                                            isEdit === false
                                                ?
                                                <Button
                                                    type="button"
                                                    className="form-button back-btn btn btn-outline-primary"
                                                    variant='outline'
                                                    onClick={() => {
                                                        setStepCtrl(4)
                                                        scrollTop()
                                                    }}
                                                >
                                                    voltar
                                                </Button>
                                                :
                                                <Button
                                                    className="form-button cancel"
                                                    onClick={handleCancel}
                                                >
                                                    Cancelar
                                                </Button>
                                        }
                                        <Button
                                            type="submit"
                                            className="form-button"
                                            onClick={scrollTop}
                                        >
                                            {t('accordionInfoCompany.buttonContinue')}
                                        </Button>
                                    </Row>
                                </Form>
                            </div>
                        </Row>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionInfoAdd;