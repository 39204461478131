import React from 'react';
import { Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { useAppSelector } from "../../../store/hooks";


export interface propForm {
    control: any,
    errors: any,
    t?:any
};

const PaymentCheckoutCash: React.FC<propForm> = ({
    control, errors, t
}: propForm) => {
    const cartSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let todayFoPayment = dd + '/' + mm + '/' + yyyy;

    return (
        <>
            <h3>{t("checkout.paymentCheckout.cash.money")}</h3>

            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCashAmount">
                    <Form.Label>{t("checkout.paymentCheckout.cash.paidValue")}</Form.Label>
                    <div>{cartSIG.totalCart?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCashDate">
                    <Form.Label>{t("checkout.paymentCheckout.cash.date")}</Form.Label>
                    <div>{todayFoPayment}</div>
                </Form.Group>
            </Row>
        </>
    );
}

export default PaymentCheckoutCash;