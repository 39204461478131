import React from 'react';
import { Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { Controller } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";

export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    t?:any
};

const PaymentCheckoutDebit: React.FC<propForm> = ({
    control, errors, setValue, t
}: propForm) => {

    /* const onSubmit = (data: any) => {
    }; */

    return (
        <>
            <h3>{t("checkout.paymentCheckout.debit.debitCard")}</h3>

            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDebitNSU">
                    <Form.Label>{t("checkout.paymentCheckout.debit.sequentialNumber")}</Form.Label>
                    <Controller
                        control={control}
                        name="nsu"
                        rules={{ required: { value: true, message: t("checkout.paymentCheckout.debit.sequentialNumberError") } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="nsu"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDebitDate">
                    <Form.Label>{t("checkout.paymentCheckout.debit.date")}</Form.Label>
                    <Controller
                        control={control}
                        name="debitDate"
                        rules={{ required: { value: true, message: t("checkout.paymentCheckout.debit.dateError") } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="debitDate"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
            </Row>
        </>
    );
}

export default PaymentCheckoutDebit;