/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";

import { Container, Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Modal from "react-bootstrap/Modal";

import TableBillReceiveEditItems from "./TableBillReceiveEditItems/TableBillReceiveEditItems";
import TableBillReceiveEditBills from "./TableBillReceiveEditBills/TableBillReceiveEditBills";
import FormBillReceivableLog from "./FormBillReceivableLog/FormBillReceivableLog";

import "../../../assets/sass/modal.scss";
import "./FormBillReceiveEdit.scss";
import api from "../../../services/api";
import ModalQuestion from "../../Modal/ModalQuestion/ModalQuestion";

export interface propModal {
  action: any,
  info: any,
  erp?: any,
};

const FormBillReceiveEdit: React.FC<propModal> = ({
  action, info, erp = 2
}: propModal, props: any) => {
  const [logModalShow, setLogModalShow] = React.useState(false);
  const [logReloc, setLogReloc] = React.useState('');
  const [item, setItem] = useState<any>(null);
  const [billExportErpModalShow, setBillExportErpModalShow] = useState<any>(false);

  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [loading, setLoading] = useState<any>(false);

  const modalReservationClick = (e: any) => {
    //setLogReloc(info.localizador);
    //setLogModalShow(true);
  }

  const modalLogClick = (e: any) => {
    setLogReloc(info.localizador);
    setLogModalShow(true);
  }

  function handleActionLogHideModal() {
    //() => setBillEditModalShow(false)
    setLogModalShow(false);
    setLogReloc('');
  }

  async function refreshData() {
    setLoading(true);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
        headers: { 'Authorization': `Bearer ${token}` },
    };

    try {
        const { data } = await api.get(`BillsReceivable/GetOrderInfo/${info.localizador}`, config);

        if (data.status !== 400) {
          const dados = data.data;

          // show modal
          setItem(dados);
          //setBillEdit(dados);
          //setBillEditModalShow(true);
          setLoading(false);
        }
    } catch (error: any) {
        if (error?.response?.status === 401) {
            window.location.href = window.location.origin + '/';
        }
    }
    
  }

  function convertDate(cell: any) {
    //var date = (cell) ? convertUTCDateToLocalDate(new Date(cell)) : '';
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  /* Forma de Pagamento: Cash | PIX | Transferência | Cartão |   poderão ser exportados a qualquer tempo
    Outras  formas de Pagamento:  Faturado | Comissão a Receber |  só poderão ser exportados  após D+1 da data de operação
  */
  function canExportErp() {
    if(item) {
      if(item.statusErp === 2) {
        return false;
      } else {
        //#VerificarBruno
        // if(item.exportaErp === 1) {
        //   return true;
        // } else if(item.exportaErp === 3) {

        //   const ano  = parseInt( item.dataOperacao.substring(0,4) );
        //   const mes  = parseInt( item.dataOperacao.substring(5,7) );
        //   const dia  = parseInt( item.dataOperacao.substring(8,10) );
            
        //   const dataOperacaoD = new Date(ano,mes-1,dia,0,0,0);
        //   const today = new Date();

        //   const days = (today.getTime() - dataOperacaoD.getTime()) / (1000*60*60*24);

        //   if(days >= 1) {
        //     return true;
        //   } else {
        //     return false;
        //   }

        // } else {
        //   return false;
        // }
        return true;
        
      }
    } else {
      return false;
    }
  }

  function exportarErpClick() {
    // console.log(info);
    //setBillEdit(info);
    setBillExportErpModalShow(true);
  }

  function handleActionBillExportErpModal() {
    setBillExportErpModalShow(false);

    //const info = billEdit;
    //setBillEdit(null);

    setModalContent('loading');
    setModalSuccess(true);
    
    async function settleQuery() {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {
        const res = await api.post(`/Checkout/EnvioContasReceberRM?booking=${item.localizador}`, {}, config);

        if (res.status !== 400) {
          //const dados = res.data.data;
          const dados = res.data;

          setLog(dados.log);

          if (dados.log === 0) {
            setModalContent('success');
            setResponseText('Enviado com sucesso ao ERP!');
          }
          else if (dados.log === 1) {
            setModalContent('error');
            setResponseText(dados.texto);
          }
        }
      } catch (error: any) {
        //console.log(error)
        console.log(error.response)
        if (error?.response?.status === 401) {
            //window.location.href = window.location.origin + '/';
        }
      }
    }

    settleQuery();
  }

  useEffect(() => {
    refreshData();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [info]);

  //if(item != null) {
    return (
      <>
        <Modal.Body>
          <Container className="bg-modal-contact p-0" fluid>
            <Row>
              <Row className="contact-title mb-3">
                <div className="col-md-7 d-flex">
                  <div className="col-md-3">
                    <h6>Localizador</h6>
                    {loading !== true && item !== null ?
                    <p>
                      {item.localizador === '' ?
                      <Button variant="link" className="btn p-0"  onClick={modalReservationClick}>{item.localizador}</Button>
                      :
                      <span className="py-2">{item.localizador}</span>
                      }
                      <Button variant="link" className="btn p-0 ml-1" onClick={modalLogClick}>
                        <FontAwesomeIcon
                          icon={["fal", "history"]}
                          size="1x"
                        />
                      </Button>
                    </p>
                    : <p className="mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></p>}
                  </div>
                  <div className="col-md-4">
                    <h6>Ref. Agente</h6>
                    {loading !== true && item !== null ?
                    <p className="py-1">{item.referenciaAgente}</p>
                    : <p className="mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></p>}
                  </div>
                  {item !== null && item.localizadorRezdy !== '' && loading !== true ? 
                  <div className="col-md-3">
                    <h6>Loc. Rezdy</h6>
                    <p><a className="btn p-0" href={"https://app.rezdy.com/orders/edit/" +item.localizadorRezdy} target="_blank" rel="noreferrer">{item.localizadorRezdy}</a></p>
                  </div>
                   :
                   <div className="col-md-3"></div>
                   } 
                  <div className="col-md-2">
                    <h6>Reserva</h6>
                    {loading !== true  && item !== null ?
                    <p className="py-1">{convertDate(item.dataReserva)}</p>
                    : <p className="mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></p>}
                  </div>
                </div>
                <div className="col-md-2">
                  <h6>Agente</h6>
                  {loading !== true  && item !== null ?
                  <p className="py-1">{item.agente}</p>
                  : <p className="mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></p>}
                </div>
                <div className="col-md-3">
                  <h6>Cliente</h6>
                  {loading !== true && item !== null ?
                  <p className="py-1">{item.cliente}</p>
                  : <p className="mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></p>}
                </div>
                {/*<div className="col-md-4 d-flex">
                  
                   <div className="col-md-4">
                    <h6>Status</h6>
                    {loading !== true && item !== null ?
                    <p>
                      <div
                        className={"w-100 "+
                          (item.statusReserva === 'Confirmada'
                            ? "bg-ativo"
                            : item.statusReserva === 'Cancelada'
                              ? 'bg-inativo'
                              : item.statusReserva === 'Pendente'
                                ? "bg-yellow"
                                : "bg-blue")
                        }
                      >
                        {item.statusReserva}
                      </div>
                    </p>
                    : <p className="mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></p>}
                  </div> 
                </div>*/}
                
              </Row>
              <Form
                className="form-add-contact"
                noValidate
              >
                <div className="">
                  <Row className="border-bottom pb-3 mb-3">
                    <Form.Group as={Col} md="8">
                      {/* itens */}
                      <TableBillReceiveEditItems 
                        info={item ? item.items : []} 
                        loading={loading} 
                        statusErp={item ? item.statusErp : 1} 
                        setModalSuccess={setModalSuccess}
                        setModalResponse={setModalContent}
                        setModalMessage={setResponseText}
                        setLog={setLog}
                      />
                    </Form.Group>
                    <Form.Group as={Col} md="4">
                      {/* totais */}
                      <table className="table table-striped table-bordered">
                        <tbody>
                          <tr><th className="w-50">Total Recebido:</th>{loading !== true && item !== null ? <th>R$ {convertValue(item.totalRecebido)}</th> : <th><div className="m-0 mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></div></th>}</tr>
                          <tr><th className="w-50">Total Serviços:</th>{loading !== true && item !== null ? <th>R$ {convertValue(item.totalServicos)}</th> : <th><div className="m-0 mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></div></th>}</tr>
                          <tr><th className="w-50">Saldo:</th>{loading !== true && item !== null ? <th>R$ {convertValue(item.totalSaldo)}</th> : <th><div className="m-0 mr-2 text-primary title-reservation animated-background" style={{height: "1.5rem"}}></div></th>}</tr>
                        </tbody>
                      </table>
                      <Form.Group as={Col} md="12">
                      
                        {/* <button type="button" className="btn btn-outline-primary" disabled={!canExportErp()} onClick={() => exportarErpClick()}><FontAwesomeIcon icon={['fal', 'share']} /> Exportar ERP</button> */}
                       
                      </Form.Group>
                    </Form.Group>
                  </Row>
                  <Row className="mb-3">
                    <Form.Group as={Col} md="12">
                      {/* pagamentos */}
                      <TableBillReceiveEditBills 
                        info={item ? item.bills : []} 
                        refreshData={refreshData}
                        dataOperacao={item ? item.dataOperacao : ''} 
                        estrangeiro={item ? item.estrangeiro : 2} 
                        documentoCliente={item ? item.documentoCliente : ''} 
                        setModalSuccess={setModalSuccess}
                        setModalResponse={setModalContent}
                        setModalMessage={setResponseText}
                        setLog={setLog}
                        reserva={item ? item.localizador : ''}
                        cliente={item && item.items && item.items.length > 0 ? item.items[0].agenteIdentity : ''}
                        loading={loading}
                        statusErp={item ? item.statusErp : 1}
                        registerCard={item ? item.registerCard : 2}
                      />
                    </Form.Group>
                  </Row>
                </div>
              </Form>
            </Row>

            {logReloc !== null? 
            <Modal
            className="modalReceber"
            show={logModalShow}
            onHide={handleActionLogHideModal}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
          >
            <>
              <Modal.Header className="p-3" closeButton></Modal.Header>
              <FormBillReceivableLog localizador={logReloc} />
            </>
          </Modal>: <></>}
          </Container>
        </Modal.Body>
        {/* Modal de Success */}
        <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => { if(modalContent !== 'loading') { if(modalContent === 'success') {refreshData();} setModalSuccess(false); } } }
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em" }}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em"}}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em" }}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={() => {refreshData(); setModalSuccess(false);}}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          // modalContent === 'warning'
                            // ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            // :
                            // <button
                            //   onClick={() => setModalShow(false)}
                            //   className="btn btn-primary mx-2 w-25"
                            // >
                            //   Confirmar
                            // </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}

        <ModalQuestion
          modalShow={billExportErpModalShow}
          setModalShow={setBillExportErpModalShow}
          titulo="Exportar para ERP"
          mensagem="Confirma os valores a serem exportados ?"
          handleActionConfirmModal={handleActionBillExportErpModal}
          //handleActionCancelModal={handleActionCancelDeleteClick}
        />

        {/* <Modal.Footer>
          <Row className="buttons-modal d-flex justify-content-end mx-0">
            <Button
              type="button"
              className="form-button"
              onClick={onSubmitContact}
            >
              <FontAwesomeIcon
                icon={["fal", "save"]}
                size="1x"
                style={{ marginRight: "5px" }}
              /> Salvar
            </Button>
          </Row>
        </Modal.Footer> */}


      </>
    );
  // } else {
  //   return(<></>);
  // }
}

export default FormBillReceiveEdit;
