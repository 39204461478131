import React, { useEffect } from "react";

import { Controller, useForm } from "react-hook-form";

import Form from 'react-bootstrap/Form';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';

//STYLES
import "./TableSettleBillsReceive.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import ModalDefault from "../../../../components/Modal/ModalDefault/ModalDefault";
/* import DebitNotes from "../../../../components/Modal/ModalDefault/DebitNotes/DebitNotes";
import Payment from "../../../../components/Modal/ModalDefault/Payment/Payment"; */
import SettleBillsToReceive from "../../../../components/Modal/ModalDefault/SettleBillsToReceive/SettleBillsToReceive";

export interface propTable {
  billsReceive: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportBills?: any;
  printBills?: any;
  parameters: any;
  loading: any;
  selectedCheck: any;
  setSelectedCheck: any;
  itemsToSettle: any;
  setItemsToSettle: any;
}

const TableSettleBillsReceive: React.FC<propTable> = ({
  billsReceive, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportBills, printBills, parameters, loading
  , selectedCheck, setSelectedCheck, itemsToSettle, setItemsToSettle
}: propTable) => {

  
  


  //const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  //const userName: any = tourAuth.user.nome;
  //const userLastName: any = tourAuth.user.lastName;
  //const uId: any = tourAuth.user.uId;

  const {
    control,
  } = useForm();

  /*converte a data UTC para a data local do usuário*/
  /* function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  } */

  function convertDate(cell: any, row: any) {
    //var date = convertUTCDateToLocalDate(new Date(cell));
    if (cell !== null) {
      var aux: any = cell.split("T")[0].split("-");
      var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
      return (
        <>
          {date}
        </>
      );
    } else {
      return (
        <>
        </>
      )
    }
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function convertValue(cell: any) {
    if (cell !== null) {

      return (
        <>
          <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  }

  function formatValue(cell: any, row: any) {
    if (cell !== null) {

      return (
        <>
          <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")} {row.sufixovalor}</span>
        </>
      )
    } else {
      return (
        <>
        </>
      )
    }
  }

  function textReduce(cell: any, row: any) {
    if (cell !== null) {
      var aux = cell.split(' ')
      if (aux.length >= 4) {
        aux = aux[0] + ' ' + aux[1] + ' ' + aux[2] + ' ' + aux[3] + '...';
      } else {
        aux = cell;
      }

      return (
        <>
          <OverlayTrigger
            overlay={
              <Tooltip id="tooltip">{cell}</Tooltip>
            }>
            <span className="text-wrap-custom">
              {cell}
            </span>
          </OverlayTrigger>
        </>
      )
    } else {
      return (
        <>

        </>
      )
    }
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-analise"
            : cell === "Confirmada"
              ? "bg-ativo"
              : cell === "Cancelada"
                ? "bg-inativo"
                : ""
        }
      >
        {cell}
      </div>
    );
  }

  function handleClick(cell: any) {
    const getBills = billsReceive.find((element: { identity: any; }) => element.identity === cell);
    setSelectedCheck((prevState: any) => [...prevState, getBills]);
  }

  useEffect(() => {
    if (selectedCheck.length > 1) {
      var lastOne: any = selectedCheck[selectedCheck.length - 1];
      var aux: any = [];
      var add: boolean = true;

      for (var i: any = 0; i < itemsToSettle.length; i++) {
        if (itemsToSettle[i].identity !== lastOne.identity) {
          aux.push(itemsToSettle[i]);
        } else {
          add = false;
        }
      }

      if (add === true) {
        setItemsToSettle((prevState: any) => [...prevState, lastOne]);
      } else {
        setItemsToSettle(aux);
      }

    } else if (selectedCheck.length === 1) {
      setItemsToSettle(selectedCheck);
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [/*itemsToSettle,   */selectedCheck])

  function confereChecked(cell: any) {
    for (var i: any = 0; i < itemsToSettle.length; i++) {
      if (itemsToSettle[i].identity === cell) {
        return true;
      }
    }
    return false;
  }

  function addCheckButton(cell: any, row: any) {
    return (
      <>
        <div className="center-table">
          <Controller
            control={control}
            name={'' + cell}
            render={({ field }: any) => (
              <Form.Check
                {...field}
                fullWidth
                variant="standard"
                margin="normal"
                type="checkbox"
                defaultChecked={confereChecked(cell)}
                value={cell}
                onClick={() => { handleClick(cell) }}
              />
            )}
          />
        </div>
      </>
    )
  }

  function addPayment(cell: any, row: any) {
    return (
      <div
        className={
          cell === "Pendente"
            ? "bg-inativo"
            : cell === "Recebido"
              ? "bg-ativo"
              : "bg-analise"
        }
      >
        {cell}
      </div>
    );
  }

  /* loading table */
  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando contas", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const columns = [
    {
      dataField: 'identity',
      text: ``,
      formatter: addCheckButton
    },
    {
      dataField: "vencimento",
      text: "Vencimento",
      sort: true,
      formatter: convertDate,
    },
    { dataField: "formaPagamento", text: "Forma de Pagamento", sort: true },
    { dataField: "nFatura", text: "Nº Fatura" },
    { dataField: "agente", text: "Agente", sort: true },
    {
      dataField: "dataReserva",
      text: "Data Reserva",
      sort: true,
      formatter: convertDate,
    },
    {
      dataField: "dataOperacao",
      text: "Data Operação",
      sort: true,
      formatter: convertDate,
    },
    {
      dataField: "tour",
      text: "Tour",
      sort: true,
      formatter: textReduce,
    },
    {
      dataField: "cliente",
      text: "Cliente",
      sort: true,
      formatter: textReduce,
    },
    {
      dataField: "valor",
      text: "Valor",
      sort: true,
      formatter: formatValue,
    },
    {
      dataField: "statusReserva",
      text: "Status Reserva",
      sort: true,
      formatter: addStatus,
    },
    {
      dataField: "statusPagamento",
      text: "Status Pagamento",
      sort: true,
      formatter: addPayment,
    },
    //{ dataField: "id", text: "Ações", formatter: addActionButton },
  ];


  //console.log(props)

  /* [END] ALTERAR OS DATAFIELDS QUANDO A API CHEGAR */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "clientes", text: "Clientes" }];

  const notFoundProducts = [{ clientes: "Nenhum cliente cadastrado" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.statusPagamento === "Conciliado") {
      return 'border-analise';
    } else if (row.statusPagamento === "Pago" || row.statusPagamento === "Recebido") {
      return 'border-ativo';
    } else {
      return 'border-inativo';
    }
  };

  if (billsReceive !== null) {
    return (
      <>
        <div className="table-default bills-receive-table settle-receive">
          <div className="table-container px-4">
            <div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={billsReceive}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />
            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />
          </div>
        </div>
        <div className="d-flex justify-content-end">

          <ModalDefault
            title="Liquidar em Lote"
            name="Liquidar"
            classBtn="btn-default text-uppercase btn btn-primary mt-3"
            classModal="modal-large"
          >
            <SettleBillsToReceive itemsToSettle={itemsToSettle} parameters={parameters} />
          </ModalDefault>
        </div>
      </>
    );
  } else if (billsReceive === null && loading === true) {
    return (
      <>
        <div className="table-default bills-receive-table loading not-found">
          <div className="table-container">
            <div style={{ padding: "20px 10px 5px" }}>
              <BootstrapTable
                bootstrap4
                keyField="id"
                data={loadingProducts}
                hover={true}
                columns={loadingColumns}
                striped={true}
              />
            </div>
          </div>
        </div>
      </>
    );
  } else {
    return (
      <>
        <div className="table-default bills-receive-table not-found">
          <div className="table-container">
            {/*             <div className="table-title">
              <h3 className="">Fornecedores</h3>
            </div> */}

            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableSettleBillsReceive;
