import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Col, Form } from "react-bootstrap";
import { Controller } from "react-hook-form";
import ReactQuill from "react-quill";
import './ReactQuillComponent.scss';

export interface textarea {
  isEdit?: boolean;
  size: any;
  handleBlur?: any;
  numberOfCaracters?: any;
  minLength?: any;
  maxLength?: any;
  state?: any;
  setState?: any;
  control: any;
  errors: any;
  name: any;
  setValue: any;
  style?: any
  title: string;
  haveToolbar?: boolean
  id?: string;
  counter?: boolean;
  required?: boolean
  disabled?: boolean
};

const ReactQuillComponent: React.FC<textarea> = React.memo(({
  isEdit, handleBlur, size, numberOfCaracters = false, minLength, maxLength, state, setState, control, errors, name, setValue, style, title, haveToolbar = true, id, counter = true, required = true, disabled = false
}: textarea) => {
  /* Controle Quill */
  const modules: any = {
    toolbar: [["bold"]],
  };

  const formats = ["bold"];
  /* Controle Quill */

  // State to track character count including HTML tags
  const [charCount, setCharCount] = useState<number>(0);

  const handleChange = (e: any) => {
    let field = e;

    // Remove all tags if the user deletes all text
    if (e.replace(/<[^>]*>?/gm, '').trim() === '') {
      field = '';
    } else {
      // Ensure the content is wrapped in <p> tags
      if (!e.startsWith('<p>')) {
        field = `<p>${e}</p>`;
      }
    }

    setState(field);
    setValue(name, field);
    setCharCount(field.length);  // Update character count including HTML tags
  };

  useEffect(() => {
    const haveToolBar = () => {
      let qlEditor: any = document.querySelector(".ql-editor");
      let qlToolbar: any = document.querySelector(".ql-toolbar");
      if (haveToolbar === false) {
        qlToolbar?.classList.add("d-none");
        qlEditor?.classList.add("pt-2");
      } else {
        qlToolbar?.classList.remove("d-none");
        qlEditor?.classList.remove("pt-2");
      }
    }
    haveToolBar();
  }, [haveToolbar])

  useEffect(() => {
    // Ensure the initial state is wrapped in <p> tags
    let initialState = state || '';
    if (initialState && !initialState.startsWith('<p>')) {
      initialState = `<p>${initialState}</p>`;
      setState(initialState);
    }
    setCharCount(initialState.length);  // Initialize character count on component mount
  }, [state]);

  return (
    <Form.Group as={Col} md={size} controlId="" className="mt-4">
      <Form.Label className="label-small d-flex justify-content-between">
        <span>{title}</span>
        {counter === true &&
          <span style={{ fontWeight: 400 }}>
            {`${charCount}/${maxLength} caracteres`}
          </span>}
      </Form.Label>

      <Controller
        control={control}
        name={name}
        defaultValue={''}
        rules={{
          required: {
            value: required,
            message:
              "Por favor, informe um resumo para o produto",
          },
          minLength: {
            value: minLength,
            message: `Por favor, insira ao menos ${minLength} caracteres`,
          },
          maxLength: {
            value: maxLength,
            message: `Por favor, insira no máximo ${maxLength} caracteres`,
          },
        }}
        render={({ field }: any) => (
          <ReactQuill
            {...field}
            id={id}
            className="react-modal-control-quill"
            theme="snow"
            formats={formats}
            modules={modules}
            placeholder="Digite um texto..."
            value={state}
            style={style}
            onChange={(e: any) => {
              handleChange(e);
            }}
            onBlur={handleBlur}
            readOnly={disabled}
          />
        )}
      />
      <div className="d-flex justify-content-between">

        <ErrorMessage
          errors={errors}
          name={name}
          render={({ message }) => (
            <small style={{ color: "red" }}>{message}</small>
          )}
        />
        {counter === true && <small>Mínimo {minLength} caracteres</small>}
      </div>
    </Form.Group>
  )
})

export default ReactQuillComponent;
