import React, { useEffect, useState } from "react";

import "../../../../../../assets/sass/modal.scss";
import "./DecolarProducts.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Col, Container, Form, InputGroup, Row, Modal, Table } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";
//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../../services/api';

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import Pagination from "../../../../../../components/Pagination/Pagination";
import BootstrapTable from "react-bootstrap-table-next";
import ModalQuestion from "../../../../../../components/Modal/ModalQuestion/ModalQuestion";

export interface propModal {
  //importId: any,
  //setModalShow: any,
  show: any,
  setResponseText: any,
  setModalContent: any,
  setLog: any
};

const DecolarProducts: React.FC<propModal> = ({
  //setModalShow ,
  show, setResponseText, setModalContent, setLog
}: propModal, props: any) => {
  const [pickupList, setPickupList] = useState<any>(null);
  //const [messageToSend, setMessageToSend] = useState<any>('');

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  /* END - Pagination */

  const [loading, setLoading] = useState<any>(false);

  const [deleteConfirmModalShow, setDeleteConfirmModalShow] = useState<any>(false);
  const [selectedProductIndex, setSelectedProductIndex] = useState<any>(null);

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando produtos", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  const {
    control,
    handleSubmit,
    setValue,
    getValues
  } = useForm();

  const getSessionMessages = async (tipo: any = getValues().tipo, resetPagination: boolean = false) => {

    setLoading(true);
    const token = localStorage.getItem('GroupId') || '';

    if (resetPagination) {
      setPageCount(1);
    }

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {

      const { data } = await api.post(`/Decolar/GetProductsDecolarReportAsync`, {
        "page": resetPagination ? 1 : pageCount,
        "rowsPerPage": rowsPerPage,
        "tipo": tipo
      }, config);
      if (data.status !== 400) {
        const response = data.data;
        const items = response.rows;

        for (let i = 0; i < items.length; i++) {
          if (items[i].embarkingType === 1) {
            items[i].pickup = '';
          } else {
            items[i].pickup = items[i].meetingPoint;
          }
        }

        setTotalRows(response.rowsCount);
        setPickupList(items);

      }
      setLoading(false);
    } catch (error: any) {
      if (error?.response?.status === 401) {
        window.location.href = window.location.origin + '/';
      }
      if (error?.response?.status === 400) {
        //window.location.href = window.location.origin + '/session-closure';              
      }
      //;
    }

  }

  useEffect(() => {

    getSessionMessages();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage]);

  function salvar(index: any) {
    setSelectedProductIndex(index);
    setDeleteConfirmModalShow(true);
  }

  function handleActionDelete() {
    setDeleteConfirmModalShow(false);
    deleteProduct(selectedProductIndex);
  }

  function deleteProduct(index: any) {
    const data = pickupList[index];

    ;

    async function settle() {
      show(true);
      setModalContent("loading");

      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
      };
      try {

        const res = await api.post('/Decolar/DeleteProductDecolarAsync',
          {
            "id": data.id
            //"uid": uId,
            //"usuario": (userName !== null && userLastName !== null) ? `${userName} ${userLastName}` : userName
          }, config
        );
        if (res.status !== 400) {
          setResponseText(res.data.data.texto);
          setLog(res.data.data.log);

          if (res.data.data.log === 0) {
            setModalContent('successNoRefresh');
          }
          else if (res.data.data.log === 1) {
            setModalContent('error');
          }
          getSessionMessages();
        }
      } catch (error: any) {

        if (error?.response?.status === 401) {
        }
      }
    }

    settle();
  }

  const onSubmit = (data: any) => {
    getSessionMessages(data.tipo, true)
  }

  if (pickupList !== null) {
    return (
      <>
        <Modal.Body className="p-0 decolar-products">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="h-100 flex-column decolarproducts-table">
              <div className="table-container">
                <div>
                  <Row as={Col} md="12">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Col md="12" className="d-flex justify-content-md-start">
                        <Form.Group as={Col} md="4" controlId="validationCustom01">
                          <Form.Label>Termo:</Form.Label>
                          <Controller
                            control={control}
                            name="tipo"
                            defaultValue={'0'}
                            render={({ field }: any) => (
                              <>
                                <div className="endpoint">
                                  <InputGroup hasValidation>
                                    <Form.Select
                                      {...field}
                                      aria-describedby="inputGroupPrepend"
                                    >
                                      <option value="0">Todos</option>
                                      <option value="1">Tour</option>
                                      <option value="2">Transfer</option>
                                    </Form.Select>
                                  </InputGroup>
                                </div>
                              </>
                            )}
                          />
                        </Form.Group>
                        <Col md="8" className="d-flex justify-content-md-end align-items-end">
                          <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" style={{ height: '36px', padding: '0px' }} onClick={() => { setValue('tipo', '0'); getSessionMessages('0', true); }}>Limpar</Button>
                          <Button type="submit" className="btn btn-primary btn-default text-uppercase" style={{ height: '36px', padding: '0px' }}>Buscar</Button>
                        </Col>
                      </Col>
                    </Form>
                  </Row>
                </div>
                <hr className="d-block" style={{ margin: '30px 0px' }} />

                {loading === false ?
                  <>
                    <Table striped >
                      <thead>
                        <tr>
                          <th>Modalidade</th>
                          <th>Id Decolar</th>
                          <th>Ações</th>
                        </tr>
                      </thead>
                      <tbody>
                        {pickupList.length > 0 ? pickupList.map((item: any, index: any) => (
                          <tr key={index}>
                            <td>{item.modalidade}</td>
                            <td>{item.idDecolar}</td>
                            <td>
                              <span className='btn' style={{ height: "40px" }} onClick={(e: any) => salvar(index)}>
                                <FontAwesomeIcon
                                  size="lg"
                                  icon={["fal", "trash-alt"]}
                                  style={{ color: "#707070" }}
                                  title="Excluir"
                                />
                              </span>
                            </td>
                          </tr>
                        ))
                          :
                          <tr><td colSpan={3} className="mb-2 text-center"><span>Nenhum produto encontrado</span></td></tr>}
                      </tbody>
                    </Table>
                    <Pagination
                      totalRows={totalRows}
                      pageCount={pageCount}
                      setPageCount={setPageCount}
                      rowsPerPage={rowsPerPage}
                      setRowsPerPage={setRowsPerPage}
                      selectNumberRows="no"
                    />
                  </>
                  :
                  <>
                    <div className="text-center my-5">
                      <div className="load-big primary">

                      </div>
                    </div>
                  </>
                }

              </div>

              {/* <div className="mt-auto">
                <Form
                  className="form-add-contact"
                  noValidate
                >
                  <Row className="d-flex justify-content-center">
                    <Form.Group as={Col} md="4">
                      <button
                        type="button"
                        className="btn btn-primary w-100"
                        onClick={() => setModalShow(false)}
                      >
                        <FontAwesomeIcon
                          icon={["fal", "check"]}
                          size="1x"
                          style={{ marginRight: "5px" }}
                        /> Confirmar
                      </button>
                    </Form.Group>
                  </Row>
                </Form>
              </div> */}
            </Row>
          </Container>
        </Modal.Body>
        <ModalQuestion
          modalShow={deleteConfirmModalShow}
          setModalShow={setDeleteConfirmModalShow}
          titulo=""
          mensagem="Deseja excluir o item?"
          handleActionConfirmModal={handleActionDelete}
        />
      </>
    );
  } else {
    return (
      <>
        <Modal.Body className="p-0">
          <Container className="bg-modal-contact p-0" fluid>
            <Row className="flex-column loading-table">
              <div className="table-container">
                <BootstrapTable
                  bootstrap4
                  keyField="id"
                  data={loadingProducts}
                  hover={true}
                  columns={loadingColumns}
                  striped={true}
                />
                <Pagination
                  totalRows={totalRows}
                  pageCount={pageCount}
                  setPageCount={setPageCount}
                  rowsPerPage={rowsPerPage}
                  setRowsPerPage={setRowsPerPage}
                  selectNumberRows="no"
                />
              </div>
            </Row>
          </Container>
        </Modal.Body>

      </>
    );
  }

}

export default DecolarProducts;
