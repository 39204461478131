import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './ExportERPSales.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterExportERP from './components/FilterExportERP/FilterExportERP';
import TableExportERP from './components/TableBills/TableExportERP';

function ExportERPSales() {
    const [actualDate, setActualDate] = useState<any>([]);
    const [actualStatusErp, setActualStatusErp] = useState<any>(1);

    const [listERP, setListERP] = useState<any>(null);
    const [parameters, setParameters] = useState<any>(null);
    const [totalBillsReceive, setTotalBillsReceive] = useState<any>(0);
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    async function refreshData() {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setListERP(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        async function listBills() {
            try {
                const { data } = await api.post('Manifest/GetListRM',
                    {
                        "page": pageCount,
                        "rowsPerPage": parseInt(rowsPerPage),
                        "statusErp": parseInt(actualStatusErp) || 0,
                        "dataInic": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getDate() - 3 > 0 ? today.getMonth() + 1 : today.getMonth()}-${today.getDate() - 3 > 0 ? today.getDate() - 3 : 28}`,
                        "dataFim": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getDate() - 3 > 0 ? today.getMonth() + 1 : today.getMonth()}-${today.getDate() - 3 > 0 ? today.getDate() - 1 : 28}`
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": parseInt(rowsPerPage),
                        "statusErp": parseInt(actualStatusErp) || 0,
                        "dataInic": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                        "dataFim": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                    });
                    setListERP(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                    setTotalBillsReceive(data.data.valorTotal);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listBills();
    }

    useEffect(() => {
        //if(searchOk === true) 
        refreshData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage]);

    async function buscar(date: any, statusErp: any) {
        setActualDate(date);
        setActualStatusErp(statusErp);
        setPageCount(1)

        setLoading(true);
        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setListERP(null);
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        let beginDate = []
        let endDate = []


        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const res = await api.post('Manifest/GetListRM',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "statusErp": parseInt(statusErp) || 0,
                    "dataInic": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFim": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                }, config
            );
            setLoading(false);
            if (res.status !== 400) {
                setParameters({
                    "page": 0,
                    "rowsPerPage": 0,
                    "statusErp": parseInt(statusErp) || 0,
                    "dataInic": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFim": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                });
                setListERP(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
                setTotalBillsReceive(res.data.data.valorTotal);
            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function exportListERM(loc: string) {
        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Accept": "*/*"
                //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };
        try {
            const exp = await api.get(`Manifest/CreateSendBillsToReceiveRmAsync?Loc=${loc}`, config);
            if (exp.status !== 400) {
                console.log(exp)
            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }

    };

    async function printBills() {

        let beginDate = []
        let endDate = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Accept": "*/*"
                //"Access-Control-Allow-Origin": "*"
            },
            responseType: 'blob'
        };

        try {
            const print = await api.post('/BillsReceivable/PrintReport',
                {
                    "page": 1,
                    "rowsPerPage": 0, // pagina unica
                    "erp": 1,
                    "statusErp": actualStatusErp ? parseInt(actualStatusErp) : null,
                    "agenteIndividual": -1,
                    "dataInicial": (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataFinal": (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`
                },
                config

            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_receber.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);


            }
        } catch (error: any) {
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard export-erp">
                    <Breadcrumb title={'Financeiro / Exportação ERP'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Exportação ERP</h2>
                    </div>
                    <FilterExportERP
                        buscar={buscar}
                    />
                    <TableExportERP
                        listERP={listERP}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportListERM={exportListERM}
                        printBills={printBills}
                        loading={loading}
                        totalBillsReceive={totalBillsReceive}
                        actualDate={actualDate}
                        actualStatusErp={actualStatusErp}
                        parameters={parameters}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    );

}

export default ExportERPSales;