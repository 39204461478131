import React, { useState, Key, useEffect } from "react";
import { Card } from "react-bootstrap";

import { useTranslation } from 'react-i18next';

import Button from "react-bootstrap/Button";
import api from "../../../../services/api";

function ModalProfileCards() {
    const { t } = useTranslation();

    const [selected, setSelected] = useState<any>(null);

    const c2tourAuth: any = JSON.parse(localStorage.getItem("c2tourAuth") || "{}");

    const perfils: any = c2tourAuth.user.perfils;

    function selectCard(e: any) {
        var cards = document.querySelectorAll(".profile-cards");

        cards.forEach((element) => {
            element.classList.remove("selected");
        });

        if (e.target.classList.contains("profile-cards")) {
            e.target.classList.add("selected");
        } else if (e.target.offsetParent.classList.contains("profile-cards")) {
            e.target.offsetParent.classList.add("selected");
        }
    }

    function verifyIsAgent() {
        if (selected !== null) {
            var newProfileObj: any = c2tourAuth;
            newProfileObj.user.perfils = undefined;
            newProfileObj.user.perfil = perfils[selected];

            if ([24, 25, 26, 29].includes(newProfileObj.user.perfil.nivel)) {
                localStorage.setItem('idCanal', newProfileObj.user.perfil.idCanal)
                newProfileObj.user.perfil.isAgent = 1;

                canSeeBills(newProfileObj);

            } else {
                console.log("não agente")
                goToDashboard(newProfileObj);
            }
        }
    }

    async function canSeeBills(newProfileObj: any) {
        const token = newProfileObj.user.perfil.token.token;
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const res = await api.post(`/UserPermission/CanSeeBills`, {}, config);
            if (res.status !== 400) {
                newProfileObj.user.perfil.canSeeBills = res.data.data;
                goToDashboard(newProfileObj);
            }
        } catch (error: any) {
            console.log(error)
        }
    }

    function goToDashboard(newProfileObj: any) {
        newProfileObj.user.perfil.nivel = btoa(`${(newProfileObj.user.perfil.nivel)}`);
        localStorage.setItem("c2tourAuth", JSON.stringify(newProfileObj));
        localStorage.setItem("GroupId", perfils[selected].token.token);
        window.location.href = window.location.origin + '/dashboard';

    }

    function goToLogin() {
        localStorage.removeItem("GroupId");
        localStorage.removeItem("c2tourAuth");
        window.location.href = window.location.origin + '/';
    }

    useEffect(() => {
        async function getIdChannel() {
            try {
                const { data } = await api.get(`${process.env.REACT_APP_SERVER_URL_API}/Products/GetChannelBySource/sig`);
                if (data.statusCode === 200) {
                    localStorage.setItem('idCanal', data.data.data)
                }
            } catch (error) { }
        }
        getIdChannel();
    }, [])

    return (
        <>
            <div className="card-profile">
                <h2 className="text-center">{t("login.profile.welcome")} <strong>{`${c2tourAuth.user.nome} ${c2tourAuth.user.lastName}`}</strong></h2>
                <h5 className="text-center">{t("login.profile.choiceProfile")}</h5>

                <div className="testandoCards">
                    <div style={{ display: "flex", flexWrap: "wrap" }}>
                        {perfils?.length > 0
                            ? perfils.map((elem: any, index: Key) => {
                                return (
                                    <Card
                                        className="profile-cards"
                                        onClick={(e: any) => { selectCard(e); setSelected(index) }}
                                        style={{ padding: "10px" }}
                                        key={index}
                                    >

                                        <img src={process.env.REACT_APP_SERVER_LOGO_SHORT} alt="Marca Destinow" style={{ maxWidth: process.env.REACT_APP_SERVER_LOGO_WIDTH_CARD }} />

                                        <div className="d-flex align-items-center justify-content-evenly flex-column">
                                            <span>
                                                <strong>{elem.companyName}</strong>
                                            </span>
                                            <span className="font-size-10">{elem.userType}</span>
                                        </div>

                                    </Card>
                                )
                            })
                            :
                            <>
                            </>
                        }
                    </div>
                    <div className="mt-5 d-flex justify-content-center" style={{ gap: "20px" }}>
                        <Button className="form-button btn bg-white btn-block btn-login text-primary " onClick={() => { goToLogin() }}>Voltar</Button>
                        <Button className="form-button btn btn-primary btn-block btn-login uppercase" onClick={() => { verifyIsAgent() }}>{t("login.profile.go")}</Button>
                    </div>
                </div>
            </div>
        </>
    );
}

export default ModalProfileCards;
