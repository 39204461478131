import React from 'react';
import './Dashboard.scss';

//COMPONENTS
// import TabSearchs from './components/TabSearchs/TabSearchs';
// import TourList from './components/TourList/TourList'
import DesktopDefault from '../../templates/DesktopDefault';
// import Transfers from './components/Transfers/Transfers';


function Dashboard() {
    // const [search, setSearch] = useState<any>(null);
    // const [selected, setSelected] = useState<any>(1);

    return (
        <>
            <DesktopDefault>
                {/* <TabSearchs setSearch={setSearch} setSelected={setSelected} />
                {
                    selected === 1
                        ?
                        <TourList search={search} setSearch={setSearch} />
                        :
                        <Transfers search={search} setSearch={setSearch}/>
                } */}

            
            </DesktopDefault>
        </>

    );
}

export default Dashboard;