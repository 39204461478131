import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import {  Col, Form, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import api from "../../../../services/api";

export type TExecuteAIModel = {
    modalOpen?: boolean,
    setModalOpen?: any,
    _sessionId: string,
    setSuccess: any,
}

function ExecuteAIModel({
    modalOpen, setModalOpen, _sessionId, setSuccess
}: TExecuteAIModel) {
    
    const [executeAIModel, setExecuteIAModel] = useState<boolean>(true);

    /* Modal Coginitivo */
    const [loading, setLoading] = useState<boolean>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<string>('processando');
    /* END - Modal Coginitivo */

    const [modalRequestOpen, setModalRequestOpen] = useState<boolean>(false);


    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm({});

    const repeatSubmit = () => {
        setModalRequestOpen(true);
        setModalLog(null);
        setModalMessage('processando');
        onSubmit();
    }

    const onSubmit = () => {
        setModalRequestOpen(true);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function updateIACognitivoModel() {
            try {
                const { data } = await api.post(`/Manifest/UpdateFlagExecuteModelSessionIACognitivo`, {
                    "sessionId": _sessionId,
                    "isSessionExecuteIACognitvo": executeAIModel
                }, config);
                if (data.status !== 400) {
                    console.log(data);
                    setModalLog(0);
                    setModalMessage('Sucesso!');                    
                }
            } catch (error: any) {
                console.log(error);
                setModalLog(1);
                setModalMessage('Erro ao processar operação!');
            }
        }

        updateIACognitivoModel();
    };

    
    return (
        <>
            <Modal
                className="modalAuth"
                show={modalOpen} onHide={() => setModalOpen(false)}                
                aria-labelledby="contained-modal-title-vcenter"
            >
                {modalRequestOpen === false ?
                    <div className='status-modal'>
                        <Form className="modal-content" noValidate validated={false} onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                            <Modal.Body>

                                <div className="modal-header rounded-0 justify-content-between">
                                    <div>
                                        <h4 className="pt-1 text-primary title-reservation">Cognitivo</h4>
                                    </div>                                   
                                </div>

                                <hr className='w-100 my-1 mb-4' style={{ borderBottom: '1px solid' }} />

                                <div>
                                    <Form.Group as={Col} md="12" controlId="formInfoCompanySubscription">
                                        <Form.Label>Executar modelo de IA da Cognitivo?</Form.Label>
                                        <div className="d-flex">
                                            <Controller
                                                control={control}
                                                name="isModalityPriorityCognitivo"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        className="col-5 mb-1"
                                                        type="radio"
                                                        id={"isModalityPriorityCognitivo-yes"}
                                                        label={"Sim"}
                                                        onClick={() => setExecuteIAModel(true)}
                                                        defaultChecked={executeAIModel}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name="isModalityPriorityCognitivo"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        className="col-3 mb-1"
                                                        type="radio"
                                                        id={"isModalityPriorityCognitivo-no"}
                                                        label={"Não"}
                                                        onClick={() => setExecuteIAModel(false)}
                                                    />
                                                )}
                                            />
                                        </div>
                                    </Form.Group>
                                </div>
                            </Modal.Body>
                            <Modal.Footer>
                                <div className="modal-footer rounded-0 border-0">
                                    <button type="submit" className="btn-default mr-2 text-uppercase btn btn-primary" data-dismiss="modal">
                                        <span 
                                        // onClick={() => closemodalCognitivoAndOpenModalRequest()}
                                         data-vouchercampo="close">Enviar</span>
                                    </button>
                                </div>
                            </Modal.Footer>
                        </Form>
                    </div>
                    :
                    <Modal.Body className="modal-body text-center sucess-pos d-flex justify-content-evenly"
                        style={{ height: "600px" }}>

                        <div className="my-4">
                            {
                                modalLog === 1 || modalLog === "1"
                                    ?
                                    <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em" }}
                                    />
                                    :
                                    modalLog === 0 || modalLog === "0"
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em" }}
                                        />
                                        :
                                        <>
                                            <div className="loading-modal">
                                                <div className="load"></div>
                                            </div>
                                        </>
                            }
                        </div>
                        <div className="my-4">
                            {modalMessage}
                        </div>
                        <div className="d-flex justify-content-center my-4">
                            {
                                modalMessage === 'Sucesso!'
                                    ?
                                    <button
                                    onClick={() => {
                                        setSuccess(true);
                                        setModalOpen(false)}}
                                    className="btn btn-primary mx-2 w-25"
                                >
                                    Confirmar
                                </button>
                                    :
                                    modalMessage === 'Erro ao processar operação!'
                                        ?
                                        <>
                                            <button
                                                onClick={() => {
                                                    setSuccess(true);
                                                    setModalOpen(false)
                                                }}
                                                className="btn btn-primary mx-2 w-25"
                                            >
                                                Não
                                            </button>
                                            <button
                                                onClick={() => repeatSubmit()}
                                                className="btn btn-primary mx-2 w-25"
                                            >
                                                Sim
                                            </button>
                                        </>
                                        :
                                       <></>
                            }
                        </div>
                    </Modal.Body>
                }

            </Modal>
        </>
    )

}

export default ExecuteAIModel;