import React, { Key, useEffect, useState } from 'react';
import Table from "react-bootstrap/Table";

import { Row, Col, Button, Modal} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { Controller, useForm } from 'react-hook-form';
import FinancialModalBillToReceiveTable from '../FinancialModalBillToReceiveTable/FinancialModalBillToReceiveTable';
import FinancialModalBillToPayTable from '../FinancialModalBillToPayTable/FinancialModalBillToPayTable';
import api from '../../../../services/api';

import objectBills from './locales/bills.json';
import FinancialModalBillToReceiveModal from '../FinancialModalBillToReceiveModal/FinancialModalBillToReceiveModal';
import FinancialModalBillToPayModal from '../FinancialModalBillToPayModal/FinancialModalBillToPayModal';

export interface propInsert {
    fileId: any,
    closeModal: any
}

const FinancialModal: React.FC<propInsert> = ({
    fileId, closeModal
}: propInsert) => {
   
    const [financialInfo, setFinancialInfo] = useState<any>({receipts: [], payments: []});

    /* Success Modal (Inserir) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    /* END - Success Modal (Inserir) */

    const [createBillReceiveModalShow, setCreateBillReceiveModalShow] = useState<any>(false);
    const [createBillPayModalShow, setCreateBillPayModalShow] = useState<any>(false);

    const [totalReceive, setTotalReceive] = useState<any>(0);
    const [totalPay, setTotalPay] = useState<any>(0);
    const [balance, setBalance] = useState<any>(0);

    const [loading, setLoading] = useState<any>(false);

    const GetFinancialInfo = async () => {
        setLoading(true);

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const { data } = await api.post('GroupFile/GetGroupFileBills', {
            fileId: fileId
        }, config);
        /* console.log(data.data); */
        if (data.status !== 400) {
            var response = data.data;

            if(response.log !== 1) {
                setFinancialInfo(response);

                setTotalReceive(response.totalReceive);
                setTotalPay(response.totalPay);
                setBalance(response.balance);
            }
        }

        setLoading(false);
    }

    function handleReload() {
        setModalSuccess(false);
        setCreateBillReceiveModalShow(false);
        GetFinancialInfo();
    }

    function createBillToReceive() {
        setCreateBillReceiveModalShow(true);
    }

    function createBillToPay() {
        setCreateBillPayModalShow(true);
    }

    async function exportFinancial() {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        try {
            const res = await api.post('GroupFile/ExportGroupFileBillsReport', {
                fileId: fileId
            }, config);

            if (res.status !== 400) {
                //download file
                const dados = res.data.data;
                download(dados.file, dados.fileType, dados.fileName);
            }
        } catch (error: any) {
            //setError(true)
            //if (error?.response?.status === 401) {
            //    window.location.href = window.location.origin + '/';
            //}
        }
    }

    function download(file: any, filetype: any, filename: any) { // file: bytes/string, name: string
        //const blob = new Blob([file], { type: filetype });
        const blob = `data:${filetype};base64,${file}`;
        //console.log(blob);
        //if(navigator.msSaveBlob){ // For ie and Edge
        //  return navigator.msSaveBlob(blob, filename);
        //}
        //else{
        let link = document.createElement('a');
        link.href = blob;//window.URL.createObjectURL(blob);
        link.download = filename;
        document.body.appendChild(link);
        link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
        link.remove();
        window.URL.revokeObjectURL(link.href);
        //}
    };

    useEffect(() => {
        if(fileId) {
            GetFinancialInfo();
            //setFinancialInfo(objectBills.bills);
        }
    }, [fileId]);

    function convertValue(cell: any) {
        return (
          <>
            <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
          </>
        )
      }

        return (
            <>
                <Form className="h-100" noValidate autoComplete="false">
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <FinancialModalBillToReceiveTable info={financialInfo.receipts} fileId={fileId} setModalSuccess={setModalSuccess} setResponseText={setResponseText} setModalContent={setModalContent} setLog={setLog} loading={loading} />
                        <hr className="my-4" />
                        <FinancialModalBillToPayTable info={financialInfo.payments} fileId={fileId} setModalSuccess={setModalSuccess} setResponseText={setResponseText} setModalContent={setModalContent} setLog={setLog} loading={loading} />
                        <div className='row'>
                            <div className='col-4 d-flex'><div className='' style={{fontWeight: 'bolder'}}>Total Recebimentos:</div><div className='ml-2'>R$ {convertValue(totalReceive)}</div></div>
                            <div className='col-4 d-flex'><div className='' style={{fontWeight: 'bolder'}}>Total Pagamentos:</div><div className='ml-2'>R$ {convertValue(totalPay)}</div></div>
                            <div className='col-4 d-flex'><div className='' style={{fontWeight: 'bolder'}}><b>Saldo:</b></div><div className='ml-2'>R$ {convertValue(balance)}</div></div>
                        </div>
                        <div className='mt-4 d-flex justify-content-end gap-2'>
                            <Button type="button" className="form-button" onClick={() => closeModal()}>Voltar</Button>
                            <Button type="button" value={'new-receive'} className="form-button" onClick={createBillToReceive}>Novo Recebimento</Button>
                            <Button type="button" value={'new-pay'} className="form-button" onClick={createBillToPay}>Novo Pagamento</Button>
                            <Button type="button" value={'export'} className="form-button" onClick={exportFinancial}>Exportar</Button>
                        </div>
                    </div>
                </Form>

                {/* Modal de Success */}
                <Modal
                    className={"modal-confirm loading-modal"}
                    show={modalSuccess}
                    onHide={() => setModalSuccess(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos'>
                        {
                            modalContent !== "loading"
                            ?
                            <>
                                <div>
                                {
                                    log === 2 || log === "2"
                                    ?
                                    <FontAwesomeIcon
                                        icon={["fal", "exclamation-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                    />
                                    :
                                    log === 1 || log === "1"
                                        ?
                                        <FontAwesomeIcon
                                        icon={["fal", "times-circle"]}
                                        size="5x"
                                        className="text-primary"
                                        style={{ fontSize: "7.5em"}}
                                        />
                                        :
                                        log === 0 || log === "0"
                                        ?
                                        <FontAwesomeIcon
                                            icon={["fal", "check-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em"}}
                                        />
                                        :
                                        <FontAwesomeIcon
                                            icon={["fal", "question-circle"]}
                                            size="5x"
                                            className="text-primary"
                                            style={{ fontSize: "7.5em"}}
                                        />
                                }
                                </div>
                                <div>
                                {responseText}
                                </div>
                                <div className="d-flex justify-content-center">
                                {
                                    modalContent === 'success'
                                    ?
                                    <button
                                        onClick={handleReload}
                                        className="btn btn-primary mx-2 w-25"
                                    >
                                        Confirmar
                                    </button>
                                    :
                                    modalContent === 'warning'
                                        ?
                                        <button
                                        onClick={() => setModalSuccess(false)}
                                        className="btn btn-primary mx-2 w-25"
                                        >
                                        Confirmar
                                        </button>
                                        :
                                        <button
                                        onClick={() => setModalSuccess(false)}
                                        className="btn btn-primary mx-2 w-25"
                                        >
                                        Confirmar
                                        </button>
                                }
                                </div>
                            </>
                            :
                            <div className="modal-body">
                                <div className="text-center">
                                <div className="load"></div>
                                </div>
                                <div>
                                <span>Processando</span>
                                </div>
                                <div></div>
                            </div>
                        }
                        </Modal.Body>
                    </>
                </Modal>
                {/* END - Modal de Success */}

                <Modal
                    className="modalReceber"
                    show={createBillReceiveModalShow}
                    onHide={() => setCreateBillReceiveModalShow(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                    <Modal.Header className="p-3" closeButton></Modal.Header>
                    <FinancialModalBillToReceiveModal 
                        action={() => {setCreateBillReceiveModalShow(false); handleReload();}} 
                        info={null} 
                        fileId={fileId}
                        setModalSuccess={setModalSuccess}
                        setModalResponse={setModalContent}
                        setModalMessage={setResponseText}
                        setLog={setLog}
                    />
                    </>
                </Modal>

                <Modal
                    className="modalReceber"
                    show={createBillPayModalShow}
                    onHide={() => setCreateBillPayModalShow(false)}
                    size="xl"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                    <Modal.Header className="p-3" closeButton></Modal.Header>
                    <FinancialModalBillToPayModal 
                        action={() => {setCreateBillPayModalShow(false); handleReload();}} 
                        info={null} 
                        fileId={fileId}
                        show={setModalSuccess}
                        setModalContent={setModalContent}
                        setResponseText={setResponseText}
                        setLog={setLog}
                    />
                    </>
                </Modal>
            </>
        )   
}

export default FinancialModal;