/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import './ExportERPLogs.scss'

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterExportERPLogs from './components/FilterExportERPLogs/FilterExportERPLogs';
import TableExportERPLogs from './components/TableExportERPLogs/TableExportERPLogs';
import ModalShow from '../../components/Modal/ModalShow/ModalShow';

function ExportERPLogs() {
    const today = new Date();
    const todayStr = [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`];

    const [actualDate, setActualDate] = useState<any>(todayStr);
    const [actualStatus, setActualStatus] = useState<any>(3);
    const [actualReference, setActualReference] = useState<any>('');

    const [modalShow, setModalShow] = useState<boolean>(false);
    const [modalMessage, setModalMessage] = useState<string>("Carregando");
    const [modalLog, setModalLog] = useState<any>(null);

    const [logs, setLogs] = useState<any>(null);
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    const searchFunction = async (date: any, reference: string, status: any, page: any = 1) => {
        setLoading(true);
        setLogs(null);
        let dataInicio: string = date[0].split('/').reverse().join('-');
        let dataFim: string = date[date.length - 1].split('/').reverse().join('-');

        const token = localStorage.getItem('GroupId') || '{}';
        const config: any = {
            headers: {
                'Authorization': `Bearer ${token}`,
                'Content-Type': 'application/json',
                "Accept": "*/*"
            }
        };
        try {
            const { data } = await api.post('https://backend.c2.tours/api/Manifest/GetLogsRm',
                {
                    "page": page,
                    "rowesPage": rowsPerPage,
                    "referencia": reference,
                    "dataInicio": dataInicio + "T00:00:00",
                    "dataFim": dataFim + "T23:59:59",
                    "status": status
                }, config
            );
            if (data.statusCode !== 400) {
                setLogs(data.data.rows);
                setTotalRows(data.data.rowsCount);
            }
            setLoading(false);
        } catch (error: any) {
            setLoading(false);
            setModalLog(1);
            setModalMessage("Erro ao processar operação");
        }
    }

    const buscar = (date: any, reference: string, status: any, page: any = 1) => {
        

        setActualDate(date);
        setActualReference(reference);
        setActualStatus(status);
        if(pageCount === 1){   
            searchFunction(date, reference, status, page);
        }else{
            setPageCount(1);
        }
    };

    useEffect(() => {
        searchFunction(actualDate, actualReference, actualStatus, pageCount);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount])

    useEffect(() => {
        if(pageCount === 1){   
            searchFunction(actualDate, actualReference, actualStatus, 1);
        }else{
            setPageCount(1);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [rowsPerPage])

    async function refreshData() {
        setLoading(true);

        let body: any = document.querySelector("body");
        if (body.className !== "modal-open") {
            setLogs(null);
        }

        let beginDate: any = []
        let endDate: any = []

        if (actualDate.length === 1) {
            beginDate = actualDate[0].split('/')
        } else if (actualDate.length === 2) {
            beginDate = actualDate[0].split('/')
            endDate = actualDate[1].split('/')
        }

        let today: any = new Date();

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };

        async function searchFunction() {
            try {
                const { data } = await api.post('/Manifest/GetLogsRm',
                    {
                        "page": 1,
                        "rowesPage": rowsPerPage,
                        "referencia": '',
                        "dataInicio": '',
                        "dataFim": '',
                        "status": 3
                    }, config
                );
                setLoading(false);
                if (data.status !== 400) {
                    setLogs(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        searchFunction();
    }

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard export-erp-logs">
                    <Breadcrumb title={'Financeiro / Logs RM'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Logs RM</h2>
                    </div>
                    <FilterExportERPLogs
                        buscar={buscar}
                    />
                    <TableExportERPLogs
                        logs={logs}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        loading={loading}
                    />
                </div>
                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                />
            </DesktopDefault>
        </>
    );
}

export default ExportERPLogs;