/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import api from '../../../services/api';
import './ListPartners.scss';

//COMPONENTS
import PartnersTable from '../components/ListPartners/PartnersTable/PartnersTable';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import PartnersFilter from '../components/ListPartners/PartnersFilter/PartnersFilter';
import { Link } from 'react-router-dom';
import DesktopDefault from '../../../templates/DesktopDefault';

function ListPartners() {
  const [partners, setPartners] = useState<any>(null);
  const [error, setError] = useState<any>();
  const [loading, setLoading] = useState<any>(false);
  const [loadButton, setLoadButton] = useState<any>([false, false]);

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [allowSearch, setAllowSearch] = useState<any>(1);
  /* END - Pagination */

  const [actualName, setActualName] = useState<any>();
  const [actualState, setActualState] = useState<any>();
  const [actualType, setActualType] = useState<any>();
  const [actualStatus, setActualStatus] = useState<any>();
  const [actualSocialReason, setActualSocialReason] = useState<any>();
  const [actualCNPJ, setActualCNPJ] = useState<any>();
  const [actualCPF, setActualCPF] = useState<any>();
  const [actualEmail, setActualEmail] = useState<any>();
  const [actualDate, setActualDate] = useState<any>([]);

  useEffect(() => {
    //var today: any = new Date();

    buscar('', '', 0, 0, [], '', '', '', '');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const reloadSerch = () => {
    buscar(
      actualName,
      actualState,
      actualType,
      actualStatus,
      actualDate,
      actualSocialReason,
      actualCNPJ,
      actualCPF,
      actualEmail,
    );
  };
  console.log(actualEmail);

  useEffect(() => {
    setLoading(true);
    setPartners(null);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { Authorization: `Bearer ${token}` },
    };
    async function listPartners() {
      let beginDate = [];
      let endDate = [];

      if (actualDate.length === 1) {
        beginDate = actualDate[0].split('/');
      } else if (actualDate.length === 2) {
        beginDate = actualDate[0].split('/');
        endDate = actualDate[1].split('/');
      }

      try {
        const { data } = await api.post(
          '/Supplier/GetAffiliatesByFilterAsync',
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            name: actualName,
            state: actualState,
            compName: actualSocialReason,
            cnpj: actualCNPJ,
            cpf: actualCPF,
            email: actualEmail,
            status: actualStatus,
            type: actualType,
            country: 0,
            beginDate:
              actualDate.length > 0
                ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`
                : '',
            endDate:
              actualDate.length === 1
                ? `${beginDate[2]}-${beginDate[1]}-${
                    parseInt(beginDate[0]) + 1
                  }`
                : actualDate.length === 2
                ? `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                : '',
            orderByField: '',
          },
          config,
        );
        console.log(data);

        if (data.status !== 400) {
          setLoading(false);
          setPartners(data.data.rows);
          setTotalRows(data.data.rowsCount);
        }
      } catch (error: any) {
        setLoading(false);
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listPartners();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage, allowSearch]);

  async function buscar(
    name: string = '',
    state: string = '',
    type: any = 0,
    status: any = 0,
    date: any = [],
    socialReason: string = '',
    cnpj: string = '',
    cpf: string = '',
    email: string = '',
  ) {
    setLoading(true);
    setPartners(null);

    setActualName(name);
    setActualState(state);
    setActualType(type);
    setActualStatus(status);
    setActualDate(date);
    setActualSocialReason(socialReason);
    setActualCNPJ(cnpj);
    setActualCPF(cpf);
    setActualEmail(email);

    if (pageCount !== 1) {
      setPageCount(1);
    } else {
      setAllowSearch(allowSearch + 1);
    }
  }

  // função para exportar clientes
  const exportClients = () => {
    setLoadButton([false, true]);
    async function exporting() {
      let beginDate = [];
      let endDate = [];

      if (actualDate.length === 1) {
        beginDate = actualDate[0].split('/');
      } else if (actualDate.length === 2) {
        beginDate = actualDate[0].split('/');
        endDate = actualDate[1].split('/');
      }

      let today: any = new Date();

      const token = localStorage.getItem('GroupId') || '{}';

      try {
        const exp = await api.post(
          '/Affiliate/GetAffiliatesByFilterExcelAsync',
          {
            page: 0,
            rowsPerPage: 0,
            name: actualName,
            state: actualState,
            socialReason: actualSocialReason,
            cnpj: actualCNPJ,
            cpf: actualCPF,
            email: actualEmail,
            status: actualStatus,
            type: actualType,
            country: 0,
            beginDate:
              actualDate.length > 0
                ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`
                : '',
            endDate:
              actualDate.length === 1
                ? `${beginDate[2]}-${beginDate[1]}-${
                    parseInt(beginDate[0]) + 1
                  }`
                : actualDate.length === 2
                ? `${endDate[2]}-${endDate[1]}-${endDate[0]}`
                : '',
            orderByField: '',
          },
          {
            headers: {
              Authorization: `Bearer ${token}`,
              'Content-Type': 'application/json',
              Accept: '*/*',
              //"Access-Control-Allow-Origin": "*"
            },
          },
        );

        if (exp.status !== 400) {
          setLoadButton([false, false]);
          const link = document.createElement('a');
          link.href = `data: application/vnd.openxmlformats-officedocument.spreadsheetml.sheet; base64, ${exp.data.data.result.data}`;
          link.setAttribute(
            'download',
            `lista_de_clientes_${process.env.REACT_APP_CLIENT_NAME}.xlsx`,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        }
      } catch (error: any) {}
    }
    exporting();
  };
  // função para exportar clientes

  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={'Clientes / Lista de Clientes'} />

          <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: '#707070' }}>
              Lista de Clientes
            </h2>
            <Link to={'/partners/add-partners'} className="btn btn-primary">
              Novo Cliente
            </Link>
          </div>

          <PartnersFilter buscar={buscar} partners={partners} error={error} />

          <PartnersTable
            partners={partners}
            loading={loading}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            reloadSerch={reloadSerch}
            exportClients={exportClients}
            loadButton={loadButton}
          />
        </div>
      </DesktopDefault>
    </>
  );
}

export default ListPartners;
