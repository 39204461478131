import React, { useEffect, useState } from 'react';
import api from '../../../services/api';

//COMPONENTS
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';


import MerchantsForm from '../components/MerchantsForm/MerchantsForm';
import { useTranslation } from 'react-i18next';
import DesktopDefault from '../../../templates/DesktopDefault';


function EditMerchants() {
    const { t } = useTranslation();
    const [editMerchants,setEditMerchants] = useState(null);

    const formataDecimal = (value: any): any => {
        if(value || value === 0) {
            return parseFloat(value).toFixed(2).replace('.', ',');
        } else {
            return '';
        }
    }

    useEffect(() => {
        const idSup = window.location.href.split('id=')[1];

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        const EditMerchants = async () => {
            try {
                const { data } = await api.get(`MerchantAccount/GetMerchantsByIdAsync/${idSup}`, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    const dados = data.data;
                    //console.log(dados);
                    for(var i = 0; i < dados.posDevices.length; i++) {
                        dados.posDevices[i].aluguel = formataDecimal(dados.posDevices[i].aluguel);
                        dados.posDevices[i].insencaoMin = formataDecimal(dados.posDevices[i].insencaoMin);
                        dados.posDevices[i].feeGateway = formataDecimal(dados.posDevices[i].feeGateway);
                        dados.posDevices[i].feeAntifraude = formataDecimal(dados.posDevices[i].feeAntifraude);
                    }

                    for(i = 0; i < dados.feesTerms.length; i++) {
                        dados.feesTerms[i].taxa = formataDecimal(dados.feesTerms[i].taxa);
                        dados.feesTerms[i].taxaMin = formataDecimal(dados.feesTerms[i].taxaMin);
                    }

                    for(i = 0; i < dados.installmentsRanges.length; i++) {
                        dados.installmentsRanges[i].amountMin = formataDecimal(dados.installmentsRanges[i].amountMin);
                        dados.installmentsRanges[i].amountMax = formataDecimal(dados.installmentsRanges[i].amountMax);
                    }

                    for(i = 0; i < dados.installmentsFees.length; i++) {
                        dados.installmentsFees[i].adminFee = formataDecimal(dados.installmentsFees[i].adminFee);
                        dados.installmentsFees[i].estabFee = formataDecimal(dados.installmentsFees[i].estabFee);
                    }
                    
                    setEditMerchants(dados);
                }   
            }catch(error: any) {
                if (error?.response?.status === 401) {
                    window.location.href = window.location.origin + '/';              
                }
                if (error?.response?.status === 400) {
                    window.location.href = window.location.origin + '/list-merchants';              
                }
            }
        }
        EditMerchants();
    }, [])

    if(editMerchants !== null) {
        return (
            <>
                <DesktopDefault>
                    <div className="container-fluid content-dashboard">

                        <Breadcrumb title={'Adquirentes / ' + t('editMerchants.title')} />

                        <div className="register-users mt-4">
                            <h2 className="title">{t('editMerchants.title')}</h2>
                        </div>

                        <MerchantsForm action="2" info={editMerchants} />

                    </div>

                </DesktopDefault>
            </>

        );
    } else {
        return (
            <></>
        )
    }
}

export default EditMerchants;