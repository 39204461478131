import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
    propsField: any,
    propsErrors: any,
    setValue?: any,
    defaultId?: any,
};

const GetSessionGroupId: React.FC<propPlus> = ({
    propsField, propsErrors, setValue, defaultId = null
}: propPlus) => {

    const [info, setInfo] = useState<any>(null);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    }

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listSessionGroup() {
            try {
                const { data } = await api.get(`/DropDown/GetSessionGroupId`, config);
                if (data.status !== 400) {
                    setInfo(data.data);
                    if(defaultId !== null){
                        let aux: any = data.data.find((e: any) => parseInt(e.id) === defaultId)
                        if(aux){
                          setValue('sessionGroupId', aux);
                        }
                      }
                }
            } catch (error: any) {
                setTimeout(() => {
                    listSessionGroup();
                }, 5000);
            }
        }

        listSessionGroup();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (info !== null) {
        return (
            <>
                <div className={propsErrors.city !== undefined ? "endpoint-error" : "endpoint"}>
                    <Select
                        {...propsField}
                        aria-labelledby="aria-label"
                        inputId="aria-example-input"
                        aria-invalid={propsErrors?.city ? "true" : ""}
                        name="aria-live-color"
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        as="select"
                        variant="standard"
                        margin="normal"
                        placeholder={'Selecione'}
                        options={Array.from(info)}
                        getOptionLabel={(option: any) => `${option.description}`}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <div className={propsErrors.city !== undefined ? "endpoint-error" : "endpoint"}>
                    <Form.Select>
                        <option value='' disabled></option>
                    </Form.Select>
                </div>
            </>
        )

    }
}

export default GetSessionGroupId;