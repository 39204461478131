/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css";
import "react-bootstrap-table-next/dist/react-bootstrap-table2.css";
import BootstrapTable from "react-bootstrap-table-next";

//STYLES
import "./TableRPSIssuance.scss";
import Pagination from "../../../../components/Pagination/Pagination";
import { Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import api from "../../../../services/api";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

export interface propTable {
  sessions: any;
  totalRows: any;
  pageCount: any;
  setPageCount: any;
  rowsPerPage: any;
  setRowsPerPage: any;
  exportReport: any;
  printReport: any;
  loading: any;
  dateSelect: any;
}

const TableRPSIssuance: React.FC<propTable> = ({
  sessions, totalRows, pageCount, setPageCount, rowsPerPage, setRowsPerPage, exportReport, printReport, loading, dateSelect
}: propTable) => {
  //console.log(props.suppliers[0].dataCadastro.split('T')[0])
  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  const [sessionChoice, setSessionChoice] = useState<any>();

  const [exportRPSModalShow, setExportRPSModalShow] = useState<any>();
  const [expAndEmitRPSModalShow, setExpAndEmitRPSModalShow] = useState<any>();

  const handleExportClick = (inc: any) => {
    exportReport();
  }

  const handlePrintClick = (inc: any) => {
    printReport();
  }

  const handleIssueExportRPSClick = (session: any, action: any) => {
    setExpAndEmitRPSModalShow(true);

    if (action === 1) {
      setSessionChoice(session);
    } else {
      sendToRPS(sessionChoice);
    }
  }

  const sendToRPS = async (session: any) => {
    setExpAndEmitRPSModalShow(false);
    setModalContent('loading');
    setModalSuccess(true);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    try {
      const { data } = await api.post('/Checkout/EnvioSessionRM',
        {
          "page": null,
          "rowsPerPage": null,
          "dataInicial": null,
          "dataFinal": null,
          "session": session,
          "status": null,
          "tipo": null,
        }, config
      );
      if (data.status !== 400) {
        setResponseText(data.data.data.texto);
        setLog(data.data.data.log);

        if (data.data.data.log === 0) {
          setModalContent('success');
        } else if (data.data.data.log === 1) {
          setModalContent('error');
        }
      }
    } catch (error: any) {
      setModalContent('error');
      setLog(1);
      setResponseText(error.message);
    }
  }

  const handleConfirmIssueExportRPSClick = () => {
    sendToRPSInBatch();

  }

  const sendToRPSInBatch = async () => {
    setExportRPSModalShow(false);

    setModalContent('loading');

    setModalSuccess(true);
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    let beginDate = [];
    beginDate = dateSelect.split('/');

    try {
      const { data } = await api.post('/Checkout/EnvioSessionRM',
        {
          "page": pageCount,
          "rowsPerPage": rowsPerPage,
          "dataInicial": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
          "dataFinal": `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}`,
          "session": '',
          "status": null,
          "tipo": null,
        }, config
      );
      if (data.status !== 400) {
        setResponseText(data.data.data.texto);
        setLog(data.data.data.log);

        if (data.data.data.log === 0) {
          setModalContent('success');
        } else if (data.data.data.log === 1) {
          setModalContent('error');
        }
      }
    } catch (error: any) {
      setModalContent('error');
      setLog(1);
      setResponseText("Request failed with status code 400");
    }

  }


  function addActionButton(cell: any, row: any) {
    var session: any;
    if (row.tipo === 'Tour') {
      session = `S${cell}`;
    } else {
      session = `I${cell}`;
    }

    return (
      <>

        <OverlayTrigger
          overlay={
            <Tooltip id="tooltip">Emitir/Exportar RPS</Tooltip>
          }>
          <button className="btn p-0 pb-1" onClick={(e: any) => handleIssueExportRPSClick(cell, 1)}>
            <FontAwesomeIcon
              icon={["fal", "file-invoice"]}
              size="1x"
              style={{ marginRight: "1px" }}
            />
          </button>
        </OverlayTrigger>

        {/* <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light ">
              <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                onClick={(e: any) => handleRecebimentosClick(session)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'receipt']}
                />
                Recebimentos
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={(e: any) => handlePagamentosClick(session)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'hand-holding-usd']}
                //className="mr-2"
                />
                Pagamentos
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"
                onClick={(e: any) => handleFaturamentoClick(session)}
              >
                <FontAwesomeIcon
                  icon={['fal', 'file-invoice']}
                  //className="mr-2"
                />
                Faturamento
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div> */}
      </>
    );
  }


  /*converte a data UTC para a data local do usuário*/
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  function convertUTCDateToLocalDate(date: any) {
    var newDate = new Date(date.getTime() + date.getTimezoneOffset() * 60 * 1000);

    var offset = date.getTimezoneOffset() / 60;
    var hours = date.getHours();

    newDate.setHours(hours - offset);

    return newDate.toLocaleString().split(' ')[0];
  }

  // function convertDate(cell: any, row: any) {
  //   var date = convertUTCDateToLocalDate(new Date(cell));
  //   /* console.log(date) */
  //   return (
  //     <>
  //       {date}
  //     </>
  //   );
  // }
  function convertDate(cell: any, row: any) {
    if (!cell) return '';
    //var date = convertUTCDateToLocalDate(new Date(cell));
    var aux: any = cell.split("T")[0].split("-");
    var date: any = `${aux[2]}/${aux[1]}/${aux[0]}`;
    return (
      <>
        {date}
      </>
    );
  }

  function convertValue(cell: any) {
    //console.log(Math.sign(cell))
    return (
      <>
        <span className={Math.sign(cell) === -1 ? "negative" : ""}>{cell.toFixed(2).replace(".", ",")}</span>
      </>
    )
  }

  function addStatusRecebimentos(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Faturado' || cell === 'Recebido'
              ? "bg-ativo"
              : cell === 'Pendente'
                ? "bg-inativo"
                : "")
        }
      >
        {cell}
      </div>
    );
  }

  function addTotalRps(cell: any, row: any) {
    return (
      <div
        className={row.totalRps === row.totalMargem ?
          "invoice-blue"
          : row.totalRps === 0 ?
            "invoice-red"
            : row.totalRps < row.totalMargem ?
              "invoice-orange"
              : ""}
      >
        {cell.toFixed(2).replace(".", ",")}
      </div>
    );
  }

  function addTotalErp(cell: any, row: any) {
    return (
      <div
        className={row.totalErp === row.totalMargem ?
          "invoice-blue"
          : row.totalErp === 0 ?
            "invoice-red"
            : row.totalErp < row.totalMargem ?
              "invoice-orange"
              : ""}
      >
        {cell.toFixed(2).replace(".", ",")}
      </div>
    );
  }

  function addStatusPagamentos(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Agendado' || cell === 'Autorizado' || cell === 'Pago'
              ? "bg-ativo"
              : cell === 'Pendente' || cell === 'Cancelado'
                ? "bg-inativo"
                : "")
        }
      >
        {cell}
      </div>
    );
  }

  function addStatus(cell: any, row: any) {
    return (
      <div
        className={"w-100 " +
          (cell === 'Conciliado'
            ? "bg-blue"
            : cell === 'Pendente'
              ? "bg-inativo"
              : "")
        }
      >
        {cell}
      </div>
    );
  }



  const columns = [
    {
      dataField: "dataOperacao",
      text: "Data Operação",

      formatter: convertDate,
    },
    { dataField: "tipo", text: "Tipo", },
    { dataField: "sessionReloc", text: "Session / Reloc", },
    { dataField: "descricao", text: "Tour / Descrição", },
    {
      dataField: "totalRecebimentos",
      text: "Recebimentos R$",

      formatter: convertValue,
    },
    {
      dataField: "totalPagamentos",
      text: "Pagamentos R$",

      formatter: convertValue,
    },
    {
      dataField: "totalMargem",
      text: "Margem R$",

      formatter: convertValue,
    },
    {
      dataField: "taxaMargem",
      text: "Margem %",

      formatter: convertValue,
    },
    {
      dataField: "totalRps",
      text: "RPS Emitidas",

      formatter: addTotalRps,
    },
    {
      dataField: "totalErp",
      text: "RPS Exportadas ERP",

      formatter: addTotalErp,
    },
    { dataField: "id", text: " ", formatter: addActionButton },
  ];

  /* loading table */

  function loadingFunc() {
    return (
      <>
        <div className="animated-background row-loading"></div>
      </>
    )
  }

  const loadingColumns = [{ dataField: "contasd", text: "Carregando sessions", formatter: loadingFunc }];

  const loadingProducts = [
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" },
    { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }, { contas: "" }
  ];
  /* [END] loading table */

  /*--------------------------------------------------*/

  /* In case of empty table*/
  const notFoundColumns = [{ dataField: "sessions", text: "Sessions" }];

  const notFoundProducts = [{ sessions: "Nenhuma session encontrada" }];
  /* [END] In case of empty table*/

  const rowClasses = (row: any, rowIndex: any) => {
    /* console.log(row, rowIndex) */
    if (row.status === "Conciliado") {
      return 'border-analise';
    } else {
      return 'border-inativo';
    }
  };

  function handleReload() {
    window.location.reload();
  }

  if (sessions !== null) {
    return (
      <>
        <div className="table-default rps-issuance-table">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" onClick={handleExportClick}>
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={handlePrintClick}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" onClick={() => setExportRPSModalShow(true)}>
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "file-invoice"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar RPS em Lote
                  </button>
                </div>
                {/* <div>
                    <ModalDefault
                      title="Emitir/Exportar RPS em Lote"
                      name="Emitir/Exportar RPS em Lote"
                      icon="yes"
                      typeIcon="file-invoice"
                      classBtn="btn btn-bills"
                      classModal="modal-large modal-large-custom"
                    >

                    </ModalDefault>
                  </div> */}
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={sessions}
              columns={columns}
              rowClasses={rowClasses}
              striped={true}
            />

            <Pagination
              totalRows={totalRows}
              pageCount={pageCount}
              setPageCount={setPageCount}
              rowsPerPage={rowsPerPage}
              setRowsPerPage={setRowsPerPage}
              selectNumberRows="yes"
            />

            <ModalQuestion
              modalShow={exportRPSModalShow}
              setModalShow={setExportRPSModalShow}
              titulo="Exportar para ERP"
              mensagem="Tem certeza que deseja exportar todos os valores?"
              handleActionConfirmModal={handleConfirmIssueExportRPSClick}
            //handleActionCancelModal={handleActionCancelDeleteClick}
            />

            <ModalQuestion
              modalShow={expAndEmitRPSModalShow}
              setModalShow={setExpAndEmitRPSModalShow}
              titulo="Exportar para ERP"
              mensagem="Tem certeza que deseja exportar está sessão?"
              handleActionConfirmModal={handleIssueExportRPSClick}
            //handleActionCancelModal={handleActionCancelDeleteClick}
            />

            {/* Modal de Success */}
            <Modal
              className={"modal-confirm loading-modal"}
              show={modalSuccess}
              onHide={() => setModalSuccess(false)}
              size="lg"
              aria-labelledby="contained-modal-title-vcenter"
            >
              <>
                <Modal.Body className='modal-body text-center sucess-pos'>
                  {
                    modalContent !== "loading"
                      ?
                      <>
                        <div>
                          {
                            log === 2 || log === "2"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "exclamation-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              log === 1 || log === "1"
                                ?
                                <FontAwesomeIcon
                                  icon={["fal", "times-circle"]}
                                  size="5x"
                                  className="text-primary"
                                  style={{ fontSize: "7.5em"}}
                                />
                                :
                                log === 0 || log === "0"
                                  ?
                                  <FontAwesomeIcon
                                    icon={["fal", "check-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                                  :
                                  <FontAwesomeIcon
                                    icon={["fal", "question-circle"]}
                                    size="5x"
                                    className="text-primary"
                                    style={{ fontSize: "7.5em"}}
                                  />
                          }
                        </div>
                        <div>
                          {responseText}
                        </div>
                        <div className="d-flex justify-content-center">
                          {
                            modalContent === 'success'
                              ?
                              <button
                                onClick={handleReload}
                                className="btn btn-primary mx-2 w-25"
                              >
                                Confirmar
                              </button>
                              :
                              modalContent === 'warning'
                                ?
                                <button
                                  onClick={() => setModalSuccess(false)}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                                :
                                <button
                                  onClick={() => setModalSuccess(false)}
                                  className="btn btn-primary mx-2 w-25"
                                >
                                  Confirmar
                                </button>
                          }
                        </div>
                      </>
                      :
                      <div className="modal-body">
                        <div className="text-center">
                          <div className="load"></div>
                        </div>
                        <div>
                          <span>Processando</span>
                        </div>
                        <div></div>
                      </div>
                  }
                </Modal.Body>
              </>
            </Modal>
            {/* END - Modal de Success */}

          </div>
        </div>
      </>
    );
  } else if (sessions === null && loading === true) {
    return (
      <>
        <div className="table-default rps-issuance-table loading not-found">
          <div className="table-container">
            <div>
              <div className="d-flex flex-wrap align-items-center" style={{ gap: "15px" }}>
                <div>
                  <button className="btn btn-bills" >
                    <FontAwesomeIcon
                      icon={["fal", "share"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills" >
                    <FontAwesomeIcon
                      file-invoice
                      icon={["fal", "print"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Imprimir
                  </button>
                </div>
                <div>
                  <button className="btn btn-bills">
                    <FontAwesomeIcon
                      icon={["fal", "file-invoice"]}
                      size="1x"
                      style={{ marginRight: "5px" }}
                    />
                    Exportar RPS em Lote
                  </button>
                </div>
              </div>
            </div>
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={loadingProducts}
              hover={true}
              columns={loadingColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    )
  } else {
    return (
      <>
        <div className="table-default rps-issuance-table not-found">
          <div className="table-container">
            <BootstrapTable
              bootstrap4
              keyField="id"
              data={notFoundProducts}
              hover={true}
              columns={notFoundColumns}
              striped={true}
            />
          </div>
        </div>
      </>
    );
  }
}

export default TableRPSIssuance;
