
import React, { useState } from "react";
import { Container, Row, Col, Form, InputGroup, Button, Accordion } from 'react-bootstrap';
import { Controller, useForm } from "react-hook-form";

import RangeCalendar from "../../../../components/Calendar/RangeCalendar";

//import './BlackoutFilter.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersFilter";
import GetProductsListCod from "../../../../components/C2Points/GetProductsListCod";

export interface filter {
    buscar: any
};

const BlackoutFilter: React.FC<filter> = React.memo(({
    buscar
}: filter) => {
    let today: any = new Date();
    // const todayStr = [
    //     `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`,
    //     `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`
    // ];

    const [filterDate, setFilterDate] = useState<any>(''/* todayStr */);

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm({});

    const handleClearClick = (event: any) => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            selectValue[i].value = "0";
        }

        setFilterDate(''/* todayStr */);
        setValue('name', '');
        setValue('code', '');
        setValue('status', '0');
        setValue('supplier', '');
        setValue('products', '');
        buscar("", "", 0, [], '');
    };

    const onSubmit = (data: any) => {
        buscar(data.name, data.code, data.status, filterDate, data.products);
    }

    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar blackouts</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                        <Row className="mb-3">
                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Nome</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="name"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="4">
                                                <Form.Label>Código</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="code"
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                            {...field}
                                                            type="text"
                                                            variant="standard"
                                                            margin="normal"
                                                            autoComplete="off"
                                                        />
                                                    )}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Período</Form.Label>
                                                <InputGroup hasValidation className="ranger-calendar">
                                                    <RangeCalendar date={filterDate} setDate={setFilterDate}  defaultValues={[`${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`, `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`]}/>
                                                </InputGroup>
                                            </Form.Group>

                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Status</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="status"
                                                    render={({ field }: any) => (
                                                        <div className="endpoint">
                                                            <Form.Select
                                                                {...field}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                required
                                                            >
                                                                <option value='0'>Selecione</option>
                                                                <option value='1'>Ativo</option>
                                                                <option value='2'>Inativo</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row>
                                            <Form.Group as={Col} md="8">
                                                <Form.Label>Produtos</Form.Label>
                                                <Controller
                                                    control={control}
                                                    name="products"
                                                    render={({ field }: any) => (
                                                        <div className="">
                                                            <GetProductsListCod
                                                                propsField={field}
                                                                propsErrors={errors}
                                                                //isMulti={true}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                            <Form.Group as={Col} md="4" controlId="">
                                                <Form.Label className="label-small">
                                                    Fornecedor
                                                </Form.Label>

                                                <Controller
                                                    control={control}
                                                    name="supplier"
                                                    rules={{ required: { value: false, message: 'Por favor, informe o grupo sessão' } }}
                                                    render={({ field }: any) => (
                                                        <div className="input-select-custom">
                                                            <GetSuppliersFilter
                                                                propsField={field}
                                                                propsErrors={errors}
                                                                setFilterSupplier={() => { }}
                                                                setValue={setValue}
                                                            />
                                                        </div>
                                                    )}
                                                />
                                            </Form.Group>
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" type='button' onClick={handleClearClick}>Limpar</Button>
                                                <Button className="btn-default text-uppercase" type='submit' /* onClick={handleFilterClick} */>Buscar</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
        </div >
    )
})

export default BlackoutFilter;