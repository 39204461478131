import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import GetAllBadges from "../../../../components/C2Points/GetAllBadges";
import GetSalesChannel from "../../../../components/C2Points/GetSalesChannel";

import { useAppSelector, useAppDispatch } from '../../../../store/hooks';
import { addToComboObject } from "../../../../store/combo";
import SingleCalendarWithDefaultValue from "../../../../components/Calendar/SingleCalendarWithDefaultValue";

export interface operation {
    action: any;
    isEdit: any;
    editFunction?: any;
};

const Operation: React.FC<operation> = React.memo(({
    action, isEdit, editFunction = () => { }
}: operation) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const reduxComboObject: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.combo))).combo;
    /* Controle Redux  */
useEffect(()=>{
    console.log(reduxComboObject)
},[])
    const buyingAdvanceOptions: any = ["0 Minutos", "5 Minutos", "10 Minutos", "15 Minutos", "20 Minutos", "25 Minutos", "30 Minutos", "35 Minutos", "40 Minutos", "45 Minutos", "50 Minutos", "55 Minutos", "1 hora", "2 horas", "3 horas", "4 horas", "5 horas", "6 horas", "7 horas", "8 horas", "9 horas", "10 horas", "11 horas", "12 horas", "13 horas", "14 horas", "15 horas", "16 horas", "17 horas", "18 horas", "19 horas", "20 horas", "21 horas", "22 horas", "23 horas", "24 horas", "48 horas"];

    const [refundQtd, setRefundQtd] = useState<any>(reduxComboObject?.refundPolicy ? new Array(reduxComboObject?.refundPolicy?.length).fill(true) : [true]);
    const [/* policyArray */, setPolicyArray] = useState<string[] | null>(reduxComboObject?.refundPolicy && reduxComboObject?.refundPolicy[0]?.policy ? reduxComboObject?.refundPolicy.map((elem: any) => { return `${elem.policy}` }) : ['1']);
    const [startDate, setStartDate] = useState<any>("");
    const [endDate, setEndDate] = useState<any>("");

    const {
        control,
        handleSubmit,
        setValue,
        watch,
        formState: { errors },
    } = useForm({});

    const watchRefundRule = watch(`refundPolicy[0].refundRule`, ((reduxComboObject?.refundPolicy?.length > 0 && parseInt(reduxComboObject.refundPolicy[0].refundRule)) || null));
    const watchIsOpen = watch(`isOpen`, `${reduxComboObject?.isOpen}` || '2');

    const handleRefund = () => {
        setPolicyArray((prev: any) => [...prev, '1'])
        setRefundQtd((prev: any) => [...prev, true]);
    }

    const handleDeleteRefund = (indexToExclude: number) => {
        let aux: any = [];

        refundQtd.forEach((e: any, index: number) => {
            aux.push(index !== indexToExclude ? e : null);
        });

        setRefundQtd(() => [...aux]);
    }

    const handleChangePolicy = (event: any, index: number) => {
        setPolicyArray((prev: any) => prev.map((e: any, i: number) => { return (index !== i ? e : (e === '1' ? '0' : '1')) }));
        setValue(`refundPolicy[${index}].policy`, event)
    }

    useEffect(() => {
        if (isEdit || (reduxComboObject.startDate !== undefined && reduxComboObject.endDate !== undefined)) {
            let newStartDate = new Date(reduxComboObject.startDate).toLocaleDateString();
            let newEndDate = new Date(reduxComboObject.endDate).toLocaleDateString();

            setValue("beginDate", newStartDate);
            setStartDate(newStartDate);
            setValue("endDate", newEndDate);
            setEndDate(newEndDate);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (typeof startDate === typeof "" && startDate.includes('/')) {
            setValue("startDate", startDate.split("/").reverse().join("-"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate]);

    useEffect(() => {
        if (typeof endDate === typeof "" && endDate.includes('/')) {
            setValue("endDate", endDate.split("/").reverse().join("-"));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [endDate]);

    const onSubmit = (data: any) => {
        const comboCode = isEdit ? window.location.href.split('comboCode=')[1] : undefined;

        data.channels = data.salesChannel.map((elem: any) => { return { idSalesChannel: elem.value, minutesAdvance: 0, comboCode: comboCode } })
        delete data.salesChannel

        let ajusteRefund: any = [];

        if ([1, 2].includes(parseInt(watchRefundRule || 0))) {
            ajusteRefund = [{ "refundRule": parseInt(watchRefundRule), }]
        } else if (parseInt(watchRefundRule || 0) === 3) {
            refundQtd.forEach((elem: any, index: number) => {
                if (elem !== null) {
                    ajusteRefund.push(data.refundPolicy[index])
                }
            });
        }

        data.refundPolicy = ajusteRefund;

        data.endDate = data.endDate.split('/').reverse().join('-');

        data.startDate = data.startDate.split('/').reverse().join('-');

        if(data.isOpen === 'undefined') data.isOpen = '2';
        if(data.isOpen === '2') data.maxDaysAfterChooseInitialDate = 0;

        if (isEdit) {
            // if(data.idComissionRange !== reduxComboObject.idComissionRange){ //mudou a comissão, precisa alterar os markups de acordo                
            //     dispatch(addToComboObject(JSON.stringify(data)));
            //     action("markup");
            // } else {
            editFunction(data);
            //}
        } else {
            dispatch(addToComboObject(JSON.stringify(data)));
            action("summaryAndDetails");
        }
    }



    return (
        <>
            {
                (isEdit === false || reduxComboObject?.name) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content">
                            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-primary pt-title mb-0">Operação</h3>

                                <div className="pt-content-info">
                                    <Row>
                                        <div className="d-flex justify-content-between">
                                            <h5 className="mb-0 pt-subtitle">Operação de venda</h5>
                                        </div>

                                        <Form.Group as={Col} md="4" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Canais de venda
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="salesChannel"
                                                rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                                                render={({ field }: any) => (
                                                    <GetSalesChannel
                                                        propsField={field}
                                                        propsErrors={errors}
                                                        setValue={setValue}
                                                        setSalesChannelObj={() => { }}
                                                        infoEdit={reduxComboObject?.channels && reduxComboObject.channels}
                                                    />
                                                )}
                                            />

                                            <ErrorMessage
                                                errors={errors}
                                                name="salesChannel"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Antecedência de compra
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="buyingAdvance"
                                                defaultValue={(reduxComboObject?.buyingAdvance && reduxComboObject.buyingAdvance) || '10h'}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Por favor, informe a antecedência de compra",
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <div className="endpoint">
                                                        <Form.Select
                                                            {...field}
                                                            aria-invalid={
                                                                errors?.buyingAdvance ? "true" : ""
                                                            }
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                        >
                                                            {buyingAdvanceOptions.map((item: any) => {
                                                                return (
                                                                    <>
                                                                        <option value={item.includes("Minutos") ? `${item.split(" ")[0]}min` : `${item.split(" ")[0]}h`}>{item}</option>
                                                                    </>
                                                                )
                                                            })}

                                                        </Form.Select>
                                                    </div>
                                                )}
                                            />

                                            <ErrorMessage
                                                errors={errors}
                                                name="buyingAdvance"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" controlId="" className="mt-4">
                                            <Form.Label className="label-small">
                                                Range de comissão
                                            </Form.Label>

                                            <Controller
                                                control={control}
                                                name="idComissionRange"
                                                defaultValue={(reduxComboObject?.idComissionRange && reduxComboObject.idComissionRange) || '4'}
                                                rules={{ required: { value: true, message: "Por favor, informe o tipo de operação" } }}
                                                render={({ field }: any) => (
                                                    <GetAllBadges propsField={field} />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="idComissionRange"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" className="mt-4">
                                            <Form.Label className="label-small">Data Início</Form.Label>

                                            <Controller
                                                control={control}
                                                name="startDate"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Por favor, informe a data",
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <InputGroup hasValidation className="" size="sm">
                                                        <SingleCalendarWithDefaultValue
                                                            date={startDate}
                                                            setValue={setStartDate}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="startDate"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" className="mt-4">
                                            <Form.Label className="label-small">Data Fim</Form.Label>

                                            <Controller
                                                control={control}
                                                name="endDate"
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: "Por favor, informe a data",
                                                    },
                                                }}
                                                render={({ field }: any) => (

                                                    <InputGroup hasValidation className="" size="sm">
                                                        <SingleCalendarWithDefaultValue
                                                            {...field}
                                                            date={endDate}
                                                            setValue={setEndDate}
                                                            pastDateToDisable={startDate}
                                                        />
                                                    </InputGroup>
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name="endDate"
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Form.Group>

                                        <Form.Group as={Col} md="4" className="mt-4">
                                            <Form.Label className="label-small">É Open Combo?</Form.Label>

                                            <Controller
                                                control={control}
                                                name="isOpen"
                                                defaultValue={`${reduxComboObject?.isOpen}` || '2'}
                                                render={({ field }: any) => (
                                                    <div className={"endpoint"}>
                                                        <Form.Select
                                                            {...field}
                                                            as="select"
                                                            variant="standard"
                                                            margin="normal"
                                                            required
                                                            autoComplete='off'
                                                        >
                                                            <option value='2'>Não</option>
                                                            <option value='1'>Sim</option>
                                                        </Form.Select>
                                                    </div>

                                                )}
                                            />
                                        </Form.Group>

                                        {
                                            watchIsOpen === '1' &&
                                            <Form.Group as={Col} md="4" className="mt-4">
                                                <Form.Label className="label-small">Dias após a compra</Form.Label>

                                                <Controller
                                                    control={control}
                                                    name="maxDaysAfterChooseInitialDate"
                                                    rules={{
                                                        required: {
                                                            value: true,
                                                            message: "Por favor, informe esse campo",
                                                        },
                                                    }}
                                                    defaultValue={reduxComboObject?.maxDaysAfterChooseInitialDate || ''}
                                                    render={({ field }: any) => (
                                                        <Form.Control
                                                        {...field}
                                                        type="text"
                                                        variant="standard"
                                                        margin="normal"
                                                        autoComplete='off'
                                                        onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                    />
                                                    )}
                                                />
                                            </Form.Group>
                                        }
                                    </Row>
                                </div>

                                <div className="pt-content-info" style={{ border: "none" }}>
                                    <div>
                                        <h5 className="mb-2 pt-subtitle">Regras de cancelamento</h5>
                                    </div>
                                    <Form.Group as={Col} md="6" controlId="" className="my-4">
                                        <Form.Label className="label-small">
                                            Se o hóspede quiser cancelar o pedido, como calcular a
                                            taxa de cancelamento?
                                        </Form.Label>

                                        <Row className="mx-0 mt-2 gap-2 flex-column">
                                            <Controller
                                                control={control}
                                                name={`refundPolicy[0].refundRule`}
                                                defaultValue={(reduxComboObject?.refundPolicy?.length > 0 && reduxComboObject.refundPolicy[0].refundRule) || null}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "Por favor, informe o tipo de cancelamento",
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        className=""
                                                        type="radio"
                                                        //id={"radio-1"}
                                                        value={1}
                                                        //defaultChecked={true}
                                                        defaultChecked={reduxComboObject?.refundPolicy?.length > 0 && parseInt(reduxComboObject.refundPolicy[0].refundRule) === 1}
                                                        required
                                                        label={"Reembolso total"}
                                                    //checked={getValues().refundPolicy[0].refundRule === "1"}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`refundPolicy[0].refundRule`}
                                                defaultValue={reduxComboObject?.refundPolicy?.length > 0 && reduxComboObject.refundPolicy[0].refundRule}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "Por favor, informe o tipo de cancelamento",
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        className=""
                                                        type="radio"
                                                        //id={"radio-2"}
                                                        value={2}
                                                        defaultChecked={reduxComboObject?.refundPolicy?.length > 0 && parseInt(reduxComboObject.refundPolicy[0].refundRule) === 2}
                                                        required
                                                        label={"Sem reembolso"}
                                                    //checked={getValues().refundPolicy[0].refundRule === "2"}
                                                    />
                                                )}
                                            />
                                            <Controller
                                                control={control}
                                                name={`refundPolicy[0].refundRule`}
                                                defaultValue={reduxComboObject?.refundPolicy?.length > 0 && reduxComboObject.refundPolicy[0].refundRule}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message:
                                                            "Por favor, informe o tipo de cancelamento",
                                                    },
                                                }}
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        className=""
                                                        type="radio"
                                                        //id={"radio-2"}
                                                        value={3}
                                                        defaultChecked={reduxComboObject?.refundPolicy?.length > 0 && parseInt(reduxComboObject.refundPolicy[0].refundRule) === 3}
                                                        required
                                                        label={"Definir regras de cancelamento"}
                                                    //checked={getValues().refundPolicy[0].refundRule === "3"}
                                                    />
                                                )}
                                            />
                                            <ErrorMessage
                                                errors={errors}
                                                name={`refundPolicy[0].refundRule`}
                                                render={({ message }) => (
                                                    <small style={{ color: "red" }}>{message}</small>
                                                )}
                                            />
                                        </Row>
                                    </Form.Group>

                                    {(watchRefundRule === 3 || watchRefundRule === "3") && (
                                        <>
                                            {refundQtd.map((elem: any, index: number) => {
                                                if (elem !== null) {
                                                    return (
                                                        <>
                                                            <div className="custom-cancelation-type">
                                                                <Row>
                                                                    <Col md="11">
                                                                        <Row>
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md="4"
                                                                                controlId=""
                                                                                className="mt-4"
                                                                            >
                                                                                <Form.Label className="label-small">
                                                                                    Dias antes do Check-in
                                                                                </Form.Label>

                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`refundPolicy[${index}].days`}
                                                                                    defaultValue={index < reduxComboObject?.refundPolicy?.length && reduxComboObject.refundPolicy[index].days}
                                                                                    rules={{
                                                                                        required: {
                                                                                            value: true,
                                                                                            message:
                                                                                                "Por favor, informe esse campo",
                                                                                        },
                                                                                    }}
                                                                                    render={({ field }: any) => (
                                                                                        <Form.Control
                                                                                            {...field}
                                                                                            type="number"
                                                                                            variant="standard"
                                                                                            aria-invalid={
                                                                                                errors[`refundPolicy[${index}].days`] ? "true" : ""
                                                                                            }
                                                                                            margin="normal"
                                                                                            required
                                                                                            min={0}
                                                                                            autoComplete='off'
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <ErrorMessage
                                                                                    errors={errors}
                                                                                    name={`refundPolicy[${index}].days`}
                                                                                    render={({ message }) => (
                                                                                        <small style={{ color: "red" }}>
                                                                                            {message}
                                                                                        </small>
                                                                                    )}
                                                                                />
                                                                            </Form.Group>

                                                                            <Form.Group
                                                                                as={Col}
                                                                                md="4"
                                                                                controlId=""
                                                                                className="mt-4"
                                                                            >
                                                                                <Form.Label className="label-small">
                                                                                    Política
                                                                                </Form.Label>

                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`refundPolicy[${index}].policy`}
                                                                                    defaultValue={(index < reduxComboObject?.refundPolicy?.length && reduxComboObject.refundPolicy[index].policy) || '1'}
                                                                                    rules={{
                                                                                        required: {
                                                                                            value: false,
                                                                                            message:
                                                                                                "Por favor, informe esse campo",
                                                                                        },
                                                                                    }}
                                                                                    render={({ field }: any) => (
                                                                                        <div className={"endpoint"}>
                                                                                            <Form.Select
                                                                                                {...field}
                                                                                                as="select"
                                                                                                variant="standard"
                                                                                                margin="normal"
                                                                                                required
                                                                                                onChange={(e: any) => { handleChangePolicy(e.target.value, index) }}
                                                                                            >

                                                                                                {/* <option value="1">Percentagem</option>
                                                                                                <option value="0">Sem custo</option> */}
                                                                                                <option value="1">Dias após a compra</option>
                                                                                                <option value="2">Dias antes do evento</option>

                                                                                            </Form.Select>
                                                                                        </div>
                                                                                    )}
                                                                                />
                                                                                <ErrorMessage
                                                                                    errors={errors}
                                                                                    name={`refundPolicy[${index}].policy`}
                                                                                    render={({ message }) => (
                                                                                        <small style={{ color: "red" }}>
                                                                                            {message}
                                                                                        </small>
                                                                                    )}
                                                                                />
                                                                            </Form.Group>

                                                                            {/* {(policyArray !== null && policyArray[index] === '1') && */}
                                                                            <Form.Group
                                                                                as={Col}
                                                                                md="4"
                                                                                controlId=""
                                                                                className="mt-4"
                                                                            >
                                                                                <Form.Label className="label-small">
                                                                                    Porcentagem
                                                                                </Form.Label>

                                                                                <Controller
                                                                                    control={control}
                                                                                    name={`refundPolicy[${index}].percentage`}
                                                                                    defaultValue={index < reduxComboObject?.refundPolicy?.length ? reduxComboObject.refundPolicy[index].percentage : '100'}
                                                                                    rules={{
                                                                                        required: {
                                                                                            value: true,
                                                                                            message:
                                                                                                "Por favor, informe esse campo",
                                                                                        },
                                                                                    }}
                                                                                    render={({ field }: any) => (
                                                                                        <Form.Control
                                                                                            {...field}
                                                                                            type="text"
                                                                                            aria-invalid={
                                                                                                errors[`refundPolicy[${index}].percentage`] ? "true" : ""
                                                                                            }
                                                                                            maxLength={3}
                                                                                            variant="standard"
                                                                                            margin="normal"
                                                                                            onBlur={(e: any) => { if (Number(e.target.value || 0) > 100) setValue(`refundPolicy[${index}].percentage`, '100') }}
                                                                                            onKeyPress={(e) => !/[0-9]/.test(e.key) && e.preventDefault()}
                                                                                            required
                                                                                            autoComplete='off'
                                                                                        />
                                                                                    )}
                                                                                />
                                                                                <ErrorMessage
                                                                                    errors={errors}
                                                                                    name={`refundPolicy[${index}].percentage`}
                                                                                    render={({ message }) => (
                                                                                        <small style={{ color: "red" }}>
                                                                                            {message}
                                                                                        </small>
                                                                                    )}
                                                                                />
                                                                            </Form.Group>
                                                                            {/* } */}

                                                                            <Controller
                                                                                control={control}
                                                                                name={`refundPolicy[${index}].refundRule`}
                                                                                defaultValue={3}
                                                                                render={({ field }: any) => (
                                                                                    <Form.Control
                                                                                        {...field}
                                                                                        type="hidden"
                                                                                        variant="standard"
                                                                                        margin="normal"
                                                                                        autoComplete="off"
                                                                                    />
                                                                                )}
                                                                            />
                                                                        </Row>
                                                                    </Col>
                                                                    <Col md="1">
                                                                        {refundQtd.filter((e: any) => e !== null).length > 1 &&
                                                                            <div className="d-flex align-items-end justify-content-center h-100">
                                                                                <FontAwesomeIcon
                                                                                    icon={["fal", "trash-alt"]}
                                                                                    className="mb-4"
                                                                                    style={{ cursor: "pointer" }}
                                                                                    onClick={() => {
                                                                                        handleDeleteRefund(index);
                                                                                    }}
                                                                                />
                                                                            </div>
                                                                        }
                                                                    </Col>
                                                                </Row>


                                                            </div>
                                                        </>
                                                    )
                                                } else {
                                                    return (<></>)
                                                }
                                            })}
                                            <div className="d-flex justify-content-center mt-5">
                                                <div
                                                    className="btn-add-details"
                                                    onClick={handleRefund}
                                                >
                                                    <FontAwesomeIcon
                                                        icon={["fal", "plus"]}
                                                        size="1x"
                                                        className="mr-2"
                                                    />
                                                    Adicionar
                                                </div>
                                            </div>

                                        </>
                                    )}

                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {
                                        isEdit !== true
                                            ?
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action('dataCombo')}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Continuar
                                                </Button>
                                            </Row>
                                            :
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                //onClick={() => action('meetingPoint')}
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                    }
                                </div>
                            </Form>
                        </Card>
                    </Col >
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
})

export default Operation;

