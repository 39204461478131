import React, { useEffect, useState } from "react";
import api from "../../services/api";

//componentes
import DesktopDefault from "../../templates/DesktopDefault";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import CommissionsFilter from "./Components/CommissionsFilter/CommissionsFilter";
import CommissionsTable from "./Components/CommissionsTable/CommissionsTable";
import CommissionsBills from "./Components/CommissionsBills/CommissionsBills";
import { useTranslation } from "react-i18next";

function Commissions() {
  const { t } = useTranslation();
  const [commissions, setCommissions] = useState<any>([]);
  const [cardsInfo, setCardsInfo] = useState<any>(null);
  const [loading, setLoading] = useState<any>(false);

  //filtragem
  const [actualName, setActualName] = useState<any>("");
  const [actualStatus, setActualStatus] = useState<any>(null);
  const [actualTourcodeOrNot, setActualTourcodeOrNot] = useState<any>(1);
  const [actualDate, setActualDate] = useState<any>([]);
  //fim da filtragem

  /* Pagination */
  const [totalRows, setTotalRows] = useState<any>();
  const [pageCount, setPageCount] = useState<any>(1);
  const [rowsPerPage, setRowsPerPage] = useState<any>(10);
  const [allowSearch, setAllowSearch] = useState<any>(1);
  /* END - Pagination */

  function clearActualValues() {
    //setActualName("");
    //setActualStatus(0);
    setActualDate([]);
  }

  useEffect(() => {
    var today: any = new Date();

    buscar('', 0, 1, [`${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`, `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  async function buscar(name: any, status: any, tourcodeOrNot: any, date: any) {
    setCommissions(null);
    setLoading(true);
    setActualName(name);
    setActualDate(date);
    setActualStatus(status);
    setActualTourcodeOrNot(tourcodeOrNot);
    //setPageCount(1);

    if (pageCount !== 1) {
      setPageCount(1);
    } else {
      setAllowSearch(allowSearch + 1);
    }
  }

  useEffect(() => {
    async function listCommissions() {
      setCommissions(null);
      setLoading(true);

      let beginDate = []
      let endDate = []

      if (actualDate?.length === 1) {
        beginDate = actualDate[0].split('/')
      } else if (actualDate?.length === 2) {
        beginDate = actualDate[0].split('/')
        endDate = actualDate[1].split('/')
      }

      let today: any = new Date();

      const token = localStorage.getItem("GroupId") || "{}";
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const { data } = await api.post(
          "/AgentStatement/GetAgentStatementByFilterAsync",
          {
            page: pageCount,
            rowsPerPage: rowsPerPage,
            name: actualName,
            status: actualStatus === '' ? 0 : parseInt(actualStatus),
            tourcodeOrNot: actualTourcodeOrNot === '' ? 0 : parseInt(actualTourcodeOrNot),
            beginDate: (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
            endDate: (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
            orderByField: "",
          },
          config
        );

        if (data.status !== 400) {
          setLoading(false);
          setCommissions(data.data.data.agentStatementGridDTO.rows);
          setTotalRows(data.data.data.agentStatementGridDTO.rowsCount);
          setCardsInfo(data.data.data);
        }
      } catch (error: any) {
        setLoading(false);
      }
    }
    if (actualStatus !== null) {
      listCommissions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageCount, rowsPerPage, allowSearch]);
  async function exportCommissions() {

    let beginDate = []
    let endDate = []

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    let today: any = new Date();

    const token = localStorage.getItem("GroupId") || "{}";

    try {
      const exp = await api.post('/AgentStatement/GetComissionAgentByFilterExcelAsync',
        {
          page: 0,
          rowsPerPage: 0,
          name: actualName,
          status: actualStatus === '' ? 2 : parseInt(actualStatus),
          tourcodeOrNot: actualTourcodeOrNot === '' ? 1 : parseInt(actualTourcodeOrNot),
          beginDate: (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          endDate: (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
          orderByField: "",
        },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "*/*"
            //"Access-Control-Allow-Origin": "*"

          },
          responseType: 'blob'
        },

      );
      if (exp.status !== 400) {
        const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute(
          'download',
          'comissões.xlsx',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);
      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        //window.location.href = window.location.origin + '/';
      }
    }
  };

  async function printCommissions() {

    let beginDate = []
    let endDate = []

    if (actualDate.length === 1) {
      beginDate = actualDate[0].split('/')
    } else if (actualDate.length === 2) {
      beginDate = actualDate[0].split('/')
      endDate = actualDate[1].split('/')
    }

    let today: any = new Date();

    const token = localStorage.getItem("GroupId") || "{}";

    try {
      const print = await api.post('/AgentStatement/GetCommissionAgentByFilterPDFAsync',
      {
        page: 0,
        rowsPerPage: 0,
        name: actualName,
        status: actualStatus === '' ? 2 : parseInt(actualStatus),
        tourcodeOrNot: actualTourcodeOrNot === '' ? 1 : parseInt(actualTourcodeOrNot),
        beginDate: (actualDate.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
        endDate: (actualDate.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (actualDate !== null && actualDate.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
        orderByField: "",
      },
        {
          headers: {
            "Authorization": `Bearer ${token}`,
            "Content-Type": "application/json",
            "Accept": "*/*"

          },
          responseType: 'blob'
        },

      );
      if (print.status !== 400) {
        const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
        const objectUrl = window.URL.createObjectURL(blob);

        const link = document.createElement('a');
        link.href = objectUrl;
        link.setAttribute(
          'download',
          'comissões.pdf',
        );

        // Append to html link element page
        document.body.appendChild(link);

        // Start download
        link.click();

        // // Clean up and remove the link
        link?.parentNode?.removeChild(link);


      }
    } catch (error: any) {
      if (error?.response?.status === 401) {
        //window.location.href = window.location.origin + '/';
      }
    }
  };


  return (
    <>
      <DesktopDefault>
        <div className="container-fluid content-dashboard">
          <Breadcrumb title={t("commissions.title")} />

          <div className="mt-4 d-flex justify-content-between align-items-center">
            <h2 className="title" style={{ color: "#707070" }}>
            {t("commissions.title")}
            </h2>
          </div>
          <CommissionsBills cardsInfo={cardsInfo} t={t} />

          <CommissionsFilter
            buscar={buscar}
            commissions={commissions}
            clearActualValues={clearActualValues}
            t={t}
          />
          <CommissionsTable
            commissions={commissions}
            totalRows={totalRows}
            pageCount={pageCount}
            setPageCount={setPageCount}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={setRowsPerPage}
            actualDate={actualDate}
            loading={loading}
            printCommissions={printCommissions}
            exportCommissions={exportCommissions}
            t={t}
          />

        </div>
      </DesktopDefault>
    </>
  );
}
export default Commissions;
