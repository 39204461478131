import React from 'react';
import DatePicker, { DateObject } from "react-multi-date-picker";
import configData from "../../config/config.json";
import './Calendar.scss';

export interface propsMultipleRange {
    field: any;
    values: any;
    setValues: any;
    totalRange: any;
    setId: any;
    setSelectedRange: any;
}

const MultipleRange: React.FC<propsMultipleRange> = ({
    field, values, setValues, totalRange, setId, setSelectedRange
}: propsMultipleRange) => {
    return (
        <DatePicker
            {...field}
            maxDate={new Date(totalRange.endDate)}
            minDate={new Date(totalRange.startDate)}
            currentDate={new DateObject(totalRange.startDate)}
            onOpen={() => { setSelectedRange(() => null) }}
            multiple
            range
            value={values}
            onChange={(e: any) => {
                if (e[e.length - 1].length === 2) {
                    setId((prev: any) => {
                        if (!prev.includes(`${new Date(e[e.length - 1][0]).toJSON().slice(0, 10).split('-').join('')}${e[e.length - 1].length === 2 ? new Date(e[e.length - 1][1]).toJSON().slice(0, 10).split('-').join('') : ''}`)) {
                            return [...prev, `${new Date(e[e.length - 1][0]).toJSON().slice(0, 10).split('-').join('')}${e[e.length - 1].length === 2 ? new Date(e[e.length - 1][1]).toJSON().slice(0, 10).split('-').join('') : ''}`];
                        } else {
                            return [...prev];
                        }
                    })
                }

                setValues(() => e)
            }}
            render={
                <input
                    className="form-control bg-white frm-sm w-100 rounded"
                    id={'singleCalendarId'}
                    autoComplete='off'
                />
            }
            dateSeparator=" até "
            multipleRangeSeparator="|"
            format={configData.DATE.PT}
        />
    )
}

export default MultipleRange;