import React, { useEffect, useState, Key } from 'react';
import api from "../../../../services/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { VerticalTimeline, VerticalTimelineElement } from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import './ReservationsLog.scss';
import { Badge } from 'react-bootstrap';

export interface propInfo {
    id: any;
    setLogModal: any;
};

const ReservationsLog: React.FC<propInfo> = ({
    id, setLogModal
}: propInfo) => {
    const [item, setItem] = useState<any>(null);
    const [logs, setLogs] = useState<any>(null);
    const [logsActivities, setLogsActivities] = useState<any>(null);
    const [emails, setEmails] = useState<any>(null);
    //const [timeline, setTimeLine] = useState<any>(null);


    useEffect(() => {
        const getDetails = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.get(`/NewReservations/GetNewReservationsByIdAsync/${id}`, config);
                if (res.status !== 400) {
                    setItem(res.data.data);
                    setLogs(res.data.data.logs);
                    setLogsActivities(res.data.data.logsActions);
                    setEmails(res.data.data.messageStatusSends);
                }
            } catch (error: any) {

            }
        };
        if (id !== null) {
            getDetails();
        }
    }, [id]);

    useEffect(() => {
        /* if (item !== null) {
        let aux: any = [...item.logs, ...item.messageStatusSends]
        let item1: any;
            let item2: any;

            aux.sort(function (a: any, b: any) {
                item1 = a.idMessage ? a.dateMessage : a.dateUpdated
                item2 = b.idMessage ? b.dateMessage : b.dateUpdated
                return item1 - item2
            })

            

        } */

        /* if (item !== null) {
            setTimeLine([...item.logs, ...item.messageStatusSends])
        } */
    }, [item])


    if (item !== null) {
        return (
            <>
                <div className="modal-header rounded-0">
                    <div>
                        <h4 className="pt-1 text-primary title-reservation">Histórico da reserva</h4>
                    </div>

                    <div className='buttons-options'>
                        <div>
                            <FontAwesomeIcon
                                icon={['fal', 'times']}
                                className="mx-3 text-info"
                                size="lg"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setLogModal(false); }}
                            />
                        </div>
                    </div>
                </div>
                <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />

                <div className="title"><h5 className="text-center">Logs de atividades</h5></div>
                <VerticalTimeline
                    layout={'1-column-left'}
                    lineColor={'#076fa2'}
                >
                    {logsActivities?.length > 0 ?
                        logsActivities.map((elem: any, index: Key) => {
                            return (
                                <>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work mb-40px"
                                        iconStyle={{ background: '#076fa2', color: '#fff', boxShadow: 'none' }}
                                        contentStyle={{ background: '#F2F2F2', borderRadius: '15px' }}
                                        contentArrowStyle={{ borderRight: '7px solid #F2F2F2' }}
                                        icon={<FontAwesomeIcon
                                            icon={["fal", "file-alt"]}
                                            className="w-100"
                                            style={{ marginLeft: '-20px' }}
                                        />}
                                        key={index}
                                    >
                                        <>
                                            <div className="row pt-3 mb-20px">
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Localizador</strong></p>
                                                    <p className='m-0'>{elem.bookingLoc}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Data da atividade</strong></p>
                                                    <p className='m-0'>{elem.data.split('T').join(' - ')}</p>
                                                </div>

                                            </div>
                                            <div className="row">
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Atividade</strong></p>
                                                    <p className='m-0'>{`${elem?.texto} ${elem.nomeUsuario}`}</p>
                                                </div>
                                            </div>
                                        </>

                                    </VerticalTimelineElement>
                                </>
                            )
                        })
                        :
                        <>
                            <div className="d-flex justify-content-center text-center">
                                <h5>Nenhuma atividade encontrado</h5>
                            </div>
                        </>
                    }
                </VerticalTimeline>

                <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />

                <div className="title"><h5 className="text-center">Logs da reserva</h5></div>
                <VerticalTimeline
                    layout={'1-column-left'}
                    lineColor={'#076fa2'}
                >
                    {logs?.length > 0 ?
                        logs.map((elem: any, index: Key) => {
                            return (
                                <>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work mb-40px"
                                        iconStyle={{ background: '#076fa2', color: '#fff', boxShadow: 'none' }}
                                        contentStyle={{ background: '#F2F2F2', borderRadius: '15px' }}
                                        contentArrowStyle={{ borderRight: '7px solid #F2F2F2' }}
                                        icon={<FontAwesomeIcon
                                            icon={["fal", "file-alt"]}
                                            className="w-100"
                                            style={{ marginLeft: '-20px' }}
                                        />}
                                        key={index}
                                    >
                                        <>
                                            <div className="row pt-2">
                                                <div className="col-4 mb-20px">
                                                    <div className="d-flex">
                                                        <h5 style={{ width: "120px" }}><Badge className="ml-2 mr-4 mt-2 px-4 badge grey" style={{ width: "120px" }}>Log</Badge></h5>
                                                    </div>
                                                </div>
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Usuário</strong></p>
                                                    <p className='m-0'>{elem.usuario}</p>
                                                </div>
                                                <div className="col-4 mb-20px">
                                                    <p className='m-0'>{/* {`${elem.channelName} foi enviado para:`} */}</p>
                                                    <p className='m-0'>{/* {elem.recipient} */}</p>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Data da atualização</strong></p>
                                                    <p className='m-0'>{elem.dateUpdated.split('T').join(' - ')}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Telefone</strong></p>
                                                    <p className='m-0'>{elem.telefone}</p>
                                                </div>
                                                <div className="col-4">
                                                    <p className="p-0 mb-10px text-primary"><strong>Mensagem de retorno</strong></p>
                                                    <p className='m-0'>{elem.internalNotesItem}</p>
                                                    {/* <p className="p-0 mb-10px text-primary"><strong>Idioma</strong></p>
                                                    <p className='m-0'>{elem.idioma}</p> */}
                                                </div>
                                            </div>
                                            {/*  <div className="mt-3 row">
                                                <div className="col-12">
                                                    <p className="p-0 mb-10px text-primary"><strong>Mensagem de retorno</strong></p>
                                                    <p className='m-0'>{elem.internalNotesItem}</p>
                                                </div>
                                            </div> */}
                                        </>

                                    </VerticalTimelineElement>
                                </>
                            )
                        })
                        :
                        <>
                            <div className="d-flex justify-content-center text-center">
                                <h5>Nenhum log encontrado</h5>
                            </div>
                        </>
                    }
                </VerticalTimeline>

                <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />
                <div className="title"><h5 className="text-center">Emails Reminder</h5></div>

                <VerticalTimeline
                    layout={'1-column-left'}
                    lineColor={'#076fa2'}
                >
                    {emails?.length > 0 ?
                        emails.map((elem: any, index: Key) => {
                            return (
                                <>
                                    <VerticalTimelineElement
                                        className="vertical-timeline-element--work mb-40px"
                                        iconStyle={{ background: '#076fa2', color: '#fff', boxShadow: 'none' }}
                                        contentStyle={{ background: '#F2F2F2', borderRadius: '15px' }}
                                        contentArrowStyle={{ borderRight: '7px solid #F2F2F2' }}
                                        icon={<FontAwesomeIcon
                                            icon={["fal", "envelope"]}
                                            className="w-100"
                                            style={{ marginLeft: '-20px' }}
                                        />}
                                        key={index}
                                    >
                                        <>
                                            <div className="row">
                                                <div className="col-4  mb-20px">
                                                    <div className="d-flex align-items-center">
                                                        {
                                                            elem.statusSend === "DELIVERED"
                                                                ?
                                                                <h5 style={{ width: "120px" }}><Badge className="ml-2 mr-4 mt-2 px-4" bg={'success'} style={{ width: "120px" }}>Entregue</Badge></h5>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            elem.statusSend === "SEND"
                                                                ?
                                                                <h5 style={{ width: "120px" }}><Badge className="ml-2 mr-4 mt-2 px-4 badge grey" style={{ width: "120px" }}>Enviado</Badge></h5>
                                                                :
                                                                <></>
                                                        }
                                                        {
                                                            elem.statusSend === "NOT_SEND"
                                                                ?
                                                                <h5 style={{ width: "120px" }}><Badge className="ml-2 mr-4 mt-2 px-4" bg={'danger'} style={{ width: "120px" }}>Não entregue</Badge></h5>
                                                                :
                                                                <></>
                                                        }
                                                    </div>
                                                </div>
                                                <div className="col-4  mb-20px"></div>
                                                <div className="col-4  mb-20px"></div>
                                            </div>
                                            <div className="row">
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Data do Envio</strong></p>
                                                    <p className='m-0'>{elem.dateMessage.split('T').join(' - ')}</p>
                                                </div>
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Canal</strong></p>
                                                    <p className='m-0'>{`${elem.channelName} foi enviado para:`}</p>
                                                    <p className='m-0'>{elem.recipient}</p>
                                                </div>
                                                <div className="col-4 mb-20px">
                                                    <p className="p-0 mb-10px text-primary"><strong>Mensagem de retorno</strong></p>
                                                    <p className='m-0'>{elem.messageType}</p>
                                                </div>
                                            </div>
                                        </>

                                    </VerticalTimelineElement>
                                </>
                            )
                        })
                        :
                        <>
                            <div className="d-flex justify-content-center text-center">
                                <h5>Nenhum email encontrado</h5>
                            </div>
                        </>
                    }

                </VerticalTimeline>
            </>
        );
    } else {
        return (
            <>
                <div className="modal-header rounded-0">
                    <div className='animated-background' style={{ height: '50px', width: '100%' }}>
                    </div>

                    <div className='buttons-options'>
                        <div>
                            <FontAwesomeIcon
                                icon={['fal', 'times']}
                                className="mx-3 text-info"
                                size="lg"
                                data-dismiss="modal"
                                aria-label="Close"
                                onClick={() => { setLogModal(false); }}
                            />
                        </div>
                    </div>
                </div>
                <hr className='w-100 my-4' style={{ borderBottom: '1px solid' }} />
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
                <div className='animated-background' style={{ height: '196px', width: '100%', borderRadius: '15px', marginBottom: '40px' }}></div>
            </>
        );
    }

}

export default ReservationsLog;