import React, { useState } from 'react';

//COMPONENTS
// import NewDesktop from '../../templates/NewDesktop';
import DesktopDefault from '../../templates/DesktopDefault';
import BannerHome from './components/BannerHome/BannerHome';
import TabSearchs from './components/TabSearchs/TabSearchs';

import TourList from './components/TourList/TourList';
import Transfers from './components/Transfers/Transfers';
//import { useTranslation } from 'react-i18next';


function Sales() {
    const [search, setSearch] = useState<any>(null);
    const [selected, setSelected] = useState<any>(1);
    const [eventDate, setEventDate] = useState<any>("");

    const [isLoading, setIsLoading] = useState<any>(false);
    const [toggleAction, setToggleAction] = useState<boolean>(false);

    //const { t } = useTranslation();

    return (
        <DesktopDefault>
            <div className="navTabHome">
                <BannerHome />
                <TabSearchs
                    search={search}
                    setSearch={setSearch}
                    setSelected={setSelected}
                    isLoading={isLoading}
                    eventDate={eventDate}
                    setEventDate={setEventDate}
                    setToggleAction={setToggleAction}
                />

                {
                    selected === 1 && search !== null
                        ?
                        <TourList
                            search={search}
                            setIsLoading={setIsLoading}
                            eventDate={eventDate}
                            toggleAction={toggleAction}
                        />
                        :
                        selected === 2 && search !== null
                            ?
                            <Transfers
                                search={search}
                                setSearch={setSearch}
                            />
                            :
                            <></>
                }

            </div>

        </DesktopDefault>
    );
}

export default Sales;