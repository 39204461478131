import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import api from '../../services/api';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  setSupplier?:any
};

const GetAgents: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setSupplier
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/BillsReceivable/GetAgents`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  }, []);

  function handleChange(info:any) {
    if(setSupplier) setSupplier(info.supplierId);
    propsField.onChange(info);
  }


  if (info !== null) {
    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error input-select-custom w-100" : "endpoint input-select-custom w-100"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="supplier"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t("accordionFinancial.select.select")}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.supplierId}`}
            getOptionLabel={(option: any) => `${option.compName}`}
            onChange={handleChange}
            value={info.find((a:any) => a.supplierId === propsField.value)}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetAgents;