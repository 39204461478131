import React from 'react';
import { Card } from "react-bootstrap";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

export interface propCard {
    info: any,
    t?: any,
};

const DetailsCkeckoutTransfer: React.FC<propCard> = ({
    info, t
}: propCard) => {
    const totalPrice: any = info.sellingType === 1 ? info.priceGlobalPeople : info.globalPeople * info.priceGlobalPeople;

    return (
        <>
            <Card className="card-unit bg-white">
                <div className="container-fluid">

                <FontAwesomeIcon
                        className='icon-primary'
                        icon={["fad", "car-bus"]}
                        size="1x"
                        style={{ marginBottom: "5px", width: "25px", fontSize: "1.5rem"}}
                    />
                    <h3 className="details-title">{info.productName}</h3>
                    <div className="details-date d-flex justify-content-between">
                        <small>{t("checkout.detailsCheckout.date")} {info.date.split('-').reverse().join('/')}</small>
                        <small>{t("checkout.detailsCheckout.hour")} {info.time}</small>
                    </div>

                    <div className='details-boarding'>
                        <p><strong>{t("checkout.detailsCheckout.boarding")}</strong>{` ${info.commingSource}`}</p>
                        <p><strong>{t("checkout.detailsCheckout.address")}</strong>{` ${info.goingSource}`}</p>
                        <p><strong>{t("checkout.detailsCheckout.boardingHour")}</strong>{` ${info.time}`}</p>
                    </div>

                    <hr />

                    <div className="details-price">
                        <div className="price-per">
                            <p>{t("checkout.detailsCheckout.passengers")}</p>
                            <p>{info.sellingType === 2 ? info.globalPeople : info.totalPeople}x</p>
                            <p>R$ {(totalPrice).toFixed(2).replace('.', ',')}</p>
                        </div>
                        {/* <div className="price-per">
                            <p>Crianças:</p>
                            <p>{info.childs}x</p>
                            <p>R$ {info.childs * info.priceGlobalPeople},00</p>
                        </div>
                        <div className="price-per">
                            <p>Infantos:</p>
                            <p>{info.infants}x</p>
                            <p>R$ {info.infants * info.priceGlobalPeople},00</p>
                        </div> */}
                    </div>

                    <hr />

                    <div className='total-price'>
                        <p><strong>R$ {(totalPrice).toFixed(2).replace('.', ',')}</strong></p>
                    </div>

                </div>
            </Card>
        </>
    );

}

export default DetailsCkeckoutTransfer;