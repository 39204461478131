import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Button, Card, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import GetResourcesType from "../../C2Points/GetResourcesType";
import GetResourceVehicleType from "../../C2Points/GetResourceVehicleType";
import GetSessionGroupId from "../../C2Points/GetSessionGroupId";
import GetSuppliersFilter from "../../C2Points/GetSuppliersFilter";
import api from "../../../services/api";

import { useAppSelector, useAppDispatch } from '../../../store/hooks';
import { addOperation } from "../../../store/product";
import GetResourceTicketType from "../../C2Points/GetResourceTicketType";
import GetResourceRestaurantType from "../../C2Points/GetResourceRestaurantType";
import GetProductsList from "../../../pages/Stock/components/ProductList/GetProductsList";

export interface operation {
    action: any;
    receiveObject: any;
    tourObject: any;
    isEdit: any;
    isTicket?: boolean;
    editFunction?: any;
};

const Operation: React.FC<operation> = React.memo(({
    action, receiveObject, tourObject, isEdit, isTicket = false, editFunction = () => { }
}: operation) => {
    /* Controle Redux  */
    const dispatch = useAppDispatch();
    const tourSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.tour)));
    /* Controle Redux  */

    const [integrationPurchaseType, setIntegrationPurchaseType] = useState<string>('1');

    const [controlLoop, setControlLoop] = useState<boolean>(false); //para controlar o bug do useeffect que possui "tourSIG" no array de dependência

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        watch,
        formState: { errors },
    } = useForm({});

    const watchOperationType = watch(`operationType`, '' + tourObject.operationType);     
    const watchResource = watch(`supplierByResourceModel`, tourObject.supplierByResourceModel);
    const watchSupplier = watch(`supplier`, '');
    const watchRepass = watch(`repass`, tourSIG.tour.repass + '' || '2');

    useEffect(() => {
        if (tourSIG.tour && controlLoop === false) {
            if (isTicket) {
                setValue('ticketSupplyType', tourSIG.tour.ticketSupplyType);
            }
            setControlLoop(true);
            setValue('isIntegration', tourSIG.tour?.integrationAccessType);
            setValue('operationType', tourSIG.tour.operationType);
            setValue('site', tourSIG.tour.site || tourObject.site === true || tourObject.site === 1 ? true : false);
            setValue('b2b', tourSIG.tour.b2b || tourObject.b2b === true || tourObject.b2b === 1 ? true : false);
            setValue('app', tourSIG.tour.app || tourObject.app === true || tourObject.app === 1 ? true : false);
            setValue('marketplaces', tourSIG.tour.marketPlace || tourObject.marketplaces === true || tourObject.marketplaces === 1 ? true : false);
            setValue('sessionGroupId', tourSIG.tour.sessionGroupId);
            setValue('repass', tourSIG?.tour?.repass ? tourSIG.tour.repass + '' : '2');
            console.log(tourSIG?.tour?.repass ? tourSIG.tour.repass + '' : '2')
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tourSIG])


    useEffect(() => {
        if (watchOperationType === null || watchOperationType === undefined) {
            setValue('operationType', '' + tourObject.operationType);
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchOperationType])

    useEffect(() => {
        const getIntegration = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const { data } = await api.get(`Dropdown/GetReservationSystemsBySupplierId/${watchSupplier?.supplierId}`, config);
                if (data.statusCode !== 400) {
                    if (data.data.length > 0) {
                        setIntegrationPurchaseType(data.data[0].id);
                    }
                }
            } catch (error: any) {
                console.log(error)
            }
        }

        if (watchSupplier?.supplierId) {
            getIntegration();
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [watchSupplier])

    const createTicketObject = (data: any) => {
        data.isTicket = true;

        data.integrationPurchaseType = integrationPurchaseType;
        data.integrationAccessType = data.isIntegration || "1"; //1 - Sim | 2 - Não
        data.ticketSupplyType = parseInt(data.ticketSupplyType);
        data.supplierId = data.supplier.supplierId;
        data.supplierIdentity = data.supplier.supplierIdentity;
        data.resourcesId = [];
        data.ourVehicleType = [];
        data.ourTicketType = [];
        data.ourRestaurantType = [];
        data.sessionGroupId = null;

        return data;
    }

    const createTourObject = (data: any) => {
        data.site = data.site === true || data.site === 1 ? true : false;
        data.b2b = data.b2b === true || data.b2b === 1 ? true : false;
        data.app = data.app === true || data.app === 1 ? true : false;
        data.marketplaces = data.marketplaces === true || data.marketplaces === 1 ? true : false;
        data.operationType = parseInt(data.operationType);

        if (data.operationType === 1) {
            //Operação própria
            data.integrationPurchaseType = '0';
            data.integrationAccessType = '2'; //1 - Sim | 2 - Não
        } else { //operationType === 2
            data.integrationPurchaseType = integrationPurchaseType;
            data.integrationAccessType = data.isIntegration || "1"; //1 - Sim | 2 - Não
        }

        if (parseInt(data.operationType) === 1) {
            data.ourVehicleType = [];
            data.ourTicketType = [];
            data.ourRestaurantType = [];
            data.supplierId = 0;
            data.resourcesId = data.supplierByResourceModel?.map((e: any) => parseInt(e.value)) || [];
            if ((watchResource || []).filter((elem: any) => elem.value === '10').length >= 1) {
                data.ourVehicleType = data.supplierVehicleTypeModel.map((e: any) => parseInt(e.value));
            }
            if ((watchResource || []).filter((elem: any) => elem.value === '11').length >= 1) {
                data.ourTicketType = data.supplierTicketTypeModel.map((e: any) => parseInt(e.value));
            }
            if ((watchResource || []).filter((elem: any) => elem.value === '12').length >= 1) {
                data.ourRestaurantType = data.supplierRestaurantTypeModel.map((e: any) => parseInt(e.value));
            } 
                
            
            data.sessionGroupId = parseInt(data.sessionGroupId?.id);
        } else {
            data.supplierId = data.supplier.supplierId;
            data.supplierIdentity = data.supplier.supplierIdentity;
            data.resourcesId = [];
            data.ourVehicleType = [];
            data.ourTicketType = [];
            data.ourRestaurantType = [];
            data.sessionGroupId = null;
        }

        data.correspondingOwnProduct = data.repass === '1' ? data.correspondingOwnProduct.value : '';

        return data;
    }

    const onSubmit = (data: any) => {
        let newTourObject: any = { ...tourObject, ...data };
        let dataToInsert: any;

        if (isTicket) {
            dataToInsert = createTicketObject(data);
        } else {
            dataToInsert = createTourObject(data);
        }

        if (isEdit !== true) {
            console.log(dataToInsert)
        dispatch(addOperation(dataToInsert));
        action("summaryAndDetails", newTourObject);
        } else {
            editFunction(dataToInsert);
            //receiveObject(data);
        }
    }
    
    return (
        <>
            {
                (isEdit === false || tourSIG?.tour?.productName) ?
                    <Col md={9} lg={10}>
                        <Card className="pt-content">
                            <Form className="h-100" noValidate validated={false} onSubmit={handleSubmit(onSubmit)}>
                                <h3 className="text-primary pt-title mb-0" onClick={()=>{console.log(tourSIG.tour)}}>Operação</h3>

                                <div className="pt-content-info" style={{ border: "none" }}>
                                    {!isTicket &&
                                        <Row>
                                            <div className="d-flex justify-content-between">
                                                <h5 className="mb-0 pt-subtitle">Operação de venda</h5>
                                            </div>

                                            <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                <Form.Label className="label-small">
                                                    Tipo de Operação
                                                </Form.Label>

                                                <Controller
                                                    control={control}
                                                    name="operationType"
                                                    rules={{ required: { value: true, message: 'Por favor, escolha um tipo de operação' } }}
                                                    render={({ field }: any) => (
                                                        <div className="input-select-custom">
                                                            <Form.Select
                                                                {...field}
                                                                aria-invalid={errors?.operationType ? "true" : ""}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                className="form-control"
                                                                required
                                                                defaultValue={tourObject.operationType}
                                                                disabled={isEdit}
                                                            >
                                                                <option value=''>Selecione</option>
                                                                <option value='1'>Própria</option>
                                                                <option value='2'>Terceiros</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="operationType"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>

                                            <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                <Form.Label className="label-small">
                                                    Produto de repasse
                                                </Form.Label>

                                                <Controller
                                                    control={control}
                                                    name="repass"
                                                    rules={{ required: { value: true, message: 'Por favor, escolha um tipo de operação' } }}
                                                    render={({ field }: any) => (
                                                        <div className="input-select-custom">
                                                            <Form.Select
                                                                {...field}
                                                                aria-invalid={errors?.repass ? "true" : ""}
                                                                as="select"
                                                                variant="standard"
                                                                margin="normal"
                                                                className="form-control"
                                                                required
                                                                defaultValue={'2'}
                                                            >
                                                                <option value='2'>Não</option>
                                                                <option value='1'>Sim</option>
                                                            </Form.Select>
                                                        </div>
                                                    )}
                                                />
                                                <ErrorMessage
                                                    errors={errors}
                                                    name="repass"
                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                />
                                            </Form.Group>
                                        </Row>
                                    }

                                    {
                                        (watchOperationType === 1 || watchOperationType === '1') && !isTicket
                                            ?
                                            <>
                                                <Row>
                                                    <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                        <Form.Label className="label-small">
                                                            Grupo Sessão
                                                        </Form.Label>

                                                        <Controller
                                                            control={control}
                                                            name="sessionGroupId"
                                                            rules={{ required: { value: false, message: 'Por favor, informe o grupo sessão' } }}
                                                            render={({ field }: any) => (
                                                                <GetSessionGroupId propsField={field} propsErrors={errors} setValue={setValue} defaultId={tourSIG.tour.sessionGroupId} />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="sessionGroupId"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        />
                                                    </Form.Group>
                                                    {
                                                        watchRepass === '1' &&
                                                        <>
                                                            <Form.Group as={Col} md="6" className="mt-4">
                                                                <Form.Label>Produto correspondente:</Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="correspondingOwnProduct"
                                                                    rules={{ required: { value: true, message: 'Por favor, informe o produto' } }}
                                                                    render={({ field }: any) => (
                                                                        <div className="">
                                                                            <GetProductsList
                                                                                propsField={field}
                                                                                propsErrors={errors}
                                                                                defaultValue={tourSIG.tour.correspondingOwnProduct}
                                                                                name={"correspondingOwnProduct"}
                                                                                setValue={setValue}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="correspondingOwnProduct"
                                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    }
                                                </Row>

                                                <Row>
                                                    <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                        <Form.Label className="label-small">
                                                            Tipo de Recursos
                                                        </Form.Label>

                                                        <Controller
                                                            control={control}
                                                            name="supplierByResourceModel"
                                                            rules={{ required: { value: false, message: 'Por favor, informe o tipo de recurso' } }}
                                                            render={({ field }: any) => (
                                                                <GetResourcesType
                                                                    propsField={field}
                                                                    propsLabel={''}
                                                                    propsErrors={errors}
                                                                    setValue={setValue}
                                                                    hasSavedInputs={tourObject.supplierByResourceModel !== undefined}
                                                                    infoEdit={tourObject.supplierByResourceModel}
                                                                    defaultIdArray={tourSIG.tour.resourcesId}
                                                                />
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="resourceType"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        />
                                                    </Form.Group>
                                                </Row>

                                                {(watchResource || []).filter((elem: any) => elem.value === '10').length >= 1 &&
                                                    <Row>
                                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                            <Form.Label className="label-small">
                                                                Tipo de Veículo
                                                            </Form.Label>

                                                            <Controller
                                                                control={control}
                                                                name="supplierVehicleTypeModel"
                                                                // rules={{ required: { value: true, message: 'Por favor, informe o tipo de veículo' } }}
                                                                render={({ field }: any) => (
                                                                    <GetResourceVehicleType
                                                                        propsField={field}
                                                                        propsErrors={errors}
                                                                        propsLabel={''}
                                                                        setValue={setValue}
                                                                        hasSavedInputs={tourObject.supplierVehicleTypeModel !== undefined}
                                                                        infoEdit={tourObject.supplierVehicleTypeModel}
                                                                        defaultIdArray={tourSIG.tour.ourVehicleType || []}
                                                                    />
                                                                )}
                                                            />
                                                            {/* <ErrorMessage
                                                                errors={errors}
                                                                name="vehicleType"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            /> */}
                                                        </Form.Group>
                                                    </Row>
                                                }
                                                
                                                {(watchResource || []).filter((elem: any) => elem.value === '11').length >= 1 &&
                                                    <Row>
                                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                            <Form.Label className="label-small">
                                                                Tipo de Ticket
                                                            </Form.Label>

                                                            <Controller
                                                                control={control}
                                                                name="supplierTicketTypeModel"
                                                                rules={{ required: { value: false, message: 'Por favor, informe o tipo de ticket' } }}
                                                                render={({ field }: any) => (
                                                                    <GetResourceTicketType
                                                                        propsField={field}
                                                                        propsErrors={errors}
                                                                        propsLabel={''}
                                                                        setValue={setValue}
                                                                        hasSavedInputs={tourObject.supplierTicketTypeModel !== undefined}
                                                                        infoEdit={tourObject.supplierTicketTypeModel}
                                                                        defaultIdArray={tourSIG.tour.ourTicketType || []}
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="supplierTicketTypeModel"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                }

                                                {(watchResource || []).filter((elem: any) => elem.value === '12').length >= 1 &&
                                                    <Row>
                                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                            <Form.Label className="label-small">
                                                                Restaurante
                                                            </Form.Label>

                                                            <Controller
                                                                control={control}
                                                                name="supplierRestaurantTypeModel"
                                                                rules={{ required: { value: false, message: 'Por favor, informe o restaurante' } }}
                                                                render={({ field }: any) => (
                                                                    <GetResourceRestaurantType
                                                                        propsField={field}
                                                                        propsErrors={errors}
                                                                        propsLabel={''}
                                                                        setValue={setValue}
                                                                        hasSavedInputs={tourObject.supplierRestaurantTypeModel !== undefined}
                                                                        infoEdit={tourObject.supplierRestaurantTypeModel}
                                                                        defaultIdArray={tourSIG.tour.ourRestaurantType || []}
                                                                    />
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="supplierRestaurantTypeModel"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                    </Row>
                                                }
                                            </>
                                            :
                                            <>
                                                <Row>
                                                    <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                        <Form.Label className="label-small">
                                                            Fornecedor
                                                        </Form.Label>

                                                        <Controller
                                                            control={control}
                                                            name="supplier"
                                                            rules={{ required: { value: true, message: 'Por favor, informe o grupo sessão' } }}
                                                            render={({ field }: any) => (
                                                                <div className="input-select-custom">
                                                                    <GetSuppliersFilter
                                                                        propsField={field}
                                                                        propsErrors={errors}
                                                                        setFilterSupplier={() => { }}
                                                                        setValue={setValue}
                                                                        defaultId={tourSIG.tour?.supplierId}
                                                                        disabled={isEdit}
                                                                    />
                                                                </div>
                                                            )}
                                                        />
                                                        <ErrorMessage
                                                            errors={errors}
                                                            name="supplier"
                                                            render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                        />
                                                    </Form.Group>

                                                    {
                                                        watchRepass === '1' && !isTicket &&
                                                        <>
                                                            <Form.Group as={Col} md="6" className="mt-4">
                                                                <Form.Label className="label-small">Produto correspondente:</Form.Label>
                                                                <Controller
                                                                    control={control}
                                                                    name="correspondingOwnProduct"
                                                                    rules={{ required: { value: true, message: 'Por favor, informe o produto' } }}
                                                                    render={({ field }: any) => (
                                                                        <div className="">
                                                                            <GetProductsList
                                                                                propsField={field}
                                                                                propsErrors={errors}
                                                                                defaultValue={tourSIG.tour.correspondingOwnProduct}
                                                                                name={"correspondingOwnProduct"}
                                                                                setValue={setValue}
                                                                            />
                                                                        </div>
                                                                    )}
                                                                />
                                                                <ErrorMessage
                                                                    errors={errors}
                                                                    name="correspondingOwnProduct"
                                                                    render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                                />
                                                            </Form.Group>
                                                        </>
                                                    }


                                                    {isTicket &&
                                                        <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                            <Form.Label className="label-small">
                                                                Tipo de Fornecimento
                                                            </Form.Label>

                                                            <Controller
                                                                control={control}
                                                                name="ticketSupplyType"
                                                                rules={{ required: { value: false, message: 'Por favor, informe esse campo' } }}
                                                                render={({ field }: any) => (
                                                                    <div className="input-select-custom">
                                                                        <Form.Select
                                                                            {...field}
                                                                            aria-invalid={errors?.ticketSupplyType ? "true" : ""}
                                                                            as="select"
                                                                            variant="standard"
                                                                            margin="normal"
                                                                            className="form-control"
                                                                            required
                                                                            defaultValue={tourObject.ticketSupplyType}
                                                                        >
                                                                            <option value='0'>Selecione</option>
                                                                            <option value='1'>Fornecimento Livre</option>
                                                                            <option value='2'>Fornecimento em Lotes</option>
                                                                            <option value='3'>Fornecimento por Integração</option>
                                                                        </Form.Select>
                                                                    </div>
                                                                )}
                                                            />
                                                            <ErrorMessage
                                                                errors={errors}
                                                                name="ticketSupplyType"
                                                                render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                                            />
                                                        </Form.Group>
                                                    }
                                                </Row>

                                                <Row className="mt-4">
                                                    <div className="">
                                                        <h5 className="pt-4 mt-3 mb-0 pt-subtitle" style={{ borderTop: "1px solid #B5B5B5" }}>Integração</h5>
                                                    </div>

                                                    <Form.Group as={Col} md="6" controlId="" className="mt-4">
                                                        <Form.Label className="label-small">
                                                            Seu produto possui integração de acesso?
                                                        </Form.Label>

                                                        <Controller
                                                            control={control}
                                                            name="isIntegration"
                                                            render={({ field }: any) => (
                                                                <Form.Check
                                                                    {...field}
                                                                    className="mb-1"
                                                                    type="radio"
                                                                    name="isIntegration"
                                                                    id={"radio-2"}
                                                                    value='1'
                                                                    required
                                                                    disabled={isEdit}
                                                                    checked={!getValues().isIntegration || getValues().isIntegration === '1'}
                                                                    label={"Sim"}
                                                                />
                                                            )}
                                                        />
                                                        <Controller
                                                            control={control}
                                                            name="isIntegration"
                                                            render={({ field }: any) => (
                                                                <Form.Check
                                                                    {...field}
                                                                    type="radio"
                                                                    name="isIntegration"
                                                                    id={"radio"}
                                                                    value='2'
                                                                    required
                                                                    disabled={isEdit}
                                                                    checked={getValues().isIntegration === '2' || getValues().isIntegration === 2}
                                                                    label={"Não"}
                                                                />
                                                            )}
                                                        />
                                                    </Form.Group>
                                                </Row>
                                            </>
                                    }
                                </div>

                                <div className="h-100 d-flex justify-content-end align-items-end">
                                    {
                                        isEdit !== true
                                            ?
                                            <Row className="mx-0 mt-5">
                                                <Button
                                                    variant="outline-primary"
                                                    className="form-button mr-3"
                                                    onClick={() => action('dataTour')}
                                                >
                                                    Voltar
                                                </Button>
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                >
                                                    Continuar
                                                </Button>
                                            </Row>
                                            :
                                            <Row className=" mx-0 mt-5">
                                                <Button
                                                    type="submit"
                                                    className="form-button"
                                                //onClick={() => action('meetingPoint')}
                                                >
                                                    Salvar
                                                </Button>
                                            </Row>
                                    }
                                </div>
                            </Form>
                        </Card>
                    </Col >
                    :
                    <>
                        <Col md={9} lg={10}>
                            <Card className="pt-content h-100">
                                <div className="loading-modal h-100 d-flex flex-column justify-content-center align-items-center">
                                    <div className="load mb-4"></div>
                                    <div>Carregando dados do produto</div>
                                </div>
                            </Card>
                        </Col>
                    </>
            }
        </>
    )
})

export default Operation;

