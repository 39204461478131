import React, { useState } from "react";

import "./Administrative.scss"
import { Button, Col, Form, Row } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from "@hookform/error-message";
import GetSuppliersFilter from "../../../../components/C2Points/GetSuppliersFilter";

export interface productTransfer {
    action: any,
    receiveObject: any,
    transferObject: any;
    isEdit: any
};

const Administrative: React.FC<productTransfer> = React.memo(({ action, transferObject, receiveObject, isEdit
}: productTransfer) => {
    //const [data, setData] = useState<any>([]);
    const [,setFilterSupplier] = useState<any>(0);
    const [validated,] = useState<any>(false);


    const onSubmit = (data: any) => {
        const obj = getValues();

        data.supplierId = data.supplier.supplierId;
        data.site = obj.site === true ? 1 : 0;
        data.b2b = obj.b2b === true ? 1 : 0;
        data.app = obj.app === true ? 1 : 0;

        /* let aux: any = {
            "site": (obj.site === true ? 1 : 0),
            "b2b": (obj.b2b === true ? 1 : 0),
            "app": (obj.app === true ? 1 : 0),
            "supplierId": obj.supplierId.supplierId
        } */
        //setData([data]);
        receiveObject(data);
    }

    const {
        control,
        handleSubmit,
        getValues,
        setValue,
        formState: { errors },
    } = useForm({});
   
    return (
        <>
            {/* CONTENT */}
            <Col md={9} lg={10}>
                <Form className="h-100" noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                    <div className='card h-100 border-0 p-4'>
                        <div>
                            <h3 className="pt-1 text-primary title-content">{"Administrativo"}</h3>
                        </div>

                        <section className="">
                            <Row className="mb-3 mt-3">
                                <Form.Group as={Col} md="6" controlId="">
                                    <Form.Label className="label-small">
                                        Fornecedor
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="supplier"
                                        rules={{ required: { value: true, message: 'Por favor, escolha um fornecedor' } }}
                                        render={({ field }: any) => (
                                            <div className="input-select-custom">
                                                <GetSuppliersFilter
                                                    propsField={field}
                                                    propsErrors={errors}
                                                    propsLabel="Fornecedor"
                                                    setFilterSupplier={setFilterSupplier} 
                                                    defaultId={transferObject.supplierId}  
                                                    setValue={setValue}                                                                                                
                                                />
                                            </div>
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="supplier"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="6" controlId="" >
                                    <div className="label-small mb-3">
                                        Canais de Venda
                                    </div>
                                    <div className="line-chooice row">
                                        <div className="d-flex w-auto">
                                            <Controller
                                                control={control}
                                                name="site"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        type="checkbox"
                                                        defaultChecked={transferObject.site === 1 ? true : false}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label className="label-small">
                                                Site
                                            </Form.Label>
                                        </div>

                                        <div className="d-flex w-auto">
                                            <Controller
                                                control={control}
                                                name="b2b"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        type="checkbox"
                                                        defaultChecked={transferObject.b2b === 1 ? true : false}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label className="label-small">
                                                B2B
                                            </Form.Label>
                                        </div>

                                        <div className="d-flex w-auto">
                                            <Controller
                                                control={control}
                                                name="app"
                                                render={({ field }: any) => (
                                                    <Form.Check
                                                        {...field}
                                                        type="checkbox"
                                                        defaultChecked={transferObject.app === 1 ? true : false}
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        className="mx-2"
                                                    />
                                                )}
                                            />
                                            <Form.Label className="label-small">
                                                APP
                                            </Form.Label>
                                        </div>

                                    </div>

                                </Form.Group>
                            </Row>
                        </section>

                        <section className="h-100 d-flex justify-content-end align-items-end">
                            {
                                isEdit !== true
                                    ?
                                    <Row className="mx-0 mt-5">
                                        <Button
                                            variant="outline-primary"
                                            className="form-button mr-3"
                                            onClick={() => action('blackout')}
                                        >
                                            Voltar
                                        </Button>
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        >
                                            Finalizar
                                        </Button>
                                    </Row>
                                    :
                                    <Row className=" mx-0 mt-5">
                                        <Button
                                            type="submit"
                                            className="form-button"
                                        //onClick={() => action('meetingPoint')}
                                        >
                                            Salvar
                                        </Button>
                                    </Row>
                            }
                        </section>

                    </div>
                </Form>
            </Col>

        </>
    )
});

export default Administrative;