import React, { useState } from 'react';

import { Container, Row, Col, Card } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';

import { useTranslation } from 'react-i18next';
import { Controller, useForm } from "react-hook-form";
import { ErrorMessage } from '@hookform/error-message';

import '../../../../assets/sass/accordion.scss';

import GetResourcesTypeIsActive from "../../../../components/C2Points/GetResourcesTypeIsActive";
import GetResourceVehicleType from "../../../../components/C2Points/GetResourceVehicleType";

export interface propAccordion {
    setChangeEdit: any,
    action: any,
    ContextAwareToggle: any,
    supplierControlObject: any,
    infoEdit: any,
    setStepCtrl: any,
    editSuppliers: any,
    scrollTop?: any;
    isEdit?: any;
    hasSavedInputs?: any;
};

const AccordionResources: React.FC<propAccordion> = ({
    setChangeEdit, action, ContextAwareToggle, supplierControlObject, infoEdit, setStepCtrl, editSuppliers, scrollTop, isEdit = false, hasSavedInputs = false
}: propAccordion) => {

    const [ctrlEventKey, setCtrlEventKey] = useState<boolean>(false);
    const { t } = useTranslation();
    const validated = false;

    const idSup = window.location.href.split('id=')[1]?.replace(/#/g, '');

    const {
        control,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm(/* {
        defaultValues: {
            supplierByResourceModel: isEdit === true ? { description: infoEdit.bancDesc, id: infoEdit.bankId } : '',
            supplierVehicleTypeModel: isEdit === true ? { description: infoEdit.supplierVehicleTypeDTO.typeName, id: infoEdit.supplierVehicleTypeDTO.typeId } : '',
        },
    } */);

    const onSubmit = (data: any) => {
        if (isEdit === false) {
            /// ADD SUPPLIERS PAGE
            
            data.supplierByResourceDTO = data.supplierByResourceModel.map(({ value: resourceTypeIdInput, label: resourceTypeNameInput }: any) => ({ resourceTypeIdInput, resourceTypeNameInput }));
            data.supplierVehicleTypeDTO = data.supplierVehicleTypeModel.map(({ value: typeId, label: typeName }: any) => ({ typeId, typeName }));

            data.supplierByResourceModel = data.supplierByResourceModel.map(({ value: resourceTypeIdInput }: any) => ({ resourceTypeIdInput }));
            data.supplierVehicleTypeModel = data.supplierVehicleTypeModel.map(({ value: typeId }: any) => ({ typeId }));


            setCtrlEventKey(true);
            action(data);
        } else {
            /// EDIT SUPPLIERS PAGE
            let edit: any = { ...infoEdit, ...data };

            edit.supplierId = parseInt(idSup);
            /* const aux: any = data;  //"aux" usado para garantir que todos os outros campos de informação do fornecedor sejam mantidos
            data = info;
"": 
            data.supplierByResourceModel = aux.supplierByResourceModel.map(({ value: resourceTypeIdInput }: any) => ({ resourceTypeIdInput }));
            data.supplierVehicleTypeModel = aux.supplierVehicleTypeModel.map(({ value: typeId }: any) => ({ typeId }));
            data.supplierId = idSup; */

            edit.supplierByResourceDTO = data.supplierByResourceModel.map(({ value: resourceTypeIdInput }: any) => ({ resourceTypeIdInput }));
            edit.supplierByResourceModel = data.supplierByResourceModel.map(({ value: resourceTypeIdInput }: any) => ({ resourceTypeIdInput }));
            edit.supplierVehicleTypeModel = data.supplierVehicleTypeModel.map(({ value: typeId }: any) => ({ typeId }));

            edit.usersSupplierModel = edit.usersSupplierDTO?.map((elem: any) => { return { "userId": elem.userId } });
            edit.supplierContactModel = edit.supplierContact;
            edit.costCenterSupplierModel = edit.costCenterSupplierDTO;

            //remover
            edit.tarTipo = edit.tarTipo === null ? 1 : edit.tarTipo
            edit.masterFilho = edit.masterFilho === null ? 1 : edit.masterFilho
            //[end] remover
            editSuppliers(edit);
        }
        console.log(data)
    };
    console.log(infoEdit)
    function handleCancel() {
        window.location.reload();
        /* setChangeEdit(true) */
    }
    
    return (
        <Card className="bg-white mt-4" id="editResourcesForms">
            <Card.Header id="accordionPersonal">
                <ContextAwareToggle eventKey="0" status={ctrlEventKey}>{t('accordionResources.title')}</ContextAwareToggle>
            </Card.Header>
            <Accordion.Collapse eventKey="0">
                <Card.Body>
                    <Container className="p-0 mt-3" fluid>
                        <Form noValidate validated={validated} onSubmit={handleSubmit(onSubmit)}>
                            <Row className="mb-3">
                                <Form.Group as={Col} md="4" controlId="formResourcesType">
                                    <Form.Label>
                                        {t('accordionResources.typeR')}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="supplierByResourceModel"
                                        rules={{ required: { value: true, message: t('accordionResources.validation.typeR') } }}
                                        render={({ field }: any) => (
                                            <GetResourcesTypeIsActive
                                                propsField={field}
                                                propsLabel={t('accordionAccount.typeR')}
                                                propsErrors={errors}
                                                isEdit={isEdit}
                                                hasSavedInputs={hasSavedInputs}
                                                infoEdit={infoEdit?.supplierByResourceDTO}
                                                setValue={setValue}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="supplierByResourceModel"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                                <Form.Group as={Col} md="4" controlId="formVehiclesType">
                                    <Form.Label>
                                        {t('accordionResources.typeV')}
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name="supplierVehicleTypeModel"
                                        rules={{ required: { value: true, message: t('accordionResources.validation.typeV') } }}
                                        render={({ field }: any) => (
                                            <GetResourceVehicleType
                                                propsField={field}
                                                propsErrors={errors}
                                                propsLabel={t('accordionAccount.typeV')}
                                                isEdit={isEdit}
                                                hasSavedInputs={hasSavedInputs}
                                                infoEdit={infoEdit?.supplierVehicleTypeDTO}
                                                setValue={setValue}
                                            />
                                        )}
                                    />
                                    <ErrorMessage
                                        errors={errors}
                                        name="supplierVehicleTypeModel"
                                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="d-flex justify-content-end mx-0 mt-5 mb-4">
                                {
                                    isEdit === false
                                        ?
                                        <Button
                                            type="button"
                                            className="form-button back-btn btn btn-outline-primary"
                                            variant='outline'
                                            onClick={() => {
                                                setStepCtrl(2)
                                                scrollTop()
                                            }}
                                        >
                                            voltar
                                        </Button>
                                        :
                                        <Button
                                            className="form-button cancel"
                                            onClick={handleCancel}
                                        >
                                            Cancelar
                                        </Button>}
                                <Button
                                    type="submit"
                                    className="form-button"
                                    onClick={scrollTop}
                                >
                                    {t('accordionInfoCompany.buttonContinue')}
                                </Button>
                            </Row>
                        </Form>
                    </Container>
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}

export default AccordionResources;