import React from "react";

import { Col, Container, Form, Modal, Row } from "react-bootstrap";

export interface props {
    modalShow: any,
    setModalShow: any;
    errorsList: any;
};

const ModalErrorsList: React.FC<props> = React.memo(({
    modalShow, setModalShow, errorsList
}: props) => {

    return (
        <>
            <Modal
                className="modalAuth"
                show={modalShow}
                onHide={() => {
                    //setModalMessage("Carregando");
                    setModalShow(false);
                }}
                aria-labelledby="contained-modal-title-vcenter"
            >
                <>
                <Modal.Body>
                    <Container className="bg-modal-contact p-0" fluid>
                        <Row className="h-100 flex-column">
                        <h5 className="contact-title mb-5">Erros de Exportação</h5>
                        <div className="" style={{overflow: 'auto', maxHeight: '500px'}}>
                            <div className="table-container">   
                            {errorsList.length > 0 ? errorsList.map((item: any, index: any) => (
                            <Row className="mb-2" key={index}>
                                <Form.Group as={Col} md="12">
                                {/* <Form.Label><span style={{fontWeight: 'bold'}}>{item.userName}</span></Form.Label> */}
                                <Form.Group as={Col} md="12" className="p-2 message-border">{item}</Form.Group>
                                </Form.Group>
                            </Row>
                            ))
                            :
                            <Row className="mb-2 text-center"><span>Nenhum erro de exportação!</span></Row>}
                            </div>
                        </div> 
                        
                        </Row>
                    </Container>
                    </Modal.Body>
                </>
            </Modal>
        </>
    )
});

export default ModalErrorsList;