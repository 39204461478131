import React, { useState } from "react";
import Select from "react-select";

import { useTranslation } from "react-i18next";

import Form from "react-bootstrap/Form";
//import api from "../../services/api";

export interface propPlus {
    propsField: any;
    isMult?: boolean;
    info?: any;
}

const GetTime15MinInterval: React.FC<propPlus> = ({
    propsField,
    isMult = true,
    info = null
}: propPlus) => {
    const { t } = useTranslation();

    /* const [info, setInfo] = useState<any>(null);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listTimes() {
            try {
                const { data } = await api.post(`/Domains/GetDomainHours`, {}, config);
                if (data.status !== 400) {
                    let aux: any = data.data.filter((elem: any) => elem.flagQuaterHour === 1 )
                    setInfo(() => aux)
                    if(getDomainHours !== null){
                        getDomainHours(() => aux)
                    }
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listTimes();
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []); */

    const [cat,] = useState<any>([]);

    const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

    const onMenuOpen = () => {
        if (isMenuOpen === false) {
            setIsMenuOpen(true);
        } else {
            setIsMenuOpen(false);
        }
    };

    if (info !== null) {
        const transformed = info.map(({ hours: value, hours: label }: any) => ({
            value,
            label
        }));

        return (
            <>
                <div
                >
                    <Select
                        {...propsField}
                        onMenuOpen={onMenuOpen}
                        onMenuClose={onMenuOpen}
                        as="select"
                        className="basic-multi-select"
                        classNamePrefix="select"
                        margin="normal"
                        placeholder={t("accordionFinancial.select.select")}
                        isMulti={isMult}
                        options={transformed}
                        getOptionLabel={(option: any) => `${option.label}`}
                        defaultValue={cat}
                    />
                </div>
            </>
        );
    } else {
        return (
            <>
                <Form.Select>
                    <option value="" disabled></option>
                </Form.Select>
            </>
        );
    }
};

export default GetTime15MinInterval;
