import React, { useEffect, useState, Key } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField?: any,
  propsLabel?: any,
  propsErrors?: any,
};

const GetReasonCancel: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  //const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  // const onMenuOpen = () => {
  //   if (isMenuOpen === false) {
  //     setIsMenuOpen(true);
  //   } else {
  //     setIsMenuOpen(false);
  //   }
  // }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetReasonCancel`, config);
        if (data.status !== 400) {
          setInfo(data.data)
          /* console.log(data); */
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();
  }, []);


  if (info !== null) {
    return (
      <Form.Select
        {...propsField}
        aria-invalid={propsErrors?.reason ? "true" : ""}
        label={propsLabel}
        as="select"
        variant="standard"
        margin="normal"
        required
      >
        <option value='0' selected disabled>Selecione</option>
        {
          info.length > 0
            ?
            info.map((info: any, index: Key) => (
              <option key={index} value={info.description}>{info.description}</option>
            ))
            :
            ''
        }
      </Form.Select>
    );
  } else {
    return <>

    </>
  }
}

export default GetReasonCancel;