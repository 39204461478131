import React from 'react';
import { Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
// import { Controller } from "react-hook-form";
// import { ErrorMessage } from "@hookform/error-message";
import { useAppSelector } from "../../../store/hooks";

export interface propForm {
    control: any,
    errors: any,
    t?:any
};

const PaymentCheckoutPicpay: React.FC<propForm> = ({
    control, errors, t
}: propForm) => {
    const cartSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let todayFoPayment = dd + '/' + mm + '/' + yyyy;

    return (
        <>
            <h3>{t("checkout.paymentCheckout.picpay.picpay")}</h3>

            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCashAmount">
                    <Form.Label>{t("checkout.paymentCheckout.picpay.paidValue")}</Form.Label>
                    <div>{cartSIG.totalCart?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentCashDate">
                    <Form.Label>{t("checkout.paymentCheckout.picpay.date")}</Form.Label>
                    <div>{todayFoPayment}</div>
                </Form.Group>
            </Row>

            {/* <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentPicpayNSU">
                    <Form.Label>NSU:</Form.Label>
                    <Controller
                        control={control}
                        name="nsu"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="nsu"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentPicpayDate">
                    <Form.Label>Data:</Form.Label>
                    <Controller
                        control={control}
                        name="picpayDate"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="picpayDate"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group>
            </Row> */}
        </>
    );
}

export default PaymentCheckoutPicpay;