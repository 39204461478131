import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Select from 'react-select';

/* import { Col } from 'react-bootstrap';

import Form from 'react-bootstrap/Form'; */

export interface propPlus {
  propsField: any,
  propsErrors: any,
  defaultValue?: any,
  setValue?: any,
};

const GetReservationSystems: React.FC<propPlus> = ({
  propsField, propsErrors, defaultValue = null, setValue
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);

  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listSuppliers() {
      try {
        const { data } = await api.get(`/DropDown/GetReservationSystems`, config);
        if (data.status !== 400) {
          setInfo(data.data)

          if (defaultValue !== null) {
            let aux: any = data.data.find((elem: any) => { return (Number(elem.id) === Number(defaultValue)) });
            setValue('reservationSystems', { label: aux.description, value: aux.id });
          }
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listSuppliers();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (info !== null) {
    const transformed = info.map(({ id, description }: any) => ({ value: id, label: description }));

    return (
      <>
        <div className={propsErrors.reservationSystems !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors?.reservationSystems ? "true" : ""}
            as="select"
            className="basic-multi-select"
            classNamePrefix="select"
            margin="normal"
            placeholder={"Selecione"}
            options={transformed}
            getOptionLabel={(option: any) => `${option.label}`}
          />
        </div>
      </>
    );
  } else {
    return <>

    </>
  }
}

export default GetReservationSystems;