import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react'
import { Dropdown, Modal } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import SendQuotationNew from '../TableGroupFiles/components/SendQuotation/SendQuotationNew';
import api from '../../../../services/api';
import FinancialModal from '../FinancialModal/FinancialModal';

export interface propActions {
  action?: any,
  info?: any;
  invoiceType?:number;
  setInvoiceType?:any;
};

function DropdownCustomizedActions({
  action, info, invoiceType, setInvoiceType
}: propActions) {
  const { t } = useTranslation();

  const { clientName, fileNo, financialStatus, financialStatusDesc, firstServiceDate, groupName, id, operationStatus, operationStatusDesc }: any = info
  const objetoCustomized: object = { clientName, fileNo, financialStatus, financialStatusDesc, firstServiceDate, groupName, id, operationStatus, operationStatusDesc }
  /* Success Modal (Inserir) */
  const [modalSuccess, setModalSuccess] = useState<any>(false);
  const [responseText, setResponseText] = useState<any>();
  const [modalContent, setModalContent] = useState<any>();
  const [log, setLog] = useState<any>();
  /* END - Success Modal (Inserir) */

  /* financial  Modal */
  const [financialModal, setFinancialModal] = useState<any>(false);  
  
  /* END - financial Modal */

  const [expiration, setExpiration] = useState<any>();

  const [selectedSingleRow, setSelectedSingleRow] = useState<any>(null);
  const [modalShow, setModalShow] = useState<any>(false);
  const [modalResponse, setModalResponse] = useState<any>(null);
  const [datapgto, setDatapgto] = useState<any>(undefined);

  const [confirmCancelShow, setConfirmCancelShow] = useState<any>(false);
  const [fileDetailModalShow, setFileDetailModalShow] = useState<any>(false);
  const [confirmSendQuotationShow, setConfirmSendQuotationShow] = useState<any>(false);

  const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

  function handleReload() {
    window.location.reload();
  }

  async function sendQuotation(row: any) {
    // chamar envio de cotação na api
    setSelectedSingleRow(row);
    setConfirmSendQuotationShow(true);
  }

  async function downloadQuotation(row: any) {
    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var fileId = row.id;

    //setSelectedSingleRow(null);

    try {
      const res = await api.post(`/GroupFile/CreateQuotationReportPdf`,
        {
          "fileId": fileId,
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
          const objectUrl = `data:${dados.data.fileType};base64,${dados.data.file}`;

          const link = document.createElement('a');
          link.href = objectUrl;
          link.setAttribute(
            'download',
            dados.data.fileName,
          );

          // Append to html link element page
          document.body.appendChild(link);

          // Start download
          link.click();

          // // Clean up and remove the link
          link?.parentNode?.removeChild(link);
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  }

  async function handleActionSendQuotationModal() {
    setConfirmSendQuotationShow(false);

    setModalContent("loading");
    setModalSuccess(true);

    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
    };

    var fileId = selectedSingleRow.id;

    setSelectedSingleRow(null);

    try {
      const res = await api.post(`/GroupFile/SendQuotationReportPdfToClient`,
        {
          "fileId": fileId,
        }, config);
      if (res.status !== 400) {
        var dados = res.data.data;
        //console.log(dados.centrosCusto);
        setResponseText(dados.texto);
        setLog(dados.log);

        if (dados.log !== 1) {
          setModalContent("success");
        } else {
          setModalContent("error");
        }

      } else {
        setModalContent("error");
        setResponseText(t('billsToPay.table.error'));
        setLog(1);
      }
    } catch (error: any) {
      console.log(error.response)
    }
  } 

  function addActionButton(row: object) {
    return (
      <>
        <div className="d-flex justify-content-center">
          <Dropdown drop="start">
            <Dropdown.Toggle variant="light" >
            </Dropdown.Toggle>

            <Dropdown.Menu>
              <Dropdown.Item
                eventKey="1"
                onClick={() => { sendQuotation(row) }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'envelope']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.sendQuotation')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="2"
                onClick={() => { downloadQuotation(row) }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'download']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.downloadQuotation')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="3"                
                onClick={() => setFinancialModal(true)}>
                <FontAwesomeIcon
                  icon={['fal', 'dollar-sign']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.financial')}
              </Dropdown.Item>
              <Dropdown.Item
                eventKey="4"
                onClick={() => {} }>
                <FontAwesomeIcon
                  icon={['fal', 'badge-check']}
                //className="mr-2"
                />
                {t('groupsAndCustomized.report.actionsOpts.operation')}
              </Dropdown.Item>
            </Dropdown.Menu>
          </Dropdown>
        </div>

      {/* Modal de SendQuotation */}
        <Modal
          className="modal-custom modalAuth modal-large"
          show={confirmSendQuotationShow}
          onHide={() => setConfirmSendQuotationShow(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <div className="d-flex justify-content-between w-100">
                <h3>{t('groupsAndCustomized.report.sendQuotationAction.title')} New</h3>
              </div>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <SendQuotationNew
              t={t}
              info={selectedSingleRow}
              setModalContent={setModalContent}
              setModalSuccess={setModalSuccess}
              setLog={setLog}
              setResponseText={setResponseText}
              invoiceType={invoiceType}
              setInvoiceType={setInvoiceType}
               />
          </>
        </Modal>
        {/* End of Modal de SendQuotation */}

      {/* Modal de Financeiro */}
        <Modal
          className="modal-custom modalAuth modal-large"
          show={financialModal}
          onHide={() => setFinancialModal(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Header closeButton>
              <div className="d-flex justify-content-between w-100">
                <h3>Financeiro</h3>
              </div>
            </Modal.Header>
            <hr style={{ margin: "30px 0" }} />
            <FinancialModal fileId={info ? info.id : 0} closeModal={() => setFinancialModal(false)} />
          </>
        </Modal>
        {/* End of Modal de Financeiro */}

         {/* Modal de Success */}
         <Modal
          className={"modal-confirm loading-modal"}
          show={modalSuccess}
          onHide={() => setModalSuccess(false)}
          size="lg"
          aria-labelledby="contained-modal-title-vcenter"
        >
          <>
            <Modal.Body className='modal-body text-center sucess-pos'>
              {
                modalContent !== "loading"
                  ?
                  <>
                    <div>
                      {
                        log === 2 || log === "2"
                          ?
                          <FontAwesomeIcon
                            icon={["fal", "exclamation-circle"]}
                            size="5x"
                            className="text-primary"
                            style={{ fontSize: "7.5em"}}
                          />
                          :
                          log === 1 || log === "1"
                            ?
                            <FontAwesomeIcon
                              icon={["fal", "times-circle"]}
                              size="5x"
                              className="text-primary"
                              style={{ fontSize: "7.5em"}}
                            />
                            :
                            log === 0 || log === "0"
                              ?
                              <FontAwesomeIcon
                                icon={["fal", "check-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                              :
                              <FontAwesomeIcon
                                icon={["fal", "question-circle"]}
                                size="5x"
                                className="text-primary"
                                style={{ fontSize: "7.5em"}}
                              />
                      }
                    </div>
                    <div>
                      {responseText}
                    </div>
                    <div className="d-flex justify-content-center">
                      {
                        modalContent === 'success'
                          ?
                          <button
                            onClick={handleReload}
                            className="btn btn-primary mx-2 w-25"
                          >
                            Confirmar
                          </button>
                          :
                          modalContent === 'warning'
                            ?
                            <button
                              onClick={() => setModalSuccess(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                            :
                            <button
                              onClick={() => setModalShow(false)}
                              className="btn btn-primary mx-2 w-25"
                            >
                              Confirmar
                            </button>
                      }
                    </div>
                  </>
                  :
                  <div className="modal-body">
                    <div className="text-center">
                      <div className="load"></div>
                    </div>
                    <div>
                      <span>Processando</span>
                    </div>
                    <div></div>
                  </div>
              }
            </Modal.Body>
          </>
        </Modal>
        {/* END - Modal de Success */}
      </>
    );
  }
  return (
    <>{addActionButton(info)}</>
  )
}


export default DropdownCustomizedActions;