/* eslint-disable @typescript-eslint/no-unused-vars */
import { useState, useEffect } from 'react';
import api from '../../services/api';

//COMPONENTS
import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';

import { Link } from 'react-router-dom';
import DesktopDefault from '../../templates/DesktopDefault';
import ZonesFilter from './components/ZonesFilter/ZonesFilter';
import ZonesTable from './components/ZonesTable/ZonesTable';

import './Zones.scss';

function Zones() {
    const [zones, setZones] = useState<any>(null);
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    const [allowSearch, setAllowSearch] = useState<any>(1);
    /* END - Pagination */

    const [actualDestination, setActualDestination] = useState<any>();
    const [actualZone, setActualZone] = useState<any>();
    const [actualSupplier, setActualSupplier] = useState<any>();

    useEffect(() => {
        //var today: any = new Date();

        buscar('', '', '');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const reloadSerch = () => {
        buscar(actualDestination, actualZone, actualSupplier);
    }

    useEffect(() => {
        setLoading(true);
        setZones(null);
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listVehicles() {

            try {
                const { data } = await api.post('/Zone/ListZones',
                    {
                        "destino": actualDestination,
                        "zona": actualZone,
                        "fornecedor": actualSupplier
                    }
                    , config
                );

                if (data.status !== 400) {
                    setLoading(false);
                    setZones(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }

            }
        }
        listVehicles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage, allowSearch]);

    async function buscar(destination: string = '', zone: string = '', supplier: any = '') {
        setLoading(true);
        setZones(null);

        setActualDestination(destination);
        setActualZone(zone);
        setActualSupplier(supplier);

        if (pageCount !== 1) {
            setPageCount(1);
        } else {
            setAllowSearch(allowSearch + 1);
        }
    };


    return (
        <>
            <DesktopDefault>

                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Zonas / Lista de Zonas'} />

                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Lista de Zonas</h2>
                        <Link to={'/zones/add-zone'} className='btn btn-primary'>
                            Nova Zona de Translado
                        </Link>
                    </div>

                    <ZonesFilter buscar={buscar} zones={zones} error={error} />

                    <ZonesTable zones={zones} loading={loading} totalRows={totalRows} pageCount={pageCount} setPageCount={setPageCount} rowsPerPage={rowsPerPage} setRowsPerPage={setRowsPerPage} reloadSerch={reloadSerch} />
                </div>
            </DesktopDefault>
        </>

    );
}

export default Zones;