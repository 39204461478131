import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../assets/sass/modal.scss";

//import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

import InputMask from "react-input-mask";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

export interface propModal {
  action: any,
  info: any,
};

const FormAddPosDevice: React.FC<propModal> = ({
  action, info
}: propModal, props: any) => {

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      tipo: info ? info.tipo : '',
      numeroLogico: info ? info.numeroLogico : '',
      aluguel: info ? info.aluguel : '',
      insencaoMin: info ? info.insencaoMin : '',
      alocacao: info ? info.alocacao : '',
      responsavel: info ? info.responsavel : '',
      cpfResp: info ? info.cpfResp : '',
      telefoneResp: info ? info.telefoneResp : '',
      feeGateway: info ? info.feeGateway : '',
      feeAntifraude: info ? info.feeAntifraude : '',
      id: info ? info.id : -1
    },
  });

  const onSubmitContact = (data: any) => {
    //data.id = id;
    //setId(id - 1);
    
    if (!info) {
      data.id = (Math.floor(Math.random() * 100000) + 1) * (-1);
    }

    action(data);   
  };

  return (
    <>
      <Container className="bg-modal-contact p-0" fluid>
        <Row>
          {/* <h5 className="contact-title mb-5">Maquina POS</h5> */}
          <Form
            className="form-add-contact"
            noValidate
          >
            <div className="">
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom01">
                  <Form.Label>Tipo:</Form.Label>
                  <Controller
                    control={control}
                    name="tipo"
                    rules={{ required: { value: true, message: 'Por favor, informe o tipo.' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.tipo ? "true" : ""}
                        label={'Tipo'}
                        variant="standard"
                        margin="normal"
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom02">
                  <Form.Label>Nº Lógico:</Form.Label>
                  <Controller
                    control={control}
                    name="numeroLogico"
                    rules={{ required: { value: true, message: 'Por favor, informe o Nº Lógico.' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.numeroLogico ? "true" : ""}
                        label={'Nº Logico'}
                        variant="standard"
                        margin="normal"
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Aluguel:</Form.Label>
                  <Controller
                    control={control}
                    name="aluguel"
                    rules={{ required: { value: true, message: 'Por favor, informe a Alocação .' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.aluguel ? "true" : ""}
                        label={'Alocação'}
                        variant="standard"
                        margin="normal"
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Isenção a partir de R$:</Form.Label>
                  <Controller
                    control={control}
                    name="insencaoMin"
                    rules={{ required: { value: true, message: 'Por favor, informe o valor min insenção .' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.insencaoMin ? "true" : ""}
                        label={'Isenção a partir de R$'}
                        variant="standard"
                        margin="normal"
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Alocação:</Form.Label>
                  <Controller
                    control={control}
                    name="alocacao"
                    rules={{ required: { value: true, message: 'Por favor, informe a Alocação .' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.alocacao ? "true" : ""}
                        label={'Alocação'}
                        variant="standard"
                        margin="normal"
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom04">
                  <Form.Label>Responsável:</Form.Label>
                  <Controller
                    control={control}
                    name="responsavel"
                    rules={{ required: { value: true, message: 'Por favor, informe o Responsável.' } }}
                    render={({ field }: any) => (
                      <Form.Control
                        {...field}
                        aria-invalid={errors?.responsavel ? "true" : ""}
                        label={'Responsável'}
                        variant="standard"
                        margin="normal"
                        required
                        autoComplete='off'
                      />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>CPF:</Form.Label>
                  <Controller
                    control={control}
                    name="cpfResp"
                    rules={{ required: { value: true, message: 'Por favor, informe o cpf' } }}
                    render={({ field }: any) => (
                      <InputMask mask="999.999.999-99" value={field?.value} onChange={field?.onChange}>
                        {(inputProps: any) => (
                          <Form.Control
                            {...inputProps}
                            aria-invalid={errors?.cpfResp ? "true" : ""}
                            label={'CPF'}
                            variant="standard"
                            margin="normal"
                            required
                            autoComplete='off'
                          />
                        )}
                      </InputMask>

                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Telefone:</Form.Label>
                  <Controller
                    control={control}
                    name="telefoneResp"
                    rules={{ required: { value: true, message: 'Por favor, informe o telefone' } }}
                    render={({ field }: any) => (
                      <InputMask mask="(99) 9999-9999" value={field?.value} onChange={field?.onChange}>
                        {(inputProps: any) => (
                          <Form.Control
                            {...inputProps}
                            aria-invalid={errors?.telefoneResp ? "true" : ""}
                            label={'Telefone'}
                            variant="standard"
                            margin="normal"
                            required
                            autoComplete='off'
                          />
                        )}
                      </InputMask>
                    )}
                  />
                </Form.Group>
              </Row>
              <Row className="mb-3">
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Fee Gateway R$:</Form.Label>
                  <Controller
                    control={control}
                    name="feeGateway"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="text"
                        {...field}
                        aria-invalid={errors?.feeGateway ? "true" : ""}

                        variant="standard"
                        autoComplete='off'
                        margin="normal"
                        required />
                    )}
                  />
                </Form.Group>
                <Form.Group as={Col} md="6" controlId="validationCustom03">
                  <Form.Label>Fee Antifraude R$:</Form.Label>
                  <Controller
                    control={control}
                    name="feeAntifraude"
                    rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                    render={({ field }: any) => (
                      <Form.Control type="text"
                        {...field}
                        aria-invalid={errors?.feeAntifraude ? "true" : ""}

                        variant="standard"
                        autoComplete='off'
                        margin="normal"
                        required />
                    )}
                  />
                </Form.Group>
              </Row>
            </div>
          </Form>
        </Row>
      </Container>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(onSubmitContact)}
          >
            Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormAddPosDevice;
