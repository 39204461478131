/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterReportERPConference from './components/FilterReportERPConference/FilterReportERPConference';

import './ReportERPConference.scss';

function ReportERPConference() {

    
    const [error, setError] = useState<any>();
    const [loading, setLoading] = useState<any>(false);

    /* Filtragem */
    const [defaultDate, setDefaultDate] = useState<any>([]);

    //const [actualTypeDate, setActualTypeDate] = useState<any>(3);
    const [actualDate, setActualDate] = useState<any>([]);

    //console.log(actualDate)

    /* END - Filtragem */


    function clearActualValues() {
        //setActualTypeDate(1);
        setActualDate(defaultDate);
    }

    useEffect(() => {
        const hoje = new Date();
        hoje.setDate(0);

        const fim = hoje.toISOString().substring(0,10).split('-').reverse().join('/');

        hoje.setDate(1);

        const inicio = hoje.toISOString().substring(0,10).split('-').reverse().join('/');

        setDefaultDate([inicio,fim]);
    }, []);

    async function exportReport(/*typeDate: any,*/ date: any) {
        setLoading(true);
        //setActualTypeDate(typeDate);
        setActualDate(date);

        let beginDate = []
        let endDate = []

        if (date.length === 1) {
            beginDate = date[0].split('/')
        } else if (date.length === 2) {
            beginDate = date[0].split('/')
            endDate = date[1].split('/')
        }

        let today: any = new Date();

        try {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            
            const res = await api.post('/Manifest/ExportReportXmlRM',
                {
                    "dataFim": (date.length === 1) ? `${beginDate[2]}-${beginDate[1]}-${parseInt(beginDate[0])}` : (date !== null && date.length === 2) ? `${endDate[2]}-${endDate[1]}-${endDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    "dataInicio": (date.length > 0) ? `${beginDate[2]}-${beginDate[1]}-${beginDate[0]}` : `${today.getFullYear()}-${today.getMonth() + 1}-${today.getDate()}`,
                    //"tipoData": typeDate
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);

                const dados = res.data.data;

                const objectUrl = `data:${dados.fileType};base64,${dados.file}`;

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    dados.fileName,
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            }
        } catch (error: any) {
            setLoading(false);
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    if(defaultDate.length > 0) {
    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Financeiro / Exportação ERP / Relatório de Conferência'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Relatório de Conferência</h2>
                    </div>
                    <FilterReportERPConference
                        exportReport={exportReport}
                        error={error}
                        clearActualValues={clearActualValues}
                        defaultDate={defaultDate}
                    />
                </div>
            </DesktopDefault>
        </>)
    } else {
        return (<></>);
    }
    
}

export default ReportERPConference;