import React, { useState, useEffect } from 'react';
import api from '../../services/api';

import DesktopDefault from '../../templates/DesktopDefault';

import Breadcrumb from '../../components/Breadcrumb/Breadcrumb';
import FilterSuppliersTariff from './components/FilterSuppliersTariff/FilterSuppliersTariff';
import TableSuppliersTariff from './components/TableSuppliersTariff/TableSuppliersTariff';

import './SuppliersTariff.scss';

function SuppliersTariff() {

    //const today = new Date();
    //const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [billsPay, setBillsPay] = useState<any>(null);
    const [totalPay, ] = useState<any>(0);
    const [parameters, setParameters] = useState<any>({});
    const [error, setError] = useState<any>();
    const [allowLoadFromFilter, setAllowLoadFromFilter] = useState<any>(false);
    const [loading, setLoading] = useState<any>(false);

    /* Filtragem */
    const [actualProduct, setActualProduct] = useState<any>("");
    const [actualSupplier, setActualSupplier] = useState<any>("");
    const [actualType, setActualType] = useState<any>(null);

    //console.log(actualDate)

    /* END - Filtragem */

    /* Pagination */
    const [totalRows, setTotalRows] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);
    /* END - Pagination */

    function clearActualValues() {
        setActualProduct("");
        setActualSupplier("");
        setActualType(null);
    }

    function refreshData() {
        let body: any = document.querySelector("body");
        if ( body.className !== "modal-open" ) {
            setBillsPay(null);
        }
        setLoading(true);

        //let today: any = new Date();

        async function billTopPay() {
            const token = localStorage.getItem('GroupId') || '';

            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            try {
                const { data } = await api.post('/SupplierTariff/GetReportAsync',
                    {
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "productCode": actualProduct,
                        "supplier": actualSupplier,
                        "tipo": actualType
                    }, config
                );

                if (data.status !== 400) {
                    setLoading(false);
                    setParameters({
                        "page": pageCount,
                        "rowsPerPage": rowsPerPage,
                        "productCode": actualProduct,
                        "supplier": actualSupplier,
                        "tipo": actualType
                    });
                    setBillsPay(data.data.rows);
                    setTotalRows(data.data.rowsCount);
                }
            } catch (error: any) {
                setLoading(false);
                //if (error.response.status === 401) {
                //    window.location.href = window.location.origin + '/';
                //}
            }
        }
        billTopPay();
    }

    useEffect(() => {
        refreshData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageCount, rowsPerPage/* , actualStatus */]);

    async function buscar(product: any, supplier: any, type: any) {

        const prodTemp = product && product !== '0' ? product : '';
        setBillsPay(null);
        setLoading(true);
        setAllowLoadFromFilter(true);
        setActualProduct(prodTemp);
        setActualSupplier(supplier);
        setActualType(type);
        setPageCount(1)

        

        try {
            const token = localStorage.getItem('GroupId') || '';

            const config = {
                headers: { 'Authorization': `Bearer ${token}` },
            };

            const res = await api.post('/SupplierTariff/GetReportAsync',
                {
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "productCode": prodTemp,
                    "supplier": supplier,
                    "tipo": type
                }, config
            );
            if (res.status !== 400) {
                setLoading(false);
                setAllowLoadFromFilter(false);
                setParameters({
                    "page": 1,
                    "rowsPerPage": rowsPerPage,
                    "productCode": prodTemp,
                    "supplier": supplier,
                    "tipo": type
                });
                setBillsPay(res.data.data.rows);
                setTotalRows(res.data.data.rowsCount);
            }
        } catch (error: any) {
            setLoading(false);
            setAllowLoadFromFilter(false);
            setError(true)
            //if (error?.response?.status === 401) {
            //    window.location.href = window.location.origin + '/';
            //}
        }
    };

    async function exportBills() {

        try {
            const token = localStorage.getItem('GroupId') || '';

            const exp = await api.post('/BillsPayable/ExportReport',
                {
                    "productCode": actualProduct,
                    "supplier": actualSupplier,
                    "tipo": actualType,
                    "page": 1,
                    "rowsPerPage": 0
                }, 
                {
                    headers: {
                        "Content-Type": "application/json",    
                        "Accept": "*/*"    ,
                        //"Access-Control-Allow-Origin": "*"
                        'Authorization': `Bearer ${token}` 
                    },
                    responseType: 'blob'
                },
                
            );
            if (exp.status !== 400) {
                console.log(exp)
                const blob = new Blob([exp.data], { type: exp.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);                

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_pagar.xlsx',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    async function printBills() {

        try {
            const token = localStorage.getItem('GroupId') || '';

            const print = await api.post('/BillsPayable/PrintReport',
                {
                    "productCode": actualProduct,
                    "supplier": actualSupplier,
                    "tipo": actualType,
                    "page": 1,
                    "rowsPerPage": 0
                },
                {
                    headers: {
                        "Content-Type": "application/json",    
                        "Accept": "*/*"  ,  
                        //"Access-Control-Allow-Origin": "*"
                        'Authorization': `Bearer ${token}` 
                    },
                    responseType: 'blob'
                },
                
            );
            if (print.status !== 400) {
                const blob = new Blob([print.data], { type: print.headers["Content-Type"] });
                const objectUrl = window.URL.createObjectURL(blob);                

                const link = document.createElement('a');
                link.href = objectUrl;
                link.setAttribute(
                    'download',
                    'contas_pagar.pdf',
                );

                // Append to html link element page
                document.body.appendChild(link);

                // Start download
                link.click();

                // // Clean up and remove the link
                link?.parentNode?.removeChild(link);

                
            }
        } catch (error: any) {
            setError(true)
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    };

    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Administrativo / Fornecedores / Tarifário Fornecedor'} />
                    <div className="suppliers mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Tarifário Fornecedor</h2>
                    </div>
                    <FilterSuppliersTariff
                        buscar={buscar}
                        billsPay={billsPay}
                        error={error}
                        clearActualValues={clearActualValues}
                        allowLoadFromFilter={allowLoadFromFilter}
                    />
                    <TableSuppliersTariff
                        billsPay={billsPay}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        exportBills={exportBills}
                        printBills={printBills}
                        parameters={parameters}
                        totalPay={totalPay}
                        loading={loading}
                        refreshData={refreshData}
                    />
                </div>
            </DesktopDefault>
        </>
    )
}

export default SuppliersTariff;