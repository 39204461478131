import { useState, MouseEvent, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import i18next from "i18next";
import { useTranslation } from "react-i18next";


import BRFlag from "../../assets/icons/brazil.svg";
import ESFlag from "../../assets/icons/spain.svg";
import ENFlag from "../../assets/icons/united-kingdom.svg";


// import ViewCart from "c2-cart";

import "./DropdownLanguage.scss";

const DropdownLanguage = () => {
  const { t } = useTranslation();

  const [activeFlag] = useState<any>({ pt: BRFlag, en: ENFlag, es: ESFlag });
  // const [modalShow, setModalShow] = useState(false);
  const [lng, setLng] = useState(i18next.language);

  const changeLanguage = (e: MouseEvent<HTMLButtonElement>) => {
    var element = (e.target as HTMLButtonElement).value;
    setLng(element);
    
    i18next.changeLanguage(element);
  };  

  /* Detecta clique fora da div#wrapper para fechar o dropdown de login */
  const wrapperRef = useRef(null);
  useOutsideAlerter(wrapperRef);

  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          var elem: any = document.getElementById("authLoginDropdown");
          if (elem) {
            elem.classList.remove('active');
          }
        }
      }
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref]);
  }

 
 

  return (
    <div className="navTabHome">
      <nav className="navbar navbar-expand navbar-light ">
        <div className="container flex-row p-0" style={{ maxHeight: "90px", flexWrap: 'nowrap' }}>
         

          {/* Flag Dropdown */}
          <div
            className="navbar-collapse d-none d-md-block"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav ml-auto" style={{ maxHeight: "90px" }}>
              <li className="nav-item dropdown nav-item-c2">
                <span
                  className="nav-link text-primary text-center "
                  /* to="#" */
                  id="navbarDropdownLang"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img
                    loading='lazy'
                    alt="language flag"
                    src={activeFlag[lng] === undefined ? activeFlag.pt : activeFlag[lng]}
                    style={{
                      width: "35px",
                      height: "35px",
                      display: "inline",
                    }}
                  />
                </span>

                <div
                  className="dropdown-menu languages px-4 py-3 rounded"
                  aria-labelledby="navbarDropdownLang"
                  data-dropdown="navbarDropdownMinhaConta"
                  style={{ zIndex: 1040 }}
                >
                  <button
                    className={lng === "pt" ? "pt active" : "pt"}
                    onClick={changeLanguage}
                    value="pt"
                  >
                    <img
                      loading='lazy'
                      id="port-lang"
                      alt="bandeira do brasil"
                      src={BRFlag}
                      style={{ width: "45px", height: "30px" }}
                    />
                    {t("dropdownLanguage.language.portugues")}
                  </button>
                  <button
                    className={lng === "en" ? "en active" : "en"}
                    onClick={changeLanguage}
                    value="en"
                  >
                    <img
                      loading='lazy'
                      id="esp-lang"
                      alt="bandeira da espanha"
                      src={ENFlag}
                      style={{ width: "45px", height: "30px" }}
                    />
                    {t("dropdownLanguage.language.ingles")}
                  </button>
                  <button
                    className={lng === "es" ? "es active" : "es"}
                    onClick={changeLanguage}
                    value="es"
                  >
                    <img
                      loading='lazy'
                      id="ingl-lang"
                      alt="bandeira do reino unido"
                      src={ESFlag}
                      style={{ width: "45px", height: "30px" }}
                    />
                    {t("dropdownLanguage.language.espanhol")}
                  </button>
                </div>
              </li>

              <li className="nav-item active nav-item-c2 d-none">
                <Link className="nav-link text-primary text-center" to="/">
                  <i className="fal fa-home fa-lg" aria-hidden="true"></i>
                </Link>
              </li>

              {/* Flag Dropdown */}           

            
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default DropdownLanguage;
