import React from "react";

import { Row, Col } from "react-bootstrap";

import { useTranslation } from "react-i18next";

import Modal from "react-bootstrap/Modal";

import './ModalAlert.scss';

declare global {
    interface Window {
        alertModal?: any;
    }
}

export interface propEdit {
    modalShow: boolean,
    setModalShow: any,
    info: any,
    url: string,
    header?: string,
    className?: string,
}

const ModalAlert: React.FC<propEdit> = ({
    modalShow, setModalShow, info, url, header = 'no', className = ''
}: propEdit) => {
    console.log(info)
    const [t] = useTranslation();
    const customClasses: string = "modal-custom modalAuth modal-alert" + className;

    return (
        <div className="w-auto px-0">
            <Modal
                className={customClasses}
                show={modalShow}
                onHide={() => {
                    setModalShow(false)
                }}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
            >
                <>
                    {
                        header === "no"
                            ?
                            ""
                            :
                            <Modal.Header closeButton></Modal.Header>
                    }
                    {
                        className === "modal-default"
                            ?
                            <>
                                <div className="bg-top-modal" style={{ backgroundImage: `url(${url}${info?.modalImgDesktop})` }}>
                                    <img src={`${process.env.REACT_APP_SERVER_LOGO}`} alt="logo modal" />
                                </div>
                                <div className="bg-bottom-modal m-auto">
                                    <h3 className="">{t("modal.alert.title")}</h3>

                                    <p className="">{t("modal.alert.text")}</p>

                                    <small className="">{t("modal.alert.text2")}</small>
                                    <small className="">{t("modal.alert.text3")}</small>
                                    <small className="">{t("modal.alert.text4")}</small>
                                    <small className="">{t("modal.alert.text5")}</small>
                                    <small className="">{t("modal.alert.text6")}</small>
                                    <button
                                        className="btn btn-primary"
                                        onClick={() => {
                                            setModalShow(false)
                                        }}>
                                        {t("modal.alert.btn")}
                                    </button>
                                </div>
                            </>
                            :
                            <>
                                <Row className="h-100 mx-0 flex-column">
                                    <Col md={12} className="p-0">
                                        <div className="bg-top-modal" style={{ backgroundImage: `url(${url}${info?.modalImgDesktop})` }}>
                                            <img src={`${process.env.REACT_APP_SERVER_LOGO}`} alt="logo modal" />
                                        </div>
                                    </Col>
                                    <Col md={12} className="p-0 m-auto">
                                        <div className="bg-bottom-modal m-auto">
                                            <h3 className="">{`${info?.modalTitle}`}</h3>
                                            <p
                                                dangerouslySetInnerHTML={{ __html: info?.modalTextPortugues }}
                                            />
                                            {/* <p className="">{`${info?.modalTextPortugues}`}</p> */}
                                            <button
                                                className="btn btn-primary"
                                                onClick={() => {
                                                    setModalShow(false)
                                                }}>
                                                Ok, Entendi
                                            </button>
                                        </div>
                                    </Col>
                                </Row>
                            </>
                    }
                </>
            </Modal>
        </div >
    );
}

export default ModalAlert;
