import React, { useEffect, useState } from 'react';
import api from '../../services/api';

//import Select from 'react-select';

import { useTranslation } from "react-i18next";

import Form from 'react-bootstrap/Form';

export interface propPlus {
  propsField: any,
  propsLabel: any,
  propsErrors: any,
  allowAll?: any;
  isEdit?: any
  infoEdit?: any
};

const GetPromoters: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, allowAll = false, isEdit=false, infoEdit
}: propPlus) => {
  const { t } = useTranslation();

  const [info, setInfo] = useState<any>(null);
  //const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  /* const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  } */

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function getPromoter() {
      try {
        const { data } = await api.get(`/DropDown/GetPromoters`, config);
        if (data.status !== 400) {
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error?.response?.status === 401) {
          window.location.href = window.location.origin + '/';
        }

      }
    }
    getPromoter();
  }, []);

  /*   if (propsErrors.promotor !== undefined) {
      let borda = document.querySelector('.css-1s2u09g-control');
    } */

  if (info !== null) {
    return (
      <>
        <div className={propsErrors.promotor !== undefined ? "endpoint-error" : "endpoint"}>
          <Form.Control
            as="select"
            {...propsField}
            required
            autoComplete='off'
            className="form-select"
          >
            {allowAll === true
              ?
              <option value='0'>{t("salesReport.all")}</option>
              :
              <option value='' selected={isEdit=== false} disabled>-{t("salesReport.select")}-</option>
            }
            {info.length > 0 ? info.map((info: any) => (
              <option selected={isEdit === true && infoEdit.promotor === info.id} value={info.id}>{info.description}</option>
            )) : ''}
          </Form.Control>
          {/* <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors?.promotor ? "true" : ""}
            label={propsLabel}
            as="select"
            variant="standard"
            margin="normal"
            placeholder={t('accordionInfoCompany.endpoint')}
            options={Array.from(info)}
            getOptionLabel={(option: any) => `${option.description}`}
          /> */}
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )
  }
}

export default GetPromoters;