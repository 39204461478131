import React, { useState, useEffect } from 'react';
import api from '../../../services/api';

import { Container, Row, Col } from 'react-bootstrap';

import '../../../assets/sass/modal.scss';

import { useTranslation } from 'react-i18next';

import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';

import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';

import { Controller, useForm } from "react-hook-form";

import './FormNewUsersModal.scss';

export interface propAction {
    actionModalUser: any,
    setCloseModal: any
};

const FormNewUsersModal: React.FC<propAction> = ({
    actionModalUser, setCloseModal
}: propAction) => {
    const { t } = useTranslation();

    const validated = false;
    const [listModal, setListModal] = useState<any>(false);
    const [filterName, setFilterName] = useState<any>('');
    const [filterEmail, setFilterEmail] = useState<any>('');
    const [filterCpf, setFilterCpf] = useState<any>('');
    const [selectedCheck, setSelectedCheck] = useState<any>([]);

    useEffect(() => {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        async function listUsersModal() {
            try {
                const { data } = await api.post('/Supplier/GetUsersByFilterAsync',
                    {
                        "page": 0,
                        "rowsPerPage": 0,
                        "name": "",
                        "cpf": "",
                        "email": "",
                        "orderbyfield": ""
                    }
                    , config
                );
                if (data.status !== 400) {
                    setListModal(data.data.rows);
                }
            } catch (error: any) {
                if (error.response.status === 401) {
                    window.location.href = window.location.origin + '/';
                }
            }
        }
        listUsersModal();
    }, []);

    async function search(name: string, email: string, cpf: string) {
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };
        try {
            const res = await api.post('/Supplier/GetUsersByFilterAsync',
                {
                    "page": 0,
                    "rowsPerPage": 0,
                    name,
                    "cpf": cpf.split('.').join('').split('-').join(''),
                    email,
                    "orderbyfield": ""
                }
                , config
            );
            if (res.status !== 400) {
                setListModal(res.data.data.rows);
            }
        } catch (error: any) {
            if (error.response.status === 401) {
                window.location.href = window.location.origin + '/';
            }
        }
    }

    /* OFF SIDE */

    function handleClick(e: any) {
        // setSelectedCheck([...selectedCheck, e.target.value]);
        const getUser = listModal.find((element: { id: number; }) => element.id === parseInt(e.target.value));
        setSelectedCheck((prevState: any) => [...prevState, getUser])
    }

    /* OFF SIDE */

    function addCheckButton(cell: any, row: any) {
        return (
            <>
                <div className="center-table">
                    <Controller
                        control={control}
                        name={'' + cell}
                        render={({ field }: any) => (
                            <Form.Check
                                {...field}
                                fullWidth
                                variant="standard"
                                margin="normal"
                                type="checkbox"
                                value={cell}
                                onClick={handleClick}
                            />
                        )}
                    />
                </div>
            </>
        )
    }

    const columns = [
        { dataField: 'id', text: ``, formatter: addCheckButton },
        { dataField: 'name', text: t("accordionUsers.modalUsers.name") },
        { dataField: 'email', text: `Email` },
        { dataField: 'cpf', text: `CPF` }
    ];

    const pagination = paginationFactory({
        page: 1,
        sizePerPage: 10,
        lastPageText: '>>',
        firstPageText: '<<',
        nextPageText: '>',
        prePageText: '<',
        showTotal: false,
        alwaysShowAllBtns: true
    });

    function handleChangeName(e: any) {
        const newValue = e.target.value;
        setFilterName(newValue);
    }

    function handleChangeEmail(e: any) {
        const newValue = e.target.value;
        setFilterEmail(newValue);
    }

    function handleChangeCpf(e: any) {
        const newValue = e.target.value;
        setFilterCpf(newValue);
    }

    const handleSubmitFormModal = () => {
        actionModalUser(selectedCheck);
        setCloseModal(true);
    };

    const {
        control,
        handleSubmit,
        /* formState: { errors }, */
    } = useForm();

    const onSubmit = (data: any) => {
    }

    return (
        <>
            <Container className="bg-modal-contact p-0 newUsersModal" fluid>
                <Row className="h-100">
                    <Form
                        className="form-add-contact"
                        noValidate
                        validated={validated}
                        onSubmit={handleSubmit(onSubmit)}
                    >
                        <Row className="mb-3">
                            <Form.Group as={Col} md="6" className='mb-3'>
                                <Form.Control
                                    type="text"
                                    placeholder={t("accordionUsers.modalUsers.forName")}
                                    onChange={handleChangeName}
                                    autoComplete='off'
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3'>
                                <Form.Control
                                    type="text"
                                    placeholder={"Email"}
                                    onChange={handleChangeEmail}
                                    autoComplete='off'
                                />
                            </Form.Group>
                            <Form.Group as={Col} md="6" className='mb-3'>
                                <Form.Control
                                    type="text"
                                    placeholder={"CPF"}
                                    onChange={handleChangeCpf}
                                    autoComplete='off'
                                />
                            </Form.Group>
                            <div className="mb-3 col-md-6">
                                <Button onClick={() => search(filterName, filterEmail, filterCpf)} variant="outline-primary" className="btn-default search text-uppercase ">{t("accordionUsers.modalUsers.search")}</Button>
                            </div>
                        </Row>


                        <BootstrapTable bootstrap4 keyField='id' data={listModal} hover={true} columns={columns} pagination={pagination} striped={true} />

                        <div className="d-flex justify-content-end">

                            <Button onClick={() => handleSubmitFormModal()} type="button" variant="outline-primary" className="save btn-default text-uppercase mt-4">{t("accordionUsers.modalUsers.save")}</Button>
                        </div>
                    </Form>
                </Row>
            </Container>
        </>
    );
}

export default FormNewUsersModal;
