import React, { useEffect, useState } from "react";
import { Row, Col } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Form, Dropdown, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";

import api from "../../../../../../../../services/api";
import ModalImageVehicle from "../../../../../../../../components/Modal/ModalImage/ModalImageVehicle/ModalImageVehicle";
import ModalImageDriver from "../../../../../../../../components/Modal/ModalImage/ModalImageDriver/ModalImageDriver";
import ModalConfirm from "../../../../../../../../components/Modal/ModalConfirm/ModalConfirm";
import ModalMaps from "../../../../../../../../components/Modal/ModalMaps/ModalMaps";

/* tipo: 1 = Transporte, 2 = Motorista, 3 = Guia, 4 = Ticket, 5 = Restaurante */

//COMPONENTS

export interface propResource {
    transfers: any;
}

const SessionTransferOrigin: React.FC<propResource> = ({
    transfers,
}: propResource) => {

    //console.log(transfers);

    const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    const userName: any = tourAuth.user.nome;
    const userLastName: any = tourAuth.user.lastName;
    const uId: any = tourAuth.user.uId

    /* ID Suppliers */
    const [idVehicles, setIdVehicles] = useState<any>(transfers.veiculoId !== 0 ? transfers.veiculoId : undefined);
    const [idDrivers, setIdDrivers] = useState<any>(transfers.motoristaId !== 0 ? transfers.motoristaId : undefined);
    /* END - ID Suppliers */

    /* ID Resources */
    const [resourceIdVehicles, setResourceIdVehicles] = useState<any>([]);
    const [resourceIdDrivers, setResourceIdDrivers] = useState<any>([]);
    /* END - ID Resources */

    const [objVehicle, setObjVehicle] = useState<any>(null);
    const [objDriver, setObjDriver] = useState<any>(null);

    /* Modal Confirm */
    const [modalResponse, setModalResponse] = useState<any>();
    /* END - Modal Confirm */

    /* Success Modal (Mudar Session) */
    const [modalSuccess, setModalSuccess] = useState<any>(false);
    const [responseText, setResponseText] = useState<any>();
    const [modalContent, setModalContent] = useState<any>();
    const [log, setLog] = useState<any>();
    //const [modalShow, setModalShow] = useState<any>(false);
    /* END - Success Modal (Mudar Session) */

    const {
        control,
        handleSubmit,
        //formState: { errors },
    } = useForm();

    const onSubmit = (data: any) => {
        data.vehicle = idVehicles;
        data.driver = idDrivers;
        /* setModalResponse("loading"); */

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, },
        };

        console.log(idVehicles, idVehicles);


        setModalResponse("loading");

        if (idVehicles !== undefined && idDrivers !== undefined) {
            const Insert = async () => {
                try {
                    const res = await api.post(`/Manifest/UpdateTransfer`,
                        {
                            "id": transfers.newItemsId,
                            "fornecedorId": transfers.fornecedorId,
                            "motoristaId": data.driver,
                            "veiculoId": data.vehicle,
                            "uid": uId,
                            "usuario": `${userName} ${userLastName}`,
                        }, config);

                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalResponse("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalResponse("error");
                    }
                } catch (error: any) {
                    if (error?.response?.status === 401) {
                        setResponseText("Preencha os campos!");
                        setLog(1);
                        setModalResponse("error");
                    }
                    if (error?.response?.status === 400) {
                        setResponseText("Preencha os campos!");
                        setLog(1);
                        setModalResponse("error");
                    }
                }
            }
            Insert();
        } else {
            setResponseText("Preencha os campos!");
            setLog(1);
            setModalResponse("error");
        }
    }

    useEffect(() => {
        const Vehicles = async () => {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}` },
                };

                const { data } = await api.get(
                    `/Manifest/GetSupplierVehicles/${transfers.fornecedorId}`, config
                );
                if (data.status !== 400) {
                    setResourceIdVehicles(data.data);
                    //console.log(data.data);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                }
                if (error?.response?.status === 400) {
                }
            }
        };
        Vehicles();

        const Drivers = async () => {
            try {
                const token = localStorage.getItem('GroupId') || '{}';
                const config = {
                    headers: { 'Authorization': `Bearer ${token}` },
                };

                const { data } = await api.get(
                    `/Manifest/GetSupplierDrivers/${transfers.fornecedorId}`, config
                );
                if (data.status !== 400) {
                    setResourceIdDrivers(data.data);
                    //console.log(data.data);
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                }
                if (error?.response?.status === 400) {
                }
            }
        };
        Drivers();

    }, [transfers.fornecedorId]);

    useEffect(() => {
        async function VehiclesInfo() {
            if (idVehicles !== undefined) {
                try {
                    const token = localStorage.getItem('GroupId') || '{}';
                    const config = {
                        headers: { 'Authorization': `Bearer ${token}` },
                    };

                    const { data } = await api.get(
                        `/Manifest/GetVehicleInfo/${idVehicles}`, config
                    );
                    if (data.status !== 400) {
                        setObjVehicle(data.data);
                        //console.log(data.data)
                    }
                } catch (error: any) {
                    if (error?.response?.status === 401) {
                    }
                    if (error?.response?.status === 400) {
                    }
                }
            };
        }
        VehiclesInfo()
    }, [idVehicles])

    useEffect(() => {
        async function DriversInfo() {
            if (idDrivers !== undefined) {
                try {
                    const token = localStorage.getItem('GroupId') || '{}';
                    const config = {
                        headers: { 'Authorization': `Bearer ${token}` },
                    };

                    const { data } = await api.get(
                        `/Manifest/GetDriverInfo/${idDrivers}`, config
                    );
                    if (data.status !== 400) {
                        setObjDriver(data.data);
                        //console.log(data.data)
                    }
                } catch (error: any) {
                    if (error?.response?.status === 401) {
                    }
                    if (error?.response?.status === 400) {
                    }
                }
            };
        }
        DriversInfo()
    }, [idDrivers])

    function SendEmail(item: any) {
        setModalContent("loading");
        const email = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res = await api.post(`/Manifest/SendEmailToClient`,
                    {
                        id: item,
                        uid: uId,
                        usuario: `${userName} ${userLastName}`
                    }, config);
                if (res.status !== 400) {
                    if (res.status !== 400) {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("success");
                    } else {
                        setResponseText(res.data.data.texto);
                        setLog(res.data.data.log);
                        setModalContent("error");
                    }
                }
            } catch (error: any) {
                if (error?.response?.status === 401) {
                    alert("Erro ao processar operação");
                    //setModalShow(true);
                    setLog(1);
                    setModalContent("error");
                }
                if (error?.response?.status === 400) {
                    //setModalShow(true);
                    alert("Erro ao processar operação");
                    setLog(1);
                    setModalContent("error");
                }
            }
        };

        email();
    }

    function handleReload() {
        window.location.reload();
    }

    return (
        <>
            <div className="d-flex w-100">
                <div className="w-100">
                    <Form autoComplete="false">
                        <Row
                            className="d-flex align-items-center mt-5 mb-4 px-3"
                            style={{ flexDirection: "row" }}
                        >
                            <div style={{ maxWidth: "100px", marginTop: "15px" }}>
                                <p className="circled">
                                    <FontAwesomeIcon
                                        file-invoice
                                        icon={["fal", "bus"]}
                                        size="3x"
                                        style={{ padding: "5px" }}
                                    />
                                </p>
                            </div>
                            <div className="forms" style={{ paddingRight: "0" }}>
                                <Form.Group as={Col} md="2">
                                    <Form.Label>TIPO</Form.Label>
                                    <span className="d-flex">{transfers.tipo}</span>
                                </Form.Group>

                                <Form.Group as={Col} md="2">
                                    <Form.Label>FORNECEDOR</Form.Label>
                                    <span className="d-flex">{transfers.fornecedor}</span>
                                </Form.Group>

                                <Form.Group as={Col} md="2">
                                    <Form.Label className="d-flex" style={{ gap: "10px" }}>
                                        VEÍCULOS
                                        {
                                            idVehicles !== undefined
                                                ?
                                                <div>
                                                    <ModalImageVehicle
                                                        title="Veículo"
                                                        obj={objVehicle}
                                                        classModal="modal-image"
                                                    />
                                                </div>
                                                :
                                                <></>
                                        }
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name={`vehicle`}
                                        render={({ field }: any) => (
                                            <div className="endpoint">
                                                <Form.Select
                                                    aria-labelledby="aria-label"
                                                    inputId="aria-example-input"
                                                    name="aria-live-color"
                                                    {...field}
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    onChange={(e: any) => setIdVehicles(e.target.value)}
                                                    value={idVehicles}
                                                    disabled={transfers.veiculoId !== 0 ? true : false}
                                                >
                                                    <option value="" selected disabled>
                                                        Selecione
                                                    </option>
                                                    {resourceIdVehicles.map((vehicles: any) => {
                                                        return (
                                                            <>
                                                                <option value={vehicles.id}>
                                                                    {vehicles.descricao}
                                                                </option>
                                                            </>
                                                        );
                                                    })}
                                                </Form.Select>
                                            </div>
                                        )}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="2">
                                    <Form.Label className="d-flex" style={{ gap: "10px" }}>
                                        MOTORISTA
                                        {
                                            idDrivers !== undefined
                                                ?
                                                <div>
                                                    <ModalImageDriver
                                                        title="Motorista"
                                                        obj={objDriver}
                                                        classModal={"modal-image"}
                                                    />
                                                </div>
                                                :
                                                <></>
                                        }
                                    </Form.Label>
                                    <Controller
                                        control={control}
                                        name={`driver`}
                                        render={({ field }: any) => (
                                            <div className="endpoint">
                                                <Form.Select
                                                    aria-labelledby="aria-label"
                                                    inputId="aria-example-input"
                                                    name="aria-live-color"
                                                    {...field}
                                                    as="select"
                                                    variant="standard"
                                                    margin="normal"
                                                    onChange={(e: any) => setIdDrivers(e.target.value)}
                                                    value={idDrivers}
                                                    disabled={transfers.motoristaId !== 0 ? true : false}
                                                >
                                                    <option value="" selected disabled>
                                                        Selecione
                                                    </option>
                                                    {resourceIdDrivers.map((drivers: any) => {
                                                        return (
                                                            <>
                                                                <option value={drivers.id}>
                                                                    {drivers.nome}
                                                                </option>
                                                            </>
                                                        );
                                                    })}
                                                </Form.Select>
                                            </div>
                                        )}
                                    />
                                </Form.Group>

                                <Form.Group as={Col} md="2">
                                    <Form.Label>VALOR</Form.Label>
                                    <Controller
                                        control={control}
                                        name={`valor`}
                                        render={({ field }: any) => (
                                            <span
                                                className="d-flex"
                                                style={{ height: "36px", alignItems: "center" }}
                                            >
                                                R$ 0,00
                                            </span>
                                        )}
                                    />
                                </Form.Group>
                                <div className="d-flex justify-content-end align-items-end">
                                    {
                                        idVehicles === transfers.veiculoId && idDrivers === transfers.motoristaId
                                            ?
                                            <div className="table-default m-0">
                                                <Dropdown drop="start">
                                                    <Dropdown.Toggle variant="light ">
                                                        <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item eventKey="1" disabled={transfers.latOrigem !== 0 && transfers.lngOrigem !== 0 ? false : true}>
                                                            <ModalMaps
                                                                title="Localização"
                                                                //classModal="modal-image"
                                                                obj={transfers}
                                                            />
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="1" disabled={false} onClick={() => {
                                                            SendEmail(transfers.newItemsId)
                                                            setModalSuccess(true)
                                                        }}>
                                                            <FontAwesomeIcon
                                                                icon={['fal', 'envelope']}
                                                                className="mr-2"
                                                            />
                                                            Enviar Email
                                                        </Dropdown.Item>
                                                        <Dropdown.Item eventKey="1" disabled={true}>
                                                            <FontAwesomeIcon
                                                                icon={['fal', 'trash-alt']}
                                                                className="mr-2"
                                                            />
                                                            Remover
                                                        </Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                            </div>
                                            :
                                            <div className="d-flex">
                                                <div>
                                                    <ModalConfirm
                                                        handleSubmit={handleSubmit}
                                                        onSubmit={onSubmit}
                                                        manifestInsert="yes"
                                                        confirm={responseText}
                                                        modalContent={modalResponse}
                                                        log={log}
                                                    />
                                                </div>
                                                <div className="table-default m-0 ml-3">
                                                    <Dropdown drop="start">
                                                        <Dropdown.Toggle variant="light ">
                                                            <FontAwesomeIcon size="lg" icon={["fal", "ellipsis-h"]} />
                                                        </Dropdown.Toggle>

                                                        <Dropdown.Menu>
                                                            <Dropdown.Item eventKey="1" disabled={transfers.latOrigem !== 0 && transfers.lngOrigem !== 0 ? false : true}>
                                                                <ModalMaps
                                                                    title="Localização"
                                                                    //classModal="modal-image"
                                                                    obj={transfers}
                                                                />
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="1" disabled={false} onClick={() => {
                                                                SendEmail(transfers.newItemsId)
                                                                setModalSuccess(true)
                                                            }}>
                                                                <FontAwesomeIcon
                                                                    icon={['fal', 'envelope']}
                                                                    className="mr-2"
                                                                />
                                                                Enviar Email
                                                            </Dropdown.Item>
                                                            <Dropdown.Item eventKey="1" disabled={true}>
                                                                <FontAwesomeIcon
                                                                    icon={['fal', 'trash-alt']}
                                                                    className="mr-2"
                                                                />
                                                                Remover
                                                            </Dropdown.Item>
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </div>
                                            </div>
                                    }
                                </div>
                            </div>
                        </Row>
                        <hr />
                    </Form>
                </div>
                {/* Modal de Success */}
                <Modal
                    className={"modal-confirm loading-modal"}
                    show={modalSuccess}
                    onHide={() => setModalSuccess(false)}
                    size="lg"
                    aria-labelledby="contained-modal-title-vcenter"
                >
                    <>
                        <Modal.Body className='modal-body text-center sucess-pos'>
                            {
                                modalContent !== "loading"
                                    ?
                                    <>
                                        <div>
                                            {
                                                log === 1 || log === "1"
                                                    ?
                                                    <FontAwesomeIcon
                                                        icon={["fal", "times-circle"]}
                                                        size="5x"
                                                        className="text-primary"
                                                        style={{ fontSize: "7.5em"}}
                                                    />
                                                    :
                                                    log === 0 || log === "0"
                                                        ?
                                                        <FontAwesomeIcon
                                                            icon={["fal", "check-circle"]}
                                                            size="5x"
                                                            className="text-primary"
                                                            style={{ fontSize: "7.5em"}}
                                                        />
                                                        :
                                                        <FontAwesomeIcon
                                                            icon={["fal", "question-circle"]}
                                                            size="5x"
                                                            className="text-primary"
                                                            style={{ fontSize: "7.5em"}}
                                                        />
                                            }
                                        </div>
                                        <div>
                                            {responseText}
                                        </div>
                                        <div className="d-flex justify-content-center">
                                            {
                                                modalContent === 'success'
                                                    ?
                                                    <button
                                                        onClick={handleReload}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Confirmar
                                                    </button>
                                                    :
                                                    <button
                                                        onClick={() => {} /* setModalShow(false) */}
                                                        className="btn btn-primary mx-2 w-25"
                                                    >
                                                        Confirmar
                                                    </button>
                                            }
                                        </div>
                                    </>
                                    :
                                    <div className="modal-body">
                                        <div className="text-center">
                                            <div className="load"></div>
                                        </div>
                                        <div>
                                            <span>Processando</span>
                                        </div>
                                        <div></div>
                                    </div>
                            }
                        </Modal.Body>
                    </>
                </Modal>
                {/* END - Modal de Success */}
            </div>
        </>
    );
};

export default SessionTransferOrigin;
