import React, { useEffect, useState } from 'react';
import Breadcrumb from '../../../components/Breadcrumb/Breadcrumb';
import DesktopDefault from '../../../templates/DesktopDefault';
import api from '../../../services/api';

import FilterStockGroupSession from '../components/FilterStockGroupSession/FilterStockGroupSession';
import TableStockGroupSession from '../components/TableStockGroupSession/TableStockGroupSession';
import { Link } from 'react-router-dom';
import ModalShow from '../../../components/Modal/ModalShow/ModalShow';

const ListStockGroupSession = () => {
    const [loading, setLoading] = useState<any>(false);

    const [groups, setGroups] = useState<any>(null);

    const [totalRows, ] = useState<any>();
    const [pageCount, setPageCount] = useState<any>(1);
    const [rowsPerPage, setRowsPerPage] = useState<any>(10);

    const [modalShow, setModalShow] = useState<any>(false);
    const [modalLog, setModalLog] = useState<any>(null);
    const [modalMessage, setModalMessage] = useState<any>("Carregando");

    const handleDeleteGroup = async (groupCodeToDelete: string) => {
        setModalShow(true);
        
        let obj: any =
        {
            //Obrigatorio
            "GroupSessionUniqueCode": groupCodeToDelete, //Se me mandar somente esse tudo que estiver VINCULADO a ele sera excluído (GroupSessionUniqueCode, ProdModCodeUnique e TarCodeUnique)
            "ProductCode": "",
            "ProdModCodeUnique": "", //Se me mandar somente esse - tudo que contiver ele sera excluído
            "TarCodeUnique": "" //Se me mandar somente esse - tudo que contiver ele sera excluído
        }

        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
        };
        try {
            const res = await api.post('Stock/DeleteStockGroupSession', obj, config);
            if (res.status === 200) {
                if (res.data.statusCode === 200) {
                    if (res.data.data.item1 === undefined) { //Sucesso
                        setModalLog(res.data.data.log);
                        setModalMessage(res.data.data.texto);
                    } else { //ERRO
                        setModalLog(1);
                        setModalMessage(res.data?.data?.item1[0].texto || res.data?.errorMessage || 'Erro ao processar operação!');
                    }
                } else {
                    setModalLog(1);
                    setModalMessage(res.data?.data?.texto || res.data?.errorMessage || 'Erro ao processar operação!');
                }
            } else {
                setModalLog(1);
                setModalMessage('Erro ao processar operação!');
            }
        } catch (error: any) {
            setModalLog(1);
            setModalMessage(error?.response?.data?.errorMessage || 'Erro ao processar operação!');
        }
    }

    function search(obj: any) {
        setGroups(null);
        setLoading(true);

        const getStockGroup = async () => {
            const token = localStorage.getItem('GroupId') || '{}';
            const config = {
                headers: { 'Authorization': `Bearer ${token}`, 'Content-Type': 'application/json' },
            };
            try {
                const res: any = await api.post(`/Stock/GetGroupSession`, {
                    "GroupSessionUniqueCode": obj?.groupSessionUniqueCode || '',
                    "ProdModCodeUnique": "",
                    "TarCodeUnique": ""
                }, config);

                if (res.status === 200) {
                    setGroups(res.data.data)
                    setLoading(false);
                    //setTotalRows(data.data.data.rowsCount);
                }
            } catch (error: any) { }
        };
        getStockGroup();
    }

    useEffect(() => {
        search({});
    }, [])


    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={'Grupo de Estoque'} />

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>Grupo de Estoque</h2>
                        <Link to={'/products/add-stock-group'} className="btn btn-primary">
                            Adicionar Grupo de Estoque
                        </Link>
                    </div>

                    <FilterStockGroupSession
                        search={search}
                    />
                    <TableStockGroupSession
                        groups={groups}
                        loading={loading}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                        handleDeleteGroup={handleDeleteGroup}
                    />
                </div>
                <ModalShow
                    modalShow={modalShow}
                    setModalShow={setModalShow}
                    modalMessage={modalMessage}
                    setModalMessage={setModalMessage}
                    modalLog={modalLog}
                    setModalLog={setModalLog}
                    onClose={() => { search({}); }}
                />
            </DesktopDefault>
        </>
    );
};

export default ListStockGroupSession;