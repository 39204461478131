/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from "react";
import Breadcrumb from "../../components/Breadcrumb/Breadcrumb";
import api from "../../services/api";
import DesktopDefault from "../../templates/DesktopDefault";
import ResourceToAssociatedFilter from "./components/ResourceToAssociateFilter/ResourceToAssociatedFilter";
import ResourceToAssociateTable from "./components/ResourceToAssociateTable/ResourceToAssociateTable";


function ResourceToAssociate() {

    const data = [
        {
            generic1: "LOGO",
            generic2: "Imply",
            generic3: "São Gonçalo",
            generic4: "RJ",
            generic5: "21 26060 7932",
            generic6: "leoleo@123.com",
            generic7: "Atrativos Turísticos",
            generic8: "as"
        },
        {
            generic1: "LOGO",
            generic2: "Imply",
            generic3: "São Gonçalo",
            generic4: "RJ",
            generic5: "21 26060 7932",
            generic6: "leoleo@123.com",
            generic7: "Atrativos Turísticos",
            generic8: "as"
        },
        {
            generic1: "LOGO",
            generic2: "Imply",
            generic3: "São Gonçalo",
            generic4: "RJ",
            generic5: "21 26060 7932",
            generic6: "leoleo@123.com",
            generic7: "Atrativos Turísticos",
            generic8: "as"
        },
    ];

    const [resourceToAssociate, setResourceToAssociate] = useState<any>(data);

     //loading
     const [loading, setLoading] = useState<any>(false);
     //loading
   
     /* Pagination */
     const [totalRows, setTotalRows] = useState<any>();
     const [pageCount, setPageCount] = useState<any>(1);
     const [rowsPerPage, setRowsPerPage] = useState<any>(10);
     /* END - Pagination */

    function clearActualValues() {
        setActualName("");
        setActualState("");
        setActualSupplier(0);
        setActualAssoStatus(0);
       
      };
    
      async function buscar(name: any, state:any, supplier: any, AssoStatus: any) {
        setActualName(name); 
        setActualState(state); 
        setActualSupplier(supplier); 
        setActualAssoStatus(AssoStatus); 
      };
    
     
      //filtragem
      const [filterName, setActualName] = useState<any>("");
      const [filterState, setActualState] = useState<any>("");
      const [filterSupplier, setActualSupplier] = useState<any>(0);
      const [filterAssoStatus, setActualAssoStatus] = useState<any>(0);
      //fim da filtragem



    return (
        <>
            <DesktopDefault>
                <div className="container-fluid content-dashboard">
                    <Breadcrumb title={"Fornecedores - Resources"} />

                    <div className="mt-4 d-flex justify-content-between align-items-center">
                        <h2 className="title" style={{ color: "#707070" }}>
                            Fornecedores - Resources
                        </h2>
                    </div>
                    <ResourceToAssociatedFilter
                        buscar={buscar}
                        resourceToAssociate={resourceToAssociate}
                        clearActualValues={clearActualValues}
                    />

                    <ResourceToAssociateTable
                        resourceToAssociate={resourceToAssociate}
                        loading={loading}
                        totalRows={totalRows}
                        pageCount={pageCount}
                        setPageCount={setPageCount}
                        rowsPerPage={rowsPerPage}
                        setRowsPerPage={setRowsPerPage}
                    />
                </div>
            </DesktopDefault>
        </>
    )
}

export default ResourceToAssociate;