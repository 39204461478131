import React, { useState } from "react";
import { Container, Row, Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
//import InputGroup from 'react-bootstrap/InputGroup';
import Button from 'react-bootstrap/Button';
import Accordion from 'react-bootstrap/Accordion';
import { useTranslation } from "react-i18next";

import InputGroup from 'react-bootstrap/InputGroup';

import './FilterReportDailyReceipt.scss';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//Components
import RangeCalendar from "../../../../components/Calendar/RangeCalendar";
import { useForm } from "react-hook-form";
import ModalQuestion from "../../../../components/Modal/ModalQuestion/ModalQuestion";

//import GlobalAlert from "../../../Alert/GlobalAlert/GlobalAlert";

export interface propFilter {
    buscar: any,
    allowLoadFromFilter?: any;
    defaultDate: any;
    defaultTypeDate: any;
}

const FilterReportDailyReceipt: React.FC<propFilter> = ({
    buscar, allowLoadFromFilter, defaultDate, defaultTypeDate
}) => {

    const { t } = useTranslation();

    const today = new Date();
    const todayStr = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;

    const [filterTypeDate, setFilterTypeDate] = useState<any>(defaultTypeDate); // 1 = Período, 2 = Hoje, 3 = Este Mês, 4 = Este Ano
    const [filterDate, setFilterDate] = useState<any>(defaultDate);

    const [canChangeDate, setCanChangeDate] = useState<any>(false);

    
    //const [alert, setAlert] = useState<any>();
    //const [checked, setChecked] = useState<boolean>(true);

    /* Date Interval Confirmation */
    const [dateIntervalConfirmModalShow, setDateIntervalConfirmModalShow] = useState<any>(false);
    /* END - Date Interval Confirmation */

    //const tourAuth: any = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');

    // const userName: any = tourAuth.user.nome;
    // const userLastName: any = tourAuth.user.lastName;
    //const uId: any = tourAuth.user.uId;

    const {
        handleSubmit,
    } = useForm({});

    const handleFilterClick = (event: any) => {

        var dataIntervalLong = false;

        if (filterDate.length === 2) {
            let beginDate = filterDate[0].split('/')
            let endDate = filterDate[1].split('/')

            const dataIni = new Date(parseInt(beginDate[2]), parseInt(beginDate[1])-1, parseInt(beginDate[0]));
            const dataFim = new Date(parseInt(endDate[2]), parseInt(endDate[1])-1, parseInt(endDate[0]));

            const days = (dataFim.getTime() - dataIni.getTime()) / (1000*60*60*24);

            dataIntervalLong = (days > 90); // 3 meses
            
        }

        if(dataIntervalLong) {
            setDateIntervalConfirmModalShow(true);
        } else {
            handleActionBuscar();
        }
    }

    const handleActionBuscar = () => {
        setDateIntervalConfirmModalShow(false);
        //console.log(filterLocal, filterStatus, filterType, filterSupplier, filterTypeDate, filterDate);

        buscar(filterTypeDate, filterDate);
    };

    // const incDate = (e: any, dias: number) => {
    //     e.preventDefault();

    //     const today = new Date();

    //     const newDate = (filterDate.length === 0) ? `${today.getDate()}/${today.getMonth()+1}/${today.getFullYear()}` : filterDate[0];

    //     const datao = newDate.split('/');
        
    //     const newDate1 = new Date(parseInt(datao[2]), parseInt(datao[1])-1, parseInt(datao[0])+dias);
    //     //console.log(parseInt(datao[0])+dias); return;
    //     const newDateStr = `${newDate1.getDate()}/${newDate1.getMonth()+1}/${newDate1.getFullYear()}`

    //     setFilterTypeDate(1);
    //     setCanChangeDate(true);
    //     setFilterDate([newDateStr]);

    //     buscar(filterTypeDate, [newDateStr], filterAccount);
    // };

    const handleClearClick = () => {

        let inputValue = document.querySelectorAll('input');
        for (let i = 0; i < inputValue.length; i++) {
            inputValue[i].value = "";
        }

        let selectValue = document.querySelectorAll('select');
        for (let i = 0; i < selectValue.length; i++) {
            if(selectValue[i].id === "rowsPerPage"){
                selectValue[i].value = "10";
            }else{
                selectValue[i].value = "";
            }
        }

        setFilterTypeDate(defaultTypeDate); // este mes
        setFilterDate(defaultDate);
        
        buscar(2, defaultDate);
    };


    // eslint-disable-next-line @typescript-eslint/no-unused-vars

    const handleChangeTypeDate = (e: React.ChangeEvent<HTMLInputElement>) => {
        const newValue = e.target.value;
        setFilterTypeDate(newValue);

        // 1 = Período, 2 = Hoje, 3 = Este Mês, 4 = Últimos 30 dias
        if(newValue === '2') {
            setCanChangeDate(false);
            setFilterDate([todayStr]);
        }
        if(newValue === '3') {
            setCanChangeDate(false);

            const ini = new Date(today.getFullYear(), today.getMonth(), 1);
            const fini = new Date(today.getFullYear(), today.getMonth()+1, 0);

            const iniStr = `${ini.getDate()}/${ini.getMonth() + 1}/${ini.getFullYear()}`;
            const finiStr = `${fini.getDate()}/${fini.getMonth() + 1}/${fini.getFullYear()}`;

            setFilterDate([iniStr,finiStr]);
        }
        if(newValue === '4') {
            setCanChangeDate(false);

            const ini = new Date(today.getFullYear(), 0, 1); // primeiro dia do ano
            const fini = new Date(today.getFullYear(), today.getMonth(), today.getDate());

            const iniStr = `${ini.getDate()}/${ini.getMonth() + 1}/${ini.getFullYear()}`;
            const finiStr = `${fini.getDate()}/${fini.getMonth() + 1}/${fini.getFullYear()}`;

            setFilterDate([iniStr,finiStr]);
        }
        if(newValue === '1') {
            setCanChangeDate(true);
        }
    }

    

    // useEffect(() => {
    //     if (cashierExtract) {
    //         findAccountName();
    //     }
    // }, [cashierExtract])


    return (
        <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
            <Accordion defaultActiveKey="1">
                <Accordion.Item eventKey="0">
                    <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Buscar Receita Diaria</span></Accordion.Header>
                    <Accordion.Body className="pt-0">
                        <Container className="p-0" fluid>
                            <Row>
                                <div>
                                    <Form >
                                        <Row className="mb-3">
                                            {/* {alert} */}
                                            <Form.Group as={Col} md="2">
                                                <Form.Label>Tipo de Data</Form.Label>
                                                <div className="endpoint">
                                                    <InputGroup hasValidation>
                                                        <Form.Select
                                                            aria-describedby="inputGroupPrepend"
                                                            onChange={(e: any) => handleChangeTypeDate(e)}
                                                            value={filterTypeDate}
                                                        >
                                                            <option value="2">Hoje</option>
                                                            <option value="3">Este Mês</option>
                                                            <option value="4">Este Ano</option>
                                                            <option value="1">Período</option>
                                                        </Form.Select>
                                                    </InputGroup>
                                                </div>
                                            </Form.Group>
                                            <Form.Group as={Col} md="3">
                                                <Form.Label>Data</Form.Label>
                                                <div className="d-flex">
                                                    {/* <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, -1)}><FontAwesomeIcon
                                                        size="sm"
                                                        icon={["fal", "arrow-left"]}
                                                        style={{ color: "#707070", margin: "0px", padding: "0px"}}
                                                    /></button> */}
                                                    <InputGroup hasValidation className="ranger-calendar d-flex">
                                                    
                                                    {canChangeDate === true ?
                                                        <RangeCalendar date={filterDate} setDate={setFilterDate} />
                                                    : 
                                                        <div className="py-1 px-2 mx-1">{filterDate[1] ? `${filterDate[0]} ~ ${filterDate[1]}` : filterDate}</div>
                                                    }
                                                    </InputGroup>
                                                    {/* <button className="btn btn-default p-0 mx-2" style={{width:"25px"}} onClick={(e:any) => incDate(e, 1)}><FontAwesomeIcon
                                                        size="sm"
                                                        icon={["fal", "arrow-right"]}
                                                        style={{ color: "#707070", margin: "0px", padding: "0px" }}
                                                    /></button> */}
                                                </div>
                                            </Form.Group>
                                            
                                            
                                        </Row>
                                        <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                            <Col xs lg="12" className="d-flex justify-content-md-end">
                                                <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" onClick={handleClearClick}>{t('partners.partnersfilter.btnClear')}</Button>
                                                <Button className="btn-default text-uppercase" onClick={handleSubmit(handleFilterClick)}>{allowLoadFromFilter === true ? <p className="load"></p> : t('partners.partnersfilter.btnSearch')}</Button>
                                            </Col>
                                        </Row>
                                    </Form>
                                </div>
                            </Row>
                        </Container>
                    </Accordion.Body>
                </Accordion.Item>
            </Accordion>
            <ModalQuestion
                modalShow={dateIntervalConfirmModalShow}
                setModalShow={setDateIntervalConfirmModalShow}
                titulo=""
                mensagem="Intervalo de data excede 3 meses. Continua?"
                handleActionConfirmModal={handleActionBuscar}
            />
        </div>
    )
}

export default FilterReportDailyReceipt;