/* eslint-disable @typescript-eslint/no-unused-vars */

import { ErrorMessage } from "@hookform/error-message";
import React, { useEffect, useState } from "react";
import { Card, Form, Col, Row, Modal } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
//import GetCategoriesChannelMult from "../../../../components/C2Points/GetCategoriesChannelMult";
import api from "../../../../services/api";
import GetSalesChannel from "../../../../components/C2Points/GetSalesChannel";
export interface propInsert {
  product: any;
  control: any;
  isEdit?: any;
  infoEdit?: any;
  setValue: any;
  setImage: any;
  setSalesChannel: any;
};

const EnableCategory: React.FC<propInsert> = ({
  product, control, isEdit = false, infoEdit, setValue, setImage, setSalesChannel
}) => {
  const [categoryCheck, setCategoryCheck] = useState<any>(true);

  function handleCheck(e: any) {
    setValue("category", e.target.checked === true ? 1 : 2)
    setCategoryCheck(e.target.checked)
  };

  useEffect(() => {
    if (isEdit && infoEdit !== null) {
      setSalesChannel(infoEdit?.salesChannel?.map((elem: any) => { return ({ label: elem.name, value: elem.id }) }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [infoEdit])

  /* const imgHandler = (e: any) => {
    const reader = new FileReader();
    reader.onload = () => {
      if (reader.readyState === 2) {
        setImage(reader.result)
      }
    }
    reader.readAsDataURL(e.target.files[0]);
    setImageName(e.target.files[0].name);
  } */

  const { formState: { errors } } = useForm();

  return (
    <>
      <Card className="px-3 pb-4">
        <Row className="">
          <Col lg={12}>
            <Form.Group className="d-flex">
              <Form.Label className="mr-2">Habilitar Categoria</Form.Label>
              <Controller
                control={control}
                name="category"
                // rules={{ required: { value: true, message: "required" }, }}
                render={({ field }: any) => (
                  <Form.Check
                    type="switch"
                    id="custom-switch"
                    label="Sim"
                    defaultChecked={categoryCheck}
                    onChange={(e: any) => handleCheck(e)}
                  />
                )}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="">
          <Col lg={6} md={6}>
            <Form.Group>
              <Form.Label>Nome</Form.Label>
              <Controller
                control={control}
                name="name"
                defaultValue={isEdit === true ? infoEdit.categorieName : ""}
                rules={{ required: { value: !isEdit, message: "Nome obrigatório", }, }}
                render={({ field }: any) => (
                  <div className="input-text-custom">
                    <Form.Control
                      {...field}
                      type="text"
                      variant="standard"
                      margin="normal"
                      autoComplete="off"
                    />
                  </div>
                )}
              />

              <ErrorMessage
                errors={errors}
                name="name"
                render={({ message }) =>
                  <small style={{ color: "red" }}>{message}</small>
                }

              />
            </Form.Group>
          </Col>
          <Col lg={6} md={6}>
            <Form.Group as={Col} md="12" controlId="">
              <Form.Label className="label-small">
                Canais de venda
              </Form.Label>

              <Controller
                control={control}
                name="salesChannel"
                rules={{ required: { value: true, message: "Por favor, informe esse campo" } }}
                render={({ field }: any) => (
                  <GetSalesChannel propsField={field} propsErrors={errors} setInfoChange={setSalesChannel} setValue={setValue} infoEdit={infoEdit?.salesChannel?.map((elem: any)=>{ return({idSalesChannel: elem.id})})} />
                )}
              />

              <ErrorMessage
                errors={errors}
                name="salesChannel"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="mt-3">
          <Col lg={12}>
            <Form className="d-flex">
              <Form.Label className="mr-2">
                Search Engine Optimization
              </Form.Label>
            </Form>
          </Col>
        </Row>
        <Row className="">
          <Col lg={4} md={4}>
            <Form.Group>
              <Form.Label>URL Key</Form.Label>
              <Controller
                control={control}
                name="url"
                defaultValue={isEdit === true ? infoEdit.categorieSlug : ""}
                rules={{ required: { value: !isEdit, message: "required" }, }}
                render={({ field }: any) => (
                  <div className="input-text-custom">
                    <Form.Control
                      {...field}
                      type="text"
                      variant="standard"
                      margin="normal"
                      autoComplete="off"
                    />
                  </div>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="url"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
          </Col>
          <Col lg={8} md={8}>
            <Form.Group>
              <Form.Label>Descrição</Form.Label>
              <Controller
                control={control}
                name="description"
                defaultValue={
                  isEdit === true ? infoEdit.categorieDescription : ""
                }
                rules={{ required: { value: !isEdit, message: "required" }, }}
                render={({ field }: any) => (
                  <div className="input-text-custom">
                    <Form.Control
                      {...field}
                      type="text"
                      variant="standard"
                      margin="normal"
                      autoComplete="off"
                    />
                  </div>
                )}
              />
              <ErrorMessage
                errors={errors}
                name="description"
                render={({ message }) => (
                  <small style={{ color: "red" }}>{message}</small>
                )}
              />
            </Form.Group>
          </Col>
        </Row>
      </Card>
    </>
  );
};

export default EnableCategory;