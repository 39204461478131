/* eslint-disable eqeqeq */
import React, { useEffect, useState } from 'react';
import api from '../../services/api';

import Form from 'react-bootstrap/Form';
import Select from 'react-select';

export interface propPlus {
  propsField: any,
  propsLabel?: any,
  propsErrors: any,
  setFilterSupplier?: any,
  supplier?: any,
  setValue?: any,
  defaultId?: any,
  isMulti?: any,
};

const GetSupplierGroupId: React.FC<propPlus> = ({
  propsField, propsLabel, propsErrors, setFilterSupplier, supplier, setValue = undefined, defaultId = undefined, isMulti = false
}: propPlus) => {
  const [info, setInfo] = useState<any>(null);
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);

  const onMenuOpen = () => {
    if (isMenuOpen === false) {
      setIsMenuOpen(true);
    } else {
      setIsMenuOpen(false);
    }
  }

  useEffect(() => {
    const token = localStorage.getItem('GroupId') || '{}';
    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };
    async function listAccountingAccounts() {
      try {
        const { data } = await api.get(`/DropDown/GetSupplierGroupId`, config);
        if (data.status !== 400) {
          if(setValue){
            setValue('supplier', data.data.filter((elem: any) => elem.id == defaultId)[0])
          }
          setInfo(data.data)
        }
      } catch (error: any) {
        if (error.response.status === 401) {
          window.location.href = window.location.origin + '/';
        }
      }
    }
    listAccountingAccounts();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);


  if (info !== null) {
    // if (setFilterSupplier) {
    //   setFilterSupplier(propsField.value?.supplierId)
    // }

    return (
      <>
        <div className={propsErrors[propsField.name] !== undefined ? "endpoint-error" : "endpoint"}>
          <Select
            aria-labelledby="aria-label"
            inputId="aria-example-input"
            name="aria-live-color"
            onMenuOpen={onMenuOpen}
            onMenuClose={onMenuOpen}
            {...propsField}
            aria-invalid={propsErrors[propsField.name] ? "true" : ""}
            as="select"
            isMulti={isMulti}
            variant="standard"
            margin="normal"
            placeholder={`${supplier ? supplier : "Selecione"}`}
            options={Array.from(info)}
            getOptionValue={(option: any) => `${option.id}`}
            getOptionLabel={(option: any) => `${option.description}`}
            //value={info.find((a: any) => a.supplierId === propsField.value)}
          />
        </div>
      </>
    );
  } else {
    return (
      <>
        <Form.Select>
          <option value='' disabled></option>
        </Form.Select>
      </>
    )

  }
}

export default GetSupplierGroupId;