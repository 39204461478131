import React from "react";
import { Container, Row, Col } from "react-bootstrap";

import "../../../../../assets/sass/modal.scss";
import "./FormReceiptValueEdit.scss";

//import Modal from "react-bootstrap/Modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import { Controller, useForm } from "react-hook-form";
import Modal from "react-bootstrap/Modal";

//import IconSucess from "../../../assets/icons/modal-sucess.svg";

import api from '../../../../../services/api';

export interface propModal {
  action: any,
  info: any,
  setModalSuccess: any, 
  setResponseText: any, 
  setModalContent: any, 
  setLog: any
};

const FormReceiptValueEdit: React.FC<propModal> = ({
  action, info
  , setModalSuccess, setResponseText, setModalContent, setLog
}: propModal, props: any) => {
  //const [tipo, setTipo] = useState<string>(tipo);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      id: info.newItemsId ? info.newItemsId : 0,
      valorReceita: info.valorReceita ? info.valorReceita.toFixed(2).replace('.',',') : '',
      valorPrejuizo: info.valorPrejuizo ? info.valorPrejuizo.toFixed(2).replace('.',',') : '',
    },
  });

  const handleSaveClick = (dataObj: any) => {

    if(dataObj.valorReceita) dataObj.valorReceita = parseFloat(dataObj.valorReceita.replace(",", "."));
    if(dataObj.valorPrejuizo) dataObj.valorPrejuizo = parseFloat(dataObj.valorPrejuizo.replace(",", "."));

    let userData = JSON.parse(localStorage.getItem('c2tourAuth') || '{}');
    const user = userData.user;

    dataObj.uid = user.uId;
    dataObj.usuario = `${user.nome} ${user.lastName}`;

    const token = localStorage.getItem('GroupId') || '';

    const config = {
      headers: { 'Authorization': `Bearer ${token}` },
    };

    const salvar = async () => {
      setModalContent("loading");
      setModalSuccess(true);
        try {

            const { data } = await api.post("SessionClosure/UpdateReceiptValue", dataObj, config);
            /* console.log(data.data); */
            if (data.status !== 400) {
                var response = data.data;

                //alert(response.texto);
                setResponseText(response.texto);
                setLog(response.log);

                if(response.log !== 1) {
                  setModalContent("success");
                  action(dataObj);
                } else {
                  setModalContent("error");
                }
            }   
        }catch(error: any) {
          setModalContent("error");
          setLog(1);
            if (error?.response?.status === 401) {
                window.location.href = window.location.origin + '/';              
            }
            if (error?.response?.status === 400) {
                //window.location.href = window.location.origin + '/session-closure';              
            }
            //;
        }
    }
    salvar();
  };

  return (
    <>
      <Modal.Body>
        <Container className="bg-modal-contact p-0" fluid>
          <Row>
            <h5 className="contact-title mb-5">Altera Valor Receita</h5>
            <Form
              className="form-add-contact"
              noValidate
            >
              <div className="">
                <Row className="">
                  <Form.Group as={Col} md="6">
                    <Form.Label>Receita</Form.Label>
                    <Controller
                      control={control}
                      name="valorReceita"
                      rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                      render={({ field }: any) => (
                        <Form.Control type="text"
                          {...field}
                          aria-invalid={errors?.valorReceita ? "true" : ""}
                          autoComplete='off'
                          variant="standard"
                          
                          margin="normal"
                          required />
                      )}
                    />
                  </Form.Group>
                  <Form.Group as={Col} md="6">
                    <Form.Label>NP</Form.Label>
                    <Controller
                      control={control}
                      name="valorPrejuizo"
                      rules={{ required: { value: true, message: 'Por favor, preencher esse campo' } }}
                      render={({ field }: any) => (
                        <Form.Control type="text"
                          {...field}
                          aria-invalid={errors?.valorPrejuizo ? "true" : ""}
                          autoComplete='off'
                          variant="standard"
                          
                          margin="normal"
                          required />
                      )}
                    />
                  </Form.Group>
                </Row>
              </div>
            </Form>
          </Row>
        </Container>
      </Modal.Body>

      <Modal.Footer>
        <Row className="buttons-modal d-flex justify-content-end mx-0">
          {/* <Button className="form-button mr-3" onClick={props.onHide}>
            Cancelar
          </Button> */}
          <Button
            type="button"
            className="form-button"
            onClick={handleSubmit(handleSaveClick)}
          >
            <FontAwesomeIcon
              icon={["fal", "save"]}
              size="1x"
              style={{ marginRight: "5px" }}
            /> Salvar
          </Button>
        </Row>
      </Modal.Footer>


    </>
  );
}

export default FormReceiptValueEdit;
