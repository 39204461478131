import React from 'react';
import DatePicker from "react-multi-date-picker";
import Icon from "react-multi-date-picker/components/icon"
import { useTranslation } from "react-i18next";
import configData from "../../config/config.json";

import './Calendar.scss';

const CustomMultipleInput = ({ openCalendar, value, date, setValue, errors, startTimeLocal, field }: any) => {

    setValue(date);

    return (
        <>
            <div style={{ position: 'relative' }}>

                <input
                    {...field}
                    className={errors?.dataPgto ? "form-control bg-white frm-sm w-100 rounded endpoint-error" : "form-control bg-white frm-sm w-100 rounded"}
                    //onFocus={openCalendar}
                    value={date}
                    id={'singleCalendarId'}
                    placeholder={"dd/mm/aaaa"}
                    autoComplete='off'
                    onChange={(e: any) => { if (e.target.value.length <= 10) { setValue(e.target.value) } }}
                    //onPaste={(e: any) => {console.log(e)}}
                    onKeyPress={(e) => !/[0-9/]/.test(e.key) && e.preventDefault()}
                />
                <Icon onClick={openCalendar} style={{ position: 'absolute', top: '3px', right: '5px' }} />
            </div>
        </>
    )
}

const SingleCalendarTypingOption = ({ date, setValue = () => { }, field, errors = undefined, startTimeLocal = undefined, disableFutureDates = false, disablePastDates = false }: any) => {
    const { t } = useTranslation();


    const maxDate: any = new Date();

    return (
        <DatePicker
            /* {...field} */
            weekDays={t('calendar.weekDays').split(',')}
            months={t('calendar.months').split(',')}
            format={configData.DATE.PT}
            calendarPosition={`top`}
            maxDate={disableFutureDates === true ? maxDate : ''}
            minDate={disablePastDates === true ? maxDate : ''}
            render={<CustomMultipleInput date={date} setValue={setValue} errors={errors} startTimeLocal={startTimeLocal} field={field} />}
            onChange={(e: any) => { setValue(new Date(e).toJSON().slice(0, 10).split('-').reverse().join('/')) }}
        />
    )
}

export default SingleCalendarTypingOption;