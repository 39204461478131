import React, { useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { Modal } from 'react-bootstrap';
import api from '../../services/api';
import ModalShow from '../Modal/ModalShow/ModalShow';

// Styles
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.css';
import './Reminder.scss';

export interface propTable {
  id: any;
}

const Reminder: React.FC<propTable> = ({ id }: propTable) => {
  // Modal Loading
  const [loadingModal, setLoadingModal] = useState<boolean>(false);
  const [modalMessage, setModalMessage] = useState<string>('');
  const [modalLog, setModalLog] = useState<any>(null);

  const reminderData: any = [
    {
      nome: 'Reservas sem Hotel',
      sms: 'sms',
      whatsapp: 'whatsapp',
      email: 'email',
      id: 1,
    },
    {
      nome: 'Lembrete da Atração',
      sms: 'sms',
      whatsapp: 'whatsapp',
      email: 'email',
      lastcall: 'lastcall',
      id: 2,
    },
    {
      nome: 'Ponto de Encontro',
      sms: 'sms',
      whatsapp: 'whatsapp',
      email: 'email',
      lastcall: 'lastcall',
      id: 3,
    },
    {
      nome: 'Reserva para Fornecedor',
      sms: 'sms',
      whatsapp: 'whatsapp',
      email: 'email',
      id: 4,
    },
  ];

  const columns = [
    {
      dataField: 'nome',
      text: 'Reminder',
    },
    // {
    //   dataField: 'sms',
    //   text: 'SMS',
    //   formatter: addCheckBox,
    // },
    {
      dataField: 'whatsapp',
      text: 'WhatsApp',
      formatter: addCheckBox,
    },
    {
      dataField: 'email',
      text: 'E-mail',
      formatter: addCheckBox,
    },
    {
      dataField: 'lastcall',
      text: 'Última Chamada',
      formatter: addCheckBoxCall,
    },
    {
      dataField: 'id',
      text: 'Ação',
      formatter: addActionButton,
    },
  ];

  const rowClasses = (row: any) => {
    if (row.status === 3) {
      return 'border-analise';
    } else if (row.status === 1) {
      return 'border-ativo';
    } else if (row.status === 2) {
      return 'border-inativo';
    } else {
      return '';
    }
  };

  function addActionButton(cell: any, row: any) {
    return (
      <button className="btn-reminder bg-primary" onClick={() => submitReminder(row)}>
        Enviar
      </button>
    );
  }

  function addCheckBox(cell: any, row: any) {
    return (
      <input
        className={`${row[cell]}-${row.id}`}
        type="checkbox"
        value={row.id}
      ></input>
    );
  }

  function addCheckBoxCall(cell: any, row: any) {
    if (row.id === 2 || row.id === 3) {
      return (
        <input
          className={`${row[cell]}-${row.id}`}
          type="checkbox"
          value={row.id}
        ></input>
      );
    } else {
      return <></>;
    }
  }

  function submitReminder(data: any) {
    setLoadingModal(true);
    const whatsappChecked = (
      document.querySelector(`.whatsapp-${data.id}`) as HTMLInputElement
    ).checked;

    const emailChecked = (
      document.querySelector(`.email-${data.id}`) as HTMLInputElement
    ).checked;

    const reminderInfos = async () => {
      const token = localStorage.getItem('GroupId') || '{}';
      const config = {
        headers: { Authorization: `Bearer ${token}` },
      };
      try {
        const res = await api.post(
          `${process.env.REACT_APP_SERVER_URL_API}/Reminder/ReminderSendSingleEmailAndWhatsApp`,
          {
            idItens: id.idNewItems,
            reminderType: data.id,
            isLastCall: data.id === 1 || data.id === 2 ? 2 : 1,
            isEmail: emailChecked === true ? 1 : 2,
            isMobile: whatsappChecked === true ? 1 : 2,
          },
          config,
        );
        if (res.status !== 400) {
          if (res.data.statusCode === 200) {
            setModalMessage(res.data.data);
            setModalLog(0);
          } else {
            setModalLog(1);
            setModalMessage(res.data[0].texto);
          }
        }
      } catch (error: any) {
        setModalLog(1);
        setModalMessage('Error');
      }
    };
    reminderInfos();
  }

  return (
    <>
      <div className="reminder-table table-default">
        <div className="table-container">
          <Modal.Header closeButton>
            <div className="table-title">
              <h3>Enviar Reminder</h3>
            </div>
          </Modal.Header>
          <hr className="w-100 my-4"></hr>
          <BootstrapTable
            bootstrap4
            keyField="id"
            data={reminderData}
            columns={columns}
            rowClasses={rowClasses}
          />
        </div>
      </div>
      <ModalShow
        modalShow={loadingModal}
        setModalShow={setLoadingModal}
        modalMessage={modalMessage}
        setModalMessage={setModalMessage}
        modalLog={modalLog}
        setModalLog={setModalLog}
      />
    </>
  );
};

export default Reminder;
