/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Row, Col } from "react-bootstrap";

import Form from "react-bootstrap/Form";
import { useAppSelector } from "../../../store/hooks";

export interface propForm {
    control: any,
    errors: any,
    setValue: any,
    t?:any
};

const PaymentCheckoutDirect: React.FC<propForm> = ({
    control, errors, setValue, t
}: propForm) => {
    const cartSIG: any = JSON.parse(JSON.stringify(useAppSelector((state: any) => state.cart)));
    const [date, setDate] = useState<any>(null);

    let today = new Date();
    let dd = String(today.getDate()).padStart(2, '0');
    let mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
    let yyyy = today.getFullYear();

    let todayFoPayment = dd + '/' + mm + '/' + yyyy;

    const fixDate = (data: any) => {
        var aux = data.split('/');

        return (`${aux[2]}-${aux[1]}-${aux[0]}`);
    }

    useEffect(() => {
        if (typeof date === typeof '') {
            setValue('directDate', fixDate(date))
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [date])

    return (
        <>
            <h3>{t("checkout.paymentCheckout.direct.payment")}</h3>

            <Row className="mb-3">
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDirectAmountPaid">
                    <Form.Label>{t("checkout.paymentCheckout.direct.paidValue")}</Form.Label>
                    <div>{cartSIG.totalCart?.toLocaleString('pt-br', { style: 'currency', currency: 'BRL' })}</div>
                    {/* <Controller
                        control={control}
                        name="amountPaid"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="amountPaid"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    /> */}
                </Form.Group>
                {/* <Form.Group as={Col} md="4" controlId="formCheckoutPymentDirectAmountReceived">
                    <Form.Label>Valor recebido:</Form.Label>
                    <Controller
                        control={control}
                        name="amountReceived"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="amountReceived"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    />
                </Form.Group> */}
                <Form.Group as={Col} md="4" controlId="formCheckoutPymentDirectDate">
                    <Form.Label>{t("checkout.paymentCheckout.direct.date")}</Form.Label>
                    <div>{todayFoPayment}</div>
                    {/* <InputGroup
                        hasValidation
                        size="sm"
                        placeholder="Quando?"
                    >
                        <SingleCalendar setValue={setDate} />
                    </InputGroup> */}
                    {/* <Controller
                        control={control}
                        name="DirectDate"
                        rules={{ required: { value: true, message: 'Por favor, informe o campo.' } }}
                        render={({ field }: any) => (
                            <Form.Control
                                {...field}
                                aria-invalid={errors?.compName ? "true" : ""}
                                variant="standard"
                                margin="normal"
                                autoComplete="off"
                                required
                            />
                        )}
                    />
                    <ErrorMessage
                        errors={errors}
                        name="DirectDate"
                        render={({ message }) => <small style={{ color: "red" }}>{message}</small>}
                    /> */}
                </Form.Group>
            </Row>
        </>
    );
}

export default PaymentCheckoutDirect;