import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Accordion, Container, Row, Col, Form, InputGroup, Button } from 'react-bootstrap';
import { Controller, useForm } from 'react-hook-form';


export interface propFilter {
    search: any
}

const FilterStockGroupSession: React.FC<propFilter> = ({
    search
}) => {
    const {
        control,
        handleSubmit,
        setValue,
    } = useForm();

    const handleClearClick = () => {
        setValue('groupSessionUniqueCode', '');
    }

    const onSubmit = (data: any) => {
        search(data);
    }

    return (
        <>
            <div className="suppliers-filter d-flex justify-content-center bg-white mt-4" style={{ textAlign: "left" }}>
                <Accordion defaultActiveKey="1">
                    <Accordion.Item eventKey="0">
                        <Accordion.Header><FontAwesomeIcon icon={['fal', 'sliders-h']} size="lg" className="mr-2" /><span className="h5 mb-0">Grupos de Estoque</span></Accordion.Header>
                        <Accordion.Body className="pt-0">
                            <Container className="p-0" fluid>
                                <Row>
                                    <div>
                                        <Form
                                            className="h-100"
                                            noValidate
                                            validated={false}
                                            onSubmit={handleSubmit(onSubmit)}
                                        >
                                            <Row className="mb-3">
                                                <Form.Group as={Col} md="4">
                                                    <Form.Label>Código</Form.Label>
                                                    <Controller
                                                        control={control}
                                                        name="groupSessionUniqueCode"
                                                        render={({ field }: any) => (
                                                            <Form.Control
                                                                {...field}
                                                                type="text"
                                                                variant="standard"
                                                                margin="normal"
                                                                autoComplete="off"
                                                            />
                                                        )}
                                                    />
                                                </Form.Group>
                                            </Row>

                                            <Row as={Col} md="12" className="mt-5 justify-content-md-end">
                                                <Col xs lg="12" className="d-flex justify-content-md-end">
                                                    <Button variant="outline-primary" className="btn-default mr-2 text-uppercase" type='button' onClick={handleClearClick}>Limpar</Button>
                                                    <Button className="btn-default text-uppercase" type='submit'>Buscar</Button>
                                                </Col>
                                            </Row>
                                        </Form>
                                    </div>
                                </Row>
                            </Container>
                        </Accordion.Body>
                    </Accordion.Item>
                </Accordion>
            </div>
        </>
    );
};

export default FilterStockGroupSession;