import React, { Key, useEffect } from 'react';
import Table from "react-bootstrap/Table";

import { Row, Col, Button} from "react-bootstrap";
import { Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './SendQuotation.scss';
import { Controller, useForm } from 'react-hook-form';
import api from '../../../../../../services/api';

export interface propInsert {
    info: any,
    setModalSuccess: any, 
    setModalContent: any, 
    setLog: any, 
    setResponseText: any,
    t?: any,
    invoiceType?:any;
    setInvoiceType?:any;
}

const SendQuotationNew: React.FC<propInsert> = ({
    info, setModalSuccess, setModalContent, setLog, setResponseText, t, invoiceType, setInvoiceType
}: propInsert) => {
console.log(invoiceType)

    function closeModal() {
        var closeBtn: any = document.getElementsByClassName("btn-close")[0];
        if (closeBtn !== undefined) {
            closeBtn.click();
        }
    }

    const {
        control,
        handleSubmit,
        formState: { errors },
    } = useForm();

    function onSubmit(obj: any) {

        if(!obj.email1 && !obj.email2 && !obj.email3) {
            setResponseText(t('groupsAndCustomized.report.sendQuotationAction.emailRequired'));
            setModalContent("warning");
            setLog(2);
            setModalSuccess(true);
            return;
        }

        setModalContent("loading");
        setModalSuccess(true);
        
        const token = localStorage.getItem('GroupId') || '{}';
        const config = {
            headers: { 'Authorization': `Bearer ${token}` },
        };

        const EditAccounts = async () => {
            try {

                const { data } = await api.post(`GroupFile/SendQuotationReportPdf`, {
                    fileId: info.id,
                    recipients: [
                        obj.email1, obj.email2, obj.email3
                    ]
                }, config);
                /* console.log(data.data); */
                if (data.status !== 400) {
                    var response = data.data;

                    setResponseText(response.texto);
                    setLog(response.log);

                    if(response.log === 1) {
                        setModalContent("error");
                    } else {
                        setModalContent("success");
                    }
                    
                }
            } catch (error: any) {
                setResponseText("Erro ao tentar enviar cotação");
                setModalContent("error");

                // if (error?.response?.status === 401) {
                //     window.location.href = window.location.origin + '/';
                // }
                // if (error?.response?.status === 400) {
                //     window.location.href = window.location.origin + '/list-groups-customized';
                // }
            }
        }
        EditAccounts();
    }
    const handleDate = (data:string) => {
        return data.split('T')[0].split('-').reverse().join('/')
    }

    const formatAmount = (value:number) => {
        const num:string = value.toString();
        if (!num) return '';
        
        // Remove caracteres não numéricos
        const cleanNum = num.replace(/\D/g, '');
        
        // Adiciona os pontos
        const formattedNum = cleanNum.replace(/\B(?=(\d{3})+(?!\d))/g, '.');
        
        return `${formattedNum}`;
      };

    if (info !== null) {        
        return (
            <>
                <Form className="h-100" noValidate onSubmit={handleSubmit(onSubmit)} autoComplete="false">
                    <div className='d-flex flex-column justify-content-between h-100'>
                        <div>
                            <Row className="mb-3">
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>{t("groupsAndCustomized.report.sendQuotationAction.email1")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="email1"
                                        //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                //label={t("groupsAndCustomized.observation")}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='off'    
                                                defaultValue={info?.email}                                           
                                                //value={observation}
                                                //onChange={(e: any) => { setObservation(e.target.value); field.onChange(e); }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                           
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>{t("groupsAndCustomized.report.sendQuotationAction.email2")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="email2"
                                        //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                //label={t("groupsAndCustomized.observation")}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='off'
                                                //value={observation}
                                                //onChange={(e: any) => { setObservation(e.target.value); field.onChange(e); }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                           
                                <Form.Group as={Col} md={4}>
                                    <Form.Label>{t("groupsAndCustomized.report.sendQuotationAction.email3")}</Form.Label>
                                    <Controller
                                        control={control}
                                        name="email3"
                                        //rules={{ required: { value: true, message: 'Por favor, informe o nome fantasia.' } }}
                                        render={({ field }: any) => (
                                            <Form.Control
                                                {...field}
                                                //label={t("groupsAndCustomized.observation")}
                                                variant="standard"
                                                margin="normal"
                                                autoComplete='off'
                                                //value={observation}
                                                //onChange={(e: any) => { setObservation(e.target.value); field.onChange(e); }}
                                            />
                                        )}
                                    />
                                </Form.Group>
                            </Row>
                            <Row className="mb-3">
                                    {/* A fatura será paga por? Valor Total; Serviço Discriminado. */}
                                    <Form.Group as={Col} md={6}>
                                        <Form.Label>{t("groupsAndCustomized.invoiceType")}</Form.Label>
                                        <Controller
                                            control={control}
                                            name="invoiceType"
                                            //rules={{ required: { value: true, message: "required" }, }}
                                            render={({ field }: any) => (
                                                <div className='d-flex align-items-center' style={{ height: "43px" }}>
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.invoiceTypeOpts.totalValue")}
                                                        // name="customerType"
                                                        id="invoiceTypeRadios1"
                                                        // disabled="true"
                                                        onClick={()=> setInvoiceType(1)}
                                                        checked={invoiceType === 1}
                                                        className="col-md-6"
                                                    />
                                                    <Form.Check
                                                        {...field}
                                                        type="radio"
                                                        label={t("groupsAndCustomized.invoiceTypeOpts.serviceItemized")}
                                                        // name="customerType"
                                                        id="invoiceTypeRadios2"
                                                        // disabled="true"
                                                        onClick={()=> setInvoiceType(2)}
                                                        checked={invoiceType === 2}
                                                        className="col-md-6"
                                                    />
                                                </div>
                                            )}
                                        />
                                    </Form.Group>
                                    </Row>

                            <div className='my-4 w-100 border-bottom'></div>

                            <Row className="mb-3 mx-1">
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>Operador:</label>
                                    <p>{info.operatorUser.name} {info.operatorUser.lastName}</p>
                                </div>
                                </Col>
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>Cliente:</label>
                                    <p>{info.clientName}</p>
                                </div>
                                </Col>
                                <Col md={3}>
                                <   div className="bg-light p-2 quotation-data">
                                        <label>Nome do Grupo:</label>
                                        <p>{info.groupName}</p>
                                    </div>
                                </Col>
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>Net/Comissionado:</label>
                                    <p>{info.tarTypeDesc}</p>
                                </div>
                                </Col>
                            </Row>
                            <Row className="mb-3 mx-1">
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>Comissão:</label>
                                    <p>{info.comission}</p>
                                </div>
                                </Col>
                                <Col md={3}>
                                <   div className="bg-light p-2 quotation-data">
                                        <label>Markup:</label>
                                        <p>{info.markup}</p>
                                    </div>
                                </Col>
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>Data de Abertura:</label>
                                    <p>{handleDate(info.dateCreated)}</p>
                                </div>
                                </Col>
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>Data do 1º Serviço:</label>
                                    <p>{handleDate(info.firstServiceDate)}</p>
                                </div>
                                </Col>
                            </Row>
                            <Row className="mb-3 mx-1">
                                <Col md={3}>
                                <   div className="bg-light p-2 quotation-data">
                                        <label>Moeda:</label>
                                        <p>{info.currency}</p>
                                    </div>
                                </Col>
                            {/* </Row>
                            <Row className="mb-3 mx-1"> */}
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>File Nº:</label>
                                    <p>{info.fileNo}</p>
                                </div>
                                </Col>
                                <Col md={3} >
                                <div className="bg-light p-2 quotation-data">
                                    <label>Status financeiro:</label>
                                    <p>{info.financialStatusDesc}</p>
                                </div>
                                </Col>
                                <Col md={3}>
                                <   div className="bg-light p-2 quotation-data">
                                        <label>Status operação:</label>
                                        <p>{info.operationStatusDesc}</p>
                                    </div>
                                </Col>
                            </Row>

                            <div className='my-4 w-100 border-bottom'></div>

                            <Row className="mb-3 mx-1 px-3">
                                <h3 className='p-0'>Passageiros</h3>
                                <table className="table table-striped border table-send-quotation">
                                    <thead>
                                        <tr>
                                            <th scope="col">Nome</th>
                                            <th scope="col">Passaporte/ID</th>
                                            <th scope="col">Email</th>
                                            <th scope="col">Telefone</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {info?.passengers?.map((item:any)=> {
                                            return <tr key={item.id}>
                                                <td>{item.name}</td>
                                            <td>{item.passportId}</td>
                                            <td>{item.email}</td>
                                            <td>{item.phone}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </Row>

                            {/* <div className='my-4 w-100 border-bottom'></div> */}

                            <Row className="mb-3 mx-1 px-3">
                                <h3 className='p-0'>Serviços</h3>
                                <table className="table table-striped border table-send-quotation">
                                    <thead>
                                        <tr>
                                            <th scope="col">Tipo</th>
                                            <th scope="col">Recurso</th>
                                            <th scope="col">Fornecedor</th>
                                            <th scope="col">Global/Preço</th>
                                            <th scope="col">Valor Net</th>
                                            {Number(invoiceType) === 2 && <th scope="col">Valor Venda</th>}
                                            <th scope="col">Data</th>
                                            <th scope="col">Cidade</th>
                                            <th scope="col">Idioma</th>
                                            <th scope="col">Status</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {info?.services?.map((service:any)=> {
                                            return <tr key={service.id}>
                                                <td>{service.serviceTypeName}</td>
                                            <td>{service.serviceName}</td>
                                            <td>{service.supplierName}</td>
                                            <td>{service.globalPriceDesc}</td>
                                            <td>{service.netAmount}</td>
                                            {Number(invoiceType) === 2 && <td>{service.saleAmount}</td>}
                                            <td>{handleDate(service.serviceDate)}</td>
                                            <td>{service.city}</td>
                                            <td>{service.languageDesc}</td>
                                            <td>{service.statusDesc}</td>
                                            </tr>
                                        })}
                                    </tbody>
                                </table>
                            </Row>
                        </div>
                        <div className='mt-4 d-flex justify-content-end'>
                            <Button type="submit"  className="form-button">{t('groupsAndCustomized.report.sendQuotationAction.send')}</Button>
                        </div>
                    </div>
                </Form>
            </>
        )
    } else {
        return (<></>);
    }

}

export default SendQuotationNew;