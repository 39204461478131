import React from "react";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import IconHome from '../../assets/icons/icon-home.svg';

function Breadcrumb(props: any) {
    return (
        <>
            <div className="bg-white p-2" style={{ textAlign: "left" }}>
                <span className="mr-2">
                    <Link className="" to="/dashboard"><FontAwesomeIcon className="text-primary" icon={['fal', 'home']}/></Link>
                </span>
                <span>/ {props.title}</span>
            </div>
        </>
    );
}

export default Breadcrumb;